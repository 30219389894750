import React from "react";
import { SemanticICONS } from "semantic-ui-react";
import { Container, MenuItemIcon, MenuItemText } from "./styles";

interface MenuItemProps {
    icon: SemanticICONS;
    text: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export function MenuItem({ icon, text, onClick }: MenuItemProps) {
    return (
        <Container onClick={onClick}>
            <MenuItemIcon name={icon} />
            <MenuItemText>{text}</MenuItemText>
        </Container>
    );
}
