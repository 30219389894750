import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Segment } from 'semantic-ui-react';
import { orderBy, roteirosPendentesSemWhere } from '../../Utils/Roteiros';
import { oportunidadesPendentesPaginado } from '../../_actions/RoteiroActions';
import { DEFAULT_PER_PAGE, paginator, RoteiroPaginator } from './../../Utils/RoteiroPaginator';
import ListarRoteiros from './ListarRoteiros';
import ListarRoteirosPendentes from './Listas/ListarRoteirosPendentes';
import RoteiroConnect from './RoteiroConnect';

const DATA_ATUAL = moment().format('YYYY-MM-DD')
const RP = RoteiroPaginator;

class RoteirosPendentes extends Component {
    state = { filtroAberto: false, carregando: false };

    componentWillMount() {
        if (!RP.findByName("pendentes"))
            this.buildPaginator();
    }

    buildPaginator = () => {
        var callback = ({ data }) => this.props.dispatch(oportunidadesPendentesPaginado(data))
        _.isEmpty(this.props.oportunidadesPendentesPaginado) &&
            paginator()
                .get(roteirosPendentesSemWhere(this.props.empresa.uid, false)
                    .where("data", ">=", DATA_ATUAL)
                    .orderBy("data", "asc"),
                    callback,
                    DEFAULT_PER_PAGE,
                    'pendentes'
                )
    }

    roteiroEstaAberto = (index, indexM) =>
        this.state.roteiroAberto === index &&
        this.state.dataAberta === indexM;

    abrirRoteiro = (index, indexM) => event =>
        this.setState(
            this.roteiroEstaAberto(index, indexM) ?
                { roteiroAberto: -1, dataAberta: -1 } :
                { roteiroAberto: index, dataAberta: indexM, confirmacaoApagar: false });

    apagarRoteiroConfirmacao = (index, indexM) => event => { };

    apagarRoteiro = () => { };

    render() {
        const { confirmacaoApagar } = this.state;
        const { t } = this.props;
        const carregando = _.toArray(this.props.oportunidadesPendentesPaginado) == null;
        const oportunidades = _.groupBy(this.props.oportunidadesPendentesPaginado, 'data')
        return (
            <div>
                <ListarRoteiros
                    renderer={ListarRoteirosPendentes}
                    t={t} carregando={carregando}
                    roteiros={oportunidades}
                    confirmacaoApagar={confirmacaoApagar}
                    roteiroEstaAberto={this.roteiroEstaAberto}
                    apagarRoteiroConfirmacao={this.apagarRoteiroConfirmacao}
                    apagarRoteiro={this.apagarRoteiro}
                    abrirRoteiro={this.abrirRoteiro} />

                <Segment basic textAlign='center'>
                    {!paginator().hasEnded() &&
                        <Button
                            content="Carregar mais"
                            loading={carregando}
                            onClick={() => {
                                var callback = ({ data }) => {
                                    this.props.dispatch(oportunidadesPendentesPaginado(data))
                                    this.setState({ carregando: false })
                                }
                                paginator()
                                    .get(roteirosPendentesSemWhere(this.props.empresa.uid, false)
                                        .where("data", ">=", DATA_ATUAL)
                                        .orderBy("data", "asc"),
                                        callback,
                                        DEFAULT_PER_PAGE,
                                        'pendentes'
                                    )
                            }}
                            basic color='purple'></Button>
                    }
                </Segment>
            </div>
        )
    }
}

export default RoteiroConnect(
    withTranslation()(RoteirosPendentes)
);
