import React from "react";
import { renderizarOportunidades, renderizarPdv, renderizarProdutos } from "./LinhasDasTabelas";
import { TabelaDeOportunidade, TabelaDePDV, TabelaDeProdutos } from "./Tabelas";

export default (state, setState, t) => [
    {
        menuItem: { key: "Oportunidades", icon: "rocket", content: t("por.oportunidades") },
        render: () => (
            <>
                {!state.carregando.status && (
                    <>
                        <TabelaDeOportunidade
                            setState={setState}
                            state={state}
                            t={t}
                            render={renderizarOportunidades}
                        />
                    </>
                )}
                {state.carregando.status && (
                    <>
                        <p>{state.carregando.step}</p>
                    </>
                )}
            </>
        ),
    },
    {
        menuItem: { key: "PDV", icon: "shopping cart", content: t("por.pdv") },
        render: () => (
            <>
                {!state.carregando.status && (
                    <>
                        <TabelaDePDV setState={setState} state={state} t={t} render={renderizarPdv} />
                    </>
                )}
                {state.carregando.status && (
                    <>
                        <p>{state.carregando.step}</p>
                    </>
                )}
            </>
        ),
    },
    {
        menuItem: { key: "produtos", icon: "boxes", content: t("por.produtos") },
        render: () => (
            <>
                {!state.carregando.status && (
                    <>
                        <TabelaDeProdutos setState={setState} state={state} t={t} render={renderizarProdutos} />
                    </>
                )}
                {state.carregando.status && (
                    <>
                        <p>{state.carregando.step}</p>
                    </>
                )}
            </>
        ),
    },
];
