import { Button, Flex, Input, Table, TableContainer } from "@chakra-ui/react";
import { map, orderBy } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Opportunity } from "../../../@types/Opportunity";
import { Iconify } from "../../../Componentes/Iconify";
import { useSpreadsheet } from "../../../hooks/useSpreadhseet";
import { CostPerPromoterReportTableContent } from "./CostPerPromoterReportTableContent";
import { CostPerPromoterReportTableHeader } from "./CostPerPromoterReportTableHeader";

export interface CostPerPromoterReportProps {
	reportData: {
		docs: Opportunity[];
	};
}

export interface OpportunitiesReportTableData extends Opportunity {
	horas: string;
}

export interface OrderState {
	direction: "asc" | "desc";
	columnName:
		| "data"
		| "cnpj"
		| "loja"
		| "inicio"
		| "fim"
		| "horas"
		| "status"
		| "promoter"
		| "valor";
}

export function RelatorioDeOportunidades({ reportData }: CostPerPromoterReportProps) {
	const { t } = useTranslation();
	const [order, setOrder] = useState<OrderState | null>(null);
	const [inputFilterValue, setInputFilterValue] = useState("");

	const sortMappedReportData = useCallback(
		(data: OpportunitiesReportTableData[]) => {
			switch (order?.columnName) {
				case "data":
					return orderBy(data, "data", order?.direction);
				case "cnpj":
					return orderBy(data, "loja.codigo", order?.direction);
				case "loja":
					return orderBy(data, "loja.fantasia", order?.direction);
				case "inicio":
					return orderBy(data, "iniciadoEm", order?.direction);
				case "fim":
					return orderBy(data, "finalizadoEm", order?.direction);
				case "horas":
					return orderBy(data, "horas", order?.direction);
				case "promoter":
					return orderBy(data, "usuario.nome", order?.direction);
				case "valor":
					return orderBy(data, "valor", order?.direction);
				default:
					return data;
			}
		},
		[order?.columnName, order?.direction]
	);

	const sortedAndMappedReportData = useMemo<OpportunitiesReportTableData[]>(() => {
		const noOrderedData = map(reportData.docs, value => {
			let horas = moment(value.finalizadoEm).diff(value.iniciadoEm, "minutes").toString();
			return { ...value, horas };
		});

		return sortMappedReportData(noOrderedData).filter(data => {
			return new RegExp(inputFilterValue, "i").test(data.usuario.nome);
		});
	}, [reportData.docs, sortMappedReportData, inputFilterValue]);

	const reportDataToExportInXLSX = useMemo(() => {
		return sortedAndMappedReportData.map(data => {
			return {
				Data: data.data,
				CNPJ: data.loja.codigo,
				PDV: data.loja.fantasia,
				"Nome do Promotor": data.usuario.nome,
				Inicio: data.iniciadoEm,
				Fim: data.finalizadoEm,
				Horas: moment.utc(Number(data.horas) * 60 * 1000).format("HH:mm"),
				Valor: data.valor,
			};
		});
	}, [sortedAndMappedReportData]);

	const { downloadXLSX } = useSpreadsheet({
		data: reportDataToExportInXLSX,
		fileName: "relatório-de-oportunidades",
	});

	return (
		<Flex flexDir={"column"} gap={4}>
			<Flex
				gap={4}
				alignItems={"center"}
				justifyContent={"space-between"}
				flexDir={{ base: "column", md: "row" }}>
				<Input
					maxW={{ base: "full", md: "md" }}
					placeholder={t("busque-promotor-pelo-nome")}
					onChange={e => setInputFilterValue(e.target.value)}
				/>
				<Button
					size={"lg"}
					onClick={downloadXLSX}
					w={{ base: "full", md: "auto" }}
					disabled={!sortedAndMappedReportData.length}
					leftIcon={<Iconify icon="mdi:file-excel" boxSize={"6"} />}>
					{t("exportar-xlsx")}
				</Button>
			</Flex>
			<TableContainer border={"1px solid"} rounded="md" borderColor={"gray.300"}>
				<Table variant="striped" id="table-to-xls">
					<CostPerPromoterReportTableHeader order={order} setOrder={setOrder} />
					<CostPerPromoterReportTableContent tableData={sortedAndMappedReportData} />
					{/* <CostPerPromoterReportTableFooter tableData={sortedAndMappedReportData} /> */}
				</Table>
			</TableContainer>
		</Flex>
	);
}
