import React, { Component } from 'react';
import './components.css';

class ImagemCircular extends Component {
    render() {
        const { src, size } = this.props;
        let imagem = src?.default ?? src
        return (
            <div className={'imagem-circular ' + size} style={{ backgroundImage: 'url(' + imagem + ')' }} {...this.props}></div>
        )
    }
}
export default ImagemCircular;