import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Button, Grid, Loader, Message, Segment, Header, Divider } from "semantic-ui-react";
import { chamarFunction } from "../../Utils/Api";
import { ModalDeConfirmacao } from "../../Componentes/ConfirmarRegistro";
import { Tooltip } from "@material-ui/core";

class ListarFaturas extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.listar();
    }

    retornoLista = (body) => this.setState({ carregando: false, lista: body.items });

    listar = () => {
        this.setState({ carregando: true }, () =>
            chamarFunction("listarFaturas", {
                tipo: "empresas",
                uid: this.props.empresa.uid,
                empresa: this.props.empresa,
            }).then(this.retornoLista)
        );
    };

    cancelarFatura = () => {
        this.setState({ loading: true }, () =>
            chamarFunction("cancelarFatura", {
                body: {
                    tipo: "empresas",
                    uid: this.props.empresa.uid,
                    idAuth: this.props.empresa.idUsuario,
                    idFatura: this.state.idFatura,
                },
            }).then((res) => {
                if (!res.errors) {
                    this.setState(
                        {
                            loading: false,
                            modal: false,
                        },
                        () => this.listar()
                    );
                } else {
                    this.setState({
                        loading: false,
                        erro: true,
                    });
                }
            })
        );
    };

    status = {
        pending: this.props.t("pendente"),
        paid: this.props.t("pago"),
        expired: this.props.t("expirado"),
        refunded: this.props.t("reembolsado"),
        canceled: this.props.t("cancelado"),
    };

    cor = {
        pending: "#fbc02d",
        paid: "#35BE35",
        expired: "#CCC",
        refunded: "#F44336",
        canceled: "#F44336",
    };

    render() {
        const { t } = this.props;
        const { lista, carregando } = this.state;

        if (carregando) {
            return (
                <Segment basic className="page-loading">
                    <Loader style={{ display: "block" }} inline="centered">
                        {t("carregando")}
                    </Loader>
                </Segment>
            );
        }

        return (
            <div>
                <ModalDeConfirmacao
                    t={t}
                    isOpen={this.state.modal}
                    error={this.state.erro}
                    titulo={"Cancelar recarga"}
                    message={t("voce-tem-certeza-que-deseja-cancelar-a-recarga")}
                    onCancel={() => this.setState({ modal: false }, () => console.log("cancelado"))}
                    onConfirm={this.cancelarFatura}
                    onLoading={this.state.loading}
                />
                <Header textAlign="center" as="h2">
                    {t("lista.de.faturas")}
                </Header>
                {_.isEmpty(lista) ? (
                    <Message style={{ textAlign: "center" }} content={t("nenhuma.fatura.disponivel")}></Message>
                ) : (
                    <Segment color="purple">
                        {_.map(lista, (nota, i) => {
                            const isPix = nota.payable_with === "pix";
                            const pendingMessage = !isPix ? t("aguarde.48.horas") : t("aviso-compensacao-pix");

                            return (
                                <Grid key={nota.id} className="roteiro-grid" stackable>
                                    <Grid.Column verticalAlign="middle" width={4}>
                                        <span>{nota.total}</span>
                                        <br />
                                        {nota.status === "pending" && (
                                            <Tooltip title={pendingMessage} arrow>
                                                <span className="roteiro-info-secondary">{pendingMessage}</span>
                                            </Tooltip>
                                        )}
                                    </Grid.Column>
                                    <Grid.Column verticalAlign="middle" width={3}>
                                        <span>{t("criado")}</span>
                                        <br />
                                        <span className="roteiro-info-secondary">
                                            {moment(nota.created_at_iso).format("DD/MM/YYYY")}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column verticalAlign="middle" width={3}>
                                        <span>{t("vencimento")}</span>
                                        <br />
                                        <span className="roteiro-info-secondary">
                                            {moment(nota.due_date).format("DD/MM/YYYY")}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column verticalAlign="middle" width={3} textAlign="center">
                                        <span style={{ color: this.cor[nota.status] }}>{this.status[nota.status]}</span>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <Button
                                            target="_blank"
                                            href={nota.secure_url}
                                            basic
                                            content={t("pdf")}
                                            icon="file pdf"
                                            fluid
                                        />
                                        {nota.status === "pending" && (
                                            <Button
                                                onClick={() => this.setState({ modal: true, idFatura: nota.id })}
                                                icon="times"
                                                color="red"
                                                basic
                                                content={"Cancelar"}
                                                fluid
                                                style={{ marginTop: 10 }}
                                            />
                                        )}
                                    </Grid.Column>
                                    {lista.length - 1 !== i && (
                                        <Divider
                                            style={{
                                                width: "100%",
                                                marginTop: 0,
                                                marginBottom: 0,
                                                marginLeft: 10,
                                                marginRight: 10,
                                            }}
                                        />
                                    )}
                                </Grid>
                            );
                        })}
                    </Segment>
                )}
            </div>
        );
    }
}

export default ListarFaturas;
