import React from "react";
import { withTranslation } from "react-i18next";
import imgDefault from "../../../imagens/imagem-padrao-empresa.png";

const ImagemCircularComImagemDefault = (props) => {
    const { t } = props;
    return (
        <>
            <div
                style={{
                    width: props.width,
                    height: props.width,
                    borderRadius: props.circular ? "50%" : "0%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                }}>
                <img
                    alt={t("imagem.perfil.usuario")}
                    style={{ width: "100%" }}
                    src={props.url ?? imgDefault}
                    onError={(e) => {
                        e.target.src = imgDefault;
                    }}
                />
            </div>
        </>
    );
};

export default withTranslation()(ImagemCircularComImagemDefault);
