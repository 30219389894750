import { SALVAR_FATURAS, SELECIONAR_PROMOTOR } from "./Types";

export const selecionarPromotor = data => {
	return {
		type: SELECIONAR_PROMOTOR,
		payload: data,
	};
};
export const actionSalvarFaturas = data => {
	return {
		type: SALVAR_FATURAS,
		payload: data,
	};
};
