import firebase from "firebase";
import _ from "lodash";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Form, Header, Icon, Message } from "semantic-ui-react";
import { v4 } from "uuid";
import { Editor } from "../../Componentes/SunEditor";
import { storage } from "../../config";
import { FileCompressError } from "../../errors/File.erros";
import { compressImage } from "../../Utils/File.utils";

export default class TreinamentoImagem extends Component {
	state = {
		treinamento: {},
		titulo: "",
		descricao: "",
		id: v4(),
		tipo: "imagem",
		erro: false,
		url: "",
		error: [],
		carregando: false,
		file: null,
		preview: "",
		novo: true,
	};

	componentDidMount() {
		if (this.props.atividade) {
			var { id, titulo, descricao, url, ordem, tipo } = this.props.atividade;
			this.setState({ id, titulo, descricao, url, ordem, tipo, novo: false });
			firebase
				.storage()
				.ref()
				.child(url)
				.getDownloadURL()
				.then(url => {
					this.setState({ preview: url });
				});
		}
	}

	// fileUploadHandler = event => {
	//     var reader = new FileReader();
	//     var file = event.target.files[0];
	//     reader.onload = function (e) {
	//         this.setState({
	//             fotoEmpresa: file,
	//             logoEmpresa: e.target.result
	//         }, this.salvarImagem);
	//     }.bind(this);
	//     reader.readAsDataURL(file);
	// }

	//     function uploadImagem(e) {
	//     let reader = new FileReader();
	//     reader.onload = () => {
	//         var output = document.getElementById('output_image');
	//         output.src = reader.result;
	//     }
	//     reader.readAsDataURL(e.target.files[0]);
	// }

	/**
	 * @name handleSubmit
	 * @description envia a atividade de tipo imagem
	 * @param {Object} e evento que vem no click
	 */
	handleSubmit = async e => {
		e.preventDefault();
		this.setState({ carregando: true }, () => {
			if (this.state.descricao === "") {
				return this.setState({ carregando: false, erro: true });
			} else {
				this.setState({ erro: false });
			}
			let idTreinamento = this.state.id;
			const { salvar } = this.props;
			const salvarNobanco = idTreinamento => {
				let { titulo, descricao, url, ordem, tipo } = this.state;
				salvar({
					titulo,
					descricao,
					ordem,
					url,
					tipo,
					id: idTreinamento,
				}).catch(err => {
					this.setState({
						carregando: false,
						error: [...this.state.error, "Erro no envio"],
					});
				});
			};

			this.uploadImagem()
				.then(() => salvarNobanco(idTreinamento))
				.catch(err => {
					this.setState({
						carregando: false,
						progress: 0,
						error: [...this.state.error, "Erro no envio"],
					});
				});
		});
		// this.props.salvar()
	};

	uploadImagem = () =>
		new Promise((resolve, reject) => {
			let file = this.state.file;
			if (!file && this.state.url) {
				return resolve();
			}
			let { id } = this.state;
			this.setState({ carregando: true }, () => {
				const fileRef = storage.ref().child(`treinamentos/imagens/${id}-${file.name}`);
				let uploadTask = fileRef.put(file);

				uploadTask.then(() => {
					this.setState({ carregando: false }, () =>
						this.handleImage(
							null,
							{ value: `treinamentos/imagens/${id}-${file.name}`, name: "url" },
							resolve()
						)
					);
				});
			});
		});
	// uploadTask.on('state_changed', (snapshot) => {
	//     var progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);
	//     this.setState({ progress })
	//     switch (snapshot.state) {
	//         case firebase.storage.TaskState.PAUSED: // or 'paused'
	//             console.log('Upload is paused');
	//             break;
	//         case firebase.storage.TaskState.RUNNING: // or 'running'
	//             console.log('Upload is running');
	//             break;
	//     }
	// }, (error) => {
	//     this.setState({ error: [...this.state.error, 'Erro no envio'] })
	//     reject(error)
	// }, () => {
	//     this.handleImage(null, { value: `treinamentos/imagens/${id}-${file.name}`, name: 'url' }, () => resolve())
	// })

	handleImage = (e, { value, name }, call) => {
		this.setState({ [name]: value }, call);
	};

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleImageChange = async event => {
		let image = event.target.files[0];
		if (image) {
			try {
				const compress = await compressImage(image);
				this.setState({
					file: compress,
					preview: URL.createObjectURL(compress),
					url: `treinamentos/imagens/${this.state.id}-${compress.name}`,
				});
			} catch (e) {
				event.target.value = "";
				if (e instanceof FileCompressError) {
					console.log(e);
					toast.warning(e.message);
				}
			}
		}
	};

	/**
	 * @name handleInputDescricao
	 * @date Criado em 5/01/2021
	 * @author Vitor Andre Savian
	 * @description Define a descrição do component do sun editor
	 * @param {String} descricao string que vem do suneditor
	 */
	handleInputDescricao = descricao => {
		this.setState({ descricao: descricao });
	};

	render() {
		const BotaoCancelar = this.props.cancelar;
		const { t } = this.props;
		const { titulo, descricao, preview, url, erro } = this.state;

		return (
			<>
				<Header content="Imagem" as="h2" />
				{!_.isEmpty(this.state.error) && (
					<Message error header="Ops algo deu errado" list={this.state.error} />
				)}
				<Form onSubmit={this.handleSubmit}>
					<Form.Field>
						<label>{t("titulo")}</label>
						<input
							type="text"
							name="titulo"
							required
							placeholder="Título da atividade imagem (aparecerá com maior destaque)"
							value={titulo}
							onChange={this.handleChange}
						/>
					</Form.Field>

					<Form.Field>
						<Editor
							placeholder="Descrição da imagem (texto mais longo e detalhado)"
							label="Descrição"
							name="descricao"
							required={true}
							value={descricao}
							handleInputDescricao={this.handleInputDescricao}
						/>
					</Form.Field>

					<div className="button-wrapper">
						<div
							style={{
								width: 200,
								height: 40,
								backgroundColor: "#eee",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								borderRadius: 5,
								cursor: "pointer",
								background: "#7501c0",
								color: "#fff",
							}}>
							<span style={{ textAlign: "center" }}>
								<Icon disabled name="image" size="large" />
								{url === "" ? t("selecione.imagem") : t("selecione.outra.imagem")}
							</span>
						</div>
						<input
							accept="image/*"
							type="file"
							required={preview === "" ? true : false}
							style={{
								display: "inline-block",
								position: "absolute",
								zIndex: 1,
								width: 200,
								height: 40,
								top: 0,
								left: 0,
								opacity: 0,
								cursor: "pointer",
							}}
							className="upload-box"
							onChange={e => this.handleImageChange(e)}
						/>
					</div>
					<br />
					<Form.Field>
						{preview === "" ? null : (
							<div
								style={{
									width: "100%",
									height: 268,
									display: "flex",
									justifyContent: "center",
								}}>
								<img
									src={preview}
									alt="Pré-visualização de imagem de treinamento."
									style={{ maxWidth: "100%", maxHeight: "100%" }}
								/>
							</div>
						)}
					</Form.Field>
					{erro && (
						<Message
							negative
							content="A atividade deve ir com descrição para facilitar o entendimento."
						/>
					)}
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<BotaoCancelar />
						<Button
							positive
							type="submit"
							loading={this.state.carregando}
							disabled={this.state.carregando}>
							{this.state.novo ? t("salvar.atividade") : t("editar.atividade")}
						</Button>
					</div>
				</Form>
			</>
		);
	}
}
