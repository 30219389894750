import axios from "axios";
import Configuration from "../configuration.json";
import firebase from "firebase";
import { store } from "../_reducers/reducers";
import { LoggedUserInLog } from "../@types/Logs";
import { omitUndefinedProps } from "toolkit-extra";

const auth = firebase.auth();

interface MongodbAtlasReturn<T> {
	documents: T[];
}

export async function mongoDBAtlas<T>(
	method: string,
	collection: string,
	service: string,
	data: any
) {
	const token = await auth?.currentUser?.getIdToken(true);

	if (!token) throw new Error("Usuário não autenticado");

	const res = await axios.post<MongodbAtlasReturn<T>>(
		`${Configuration.nodeAPIURL}/atlasService`,
		{
			method,
			collection,
			service,
			dev: Configuration.dev,
			data,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	);

	return res.data;
}

export const logging = (
	acao: "CREATE" | "DELETE" | "UPDATE",
	descricao: string,
	identificador: string
) => {
	const { ipLocation, empresa, coordenadas } = store.getState().AutenticacaoReducer;
	const currentUser = auth.currentUser;

	let loggedUser: LoggedUserInLog | undefined;

	if (currentUser) {
		loggedUser = {
			email: currentUser.email || "",
			nome: currentUser.displayName || empresa.fantasia || "",
			uid: currentUser.uid,
		};
	}

	const data = omitUndefinedProps({
		document: {
			usuario: empresa?.uid,
			usuarioLogado: loggedUser,
			ip: ipLocation?.ip,
			acao,
			descricao: `${descricao} - #${identificador}`,
			coordenadas,
			data: { $date: new Date() },
		},
	});

	mongoDBAtlas("POST", "logs", "/action/insertOne", data);
};
