import { Divider } from "@material-ui/core";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Container, TextTime } from "./styles";

interface OpportunityExecutionTimesProps {
	iniciadoEm?: string;
	finalizadoEm?: string;
}

export function OpportunityExecutionTimes({
	finalizadoEm,
	iniciadoEm,
}: OpportunityExecutionTimesProps) {
	const { t } = useTranslation();

	const times = useMemo(() => {
		const diff = moment(finalizadoEm).diff(iniciadoEm);
		const horas = moment.duration(diff).hours();
		const duracao = moment.utc(diff);

		const duracaoFormatada =
			finalizadoEm && iniciadoEm
				? horas > 0
					? duracao.format("HH:mm:ss")
					: duracao.format("mm:ss")
				: null;
		const finalizadoEmFormatado = finalizadoEm ? moment(finalizadoEm).format("HH:mm:ss") : null;
		const iniciadoEmFormatado = iniciadoEm ? moment(iniciadoEm).format("HH:mm:ss") : null;

		return { finalizadoEmFormatado, iniciadoEmFormatado, duracaoFormatada };
	}, [finalizadoEm, iniciadoEm]);

	if (!times.iniciadoEmFormatado) return null;

	return (
		<Container>
			<div>
				<p>{t("iniciado-em")}:</p>
				<TextTime>{times.iniciadoEmFormatado}</TextTime>
			</div>
			<div>
				<p>{t("finalizado-em")}:</p>
				<TextTime>{times.finalizadoEmFormatado || t("em.execucao")}</TextTime>
			</div>
			{!!times.duracaoFormatada && (
				<>
					<Divider />
					<div>
						<p>{t("tempo.de.execucao")}:</p>
						<TextTime>{times.duracaoFormatada}</TextTime>
					</div>
				</>
			)}
		</Container>
	);
}
