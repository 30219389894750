import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";
import { Container, Content, Image } from "./styles";

interface ModalPlanogramProps {
    isOpen: boolean;
    image: string;
    onClose: () => void;
}

const imageDefault = "https://react.semantic-ui.com/images/wireframe/image.png";

export function ModalPlanogram({ isOpen, onClose, image }: ModalPlanogramProps) {
    const { t } = useTranslation();

    return (
        <Container open={isOpen} closeOnDimmerClick closeOnEscape>
            <Modal.Header>{t("planograma")}</Modal.Header>
            <Content image>
                <Image src={image} onError={(e) => (e.currentTarget.src = imageDefault)} alt={t("planograma")} />
            </Content>
            <Modal.Actions>
                <Button color="red" onClick={onClose} content={t("fechar")} />
            </Modal.Actions>
        </Container>
    );
}
