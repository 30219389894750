import styled from "styled-components";

export const UserBasicInfo = styled.p`
	font-size: 0.75rem;
	line-height: 1rem;
	color: #4a5568;
`;

export const UserName = styled.p`
	line-height: 1.5rem;
`;

export const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
`;
