import { useLeafletContext } from "@react-leaflet/core";
import { useEffect } from "react";
import { useController } from "react-hook-form";
import { OperationAreaFormProps } from "../..";

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Um componente que permite controlar o zoom o estado de zoom do mapa e transmitir para o `react-hook-form`.
 */
export function LeafletZoomController() {
	const { map } = useLeafletContext();
	const {
		field: { onChange },
	} = useController<OperationAreaFormProps, "zoomLevel">({
		name: "zoomLevel",
	});

	useEffect(() => {
		const handleZoomChange = () => onChange(map.getZoom());

		map.on("zoomend", handleZoomChange);

		return () => {
			map.off("zoomend", handleZoomChange);
		};
	}, [map, onChange]);

	return null;
}
