import { Th, Thead, Tr } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OrderState } from "..";
import { Iconify } from "../../../../Componentes/Iconify";

interface CostPerPromoterReportTableHeaderProps {
	order: OrderState | null;
	setOrder: Dispatch<SetStateAction<OrderState | null>>;
}

export function CostPerPromoterReportTableHeader({
	order,
	setOrder,
}: CostPerPromoterReportTableHeaderProps) {
	const { t } = useTranslation();

	const orderIcon = useMemo(() => {
		if (!order) return null;

		return order.direction === "asc" ? (
			<Iconify icon="mdi:arrow-up" />
		) : (
			<Iconify icon="mdi:arrow-down" />
		);
	}, [order]);

	const handleChangeOrder = useCallback(
		(columnName: OrderState["columnName"]) => {
			setOrder(oldState => {
				if (oldState?.columnName === columnName) {
					return {
						direction: oldState.direction === "asc" ? "desc" : "asc",
						columnName,
					};
				}
				return {
					direction: "asc",
					columnName,
				};
			});
		},
		[setOrder]
	);

	const tableHeader = useMemo(() => {
		return (
			<Thead>
				<Tr>
					<Th onClick={() => handleChangeOrder("promoter")} cursor={"pointer"}>
						{t("promotor")} / {t("agencia")}{" "}
						{order?.columnName === "promoter" && orderIcon}
					</Th>
					<Th
						onClick={() => handleChangeOrder("finishedOpportunities")}
						textAlign={"center"}
						cursor={"pointer"}>
						{t("roteiros.executados")}{" "}
						{order?.columnName === "finishedOpportunities" && orderIcon}
					</Th>
					<Th onClick={() => handleChangeOrder("total")} isNumeric cursor={"pointer"}>
						{t("valor-integral")} / {t("valor-pago-ao-promotor")}{" "}
						{order?.columnName === "total" && orderIcon}
					</Th>
				</Tr>
			</Thead>
		);
	}, [handleChangeOrder, order?.columnName, orderIcon, t]);

	return tableHeader;
}
