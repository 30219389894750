import { Avatar, Box, Center, Flex, Link, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { OpportunitiesReportTableData } from "..";
import { EmptyContentMessage } from "../../../../Componentes/EmptyContentMessage";
import { Iconify } from "../../../../Componentes/Iconify";
import { formatarValorDoInput } from "../../../../Componentes/ValorRoteiro";
import { formatCNPJ } from "../../../../Utils/CNPJ";
import { calculateEvaluationAverage } from "../../../../Utils/Number";
import { PATHS } from "../../../../Utils/Routes";
interface OpportunitiesReportTableContentProps {
	tableData: OpportunitiesReportTableData[];
}

export function CostPerPromoterReportTableContent({
	tableData,
}: OpportunitiesReportTableContentProps) {
	const { t } = useTranslation();

	const tableContent = useMemo(() => {
		if (tableData.length === 0)
			return (
				<Tr>
					<Td colSpan={8}>
						<Center minH={"container.sm"}>
							<EmptyContentMessage
								title={t("relatorio-vazio")}
								message={t("tente-alterar-os-campos-de-data-e-filtro")}
							/>
						</Center>
					</Td>
				</Tr>
			);
		return tableData.map(oportunidade => (
			<Tr key={oportunidade._id}>
				<Td>{moment(oportunidade.data).format("DD/MM/YY")}</Td>
				<Td>{formatCNPJ(oportunidade.loja.codigo)}</Td>
				<Td whiteSpace={"break-spaces"}>
					<Link
						as={RouterLink}
						to={PATHS.oportunidades.info(oportunidade._id ?? oportunidade.id)}>
						{oportunidade.loja.fantasia}
					</Link>
				</Td>
				<Td>
					<Flex gap={4} alignItems={"center"}>
						<Avatar
							src={oportunidade.usuario.foto}
							name={oportunidade.usuario.nome}
							size={"md"}></Avatar>
						<Box>
							<Text fontWeight={"bold"}>
								<Link
									as={RouterLink}
									to={PATHS.promoters.profile(oportunidade.usuario.uid)}>
									{oportunidade.usuario.nome}
								</Link>
							</Text>
							<Text fontSize="sm" color="gray.500" lineHeight={"1"}>
								{oportunidade.usuario.sexo}
								<Iconify icon="mdi:star" inline mx={1}></Iconify>
								{calculateEvaluationAverage(
									oportunidade.usuario.mediaAvaliacao,
									oportunidade.usuario.quantidadeAvaliacao
								)}
							</Text>
						</Box>
					</Flex>
				</Td>
				<Td>{moment(oportunidade.iniciadoEm).format("HH:mm")}</Td>
				<Td>{moment(oportunidade.finalizadoEm).format("HH:mm")}</Td>
				<Td>{moment.utc(Number(oportunidade.horas) * 60 * 1000).format("HH:mm")}</Td>
				<Td>
					<Text fontWeight={"bold"}>
						{formatarValorDoInput("R$", ",", oportunidade.valor)}
					</Text>
				</Td>
				{/* <Td textAlign={"center"} fontWeight={"bold"}>
					{row.finishedOpportunities}
				</Td>
				<Td isNumeric>
					<Box>
						<Text fontWeight={"bold"}>{fCurrency(row.totalCost)}</Text>

						<Text color="gray.500" fontSize={"sm"} lineHeight={"1"}>
							{fCurrency(row.totalPaidForPromoter)}
						</Text>
					</Box>
				</Td> */}
			</Tr>
		));
	}, [tableData, t]);

	return <Tbody>{tableContent}</Tbody>;
}
