import { Opportunity } from "../../../../@types/Opportunity";
import { Card } from "../../../../Componentes/Card";
import Direcionar from "../../Direcionar";
import { CandidateController } from "../CandidateController";
import { NotifyAboutOpportunityConditions } from "../NotifyAboutOpportunityConditions";

interface CandidateAreaProps {
	opportunity: Opportunity;
	bloqueioPorNotasPendentes: boolean;
}

/**
 * Renderiza um componente da área de candidatura da agência com informações pertinentes ao direcionamento de promotor.
 */
export function CandidateArea({ opportunity, bloqueioPorNotasPendentes }: CandidateAreaProps) {
	return (
		<Card h="full" gap={5} display={"flex"} flexDir="column">
			<NotifyAboutOpportunityConditions />
			{opportunity.statusAgencia ? (
				<Direcionar roteiro={opportunity} />
			) : (
				<CandidateController
					roteiro={opportunity}
					bloqueioPorNotasPendentes={bloqueioPorNotasPendentes}
				/>
			)}
		</Card>
	);
}
