import { AxiosError } from "axios";
import { CanonicalErrorCodeName } from "firebase-functions/lib/common/providers/https";
import { toast, ToastContent, ToastOptions } from "react-toastify";
import { z } from "zod";

export interface BackendApiErrorResponse<Details> {
    details: Details;
    message: string;
    status: CanonicalErrorCodeName;
    httpStatusCode: number;
}

export class BackendApiError<Details = unknown> extends Error implements BackendApiErrorResponse<Details> {
    details: Details;
    status: CanonicalErrorCodeName;
    httpStatusCode: number;

    constructor(error: unknown, toastProps?: { content?: ToastContent; options?: ToastOptions }) {
        //NOTE: Setamos os dados padronizados
        super("Erro desconhecido, por favor, tente novamente mais tarde ou entre em contato com o suporte.");

        this.httpStatusCode = 500;
        this.status = "UNKNOWN";
        this.details = {} as Details;

        this.handle(error);

        if (toastProps) {
            const { content, options } = toastProps;
            toast(content || this.message, options);
        }

        Object.setPrototypeOf(this, BackendApiError.prototype);
        console.error(this, error);
    }

    /**
     * @param error Qualquer tipo de dado.
     * @description Recebe um dado, avalia se é o esperado do backend e padronizamos sua estrutura independente do dado passado.
     */
    handle(error: unknown): BackendApiError<Details> {
        this.details = error as Details;

        //NOTE: Valida se é um erro do axios
        if (error instanceof AxiosError) {
            //NOTE: Monta um schema do que esperamos do backend
            const schema = z.object({
                error: z.object({
                    details: z.unknown(),
                    message: z.string(),
                    status: z.enum([
                        "OK",
                        "CANCELLED",
                        "UNKNOWN",
                        "INVALID_ARGUMENT",
                        "DEADLINE_EXCEEDED",
                        "NOT_FOUND",
                        "ALREADY_EXISTS",
                        "PERMISSION_DENIED",
                        "UNAUTHENTICATED",
                        "RESOURCE_EXHAUSTED",
                        "FAILED_PRECONDITION",
                        "ABORTED",
                        "OUT_OF_RANGE",
                        "UNIMPLEMENTED",
                        "INTERNAL",
                        "UNAVAILABLE",
                        "DATA_LOSS",
                    ]),
                    httpStatusCode: z.number(),
                }),
            });

            //NOTE: Setamos os dados padrões para um AxiosError
            this.httpStatusCode = error.response?.status || 500;

            //NOTE: Se tiver um dado em response, validamos se é o que esperamos e se for, setamos os dados
            if (error.response?.data) {
                const parsedError = schema.safeParse(error.response?.data);
                if (parsedError.success) {
                    this.details = parsedError.data.error.details as Details;
                    this.message = parsedError.data.error.message;
                    this.status = parsedError.data.error.status;
                    this.httpStatusCode = parsedError.data.error.httpStatusCode;
                }
            }
        }

        return this;
    }
}
