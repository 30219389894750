import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import firebase from "firebase";
import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
	Button,
	Checkbox,
	Form,
	Input,
	Loader,
	Message,
	Modal,
	Popup,
	Segment,
	Table,
} from "semantic-ui-react";
import { Avatar } from "../../Componentes/Avatar/index";
import { HeaderPadrao } from "../../Componentes/Componentes";
import Confirmar from "../../Componentes/Confirmar";
import ImagemCentralizada from "../../Componentes/ImagemCentralizada";
import { ModalPlanogram } from "../../Componentes/ModalPlanogram";
import fotoProduto from "../../imagens/produto.png";
import * as d from "../../Utils/Debugging";
import { arquivarProduto } from "../../Utils/Produto";
import { paginacaoProdutos } from "../../_nodeApi/produtos";
import {
	juntarProdutos,
	listProdutos,
	toggleAllProdutos,
	toggleProdutos,
} from "../importarProdutos/metodos-gerais";
import excluirProd from "./metodoExclusao";

let Link = "a";
if (process.env.NODE_ENV !== "test") {
	Link = require("react-router-dom").Link;
}

/**
 * @name produtosMongoDB
 * @date Criado em 7/12/2020
 * @author Vitor Savian
 * @description manda para a função que vai puxar os produtos
 * @param {String} idEmpresa IdEmpresa
 * @param {String} descricao Descrição do produto
 * @param {String} preco Preço do produto
 * @param {Object} axios axios
 * @param {Object} firebase firebase
 * @param {Number} page pagina que vai ir pro paginaitor
 * @param {Object} produtosSelecionados produtos que foram selecionados
 * @param {Object} selectAll se foi selecionado alguma pagina inteira
 * @param {Boolean} filtro se caso foi usado o filtro ou nao
 * @param {Object} retornosGeral objeto com todos os produtos
 */
const produtosMongoDB = async (
	idEmpresa,
	descricao,
	preco,
	axios,
	firebase,
	page,
	produtosSelecionados,
	selectAll,
	filtro,
	retornosGeral
) => {
	return await listProdutos(
		descricao,
		preco,
		idEmpresa,
		produtosSelecionados,
		selectAll,
		page,
		retornosGeral,
		axios,
		firebase,
		filtro,
		paginacaoProdutos
	);
};

/**
 * @name Produto
 * @date alterada em 8/12/2020
 * @author Vitor Savian
 * @description devolve a tabela dos produtos (que foram puxados da API)
 * @param {Object} props propriedades que serão usadas na função
 */
export const Produto = props => {
	const {
		t,
		handleRemove,
		toggleProdutos,
		toggleAllProdutos,
		totalDePaginas,
		produtosSelecionados,
		handleChangePage,
		products,
		page,
		verPlanograma,
	} = props;

	let selectAll = props.selectAll[props.page] ? props.selectAll[props.page] : false;
	const docs = products;

	if (props.load) {
		return (
			<Loader style={{ marginTop: 40 }} active inline="centered">
				Carregando...
			</Loader>
		);
	}

	return (
		<Segment basic style={{ margin: 0, padding: 0 }}>
			{_.isEmpty(docs) ||
			docs === undefined ||
			docs === null ||
			props.semProdutos === true ? (
				<Message content={t("mensagem.sem.registros")}></Message>
			) : (
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Popup
								content={"Selecionar todos para exclusão"}
								trigger={
									<Table.HeaderCell>
										<Checkbox
											checked={selectAll}
											onChange={() => toggleAllProdutos()}
										/>
									</Table.HeaderCell>
								}
							/>
							<Table.HeaderCell textAlign="center">{t("foto")}</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">{t("descricao")}</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">{t("codigo")}</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">
								{t("preco.sugerido")}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">
								{t("planograma")}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">{t("acoes")}</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{docs.map((produto, index) => {
							if (produto._id !== undefined) {
								produto.id = produto._id;
								delete produto._id;
								delete produto.__v;
							}
							let produtoCheck = produtosSelecionados[page]
								? produtosSelecionados[page]
								: { _id: false };
							return (
								<Table.Row key={index}>
									<Table.Cell collapsing>
										<Checkbox
											checked={produtoCheck[produto.id] ?? false}
											onChange={() => toggleProdutos(produto.id)}
										/>
									</Table.Cell>
									<Table.Cell>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Avatar
												src={
													produto.foto !== "" && produto.foto
														? `${produto.foto}`
														: fotoProduto
												}
											/>
										</div>
									</Table.Cell>
									<Table.Cell textAlign="center">{produto.descricao}</Table.Cell>
									<Table.Cell textAlign="center">{produto.codigo}</Table.Cell>
									<Table.Cell textAlign="center">
										{(produto.preco
											? parseFloat(produto.preco)
											: ""
										).toLocaleString("BRL", {
											style: "currency",
											currency: "BRL",
										})}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{produto.planograma !== "" && produto.planograma && (
											<Button
												circular
												basic
												color="purple"
												icon="file alternate"
												onClick={() => {
													verPlanograma(produto.planograma);
												}}
											/>
										)}
									</Table.Cell>
									<Table.Cell textAlign="center">
										<Link
											to={{
												pathname: `/produto/${produto.id}`,
												state: { produto },
											}}
											style={{ color: "#333", marginRight: 5 }}>
											<Button circular basic color="violet" icon="edit" />
										</Link>
										<Button
											circular
											basic
											color="violet"
											icon="trash"
											onClick={() => handleRemove(produto)}
										/>
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row textAlign={"center"}>
							<Table.HeaderCell colSpan="7">
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										width: "100%",
									}}>
									<Pagination
										shape="rounded"
										count={totalDePaginas}
										page={page}
										onChange={handleChangePage}
									/>
								</div>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			)}
		</Segment>
	);
};

/**
 * @name Filtro
 * @date alterado em 8/12/2020
 * @author Vitor Savian
 * @description Devolve o JSX que contem o filtro
 * @param {Object} props props que serão usadas na função
 */
const Filtro = props => {
	return (
		<div>
			<Form
				info="true"
				style={{
					marginTop: "15px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
				unstackable>
				<Form.Group widths="equal" style={{ width: "100%" }}>
					<Form.Field
						style={{ marginBottom: 5 }}
						fluid={true}
						id="form-input-control-last-name"
						value={props.state.descricao}
						control={Input}
						placeholder={"Código ou Descrição"}
						onChange={e => props.handleChangeState(e, "descricao")}
					/>
					<Form.Field
						style={{ marginBottom: 5 }}
						fluid={true}
						id="form-input-control-last-name"
						value={props.state.preco}
						control={Input}
						placeholder={"Preço Sugerido"}
						onChange={e => props.handleChangeState(e, "preco")}
					/>
					<Form.Field
						style={{ marginBottom: 5 }}
						fluid={true}
						disabled={props.validarCamposFiltro()}
						icon="search"
						id="form-button-control-public"
						control={Button}
						loading={props.state.load}
						content="Buscar"
						onClick={e => props.handleFilter()}
					/>
					<Form.Field
						fluid={true}
						style={{ backgroundColor: "#7501c0", color: "#fff", marginBottom: 5 }}
						icon="times"
						id="form-button-control-public"
						control={Button}
						loading={props.state.load}
						content="Limpar Filtro"
						onClick={e => props.handleCleanFilter()}
					/>
					{props.botaoDelete && (
						<Form.Field
							fluid={true}
							color="purple"
							id="form-button-control-public"
							icon="trash alternate outline"
							style={{ backgroundColor: "#662780", color: "#fff", marginBottom: 5 }}
							content={
								props.pluralProd > 1
									? "Excluir Selecionados"
									: "Excluir Selecionado"
							}
							control={Button}
							loading={props.state.load}
							onClick={() => props.handleChangeStateButton()}
						/>
					)}
				</Form.Group>
			</Form>
		</div>
	);
};

/**
 * @name ProdutoContainer
 * @date Alterada em 8/12/2020
 * @author Vitor Savian
 * @description Class do react que vai juntar, manipular
 * e trazer o JSX da tela de produtos
 */
export class ProdutoContainer extends Component {
	state = {
		planograma: null,
		produtosSelecionados: {
			1: {},
		},
		selectAll: {
			1: false,
		},
		semProdutos: false,
		botaoConfirmarDisabled: false,
		modalExclusao: false,
		retornosGeral: {},
		products: null,
		descricao: null,
		preco: null,
		botaoDelete: false,
		colunaOrdenada: "codigo",
		activePage: 1,
		totalDePaginas: 1,
		load: false,
		error: true,
	};

	/**
	 * @name toggleProdutos
	 * @date Criado em 15/12/2020
	 * @author Vitor Savian
	 * @description faz a seleção deu um produtos específico
	 */
	toggleProdutos = _id => {
		let novos = toggleProdutos(_id, this.state.activePage, this.state.produtosSelecionados);
		this.setState({
			produtosSelecionados: novos,
			botaoDelete: true,
		});
	};

	/**
	 * @name handleChangeStateButton
	 * @date Criado em 16/12/2020
	 * @author Vitor Savian
	 * @description apenas aciona o modal dentro
	 * da função
	 */
	handleChangeStateButton = () => {
		this.setState({ modalExclusao: true });
	};

	/**
	 * @name toggleAllProdutos
	 * @date Criado em 15/12/2020
	 * @author Vitor Savian
	 * @description faz a seleção de todos os produtos da pagina atual
	 */
	toggleAllProdutos = () => {
		let selectAll = !(this.state.selectAll[this.state.activePage]
			? this.state.selectAll[this.state.activePage]
			: false);
		this.setState(
			{ selectAll: { ...this.state.selectAll, [this.state.activePage]: selectAll } },
			() => {
				let selecionados = toggleAllProdutos(
					selectAll,
					this.state.produtosSelecionados,
					this.state.activePage
				);
				this.setState({
					produtosSelecionados: selecionados,
					botaoDelete: true,
				});
			}
		);
	};

	/**
	 * @name handleChangePage
	 * @date Criado em 8/12/2020
	 * @author Vitor Savian
	 * @description faz a mudança e a requisição para
	 * @param {Object} e evento
	 * @param {Number} page fornece a página que irá mudar
	 */
	handleChangePage = async (e, page) => {
		await produtosMongoDB(
			this.props.idEmpresa,
			this.state.descricao,
			this.state.preco,
			axios,
			firebase,
			page,
			this.state.produtosSelecionados,
			this.state.selectAll,
			false,
			this.state.retornosGeral
		)
			.then(result => {
				this.setState({ ...result });
			})
			.catch(() => {
				this.setState({ semProdutos: true });
			});
	};

	/**
	 * @name handleFilter
	 * @date Criado em 8/12/2020
	 * @author Vitor Savian
	 * @description gerencia o filtro para que seja feito
	 * corretamente a requisição
	 */
	handleFilter = () => {
		let descricao = this.state.descricao === "" ? null : this.state.descricao;
		let preco = this.state.preco === "" ? null : this.state.preco;
		this.setState({ load: true }, async () => {
			await produtosMongoDB(
				this.props.idEmpresa,
				descricao,
				preco,
				axios,
				firebase,
				1,
				this.state.produtosSelecionados,
				this.state.selectAll,
				true,
				this.state.retornosGeral
			)
				.then(result => {
					this.setState({ ...result, load: false });
				})
				.catch(err => {
					this.setState({ semProdutos: true, load: false, descricao: "", preco: "" });
				});
		});
	};

	/**
	 * @name handleChangeState
	 * @date Criado em 8/12/2020
	 * @author Vitor Savian
	 * @description gerencia a mudança dos estados
	 * de preço e descrição principalmente, porém serve
	 * para todos os estados que utilizam um Input >:o
	 * @param {Object} e Objeto do input
	 * @param {String} state representa qual state será mudado
	 */
	handleChangeState = (e, state) => {
		this.setState({
			[state]: e.target.value,
		});
	};

	/**
	 * @name handleCleanFilter
	 * @date Criado em 8/12/2020
	 * @author Vitor Savian
	 * @description faz com que os
	 * os filtros sejam limpos e re-faz a requisiçãp
	 * para que os itens sejam limpados de qualquer
	 * filtro >:v
	 */
	handleCleanFilter = () => {
		this.setState(
			{
				load: true,
			},
			async () => {
				await produtosMongoDB(
					this.props.idEmpresa,
					null,
					null,
					axios,
					firebase,
					1,
					this.state.produtosSelecionados,
					this.state.selectAll,
					false,
					this.state.retornosGeral
				)
					.then(result => {
						this.setState({
							...result,
							descricao: "",
							preco: "",
							activePage: 1,
							load: false,
						});
					})
					.catch(() => {
						this.setState({ semProdutos: true, descricao: "", preco: "", load: false });
					});
			}
		);
	};

	componentDidMount() {
		this.state.products === null &&
			this.setState(
				{
					load: true,
				},
				async () => {
					await produtosMongoDB(
						this.props.idEmpresa,
						this.state.descricao,
						this.state.preco,
						axios,
						firebase,
						1,
						this.state.produtosSelecionados,
						this.state.selectAll,
						true,
						this.state.retornosGeral
					)
						.then(result => {
							this.setState({ ...result, load: false });
						})
						.then(async () => {
							if (this.props.history.location.state !== undefined) {
								const merge = await this.handleEditState(
									this.props.history.location.state.produto,
									this.state.products
								);

								this.setState({ products: merge });
							}
						})
						.catch(() => {
							this.setState({ semProdutos: true, load: false });
						});
				}
			);
	}

	/**
	 * @name handleRemove
	 * @date Alterado em 8/12/2020
	 * @author Vitor Savian
	 * @description remove o produto que foi selecionado ( no firebase )
	 * A irá ver que o item está como arquivado true, sendo assim não vai
	 * joga-lo na tabela.
	 * @param {Object} produto informaçoes do produto a ser removido
	 */
	handleRemove = produto => {
		const { id, descricao, idEmpresa } = produto;
		const ok = confirm(`Deseja remover o produto: ${descricao}?`); // eslint-disable-line no-restricted-globals
		if (ok) {
			arquivarProduto(id).catch(err => console.error(err));
			let prod = _.find(this.state.products, produto => {
				return produto.id === id;
			});
			let withoutProd = _.pull(this.state.products, prod);
			this.setState({ products: withoutProd });
		}
	};

	/**
	 * @name verPlanograma
	 * @date Alterado em 8/12/2020
	 * @description prepara o state que vai acionar
	 * o model do planograma e principalmente prepara
	 * a imagem ou o PDF para ser mostrado.
	 * @param {String} image IdEmpresa
	 */
	verPlanograma = image => {
		this.setState({
			planograma: image,
		});
	};

	/**
	 * @name closePlanograma
	 * @date Alterado em 8/12/2020
	 * @description Apenas fecha o model do planograma
	 */
	closePlanograma = () => this.setState({ planograma: null });

	/**
	 * @name validarCamposFiltro
	 * @date Alterado em 8/12/2020
	 * @author Vitor Savian
	 * @description valida os campos para que sempre tenha algo
	 * para que o botão possa ser filtrado
	 */
	validarCamposFiltro = () => {
		if (
			(this.state.descricao !== "" && this.state.descricao !== null) ||
			(this.state.preco !== "" && this.state.preco !== null)
		) {
			return false;
		} else {
			return true;
		}
	};

	/**
	 * @name renderProdutosParaExclusao
	 * @date Criado em 15/12/2020
	 * @author Vitor Andre Savian
	 * @description Cuida da renderização dos produtos
	 * que serão excluidos se caso for confirmado
	 */
	renderProdutosParaExclusao = () => {
		let produtos = juntarProdutos(this.state.produtosSelecionados, this.state.retornosGeral);
		let { t } = this.props;
		return (
			<>
				<div
					style={{ width: "100%", textAlign: "center", marginTop: 10, marginBottom: 25 }}>
					<span style={{ lineHeight: 1 }}>
						Verifique os produtos antes de confirmar a exclusão
					</span>
				</div>
				<div style={{ overflowY: "scroll", height: 500 }}>
					{produtos.length > 0 ? (
						<Table celled compact definition>
							<Table.Header fullWidth>
								<Table.Row>
									<Table.HeaderCell>{t("foto")}</Table.HeaderCell>
									<Table.HeaderCell>{t("descricao")}</Table.HeaderCell>
									<Table.HeaderCell>{t("codigo.barras")}</Table.HeaderCell>
									<Table.HeaderCell>{t("preco")}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>
								{produtos.map((produto, index) => {
									return (
										<Table.Row key={index}>
											<Table.Cell style={{ width: 60 }}>
												<ImagemCentralizada
													circular
													onClick={() => {}}
													src={
														produto.foto !== "" && produto.foto
															? `${produto.foto}`
															: fotoProduto
													}
												/>
											</Table.Cell>
											<Table.Cell>{produto.descricao}</Table.Cell>
											<Table.Cell>{produto.codigo}</Table.Cell>
											<Table.Cell>
												{(produto.preco
													? parseFloat(produto.preco)
													: ""
												).toLocaleString("BRL", {
													style: "currency",
													currency: "BRL",
												})}
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					) : (
						<Message>
							<Message.Header>
								Não foi selecionado nenhum produto para exclusão
							</Message.Header>
							<p>
								Por favor, volte e selecione os produtos desejados para a exclusão
								usando o checkbox
							</p>
						</Message>
					)}
				</div>
			</>
		);
	};

	/**
	 * @name handleEditState
	 * @date Criado em 8/12/2020
	 * @author Vitor Savian
	 * @description Arruma o state
	 * quando algum produto é editado
	 */
	handleEditState = (produtoEditado, arrayDeProdutosAnteriores) => {
		if (arrayDeProdutosAnteriores) {
			let index = _.findIndex(arrayDeProdutosAnteriores, produto => {
				return produto.id === produtoEditado.id;
			});

			let merge = [...this.state.products];
			merge[index] = {
				...produtoEditado,
			};
			return merge;
		}
	};

	/**
	 * @name excluirProdutos
	 * @date Criado em 15/12/2020
	 * @author Vitor Savian
	 * @description junta os produtos e os manda pra exclusao
	 */
	excluirProdutos = async () => {
		let produtos = juntarProdutos(this.state.produtosSelecionados, this.state.retornosGeral);
		excluirProd(produtos, firebase, this.props.empresa, this.props.ipLocation);
		this.setState({
			load: true,
		});
		await produtosMongoDB(
			this.props.idEmpresa,
			null,
			null,
			axios,
			firebase,
			1,
			this.state.produtosSelecionados,
			this.state.selectAll,
			true,
			this.state.retornosGeral
		)
			.then(result => {
				this.setState({ ...result, load: false, descricao: "", preco: "" });
			})
			.catch(err => {
				this.setState({ semProdutos: true, load: false, descricao: "", preco: "" });
			});
	};

	render() {
		const { t } = this.props;

		let produtos = juntarProdutos(this.state.produtosSelecionados, this.state.retornosGeral);
		return (
			<div>
				<Confirmar
					callback={() =>
						this.setState({ confirmar: false }, () => this.excluirProdutos())
					}
					open={this.state.confirmar}
					onClose={() => this.setState({ confirmar: false })}
					cancelar={t("cancelar")}
					confirmar={t("prosseguir")}
					mensagem={"Tem certeza que deseja excluir esses produtos?"}
					titulo={"Exclusão de produtos"}
				/>
				<div
					className="container-header-padrao"
					style={{ justifyContent: "space-between" }}>
					<div>
						<Button
							style={{ marginTop: "10px" }}
							className="purple-gradient-87"
							icon="plus"
							color="violet"
							floated="right"
							as={Link}
							to="/produto/novo"
							content={t("novo.produto")}></Button>
					</div>
					<div style={{ width: "50%" }}>
						<HeaderPadrao
							icone="boxes"
							titulo={t("produtos")}
							subtitulo={t("seus.produtos")}
						/>
					</div>
				</div>
				<ul style={{ marginTop: 15, color: "#A333C8" }}>
					<li>{t("apenas.20.produtos")}</li>
				</ul>
				<Filtro
					state={this.state}
					handleCleanFilter={this.handleCleanFilter}
					handleFilter={this.handleFilter}
					handleChangeState={this.handleChangeState}
					pluralProd={produtos.length}
					handleChangeStateButton={this.handleChangeStateButton}
					botaoDelete={produtos.length > 0 ? true : false}
					validarCamposFiltro={this.validarCamposFiltro}
				/>
				<Produto
					{...this.props}
					{...this.state}
					semProdutos={this.state.semProdutos}
					handleChangePage={this.handleChangePage}
					handleRemove={this.handleRemove}
					products={this.state.products}
					page={this.state.activePage}
					toggleAllProdutos={this.toggleAllProdutos}
					selectAll={this.state.selectAll}
					produtosSelecionados={this.state.produtosSelecionados}
					totalDePaginas={this.state.totalDePaginas}
					toggleProdutos={this.toggleProdutos}
					verPlanograma={this.verPlanograma.bind(this)}
				/>

				<Modal
					height={700}
					onClose={() => this.setState({ modalExclusao: false })}
					open={this.state.modalExclusao}
					header="Excluir produtos"
					content={this.renderProdutosParaExclusao}
					actions={[
						{
							key: "done",
							style: {
								backgroundColor: "#F9FAFB",
								border: "1px solid #602579",
								color: "#602579",
								cursor: "pointer",
							},
							content: "Cancelar",
							positive: false,
							onClick: () => {
								this.setState({ confirmar: false, botaoConfirmarDisabled: false });
							},
						},
						{
							key: "done",
							style: {
								backgroundColor: "#F9FAFB",
								border: "1px solid #602579",
								color: "#602579",
								cursor: "pointer",
							},
							disabled: this.state.botaoConfirmarDisabled,
							content: "Confirmar",
							positive: true,
							onClick: () => this.setState({ confirmar: true }),
						},
					]}
				/>
				<ModalPlanogram
					isOpen={this.state.planograma != null}
					image={this.state.planograma}
					onClose={this.closePlanograma.bind(this)}
				/>
			</div>
		);
	}
}

const mapStateToProps = d.logState("Marca", state => ({
	ipLocation: state.AutenticacaoReducer.ipLocation,
	idEmpresa: state.AutenticacaoReducer.empresa.uid,
	empresa: state.AutenticacaoReducer.empresa,
	produtos: state.RecursosReducer.marcas,
}));

export default connect(mapStateToProps)(withTranslation()(ProdutoContainer));
