import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Divider,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { omitProp } from "toolkit-extra";
import { validatePhone } from "validations-br";
import { z } from "zod";
import { CompanyUser } from "../../@types/User";
import { CELLPHONE_REGEX } from "../../constants/regex";
import { BackendApiError } from "../../errors/BackendApi.error";
import { CompanyUserService } from "../../services/firebase/CompanyUser";
import { InputForm } from "../Form/chakraUI/InputForm";
import { SwitchForm } from "../Form/SwitchForm";
import { Iconify } from "../Iconify";

interface UpsertUserDrawerProps {
	userToEdit?: CompanyUser;
	isOpen?: boolean;
	onClose?: () => void;
	onOpen?: () => void;
	hideTrigger?: boolean;
}

const UPSERT_USER_FORM_SCHEMA = z.object({
	name: z
		.string()
		.min(3, "Nome deve ter pelo menos 3 caracteres")
		.max(50, "Nome deve ter no máximo 100 caracteres"),
	email: z.string().email("Email inválido"),
	cellphone: z
		.string()
		.refine(
			cellphone =>
				CELLPHONE_REGEX.test(cellphone) && validatePhone(cellphone.replace(/\+55 /g, "")),
			"Celular inválido"
		),
	isActive: z.boolean(),
});

type UpsertUserFormProps = z.infer<typeof UPSERT_USER_FORM_SCHEMA>;

export function UpsertUserDrawer({ userToEdit, hideTrigger, ...props }: UpsertUserDrawerProps) {
	const { t } = useTranslation();
	const localDrawerController = useDisclosure();

	const { isOpen, onClose, onOpen } = useMemo(() => {
		return {
			isOpen: props.isOpen ?? localDrawerController.isOpen,
			onClose: props.onClose ?? localDrawerController.onClose,
			onOpen: props.onOpen ?? localDrawerController.onOpen,
		};
	}, [
		localDrawerController.isOpen,
		localDrawerController.onClose,
		localDrawerController.onOpen,
		props.isOpen,
		props.onClose,
		props.onOpen,
	]);

	const {
		handleSubmit,
		control,
		reset,
		formState: { isSubmitting },
	} = useForm<UpsertUserFormProps>({
		resolver: zodResolver(UPSERT_USER_FORM_SCHEMA),
		defaultValues: {
			name: userToEdit?.nome || "",
			email: userToEdit?.email || "",
			cellphone: userToEdit?.telefone || "",
			isActive: true,
		},
	});

	/**
	 * Determina se é ou não um formulário de edição
	 */
	const isEditForm = useMemo(() => !!userToEdit, [userToEdit]);

	const isDrawerOpen = useMemo(() => isSubmitting || isOpen, [isOpen, isSubmitting]);

	const onSubmit: SubmitHandler<UpsertUserFormProps> = async data => {
		try {
			if (isEditForm && userToEdit) {
				const dataToUpdate = omitProp(
					{
						id: userToEdit.uid,
						...data,
					},
					["isActive"]
				);

				await CompanyUserService.getInstance().patchOne(dataToUpdate);

				toast.success(t("usuario-atualizado-com-sucesso"));
				reset(data);
			} else {
				await CompanyUserService.getInstance().createOne(data);

				toast.success(t("usuario-criado-com-sucesso"));
			}

			onClose();
		} catch (error) {
			const apiError = new BackendApiError(error);

			if (apiError.httpStatusCode >= 500) {
				toast.error(
					t(
						"erro-ao-processar-a-requisicao-por-favor-tente-novamente-mais-tarde-ou-entre-em-contato-com-o-suporte"
					)
				);
			} else {
				toast.warning(apiError.message);
			}
		}
	};

	useEffect(() => {
		isDrawerOpen === false && reset();
	}, [isDrawerOpen, reset]);

	return (
		<>
			{!hideTrigger && (
				<Button
					onClick={onOpen}
					leftIcon={<Iconify icon="mdi:plus" boxSize={6} />}
					w={{ md: "fit-content", base: "100%" }}>
					{t("novo.usuario")}
				</Button>
			)}

			<Drawer isOpen={isDrawerOpen} placement="right" onClose={onClose} size="lg">
				<DrawerOverlay />
				<form onSubmit={handleSubmit(onSubmit)}>
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader>
							{isEditForm ? "Editar usuário" : "Criar usuário"}
						</DrawerHeader>
						<Divider />
						<DrawerBody display={"flex"} flexDir="column" gap={4} mt={4}>
							<InputForm
								control={control}
								name="name"
								label="Nome"
								isRequired
								placeholder="João da Silva"
								autoFocus
							/>
							<InputForm
								control={control}
								name="email"
								label="Email"
								isRequired
								placeholder="joao@gmail.com"
							/>
							<InputForm
								control={control}
								name="cellphone"
								label="Celular"
								isRequired
								patternProps={{
									format: "+55 (##) #####-####",
									allowEmptyFormatting: true,
									mask: "_",
								}}
							/>

							{!isEditForm && (
								<SwitchForm control={control} name="isActive" label="Ativo" />
							)}
							{!isEditForm && (
								<Alert rounded={"md"} status="warning" overflow={"visible"}>
									<Box>
										<AlertTitle display={"flex"} alignItems={"center"}>
											<AlertIcon boxSize={"4"} />
											{t("a.senha.usuario.sera.enviada")}.
										</AlertTitle>
										<AlertDescription fontSize="sm">
											{t("informacao-senha-usuario-novo")}
										</AlertDescription>
									</Box>
								</Alert>
							)}
						</DrawerBody>
						<Divider />
						<DrawerFooter p={4} flexDir="row" as="div">
							<Button
								variant="outline"
								mr={3}
								onClick={onClose}
								colorScheme="red"
								isDisabled={isSubmitting}>
								Cancelar
							</Button>
							<Button type="submit" isLoading={isSubmitting}>
								{isEditForm ? "Editar" : "Criar"}
							</Button>
						</DrawerFooter>
					</DrawerContent>
				</form>
			</Drawer>
		</>
	);
}
