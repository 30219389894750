import { orderBy } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Rating } from "semantic-ui-react";
import { Container, HeaderText } from "./styles";

interface Company {
    fantasia?: string;
}

interface Assessment {
    id: string;
    empresa: Company;
    avaliacao: number;
    comentario: string;
    data: Date;
}

interface AssessmentsProps {
    data: Assessment[];
}

export function Assessments({ data }: AssessmentsProps) {
    const { t } = useTranslation();

    const sortedData = useMemo(() => orderBy(data, ["data"], ["desc"]), [data]);

    return (
        <Container>
            <HeaderText>{t("avaliacoes.recentes")}</HeaderText>

            {sortedData.map((assessment) => {
                const { avaliacao, comentario, data, empresa, id } = assessment;
                const temComentario = comentario != null;
                const columnClassName = temComentario ? "" : "sem-comentario";

                return (
                    <Grid stackable key={id} style={{ borderBottom: "1px solid #eee" }}>
                        <Grid.Column className={columnClassName} width="11" textAlign="left">
                            <span
                                style={{
                                    fontStyle: "italic",
                                    color: "#999",
                                }}>
                                {temComentario ? `"${comentario}"` : t("sem.comentario")}
                            </span>
                            <br />
                            <span style={{ fontStyle: "italic" }}>{`- ${
                                empresa?.fantasia ? empresa.fantasia : ""
                            }`}</span>
                        </Grid.Column>
                        <Grid.Column className={columnClassName} width="5" textAlign="right">
                            <Rating maxRating={5} defaultRating={avaliacao} disabled />
                            <br />
                            {moment(data).format("DD/MM/YYYY")}
                        </Grid.Column>
                    </Grid>
                );
            })}
        </Container>
    );
}
