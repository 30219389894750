import { Container, Flex, Image, Link } from "@chakra-ui/react";
import { ReactNode } from "react";

interface UnauthenticatedPageLayoutProps {
	children: ReactNode;
	hideLogo?: boolean;
}

export default function UnauthenticatedPageLayout({
	children,
	hideLogo,
}: UnauthenticatedPageLayoutProps) {
	return (
		<Flex h="100vh">
			<Container
				flex={1}
				maxW={"container.lg"}
				display={"flex"}
				flexDir={"column"}
				alignItems={"center"}
				justifyContent={"space-between"}
				gap={10}>
				<Image src={"/imagens/login-rectangle.webp"} className="fundo" />
				<Flex w="full" flexDir={"column"} justifyContent="center" alignItems={"center"}>
					{!hideLogo && (
						<Link
							mb={5}
							href="https://promotorfreelance.com.br"
							rel="noreferrer"
							alignSelf={"center"}>
							<Image
								src={"/imagens/logo-full.svg"}
								alt="logo"
								w={{ base: "2xs", sm: "xs" }}
							/>
						</Link>
					)}
					{children}
				</Flex>
				<Flex as="footer" justifyContent={"center"} bg="inherit" p={0} m={0}>
					<Image
						src={"/imagens/login-rectangle.webp"}
						transform={"rotate(180deg) scaleX(-1)"}
					/>
				</Flex>
			</Container>
			<Image src={"/imagens/login-lateral-image.webp"} h={{ base: "0", "2xl": "auto" }} />
		</Flex>
	);
}
