import { MuiThemeProvider } from "@material-ui/core/styles";
import { Component } from "react";
import { Provider as ReduxProvider } from "react-redux";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "animate.css";
import Aplicacao from "./Aplicacao";
import muiTheme from "./theme/@mui";
import LocaleDef from "./Utils/LocaleDef";
import { store } from "./_reducers/reducers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ReactQueryProvider } from "./providers/ReactQueryProvider";
import { FirebaseUnsubscribeFunctionsProvider } from "./contexts/FirebaseUnsubscribeFunctionsContext";

class App extends Component {
	constructor(props) {
		super(props);
		this.localeMomentInit();
	}

	localeMomentInit() {
		return moment.updateLocale("pt-br", LocaleDef.ptBR);
	}

	render() {
		return (
			<ReduxProvider store={store}>
				<FirebaseUnsubscribeFunctionsProvider>
					<MuiThemeProvider theme={muiTheme}>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<ReactQueryProvider>
								<Aplicacao />
								<ToastContainer />
							</ReactQueryProvider>
						</MuiPickersUtilsProvider>
					</MuiThemeProvider>
				</FirebaseUnsubscribeFunctionsProvider>
			</ReduxProvider>
		);
	}
}

export default App;
