import { Icon } from "@iconify/react";
import { Button, Image } from "semantic-ui-react";
import styled from "styled-components";
import PixLogoSVG from "../../../../imagens/pix-full.svg";

export const Container = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 18px;
`;

export const DatePickerLabel = styled.h4`
	font-weight: bold;
	margin: 0;
`;

export const TextTooltip = styled.p`
	color: #468498;
`;

export const PixLogo = styled(Image).attrs({ src: PixLogoSVG })`
	width: 148px;
`;

export const OptionIcon = styled(Icon).attrs({ inline: true })`
	width: 30px;
	height: 30px;
`;

export const SubmitButton = styled(Button).attrs({
	color: "violet",
	type: "button",
})`
	display: flex !important;
	gap: 4px !important;
	align-items: center !important;
	justify-content: center !important;
	font-weight: 500 !important;
	min-width: 148px;
`;
