/**
 * Define um mapeamento para as rotas da aplicação;
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export const PATHS = {
	dashboard: {
		root: "/dashboard",
	},
	areaDeAtuacao: {
		root: "/area-de-atuacao",
		create: "/area-de-atuacao/criar",
		editar: (id: string) => `/area-de-atuacao/editar/${id}`,
	},
	redes: {
		root: "/redes",
	},
	pdv: {
		root: "/loja",
		create: "/loja/novo",
		editar: (id: string) => `/loja/${id}`,
	},
	oportunidades: {
		buscar: (id: string) => `/buscar/${id}`,
		info: (id: string) => `/oportunidade/info/${id}`,
	},
	unauthenticated: {
		register: "/registro",
		forgotPassword: "/esqueci-minha-senha",
		login: "/login",
	},
	usuarios: {
		root: "/usuarios",
	},
	promoters: {
		profile: (id: string) => `/perfil/${id}`,
	},
};
