import _, { includes } from "lodash";
import { useMemo } from "react";
import ReactCardFlip from "react-card-flip";
import { withTranslation } from "react-i18next";
import { Button, Card, Grid, Icon, Label, Popup } from "semantic-ui-react";
import { db } from "../../config";
import { maximumNumberOfHoursWorkedPerWeekPerCompany } from "../../rules/Promoter.rules";
import ImagemCentralizada from "../ImagemCentralizada";
import Link from "../Link";
import { LabelWrapper } from "./styles";
import moment from "moment";

const CardPromoter = props => {
	const sumWorkTime = useMemo(
		() => props?.workTime?.duration?.clone().add(props?.pendingWorkTime.duration),
		[props?.workTime, props?.pendingWorkTime]
	);

	const isReleaseToWork = useMemo(() => {
		if (sumWorkTime?.hours() && props?.user?.sexo !== "Agência") {
			const daysInHours = sumWorkTime.days() * 24;
			return sumWorkTime.hours() + daysInHours < maximumNumberOfHoursWorkedPerWeekPerCompany;
		} else {
			return true;
		}
	}, [sumWorkTime, maximumNumberOfHoursWorkedPerWeekPerCompany]);

	const toggleFavorito = (ref, flag, data) => (!flag ? ref.set(data) : ref.delete());
	const toggleBloqueado = (ref, flag, data) => (!flag ? ref.set(data) : ref.delete());

	function desfavoritar() {
		const idEmpresa = props.empresa.uid;
		const { user, callbackRemove, index, favorito } = props;

		const ref = db.collection("empresas").doc(idEmpresa).collection("favoritos").doc(user.uid);
		toggleFavorito(ref, favorito, { ...user, idEmpresa: idEmpresa }).then(() =>
			callbackRemove(index)
		);
	}

	function desbloquear() {
		const idEmpresa = props.empresa.uid;
		const { user, callbackRemove, index, bloqueado } = props;

		const ref = db.collection("empresas").doc(idEmpresa).collection("bloqueados").doc(user.uid);
		toggleBloqueado(ref, bloqueado, { ...user, idEmpresa: idEmpresa }).then(() =>
			callbackRemove(index)
		);
	}

	const { user, t, verNotas, botaoDesfavoritar, botaoDesbloquear, roteiro } = props;
	const { treinamentos } = user;
	var treinamentosRoteiro = roteiro?.treinamentos?.map(t => t.id);
	var chavesRoteiro =
		treinamentos &&
		Object.keys(treinamentos).filter(chave => includes(treinamentosRoteiro, chave));

	if (!_.isEmpty(treinamentos)) {
		var chaves = Object.keys(treinamentos);
		var notaTreinamentos = _.reduce(
			treinamentos,
			(soma, treinamentos) => {
				let aux = parseFloat(treinamentos.nota);
				return soma + aux;
			},
			0
		);
		notaTreinamentos = notaTreinamentos / chaves.length;
	}

	return (
		<ReactCardFlip isFlipped={verNotas} flipDirection="horizontal">
			<div style={{ opacity: isReleaseToWork ? 1 : 0.7 }}>
				<Card
					fluid
					key={user.uid}
					disabled={props.disabled}
					style={{ borderRadius: 10, overflow: "hidden" }}
					className="card-aprovar-candidato">
					<div className="card-aprovar-candidato-hover">
						{!props.esconderEscolher &&
							(isReleaseToWork ? (
								<div
									className="card-aprovar-candidato-check"
									onClick={props.onClick}
								/>
							) : (
								<LabelWrapper>
									<Popup
										content={t("promotor.atingiu.o.limite.de.horas.semanais")}
										trigger={
											<Label color="red">{t("selecao.indisponivel")}</Label>
										}
									/>
								</LabelWrapper>
							))}

						{botaoDesfavoritar && (
							<Button
								onClick={desfavoritar}
								color={"grey"}
								className="card-favoritar-candidato-hover-perfil-botao">
								<Button.Content>
									<Icon name="heart" /> {t("remover.favorito")}
								</Button.Content>
							</Button>
						)}

						{botaoDesbloquear && (
							<Button
								onClick={desbloquear}
								color={"grey"}
								className="card-favoritar-candidato-hover-perfil-botao">
								<Button.Content>
									<Icon name="ban" /> {t("desbloquear")}
								</Button.Content>
							</Button>
						)}

						<Button
							icon="user"
							content="Ver perfil"
							className="card-aprovar-candidato-hover-perfil-botao"
							color="violet"
							as={Link}
							to={"/perfil/" + user.uid}></Button>
					</div>
					{props.favorito ? (
						<Label
							corner="right"
							icon="heart"
							style={{ borderColor: "#662780", color: "#fafafa" }}
						/>
					) : (
						""
					)}
					{props.bloqueado ? (
						<Label
							corner="right"
							icon="ban"
							style={{ borderColor: "red", color: "white" }}
						/>
					) : (
						""
					)}
					<Card.Content textAlign="center">
						<ImagemCentralizada circular src={user.foto} />
						<Card.Header
							style={{
								textTransform: "capitalize",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								marginTop: 14,
							}}>
							{user.nome}
						</Card.Header>
						<Card.Meta>
							{user.sexo} <br />
							{user?.nivelExperiencia && `Experiência: ${user.nivelExperiencia}`}
							<br />
							<br />
							{props?.workTime?.humanized}
							<br />
							{props?.pendingWorkTime?.humanized}
							<br />
							{chaves ? (
								<p style={{ fontWeight: "bolder" }}>
									{t("nota.geral")} {notaTreinamentos.toFixed(1)}{" "}
									<Icon name="graduation" />
								</p>
							) : (
								<div style={{ marginBottom: "1.2em" }}>
									<br />
								</div>
							)}
						</Card.Meta>
						<Card.Description as={Grid} columns={2}>
							<Grid.Column textAlign="center">
								<Icon name="comment outline" /> {user.quantidadeAvaliacao}
								<br />
								Coment.
							</Grid.Column>
							<Grid.Column textAlign="center">
								<Icon name="star outline" />{" "}
								{user.mediaAvaliacao && user.quantidadeAvaliacao
									? (user.mediaAvaliacao / user.quantidadeAvaliacao).toFixed(2)
									: 0}
								<br />
								Média
							</Grid.Column>
						</Card.Description>
					</Card.Content>
				</Card>
			</div>

			<div>
				<Card
					fluid
					key={user.uid}
					disabled={props.disabled}
					style={{ borderRadius: 10 }}
					className="card-aprovar-candidato">
					{props.favorito ? (
						<Label
							corner="right"
							icon="heart"
							style={{ borderColor: "#662780", color: "#fafafa" }}
						/>
					) : (
						""
					)}
					<Card.Content>
						<Card.Description>
							<span style={{ fontWeight: "bold" }}>{user.nome}</span>
							<hr></hr>
							<div className={chaves?.length > 2 ? "scrollbarCardPromotor" : ""}>
								{!_.isEmpty(treinamentos) &&
									_.map(treinamentos, (treinamento, index) => {
										if (includes(chavesRoteiro, index)) {
											if (chaves.length > 2) {
												return (
													<div>
														<div
															style={{
																display: "flex",
																justifyContent: "space-between",
																flexDirection: "column",
															}}>
															<div
																style={{
																	color: "grey",
																	width: "80%",
																	fontWeight: "bolder",
																}}>
																{treinamento.nome}
															</div>
															<div
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																	flexDirection: "row",
																}}>
																<div
																	style={{
																		textAlign: "left",
																	}}>
																	<div
																		style={{
																			paddingRight: "6px",
																		}}>
																		{moment(
																			treinamento.data
																		).format("DD/MM/YYYY")}
																	</div>
																</div>
																<div
																	style={{
																		textAlign: "right",
																	}}>
																	<div
																		style={{
																			paddingRight: "6px",
																		}}>
																		Nota:{" "}
																		<span
																			style={{
																				fontWeight: "bold",
																			}}>
																			{treinamento.nota}
																		</span>
																	</div>
																</div>
															</div>
														</div>
														{chaves[chaves.length - 1] !== index && (
															<hr></hr>
														)}
													</div>
												);
											}
											return (
												<div>
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}>
														<div
															style={{ color: "grey", width: "80%" }}>
															{treinamento.nome}
														</div>
														<div
															style={{
																textAlign: "right",
																fontWeight: "bolder",
															}}>
															<div style={{ paddingRight: "6px" }}>
																{treinamento.nota}
															</div>
														</div>
													</div>
													{chaves[chaves.length - 1] !== index && (
														<hr></hr>
													)}
												</div>
											);
										}
									})}
							</div>
						</Card.Description>
						<hr></hr>
						<Card.Description style={{ display: "flex", justifyContent: "center" }}>
							<Button
								fluid
								onClick={props.onClick}
								content={t("aprovar")}
								style={{ fontWeight: "bold" }}
								color="green"
								icon="check"
							/>
						</Card.Description>
					</Card.Content>
				</Card>
			</div>
		</ReactCardFlip>
	);
};
export default withTranslation()(CardPromoter);
