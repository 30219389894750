import styled from "styled-components";

interface ButtonProps {
	isMain?: boolean;
}

export const Container = styled.div`
	display: flex;
	text-align: center;
`;

export const RightContent = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	margin: 3rem;
	gap: 3.9375rem /* 63px -> 3.9375rem */;

	@media (max-width: 1050px) {
		width: 50vw;
	}
`;

export const Logo = styled.img`
	width: 18rem /* 288px -> 12.125rem */;
	height: 5rem /* 80px -> 2.75rem */;
	margin-bottom: 0.2rem;
`;

export const Title = styled.h1`
	font-size: 2.5rem /* 40px -> 2.5rem */;
	font-weight: bold;

	@media (max-width: 1050px) {
		font-size: 1.5rem;
	}
`;

export const Selector = styled.div`
	display: flex;
	gap: 2.1875rem /* 35px -> 2.1875rem */;
`;

export const Button = styled.button<ButtonProps>`
	width: 12.5625rem /* 201px -> 12.5625rem */;
	height: 3.25rem /* 52px -> 3.25rem */;
	border-radius: 4.375rem /* 70px -> 4.375rem */;

	background-color: ${props => (props.isMain ? "#7501c0" : "#FFF")};
	color: ${props => (props.isMain ? "#FFF" : "#7501c0")};
	border: ${props => (props.isMain ? "none" : "1px solid #7501c0")};
	font-weight: bold;
	transition: filter 0.3s;
	cursor: pointer;

	&:hover {
		filter: opacity(0.7);
	}

	&:active {
		filter: opacity(1);
	}

	@media (max-width: 1050px) {
		width: 9.375rem /* 150px -> 9.375rem */;
		height: 3.25rem;
	}
`;

export const LeftBackground = styled.img`
	height: 100vh;

	@media (max-width: 1050px) {
		display: none;
		width: 50vw;
	}
`;
