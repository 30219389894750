//import { Step } from '@material-ui/core';
import "cleave.js/dist/addons/cleave-phone.br";
import firebase from "firebase";
import React, { Component } from "react";
import { Message, Button, Card, Header } from "semantic-ui-react";
import "./cadastro.css";
import { BotaoProximo } from "./CadastroEmpresa";

const firebaseDf = firebase;

/**
 * @name pegaVendedor
 * @date Criado em 17/12/2020
 * @author Vitor Savian
 * @description pega o vendedor e joga as informações dele para o state
 * @param {String} codigoVendedor codigo que será usado na
 * pesquisa do firebase
 * @param {Function} handleStateVendedor Descrição
 * @param {Function} setState Manda o state para a classe
 * @param {Function} handleCodigo atualiza o state dentro da
 * classe pai se caso não ter dado boa o código
 * @param {Function} handleTakeVendor atualiza o state de vendedor
 * @param {Function} firebase firebase
 */
export const pegaVendedor = (
	codigoVendedor,
	handleStateVendedor,
	setState,
	handleCodigo,
	handleTakeVendor,
	firebase = firebaseDf
) => {
	return firebase
		.firestore()
		.collection("vendedores")
		.where("codigoVendedor", "==", codigoVendedor)
		.get()
		.then(snapshot => {
			return handleStateVendedor(snapshot.docs[0].data(), true, setState, handleTakeVendor);
		})
		.catch(function (error) {
			handleCodigo("");
			console.log("error", error);
			return handleStateVendedor({}, false, setState);
		});
};

/**
 * @name handleStateVendedor
 * @date Criado em 17/12/2020
 * @author Vitor Savian
 * @description arruma o state e envia para a classe pai
 * onde está o objeto da empresa
 * @param {Object} doc informações do vendedor
 * @param {Boolean} bolean manda se conseguiu ou não encontrar o vendedor
 * @param {Function} setState função de setar estado da classe pai
 * @param {Function} handleTakeVendor atualiza o state de vendedor
 */
export const handleStateVendedor = (doc, bolean, setState, handleTakeVendor) => {
	if (bolean === true) {
		handleTakeVendor(doc);
		return setState({
			sucesso: true,
			vendedor: doc,
			erro: false,
		});
	} else {
		return setState({
			sucesso: false,
			erro: true,
		});
	}
};

/**
 * @name handleDeleteVendorCode
 * @date Criado em 18/12/2020
 * @author Vitor Savian
 * @description retira o codigo do vendedor
 * se o botao de cancelar for clickado
 */
export const handleDeleteVendorCode = (setState, handleCodigo) => {
	return setState(
		{
			sucesso: false,
			vendedor: {},
			erro: false,
		},
		() => {
			handleCodigo("");
		}
	);
};

/**
 * @name Step7
 * @date Criado em 17/12/2020
 * @author Vitor Savian
 * @description manipula e retorna o JSX para o step de codigo
 * de vendedor
 */
class Step7 extends Component {
	state = {
		sucesso: false,
		erro: false,
		vendedor: {},
	};

	render() {
		const { sucesso, erro, vendedor } = this.state;
		const { empresa, handleCodigo, t, handleTakeVendor } = this.props;
		return (
			<div className={"d-block"}>
				<div className="form-group">
					{console.log("empresa", empresa)}
					{sucesso ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
								marginBottom: "30px",
							}}>
							<Header as="h3" textAlign="center">
								<Header.Content>Código adicionado com sucesso!</Header.Content>
								<Header.Subheader>
									Segue as informações do(a) vendedor(a)
								</Header.Subheader>
							</Header>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Card>
									<Card.Header
										style={{
											fontWeight: "bold",
											color: "#000",
											paddingTop: "10px",
										}}>
										{vendedor.nome}
									</Card.Header>
									<Card.Description style={{ paddingBottom: "10px" }}>
										Email: {vendedor.email}
									</Card.Description>
								</Card>
							</div>
							<div className="form-group">
								<Button
									style={{ borderRadius: "60px" }}
									color="purple"
									className="btn  btn-primary-gradient"
									content={"Remover vendedor"}
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();
										return handleDeleteVendorCode(
											(val, call) => this.setState(val, call),
											handleCodigo
										);
									}}
								/>
							</div>
						</div>
					) : (
						<div style={{ marginBottom: "30px" }}>
							<Header as="h3" textAlign="center">
								<Header.Content>
									Foi contatado por um(a) de nossos(as) vendedores(as)?
								</Header.Content>
								<Header.Subheader>Digite o código abaixo. :)</Header.Subheader>
							</Header>
							<input
								name="codigo"
								maxLength={45}
								value={empresa.vendedor.codigo}
								onChange={e => handleCodigo(e.target.value)}
								placeholder={t("Id do vendedor")}
							/>
							<Button
								style={{ marginTop: "10px", borderRadius: "60px" }}
								color="purple"
								className="btn  btn-primary-gradient"
								content={"Adicionar ID de vendedor"}
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									let codigoVendedor = empresa.vendedor.codigo;
									return pegaVendedor(
										codigoVendedor,
										handleStateVendedor,
										val => this.setState(val),
										handleCodigo,
										handleTakeVendor
									);
								}}
							/>
							{erro && (
								<Message
									negative
									icon="cancel"
									header="Id inválido"
									content="Coloque um código válido"
								/>
							)}
						</div>
					)}
				</div>
				<BotaoProximo t={t} />
			</div>
		);
	}
}

export default Step7;
