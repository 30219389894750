import { Image, ImageProps } from "semantic-ui-react";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    @media (max-width: 1020px) {
        flex-direction: column;
    }
`;

export const Avatar: React.FC<ImageProps> = styled(Image).attrs({ avatar: true })`
    width: 2.7rem !important;
    height: 2.7rem !important;
`;

export const RightContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 1020px) {
        display: flex;
        gap: 0.3rem;
        justify-content: center;
    }
`;

export const RatingArea = styled.div`
    display: flex;
    gap: 0.2rem;
    align-items: center;
    color: #999999 !important;

    i {
        color: #999999 !important;
    }

    span {
        line-height: 1;
    }
`;

export const Name = styled.span`
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    padding-left: 0.15rem;
`;
