import React from "react";

const Cubo = ({ color, content, title }) => {
	let tituloBreakline = title.split("|")[1];
	return (
		<div
			style={{
				width: 150,
				height: 150,
				backgroundColor: color,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				padding: 5,
				textAlign: "center",
				color: "#fff",
				borderRadius: 5,
				marginBottom: 5,
			}}>
			<p style={{ fontWeight: "bold", fontSize: 35, lineHeight: 1 }}>{content}</p>
			<p style={{ fontSize: 15, lineHeight: 1 }}>
				{title.split("|")[0]}
				{tituloBreakline && (
					<>
						<br />
						{tituloBreakline}
					</>
				)}
			</p>
		</div>
	);
};

export default props => {
	let { t } = props;
	let { percentual, produtos, rupturas, oportunidades, pdvs } = props.totais;
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				flexWrap: "wrap",
			}}>
			<Cubo
				color={"#4F1E63"}
				content={oportunidades ?? 0}
				title="Oportunidades de reposição"
			/>
			<Cubo color={"#1E88E5"} content={pdvs ?? 0} title="PDVs (oportunidades de reposição)" />
			<Cubo color={"#35BE35"} content={produtos ?? 0} title={t("produtos")} />
			<Cubo color={"#FF6F00"} content={rupturas ?? 0} title={t("rupturas")} />
			<Cubo
				color={"#F44336"}
				content={`${(percentual ?? 0)?.toFixed()}%`}
				// title={t('percentual')}
				title={t("rupturas")}
			/>
		</div>
	);
};
