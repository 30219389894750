import firebase from "firebase";
import _ from "lodash";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { downloadLojasDaEmpresa } from "../../Utils/Oportunidades";
import { lojasDaEmpresa } from "../../_actions/RecursoActions";
import { LojaDropdown } from "../../Componentes/NovoRoteiroInputs";
import { connect } from "react-redux";
import CardPromoter from "../../Componentes/CardPromoter";
import ReactTypingEffect from "react-typing-effect";
import {
	Button,
	Divider,
	Grid,
	Header,
	Icon,
	Input,
	Popup,
	Segment,
	Radio,
	Tab,
	Message,
} from "semantic-ui-react";
import { v4 } from "uuid";
import HeaderPadrao from "../../Componentes/HeaderPadrao";
import { db } from "../../config";

const firebaseDf = firebase;

const iconesStatus = {
	pendente: "clock outline",
	aceito: "check",
};

class SelecaoAutomatica extends Component {
	state = {
		loading: {},
		searchInput: null,
		codigoAgencia: "",
		meuCodigo: "",
		carregando: false,
		lojasDropDown: [],
		pdvEscolhido: null,
		tabAtiva: 0,
		favoritos: [],
		bloqueados: [],
	};

	/**
	 * @name handleSaveOportunidade
	 * @date Criado em 05/02/2021
	 * @author Vitor Andre Savian <vitorandresaviandev@gmail.com>
	 * @description salva no firebase a seleção automatica para determinado PDV
	 * @param {String} id da loja
	 * @param {String} selecaoAutomatica ID da empresa que foi selecionada
	 */
	handleSaveOportunidade = ({ id, selecaoAutomatica }, firebase = firebaseDf) => {
		return firebase.firestore().collection("lojas").doc(id).update({
			selecaoAutomatica: selecaoAutomatica,
		});
	};

	/**
	 * @name handleSaveOportunidade
	 * @date Criado em 05/02/2021
	 * @author Vitor Andre Savian <vitorandresaviandev@gmail.com>
	 * @description salva no firebase a seleção automatica para determinado PDV
	 * @param {Object} event evento do DOM
	 * @param {Object} value todas as informações que vieram pelo dropDown
	 */
	handleChangePDVDropDown = (event, { value }, setState) => {
		return setState({ pdvEscolhido: value });
	};

	/**
	 * @name handleSelecaoAutomatica
	 * @date Criado em 05/02/2020
	 * @author Vitor Andre Savian <vitorandresaviandev@gmail.com>
	 * @description coloca dentro do pdvEscolhido a empresa que foi selecionada
	 * @param {Object} empresa informações da empresa
	 * @param {Object} usuario informações do usuario
	 */
	handleSelecaoAutomatica = (e, pdvEscolhido, setState) => {
		if (e.currentTarget.id !== this.state.pdvEscolhido.selecaoAutomatica) {
			return setState({
				pdvEscolhido: {
					...pdvEscolhido,
					selecaoAutomatica: e.currentTarget.id,
				},
			});
		} else {
			return setState({
				pdvEscolhido: { ...pdvEscolhido, selecaoAutomatica: null },
			});
		}
	};

	componentDidMount() {
		this.generateCompanyCode();
		this.getPromotoresFavoritos();
		this.getPromotoresBloqueados();
		this.props.lojas === null &&
			firebase
				.firestore()
				.collection("lojasHomologadas")
				.get()
				.then(d => {
					var i = d.docs;
					return i;
				})
				.then(p => {
					downloadLojasDaEmpresa(this.props.idEmpresa).onSnapshot(snap => {
						var s = {
							docs: _.concat(snap.docs, p),
						};
						this.props.dispatch(lojasDaEmpresa(s));
					});
				});
		if (this.props.lojas) {
			const lojas = this.props.lojas.map(loja => {
				const key = {
					key: loja.id,
					text: loja.fantasia,
					value: { ...loja },
					content: (
						<div key={loja.id}>
							<span>{loja.fantasia}</span>
							<br />
							<p style={{ fontWeight: "normal", fontSize: 12, opacity: 0.6 }}>
								{loja.endereco.endereco}
							</p>
						</div>
					),
				};
				return key;
			});
			this.setState({ lojasDropDown: lojas });
		}
	}

	componentDidUpdate(prevProps) {
		if (
			(this.props.lojas !== null &&
				this.props.lojas.length > 0 &&
				_.isEmpty(this.state.lojasDropDown)) ||
			(prevProps.lojas !== null &&
				prevProps.lojas.length > 0 &&
				_.isEmpty(this.state.lojasDropDown))
		) {
			const lojas = this.props.lojas.map(loja => {
				const key = {
					key: loja.id,
					text: loja.fantasia,
					value: { ...loja },
					content: (
						<div key={loja.id}>
							<span>{loja.fantasia}</span>
							<br />
							<p style={{ fontWeight: "normal", fontSize: 12, opacity: 0.6 }}>
								{loja.endereco.endereco}
							</p>
						</div>
					),
				};
				return key;
			});
			this.setState({ lojasDropDown: lojas });
		}
	}

	getPromotoresFavoritos = async () => {
		const docRef = await db.collection("empresas").doc(this.props.empresa.uid);
		docRef
			.collection("favoritos")
			.get()
			.then(snap => this.setState({ favoritos: snap.docs.map(doc => doc.data()) }));
	};

	getPromotoresBloqueados = async () => {
		const docRef = await db.collection("empresas").doc(this.props.empresa.uid);
		docRef
			.collection("bloqueados")
			.get()
			.then(snap => this.setState({ bloqueados: snap.docs.map(doc => doc.data()) }));
	};

	generateCompanyCode = () => {
		if (!this.props.empresa.codigoSA && this.props.empresa) {
			let name = this.props.empresa.fantasia;
			name = name.split(" ")[0];
			name = name.toUpperCase();
			name = name.replace("I", "1");
			name = name.replace("A", "4");
			name = name.replace("O", "0");
			name = name.replace("T", "7");
			name = name.replace("S", "5");
			this.salvarCodigo(name);
		}
	};

	salvarCodigo = codigo => {
		this.setState({ salvandoCodigo: true }, () =>
			db
				.collection("empresas")
				.doc(this.props.empresa.uid)
				.update({
					codigoSA: codigo,
				})
				.then(a => {
					this.setState({ salvandoCodigo: false });
				})
		);
	};

	buscarCodigo = () => {
		const id = v4();
		const { t } = this.props;
		this.setState({ buscandoCodigo: true }, () =>
			db
				.collection("empresas")
				.where("codigoSA", "==", this.state.codigoAgencia)
				.get()
				.then(snapshot => {
					if (snapshot.size === 1) {
						let ag = snapshot.docs[0].data();
						Promise.all([
							db
								.collection("empresas")
								.doc(ag.uid)
								.update({
									[`selecionadoAutomaticamente.${this.props.empresa.uid}`]: {
										id: this.props.empresa.uid,
										fantasia: this.props.empresa.fantasia,
										status: "aceito",
									},
								}),
							db
								.collection("notificacoes")
								.doc(id)
								.set({
									data: moment().format("YYYY-MM-DD"),
									descricao: `${this.props.empresa.fantasia} ${t(
										"lhe.adicionou.a.lista.de.selecao.automatica"
									)}`,
									id: id,
									idEmpresa: ag.uid,
									idRoteiro: "",
									link: "/selecaoAutomatica",
									titulo: t("selecao.automatica"),
									visto: 0,
								}),
							//minha lista
							db
								.collection("empresas")
								.doc(this.props.empresa.uid)
								.update({
									[`selecaoAutomatica.${ag.uid}`]: {
										id: ag.uid,
										fantasia: ag.fantasia,
										status: "aceito",
									},
								}),
						]).then(a => {
							this.setState({
								codigoAgencia: "",
								buscandoCodigo: false,
								msgBusca: {
									msg: `${ag.fantasia} ${this.props.t("adicionada.a.lista")}`,
									type: 0,
								},
							});
						});
					} else {
						this.setState({
							buscandoCodigo: false,
							msgBusca: {
								msg: this.props.t("nenhuma.agencia.codigo"),
								type: 1,
							},
						});
					}
				})
		);
	};

	recusar = id => () => {
		this.setState(
			{
				loading: {
					...this.state.loading,
					[id]: { status: 0 },
				},
			},
			() => {
				setTimeout(() => {
					Promise.all([
						db
							.collection("empresas")
							.doc(this.props.empresa.uid)
							.update({
								[`selecionadoAutomaticamente.${id}`]:
									firebase.firestore.FieldValue.delete(),
							}),
						db
							.collection("empresas")
							.doc(id)
							.update({
								[`selecaoAutomatica.${this.props.empresa.uid}`]:
									firebase.firestore.FieldValue.delete(),
							}),
					]).then(a => {
						this.setState({
							loading: {
								...this.state.loading,
								[id]: false,
							},
						});
					});
				}, 3000);
			}
		);
	};

	aceitar = id => () => {
		this.setState(
			{
				loading: {
					...this.state.loading,
					[id]: { status: 1 },
				},
			},
			() => {
				setTimeout(() => {
					Promise.all([
						db
							.collection("empresas")
							.doc(this.props.empresa.uid)
							.update({
								[`selecionadoAutomaticamente.${id}.status`]: "aceito",
							}),
						db
							.collection("empresas")
							.doc(id)
							.update({
								[`selecaoAutomatica.${this.props.empresa.uid}.status`]: "aceito",
							}),
					]).then(a => {
						this.setState({
							loading: {
								...this.state.loading,
								[id]: false,
							},
						});
					});
				}, 3000);
			}
		);
	};

	remover = id => () => {
		this.setState(
			{
				loading: {
					...this.state.loading,
					[id]: true,
				},
			},
			() => {
				setTimeout(() => {
					Promise.all([
						db
							.collection("empresas")
							.doc(this.props.empresa.uid)
							.update({
								[`selecaoAutomatica.${id}`]: firebase.firestore.FieldValue.delete(),
							}),
						db
							.collection("empresas")
							.doc(id)
							.update({
								[`selecionadoAutomaticamente.${this.props.empresa.uid}`]:
									firebase.firestore.FieldValue.delete(),
							}),
					]).then(a => {
						this.setState({
							loading: {
								...this.state.loading,
								[id]: false,
							},
						});
					});
				}, 3000);
			}
		);
	};

	favoritar = id => () => {
		const docRef = db.collection("empresas").doc(this.props.empresa.uid);
		let favorito = _.find(this.props.empresa.selecaoAutomatica, {
			favorito: true,
		});
		if (favorito) {
			if (favorito.id === id) {
				docRef.update({
					[`selecaoAutomatica.${favorito.id}.favorito`]:
						firebase.firestore.FieldValue.delete(),
				});
			} else {
				docRef.update({
					[`selecaoAutomatica.${favorito.id}.favorito`]:
						firebase.firestore.FieldValue.delete(),
					[`selecaoAutomatica.${id}.favorito`]: true,
				});
			}
		} else {
			docRef.update({
				[`selecaoAutomatica.${id}.favorito`]: true,
			});
		}
	};

	handleChange = e => this.setState({ [e.target.name]: e.target.value });

	removerFavorito = idx => {
		var favoritos = [...this.state.favoritos];

		this.setState({
			favoritos: favoritos.filter((object, index) => idx !== index),
		});
	};

	removerBloqueio = idx => {
		var bloqueados = [...this.state.bloqueados];

		this.setState({
			bloqueados: bloqueados.filter((object, index) => idx !== index),
		});
	};

	render() {
		const {
			codigoAgencia,
			salvandoCodigo,
			buscandoCodigo,
			msgBusca,
			lojasDropDown,
			pdvEscolhido,
			carregando,
			tabAtiva,
			favoritos,
			bloqueados,
		} = this.state;
		const { t, empresa, lojas } = this.props;

		const panes = [
			{
				menuItem: {
					key: "selecao.automatica",
					icon: "star",
					content: t("selecao.automatica"),
				},
				render: () => <SelecaoAutomatica />,
			},
			{
				menuItem: {
					key: "promotores.favoritos",
					icon: "heart",
					content: t("promotores.favoritos"),
				},
				render: () => <PromotoresFavoritos />,
			},
			{
				menuItem: {
					key: "promotores.bloqueados",
					icon: "ban",
					content: t("promotores.bloqueados"),
				},
				render: () => <PromotoresBloqueados />,
			},
		];

		const PromotoresFavoritos = () => {
			return (
				<>
					<HeaderPadrao
						icone="heart"
						titulo={t("promotores.favoritos")}
						subtitulo={t("gerencia.promotores.favoritos")}></HeaderPadrao>

					{favoritos.length > 0 ? (
						<Grid stackable columns={5}>
							{favoritos.map((promotor, i) => (
								<Grid.Column key={i}>
									<CardPromoter
										esconderEscolher={true}
										user={promotor}
										favorito
										botaoDesfavoritar={true}
										empresa={this.props.empresa}
										index={i}
										callbackRemove={this.removerFavorito}
									/>
								</Grid.Column>
							))}
						</Grid>
					) : (
						<Message content={t("nenhum.promotor.favorito")}></Message>
					)}
				</>
			);
		};

		const PromotoresBloqueados = () => {
			return (
				<>
					<HeaderPadrao
						icone="ban"
						titulo={t("promotores.bloqueados")}
						subtitulo={t("gerencia.promotores.bloqueados")}></HeaderPadrao>

					{bloqueados.length > 0 ? (
						<Grid stackable columns={5}>
							{bloqueados.map((promotor, i) => (
								<Grid.Column key={i}>
									<CardPromoter
										esconderEscolher={true}
										user={promotor}
										bloqueado
										botaoDesbloquear={true}
										empresa={this.props.empresa}
										index={i}
										callbackRemove={this.removerBloqueio}
									/>
								</Grid.Column>
							))}
						</Grid>
					) : (
						<Message content={t("nenhum.promotor.bloqueado")}></Message>
					)}
				</>
			);
		};

		const SelecaoAutomatica = () => {
			return (
				<>
					<HeaderPadrao
						icone="star"
						titulo={t("selecao.automatica")}
						subtitulo={t("gerencia.selecao.automatica")}></HeaderPadrao>
					<Grid stackable>
						{empresa.tipo === 2 && (
							<Grid.Column width={16} textAlign="center">
								<div
									style={{
										margin: "auto",
										position: "relative",
										display: "inline-block",
									}}>
									<p>{t("seu.codigo")}</p>
									<Input
										name="meuCodigo"
										value={this.props.empresa.codigoSA}
										action={{
											color: "green",
											icon: "check",
											onClick: () => this.salvarCodigo(this.state.meuCodigo),
											loading: salvandoCodigo,
											disabled: salvandoCodigo,
										}}
										onChange={this.handleChange}
									/>
									<p style={{ color: "#666" }}>{t("compartilhe.seu.codigo")}</p>
								</div>
							</Grid.Column>
						)}
						{(!empresa.tipo || empresa.tipo === 1) && (
							<Grid.Column width={16} textAlign="center">
								<div
									style={{
										margin: "auto",
										position: "relative",
										display: "inline-block",
									}}>
									<p>{t("digite.codigo.agencia")}</p>
									<Input
										name="codigoAgencia"
										value={codigoAgencia}
										action={{
											color: "blue",
											icon: "search",
											onClick: this.buscarCodigo,
											loading: buscandoCodigo,
											disabled: buscandoCodigo,
										}}
										onChange={this.handleChange}
									/>
									<p style={{ color: "#666" }}>
										{t("busque.agencias.selecao.automatica")}
									</p>
									{msgBusca && (
										<p style={{ color: msgBusca.type === 1 ? "red" : "green" }}>
											{msgBusca.msg}
										</p>
									)}
								</div>
							</Grid.Column>
						)}
					</Grid>
					{_.isEmpty(empresa.selecaoAutomatica) &&
					_.isEmpty(empresa.selecionadoAutomaticamente) ? (
						<Header
							as="h2"
							icon
							textAlign="center"
							color="grey"
							style={{ marginTop: 150 }}>
							<Icon name="cloud" />
							{t("lista.vazia.selecao.automatica")}
							<Header.Subheader>
								{t("lista.vazia.selecao.automatica.desc")}
							</Header.Subheader>
						</Header>
					) : (
						<>
							{empresa.tipo === 2 &&
								!_.isEmpty(
									_.filter(empresa.selecionadoAutomaticamente, {
										status: "pendente",
									})
								) && (
									<>
										<Header
											content={t("aguardando.sua.resposta")}
											subheader={t("aguardando.sua.resposta.desc")}
										/>
										<Segment color="grey">
											<Grid stackable>
												{Object.keys(
													empresa.selecionadoAutomaticamente || {}
												).map(key => {
													const sel =
														empresa.selecionadoAutomaticamente[key];
													if (sel.status !== "pendente") {
														return null;
													}
													return (
														<Fragment key={sel.id}>
															<Grid.Column
																verticalAlign="middle"
																width={10}>
																<b>{sel.fantasia}</b>
															</Grid.Column>
															<Grid.Column
																width={6}
																textAlign="right">
																{this.state.loading[sel.id] ? (
																	<p
																		style={{
																			color:
																				this.state.loading[
																					sel.id
																				].status === 1
																					? "green"
																					: "red",
																		}}>
																		<ReactTypingEffect
																			speed={10}
																			eraseDelay={10000}
																			typingDelay={300}
																			text={
																				this.state.loading[
																					sel.id
																				].status === 1
																					? t(
																							"aceitando.proposta"
																						)
																					: t(
																							"recusando.proposta"
																						)
																			}
																		/>
																	</p>
																) : (
																	<span
																		style={{
																			margin: "auto",
																			position: "relative",
																		}}>
																		<Button
																			content={t("aceitar")}
																			onClick={this.aceitar(
																				sel.id
																			)}
																			icon="check"
																			basic
																			color="green"
																		/>
																		<Button
																			content={t("recusar")}
																			onClick={this.recusar(
																				sel.id
																			)}
																			icon="times"
																			basic
																			color="red"
																		/>
																	</span>
																)}
															</Grid.Column>
															<Divider
																style={{
																	width: "100%",
																	marginTop: 0,
																	marginBottom: 0,
																	marginLeft: 20,
																	marginRight: 20,
																}}
															/>
														</Fragment>
													);
												})}
											</Grid>
										</Segment>
									</>
								)}
							{(empresa.tipo === 2 || empresa.tipo === 1) && lojas?.length > 0 && (
								<>
									<Header
										content={t("selecione.pdv.header")}
										subheader={t("selecione.pdv.subheader")}
									/>
									<Segment>
										<Grid stackable>
											<Grid.Column width={16}>
												<LojaDropdown
													options={lojasDropDown}
													label={t("selecione.pdv")}
													placeholder={t("selecione.pdv")}
													onChange={(e, data) =>
														this.handleChangePDVDropDown(e, data, val =>
															this.setState(val)
														)
													}
													selection
													fluid
													search={true}
													onSearchChange={(e, data) =>
														this.setState({
															searchInput: data,
														})
													}
												/>
											</Grid.Column>
											{empresa.selecaoAutomatica &&
											!_.isEmpty(pdvEscolhido) &&
											Object.keys(empresa.selecaoAutomatica).length > 0 ? (
												<Grid.Column width={16}>
													<Segment>
														{Object.keys(empresa.selecaoAutomatica).map(
															key => (
																<div
																	key={key}
																	className={`linha-selecao-automatica ${
																		pdvEscolhido?.selecaoAutomatica ===
																		key
																			? "selecionado"
																			: ""
																	}`}
																	id={key}
																	onClick={e =>
																		this.handleSelecaoAutomatica(
																			e,
																			pdvEscolhido,
																			val =>
																				this.setState(val)
																		)
																	}>
																	<Radio
																		name="selecaoAutomatica"
																		value={key}
																		checked={
																			pdvEscolhido?.selecaoAutomatica ===
																			key
																		}
																		size="medium"
																	/>{" "}
																	{
																		empresa.selecaoAutomatica[
																			key
																		].fantasia
																	}
																</div>
															)
														)}
													</Segment>
												</Grid.Column>
											) : (
												<Grid.Column width={16}>
													{!_.isEmpty(pdvEscolhido) &&
														(empresa.selecaoAutomatica === undefined ||
															Object.keys(empresa.selecaoAutomatica)
																.length === 0) && (
															<Header
																as="h5"
																icon
																textAlign="center"
																color="grey">
																<Icon name="address book" />
																{t(
																	"selecione.pdv.semSelecao.header"
																)}
																<Header.Subheader>
																	{t("selecione.pdv.semSelecao")}
																</Header.Subheader>
															</Header>
														)}
												</Grid.Column>
											)}
											{empresa.selecaoAutomatica &&
												!_.isEmpty(pdvEscolhido) && (
													<Grid.Column width={16}>
														<Button
															loading={carregando}
															content={t("salvar")}
															color="green"
															onClick={e =>
																this.setState(
																	{ carregando: true },
																	() => {
																		this.handleSaveOportunidade(
																			pdvEscolhido
																		).then(() => {
																			this.setState({
																				carregando: false,
																			});
																		});
																	}
																)
															}
														/>
														<Button
															content={t("cancelar")}
															color="red"
															basic
															onClick={() =>
																this.setState({
																	pdvEscolhido: null,
																})
															}
														/>
													</Grid.Column>
												)}
										</Grid>
									</Segment>
								</>
							)}
							{(!empresa.tipo || empresa.tipo === 1) &&
								!_.isEmpty(empresa.selecaoAutomatica) && (
									<>
										<Header
											content={t("estao.na.sua.lista")}
											subheader={t("estao.na.sua.lista.desc")}
										/>
										<Segment color="purple">
											<Grid stackable>
												{Object.keys(empresa.selecaoAutomatica || {}).map(
													key => {
														const sel = empresa.selecaoAutomatica[key];
														return (
															<Fragment key={sel.id}>
																<Grid.Column
																	verticalAlign="middle"
																	width={8}>
																	<div
																		className={`star-selecao-automatica ${
																			sel.favorito
																				? "favoritado"
																				: ""
																		}`}>
																		<Popup
																			content={t(
																				"favoritar.agencia.selecao.automatica"
																			)}
																			trigger={
																				<Icon
																					name={
																						sel.favorito
																							? "star"
																							: "star outline"
																					}
																					size="large"
																					onClick={this.favoritar(
																						sel.id
																					)}
																				/>
																			}
																		/>
																	</div>
																	<b>{sel.fantasia}</b>
																</Grid.Column>
																<Grid.Column
																	verticalAlign="middle"
																	width={4}>
																	<p
																		style={{
																			color:
																				sel.status ===
																				"aceito"
																					? "green"
																					: "grey",
																		}}>
																		<Icon
																			name={
																				iconesStatus[
																					sel.status
																				]
																			}
																		/>{" "}
																		{t(sel.status)}
																	</p>
																</Grid.Column>
																<Grid.Column
																	width={4}
																	textAlign="right">
																	{this.state.loading[sel.id] ? (
																		<p
																			style={{
																				color: "grey",
																			}}>
																			<ReactTypingEffect
																				speed={10}
																				eraseDelay={10000}
																				typingDelay={300}
																				text={t(
																					"removendo.da.lista"
																				)}
																			/>
																		</p>
																	) : (
																		<Button
																			icon="times"
																			content={t(
																				"remover.da.lista"
																			)}
																			basic
																			color="red"
																			onClick={this.remover(
																				sel.id
																			)}
																		/>
																	)}
																</Grid.Column>
																<Divider
																	style={{
																		width: "100%",
																		marginTop: 0,
																		marginBottom: 0,
																		marginLeft: 20,
																		marginRight: 20,
																	}}
																/>
															</Fragment>
														);
													}
												)}
											</Grid>
										</Segment>
									</>
								)}
							{empresa.tipo === 2 &&
								!_.isEmpty(
									_.filter(empresa.selecionadoAutomaticamente, {
										status: "aceito",
									})
								) && (
									<>
										<Header
											content={t("voce.esta.na.lista.de")}
											subheader={t("voce.esta.na.lista.de.desc")}
										/>
										<Segment color="violet">
											<Grid stackable>
												{Object.keys(
													empresa.selecionadoAutomaticamente || {}
												).map(key => {
													const sel =
														empresa.selecionadoAutomaticamente[key];
													if (sel.status === "pendente") {
														return null;
													}
													return (
														<Fragment key={sel.id}>
															<Grid.Column
																verticalAlign="middle"
																width={10}>
																<b>{sel.fantasia}</b>
															</Grid.Column>
															<Grid.Column
																width={6}
																textAlign="right">
																{this.state.loading[sel.id] &&
																this.state.loading[sel.id]
																	.status === 0 ? (
																	<p style={{ color: "grey" }}>
																		<ReactTypingEffect
																			speed={10}
																			eraseDelay={10000}
																			typingDelay={300}
																			text={t(
																				"saindo.da.lista"
																			)}
																		/>
																	</p>
																) : (
																	<Button
																		icon="times"
																		content={t("sair.da.lista")}
																		onClick={this.recusar(
																			sel.id
																		)}
																		basic
																		color="red"
																	/>
																)}
															</Grid.Column>
															<Divider
																style={{
																	width: "100%",
																	marginTop: 0,
																	marginBottom: 0,
																	marginLeft: 20,
																	marginRight: 20,
																}}
															/>
														</Fragment>
													);
												})}
											</Grid>
										</Segment>
									</>
								)}
						</>
					)}
				</>
			);
		};

		return (
			<Tab
				menu={{
					color: "purple",
					secondary: true,
					pointing: true,
					className: "tab-bar-wrapped",
				}}
				panes={panes}
				activeIndex={tabAtiva}
				onTabChange={(event, data) => this.setState({ tabAtiva: data.activeIndex })}
			/>
		);
	}
}

const mapStateToProps = state => ({
	idEmpresa: state.AutenticacaoReducer.empresa.uid,
	lojas: state.RecursosReducer.lojas,
	empresa: state.AutenticacaoReducer.empresa,
});

export default connect(mapStateToProps)(withTranslation()(SelecaoAutomatica));

export const handleChangePDV = new SelecaoAutomatica().handleChangePDVDropDown;
export const handleSaveOportunidade = new SelecaoAutomatica().handleSaveOportunidade;
export const handleSelecaoAutomatica = new SelecaoAutomatica().handleSelecaoAutomatica;
