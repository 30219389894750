import firebase from "firebase";
import _ from "lodash";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
	Button,
	Form,
	Grid,
	Header,
	Icon,
	Input,
	Radio,
	Segment,
	TextArea,
} from "semantic-ui-react";
import { db } from "../../config";
import { logging } from "../../services/Api";
import AtividadeMultiplaEscolha from "./AtividadeMultiplaEscolha";
import AtividadeTipoVideo from "./AtividadeTipoVideo/AtividadeTipoVideo";
import ListaDeAtividades from "./listaDeAtividade";
import TreinamentoImagem from "./TreinamentoImagem";
import TreinamentoPDF from "./TreinamentoPdf";

const iconeTipoAtividade = {
	me: "list ol",
	video: "play",
	imagem: "image",
	pdf: "paperclip",
};

const labelTipoAtividade = {
	me: "multipla.escolha",
	video: "video",
	imagem: "imagem",
	pdf: "pdf",
};

const compTipoAtividade = {
	me: AtividadeMultiplaEscolha,
	video: AtividadeTipoVideo,
	imagem: TreinamentoImagem,
	pdf: TreinamentoPDF,
};

export const EditarTreinamento = props => (
	<>
		<Segment>
			<Form onSubmit={props.handleSalvarEditar}>
				<Form.Group style={{ display: "block", margin: "0 auto" }}>
					<div className="field">
						<label style={{ display: "block", fontWeight: "bold" }}>
							{props.t("nome.treinamento")}
						</label>
						<Input
							fluid
							placeholder={props.t("nome.treinamento")}
							type="text"
							required
							className="input-border-color"
							name="nome"
							value={props.nome}
							onChange={props.handleInput}
						/>
					</div>
				</Form.Group>
				<Form.Group style={{ display: "block", margin: "0 auto" }}>
					<div className="field">
						<label style={{ display: "block", fontWeight: "bold" }}>
							{props.t("nota.de.aprovacao")}
						</label>
						<Input
							fluid
							placeholder={props.t("nota.de.aprovacao")}
							type="number"
							required
							max={10}
							min={0}
							className="input-border-color"
							name="notaAprovacao"
							value={props.notaAprovacao}
							onChange={props.handleInput}
						/>
					</div>
				</Form.Group>
				<Form.Group style={{ display: "block", margin: "0 auto" }}>
					<div className="field">
						<label style={{ display: "block", fontWeight: "bold" }}>
							{props.t("descricao.geral.treinamento")}
						</label>
						<TextArea
							fluid="true"
							className="input-border-color"
							placeholder={props.t("descricao.geral.treinamento")}
							value={props.descricao}
							onChange={e => props.handleInputDescricao(e.target.value)}
						/>
					</div>
				</Form.Group>
				<div style={{ justifyContent: "center", textAlign: "center" }}>
					<br />
					<Button
						negative
						content={props.t("cancelar")}
						onClick={props.handleCancelEditar}
					/>
					<Button positive content={props.t("concluir")} />
				</div>
			</Form>
		</Segment>
	</>
);

export const HeaderTreinamento = props => (
	<>
		<div style={{ display: "flex", justifyContent: "space-between" }}>
			<div style={{ color: "grey" }}>
				<Icon name="graduation" /> {props.t("treinamento")}
			</div>
			<div style={{ textAlign: "right" }}>
				<Icon name="pencil" onClick={props.handleEditar} />
			</div>
		</div>
		<Header
			as="h4"
			style={{
				margin: "0px 0 20px",
				display: "flex",
				textAlign: "left",
				fontSize: "30px",
				fontWeight: "bold",
				lineHeight: "40px",
			}}
			content={props.treinamento.nome}
		/>
	</>
);

export const LabelAtivarTreinamento = props => {
	return (
		<div className="info-treinamento">
			<div className="info-ativar">
				<Radio
					toggle
					onChange={props.handleAtivarTreinamento}
					checked={props.treinamento.ativo}
				/>
				<div className="ativo-label">{props.treinamento.ativo ? "Ativo" : "Inativo"}</div>
			</div>
			<div className="info-nota">
				Nota: <div>{parseFloat(props.treinamento.notaAprovacao).toFixed(1)}</div>
			</div>
		</div>
	);
};

export const DescricaoTreinamento = props => (
	<div style={{ marginTop: 20 }}>
		<Header content={props.t("descricao")} style={{ fontWeight: "bold" }} />
		<p style={{ lineHeight: "21px", color: "grey", marginTop: "-10px" }}>
			{props.treinamento.descricao}
		</p>
	</div>
);

export const AtividadesTreinamento = props => (
	<div style={{ marginTop: 30 }}>
		<Header content="Atividades" style={{ fontWeight: "bold" }} />
		<p style={{ lineHeight: "21px", color: "grey", marginTop: "-10px" }}>
			Clique e arraste para reordenar suas atividades.
		</p>
		{props.state.items && (
			<ListaDeAtividades
				state={props.state}
				treinamento={props.treinamento}
				idEmpresa={props.idEmpresa}
				props={props}
				iconeTipoAtividade={iconeTipoAtividade}
				labelTipoAtividade={labelTipoAtividade}
			/>
		)}
	</div>
);

/**
 * @name TipoAtividade
 * @date alterado em 18/11/2020
 * @author Vitor Savian
 * @description Componente que define cada atividade para o usuario escolher
 * @param {Object} props propriedas que vao para o componente
 */
const TipoAtividade = props => (
	<div className="tipo-atividade">
		<Header content={props.t(labelTipoAtividade[props.tipo])} textAlign="center" />
		<div className="tipo-atividade-icone">
			<Icon name={iconeTipoAtividade[props.tipo]} size="huge" />
		</div>
		<p className="tipo-atividade-descricao">{props.t(`${props.descricao}`)}</p>
		<Button
			content={props.t("selecionar")}
			color="violet"
			fluid
			onClick={props.selecionarAtividade(props.tipo)}
		/>
	</div>
);

/**
 * @name SelecionarAtividade
 * @date alterado em 18/11/2020
 * @author Vitor Savian
 * @description Mostra as atividades que podem ser escolhidas
 * @param {Object} props propriedades que vem com o componente
 */
const SelecionarAtividade = props => (
	<>
		<Header content={props.t("adicione.atividades")} as="h1" />
		<p className="descricao-adicionar-atividade">{props.t("descricao.adicionar.atividade")}</p>
		<Grid columns="equal">
			<Grid.Column mobile={16} computer={4} style={{ borderRight: "1px solid #eee" }}>
				<TipoAtividade
					{...props}
					tipo="imagem"
					descricao={props.descricaoAtividade["imagem"]}
					selecionarAtividade={props.selecionarAtividade}
				/>
			</Grid.Column>
			<Grid.Column mobile={16} computer={4} style={{ borderRight: "1px solid #eee" }}>
				<TipoAtividade
					{...props}
					tipo="video"
					descricao={props.descricaoAtividade["video"]}
					selecionarAtividade={props.selecionarAtividade}
				/>
			</Grid.Column>
			<Grid.Column mobile={16} computer={4} style={{ borderRight: "1px solid #eee" }}>
				<TipoAtividade
					{...props}
					tipo="me"
					descricao={props.descricaoAtividade["me"]}
					selecionarAtividade={props.selecionarAtividade}
				/>
			</Grid.Column>
			<Grid.Column mobile={16} computer={4}>
				<TipoAtividade
					{...props}
					tipo="pdf"
					descricao={props.descricaoAtividade["pdf"]}
					selecionarAtividade={props.selecionarAtividade}
				/>
			</Grid.Column>
		</Grid>
	</>
);

const BotaoCancelar = cancelar => () => (
	<Button basic content="Cancelar" color="red" onClick={cancelar} type="button" />
);

class TreinamentoBase extends Component {
	constructor(props) {
		super(props);
		const empresa = props.empresa;
		const ipLocation = props.ipLocation;
		this.state = {
			idTreinamento: this.props.match.params.id,
			treinamento: {},
			atividadeAtiva: -1,
			empresa,
			ipLocation,
			editar: false,
			nome: "",
			notaAprovacao: 0,
			descricao: "",
		};
	}

	componentDidMount() {
		this.setState(
			{
				ref: db
					.collection("empresas")
					.doc(this.state.empresa.uid)
					.collection("treinamentos")
					.doc(this.state.idTreinamento),
			},
			() => {
				this.buscarTreinamento();
			}
		);
	}

	handleDeleteAtividade = id => {
		var atividade = this.state.treinamento.atividades[id];
		var atividades = { ...this.state.treinamento.atividades };
		_.sortBy(atividades, o => o.ordem).forEach(ativ => {
			if (ativ.ordem > atividade.ordem) {
				atividades[ativ.id].ordem = ativ.ordem - 1;
			}
		});
		delete atividades[id];
		this.state.ref.update({
			atividades: atividades,
		});
	};

	handleAtivarTreinamento = () => {
		this.state.ref.update({
			ativo: !this.state.treinamento.ativo,
		});
	};

	handleSalvarEditar = () => {
		const { treinamento, empresa, ipLocation } = this.state;
		this.state.ref
			.update({
				nome: this.state.nome,
				notaAprovacao: this.state.notaAprovacao,
				descricao: this.state.descricao,
			})
			.then(() => {
				logging("UPDATE", "log.update.treinamento", treinamento.id);
			});
		this.setState({ editar: false });
	};

	handleEditar = () => {
		this.setState({
			editar: true,
			nome: this.state.treinamento.nome,
			notaAprovacao: this.state.treinamento.notaAprovacao,
			descricao: this.state.treinamento.descricao,
		});
	};

	handleInput = ({ target: { name, value } }) => {
		if (name === "notaAprovacao") {
			var valor = parseFloat(value);
			var nota = isNaN(valor) ? null : valor;
			nota = nota <= 10 ? nota : 10;
			this.setState({ [name]: nota });
		} else {
			this.setState({ [name]: value });
		}
	};

	handleInputDescricao = value =>
		this.setState({
			descricao: value,
		});

	handleCancelEditar = () => {
		this.setState({ editar: false });
	};

	buscarTreinamento = () =>
		this.setState({ listener: this.state.ref.onSnapshot(this.buscarInformacoesDoTreinamento) });

	buscarInformacoesDoTreinamento = snap => {
		const treinamento = snap.data();
		if (this.state.idTreinamento === treinamento.id) {
			this.setState({
				treinamento: treinamento,
				items: _.map(_.sortBy(treinamento.atividades, o => o.ordem)),
			});
		} else {
			return this.props.history.push("/dashboard");
		}
	};

	componentWillUnmount() {
		this.state.listener();
	}

	salvarAtividade = atividade => {
		atividade.ordem =
			atividade.ordem ?? Object.keys(this.state.treinamento.atividades ?? []).length + 1;
		return this.state.ref
			.update({
				["atividades." + atividade.id]: atividade,
			})
			.then(this.cancelarAtividade);
	};

	selecionarAtividade = tipo => () => this.setState({ tipoAtividade: tipo });
	cancelarAtividade = () => this.setState({ tipoAtividade: null, atividade: null });

	abrirAtividade = atividade => () =>
		this.setState({
			atividade: atividade,
			tipoAtividade: atividade.tipo,
			atividadeAtiva: atividade.id,
		});

	atualizarListas(list) {
		let { id } = this.state.treinamento;
		let newAtividades = list.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {});
		firebase
			.firestore()
			.collection("empresas")
			.doc(this.props.empresa.uid)
			.collection("treinamentos")
			.doc(id)
			.update({
				atividades: {
					...newAtividades,
				},
			});
	}

	onDragEnd = (result, reorder) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		let items = reorder(this.state.items, result.source.index, result.destination.index);

		items = items.map((obj, i) => ({ ...obj, ordem: i + 1 }));
		console.log(items);

		this.setState({ items }, () => this.atualizarListas(items));
	};

	render() {
		const { t } = this.props;

		const { treinamento, tipoAtividade, atividade, editar, descricao, nome, notaAprovacao } =
			this.state;
		var CompAtividade = null;
		const descricaoAtividade = {
			me: "multipla.escolha.descricao.sobre",
			video: "video.descricao.sobre",
			imagem: "imagem.descricao.sobre",
			pdf: "pdf.descricao.sobre",
		};
		var botaoCancelar = null;
		if (tipoAtividade) {
			CompAtividade = compTipoAtividade[tipoAtividade];
			botaoCancelar = BotaoCancelar(this.cancelarAtividade);
		}

		return (
			<>
				<Grid stackable>
					<Grid.Column computer={6} mobile={16} style={{ borderRight: "1px solid #eee" }}>
						{!editar ? (
							<div>
								<HeaderTreinamento
									t={t}
									treinamento={treinamento}
									handleEditar={this.handleEditar}
								/>
								<LabelAtivarTreinamento
									t={t}
									handleAtivarTreinamento={this.handleAtivarTreinamento}
									treinamento={treinamento}
								/>
								<DescricaoTreinamento t={t} treinamento={treinamento} />
							</div>
						) : (
							<EditarTreinamento
								t={t}
								handleInput={this.handleInput}
								handleInputDescricao={this.handleInputDescricao}
								nome={nome}
								notaAprovacao={notaAprovacao}
								descricao={descricao}
								treinamento={treinamento}
								handleCancelEditar={this.handleCancelEditar}
								handleSalvarEditar={this.handleSalvarEditar}
							/>
						)}
						<AtividadesTreinamento
							t={t}
							onDragEnd={this.onDragEnd}
							state={this.state}
							idEmpresa={this.props.empresa.uid}
							treinamento={treinamento}
							handleDeleteAtividade={this.handleDeleteAtividade}
							atividadeAtiva={this.state.atividadeAtiva}
							abrirAtividade={this.abrirAtividade}
						/>
					</Grid.Column>
					<Grid.Column computer={10} mobile={16}>
						{!tipoAtividade && (
							<SelecionarAtividade
								{...this.props}
								descricaoAtividade={descricaoAtividade}
								selecionarAtividade={this.selecionarAtividade}
							/>
						)}
						{tipoAtividade && (
							<CompAtividade
								key={this.state.atividadeAtiva}
								atividade={atividade}
								t={t}
								treinamento={treinamento}
								salvar={this.salvarAtividade}
								empresa={this.state.empresa}
								cancelar={botaoCancelar}
							/>
						)}
					</Grid.Column>
				</Grid>
			</>
		);
	}
}

const mapStateToProps = state => ({
	empresa: state.AutenticacaoReducer.empresa,
	ipLocation: state.AutenticacaoReducer.ipLocation,
});

export default withSnackbar(connect(mapStateToProps)(withTranslation()(TreinamentoBase)));
