import {
	Box,
	Center,
	Collapse,
	Flex,
	HStack,
	Select,
	Spinner,
	Text,
	Tooltip,
	useDisclosure,
} from "@chakra-ui/react";
import { Badge } from "@material-ui/core";
import { orderBy } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AgencyInformationCard } from "../../../../../Componentes/AgencyInformationCard";
import { EmptyMessage } from "../../../../../Componentes/EmptyMessage";
import { IconifyIconButton } from "../../../../../Componentes/IconifyIconButton";
import { PageHeader } from "../../../../../Componentes/PageHeader";
import { useContextOpportunityExecutionNegotiation } from "../../../../../contexts/ContextOpportunityExecutionNegotiation";

enum FilterOption {
	"Credenciamento",
	"Melhor Avaliação",
	"Pior Avaliação",
	"Menor valor por hora",
	"Maior valor por hora",
}

export function ListAgencies() {
	const { t } = useTranslation();

	const { agencyIsLoading, joinBetweenNegotiationAndAgency } =
		useContextOpportunityExecutionNegotiation();

	const collapseController = useDisclosure({ defaultIsOpen: true });

	const [order, setOrder] = useState<FilterOption>(FilterOption["Credenciamento"]);

	/**
	 * Retorna um novo array de `AgencyWithNegotiation` ordenado e filtra pelos que não tem negociação.
	 */
	const orderedAgencies = useMemo(() => {
		//NOTE: Filtra apenas as agencias que não possuem negociação
		const filteredJoinBetweenNegotiationAndAgency = joinBetweenNegotiationAndAgency.filter(
			agency => !agency.negotiation
		);

		switch (order) {
			case FilterOption["Credenciamento"]:
				return orderBy(
					filteredJoinBetweenNegotiationAndAgency,
					"agency.estaCredenciadaNaRede",
					"desc"
				);
			case FilterOption["Maior valor por hora"]:
				return orderBy(
					filteredJoinBetweenNegotiationAndAgency,
					"agency.valorPorHora",
					"desc"
				);
			case FilterOption["Menor valor por hora"]:
				return orderBy(
					filteredJoinBetweenNegotiationAndAgency,
					"agency.valorPorHora",
					"asc"
				);
			case FilterOption["Pior Avaliação"]:
				return orderBy(
					filteredJoinBetweenNegotiationAndAgency,
					"agency.mediaAvaliacaoCalculada",
					"asc"
				);
			case FilterOption["Melhor Avaliação"]:
				return orderBy(
					filteredJoinBetweenNegotiationAndAgency,
					"agency.mediaAvaliacaoCalculada",
					"desc"
				);
			default:
				return filteredJoinBetweenNegotiationAndAgency;
		}
	}, [joinBetweenNegotiationAndAgency, order]);

	const contentComponent = useMemo(() => {
		if (agencyIsLoading) {
			return (
				<Center>
					<Spinner my={20} size="xl" thickness={"3px"} />
				</Center>
			);
		}

		if (orderedAgencies.length) {
			return (
				<Flex overflow={"auto"}>
					<HStack gap={4} p={4}>
						{orderedAgencies.map(agencyWithNegotiation => (
							<AgencyInformationCard
								data={agencyWithNegotiation}
								key={agencyWithNegotiation.agency.uid}
							/>
						))}
					</HStack>
				</Flex>
			);
		}

		return (
			<Center my={20}>
				<EmptyMessage
					text={t("nenhuma-agencia-encontrada")}
					icon="fa6-solid:building-circle-xmark"
				/>
			</Center>
		);
	}, [agencyIsLoading, orderedAgencies, t]);

	return (
		<Box>
			<Flex my={4} justify="space-between" align="center" gap={4}>
				<PageHeader
					title={t("agencias")}
					subtitle={t("descricao-header-lista-de-agencias-para-negociar")}
					icon="mdi:sitemap"
				/>

				<Tooltip label={collapseController.isOpen ? "Esconder" : "Mostrar"} hasArrow>
					<Badge badgeContent={orderedAgencies.length} color="secondary">
						<IconifyIconButton
							alt="controller-collapse-button"
							size="sm"
							rounded="full"
							icon={collapseController.isOpen ? "mdi:arrow-up" : "mdi:arrow-down"}
							{...collapseController.getButtonProps()}
						/>
					</Badge>
				</Tooltip>
			</Flex>
			<Collapse in={collapseController.isOpen}>
				<HStack>
					<Text>{t("ordenar-agencias-por")}</Text>
					<Select
						value={order}
						onChange={e => setOrder(Number(e.target.value))}
						variant="unstyled"
						w="fit-content"
						cursor="pointer"
						fontWeight="bold"
						pt={"1px"}
						size="sm">
						<option value={FilterOption["Credenciamento"]}>
							{t("credenciamento")}
						</option>
						<option value={FilterOption["Melhor Avaliação"]}>
							{t("melhor.avaliacao")}
						</option>
						<option value={FilterOption["Pior Avaliação"]}>
							{t("pior.avaliacao")}
						</option>
						<option value={FilterOption["Menor valor por hora"]}>
							{t("menor-valor-por-hora")}
						</option>
						<option value={FilterOption["Maior valor por hora"]}>
							{t("maior-valor-por-hora")}
						</option>
					</Select>
				</HStack>
				{contentComponent}
			</Collapse>
		</Box>
	);
}
