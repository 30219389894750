import {
    // DOWNLOADED_RECURSOS, GERAR_NOTIFICACOES,
    NOTIFICADO_AVALIACAO_PENDENTE,
    // ROTEIRO_AVALIADO,
    LOJAS_DA_EMPRESA,
    MARCAS_DA_EMPRESA,
    ATUALIZAR_MARCA,
    CONFIGURACAO,
    LOCALIZACAO,
    NOTAS_PENDENTES,
    SET_REDES,
} from "./Types";

export const notificadoAvaliacaoPendente = () => ({
    type: NOTIFICADO_AVALIACAO_PENDENTE,
});

export const lojasDaEmpresa = (snap) => ({
    type: LOJAS_DA_EMPRESA,
    lojas: snap.docs.map((doc) => doc.data()),
});

export const localizacao = (location) => (dispatch) => {
    dispatch({
        type: LOCALIZACAO,
        location,
    });
    return Promise.resolve();
};

export const marcasDaEmpresa = (snap) => ({
    type: MARCAS_DA_EMPRESA,
    marcas: snap.docs.map((doc) => doc.data()),
});

export const atualizarMarca = (marca) => ({
    type: ATUALIZAR_MARCA,
    marca,
});

export const configuracao = (snap) => ({
    type: CONFIGURACAO,
    configuracao: snap.docs.map((doc) => doc.data())[0],
});

export const notasPendentes = (snap) => ({
    type: NOTAS_PENDENTES,
    notasPendentes: snap.docs.map((doc) => doc.data()),
});

export const setRedes = (data) => ({
    type: SET_REDES,
    redes: data,
});
