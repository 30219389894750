import { deleteDuplicate, omitUndefinedProps } from "toolkit-extra";
import { OperationArea } from "../../@types/OperationArea";
import { MongodbHelper } from "../../helpers/Mongodb";
import { mongoDBAtlas } from "../Api";

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Serviço para interagir com os recursos de áreas de atuação no banco de dados.
 */
export class OperationAreaService {
	private static instance: OperationAreaService;

	public static getInstance(): OperationAreaService {
		if (!OperationAreaService.instance) {
			OperationAreaService.instance = new OperationAreaService();
		}
		return OperationAreaService.instance;
	}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função para criar uma nova área de atenção no banco de dados.
	 * @param data Objeto contendo as propriedades necessárias para criar uma área de atuação.
	 * @returns Uma promise com a área de atenção criada.
	 */
	async createOne(
		data: Omit<OperationArea, "createdAt" | "updatedAt" | "_id">
	): Promise<OperationArea> {
		const operationArea = omitUndefinedProps<Omit<OperationArea, "_id">>({
			...data,
			createdAt: new Date(),
			updatedAt: new Date(),
		});

		const mongoDBReturn = (await mongoDBAtlas("POST", "operationArea", "/action/insertOne", {
			document: {
				...operationArea,
				createdAt: { $date: operationArea.createdAt },
				updatedAt: { $date: operationArea.updatedAt },
			},
		})) as unknown as { insertedId: string };

		return { ...operationArea, _id: mongoDBReturn.insertedId };
	}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função para atualizar uma área de atenção no banco de dados.
	 * @param data Objeto contendo as propriedades necessárias para atualizar uma área de atenção.
	 * @returns Os dados que foram atualizados da área de atuação.
	 */
	async updateOne(data: Omit<OperationArea, "createdAt" | "updatedAt" | "companyId">) {
		//NOTE: Separa o _id do resto das propriedades.
		const { _id, ...restProps } = data;

		//NOTE: Cria um objeto com as propriedades que serão atualizadas.
		const operationArea = omitUndefinedProps<
			Omit<OperationArea, "_id" | "createdAt" | "companyId">
		>({
			...restProps,
			updatedAt: new Date(),
		});

		//NOTE: Atualiza o documento no MongoDB.
		await mongoDBAtlas("POST", "operationArea", "/action/updateOne", {
			filter: {
				_id: { $oid: _id },
			},
			update: {
				$set: {
					...operationArea,
					updatedAt: { $date: operationArea.updatedAt },
				},
			},
		});

		return operationArea;
	}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função para buscar todas as áreas de atuação de uma empresa.
	 * @param companyId Id da empresa associada as áreas de atuação.
	 * @returns Um array contendo as áreas de atuação.
	 */
	async fetchOperationArea(companyId: string): Promise<OperationArea[]> {
		const { documents } = await mongoDBAtlas<OperationArea>(
			"POST",
			"operationArea",
			"/action/aggregate",
			{
				pipeline: [
					{
						$match: {
							companyId: companyId,
						},
					},
				],
			}
		);

		return documents.map(op => ({
			...op,
			createdAt: new Date(op.createdAt),
			updatedAt: new Date(op.updatedAt),
		}));
	}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função para contar quantos documentos de área de atuação uma empresa tem.
	 * @param companyId Id da empresa associada as áreas de atuação.
	 * @returns A quantidade de documentos.
	 */
	async countDocs(companyId: string): Promise<number> {
		const { documents } = await mongoDBAtlas<{ _id?: null; total?: number }>(
			"POST",
			"operationArea",
			"/action/aggregate",
			{
				pipeline: [
					{
						$match: {
							companyId: companyId,
						},
					},

					{
						$group: {
							_id: null,
							total: { $sum: 1 },
						},
					},
				],
			}
		);

		const result = documents[0];

		return result?.total || 0;
	}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função para remover uma área de atuação no MongoDB.
	 * @param operationAreaId Id da área de atuação que será excluída
	 * @returns Uma promise com um objeto contendo a quantidade de itens excluídos
	 */
	deleteOne(operationAreaId: string) {
		return mongoDBAtlas("POST", "operationArea", "/action/deleteOne", {
			filter: {
				_id: { $oid: operationAreaId },
			},
		}) as unknown as Promise<{ deletedCount: number }>;
	}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função que busca todas as áreas de atuação em que o campo `coordinate` contém coordenadas que constam dentro dos polígonos da área de atuação.
	 * @param coordinate Coordenadas em longitude e latitude.
	 * @param companyIdToExclude ID da empresa que a função vai ser excluída da consulta.
	 * @returns Um array de objetos contendo o `_id` das áreas de atuação.
	 */
	async getOperationAreasContainingCoordinate(
		coordinate: { _long: number; _lat: number },
		companyIdToExclude?: string
	) {
		/**
		 * Array que conterá alguns os passos de pipeline da consulta.
		 */
		const pipeline: object[] = [];

		//NOTE: Se for passado o ID da empresa que será excluída da consulta, adiciona o filtro.
		if (companyIdToExclude) {
			pipeline.push({
				$match: {
					companyId: {
						$ne: companyIdToExclude,
					},
				},
			});
		}
		const fetchPolygon = mongoDBAtlas<{ _id: string }>(
			"POST",
			"operationArea",
			"/action/aggregate",
			{
				pipeline: [
					...pipeline,
					{
						$match: {
							"geometry.type": "Polygon",
							geometry: {
								$geoIntersects: {
									$geometry: {
										type: "Point",
										coordinates: [coordinate._long, coordinate._lat],
									},
								},
							},
						},
					},
					{
						$group: {
							_id: "$companyId",
						},
					},
				],
			}
		);

		const fetchCircumference = mongoDBAtlas<{ _id: string }>(
			"POST",
			"operationArea",
			"/action/aggregate",
			{
				pipeline: [
					...pipeline,
					...MongodbHelper.getInstance().generateCircumferenceHaversinePipeline(
						coordinate._lat,
						coordinate._long
					),
					{
						$group: {
							_id: "$companyId",
						},
					},
				],
			}
		);

		//NOTE: Junta os resultados das duas consultas. Aplica um flatMap para criar um array só de strings de ids e depois remove os duplicados.
		const fetchResult = deleteDuplicate(
			(await Promise.all([fetchPolygon, fetchCircumference])).flatMap(i =>
				i.documents.map(i => i._id)
			)
		);

		return fetchResult;
	}
}
