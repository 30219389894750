import { Box, Grid, GridProps, Heading, Text } from "@chakra-ui/react";

import { Card } from "../Card";
import { Iconify } from "../Iconify";

interface EmptyContentMessageProps extends GridProps {
	title: string;
	message: string;
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Renderiza uma mensagem de conteúdo vazio.
 */
export function EmptyContentMessage({ title, message, ...restProps }: EmptyContentMessageProps) {
	return (
		<Grid
			justifyItems="center"
			alignItems="center"
			alignContent="center"
			gap={4}
			as={Card}
			w="fit-content"
			p={10}
			{...restProps}>
			<Iconify icon="fa-solid:box-open" color="purple.500" boxSize="28" />
			<Box textAlign="center">
				<Heading fontSize="2xl">{title}</Heading>
				<Text fontSize="sm" color="gray.600">
					{message}
				</Text>
			</Box>
		</Grid>
	);
}
