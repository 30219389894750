import { Box, chakra, Flex, Grid, GridItem, Text, Tooltip } from "@chakra-ui/react";
import L from "leaflet";
import numeral from "numeral";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FeatureGroup, MapContainer, TileLayer } from "react-leaflet";
import { toast } from "react-toastify";
import { OperationAreaFormProps } from "..";
import { GeomanControl } from "../../../../Componentes/GeomanControl";
import { Iconify } from "../../../../Componentes/Iconify";
import { layerToGeoJSON } from "../../../../helpers/Leaflet";
import { getAreaAndGeometryFromLayer } from "../../../../helpers/GeographyCalculation";
import { useConfigs } from "../../../store/useConfigs";
import { PolygonOrCircleToEdit } from "./PolygonOrCircleToEdit";
import { MapHeaderActions } from "./MapHeaderActions";
import { LeafletZoomController } from "./ZoomController";

const ChakraMapContainer = chakra(MapContainer);

interface OperationAreaMapProps {
	editMode?: boolean;
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Um componente que permite desenhar áreas no mapa usando o [Leaflet](https://leafletjs.com/) e [Geoman](https://geoman.io/) e guardar os dados do desenho como um geoJSON no `react-hook-form`.
 * @obs O componente **deve** ser renderizado dentro de um `FormProvider` e atualmente está bem acoplado com a criação de áreas de atuação.
 */
export function OperationAreaMap({ editMode }: OperationAreaMapProps) {
	const mapRef = useRef<L.Map>(null);
	const { t } = useTranslation();
	const { areaDeAtuacaoMaxima } = useConfigs();
	const { getValues, watch, setValue } = useFormContext<OperationAreaFormProps>();

	const currentOperationAreaColor = watch("color");
	const zoomLevel = watch("zoomLevel");
	const mapCenter = watch("mapCenter");

	const {
		field: { onChange, value, ref },
		fieldState: { error },
	} = useController<OperationAreaFormProps, "geometricShape">({
		name: "geometricShape",
	});

	useEffect(() => {
		if (!editMode) {
			navigator.geolocation.getCurrentPosition(position => {
				const newCord: [number, number] = [
					position.coords.latitude,
					position.coords.longitude,
				];
				setValue("mapCenter", newCord);
				mapRef.current?.setView(newCord);
			});
		}
	}, [editMode, setValue]);

	//NOTE: Sempre que a cor for alterada, alteramos o estilo do shape no mapa.
	useEffect(() => {
		const layers = mapRef.current?.pm.getGeomanLayers();

		if (layers?.length) {
			(layers[0] as L.Circle | L.Polygon).setStyle({
				color: currentOperationAreaColor,
				fillColor: currentOperationAreaColor,
				weight: 2,
				opacity: 1,
				fillOpacity: 0.3,
			});
		}
	}, [currentOperationAreaColor]);

	const handleOnCreateShape = useCallback<L.PM.CreateEventHandler>(
		e => {
			const geomanLayers = mapRef.current?.pm.getGeomanLayers().length || 0;

			if (geomanLayers > 1) {
				toast.info(t("aviso-toast-apenas-uma-area-no-mapa"));
				e.layer.remove();
				return;
			}

			const currentOperationAreaColor = getValues("color");

			(e.layer as L.Circle | L.Polygon).setStyle({
				color: currentOperationAreaColor,
				weight: 2,
				opacity: 1,
				fillColor: currentOperationAreaColor,
				fillOpacity: 0.3,
				dashArray: "5, 5",
			});

			const geometry = layerToGeoJSON(e.layer);

			onChange(geometry);
		},
		[getValues, onChange, t]
	);

	const handleOnEditShape = useCallback<L.PM.EditEventHandler>(
		e => {
			const geometry = layerToGeoJSON(e.layer);

			onChange(geometry);
		},
		[onChange]
	);

	const handleOnRemoveShape = useCallback<L.PM.RemoveEventHandler>(() => {
		mapRef.current?.pm.disableGlobalRemovalMode();
		onChange(null);
	}, [onChange]);

	const personalizedErros = useMemo(() => {
		let minAreaQuantityError = false;
		let maxAreaSizeError = false;

		if (!value) {
			minAreaQuantityError = true;
			maxAreaSizeError = true;
		}

		if (value) {
			const { area } = getAreaAndGeometryFromLayer(value);

			if (area > areaDeAtuacaoMaxima) {
				maxAreaSizeError = true;
			}
		}

		return {
			minAreaQuantityError,
			maxAreaSizeError,
		};
	}, [areaDeAtuacaoMaxima, value]);

	return (
		<Grid gap={5} ref={ref}>
			<Text as={"label"} htmlFor="operationAreaMap" fontWeight="bold" m={0}>
				{t("mapa")}
			</Text>
			<Grid>
				<MapHeaderActions mapRef={mapRef} />
			</Grid>
			<Box
				id="operationAreaMap"
				h={"2xl"}
				rounded="lg"
				overflow="hidden"
				shadow={"lg"}
				border={error ? "2px solid red" : undefined}>
				<ChakraMapContainer center={mapCenter} zoom={zoomLevel} ref={mapRef} h="full">
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					<LeafletZoomController />
					<FeatureGroup>
						<GeomanControl
							position="topright"
							drawText={false}
							drawPolyline={false}
							drawCircleMarker={false}
							drawMarker={false}
							cutPolygon={false}
							pmCreate={handleOnCreateShape}
							pmRemove={handleOnRemoveShape}
							pmUpdate={handleOnEditShape}
						/>
						<PolygonOrCircleToEdit
							editMode={editMode}
							shape={value}
							onChange={handleOnEditShape}
						/>
					</FeatureGroup>
				</ChakraMapContainer>
			</Box>
			<Grid
				fontWeight="medium"
				templateColumns={{
					base: "1fr",
					md: "1fr 1fr",
				}}
				gap={1}>
				<GridItem>
					<Text color={personalizedErros.minAreaQuantityError ? "red.500" : "green.600"}>
						<Iconify
							icon={
								personalizedErros.minAreaQuantityError ? "mdi:close" : "mdi:check"
							}
							inline
						/>
						{t("check-de-validacao-uma-area-no-mapa")}
					</Text>
				</GridItem>
				<GridItem>
					<Flex direction="column" w="fit-content">
						<Text color={personalizedErros.maxAreaSizeError ? "red.500" : "green.600"}>
							<Iconify
								icon={
									personalizedErros.maxAreaSizeError ? "mdi:close" : "mdi:check"
								}
								inline
							/>
							{t("check-de-validacao-area-maxima", {
								maxAreaSize: numeral(areaDeAtuacaoMaxima / 1_000_000).format(
									"0,0.0"
								),
							})}
						</Text>
						{!!value && (
							<Tooltip label={t("area-do-desenho-no-mapa")} hasArrow>
								<Text
									color="gray.600"
									fontWeight="thin"
									fontSize="sm"
									lineHeight={0.8}
									alignSelf="flex-end">
									{numeral(
										getAreaAndGeometryFromLayer(value).area / 1_000_000
									).format("0,0.0")}{" "}
									km²
								</Text>
							</Tooltip>
						)}
					</Flex>
				</GridItem>
			</Grid>
		</Grid>
	);
}
