import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import onClickOutside from "react-onclickoutside";
import { Link } from "react-router-dom";
import { Dimmer, Header, Icon, Loader } from "semantic-ui-react";
import { Icon as Iconify } from "@iconify/react";
import { HStack, Text } from "@chakra-ui/react";

var styles = {
	headerNotification: {
		display: "flex",
		flexDirection: "row",
		color: "#fff",
		background: "#7501c0",
		justifyContent: "space-between",
		alignItems: "center",
		height: 65,
		paddingTop: 10,
		paddingLeft: 15,
		paddingRight: 10,
	},
	titleNotification: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 19,
		paddingRight: 10,
	},
};

class Notificacoes extends Component {
	handleClickOutside = event => {
		if (this.props.checked) {
			this.props.checkNotificacao();
		}
	};

	render() {
		const { t, modulo } = this.props;
		return (
			<div className="notificacao">
				<header style={styles.headerNotification}>
					<HStack>
						<Iconify icon="mdi:bell" width={25} height={25} />
						<Text>{t("minhas.notificacoes")}</Text>
					</HStack>
					{this.props.notificacoes.length > 0 && (
						<button
							onClick={() => this.props.removerTodas()}
							style={{ background: "none", border: "none", color: "#fff" }}>
							<Iconify icon="mdi:delete" width={25} height={25} />
						</button>
					)}
				</header>
				{this.props.apagando && (
					<Dimmer active>
						<Loader content={t("apagando")}></Loader>
					</Dimmer>
				)}
				{this.props.notificacoes.length <= 0 && (
					<h3 style={{ fontSize: 15, color: "#fff", textAlign: "center", marginTop: 40 }}>
						{t("voce.nao.possui.nenhuma.notificacao")}
					</h3>
				)}
				{this.props.notificacoes.map((item, index) => {
					let agencia = `/buscar/${item.idRoteiro}`;
					if (item.link.includes("/loja")) {
						item.link = item.link.slice(5);
					}
					return (
						<Header key={index} as="h5" inverted style={styles.titleNotification}>
							<Header.Content
								style={{
									color: "#fff",
									cursor: "pointer",
									zIndex: 500,
									fontSize: 18,
								}}
								as={Link}
								to={{
									pathname: "/loading",
									state: {
										linkAgencia: agencia,
										link: item.link,
										modulo: modulo,
									},
								}}
								onClick={() => {
									this.props.removerNotificacao(item, index);
								}}>
								{item.titulo}
								<Header.Subheader
									style={{
										marginRight: 20,
										fontSize: 13,
										blockOverflow: "ellipsis",
										maxLines: 2,
									}}>
									{item.descricao}
								</Header.Subheader>
							</Header.Content>
							<Icon
								name="times"
								style={{ color: "#fff" }}
								className="remover-notificacao"
								title={t("remover")}
								onClick={() => this.props.removerNotificacao(item, index)}
							/>
						</Header>
					);
				})}
			</div>
		);
	}
}

export default withTranslation()(onClickOutside(Notificacoes));
