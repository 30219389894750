import React from 'react'
import { Drawer, CircularProgress } from '@material-ui/core'

export default (props) => {
    let { callback, open, onClose, titulo, mensagem, isLoading, loadMessage, errorTitle, errorMessage, error, confirmar, cancelar, fechar } = props
    return (
        <Drawer anchor={'bottom'} open={open} onClose={onClose}>
            <div style={styles.principal}>
                {isLoading ?
                    <div style={{ ...styles.principal, padding: 30 }}>
                        <p style={styles.content}>{loadMessage}</p>
                        <CircularProgress color='inherit' />
                    </div>
                    :
                    !error ?
                        <>
                            <p style={styles.titulo}>{titulo}</p>
                            <p style={styles.mensagem}>{mensagem}</p>
                            <div style={styles.divBotoes}>
                                {cancelar && <button style={styles.botao} onClick={onClose}>{cancelar}</button>}
                                <button style={styles.botao} onClick={callback}>{confirmar}</button>
                            </div>
                        </>
                        :
                        <>
                            {errorTitle && <p style={styles.titulo}>{errorTitle}</p>}
                            <p style={styles.content}>{errorMessage}</p>
                            <div style={styles.divBotoes}>
                                <button style={styles.botao} onClick={onClose}>{fechar}</button>
                            </div>
                        </>
                }
            </div>
        </Drawer>
    )
}

const styles = {
    principal: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    divBotoes: {
        marginBottom: 10
    },
    titulo: {
        marginTop: 10,
        marginBottom: 10,
        fontSize: 20
    },
    mensagem: {
        textAlign: 'center',
        marginLeft: 100,
        marginRight: 100,
        marginBottom: 10,
        fontSize: 16,
        color: 'gray'
    },
    botao: {
        fontWeight: 'bold',
        width: 130,
        height: 50,
        background: '#ffffff',
        borderWidth: 1,
        borderColor: '#602579',
        borderRadius: 4,
        color: '#602579',
        cursor: 'pointer',
        margin: 5,
        marginBottom: 10,
        marginTop: 10
    }
}