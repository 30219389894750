import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Header, Modal } from "semantic-ui-react";
import * as yup from "yup";
import { Company, Opportunity, Promoter } from "..";
import { DropdownForm } from "../../../../../Componentes/Form/DropdownForm";
import { TextAreaForm } from "../../../../../Componentes/Form/TextAreaForm/index";
import { db } from "../../../../../config";
import { ReasonCategory } from "../../../../../enum/Complaint.enum";
import { Container } from "./styles";
import { v4 } from "uuid";
import { toast } from "react-toastify";

const textAreaLimit = 1000;
const complaintOptions = Object.values(ReasonCategory).map((item, index) => {
    return { key: index, text: item, value: item };
});

type FormData = {
    description: string;
    category: ReasonCategory;
};

const FormSchema = yup.object({
    category: yup.string().required("A categoria é obrigatória."),
    description: yup
        .string()
        .min(5, "A descrição deve ter no mínimo 5 caracteres.")
        .max(textAreaLimit, `A descrição não pode passar de ${textAreaLimit} caracteres.`)
        .required("A descrição é obrigatória."),
});

interface ModalComplaintProps {
    onClose: () => void;
    onOpen: () => void;
    isOpen: boolean;

    promoter: Promoter;
    opportunity: Opportunity;
    company: Company;
}

export function ModalComplaint({ isOpen, onClose, onOpen, ...rest }: ModalComplaintProps) {
    const {
        reset,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(FormSchema),
        defaultValues: {
            description: "",
        },
    });

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen, reset]);

    const handleCreateComplaint: SubmitHandler<FormData> = async (values) => {
        const id = v4();
        const data = {
            id,
            data: new Date(),
            denunciado: rest.promoter.id,
            denunciante: rest.company.id,
            motivo: values.category,
            nomeDenunciado: rest.promoter.name,
            oportunidade: rest.opportunity.id,
            resumo: values.description,
            status: "Não Resolvido",
        };

        toast
            .promise(db.collection("denuncias").doc(id).set(data), {
                error: "Tivemos um problema para enviar sua denuncia. 😥",
                success: "Denúncia enviada com sucesso!",
                pending: "Enviando denúncia...",
            })
            .catch((error) => {
                console.log(error);
            });
        onClose();
    };

    return (
        <Container open={isOpen} onOpen={onOpen} onClose={onClose} closeOnDocumentClick>
            <Modal.Header>Denunciar promotor</Modal.Header>
            <Modal.Content>
                <form id="formComplaint" onSubmit={handleSubmit(handleCreateComplaint)}>
                    <Modal.Description>
                        <Header>Deseja denunciar {rest.promoter.name}?</Header>
                    </Modal.Description>
                    <DropdownForm
                        isRequired
                        control={control}
                        reactHookError={errors.category}
                        options={complaintOptions}
                        name="category"
                        label="Categoria"
                        placeholder="Selecione uma categoria"
                    />
                    <TextAreaForm
                        isRequired
                        control={control}
                        limit={textAreaLimit}
                        reactHookError={errors.description}
                        label="Descrição"
                        placeholder="Descrição da denuncia"
                        name="description"
                    />
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} negative>
                    Cancelar
                </Button>
                <Button type="submit" form="formComplaint" positive>
                    Denunciar
                </Button>
            </Modal.Actions>
        </Container>
    );
}
