import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Menu1 from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from "@material-ui/icons/AccountCircle";
import BarChart from "@material-ui/icons/BarChart";
import BugReportIcon from "@material-ui/icons/BugReport";
import Group from "@material-ui/icons/Group";
import Star from "@material-ui/icons/Star";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import onClickOutside from "react-onclickoutside";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button, Icon, Input, Label } from "semantic-ui-react";
import { ListaDeEmpresas } from "../Interno/importarProdutos/importarProdutos";
import { alternarModulo, desautenticaUsuario } from "../_actions/AutenticacaoActions";
import { auth } from "./../config";
import { imagemOuDefault } from "./../Utils/Login";
import menus, { filtrarMenuToDict, filtroMenuPorTexto } from "./../Utils/menus";
import { ImagemCircular } from "./Componentes";
import { MenuCountBadge } from "./MenuCountBadge";
var conf = require("../configuration.json");

function ListItemLink(props) {
	return <ListItem button component={Link} {...props} />;
}

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menus: menus,
			anchorMenu: null,
		};
	}

	componentDidMount() {
		let verificarSeJaFoiAdicionado = menus[0].menus.find(
			({ nome }) => nome === "importacao.triunfante"
		);
		if (ListaDeEmpresas.includes(this.props.empresa.uid) && !verificarSeJaFoiAdicionado) {
			menus[0].menus.push({
				nome: "importacao.triunfante",
				icon: "boxes",
				link: "/produto/importarTriunfante",
				menu: true,
			});
			this.setState({ menus: menus });
		}
	}

	logout = () => {
		auth.signOut().then(() => {
			this.props.desautenticaUsuario();
			window.localStorage.removeItem("modulo");
			this.props.history.push("/login");
		});
	};

	handleClickOutside = event => {
		let e = event.target;
		let isMenu = false;
		let isMenuSistema = false;
		if (e.classList.contains("open-menu")) {
			isMenu = e.classList.contains("open-menu");
		}
		if (e.classList.contains("menu-sistema")) {
			isMenuSistema = e.classList.contains("menu-sistema");
		}
		if (isMenu || isMenuSistema) {
			event.preventDefault();
			return;
		}
		if (this.props.checked) {
			this.props.checkMenu();
		}
	};

	filtrarMenu = event => {
		const { t } = this.props;
		let updateMenu;
		if (event.target.value === "") {
			updateMenu = menus;
		} else {
			const filtro = filtroMenuPorTexto(t, event.target.value.toLowerCase());
			updateMenu = filtrarMenuToDict(t, filtro, menus);
		}
		this.setState({ menus: updateMenu });
	};

	resetaFiltro = event => {
		const { checkMenu } = this.props;
		this.setState({ menus }, () => {
			const filtroInput = document.querySelector(".menu-search input");
			filtroInput.value = "";
			if (this.props.checked) {
				checkMenu();
			}
		});
	};

	handleOpenMenu = e =>
		this.setState({ anchorMenu: this.state.anchorMenu ? null : e.currentTarget });

	render() {
		const { menus, anchorMenu } = this.state;
		const { t, checked, menuAberto, openMenu } = this.props;
		const { imagemURL, fantasia, email } = this.props.empresa
			? this.props.empresa
			: { imagemURL: "", fantasia: "", razaoSocial: "", email: "" };
		const { img } = this.props.mercado ? this.props.mercado : { img: "" };
		var l = 0;
		return (
			<>
				<div
					className={
						"menu-sistema " +
						(checked ? "aberto" : "fechado") +
						(menuAberto ? " menu-sistema-fechado" : "")
					}>
					<div className="menu-mobile" onClick={openMenu}>
						<div>
							<Icon className="menu-mobile-icon" name="bars" size="big" />
						</div>
					</div>
					<div>
						<div className="header-menu" onClick={this.handleOpenMenu}>
							<div>
								<ImagemCircular
									size={checked ? "medio-1" : "medio"}
									src={imagemOuDefault(this.props.modulo === 3 ? img : imagemURL)}
								/>
							</div>
							{checked && (
								<span className="fantasia-empresa-menu">
									{this.props.modulo === 3
										? this.props.mercado.contato.nome
										: fantasia}
									<br />
									<span>
										{this.props.modulo === 3
											? this.props.mercado.contato.email
											: email}
									</span>
								</span>
							)}
						</div>
					</div>
					<Menu1
						id="menu-imagem-perfil"
						anchorEl={anchorMenu}
						keepMounted
						open={Boolean(anchorMenu)}
						onClose={this.handleOpenMenu}>
						<Link to="/perfil">
							<MenuItem onClick={this.handleOpenMenu}>
								<ListItemIcon>
									<AccountCircle />
								</ListItemIcon>
								{t("perfil")}
							</MenuItem>
						</Link>
						<Link to="/selecaoAutomatica">
							<MenuItem onClick={this.handleOpenMenu}>
								<ListItemIcon>
									<Star />
								</ListItemIcon>
								{t("promotores")}
							</MenuItem>
						</Link>
						<Link to="/usuarios">
							<MenuItem onClick={this.handleOpenMenu}>
								<ListItemIcon>
									<Group />
								</ListItemIcon>
								{t("usuarios")}
							</MenuItem>
						</Link>
						<Link to="/financeiro">
							<MenuItem onClick={this.handleOpenMenu}>
								<ListItemIcon>
									<BarChart />
								</ListItemIcon>
								{t("financeiro")}
							</MenuItem>
						</Link>
						<Link to="/logs">
							<MenuItem onClick={this.handleOpenMenu}>
								<ListItemIcon>
									<BugReportIcon />
								</ListItemIcon>
								{t("registro.logs")}
							</MenuItem>
						</Link>
					</Menu1>
					{checked ? (
						<Input
							fluid
							className="menu-search"
							icon="search"
							placeholder="Pesquisa..."
							onChange={this.filtrarMenu}
						/>
					) : (
						<></>
					)}
					<div
						className={
							"menu-navegacao " + (menuAberto ? "menu-navegacao-fechado" : "")
						}>
						{menus.map((item, i) => {
							let Lista = () => (
								<List
									className="menus-list"
									key={item.titulo}
									component="nav"
									subheader={
										checked ? (
											<ListSubheader component="div" disableSticky>
												{t(item.titulo)}
											</ListSubheader>
										) : l > 0 ? (
											<div className="divider-menu"></div>
										) : (
											<></>
										)
									}>
									{}
									{item.menus.map(menu =>
										!menu.menu ? null : (
											<ListItemLink
												className={`lista-menus ${
													this.props.location.pathname === menu.link
														? "menu-atual"
														: ""
												}`}
												button
												to={menu.link}
												key={menu.nome}>
												<ListItemIcon
													className={
														checked ? "" : "menu-icon-center icon-menu"
													}>
													<div className="square-icon">
														<MenuCountBadge variant={menu.nome}>
															<Icon
																name={menu.icon}
																size="large"
																inverted
															/>
														</MenuCountBadge>
														{!checked && (
															<Label
																className="label-icon"
																pointing="left"
																size="large"
																color="purple">
																{t(menu.nome)}
															</Label>
														)}
													</div>
												</ListItemIcon>

												{checked && (
													<ListItemText
														primary={t(menu.nome)}
														style={{ color: "#fafafa" }}
													/>
												)}
											</ListItemLink>
										)
									)}
								</List>
							);
							if (this.props.modulo === 3) {
								if (item.modulo === 3) {
									l++;
									return <Lista key={item.titulo} />;
								}
							}
							if (item.modulo === this.props.modulo || item.modulo === 0) {
								l++;
								return <Lista key={item.titulo} />;
							}
							return null;
						})}
					</div>
					<div className={"footer-menu " + (menuAberto ? "footer-menu-fechado" : "")}>
						<div className="sair-menu">
							{checked ? (
								<Button
									content={t("sair")}
									icon="sign-out"
									basic
									inverted
									onClick={this.logout}
								/>
							) : (
								<Button basic inverted icon onClick={this.logout}>
									<Icon name="sign-out" />
								</Button>
							)}
						</div>
						{checked && (
							<div className="versao-menu">
								<div>{conf.version.split("-")[0]}</div>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}
}

const mapsStateToProps = state => ({ ...state.AutenticacaoReducer });

export default withRouter(
	connect(mapsStateToProps, { alternarModulo, desautenticaUsuario })(
		withTranslation()(onClickOutside(Menu))
	)
);
