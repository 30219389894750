import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Table } from 'semantic-ui-react';

const Label = ({ content }) => <span className='row-table-label'>{content}</span>

export const renderizarOportunidades = (state, consolidado, t) => {
    let direcao = state.order['pecentualOp'] === "ascending" ? "asc" : "desc"
    return _.orderBy(consolidado, ['rupturaPorOportunidade.percentual'], [direcao]).map(({ rupturaPorOportunidade, loja, valor, data, _id }) => {
        return rupturaPorOportunidade && <Table.Row key={_id}>
            <Table.Cell>
                <Header as='h4'>
                    <Header.Content>
                        <Link to={`oportunidade/info/${_id}`} className="link-oportunidade-lista">
                            {loja.fantasia}
                        </Link>
                        <Header.Subheader>{`${moment(data).format('DD/MM/YYYY')} | ${valor.toLocaleString('BRL', { style: 'currency', currency: 'BRL' })}`}</Header.Subheader>
                    </Header.Content>
                </Header>
            </Table.Cell>
            <Table.Cell><span style={{ display: "flex" }}><Label content={`${t('produtos')}:`} /> {rupturaPorOportunidade.produtos}</span></Table.Cell>
            <Table.Cell><span style={{ display: "flex" }}><Label content={`${t('rupturas')}:`} /> {rupturaPorOportunidade.rupturas}</span></Table.Cell>
            <Table.Cell><span style={{ display: "flex" }}><Label content={`${t('percentual')}:`} /> {`${rupturaPorOportunidade.percentual.toFixed()}%`}</span></Table.Cell>
        </Table.Row>
    })
}

export const renderizarPdv = (state, pdvs, t) => {
    let direcao = state.order['pecentualPDV'] === "ascending" ? "asc" : "desc"
    return _.map(_.orderBy(pdvs, ["rupturasPdv.percentual"], [direcao]),
        ({ rupturasPdv: { percentual, produtos, rupturas }, pdv: { fantasia, endereco: { endereco } } }, key) => {
            return <Table.Row key={key}>
                <Table.Cell>
                    <Header as='h4'>
                        <Header.Content>
                            {fantasia}
                            <Header.Subheader>{endereco}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Table.Cell>
                <Table.Cell><span style={{ display: "flex" }}><Label content={`${t('produtos')}:`} />{produtos}</span></Table.Cell>
                <Table.Cell><span style={{ display: "flex" }}><Label content={`${t('rupturas')}:`} />{rupturas}</span></Table.Cell>
                <Table.Cell><span style={{ display: "flex" }}><Label content={`${t('percentual')}:`} />{`${percentual.toFixed()}%`}</span></Table.Cell>
            </Table.Row>
        })
}

export const renderizarProdutos = (state, produtos, t) => {
    let direcao = state.order['pecentualProd'] === "ascending" ? "asc" : "desc"
    return _.map(_.orderBy(produtos, ["percentual"], [direcao]),
        ({ frequencia, rupturas, percentual, produto: { descricao, preco }, key }) => {
            return <Table.Row key={key}>
                <Table.Cell>
                    <Header as='h4'>
                        <Header.Content>
                            {descricao}
                            <Header.Subheader>{preco && parseFloat(preco).toLocaleString('BRL', { style: 'currency', currency: 'BRL' })}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Table.Cell>
                <Table.Cell><span style={{ display: "flex" }}><Label content={`${t('frequencia')}:`} />{frequencia}</span></Table.Cell>
                <Table.Cell><span style={{ display: "flex" }}><Label content={`${t('rupturas')}:`} />{rupturas}</span></Table.Cell>
                <Table.Cell><span style={{ display: "flex" }}><Label content={`${t('percentual')}:`} />{`${percentual.toFixed()}%`}</span></Table.Cell>
            </Table.Row>
        })
}