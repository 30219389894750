import { OPORTUNIDADES_DASHBOARD, DESAUTENTICA_USUARIO, OPORTUNIDADES_AGENCIA } from "../_actions/Types";


const INITIAL_STATE = {
    oportunidadesPorDia: {},
    oportunidadesAgencia: []
};

const reducers = {
    [OPORTUNIDADES_DASHBOARD]: (state, action) => {
        return {
            ...state,
            oportunidadesPorDia: action.oportunidadesPorDia
        };
    },
    [OPORTUNIDADES_AGENCIA]: (state, action) => {
        return {
            ...state,
            oportunidadesAgencia: action.oportunidadesAgencia
        };
    },
    [DESAUTENTICA_USUARIO]: () => ({
        oportunidadesPorDia: {},
        oportunidadesAgencia: []
    })
};

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type];
    if (reducer) return reducer(state, action)
    return state;
};
