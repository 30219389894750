import {
	Box,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputProps,
	Text,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useController, UseControllerProps } from "react-hook-form";
import { enhancedContrastColor } from "../../../Utils/Colors";

type ColorPickerProps<T> = Omit<InputProps, "type"> &
	UseControllerProps<T> & {
		label?: string;
		enableTextError?: boolean;
	};

/**
 * Renderiza um input seletor de cores do `Chakra UI` controlado pelo `react-hook-form`
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function ColorPickerForm<T>({
	label,
	control,
	name,
	rules,
	enableTextError = true,
	defaultValue,
	isRequired,
	isDisabled,
	isReadOnly,
	...rest
}: ColorPickerProps<T>) {
	const inputRef = useRef<HTMLInputElement>(null);

	const {
		field: { onChange, value, onBlur },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
		defaultValue,
	});

	return (
		<FormControl isInvalid={!!error} isDisabled={isDisabled} isReadOnly={isReadOnly}>
			{!!label && (
				<FormLabel
					htmlFor={name}
					_after={isRequired ? { content: `' *'`, color: "red" } : {}}>
					{label}
				</FormLabel>
			)}
			<InputGroup>
				<Box
					w="32"
					h="10"
					bg={value as string}
					cursor="pointer"
					rounded="md"
					shadow="md"
					display="flex"
					justifyContent="center"
					alignItems="center"
					position="relative"
					onClick={() => inputRef.current?.click()}>
					<Text color={enhancedContrastColor(value as string)} fontWeight="medium">
						{String(value).toUpperCase()}
					</Text>
					<Input
						{...rest}
						position="absolute"
						visibility="hidden"
						type="color"
						ref={inputRef}
						name={name}
						onChange={onChange}
						onBlur={onBlur}
						value={value as string}
					/>
				</Box>
			</InputGroup>
			{!!error && enableTextError && <FormErrorMessage>{error.message}</FormErrorMessage>}
		</FormControl>
	);
}
