import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import update from "immutability-helper";
import { Blocker } from "../../@types/Blocker";
import { SliceStateProps } from "../../@types/store/Store";
import { generateDefaultReducers } from "../../helpers/Redux";
import { DESAUTENTICA_USUARIO } from "../../_actions/Types";

interface StateProps extends SliceStateProps<Blocker> {}

const initialState: StateProps = {
	data: {
		isAcceptProposalBlocked: false,
		isFinishNegotiationBlocked: false,
		isSendCounterProposalBlocked: false,
		isReopenNegotiationBlocked: false,
		isDeleteOperationAreaBlocked: false,
		isCreateOperationAreaBlocked: false,
		isSendReceiptFileBlocked: false,
		isCancelReceiptBlocked: false,
		isDeleteCompanyUserBlocked: false,
		isUpdateCompanyUserBlocked: false,
	},
	initialFetchWasMade: false,
	isFetching: false,
	initialStateHasBeenLoaded: false,
};

export const BlockerSlice = createSlice({
	name: "blocker",
	initialState,
	reducers: {
		...generateDefaultReducers<Blocker>(),
		setData: (state, action: PayloadAction<Partial<Blocker>>) =>
			update(state, { data: { $merge: action.payload } }),
	},
	extraReducers: builder => {
		builder.addCase(DESAUTENTICA_USUARIO, () => initialState);
	},
});

export const { actions } = BlockerSlice;

export default BlockerSlice.reducer;
