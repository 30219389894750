import firebase from "firebase";
import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Input, Message, Popup, Segment } from "semantic-ui-react";
import { ASCENDENTE, Column, DataTable, DIRECAO, SortingIcon } from "../../Componentes/Tabela";
import foto from "../../imagens/TutorialNovoPDV.png";
import { formatCNPJ } from "../../Utils/CNPJ";
import * as d from "../../Utils/Debugging";
import { aplicarFiltro } from "../../Utils/Filtro";
import { arquivarLoja } from "../../Utils/Loja";
import { downloadLojasDaEmpresa } from "../../Utils/Oportunidades";
import { lojasDaEmpresa } from "../../_actions/RecursoActions";
import Tutorial from "../Tutoriais/TutorialGeral";
import { HeaderPadrao } from "./../../Componentes/Componentes";
import Link from "./../../Componentes/Link";

const filtros = {
	codigo: (a, b) => a.codigo.localeCompare(b.codigo),
	fantasia: (a, b) => a.fantasia.localeCompare(b.fantasia),
	"endereco.endereco": (a, b) => a.endereco.endereco.localeCompare(b.endereco.endereco),
};

const DataTableHeaderSearchable = props => {
	const SortingIcon = props.sortingIcon;
	return (
		<div className="header-positioning">
			<div className="header-label" onClick={props.onClick}>
				<span>{props.label}</span>
				<SortingIcon sorting={props.sorting} />
			</div>
			{props.property !== "acoes" ? <Input fluid onChange={props.onFilter} /> : null}
		</div>
	);
};

const formatCodigoOrCNPJ = data => (data.length < 14 ? data : formatCNPJ(data));

export const Loja = props => {
	const { t, handleFiltro, handleSort, handleRemove, temLojas, lojas, colunaOrdenada, direcao } =
		props;
	return (
		<Segment basic>
			{!temLojas ? (
				<Message content={t("mensagem.sem.registros")}></Message>
			) : (
				<DataTable
					dataSource={lojas}
					header={DataTableHeaderSearchable}
					sortingIcon={SortingIcon}>
					<Column
						label="CNPJ"
						property="codigo"
						sort={handleSort("codigo")}
						sorting={colunaOrdenada === "codigo" ? direcao : null}
						onFilter={handleFiltro("codigo")}
						cell={props => formatCodigoOrCNPJ(props.data)}
					/>
					<Column
						label="Nome"
						property="fantasia"
						sort={handleSort("fantasia")}
						sorting={colunaOrdenada === "fantasia" ? direcao : null}
						onFilter={handleFiltro("fantasia")}
					/>
					<Column
						label="Endereço"
						property="endereco.endereco"
						sort={handleSort("endereco.endereco")}
						sorting={colunaOrdenada === "endereco" ? direcao : null}
						onFilter={handleFiltro("endereco.endereco")}
					/>
					<Column
						label={t("acoes")}
						property="acoes"
						cell={props => (
							<>
								<Popup
									content={"Criar oportunidade para essa loja."}
									trigger={
										<Link
											to={`/roteiro/novo/${props.rowData.id}`}
											style={{ color: "#333", marginRight: 5 }}>
											<Button circular basic color="violet" icon="plus" />
										</Link>
									}
								/>
								{props.rowData.idEmpresa ? (
									<>
										<Popup
											content={"Editar informações desta loja."}
											trigger={
												<Link
													to={`/loja/${props.rowData.id}`}
													style={{ color: "#333", marginRight: 5 }}>
													<Button
														circular
														basic
														color="violet"
														icon="edit"
													/>
												</Link>
											}
										/>
										<Popup
											content={"Remover esta loja."}
											trigger={
												<Button
													circular
													basic
													color="violet"
													icon="trash"
													onClick={() => handleRemove(props.rowData)}
												/>
											}
										/>
									</>
								) : null}
							</>
						)}
					/>
				</DataTable>
			)}
		</Segment>
	);
};

export class LojaContainer extends Component {
	state = {
		filtroLojas: null,
		direcao: ASCENDENTE,
		colunaOrdenada: "nome",
	};

	componentWillMount() {
		d.logPath("Loja");
		this.props.lojas == null &&
			firebase
				.firestore()
				.collection("lojasHomologadas")
				.get()
				.then(d => {
					var i = d.docs;
					return i;
				})
				.then(p => {
					downloadLojasDaEmpresa(this.props.idEmpresa).onSnapshot(snap => {
						var s = {
							docs: _.concat(snap.docs, p),
						};
						this.props.dispatch(lojasDaEmpresa(s));
					});
				});
	}

	handleSort = coluna => event => {
		d.log("Loja", "ordenando", `coluna: ${coluna}`, event);
		const { colunaOrdenada } = this.state;
		const direcao = coluna === colunaOrdenada ? this.state.direcao * DIRECAO : ASCENDENTE;
		this.setState({
			direcao,
			colunaOrdenada: coluna,
			filtroLojas: this.props.lojas.sort((a, b) => direcao * filtros[coluna](a, b)),
		});
	};

	handleFiltro = property => event => {
		d.log("Loja", "filtrando", `coluna: ${property}`, event);
		const searchText = event.target.value.toLowerCase();
		this.setState({
			filtroLojas: aplicarFiltro(
				event.target.value === "",
				loja => {
					const text = _.get(loja, property);
					return text && text.toLowerCase().includes(searchText);
				},
				this.props.lojas
			),
		});
	};

	handleRemove = ({ id, fantasia }) => {
		const ok = confirm(`Deseja remover o loja: ${fantasia}?`); // eslint-disable-line no-restricted-globals
		ok && arquivarLoja(id).catch(err => console.error(err));
	};

	render() {
		const { t } = this.props;
		const { filtroLojas } = this.state;
		const lojas = this.props.lojas || [];

		return (
			<div>
				<div
					className="container-header-padrao"
					style={{ justifyContent: "space-between" }}>
					<Button
						style={{ marginTop: "10px" }}
						className="purple-gradient-87"
						as={Link}
						to="/loja/novo"
						icon="plus"
						color="violet"
						floated="right"
						content={t("nova.loja")}></Button>
					<div style={{ width: "50%" }}>
						<HeaderPadrao
							icone="shopping cart"
							titulo={t("lojas")}
							subtitulo={t("suas.lojas")}
						/>
					</div>
				</div>
				<Loja
					{...this.props}
					{...this.state}
					handleSort={this.handleSort}
					handleFiltro={this.handleFiltro}
					handleRemove={this.handleRemove}
					temLojas={lojas.length > 0}
					lojas={filtroLojas || lojas}
				/>
				<Tutorial
					nome={"criarPDV"}
					tutorial={{
						titulo: "Criar um novo PDV",
						primeiroTexto: `Os PDVs são uma parte importante da aplicação, pois sem ele se torna
                    difícil do(a) candidato(a) encontrar o local. Para criar um novo
                    clique no botão no lado superior direito da tela e complete o cadastro do PDV.`,
						segundoTexto: `Se tudo ocorrer bem o PDV vai se encontrar na lista e na criação de oportunidade.`,
						primeiraImagem: foto,
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = d.logState("Loja", state => ({
	idEmpresa: state.AutenticacaoReducer.empresa.uid,
	empresa: state.AutenticacaoReducer.empresa,
	lojas: state.RecursosReducer.lojas,
}));

export default connect(mapStateToProps)(withTranslation()(LojaContainer));
