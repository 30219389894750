// import _ from 'lodash';
import {
    RELATORIOS_DE_RUPTURA, RELATORIOS_PERIODO, RELATORIOS_OPORTUNIDADES, DESAUTENTICA_USUARIO
} from "../_actions/Types";
import moment from 'moment'


const INITIAL_STATE = {
    relatorioDeRuptura: {
        dados: {},
    },
    periodo: {
        dataInicio: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        dataFinal: moment().format('YYYY-MM-DD'),
    },
    oportunidades: []
};

const reducers = {
    [RELATORIOS_DE_RUPTURA]: (state, action) => {
        return {
            ...state,
            relatorioDeRuptura: {
                ...state.relatorioDeRuptura,
                ...action
            }
        };
    },
    [RELATORIOS_PERIODO]: (state, action) => {
        return {
            ...state,
            periodo: {
                ...state.periodo,
                ...action.payload
            }
        };
    },
    [RELATORIOS_OPORTUNIDADES]: (state, action) => {
        return {
            ...state,
            oportunidades: action.oportunidades
        };
    },
    [DESAUTENTICA_USUARIO]: () => ({
        relatorioDeRuptura: {
            dados: {},
        },
        periodo: {
            dataInicio: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            dataFinal: moment().format('YYYY-MM-DD'),
        },
        oportunidades: []
    })
};

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type];
    if (reducer) return reducer(state, action)
    return state;
};
