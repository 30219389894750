import React from "react";
import { Assessments } from "./Assessments";
import Metrics from "./Metrics";
import { Container } from "./styles";

interface Company {
    fantasia?: string;
}

interface Assessment {
    id: string;
    empresa: Company;
    avaliacao: number;
    comentario: string;
    data: Date;
}

interface LeftContentProps {
    metricData: number[];
    assessmentsData: Assessment[];
}

export function LeftContent({ metricData, assessmentsData }: LeftContentProps) {
    return (
        <Container>
            <Metrics data={metricData} />
            <Assessments data={assessmentsData} />
        </Container>
    );
}
