import { Image } from "semantic-ui-react";
import { Icon } from "@iconify/react";
import { Texto } from "../Componentes/TextoSimples";
import {
	Button,
	Container,
	ContainerBody,
	ContainerBodyContent,
	ContainerBodyFooter,
	ContainerBodyHeader,
	iconWhatsapp,
} from "./style";
import logo from "../imagens/logo-nome.svg";
import moment from "moment";
import { iconBloqueado } from "./style";
import { desautenticaUsuario } from "../_actions/AutenticacaoActions";
import { Redirect, useHistory } from "react-router-dom";
import { auth } from "../config";
import { PropsUsuarioBloqueado } from "../@types/componentes/UsuarioBloqueado";
import { isEmpty } from "lodash";

export default function UsuarioBloqueado({ data }: PropsUsuarioBloqueado) {
	const history = useHistory();
	function conversarSuporte() {
		window.open("https://wa.me/554192686415", "_blank");
	}

	async function deslogarUsuario() {
		await auth.signOut();
		desautenticaUsuario();
		window.localStorage.removeItem("modulo");
		history.push("/login");
	}

	if (!auth.currentUser || isEmpty(data)) {
		return <Redirect to={"/login"} />;
	}

	return (
		<Container>
			<ContainerBody>
				<ContainerBodyHeader>
					<Image centered src={logo} size="small" alt="logo promotor freelancer" />
					<Icon
						icon={"line-md:alert-circle-twotone-loop"}
						style={iconBloqueado}
						color="#fc3a3a"
					/>
					<Texto fontSize="24px" fontWeight="bold">
						Acesso Bloqueado
					</Texto>
				</ContainerBodyHeader>
				<ContainerBodyContent>
					<Texto fontSize="20px" fontWeight="bold">
						{data?.titulo}
					</Texto>
					<Texto fontSize="16px">{data?.descricao}</Texto>
				</ContainerBodyContent>
				<ContainerBodyFooter>
					<Texto fontSize="14px" color="#7501c0" fontStyle="italic">
						Data do bloqueio: {moment(data?.dataDoBloqueio).format("DD/MM/YYYY")}
					</Texto>
					<Button onClick={() => conversarSuporte()}>
						<Icon icon={"ic:baseline-whatsapp"} color="white" style={iconWhatsapp} />
						<Texto fontSize="16px" color="white">
							Contato Suporte
						</Texto>
					</Button>
					<Button
						backgroundColor="transparent"
						hover="#efcffc"
						onClick={() => deslogarUsuario()}>
						<Texto fontSize="15px" color="#7501c0">
							Encerrar sessão
						</Texto>
					</Button>
				</ContainerBodyFooter>
			</ContainerBody>
		</Container>
	);
}
