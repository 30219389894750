import LinearProgress from "@material-ui/core/LinearProgress";
import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.br";
import $ from "jquery";
import React, { Component } from "react";
import { BotaoProximo } from "./CadastroEmpresa";
import "./cadastro.css";
export default class Step3 extends Component {
	componentDidMount() {
		this.inputCEP = new Cleave($("#cep"), {
			blocks: [5, 3],
			delimiters: ["-"],
		});
	}

	componentWillUnmount() {
		this.inputCEP = null;
	}

	render() {
		return (
			<div className={this.props.step === 3 ? "d-block" : "d-none"}>
				<div>
					<div className="titulo">{this.props.t("localizacao")}</div>
					<div className="form-group">
						<input
							id="cep"
							name="cep"
							type="text"
							required
							maxLength={9}
							value={this.props.empresa.endereco.cep}
							onBlur={e => {
								// mover buscar cep para a classe principal
								this.props.handleChangeEmpresaEndereco(e);
								this.props.buscarCep();
							}}
							onChange={this.props.handleChangeEmpresaEndereco}
							placeholder={this.props.t("cep")}
							data-mask="00000-000"
							data-mask-selectonfocus="true"
						/>
						{this.props.erros.cep}
					</div>
					{this.props.carregandoCEP && (
						<div className="form-group mt-0 d-flex flex-column">
							<LinearProgress />
						</div>
					)}
					<div className="form-group">
						<input
							name="logradouro"
							type="text"
							required
							maxLength={30}
							disabled={this.props.disableEndereco}
							value={this.props.empresa.endereco.logradouro}
							onChange={this.props.handleChangeEmpresaEndereco}
							placeholder={this.props.t("logradouro")}
						/>
					</div>
					<div className="form-group">
						<input
							name="numero"
							type="text"
							required
							maxLength={8}
							value={this.props.empresa.endereco.numero}
							onChange={this.props.handleChangeEmpresaEndereco}
							placeholder={this.props.t("numero")}
						/>
					</div>
					<div className="form-group">
						<input
							name="complemento"
							type="text"
							maxLength={25}
							value={this.props.empresa.endereco.complemento}
							onChange={this.props.handleChangeEmpresaEndereco}
							placeholder={this.props.t("complemento")}
						/>
					</div>
					<div className="form-group">
						<input
							name="bairro"
							type="text"
							required
							maxLength={30}
							disabled={this.props.disableEndereco}
							value={this.props.empresa.endereco.bairro}
							onChange={this.props.handleChangeEmpresaEndereco}
							placeholder={this.props.t("bairro")}
						/>
					</div>
					<div className="form-group">
						<input
							name="cidade"
							type="text"
							required
							maxLength={30}
							disabled={this.props.disableEndereco}
							value={this.props.empresa.endereco.cidade}
							onChange={this.props.handleChangeEmpresaEndereco}
							placeholder={this.props.t("cidade")}
						/>
					</div>
					<div className="form-group">
						<input
							name="estado"
							type="text"
							required
							maxLength={15}
							disabled={this.props.disableEndereco}
							value={this.props.empresa.endereco.estado}
							onChange={this.props.handleChangeEmpresaEndereco}
							placeholder={this.props.t("estado")}
						/>
					</div>
				</div>
				<BotaoProximo t={this.props.t} />
			</div>
		);
	}
}
