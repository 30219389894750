import { useEffect } from "react";
import { actions } from "../../_reducers/Rede/actions";
import { useAppDispatch } from "../useAppDispatch";
import { useAppSelector } from "../useAppSelector";

export function useRedes() {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.RedeReducer);

    useEffect(() => {
        if (!state.initialFetchWasMade) {
            dispatch(actions.fetchData());
        }
    }, [state.initialFetchWasMade, dispatch]);

    const { data, ...stateRest } = state;

    return {
        state: { ...stateRest, redes: data },
        actions,
        dispatch,
    };
}
