import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Button, Divider, Form, Grid, Input, Segment } from "semantic-ui-react";
import Mapa from "./Mapa";
import { ModelLoja } from "../Model/model";
import { lojaSearchBoxString } from "../Utils/Loja";
import { localizacao } from "../_actions/RecursoActions";
import RoteiroConnect from "../Interno/Roteiro/RoteiroConnect";
import { withTranslation } from "react-i18next";

export const defaultFilterEvent = () => ({
	filterData: { tipo: null, data: "" },
});

const DateInput = props => (
	<Input
		name={props.name}
		type="date"
		iconPosition="left"
		icon="calendar"
		onChange={props.onChange}
		value={props.value}
	/>
);

export const botalFiltroLabel = (t, state) =>
	!state ? t("botao.filtro.abrir") : t("botao.filtro.fechar");

export const FilterButton = props => (
	<Button
		style={{ marginTop: "10px" }}
		content={botalFiltroLabel(props.t, props.aberto)}
		icon="filter"
		color="violet"
		floated="right"
		basic
		onClick={props.onClick}></Button>
);

class FiltroBuscarOportunidade extends Component {
	state = { tipo: null, data: "", loja: new ModelLoja() };

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleChangeLocation = event => {
		const { loja } = event;
		this.setState(
			{
				searchBox: lojaSearchBoxString(loja),
				loja: { ...loja },
			},
			() =>
				this.props.dispatch(
					localizacao({
						lat: this.state.loja.endereco.coordenada.latitude,
						lng: this.state.loja.endereco.coordenada.longitude,
					})
				)
		);
	};

	handleChangeSelectTipo = (event, { value }) => this.setState({ tipo: value });

	handleChangeFilterDate = field => event =>
		this.setState({
			[field]: !!event.target.value ? moment(event.target.value).format("YYYY-MM-DD") : "",
		});

	aplicarFiltro = event => this.props.aplicarFiltro({ ...event, filterData: { ...this.state } });

	render() {
		const { tipo, data, loja } = this.state;
		const { t, types, aberto, carregando, searchBox } = this.props;
		const location =
			(loja &&
				loja.endereco &&
				loja.endereco.coordenada && {
					lat: loja.endereco.coordenada.latitude,
					lng: loja.endereco.coordenada.longitude,
				}) ||
			null;
		return !aberto ? null : (
			<Segment basic className="purple-gradient-45">
				<Form className="filtrar-roteiros">
					<Form.Group style={{ margin: "0 !important" }}>
						<Form.Input width="6">
							<DateInput
								name="start"
								fluid
								onChange={this.handleChangeFilterDate("data")}
								value={data}
							/>
						</Form.Input>
						<Form.Dropdown
							name="tipo"
							value={tipo}
							fluid
							width="10"
							onChange={this.handleChangeSelectTipo}
							placeholder={t("placeholder.atividade.principal")}
							search
							selection
							options={types.concat({ key: 0, value: null, label: "Limpar" })}
						/>
						<Form.Button
							width="2"
							icon="filter"
							fluid
							content={t("filtrar")}
							style={{ display: "flex" }}
							onClick={event => this.aplicarFiltro(event)}></Form.Button>
					</Form.Group>
				</Form>
				<Grid.Column width={8}>
					<Divider inverted />
					<Form.Group widths="equal" style={{ color: "white" }}>
						<Form.Input
							loading={carregando}
							icon="search"
							id="searchBox"
							name="searchBox"
							value={searchBox}
							onChange={this.handleChange.bind(this)}
							fluid
							label={t("pesquisar.mapa")}
							placeholder={t("digite.aqui.busca.mapa")}
						/>
					</Form.Group>
					<div
						style={{
							width: "100%",
							height: "280px",
							position: "relative",
							marginBottom: "20px",
						}}>
						<Mapa
							location={location}
							customMarker={_.get(loja, ["place_id"], "")}
							onClickLocation={this.handleChangeLocation.bind(this)}
							searchBoxInput={document.getElementById("searchBox")}></Mapa>
					</div>
					<Form.Group widths="equal">
						<Form.Field style={{ color: "white" }}>
							<Form.Input
								fluid
								required
								loading={carregando}
								value={_.get(loja, "endereco.endereco", "")}
								icon="map marker alternate"
								iconPosition="left"
								label={t("endereco")}
								placeholder={t("aguardando.selecao.mapa")}
							/>
						</Form.Field>
					</Form.Group>
					<br />
					<Grid.Column>
						<Form className="raio-mapa">
							<Form.Input
								label={`Raio de busca: ${this.props.raio / 1000} Km `}
								min={1000}
								max={100000}
								name="raio"
								onChange={this.props.setRaio}
								step={1000}
								type="range"
								value={this.props.raio}
							/>
						</Form>
					</Grid.Column>
				</Grid.Column>
			</Segment>
		);
	}
}

export default RoteiroConnect(withTranslation()(FiltroBuscarOportunidade));
