import { Heading } from "@chakra-ui/react";
import { differenceInDays } from "date-fns";
import _ from "lodash";
import moment from "moment";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button, Icon, Input, Message, Modal, Tab } from "semantic-ui-react";
import { HeaderPadrao } from "../../Componentes/Componentes";
import { GenericError } from "../../errors/GenericError";
import { chamarFunction, chamarNodeApi } from "../../Utils/Api";
import { isTrue } from "../../Utils/isTrue";
import {
	alterarEstadoDeUmRelatorio,
	alterarOportunidadesRelatorio,
	alterarPeriodoRelatorio,
} from "../../_actions/RelatoriosActions";
import { CostPerPromoterReport } from "./CostPerPromoterReport";
import { RelatorioDeOportunidades } from "./Oportunidades";
import RelatorioDeGastoPorLoja from "./RelatorioDeGastoPorLoja/RelatorioDeGastoPorLoja";
import RelatorioDeRuptura from "./RelatorioDeRuptura/RelatorioDeRuptura";
import RelatorioDeVisita from "./RelatorioDeVisita/RelatorioDeVisita";
import RelatorioPesquisaDePreco from "./RelatorioPesquisaDePreco/RelatorioPesquisaDePreco";
import "./relatorios.css";
import TimeReport from "./TimeReport/index.tsx";

const relatorios = props => [
	{
		menuItem: { key: "ruptura", icon: "boxes", content: props.t("ruptura") },
		render: () => <RelatorioDeRuptura prev={{ ...props }} />,
	},
	{
		menuItem: {
			key: "preco",
			icon: "dollar",
			content: props.t("pesquisa.de.preco"),
		},
		render: () => <RelatorioPesquisaDePreco />,
	},
	{
		menuItem: {
			key: "visita",
			icon: "calendar check outline",
			content: props.t("visita"),
		},
		render: () => <RelatorioDeVisita />,
	},
	{
		menuItem: {
			key: "porloja",
			icon: "dolly",
			content: props.t("custo.por.loja"),
		},
		render: () => <RelatorioDeGastoPorLoja />,
	},
	{
		menuItem: {
			key: "porpromotor",
			icon: "user",
			content: props.t("custo.por.promotor"),
		},
		render: () => <CostPerPromoterReport reportData={props.oportunidades} />,
	},
	{
		menuItem: {
			key: "dehoras",
			icon: "clock",
			content: props.t("rel.de.horas"),
		},
		render: () => <TimeReport />,
	},
	{
		menuItem: {
			key: "oportunidades",
			icon: "calendar outline",
			content: props.t("oportunidades"),
		},
		render: () => <RelatorioDeOportunidades reportData={props.oportunidades} />,
	},
];

class RelatorioBase extends Component {
	state = {
		relatorioGerado: false,
		carregando: false,
		errorModal: false,
		errorMessage: "",
		tabIndex: 0,
	};

	setTabIndex(index) {
		this.setState({
			tabIndex: index,
		});
	}

	componentWillUnmount() {
		window.sessionStorage.removeItem("TimeReport.selectedOption");
	}

	/**
	 * @name buscarRoteiros
	 * @date Alterada em 16/01/2021
	 * @author Diego jimenes
	 * @description Faz uma busca de roteiros por data e status
	 * @param {Object} props propriedades para fazer a busca
	 * @param {Function} setState
	 * @param {Function} querie Query padrão para buscar roteiros por data e status
	 * @param {Function} node função que faz uma chamada na api node
	 */
	buscarRoteiros = async (
		x,
		y,
		props = this.props,
		setState = (valor, callback) => this.setState(valor, callback),
		node = chamarNodeApi
	) => {
		try {
			setState({ carregando: true, relatorioGerado: false });
			let { t, periodo } = props;
			let diff = moment(periodo.dataFinal).diff(periodo.dataInicio, "months");
			if (diff > 1) {
				setState({
					carregando: false,
					errorModal: true,
					errorMessage: t("periodo.maximo.de.30.dias"),
				});
				return Promise.reject({
					erroDePeriodo: t("periodo.maximo.de.30.dias"),
				});
			}
			const checkedOption = window.sessionStorage.getItem("TimeReport.checkedOption");
			const oportunidades = await node(
				"roteiro/gerarRelatorioDeRuptura",
				{
					idEmpresa: props.empresa.uid,
					dataInicio: periodo.dataInicio,
					dataFim: periodo.dataFinal,
					showMore: isTrue(checkedOption),
				},
				"POST"
			);
			props.alterarOportunidadesRelatorio(oportunidades);
			setState({ relatorioGerado: true, carregando: false });
			return oportunidades;
		} catch (erro) {
			setState({ carregando: false, errorModal: true, errorMessage: erro });
		}
	};

	baixarFotos = async () => {
		try {
			const dataInicial = new Date(this?.props?.periodo?.dataInicio);
			const dataFinal = new Date(this?.props?.periodo?.dataFinal);
			const diferencaEmDias = differenceInDays(dataFinal, dataInicial);
			const maisDeUmMesDeIntervalo = diferencaEmDias > 31;

			if (maisDeUmMesDeIntervalo) {
				throw new GenericError("Datas inválidas, período máximo de um mês");
			}

			this.setState({ carregando: true });

			const response = await chamarFunction("Opportunity-downloadPhotosOpportunity", {
				idEmpresa: this?.props?.empresa?.uid,
				dataInicial: this?.props?.periodo?.dataInicio,
				dataFinal: this?.props?.periodo?.dataFinal,
			});

			if (response?.status === "empty") {
				throw new GenericError("Nenhum arquivo encontrado");
			} else {
				const arrayBuffer = new Uint8Array(response?.blobBuffer?.data)?.buffer;
				const blob = new Blob([arrayBuffer], { type: "application/zip" });

				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = "imagens.zip";

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (error) {
			throw new GenericError(error);
		} finally {
			this.setState({ carregando: false });
		}
	};

	toastPromise = () =>
		toast.promise(
			this.baixarFotos(),
			{
				pending: "Download iniciado, aguarde um momento...",
				success: "Arquivos baixados com sucesso!",
				error: {
					render({ data }) {
						return data.message;
					},
				},
			},
			{
				theme: "colored",
			}
		);

	renderFiltro = () => {
		let { t, oportunidades } = this.props;
		let { dataInicio, dataFinal } = this.props.periodo;
		return (
			<div className="relatorio-container-filtro">
				<div className="relatorio-filtro">
					<div className="relatorio-container-input">
						<label style={{ lineHeight: 1 }}>{t("data.inicial")}</label>
						<Input
							type="date"
							value={dataInicio}
							onChange={(e, data) =>
								this.props.alterarPeriodoRelatorio({
									dataInicio: data.value,
								})
							}
						/>
					</div>
					<div className="relatorio-container-input">
						<label style={{ lineHeight: 1 }}>{t("data.final")}</label>
						<Input
							type="date"
							value={dataFinal}
							onChange={(e, data) =>
								this.props.alterarPeriodoRelatorio({
									dataFinal: data.value,
								})
							}
						/>
					</div>
					{!_.isEmpty(oportunidades) && (
						<>
							<Button
								loading={this.state.carregando}
								style={{ height: 40, width: "100%" }}
								color="violet"
								content={t("gerar.relatorio")}
								onClick={this.buscarRoteiros}
							/>
							<Button
								icon="download"
								loading={this.state.carregando}
								style={{
									display: "flex",
									height: 40,
									width: "180px",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
								}}
								color="violet"
								content={"Baixar imagens"}
								onClick={() => {
									this.toastPromise();
								}}
							/>
						</>
					)}
				</div>
				{_.isEmpty(oportunidades) && (
					<Button
						loading={this.state.carregando}
						style={{ height: 40, width: "100%", marginTop: 10 }}
						color="violet"
						content={t("gerar.relatorio")}
						onClick={this.buscarRoteiros}
					/>
				)}
			</div>
		);
	};

	render() {
		const { t, oportunidades } = this.props;
		const { errorModal, errorMessage } = this.state;
		return (
			<>
				<Modal
					size={"mini"}
					open={errorModal}
					onClose={() => this.setState({ errorModal: false })}>
					<Modal.Header>{t("erro.filtro")}</Modal.Header>
					<Modal.Content>
						<p>{errorMessage}</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							color={"purple"}
							onClick={() => this.setState({ errorModal: false })}>
							{t("botao.modal")}
						</Button>
					</Modal.Actions>
				</Modal>
				<div
					className="container-header-relatorio"
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: 30,
					}}>
					<HeaderPadrao
						icone="chart bar outline"
						titulo={t("relatorios")}
						subtitulo={t("relatorios.de.oportunidades.do.periodo")}
					/>
					{!_.isEmpty(oportunidades) && this.renderFiltro()}
				</div>
				{_.isEmpty(oportunidades) && (
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							color: "#666",
						}}>
						<Icon
							name="file alternate outline"
							size="massive"
							style={{ marginBottom: 20, marginTop: "15%" }}
						/>
						<Heading as="h3" size="lg" my={2}>
							{t("selecione.um.periodo.para.gerar.relatorio")}
						</Heading>
						<br />
						{this.renderFiltro()}
						{_.isEmpty(oportunidades) && this.state.relatorioGerado === true && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginTop: 10,
								}}>
								<Message
									color="red"
									icon="line graph"
									header={t("dados.insuficientes")}
									content={t("dados.insuficientes.gerar.relatorio")}
								/>
							</div>
						)}
					</div>
				)}

				{!_.isEmpty(oportunidades) && this.state.relatorioGerado === true && (
					<Tab
						menu={{
							color: "purple",
							secondary: true,
							pointing: true,
							className: "tab-bar-wrapped",
						}}
						panes={relatorios(this.props)}
						activeIndex={this.state.tabIndex}
						onTabChange={(e, { activeIndex }) => this.setTabIndex(activeIndex)}
					/>
				)}
			</>
		);
	}
}
const mapStateToProps = state => ({
	periodo: state.RelatoriosReducer.periodo,
	oportunidades: state.RelatoriosReducer.oportunidades,
});

export default withTranslation()(
	connect(mapStateToProps, {
		alterarEstadoDeUmRelatorio,
		alterarPeriodoRelatorio,
		alterarOportunidadesRelatorio,
	})(RelatorioBase)
);

export const buscarRoteiros = new RelatorioBase().buscarRoteiros;
