import moment from "moment";

export const todayAs = (format: string) => () => moment().format(format);
export const todayYYYYMMDD = todayAs("YYYY-MM-DD");

export const DOMINGO = 0;
export const SEGUNDA = 1;
export const TERCA = 2;
export const QUARTA = 3;
export const QUINTA = 4;
export const SEXTA = 5;
export const SABADO = 6;

export function extremesOfTheWeek(date = moment()) {
    const startDayOfWeek = date.clone().startOf("week");
    const endDayOfWeek = date.clone().endOf("week");
    return { startDayOfWeek, endDayOfWeek };
}

/**
 * @description Função que recebe uma data e compara com a data atual para determinar se a data passada é anterior a data atual.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @param date Uma string no formato YYYY-MM-DD.
 * @returns Um booleano que determina se a data passada é anterior a data atual.
 */
export function isDateBeforeToday(date: string): boolean {
    const today = moment();
    const dataToCompare = moment(date, "YYYY-MM-DD");

    return dataToCompare.isBefore(today, "day");
}

/**
 * @description Função que recebe uma data e compara com a data atual para determinar se a data passada é anterior a data atual ou igual.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @param date Uma string no formato YYYY-MM-DD.
 * @returns Um booleano que determina se a data passada é anterior a data atual ou igual.
 */
export function isDateSameOrBeforeToday(date: string): boolean {
    const today = moment();
    const dataToCompare = moment(date, "YYYY-MM-DD");

    return dataToCompare.isSameOrBefore(today, "day");
}
