import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonProps, Icon, Popup } from "semantic-ui-react";
import { ModalDeConfirmacao } from "../ConfirmarRegistro";

interface ButtonBlockProps extends Omit<ButtonProps, "onClick"> {
    isBlocked: boolean;
    handle: () => void;
}

export function ButtonBlock({ isBlocked, handle, ...rest }: ButtonBlockProps) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <ModalDeConfirmacao
                t={t}
                isOpen={modalIsOpen}
                titulo={t("bloquear.promotor")}
                message={
                    <p style={{ marginTop: 10 }}>
                        {isBlocked ? t("desbloquear.promotor.texto") : t("bloquear.promotor.modal")}
                    </p>
                }
                onConfirm={() => {
                    setModalIsOpen(false);
                    handle();
                }}
                onCancel={() => setModalIsOpen(false)}
            />

            <Popup
                content={isBlocked ? t("desbloquear.promotor.texto") : t("bloquear.promotor.texto")}
                trigger={
                    <Button basic color={isBlocked ? "grey" : "red"} onClick={() => setModalIsOpen(true)} {...rest}>
                        <Button.Content>
                            <Icon name="ban" /> {t(isBlocked ? "desbloquear" : "bloquear")}
                        </Button.Content>
                    </Button>
                }
            />
        </>
    );
}
