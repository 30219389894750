import moment from "moment";
import { ReceiptStatus } from "../@types/Opportunity";

export const VISITA_NAO_NOTIFICADA = 0;
export const VISITA_NOTIFICACAO_CANCELADA = 1;
export const VISITA_NOTIFICADA = 2;
export const SEM_DOCUMENTO = 0;
export const DOCUMENTO_CANCELADO_ENVIO = 1;
export const DOCUMENTO_ENVIADO = 2;

export const modelEmpresa = {
	uid: "",
	cnpj: "",
	razaoSocial: "",
	fantasia: "",
	inscEstadual: "",
	email: "",
	telefone: "",
	imagemURL: "",
	tipo: null,
	banco: {
		objetoBanco: {},
		agencia: "",
		conta: "",
		tipoConta: { nome: "Corrente", id: 0 },
	},
	endereco: {
		cep: "",
		logradouro: "",
		numero: "",
		complemento: "",
		bairro: "",
		cidade: "",
		estado: "",
	},
	responsavel: {
		nome: "",
		email: "",
		telefone: "",
	},
	vendedor: {
		nome: "",
		telefone: "",
		email: "",
		codigo: "",
		foto: "",
	},
	novoCadastro: true,
};

export function ModelLoja() {
	return {
		place_id: "",
		idEmpresa: "",
		codigo: "",
		fantasia: "",
		razaoSocial: "",
		rede: {
			id: "",
			nome: "",
		},
		contato: {
			nome: "",
			email: "",
			telefone: "",
		},
		endereco: {
			rua: "",
			cep: "",
			numero: "",
			bairro: "",
			cidade: "",
			estado: "",
			sigla: "",
			pais: "",
			coordenada: "",
			endereco: "",
		},
	};
}

export function ModelRoteiro(empresa) {
	const HORA_INICIAL = moment("08:00", "HH:mm").format("HH:mm");
	const HORA_FINAL = moment("18:00", "HH:mm").format("HH:mm");
	return {
		data: moment().format("YYYY-MM-DD"),
		dataFim: "",
		horaInicio: HORA_INICIAL,
		horaFim: HORA_FINAL,
		valor: null,
		notaFiscal: { requerida: true, status: 1, id: "" },
		loja: null,
		documento: null,
		statusNotificacaoVisita: null,
		statusRoteiro: { id: 1 },
		idEmpresa: empresa.uid,
		imagemEmpresa: empresa.imagem ?? null,
		fantasiaEmpresa: empresa.fantasia,
		tipo: null,
		descricao: "",
		arquivado: false,
		tempoEstimado: "",
		selecaoAutomatica: false,
		material: {
			fornecido: false,
			descricao: "",
		},
	};
}

export function ModelAvaliacao(empresa) {
	return {
		avaliacao: null,
		comentario: null,
		data: null,
		empresa: {
			fantasia: empresa.fantasia,
			id: empresa.uid,
		},
		id: null,
	};
}

export function ModelProduto() {
	return {
		arquivado: false,
		descricao: "",
		codigoBarras: "",
		preco: "",
		foto: "",
		idEmpresa: null,
		planograma: "",
	};
}
