import styled from "styled-components";

export const Container = styled.div`
	border-radius: 5px;
	background-color: rgb(238, 238, 238);

	color: rgb(51, 51, 51);
	box-shadow:
		rgb(212, 212, 213) 0px 1px 3px 0px,
		rgb(212, 212, 213) 0px 0px 0px 1px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 4px 0;
	text-align: center;
`;

export const TextTime = styled.span`
	font-weight: bold;
	font-size: 24px;
	color: #7501c0;
`;
