import { Tag, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { NegotiationLog } from "../../../@types/Negotiation";
import { getLogColor } from "../../../helpers/Negotiation";

interface NegotiationLogRibbonProps {
    log: NegotiationLog;
}

/**
 * Renderiza uma faixa no formato de tag com informações de um log de negociação.
 */
export function NegotiationLogRibbon({ log }: NegotiationLogRibbonProps) {
    const { colorScheme } = getLogColor(log);

    return (
        <VStack spacing={0}>
            <Text fontSize="smaller">{moment(log.criadoEm).format("DD/MM/YYYY HH:mm")}</Text>
            <Tag colorScheme={colorScheme} w="full" size="lg" justifyContent="center" textAlign="center" p={4}>
                {log.titulo}
                <br />
                {log.descricao}
            </Tag>
        </VStack>
    );
}
