import numeral from "numeral";

if (numeral.locales.br === undefined) {
	numeral.register("locale", "br", {
		delimiters: {
			thousands: ".",
			decimal: ",",
		},
		abbreviations: {
			thousand: "m",
			million: "M",
			billion: "B",
			trillion: "T",
		},
		ordinal: function (number) {
			const b = number % 10;
			return ~~((number % 100) / 10) === 1
				? "th"
				: b === 1
					? "st"
					: b === 2
						? "nd"
						: b === 3
							? "rd"
							: "th";
		},
		currency: {
			symbol: "R$",
		},
	});
}

numeral.locale("br");

export function fCurrency(number: string | number) {
	return numeral(number).format(Number.isInteger(number) ? "$ 0,0" : "$ 0,0.00");
}

export function fPercent(number: number) {
	return numeral(number / 100).format("0.0%");
}

export function fNumber(number: string | number) {
	return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
	return numeral(number).format(Number.isInteger(number) ? "0" : "0.00");
}

export function fData(number: string | number) {
	return numeral(number).format("0.0 b");
}

/**
 * @description Calcula a nota média de uma quantia de avaliações.
 */
export function calculateEvaluationAverage(
	sumOfTotalEvaluations: number,
	evaluationsQuantities: number
) {
	const result = sumOfTotalEvaluations / evaluationsQuantities;

	if (Number.isNaN(result)) return 0;

	return Number(result.toFixed(2));
}
