import { Heading } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";
import { OpportunitySegment } from "../../Componentes/OpportunitySegment";
import RoteiroConnect from "../../Interno/Roteiro/RoteiroConnect";
import { filtroOportunidades, orderBy, ROTEIRO_EXECUTADO } from "../../Utils/Roteiros";
import { oportunidadesAgExecutadas } from "../../_actions/RoteiroActions";

class OportunidadesExecutadas extends Component {
    componentDidMount() {
        this.props.opAgExecutadas === null &&
            filtroOportunidades(this.props.empresa.idUsuario, ROTEIRO_EXECUTADO).onSnapshot((snap) =>
                this.props.dispatch(oportunidadesAgExecutadas(snap.docs.map((d) => d.data())))
            );
    }

    componentWillUnmount() {
        this.props.dispatch(oportunidadesAgExecutadas(null));
    }

    render() {
        const { t, opAgExecutadas } = this.props;
        const grupos = orderBy(_.groupBy(opAgExecutadas, "data"), "asc");

        return (
            <>
                {_.isEmpty(opAgExecutadas) ? (
                    <Message content={t("nenhuma.oportunidade.publicada")}></Message>
                ) : (
                    _.map(grupos, (grupo, data) => (
                        <div key={data} className="cor-padrao">
                            <Heading mb="7px" mt="30px!important" size="md">
                                {moment(data).format("LLLL")}
                            </Heading>
                            {_.map(grupo, (roteiro) => (
                                <OpportunitySegment
                                    key={roteiro.id}
                                    opportunity={roteiro}
                                    color="green"
                                    mode="agency"
                                />
                            ))}
                        </div>
                    ))
                )}
            </>
        );
    }
}

export default RoteiroConnect(withTranslation()(OportunidadesExecutadas));
