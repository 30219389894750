
const isProd = process.env.NODE_ENV === 'production';

const noop = () => {};

export const log = isProd ? noop : (label, ...args) =>
    console.log(`[${label}] `, ...args);

export const logPath = isProd ? noop : (label) =>
    console.log(`[${label}]`, window.location.href.replace(window.location.origin, ''));

export const logSnapshot = isProd ? noop :  (label, snap) =>
    snap.forEach(doc => log(label, doc.data()));

export const logState = isProd ?
   ((label, f) => (...args) => f(...args)) :
   ((label, f) => (...args) => {
    const r = f(...args);
    log(label, "stateToProps", r);
    return r;
   });
