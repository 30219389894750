import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Tooltip,
} from "@material-ui/core";
import { filter, map, orderBy } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import { Button as SemanticButton } from "semantic-ui-react";
import { Opportunity, OpportunityVariant } from "../../@types/Opportunity";
import { db } from "../../config";
import { ModalFinishOpportunityOfRepositionAndSearch } from "./ModalFinishOpportunityOfRepositionAndSearch";
import { ModalFinishOpportunityOfTime } from "./ModalFinishOpportunityOfTime";
import { Alert, AlertIcon } from "@chakra-ui/react";

interface ModalFinishOpportunityProps {
	roteiro: Opportunity;
}

export enum FinishOpportunityOptions {
	"CURRENT_TIME",
	"OPPORTUNITY_FINISH_TIME",
	"OPPORTUNITY_LAST_SECTION_TIME",
	"INITIAL_TIME_SUM_ESTIMED_TIME",
}

/**
 * @description Componente que renderiza um botão e um modal para finalização de uma oportunidade já iniciada.
 * @author Leonardo Petta do Nascimento
 */
export function ModalFinishOpportunity({ roteiro }: ModalFinishOpportunityProps) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [showModal, toggleShowModal] = useToggle(false);
	const [loading, toggleLoading] = useToggle(false);

	const [option, setOption] = useState<FinishOpportunityOptions | null>(null);
	function handleCloseModal() {
		toggleShowModal(false);
		setOption(null);
	}

	function handleShowModal() {
		toggleShowModal(true);
	}

	async function handleFinishOpportunity() {
		try {
			toggleLoading(true);

			const dataToUpdate = {
				acesso: {
					teveAcesso: true,
				},
				finalizadoEm: moment().format("YYYY-MM-DD HH:mm:ss"),
				"statusRoteiro.id": 3,
			};

			//NOTE: Se for a opção de tempo atual, usamos o tempo atual
			if (option === FinishOpportunityOptions.CURRENT_TIME) {
				dataToUpdate.finalizadoEm = moment().format("YYYY-MM-DD HH:mm:ss");
			}

			//NOTE: Se for a opção de hora final da oportunidade, usamos ela
			if (option === FinishOpportunityOptions.OPPORTUNITY_FINISH_TIME && roteiro.horaFim) {
				const { horaFim } = roteiro;

				dataToUpdate.finalizadoEm = moment()
					.set({
						hour: Number(horaFim.split(":")[0]),
						minute: Number(horaFim.split(":")[1]),
						second: 0,
					})
					.format("YYYY-MM-DD HH:mm:ss");
			}

			//NOTE: Se houver execução de alguma seção, pega a data de finalização mais velha
			if (option === FinishOpportunityOptions.OPPORTUNITY_LAST_SECTION_TIME) {
				//NOTE: Filtra pelas seções que tem finalizadoEm e gera um novo array somente com datas do moment
				const finalizadosEm = filter(roteiro.secoes, section =>
					Boolean(section.finalizadoEm)
				).map(section => moment(section.finalizadoEm as string));

				//NOTE: Ordena pela data mais atual primeiro e pega ela
				const finalizadoEmMaisNovo = orderBy(
					map(finalizadosEm, date => moment(date)),
					date => date.valueOf(),
					"desc"
				)[0];

				//NOTE: Se houver, usamos ela para finalizar o roteiro
				if (finalizadoEmMaisNovo) {
					dataToUpdate.finalizadoEm = finalizadoEmMaisNovo.format("YYYY-MM-DD HH:mm:ss");
				}
			}

			//NOTE: Nessa opção somamos o tempo inicial com o tempo estimado em minutos
			if (
				option === FinishOpportunityOptions.INITIAL_TIME_SUM_ESTIMED_TIME &&
				roteiro.iniciadoEm
			) {
				const { tempoEstimado, iniciadoEm } = roteiro;

				dataToUpdate.finalizadoEm = moment(iniciadoEm)
					.add(tempoEstimado || 1, "minutes")
					.format("YYYY-MM-DD HH:mm:ss");
			}

			await db.collection("roteiros").doc(roteiro.id).update(dataToUpdate);

			enqueueSnackbar(t("oportunidade.finalizada.com.sucesso"), {
				variant: "success",
			});
		} catch (error) {
			console.error(error);

			enqueueSnackbar(t("erro.finalizar.oportunidade"), {
				variant: "error",
			});
		} finally {
			handleCloseModal();
			toggleLoading(false);
		}
	}

	const currentFormComponents = useMemo(() => {
		switch (roteiro.tipo.id) {
			case OpportunityVariant.TIME: {
				return <ModalFinishOpportunityOfTime setOption={setOption} option={option} />;
			}
			case OpportunityVariant.SEARCH:
			case OpportunityVariant.REPOSITIONS: {
				return (
					<ModalFinishOpportunityOfRepositionAndSearch
						setOption={setOption}
						option={option}
						roteiro={roteiro}
					/>
				);
			}

			default: {
				return null;
			}
		}
	}, [option, roteiro]);

	if (roteiro.statusRoteiro.id !== 6) return null;

	/**
	 * @description Calcula o tempo de execução do roteiro
	 * @author Ricardo Brito
	 */
	const tempoEmExecucao = moment().diff(moment(roteiro.iniciadoEm), "minutes");
	const iniciadoEstimado = moment(roteiro.iniciadoEm).add(roteiro.tempoEstimado || 1, "minutes");
	const tempoIniciadoEstimado = iniciadoEstimado.diff(moment(roteiro.iniciadoEm), "minutes");

	return (
		<>
			<SemanticButton
				content={t("finalizar-oportunidade")}
				icon="times"
				color="purple"
				style={{ marginTop: 12 }}
				onClick={handleShowModal}
			/>
			<Dialog open={showModal}>
				<DialogTitle>{t("voce-tem-certeza")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t(
							"voce-esta-prestes-a-finalizar-a-execucao-desta-oportunidade-informe-abaixo-o-horario-de-finalizacao-que-mais-se-encaixa-com-sua-necessidade"
						)}
					</DialogContentText>
					{currentFormComponents}
					{((option === 0 && tempoEmExecucao < 30) ||
						(option === 3 && tempoIniciadoEstimado < 30)) && (
						<Alert marginTop={5} status="error">
							<AlertIcon />
							{t("alerta-tempo-para-finalizar-roteiro")}
						</Alert>
					)}
				</DialogContent>
				<DialogActions>
					<SemanticButton onClick={handleCloseModal} disabled={loading} negative basic>
						{t("cancelar")}
					</SemanticButton>
					<Tooltip title={option === null ? t("escolha-uma-opcao") : ""} arrow>
						<span>
							<SemanticButton
								onClick={handleFinishOpportunity}
								disabled={
									loading ||
									(option === 0 && tempoEmExecucao < 30) ||
									(option === 3 && tempoIniciadoEstimado < 30) ||
									option === null
								}
								loading={loading}
								positive>
								{t("finalizar")}
							</SemanticButton>
						</span>
					</Tooltip>
				</DialogActions>
			</Dialog>
		</>
	);
}
