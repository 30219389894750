import { Container, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../Componentes/Card";
import { NegotiationListProps, NegotiationsList } from "../../Componentes/NegotiationsList";

export default function Negotiations() {
	const { t } = useTranslation();

	const lists = useMemo<NegotiationListProps[]>(
		() => [
			{
				header: {
					title: t("negociacoes-aceitas"),
					subTitle: t("negociacoes-que-foram-aceitas-e-estao-pendentes-de-execucao"),
					icon: "thumbs up",
				},
				filters: ["ACCEPT"],
			},
			{
				header: {
					title: t("negociacoes-em-andamento"),
					subTitle: t(
						"negociacoes-que-ainda-estao-sendo-analisadas-entre-as-duas-partes-e-estao-pendentes-de-aceite"
					),
					icon: "balance scale",
				},
				filters: ["PENDING"],
			},
			{
				header: {
					title: t("negociacoes-recusadas"),
					subTitle: t("explicacao-lista-negociacoes-recusadas"),
					icon: "thumbs down",
				},
				filters: ["RECUSED"],
			},
			{
				header: {
					title: t("negociacoes-pausadas"),
					subTitle: t("explicacao-lista-negociacoes-pausadas"),
					icon: "pause",
				},
				filters: ["IN PAUSE"],
			},
			{
				header: {
					title: t("negociacoes-passadas"),
					subTitle: t("negociacoes-que-expiraram-ou-ja-foram-executadas"),
					icon: "calendar times",
				},
				filters: ["EXPIRED"],
			},
		],
		[t]
	);

	return (
		<Container maxW="container.xl">
			<VStack spacing={6}>
				{lists.map((props, index) => (
					<Card key={index} w="full" px={5}>
						<NegotiationsList {...props} />
					</Card>
				))}
			</VStack>
		</Container>
	);
}
