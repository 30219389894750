import React, { Component } from 'react';


export default class BancoInput extends Component {
    onChange = event => {
        const format = this.props.mask;
        const _text = event.target.value.replace(/[^-0-9]/g, '');
        const dashIndex = format.indexOf("-");
        const expectedLength = format.replace(/-/, "").length;
        const rawTextLength = _text.replace(/-/, "").length;
        let result;
        if (rawTextLength < expectedLength) {
            result = (new Array(expectedLength - rawTextLength)).fill('0').join('') + event.target.value.replace(/-/, "");
        } else {
            result = _text.replace(/-/, "");
        }
        var conta = dashIndex > -1 ?
            result.replace(new RegExp(`^\\d*(\\d{${dashIndex}})(\\d{1})$`), "$1-$2") :
            result.replace(new RegExp(`^\\d*(\\d{${expectedLength}})$`), "$1");
        this.props.onChange(event, { name: this.props.name, value: conta });
    };

    render() {
        return (
            <>
                <label>{this.props.label}</label>
                <input
                    type="text" name={this.props.name}
                    value={this.props.value}
                    className="input-banco-border"
                    onChange={this.onChange.bind(this)} />
            </>
        );
    }
};
