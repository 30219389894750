import { Button, Divider, ModalFooter, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { Company } from "../../../@types/Company";
import { Negotiation, NegotiationStatus } from "../../../@types/Negotiation";
import { useBlocker } from "../../../hooks/store/useBlocker";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { NegotiationService } from "../../../services/firebase/Negotiation";
import { NotificationService } from "../../../services/firebase/Notification";
import { isDateBeforeToday } from "../../../Utils/Date.utils";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import { NewProposalForm } from "../NewProposalForm";

interface ModalShowOpportunityNegotiationFooterProps {
	negotiation: Negotiation;
	handleCloseModal: VoidFunction;
}

interface NewProposalFormProps {
	newValue: number;
	description: string;
}

export function ModalShowOpportunityNegotiationFooter({
	negotiation,
	handleCloseModal,
}: ModalShowOpportunityNegotiationFooterProps) {
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		state: { isFinishNegotiationBlocked, isReopenNegotiationBlocked },
		utils: { setBlocker },
	} = useBlocker();
	const company = useAppSelector(state => state.AutenticacaoReducer).empresa as Company;

	/**
	 * Se a empresa logada atualmente for a empresa que recusou a negociação, então essa variável será `true` se não `false`.
	 */
	const currentCompanyIsRefuser = company.uid === negotiation.idRecusador;

	/**
	 * Se a negociação estiver recusada e a empresa logada for quem tinha recusado anteriormente, então essa variável será `true`
	 */
	const changeFooterButton =
		negotiation.status === NegotiationStatus.RECUSADO && currentCompanyIsRefuser;

	/**
	 * Determina se a empresa logada é a criadora da oportunidade.
	 */
	const amITheNegotiationCreator = company.uid === negotiation.idCriadorOportunidade;

	/**
	 * Só exibimos o footer em 2 casos (na duas, a data do roteiro deve ser depois ou igual a hoje):
	 *
	 * 1° Caso a negociação esteja `PENDENTE`;
	 *
	 * 2° Caso ela esteja `RECUSADA` e a empresa logada seja quem recusou.
	 */
	const showFooter =
		!isDateBeforeToday(negotiation.roteiro.data) &&
		(negotiation.status === NegotiationStatus.PENDENTE || changeFooterButton);

	/**
	 * Função recusa uma negociação entre as duas partes e consequentemente finaliza ela.
	 */
	const handleRecuseNegotiation = useCallback(async () => {
		try {
			handleCloseModal();
			await NegotiationService.getInstance().recuseNegotiation(
				negotiation.id,
				negotiation.roteiro.id,
				company.uid
			);

			/**
			 * Define o id da empresa que deve ser notificada;
			 */
			const idCompanyToNotify =
				negotiation.idExecutor === company.uid
					? negotiation.idCriadorOportunidade
					: negotiation.idExecutor;

			/**
			 * Define se a empresa logada é o criador da oportunidade.
			 */
			const amITheOpportunityCreator = negotiation.idCriadorOportunidade === company.uid;

			//NOTE: Envia notificação para a outra empresa que faz parte da negociação
			await NotificationService.getInstance()
				.createOne({
					id: v4(),
					data: moment().format("YYYY-MM-DD"),
					titulo: "Proposta recusada.",
					descricao: `A empresa ${company.fantasia} decidiu recusar sua proposta no roteiro.`,
					idEmpresa: idCompanyToNotify,
					link: amITheOpportunityCreator
						? `/buscar/${negotiation.roteiro.id}`
						: `/oportunidade/info/${negotiation.roteiro.id}`,
					idRoteiro: negotiation.roteiro.id,
					visto: 0,
				})
				.catch(e => console.error("Erro ao enviar notificação: ", e));
		} catch (error) {
			console.error(error);
			toast.error(t("error-finalizar-negociacao"));
		}
	}, [
		company.fantasia,
		company.uid,
		handleCloseModal,
		negotiation.id,
		negotiation.idCriadorOportunidade,
		negotiation.idExecutor,
		negotiation.roteiro.id,
		t,
	]);

	const handleReopenNegotiation = useCallback<SubmitHandler<NewProposalFormProps>>(
		data => {
			//NOTE: Se a data do roteiro for mais antigo que hoje, então é bloqueada a reabertura.
			if (isDateBeforeToday(negotiation.roteiro.data)) {
				return toast.warn("Essa negociação não pode mais ser reaberta.");
			}

			async function promiseFn() {
				try {
					onClose();
					setBlocker({ isReopenNegotiationBlocked: true });

					const acceptorId =
						negotiation.idExecutor === company.uid
							? negotiation.idCriadorOportunidade
							: negotiation.idExecutor;

					await NegotiationService.getInstance().reopenNegotiation(
						negotiation.id,
						negotiation.roteiro.id,
						company.uid,
						company.fantasia || "",
						acceptorId,
						data.newValue,
						data.description,
						negotiation.idCriadorOportunidade === company.uid
					);
				} catch (error) {
					console.error(error);
					throw error;
				} finally {
					setBlocker({ isReopenNegotiationBlocked: false });
				}
			}

			return toast.promise(promiseFn, {
				pending: t("reabrindo-a-negociacao").concat("..."),
				success: t("negociacao-reaberta-com-sucesso"),
				error: t("ocorreu-um-erro-ao-reabrir-as-negociacoes-tente-novamente-mais-tarde"),
			});
		},
		[
			company.fantasia,
			company.uid,
			negotiation.id,
			negotiation.idCriadorOportunidade,
			negotiation.idExecutor,
			negotiation.roteiro.data,
			negotiation.roteiro.id,
			onClose,
			setBlocker,
			t,
		]
	);

	/**
	 * Dependendo de alguns fatores, o botão do `footer` vai ter características diferentes que guardaremos aqui.
	 */
	const buttonPropsByVariant = useMemo(() => {
		return {
			tooltipLabel: changeFooterButton
				? t("aviso-reabertura-da-negociacao")
				: t("aviso-finalizar-negociacao"),
			isDisabled: changeFooterButton
				? isReopenNegotiationBlocked
				: isFinishNegotiationBlocked,
			buttonColorScheme: changeFooterButton ? "green" : "red",
			buttonIcon: changeFooterButton
				? "fluent:chat-arrow-back-16-filled"
				: "mdi:close-circle",
			buttonChildren: changeFooterButton
				? t("retomar-negociacoes")
				: t("terminar-negociacoes"),
			onConfirm: changeFooterButton ? undefined : handleRecuseNegotiation,
			confirmationDialogMessage: changeFooterButton ? (
				<>
					<Text
						lineHeight={1.2}
						mb={2}
						textAlign="center"
						fontWeight="medium"
						color="orange.600">
						{t("aviso-nova-proposta-de-reabertura-de-negociacao")}
					</Text>
					<Divider my={4} />
					<NewProposalForm
						onSubmit={handleReopenNegotiation}
						onCancel={onClose}
						amITheNegotiationCreator={amITheNegotiationCreator}
					/>
				</>
			) : undefined,
			confirmationDialogShowFooter: changeFooterButton ? false : true,
		};
	}, [
		changeFooterButton,
		t,
		isReopenNegotiationBlocked,
		isFinishNegotiationBlocked,
		handleRecuseNegotiation,
		handleReopenNegotiation,
		onClose,
		amITheNegotiationCreator,
	]);

	if (!showFooter) return null;

	return (
		<>
			<Divider />
			<ModalFooter
				justifyContent="flex-start"
				gap={2}
				borderBottomRadius={"lg"}
				flexDir={{ base: "column", sm: "row" }}
				m={0}
				bg="transparent">
				<Tooltip label={buttonPropsByVariant.tooltipLabel} hasArrow textAlign="center">
					<Button
						isDisabled={buttonPropsByVariant.isDisabled}
						w={{ base: "full", sm: "fit-content" }}
						colorScheme={buttonPropsByVariant.buttonColorScheme}
						onClick={onOpen}
						variant="outline"
						leftIcon={<Icon icon={buttonPropsByVariant.buttonIcon} width={20} />}>
						{buttonPropsByVariant.buttonChildren}
					</Button>
				</Tooltip>
				<ConfirmationDialog
					showFooter={buttonPropsByVariant.confirmationDialogShowFooter}
					onClose={onClose}
					isOpen={isOpen}
					onConfirm={buttonPropsByVariant.onConfirm}
					message={buttonPropsByVariant.confirmationDialogMessage}
				/>
			</ModalFooter>
		</>
	);
}
