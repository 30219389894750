import { Dispatch, SetStateAction } from "react";
import { Container, OptionButton } from "./styles";
import { FinishOpportunityOptions } from "..";
import { useTranslation } from "react-i18next";

interface ModalFinishOpportunityOfTimeProps {
    setOption: Dispatch<SetStateAction<FinishOpportunityOptions | null>>;
    option: FinishOpportunityOptions | null;
}

export function ModalFinishOpportunityOfTime({ setOption, option }: ModalFinishOpportunityOfTimeProps) {
    const { t } = useTranslation();

    return (
        <Container>
            <p>
                {t("para-oportunidades-de-tempo-voce-pode-escolher")}:<span style={{ color: "red" }}>*</span>
            </p>
            <div style={{ display: "flex", gap: 4, justifyContent: "space-evenly" }}>
                <OptionButton
                    onClick={() => setOption(FinishOpportunityOptions.CURRENT_TIME)}
                    $isSelected={option === FinishOpportunityOptions.CURRENT_TIME}
                >
                    {t("horario-atual")}
                </OptionButton>
                <OptionButton
                    onClick={() => setOption(FinishOpportunityOptions.OPPORTUNITY_FINISH_TIME)}
                    $isSelected={option === FinishOpportunityOptions.OPPORTUNITY_FINISH_TIME}
                >
                    {t("horario-de-termino-da-oportunidade")}
                </OptionButton>
            </div>
        </Container>
    );
}
