import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import { Container, HeaderText } from "./styles";

interface Course {
    id: string;
    nome: string;
    data: string;
    descricao: string;
}

interface CoursesProps {
    courses: Course[];
}

export function Courses({ courses }: CoursesProps) {
    const { t } = useTranslation();

    return (
        <Container>
            <HeaderText>{t("listagem.cursos")}</HeaderText>

            {courses?.length ? (
                courses.map((course) => (
                    <Grid stackable key={course.id} style={{ borderBottom: "1px solid #eee" }}>
                        <Grid.Column width="6" textAlign="left">
                            {course.nome} - {course.data}
                        </Grid.Column>

                        <Grid.Column width="10" textAlign="left">
                            Descrição: {course.descricao}
                        </Grid.Column>
                    </Grid>
                ))
            ) : (
                <Grid stackable style={{ borderBottom: "1px solid #eee" }}>
                    <Grid.Column
                        width="11"
                        textAlign="left"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}>
                        {t("nenhum.curso")}
                    </Grid.Column>
                </Grid>
            )}
        </Container>
    );
}
