import _ from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { todayYYYYMMDD } from '../../Utils/Date.utils';
import { downloadRoteirosNovos } from '../../Utils/Oportunidades';
import { arquivarRoteiro, orderBy, snapToRoteirosPorData } from '../../Utils/Roteiros';
import { oportunidadesNovas } from '../../_actions/RoteiroActions';
import ListarRoteiros from './ListarRoteiros';
import ListarRoteirosNovos from './Listas/ListarRoteirosNovos';
import RoteiroConnect from './RoteiroConnect';

export const tiposDeRoteiros = [
    {descricao: 'reposicao', icone: 'dolly', id: 1},
    {descricao: 'pesquisa', icone: 'search', id: 4},
    {descricao: 'por.tempo', icone: 'clock', id: 5}
]


class Roteiro extends Component {
    state = {
        filtroAberto: false,
        confirmacaoApagar: false,
        roteiroAberto: null,
        filtro: null
    };

    componentDidMount() {
        if (!_.isEmpty(this.props.novasOportunidades)) return;
        const { dispatch, empresa: { uid } } = this.props;
        downloadRoteirosNovos(uid, todayYYYYMMDD()).onSnapshot(
            snap => dispatch(oportunidadesNovas(snap.docs.map(d => d.data())))
        );
    }

    componentWillUnmount() {
        this.state.filtro != null && this.props.dispatch(oportunidadesNovas(null));
    }


    roteiroEstaAberto = (data, idRoteiro) =>
        _.isEqual(this.state.roteiroAberto, [data, idRoteiro])

    abrirRoteiro = (data, idRoteiro) => event =>
        this.setState(this.roteiroEstaAberto(data, idRoteiro) ?
            { roteiroAberto: null } :
            { roteiroAberto: [data, idRoteiro], confirmacaoApagar: false }
        );

    apagarRoteiro = roteiro =>
        arquivarRoteiro(roteiro.id).then(
            () => this.setState({
                confirmacaoApagar: false,
                roteiroAberto: null,
            })
        );

    apagarRoteiroConfirmacao = (data, idRoteiro) =>
        this.setState({ confirmacaoApagar: true });

    filterRef = ref => (this.filter = ref);

    render() {
        const { t } = this.props;
        const {
            carregando, confirmacaoApagar
        } = this.state;
        const oportunidades = orderBy(snapToRoteirosPorData(
            this.props.novasOportunidades || [],
            {}
        ), 'asc')

        return (
            <div>
                <ListarRoteiros
                    t={t} renderer={ListarRoteirosNovos}
                    carregando={carregando}
                    roteiros={oportunidades}
                    confirmacaoApagar={confirmacaoApagar}
                    roteiroEstaAberto={this.roteiroEstaAberto}
                    apagarRoteiroConfirmacao={this.apagarRoteiroConfirmacao}
                    apagarRoteiro={this.apagarRoteiro}
                    abrirRoteiro={this.abrirRoteiro} />
            </div>
        )
    }
}

export default RoteiroConnect(withTranslation()(Roteiro));
