import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Message, Tab, Grid } from 'semantic-ui-react';
import { alterarEstadoDeUmRelatorio } from '../../../_actions/RelatoriosActions';
import { withTranslation } from 'react-i18next';
import { gerarDadosPorPdvEOportunidade } from './metodos';
import paineis from './panes';
import LineChart from '../../../Utils/svg/LineChart'
import MesAnterios from './MesAnterios'
import './relatorioDeRuptura.css';
import TopUsuarioRuptura from './TopUsuarioRuptura';
import { chamarNodeApi } from '../../../Utils/Api'
import Totais from './Totais';

class RelatorioDeRuptura extends Component {
    state = {
        ...this.props.relatorioDeRupturas,
        dadosDoMesAnterios: {},
        erroDePeriodo: "",
        carregando: {
            status: false,
            step: '',
        },
        carregandoRelatorio: false,
        order: {}
    }

    componentDidMount() {
        this.relatorioDeRuptura()
        this.setState({ carregandoRelatorio: true }, () => {
            this.relatorioDoUltimoMes()
        })
    }

    componentDidUpdate(prevProps) {
        // if (JSON.stringify(novo) != JSON.stringify(antigo)) {
        if (_.differenceWith(this.props.oportunidades, prevProps.oportunidades, _.isEqual).length > 0) {
            this.relatorioDeRuptura()
        }
    }

    buscarRoteiros = (props = this.props, node = chamarNodeApi) => {
        let { t, periodo } = props
        let diff = moment(periodo.dataFinal).diff(periodo.dataInicio, 'months')
        if (diff > 1) {
            return Promise.reject({ erroDePeriodo: t('periodo.maximo.de.30.dias') })
        }
        return node('roteiro/gerarRelatorioDeRuptura', {
            idEmpresa: props.empresa.uid,
            dataInicio: periodo.dataInicio,
            dataFim: periodo.dataFinal,
            showMore: false,
        }, 'POST')
    }

    relatorioDoUltimoMes = async (props = this.props) => {

        let menosUmMes = moment(this.props.prev.periodo.dataInicio).subtract(1, 'month').format('YYYY-MM-DD')

        const propis = {
            periodo: {
                dataInicio: menosUmMes,
                dataFinal: props.prev.periodo.dataInicio
            },
            t: props.t,
            empresa: { ...props.prev.empresa }
        }

        const oportunidades = await this.buscarRoteiros(propis)
        this.setState({ dadosDoMesAnterios: oportunidades, carregandoRelatorio: false })
    }

    relatorioDeRuptura = () => {
        var dados = gerarDadosPorPdvEOportunidade(this.props.oportunidades, this.props.oportunidades.docs, this.props.t, (step, status) => {
            this.setState({
                carregando: {
                    status: status,
                    step: step
                }
            })
        })
        this.setState({
            dados: dados,
            docs: this.props.oportunidades.docs,
            carregando: {
                status: false,
            }
        })
    }

    render() {
        let { t } = this.props

        const panes = paineis(this.state, (obj, call) => this.setState(obj, call ? call() : () => { }), t)

        let { erroDePeriodo } = this.state
        return (
            <div>
                {erroDePeriodo && <Message negative>
                    <Message.Header>{erroDePeriodo}</Message.Header>
                </Message>}
                {!_.isEmpty(this.state.dados) &&
                    <>
                         <Grid stackable>
                            <Grid.Column computer={12} mobile={16}>
                                {/* grafico de linha com os dias com mais rupturas */}
                                <LineChart dados={this.state.dados?.dia ?? {}} />
                            </Grid.Column>
                            <Grid.Column computer={4} mobile={16}>
                                {/* diferença entre os ultimos 30 dias */}
                                <MesAnterios mesAnterior={this.state.dadosDoMesAnterios?.total ?? {}} mesAtual={this.state.dados?.total ?? {}} />
                            </Grid.Column>
                        </Grid>
                        <br />
                        <Totais totais={this.state.dados?.total ?? {}} t={t} />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <div className="tabela-de-rupturas">
                                <Tab menu={{ color: "purple", secondary: true, pointing: true, className: "tab-bar-wrapped" }} panes={panes} />
                            </div>
                            <TopUsuarioRuptura usuario={this.state.dados?.usuario ?? {}} />
                        </div>
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    relatorioDeRupturas: state.RelatoriosReducer.relatorioDeRuptura,
    oportunidades: state.RelatoriosReducer.oportunidades
});

export default withTranslation()(connect(mapStateToProps, {
    alterarEstadoDeUmRelatorio
})(RelatorioDeRuptura));