import { Collapse } from '@material-ui/core';
import React, { useState } from 'react';
import Currency from 'react-currency-formatter';
import { Icon } from 'semantic-ui-react';
import { ImagemCircular } from '../../Componentes/Componentes';
import { iconPorAtividade, nomePorStatus } from './DashboardComponents';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'

/**
 * Exibe cada linha de produto da seção
 * @param {produto a ser exibido} produto 
 */
const ProdutoLine = ({ produto }) => (<div className='produto'>
    <div>{produto.descricao}</div>
    <div>
        <Currency
        quantity={
            produto.preco === "" ? 0 : parseInt(produto.preco)
        }
        locale="pt_BR"
        decimal=','
        group='.'
        currency="R$ "
        />
    </div>
</div>)

/**
 * Detalha determinada oportunidade na lateral direita da dashboard.
 * As oportunidades podem ser alternadas entre as oportunidades do dia da semana selecionado.
 * @param {oportunidade a ser detalhada na rightbox} oportunidade 
 */
export const RightBoxContent = ({ oportunidade }) => {
    const { t } = useTranslation();
    const [secaoAberta, setSecaoAberta] = useState(null)
    return (
        <div className='box-right'>
            {oportunidade.usuario &&
                <>
                    <ImagemCircular src={oportunidade.usuario.foto} size='extra-grande' />
                    <div className={`promotor-nome`}>{oportunidade.usuario.nome}</div>
                </>
            }
            <div style={{ justifyContent: 'center', display: 'flex' }}>
                <div className={`oportunidade-status s${oportunidade.statusRoteiro.id}`}>{t(nomePorStatus[oportunidade.statusRoteiro.id])}</div>
            </div>
            <div className='pdv-info'>
                <div className='box-icon'>
                    <Icon name='shopping basket' />
                </div>
                <div className='pdv-data'>
                    <span>{oportunidade.loja.fantasia}</span>
                    <span>{oportunidade.loja.endereco.endereco.split('-').slice(0, 2)}</span>
                </div>
            </div>
            {!_.isEmpty(oportunidade?.secoes ?? {}) &&
                <div className='secoes'>
                    {Object.entries(oportunidade.secoes).map(([key, secao]) => (
                        <div key={key} className='secao' onClick={() => setSecaoAberta(secaoAberta === key ? null : key)}>
                            <div className='head-secao'>
                                <div>{secao.nome}</div>
                                <div className='atividades'>{secao.atividades.map((atividade, index) => (
                                    <div key={index} className='box-icon'>
                                        <Icon name={iconPorAtividade[atividade]} />
                                    </div>
                                ))}</div>
                            </div>
                            <Collapse in={secaoAberta === key}>
                                <div className='body-secao'>
                                    <div className='head-table-produtos'>
                                        <span>{t('produto')}</span>
                                        <span>{t('preco')}</span>
                                    </div>
                                    {secao.produtos.map((produto, index) => (<ProdutoLine key={index} produto={produto} />))}
                                </div>
                            </Collapse>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}