import _ from 'lodash';
import {
    backgroundStyle, textStyle, toInches,
    numberOrDefault
} from './Styles';


const HALF_PAGE = 5;
const PADDING = HALF_PAGE * 0.1;
const defaultImageWidth = HALF_PAGE - (2 * PADDING);
const defaultImageHeight = (defaultImageWidth / 16) * 9;
const alignAtHalfPage = index => (index * HALF_PAGE) + (PADDING * 0.5);

const atividadePageAssets = (slide, { pages }) =>
    slide.addImage({ ...backgroundStyle, data: pages });

const pageHeader = (slide, title) =>
    slide.addText(title,
        { ...textStyle, y: .15, w: 9.8, bold: true,
        align: 'center', color: 'ffffff' });

export const Ruptura = (pptx, atividade, roteiro, assets) => {
    const slide = pptx.addNewSlide();
    atividadePageAssets(slide, assets);
    pageHeader(slide, `${atividade.descricao}`.toUpperCase());
    const size = toInches(96);
    const x = (5 - size) * 0.5;
    let y = (5.63 - size) * 0.5;
    slide.addImage({
        data: assets.ruptura,
        x, y, w: size, h: size
    });
    slide.addText(
        (`Ruptura`).toUpperCase(),
        { ...textStyle, x: 0, y: (y += size + 0.1), w: defaultImageWidth,
            align: 'c', bold: true, color: 'ff0000'
        }
    );
    atividade.descricao && slide.addText(
        (`Descrição: ${atividade.descricao}`).toUpperCase(),
        { ...textStyle, x: 0, y: y + 0.3, w: defaultImageWidth,
            align: 'c', bold: true, color: '666666'
        }
    );
    return pptx;
};

export const PesquisaDePreco = (pptx, atividade, roteiro, assets) => {
    _.chunk(atividade.images, 2).forEach(resources => {
        const slide = pptx.addNewSlide();
        atividadePageAssets(slide, assets);
        pageHeader(slide, `${atividade.descricao}`.toUpperCase());
        let w = defaultImageWidth;
        let h = defaultImageHeight;
        resources.forEach((resource, index) => {
            const price = numberOrDefault(atividade.nossoPreco, '(Não especificado)');
            const x = alignAtHalfPage(index);
            let y = (5.63 - h) * 0.5;
            slide.addImage({
                data: `${resource.data}`,
                x, y, w, h
            });
            slide.addText(
                (`Produto: ${atividade.produto.descricao}`).toUpperCase(),
                { ...textStyle, x, y: y += h + 0.1, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
            slide.addText(
                (`Nosso preço: ${price}`).toUpperCase(),
                { ...textStyle, x, y: y += 0.3, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
        });
    });
    return pptx;
};

export const Pergunta = (pptx, atividade, roteiro, assets) => {
    const slide = pptx.addNewSlide();
    atividadePageAssets(slide, assets);
    pageHeader(slide, `${atividade.descricao}`.toUpperCase());
    slide.addText(`"${atividade.pergunta}"`, { ...textStyle, fontSize: 22, bold: true, w: 9.8, y: 2.5, align: 'c' });
    slide.addText(atividade.resposta, { ...textStyle, fontSize: 22, y: 2.88, w: 9.8, align: 'c' });
    return pptx;
};

export const PesquisaDeValidade = (pptx, atividade, roteiro, assets) => {
    _.chunk(atividade.images, 2).forEach(resources => {
        const slide = pptx.addNewSlide();
        atividadePageAssets(slide, assets);
        pageHeader(slide, `${atividade.descricao}`.toUpperCase());
        let w = defaultImageWidth;
        let h = defaultImageHeight;
        resources.forEach((resource, index) => {
            const validade = (atividade.dataValidade && atividade.dataValidade) || '(Não especificado)';
            const x = alignAtHalfPage(index);
            let y = (5.63 - h) * 0.4;
            slide.addImage({
                data: `${resource.data}`,
                x, y, w, h
            });
            slide.addText(
                (`Produto: ${atividade.produto.descricao}`).toUpperCase(),
                { ...textStyle, x, y: y += h + 0.3, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
            slide.addText(
                (`Quantidade: ${atividade.quantidade}`).toUpperCase(),
                { ...textStyle, x, y: y += 0.3, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
            slide.addText(
                (`Validade: ${validade}`).toUpperCase(),
                { ...textStyle, x, y: y += 0.3, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
        });
    });
    return pptx;
};

export const FotoFachada = (pptx, atividade, roteiro, assets) => {
    _.chunk(atividade.images, 2).forEach(resources => {
        const slide = pptx.addNewSlide();
        atividadePageAssets(slide, assets);
        pageHeader(slide, `${atividade.descricao}`.toUpperCase());
        let w = defaultImageWidth;
        let h = defaultImageHeight;
        resources.forEach((resource, index) => {
            const x = alignAtHalfPage(index);
            const y = (5.63 - h) * 0.5;
            slide.addImage({
                data: `${resource.data}`,
                x, y, w, h
            });
            atividade.descricao && slide.addText(
                (`Descrição: ${atividade.descricao}`).toUpperCase(),
                { ...textStyle, x, y: y + h + 0.3, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
        });
    });
    return pptx;
};

export const FotoDeSaidaDeLoja = (pptx, atividade, roteiro, assets) => {
    _.chunk(atividade.images, 2).forEach(resources => {
        const slide = pptx.addNewSlide();
        atividadePageAssets(slide, assets);
        pageHeader(slide, `${atividade.descricao}`.toUpperCase());
        let w = defaultImageWidth;
        let h = defaultImageHeight;
        resources.forEach((resource, index) => {
            const x = alignAtHalfPage(index);
            const y = (5.63 - h) * 0.5;
            slide.addImage({
                data: `${resource.data}`,
                x, y, w, h
            });
            atividade.descricao && slide.addText(
                (`Descrição: ${atividade.descricao}`).toUpperCase(),
                { ...textStyle, x, y: y + h + 0.3, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
        });
    });
    return pptx;
};

export const Foto = (pptx, atividade, roteiro, assets) => {
    _.chunk(atividade.images, 2).forEach(resources => {
        const slide = pptx.addNewSlide();
        atividadePageAssets(slide, assets);
        pageHeader(slide, `${atividade.descricao}`.toUpperCase());
        const w = defaultImageWidth;
        const h = defaultImageHeight;
        resources.forEach((resource, index) => {
            const x = alignAtHalfPage(index);
            let y = (5.63 - h) * 0.25;
            slide.addImage({
                data: `${resource.data}`,
                x, y, w, h
            });
            slide.addText(
                (`Produto: ${atividade.produto.descricao}`).toUpperCase(),
                { ...textStyle, x, y: y += h + 0.1, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
            slide.addText(
                (`Descrição: ${atividade.descricaoFoto}`).toUpperCase(),
                { ...textStyle, x, y: y += 0.3, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
            slide.addText(
                (`Observação: ${atividade.observacao}`).toUpperCase(),
                { ...textStyle, x, y: y += 0.8, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666', lineSpace: 5, fontSize: 14
                }
            );
        });
    });
    return pptx;
};

export const FotoDeReposicao = (pptx, atividade, roteiro, assets) => {
    _.chunk(atividade.images, 2).forEach(resources => {
        const slide = pptx.addNewSlide();
        atividadePageAssets(slide, assets);
        pageHeader(slide, `${atividade.descricao}`.toUpperCase());
        let w = defaultImageWidth;
        let h = defaultImageHeight;
        resources.forEach((resource, index) => {
            const x = alignAtHalfPage(index);
            let y = (5.63 - h) * 0.4;
            slide.addImage({
                data: `${resource.data}`,
                x, y, w, h
            });
            slide.addText(
                (`Produto: ${atividade.produto.descricao}`).toUpperCase(),
                { ...textStyle, x, y: y += h + 0.3, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
            slide.addText(
                (`Descrição: ${atividade.descricaoFoto}`).toUpperCase(),
                { ...textStyle, x, y: y += 0.3, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
            slide.addText(
                (`Quantidade: ${atividade.quantidade}`).toUpperCase(),
                { ...textStyle, x, y: y += 0.3, w: defaultImageWidth,
                    align: 'c', bold: true, color: '666666'
                }
            );
        });
    });
    return pptx;
};