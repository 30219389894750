import { Center, Divider, Grid, Skeleton } from "@chakra-ui/react";
import { Pagination } from "@material-ui/lab";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generateArray } from "toolkit-extra";
import { Redes } from "..";
import { EmptyContentMessage } from "../../../Componentes/EmptyContentMessage";
import { PageHeader } from "../../../Componentes/PageHeader";
import { RedeCard } from "../../../Componentes/RedeCard";
import { useAuth } from "../../../hooks/store/useAuth";
import usePagination from "../../../hooks/usePagination";
import { useRedes } from "../../../hooks/useRedes";
import { PATHS } from "../../../Utils/Routes";

export default function RedeList() {
	const { t } = useTranslation();

	const { company } = useAuth();

	const {
		state: { redes, isFetching },
	} = useRedes();

	const {
		currentPage,
		totalPages,
		handleChangePage: handleClick,
		paginatedData,
	} = usePagination(redes, 8);

	const contentComponent = useMemo(() => {
		if (isFetching)
			return generateArray(10).map(uid => <Skeleton key={uid} h="16" rounded={"md"} />);

		if (!redes.length)
			return (
				<Center minH="container.sm">
					<EmptyContentMessage
						title={t("nao-ha-nada-aqui")}
						message={t("volte-mais-tarde-talvez-a-lista-de-redes-tenha-mudado")}
					/>
				</Center>
			);

		return (
			<>
				{paginatedData.map(rede => (
					<RedeCard
						key={rede.id}
						data={rede}
						isActive={!!company?.idsRedesDeAtuacao?.includes(rede.id)}
					/>
				))}
				<Center>
					<Pagination
						disabled={isFetching}
						shape="rounded"
						count={totalPages}
						page={currentPage}
						onChange={(_, page) => handleClick(page)}
					/>
				</Center>
			</>
		);
	}, [
		isFetching,
		redes.length,
		t,
		paginatedData,
		totalPages,
		currentPage,
		company?.idsRedesDeAtuacao,
		handleClick,
	]);

	return (
		<Redes.Layout>
			<PageHeader
				title={t("redes")}
				subtitle={t("redes-de-lojas-em-que-sua-agencia-pode-atuar-diretamente")}
				icon="mdi:garage-variant"
				breadcrumb={[{ name: "Dashboard", to: PATHS.dashboard.root }, { name: t("redes") }]}
			/>
			<Divider />
			<Grid gap={4}>{contentComponent}</Grid>
		</Redes.Layout>
	);
}
