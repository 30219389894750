import React from "react";
//NOTE: Tipagem da biblioteca não existe
//@ts-ignore
import ReactStoreBadges from "react-store-badges";
import bg from "../../../imagens/login-lateral-image.webp";
import apple from "../../../imagens/appstore.svg";
import logo from "../../../imagens/logo-nome.svg";
import { Button, Container, LeftBackground, Logo, RightContent, Selector, Title } from "./styles";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

interface SelectorRegisterTypeProps {
	setIsPromoter: (status: boolean) => void;
}

export function SelectorRegisterType({ setIsPromoter }: SelectorRegisterTypeProps) {
	const [showBadges, setShowBadges] = React.useState(false);
	const { t } = useTranslation();

	const googlePlayUrl =
		"https://play.google.com/store/apps/details?id=br.com.promotor.magictrade&hl=pt_BR&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
	const appleStoreUrl =
		"https://apps.apple.com/br/app/promotor-freelance/id1476853110?itsct=apps_box_badge&amp;itscg=30200";

	return (
		<Container>
			<RightContent>
				<Logo src={logo}></Logo>
				{!showBadges ? (
					<>
						<Helmet>
							<script>
								{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-5C9DJXD');`}
							</script>
							<noscript>
								{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5C9DJXD"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
							</noscript>
						</Helmet>
						<Title>{t("voce.e.um.promotor.de.vendas")}</Title>
						<Selector>
							<Button isMain onClick={() => setShowBadges(true)}>
								{t("sim").toUpperCase()}
							</Button>
							<Button onClick={() => setIsPromoter(false)}>
								{t("nao").toUpperCase()}
							</Button>
						</Selector>
					</>
				) : (
					<>
						<Helmet>
							<script>
								{`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1121745168407887');
                fbq('track', 'PageView');`}
							</script>
							<noscript>
								{`<img
                height="1"
                width="1"
                style="display:none"
                src="https://www.facebook.com/tr?id=1121745168407887&ev=PageView&noscript=1"
                />`}
							</noscript>
						</Helmet>
						<Title>{t("para.criar.o.seu.perfil.baixe.o.nosso.app")}:</Title>
						<Selector>
							<a href={appleStoreUrl}>
								<img src={apple} width="210" height="80" alt="Promotor" />
							</a>
							<ReactStoreBadges
								platform={"android"}
								url={googlePlayUrl}
								locale={"pt-BR"}
								width={210}
								height={80}
							/>
						</Selector>
					</>
				)}
			</RightContent>
			<LeftBackground src={bg}></LeftBackground>
		</Container>
	);
}
