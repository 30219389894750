import firebase from "firebase";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
    Button, Grid, Modal
} from "semantic-ui-react";
import { ButtonAgencyLink } from "../../../../Componentes/ButtonAgencyLink";
import { ButtonAutomaticSelection } from "../../../../Componentes/ButtonAutomaticSelection";
import { ButtonBlock } from "../../../../Componentes/ButtonBlock";
import { ButtonFavorite } from "../../../../Componentes/ButtonFavorite/index";
import { ModalDeConfirmacao } from "../../../../Componentes/ConfirmarRegistro";
import { db } from "../../../../config";
import {
    Avatar,
    AvatarArea,
    BackgroundImage,
    BackgroundLayer,
    BasicInfo,
    ButtonArea,
    Container,
    Footer,
    PromotorInfo,
    Rating,
    RatingCard,
    Text,
    TextPrincipal,
    TextSecondary
} from "./styles";

interface User {
    uid: string;
    imagemURL?: string;
    foto?: string;
    nome: string;
    sexo: string;
    mediaAvaliacao?: number;
    quantidadeAvaliacao?: number;
    tipo: number;
    oportunidadesExecutadas?: number;
    fantasia: string;
    agencia: any
}

interface Promoter {
    fantasia: string;
    id: string;
    status: string;
}

interface Company {
    bloqueioPromotor: boolean;
    //NOTE:Fiz desse jeito pq está assim no banco, mas acredito que selecaoAutomatica, deveria ser um array de Promotores e não um objeto onde cada chave é um objeto promotor utilizando uo id dele
    selecaoAutomatica: { [key: string]: Promoter };
    uid: string;
    fantasia: string;
}

interface HeaderProps {
    user: User;
    company: Company;
}

export const Header = ({ user, company }: HeaderProps) => {
  const { t } = useTranslation();
  const [favorited, setFavorited] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [agency, setAgency] = useState(
    user.agencia === company.uid ? true : false
  );
  const[modalAgency, setModalAgency] = useState(false)
  const[modalAgencyMessage, setModalAgencyMessage] = useState(false)
  const[confirmationString, setConfirmationString] = useState('')
  const[loading, setLoading] = useState(false)

  const [loadingSelecaoAutomatica, setLoadingSelecaoAutomatica] = useState<{
    type: "add" | "remove";
    isLoading: boolean;
  }>({
    type: "add",
    isLoading: false,
  });

  const rantingValue = useMemo(
    () =>
      user.mediaAvaliacao && user.quantidadeAvaliacao
        ? (user.mediaAvaliacao / user.quantidadeAvaliacao).toFixed(2)
        : 0,
    [user.mediaAvaliacao, user.quantidadeAvaliacao]
  );

  const automaticPromoter = useMemo(
    () =>
      company.selecaoAutomatica ? company.selecaoAutomatica[user.uid] : null,
    [company.selecaoAutomatica, user.uid]
  );

  async function handleFavorite() {
    const ref = await db
      .collection("empresas")
      .doc(company.uid)
      .collection("favoritos")
      .doc(user.uid);
    favorited ? ref.delete() : ref.set({ uid: user.uid });
    setFavorited(!favorited);
  }

  async function handleBlock() {
    const ref = await db
      .collection("empresas")
      .doc(company.uid)
      .collection("bloqueados")
      .doc(user.uid);
    blocked ? ref.delete() : ref.set({ uid: user.uid });
    setBlocked(!blocked);
  }

  const removerSelecaoAutomatica = () => {
    toast.promise(
      new Promise((resolve, reject) => {
        setLoadingSelecaoAutomatica({ type: "remove", isLoading: true });
        setTimeout(() => {
          Promise.all([
            db
              .collection("empresas")
              .doc(company.uid)
              .update({
                [`selecaoAutomatica.${user.uid}`]:
                  firebase.firestore.FieldValue.delete(),
              }),
            db
              .collection("empresas")
              .doc(user.uid)
              .update({
                [`selecionadoAutomaticamente.${company.uid}`]:
                  firebase.firestore.FieldValue.delete(),
              }),
          ])
            .then(() => {
              setLoadingSelecaoAutomatica({ type: "remove", isLoading: false });
              resolve(true);
            })
            .catch((e) => reject(e));
        }, 3000);
      }),
      {
        error: t("nao.foi.possivel.propor.uma.selecao"),
        pending: t("removendo.da.lista"),
        success: t("operacao.finalizada.com.sucesso"),
      }
    );
  };

  const proporSelecaoAutomatica = () => {
    toast.promise(
      new Promise((resolve, reject) => {
        setLoadingSelecaoAutomatica({ type: "add", isLoading: true });
        setTimeout(() => {
          Promise.all([
            //propondo para
            db
              .collection("empresas")
              .doc(user.uid)
              .update({
                [`selecionadoAutomaticamente.${company.uid}`]: {
                  id: company.uid,
                  fantasia: company.fantasia,
                  status: "pendente",
                },
              }),
            //minha lista
            db
              .collection("empresas")
              .doc(company.uid)
              .update({
                [`selecaoAutomatica.${user.uid}`]: {
                  id: user.uid,
                  fantasia: user.fantasia,
                  status: "pendente",
                },
              }),
          ])
            .then(() => {
              setLoadingSelecaoAutomatica({ type: "add", isLoading: false });
              resolve(true);
            })
            .catch((e) => reject(e));
        }, 3000);
      }),
      {
        error: t("nao.foi.possivel.propor.uma.selecao"),
        pending: t("enviando.proposta.selecao.automatica"),
        success: t("operacao.finalizada.com.sucesso"),
      }
    );
  };

  useEffect(() => {
    async function fetchPromoterIsFavorited(companyId: string, userId: string) {
      try {
        const data = await db
          .collection("empresas")
          .doc(companyId)
          .collection("favoritos")
          .where("uid", "==", userId)
          .get();

        setFavorited(data.size > 0);
      } catch (error) {
        setFavorited(false);
      }
    }

    async function fetchPromoterIsBlocked(companyId: string, userId: string) {
      try {
        const data = await db
          .collection("empresas")
          .doc(companyId)
          .collection("bloqueados")
          .where("uid", "==", userId)
          .get();

        setBlocked(data.size > 0);
      } catch (error) {
        setBlocked(false);
      }
    }

    fetchPromoterIsFavorited(company.uid, user.uid);
    fetchPromoterIsBlocked(company.uid, user.uid);
  }, [company.uid, user.uid]);

  async function handleDesvincularAgencia() {
setLoading(true)
    try {
        await db
        .collection("perfis")
        .doc(user.uid)
        .update({ agencia: true })
        .then(() => setAgency(false));
        setConfirmationString(t('promotor.desvinculado'))
        handleOpenCloseModal()
        handleOpenCloseModalMessage()
    } catch (error: any) {
        setConfirmationString(error)
        handleOpenCloseModal()
        handleOpenCloseModalMessage()
    } finally {
        setLoading(false)
    }
 
  }

  const handleOpenCloseModal = () =>{
    setModalAgency(!modalAgency)
  }

  const handleOpenCloseModalMessage = () =>{
    setModalAgencyMessage(!modalAgencyMessage)
  }

  return (
    <>
      <Container>
        <ModalDeConfirmacao
          t={t}
          isOpen={modalAgency}
          titulo={t("desvincular.promotor")}
          message={
            <p style={{ marginTop: 10 }}>{t("desvincular.promotor.agencia")}</p>
          }
          onConfirm={handleDesvincularAgencia}
          onCancel={handleOpenCloseModal}
          onLoading={loading}
        />

        <Modal
          open={modalAgencyMessage}
          style={{ overflow: "visible" }}
          size="tiny"
        >
          <Modal.Header>{t("desvincular.promotor")}</Modal.Header>
          <Modal.Content>
            <div className="modal-confirmacao-texto">
              <p style={{ marginTop: 10 }}>{confirmationString}</p>
            </div>
            <Grid>
              <Grid.Column>
                <span>
                  <Button
                    color="purple"
                    onClick={handleOpenCloseModalMessage}
                    content={"OK"}
                    fluid
                  />
                </span>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
        <BackgroundImage>
          <BackgroundLayer>
            <PromotorInfo>
              <AvatarArea>
                <Avatar circular src={user.imagemURL ?? user.foto} />
              </AvatarArea>
              <BasicInfo>
                <RatingCard>
                  <Rating
                    icon="star"
                    defaultRating={rantingValue}
                    size="huge"
                  />
                  <Text>{rantingValue}</Text>
                </RatingCard>
                <TextPrincipal>{user.nome}</TextPrincipal>
                <TextSecondary>{user.sexo}</TextSecondary>
              </BasicInfo>
            </PromotorInfo>
          </BackgroundLayer>
        </BackgroundImage>

        <Footer>
          <ButtonArea>
            {agency && (
              <ButtonAgencyLink
                onClick={handleOpenCloseModal}
              ></ButtonAgencyLink>
            )}
            <ButtonFavorite
              isFavorited={favorited}
              onClick={handleFavorite}
            ></ButtonFavorite>
            {company.bloqueioPromotor && (
              <ButtonBlock
                isBlocked={blocked}
                handle={handleBlock}
              ></ButtonBlock>
            )}
            {user.tipo === 2 && (
              <ButtonAutomaticSelection
                automaticPromoter={automaticPromoter}
                onClick={
                  automaticPromoter
                    ? removerSelecaoAutomatica
                    : proporSelecaoAutomatica
                }
                loading={loadingSelecaoAutomatica.isLoading}
              />
            )}
          </ButtonArea>
        </Footer>
      </Container>
    </>
  );
};;
