import "./cadastro.css";
import logo from "./../../imagens/logo-nome.svg";
import $ from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import Link from "../../Componentes/Link";
import { cadastrarEmpresa } from "../../Utils/Auth";
import { Helmet } from "react-helmet";
import { modelEmpresa } from "./../../Model/model";
import { autenticarUsuario, cadastrandoUsuario } from "./../../_actions/AutenticacaoActions";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step7 from "./Step7";
import { Icon, Grid } from "semantic-ui-react";
import Step6 from "./Step6";
import _ from "lodash";
import { isValidCNPJ } from "@brazilian-utils/brazilian-utils";
import { SelectorRegisterType } from "./SelectorRegisterType/index";
import { Center } from "@chakra-ui/react";
const emailParse = require("email-addresses");
const erroStyle = {
	background: "#F44336",
	"font-size": "16px",
	color: "#FFFFFF",
	"border-radius": "60px",
	padding: "20px",
};

/**
 * @name BotaoProximo
 * @description Component do botão
 */
export const BotaoProximo = props => (
	<div className="login-botoes" size={{ width: "50%" }}>
		<div className="d-flex justify-content-center">
			<div className="p-3" style={{ width: "35%" }}>
				<Link to="/">
					<button tabIndex="0" className="btn btn-secondary-gradient" type="button">
						{props.t("cancelar")}
					</button>
				</Link>
			</div>
			<div className="p-3" style={{ width: "35%" }}>
				<button tabIndex="0" className="btn  btn-primary-gradient" type="submit">
					{props.label ? props.label : props.t("proximo")}
				</button>
			</div>
		</div>
	</div>
);

const table = {
	1: Step1,
	2: Step2,
	3: Step3,
	4: Step6,
	5: Step4,
	6: Step7,
	7: Step5,
};

/**
 * @name validarCep
 * @description valida o cep da empresa/agencia
 * @param {String} cep cep que vem no input
 */
const validarCep = cep => {
	var reg = new RegExp("[0-9]{5}-[0-9]{3}");
	return reg.test(cep);
};

/**
 * @name validarEmail
 * @description valida o email
 * @param {String} Email email da empresa
 */
const validarEmail = Email => {
	var emailValidate = emailParse(Email);
	return emailValidate === null ? false : true;
};

/**
 * @name pP
 * @description joga uma promise para validar dados do cadastro
 * @param {Function} f função que vem dos steps
 */
const pP = f => new Promise((rs, rj) => (f() ? rs(f) : rj(f)));

/**
 * @name validar
 * @description valida os campos dependendo do que
 * vem dos step
 */
const validar = (s, e, err, sE) => {
	const validacoes = {
		1: () => pP(() => true),
		2: e => pP(() => isValidCNPJ(e.cnpj)),
		3: (e, err) => pP(() => validarCep(e.endereco.cep) && !err.hasOwnProperty("cep")),
		4: () => pP(() => true),
		5: e => pP(() => validarEmail(e.responsavel.email)),
		6: () => pP(() => true),
		7: e => pP(() => validarEmail(e.email)),
	};
	return validacoes[s](e, err, sE);
};

/**
 * @name CadastroEmpresa
 * @description Classe que manipula os componentes do cadastro
 */
class CadastroEmpresa extends Component {
	state = {
		termos: false,
		erroCadastro: "",
		senha: "",
		empresa: modelEmpresa,
		disableEndereco: false,
		erroSenha: "",
		redirect: "",
		step: 1,
		logoEmpresa: null,
		carregando: false,
		carregandoCEP: false,
		erros: {},
		isPromoter: true,
	};

	setIsPromoter(status) {
		this.setState({ isPromoter: status });
	}

	/**
	 * @name verificarSenha
	 * @description verifica a senha do cadastro
	 * @param {Object} event input da senha
	 */
	verificarSenha = event =>
		this.setState({
			erroSenha: event.target.value === this.state.senha ? "" : "As senhas não são iguais.",
		});

	/**
	 * @name handleChange
	 * @description troca o input de informações da empresa, sem ser especifica
	 * @param {Object} event input do cadastro
	 */
	handleChange = event =>
		this.setState({
			[event.target.name]: event.target.value,
		});

	/**
	 * @name handleChangeEmpresa
	 * @description troca informações especificas da empresa
	 * @param {Object} event
	 */
	handleChangeEmpresa = event =>
		this.setState({
			empresa: {
				...this.state.empresa,
				[event.target.name]: event.target.value,
			},
		});

	/**
	 * @name handleCodigo
	 * @date Criado em 17/12/2020
	 * @author Vitor Savian
	 * @description mexe especificamente no código de vendedor da empresa
	 * @param {String} val string vazia que faz o input resetar e ir sem pro banco
	 */
	handleCodigo = val => {
		this.setState({
			empresa: {
				...this.state.empresa,
				vendedor: {
					nome: "",
					telefone: "",
					foto: "",
					email: "",
					codigo: val,
				},
			},
		});
	};

	/**
	 * @name handleTakeVendor
	 * @date Criado em 22/12/2020
	 * @author Vitor Savian
	 * @description Troca as informações do vendedor
	 * @param {Object} doc informações do promotor
	 */
	handleTakeVendor = doc => {
		this.setState({
			empresa: {
				...this.state.empresa,
				vendedor: {
					nome: doc.nome,
					telefone: doc.telefone,
					foto: doc.foto,
					email: doc.email,
					codigo: doc.codigo,
				},
			},
		});
	};

	/**
	 * @name handleChangeEmpresaEndereco
	 * @description pega o vendedor e joga as informações dele para o state
	 * @param {Object} event valores que vem do input
	 */
	handleChangeEmpresaEndereco = event =>
		this.setState({
			empresa: {
				...this.state.empresa,
				endereco: {
					...this.state.empresa.endereco,
					[event.target.name]: event.target.value,
				},
			},
		});

	/**
	 * @name handleChangeEmpresaBanco
	 * @description troca o banco da empresa, dependendo do valor e do name passado
	 * @param {String} name nome do input e do valor
	 * @param {String} value valor que vai para o campo que veio
	 * @param {Function} callback função de callback do setstate
	 */
	handleChangeEmpresaBanco = (name, value, callback) => {
		this.setState(
			{
				empresa: {
					...this.state.empresa,
					banco: {
						...this.state.empresa.banco,
						[name]: value,
					},
				},
			},
			() => {
				if (callback) {
					callback(value);
				}
			}
		);
	};

	/**
	 * @name handleChangeEmpresaResponsavel
	 * @description troca o objeto da empresa na parte de empresa responsavel
	 * @param {Object} event objeto do input da empresa responsavel
	 */
	handleChangeEmpresaResponsavel = event =>
		this.setState({
			empresa: {
				...this.state.empresa,
				responsavel: {
					...this.state.empresa.responsavel,
					[event.target.name]: event.target.value,
				},
			},
		});

	/**
	 * @name handleCheckbox
	 * @description troca o valor do checkbox
	 * @param {Object} event objeto do checkbox
	 */
	handleCheckbox = event => this.setState({ termos: event.checked });

	/**
	 * @name buscarCep
	 * @description busca o cep e coloca os valores nos
	 * inputs logo abaixo
	 */
	buscarCep = () => {
		this.setState({ carregandoCEP: true }, () => {
			$.getJSON(
				"https://viacep.com.br/ws/" + this.state.empresa.endereco.cep + "/json/?callback=?",
				function (dados) {
					if (!("erro" in dados)) {
						var err = this.state.erros;
						delete err.cep;
						this.setState({
							empresa: {
								...this.state.empresa,
								endereco: {
									...this.state.empresa.endereco,
									logradouro: dados.logradouro,
									bairro: dados.bairro,
									cidade: dados.localidade,
									estado: dados.uf,
								},
							},
							erros: err,
						});
					} else {
						this.setState({
							carregandoCEP: false,
							erros: { ...this.state.erros, cep: "Cep invalido" },
						});
					}
					this.setState({ carregandoCEP: false });
				}.bind(this)
			).fail(err =>
				this.setState({
					carregandoCEP: false,
					erros: { ...this.state.erros, cep: "Cep invalido" },
				})
			);
		});
	};

	/**
	 * @name fileUploadHandler
	 * @description arruma no state a foto da empresa
	 * @param {Object} event objeto do evento de file
	 */
	fileUploadHandler = event => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = e => {
				this.setState({ fotoEmpresa: file, logoEmpresa: e.target.result });
			};
			reader.readAsDataURL(file);
		}
	};

	/**
	 * @name validacoesBancarias
	 * @description valida os campos de banco
	 */
	validacoesBancarias = () => {
		var {
			objetoBanco: { formatoConta, formatoAgencia },
			agencia,
			conta,
		} = this.state.empresa.banco;
		var erros = {};
		formatoAgencia.length !== agencia.length
			? (erros.agencia = "Complete a agência")
			: delete erros.agencia;
		formatoConta.length !== conta.length
			? (erros.conta = "Complete a conta")
			: delete erros.conta;
		if (!_.isEmpty(erros)) {
			this.setState({ erros });
			return false;
		}
		return true;
	};

	// TODO: refatorar validação do cadastro.

	/**
	 * @name onSubmit
	 * @description pega o valor do step e verifica se for acima de 7, se for
	 * manda para o firebase as informações
	 * @param {Number} s numero do step
	 */
	onSubmit = s => e => (s < 7 ? this.next(e, s) : this.submit(e, s));

	/**
	 * @name next
	 * @description Faz avançar em steps
	 * @param {Object} e evento
	 * @param {Number} s numero do step que estava
	 */
	next = (e, s) => {
		e.preventDefault();
		validar(s, this.state.empresa, this.state.erros).then(v => {
			if (s === 4 && this.validacoesBancarias()) {
				return this.setState({ step: s + 1 });
			} else if (s !== 4 && v()) {
				return this.setState({ step: s + 1 });
			}
		});
	};

	/**
	 * @name submit
	 * @description verifica informações da empresa e envia para o firebase
	 * @param {Object} e evento
	 * @param {Number} s step do cadastro
	 */
	submit = (e, s) => {
		const {
			fotoEmpresa,
			senha,
			termos,
			empresa: { email },
			erroSenha,
		} = this.state;
		e.preventDefault();
		if (!termos) {
			this.setState({
				erroTermos: "precisa.aceitar.termos",
				carregando: false,
			});
			return;
		} else if (erroSenha !== "") {
			return this.setState(
				{
					carregando: true,
					erroCadastro: this.props.t("as.senhas.digitadas"),
				},
				() => setTimeout(() => this.setState({ carregando: false }), 3000)
			);
		}

		const createContext = (email, password, empresa, fotoEmpresa) =>
			Promise.resolve({
				usuario: { email, password },
				empresa: { ...empresa, cnpj: empresa.cnpj.replace(/[./-]/g, "") },
				fotoEmpresa,
				token: null,
				error: null,
			});

		if (this.state.empresa.tipo === 2) {
			delete this.state.empresa.novoCadastro;
		}

		delete this.state.empresa.banco.objetoBanco.key;
		delete this.state.empresa.banco.objetoBanco.value;
		delete this.state.empresa.banco.objetoBanco.text;

		if (this.state.empresa.vendedor.codigo === "") {
			delete this.state.empresa.vendedor;
		}

		this.setState({ carregando: true }, () =>
			this.props
				.dispatch(cadastrandoUsuario())
				.then(
					// NOTE: essa é a parte principal do pipeline
					// qualquer erro que acontecer, o usuário será deletado
					// (enquanto não temos um mode de recuperação).
					() =>
						createContext(email, senha, this.state.empresa, fotoEmpresa).then(
							cadastrarEmpresa
						)
				)
				.then(({ usuario, empresa }) => {
					this.props.dispatch(autenticarUsuario(usuario, empresa));
				})
				.catch(this.erroNoCadastro.bind(this))
		);
	};

	processErrors = {
		"auth/email-already-in-use": "empresa.existente.dados.acesso",
		"auth/token": "falha.cadastro.empresa",
		"function/unable_to_call": "falha.cadastro.empresa",
		"account/failed": "falha.cadastro.empresa",
		default: "falha.cadastro.empresa",
	};

	/**
	 * @name erroNoCadastro
	 * @description da setState no erro
	 * @param {Object} erros erro que veio
	 */
	erroNoCadastro = erros =>
		this.setState({
			erroCadastro: this.processErrors[(erros.error || { code: "default" }).code],
			carregando: false,
		});

	render() {
		const { t } = this.props;

		if (this.state.redirect !== "") {
			return <Redirect to={this.state.redirect}></Redirect>;
		}

		const steps = [1, 2, 3, 4, 5, 6, 7];

		const Step = table[this.state.step];

		if (this.state.isPromoter) {
			return <SelectorRegisterType setIsPromoter={status => this.setIsPromoter(status)} />;
		}

		return (
			<section className="context-bootstrap cadastro text-center">
				<Helmet>
					<script>
						{`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '739732460511854');
            fbq('track', 'PageView');`}
					</script>
					<noscript>
						{`<img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=739732460511854&ev=PageView&noscript=1"
            />`}
					</noscript>
				</Helmet>
				<div className="base-cadastro">
					<Center>
						<img
							src={logo}
							width="194"
							height="44"
							alt="Promotor"
							className="img-fluid mt-5 mb-5"
						/>
					</Center>
					<div>
						<h1 className="font-24 mt-md-4 mb-4 font-weight-light">
							{t("cadastre.se")}
							<br />
						</h1>
						{!this.state.empresa.tipo ? (
							<div>
								<p style={{ fontSize: 18 }}>
									Selecione abaixo qual opção mais se adequa à sua empresa.
								</p>
								<div
									className="botao-agencia-empresa"
									onClick={() =>
										this.setState({
											empresa: { ...this.state.empresa, tipo: 1 },
										})
									}>
									<Grid stackable>
										<Grid.Column width={3}>
											<p
												style={{
													top: "50%",
													position: "relative",
													transform: "translateY(-50%)",
												}}>
												<Icon size="big" name="building outline" />
											</p>
										</Grid.Column>
										<Grid.Column
											width={13}
											textAlign="left"
											style={{ lineHeight: "20px" }}>
											<span
												style={{
													fontWeight: "bold",
													fontSize: 25,
													display: "block",
													paddingBottom: "1rem",
												}}>
												Empresa
											</span>
											<span style={{ fontSize: 20 }}>
												Esta opção é para as empresas que desejam encontrar
												e contratar promotores freelance.{" "}
											</span>
										</Grid.Column>
									</Grid>
								</div>
								<div
									className="botao-agencia-empresa"
									style={{ borderTop: "1px solid #eee" }}
									onClick={() =>
										this.setState({
											empresa: { ...this.state.empresa, tipo: 2 },
										})
									}>
									<Grid stackable>
										<Grid.Column width={3}>
											<p
												style={{
													top: "50%",
													position: "relative",
													transform: "translateY(-50%)",
												}}>
												<Icon size="big" name="search" />
											</p>
										</Grid.Column>
										<Grid.Column
											width={13}
											textAlign="left"
											style={{ lineHeight: "20px" }}>
											<span
												style={{
													fontWeight: "bold",
													fontSize: 25,
													display: "block",
													paddingBottom: "1rem",
												}}>
												Agência
											</span>
											<span style={{ fontSize: 20 }}>
												Esta opção é para agências de merchandising que
												desejam oferecer seus serviços.
											</span>
										</Grid.Column>
									</Grid>
								</div>
							</div>
						) : (
							<div>
								<ul className="list-unstyled multi-steps">
									{steps.map(index => (
										<li
											className={
												this.state.step === index ? "is-active" : ""
											}></li>
									))}
								</ul>
								<div className="login-form">
									<form
										id="form-cadastro"
										onSubmit={this.onSubmit(this.state.step).bind(this)}>
										<Step
											t={t}
											{...this.props}
											{...this.state}
											setState={state => this.setState(state)}
											fileUploadHandler={this.fileUploadHandler}
											buscarCep={this.buscarCep}
											handleTakeVendor={this.handleTakeVendor}
											handleCheckbox={this.handleCheckbox}
											handleCodigo={this.handleCodigo}
											handleChange={this.handleChange}
											handleChangeEmpresaEndereco={
												this.handleChangeEmpresaEndereco
											}
											handleChangeEmpresaBanco={this.handleChangeEmpresaBanco}
											handleChangeEmpresa={this.handleChangeEmpresa}
											handleChangeEmpresaResponsavel={
												this.handleChangeEmpresaResponsavel
											}
											verificarSenha={this.verificarSenha}
										/>
									</form>
								</div>

								{this.state.erroCadastro && (
									<p style={erroStyle}>{t(this.state.erroCadastro)}</p>
								)}
								{this.state.erroTermos && (
									<p style={erroStyle}>{t(this.state.erroTermos)}</p>
								)}
							</div>
						)}
					</div>
				</div>
				<div className="bg-img"></div>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	autenticado:
		state.AutenticacaoReducer.firebaseReady && state.AutenticacaoReducer.usuario != null,
});

export default connect(mapStateToProps)(withTranslation()(CadastroEmpresa));
