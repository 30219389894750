import _ from "lodash";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Message, Table } from "semantic-ui-react";
import {} from "../../../Componentes/Componentes";
import { alterarEstadoDeUmRelatorio } from "../../../_actions/RelatoriosActions";
import { withTranslation } from "react-i18next";
import Currency from "react-currency-formatter";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export const LinhaExcel = (props) => {
  return (
    <tr>
      <td>{props.pesquisa.produto.descricao}</td>
      <td align="right">
        <Currency
          quantity={props.pesquisa.min}
          locale="pt_BR"
          decimal=","
          group="."
          currency="R$ "
        />
      </td>
      <td align="right">
        <Currency
          quantity={props.pesquisa.max}
          locale="pt_BR"
          decimal=","
          group="."
          currency="R$ "
        />
      </td>
      <td align="right">
        <Currency
          quantity={props.pesquisa.moda}
          locale="pt_BR"
          decimal=","
          group="."
          currency="R$ "
        />
      </td>
      <td align="right">
        <Currency
          quantity={props.pesquisa.media}
          locale="pt_BR"
          decimal=","
          group="."
          currency="R$ "
        />
      </td>
      <td align="right">
        <Currency
          quantity={props.pesquisa.sugestao}
          locale="pt_BR"
          decimal=","
          group="."
          currency="R$ "
        />
      </td>
    </tr>
  );
};
class RelatorioPesquisaDePreco extends Component {
  state = {
    pesquisas: {},
    order: {},
  };

  componentDidMount() {
    this.relatorioPesquisaDePreco();
  }

  relatorioPesquisaDePreco = () => {
    var pesquisas = {};
    this.props.oportunidades.docs.forEach((oportunidade) => {
      if (!_.isEmpty(oportunidade.secoes)) {
        Object.entries(oportunidade.secoes).forEach(([key, secao]) => {
          if (_.includes(secao.atividades, 3)) {
            secao.produtos.forEach((produto) => {
              if (!produto.ruptura && produto.pesquisaDePreco) {
                var produtoRef = pesquisas[produto.id];
                if (produtoRef) {
                  produtoRef.precos.push(
                    parseFloat(
                      produto.pesquisaDePreco
                        .replace("R$ ", "")
                        .replace(",", ".")
                    )
                  );
                } else {
                  pesquisas[produto.id] = {
                    produto,
                    precos: [
                      parseFloat(
                        produto.pesquisaDePreco
                          .replace("R$ ", "")
                          .replace(",", ".")
                      ),
                    ],
                  };
                }
              }
            });
          }
        });
      }
    });
    Object.entries(pesquisas).forEach(([key, pesquisa]) => {
      pesquisas[key].min = _.min(pesquisa.precos);
      pesquisas[key].max = _.max(pesquisa.precos);
      pesquisas[key].moda = parseFloat(
        _.orderBy(
          _.reduce(
            pesquisa.precos,
            (ps, val) => {
              ps[val.toString()]
                ? (ps[val.toString()].val += 1)
                : (ps[val.toString()] = { val: 1, key: val });
              return ps;
            },
            {}
          ),
          ["val", "key"],
          ["desc", "desc"]
        )[0].key
      );
      pesquisas[key].sugestao = parseFloat(
        _.isEmpty(pesquisa.produto.preco) ? 0 : pesquisa.produto.preco
      );
      pesquisas[key].media =
        _.reduce(pesquisa.precos, (sum, val) => sum + val, 0) /
        pesquisa.precos.length;
    });
    this.setState({ pesquisas });
  };

  changeOrdenar = (coluna) => () => {
    this.setState({
      order: {
        coluna: coluna,
        ordenar:
          this.state.order?.ordenar === "ascending"
            ? "descending"
            : "ascending",
      },
    });
    console.log(this.state.order);
  };

  render() {
    let { pesquisas, order } = this.state;
    let { t } = this.props;

    return (
      <div>
        <table
          style={{ display: "none" }}
          id="table-to-xls"
          className="ui celled table"
        >
          <thead>
            <tr>
              <th>Produto</th>
              <th>Min</th>
              <th>Max</th>
              <th>Moda</th>
              <th>Média</th>
              <th>Sugestão</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(pesquisas).map((pesquisa, index) => (
              <Fragment key={index}>
                <LinhaExcel pesquisa={pesquisa} />
              </Fragment>
            ))}
          </tbody>
        </table>

        {!_.isEmpty(pesquisas) ? (
          <div
            style={{
              marginTop: 15,
              paddingRight: 3.5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button "
              table="table-to-xls"
              filename="Pesquisas"
              sheet="Oportunidades"
              buttonText="Exportar XLS"
            />
          </div>
        ) : null}

        {_.isEmpty(pesquisas) ? (
          <Message
            style={{ textAlign: "center" }}
            content={t("nenhuma.pesquisa.disponivel")}
          ></Message>
        ) : (
          <Table sortable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("produto")}</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={order.coluna === "min" ? order.ordenar : null}
                  onClick={this.changeOrdenar("min")}
                >
                  {t("min")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={order.coluna === "max" ? order.ordenar : null}
                  onClick={this.changeOrdenar("max")}
                >
                  {t("Max")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={order.coluna === "moda" ? order.ordenar : null}
                  onClick={this.changeOrdenar("moda")}
                >
                  {t("moda")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={order.coluna === "media" ? order.ordenar : null}
                  onClick={this.changeOrdenar("media")}
                >
                  {t("media")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={order.coluna === "sugestao" ? order.ordenar : null}
                  onClick={this.changeOrdenar("sugestao")}
                >
                  {t("sugestão")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.orderBy(
                pesquisas,
                [order.coluna],
                [order.ordenar === "descending" ? "desc" : order.ordenar]
              ).map((pesquisa) => (
                <Table.Row>
                  <Table.Cell>{pesquisa.produto.descricao}</Table.Cell>
                  <Table.Cell>
                    <Currency
                      quantity={pesquisa.min}
                      locale="pt_BR"
                      decimal=","
                      group="."
                      currency="R$ "
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency
                      quantity={pesquisa.max}
                      locale="pt_BR"
                      decimal=","
                      group="."
                      currency="R$ "
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency
                      quantity={pesquisa.moda}
                      locale="pt_BR"
                      decimal=","
                      group="."
                      currency="R$ "
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency
                      quantity={pesquisa.media}
                      locale="pt_BR"
                      decimal=","
                      group="."
                      currency="R$ "
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Currency
                      quantity={pesquisa.sugestao}
                      locale="pt_BR"
                      decimal=","
                      group="."
                      currency="R$ "
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  oportunidades: state.RelatoriosReducer.oportunidades,
});

export default withTranslation()(
  connect(mapStateToProps, {
    alterarEstadoDeUmRelatorio,
  })(RelatorioPesquisaDePreco)
);
