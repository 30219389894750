import React from 'react';
import { Divider, Icon } from 'semantic-ui-react';


export default props => (
    <div style={{ margin: '20px 0 40px', ...(props.style || {}) }}>
        <Divider />
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {props.icon && <Icon name={props.icon} size='large' />}
            <div style={{ color: '#666', fontWeight: 'bold', fontSize: 18, display: 'inline-block', marginLeft: 15 }}>
                {props.titulo}
                {props.subtitulo && <span style={{ color: '#999', fontSize: '0.8em', display: 'block', fontWeight: 'normal' }}>{props.subtitulo}</span>}
            </div>
        </div>
        <div style={{textAlign: 'center'}}>
        {props.children}
        </div>
    </div>
);
