import React, { Component } from "react";
import Chart from "chart.js/auto";
import "./lineChart.css";
import moment from "moment";

export default class LineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dadosTratados: [],
            diasTratados: [],
            porcent: Object.keys(props.dados).length / 2,
            porcent2: 0,
        };
    }

    /**
     * @name renderChart
     * @date Criado em 12/02/2021
     * @author Vitor Andre Savian
     * @description Renderiza o chart que vai mostrar o grafico
     */
    renderChart = () => {
        var myChart = new Chart(document.getElementById("line-chart"), {
            type: "line",
            data: {
                labels: this.state.diasTratados,

                datasets: [
                    {
                        label: "Rupturas",
                        data: this.state.dadosTratados,
                        borderColor: "#ff2828",
                        fill: false,
                        tension: 0.3,
                    },
                ],
            },
        });

        return myChart;
    };

    componentDidMount() {
        this.tratarDados(this.props.dados);
    }

    /**
     * @name tratarDados
     * @date Criado em 12/02/2020
     * @author Vitor Andre Savian
     * @description trata os dados e chama o renderChart
     * @param {Object} dados dados que serão usados para o chart
     */
    tratarDados = (dados) => {
        let dadosTratados = Object.keys(dados).reduce((acc, key) => {
            let array = dados[key];
            acc.push(array);
            return acc;
        }, []);
        let diasTratados = Object.keys(dados).reduce((acc, key) => {
            let dia = moment(key).format("DD-MM");
            acc.push(dia);
            return acc;
        }, []);
        this.setState({ dadosTratados: dadosTratados, diasTratados: diasTratados }, () => {
            this.renderChart();
        });
    };

    render() {
        return (
            <>
                <canvas id="line-chart" width="800" height="450"></canvas>
            </>
        );
    }
}
