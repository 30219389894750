import { Container } from "@chakra-ui/react";
import { Card } from "../Card";
import { BannerRoteiro } from "../Componentes";
import { OpportunityBasicInformationBar } from "../OpportunityBasicInformationBar";

/**
 * Renderiza um header com o local da oportunidade e outras informações resumidas.
 */
export function OpportunityInformationHeader(props: any) {
	return (
		<Container maxW="container.xl" p={0}>
			<Card mb={7} overflow="visible!important">
				<BannerRoteiro
					roteiro={props.roteiro}
					title={props.roteiro.loja.fantasia}
					subtitle={props.roteiro.loja.endereco.endereco}
					{...props}
					switchModalRelancarOportunidade={props.switchModalRelancarOportunidade}
					modalRelancarAberto={props.modalRelancarAberto}
				/>
			</Card>
			<OpportunityBasicInformationBar opportunity={props.roteiro} />
		</Container>
	);
}
