import {
	Button,
	ButtonProps,
	Divider,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface ConfirmationDialogProps extends Omit<ModalProps, "children"> {
	title?: string;
	message?: ReactNode;
	onConfirm?: VoidFunction;
	onCancel?: VoidFunction;
	confirmButtonText?: string;
	cancelButtonText?: string;
	showFooter?: boolean;
	confirmButtonProps?: ButtonProps;
	cancelButtonProps?: ButtonProps;
}

/**
 * Renderiza um componente modal para confirmação de ações pelo usuário.
 */
export function ConfirmationDialog({
	title,
	message,
	isOpen,
	onClose,
	onCancel,
	onConfirm,
	showFooter = true,
	confirmButtonText,
	cancelButtonText,
	confirmButtonProps,
	cancelButtonProps,
	...restProps
}: ConfirmationDialogProps) {
	const { t } = useTranslation();
	const defaultTitle = title || t("voce-tem-certeza");
	const defaultMessage = message || t("essa-acao-exige-sua-confirmacao");

	function handleConfirm() {
		onClose();
		onConfirm && onConfirm();
	}

	function handleCancel() {
		onClose();
		onCancel && onCancel();
	}

	return (
		<Modal onClose={onClose} isOpen={isOpen} isCentered {...restProps}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{defaultTitle}</ModalHeader>
				<ModalCloseButton />
				<Divider />
				<ModalBody my={4}>{defaultMessage}</ModalBody>
				{showFooter && (
					<>
						<Divider />
						<ModalFooter
							as="div"
							bg="blackAlpha.50"
							borderBottomRadius="md"
							display="flex"
							gap={2}>
							<Button
								onClick={handleCancel}
								colorScheme="red"
								variant="outline"
								{...cancelButtonProps}>
								{cancelButtonText || t("cancelar")}
							</Button>
							<Button
								onClick={handleConfirm}
								colorScheme="green"
								{...confirmButtonProps}>
								{confirmButtonText || t("confirmar")}
							</Button>
						</ModalFooter>
					</>
				)}
			</ModalContent>
		</Modal>
	);
}
