import React from "react";
import { useTranslation } from "react-i18next";
import { roteiroStatusToText } from "../../../Utils/Roteiros";
import { Opportunity, OpportunitySegmentVariant } from "../index";
import { Container, Description, Title } from "./styles";

interface CompanyAreaProps {
    opportunity: Opportunity;
    type: OpportunitySegmentVariant;
}

export function CompanyAreaInformation({ opportunity, type }: CompanyAreaProps) {
    const { t } = useTranslation();

    return (
        <Container>
            <Title>{opportunity.loja.fantasia}</Title>
            <Description>
                {type === "incomplete" ? roteiroStatusToText(t, opportunity) : opportunity.loja.endereco.endereco}
            </Description>
        </Container>
    );
}
