import { Icon } from "@iconify/react";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Container, ContainerProps, Heading, Text } from "@chakra-ui/react";

interface EmptyMessageProps extends ContainerProps {
    text?: string;
    description?: string;
    icon?: string;
}

export function EmptyMessage({ text, description, icon, ...restProps }: EmptyMessageProps) {
    const { t } = useTranslation();
    const { palette } = useTheme();

    return (
        <Container centerContent maxW="container.sm" textAlign="center" justifyContent="center" {...restProps}>
            <Icon icon={icon || "fa-solid:box-open"} color={palette.primary.main} width={80} height={80} />
            <Heading fontSize="xl" color="gray.800">
                {text || t("nao-ha-nada-aqui")}
            </Heading>
            {!!description && <Text color="gray.600">{description}</Text>}
        </Container>
    );
}
