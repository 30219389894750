import { useMutation, useQueryClient } from "@tanstack/react-query";
import { noop } from "lodash";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { OperationArea } from "../../../../@types/OperationArea";
import { GenericError } from "../../../../errors/GenericError";
import {
	OperationAreaFormProps,
	useUpsertOperationAreaForm,
} from "../../../../hooks/forms/useUpsertOperationAreaForm";
import { useAuth } from "../../../../hooks/store/useAuth";
import { OperationAreaService } from "../../../../services/OperationArea";
import { PATHS } from "../../../../Utils/Routes";
import update from "immutability-helper";

interface EditOperationAreaFormProps {
	operationArea: OperationArea;
}

export function EditOperationAreaForm({ operationArea }: EditOperationAreaFormProps) {
	const { t } = useTranslation();
	const { company } = useAuth();
	const queryClient = useQueryClient();
	const history = useHistory();

	/**
	 * Função que realiza a operação de atualização de uma nova área de atuação.
	 */
	function handleUpdateOperationArea(formData: OperationAreaFormProps) {
		return toast.promise(
			async () => {
				//NOTE: Se o shape for nulo, retorna um erro (nunca vai ser segundo a validação zod feita pelo react-hook-form, porém isso garante que a tipagem esteja correta).
				if (!formData.geometricShape) {
					throw new GenericError(t("crie-ao-menos-uma-area"));
				}

				//NOTE: Se a empresa for inexistente, retorna um erro.
				if (!company) {
					throw new GenericError(t("empresa-inexistente"));
				}

				//NOTE: Atualiza a área de atenção se tudo acima for correto.
				return OperationAreaService.getInstance().updateOne({
					_id: operationArea._id,
					name: formData.name,
					description: formData.description,
					geometry: formData.geometricShape,
					color: formData.color,
					zoomLevel: formData.zoomLevel,
				});
			},
			{
				pending: t("atualizando-area-de-atuacao").concat("..."),
				success: t("area-atualizada-com-sucesso"),
				error: {
					render: ({ data, toastProps }) => {
						if (data instanceof GenericError) {
							toastProps.type = "warning";
							return data.message;
						}

						return t("erro-ao-atualizar-a-area-de-atuacao-tente-novamente-mais-tarde");
					},
				},
			}
		);
	}

	/**
	 * Mutação com react-query para conseguirmos editar uma área de atuação local sem realizar novas requisições
	 */
	const { mutateAsync: createOperationAreaMutation } = useMutation({
		mutationFn: handleUpdateOperationArea,
		onSuccess: data => {
			const cachedData = queryClient.getQueryData<OperationArea[]>([
				"operationAreas",
				company?.uid,
			]);

			//NOTE: Atualizando o cache se ela existir
			if (cachedData) {
				//NOTE: Busca o index do elemento que foi atualizado no cache.
				const foundedIndex = cachedData.findIndex(
					cachedOperationArea => cachedOperationArea._id === operationArea._id
				);

				//NOTE: Se ele existir no array, prosseguimos.
				if (foundedIndex > -1) {
					//NOTE: Atualiza o elemento utilizando immutability-helper realizando um merge das propriedades.
					const updatedCachedData = update(cachedData, {
						[foundedIndex]: {
							$merge: data,
						},
					});

					//NOTE: Atualiza o cache com o novo dado
					queryClient.setQueryData(["operationAreas", company?.uid], updatedCachedData);
				}
			}

			//NOTE: Retorna para a página inicial (Para evitar memory leaks fazemos poucos segundos depois).
			setTimeout(() => {
				history.push(PATHS.areaDeAtuacao.root);
			}, 500);
		},
	});

	/**
	 * Função que será passada para o envio do formulário pelo react-hook-form.
	 */
	const onSubmit: SubmitHandler<OperationAreaFormProps> = data =>
		createOperationAreaMutation(data).catch(noop); //NOTE: ATENÇÃO: a função `mutateAsync` continua perpetuando o erro, mesmo ele já sendo tratado pelo react-query. Por isso ignoramos o erro que está por vir.

	const { formContent } = useUpsertOperationAreaForm({
		onSubmit,
		defaultValues: {
			name: operationArea.name,
			color: operationArea.color,
			description: operationArea.description,
			geometricShape: operationArea.geometry,
			zoomLevel: operationArea.zoomLevel,
		},
		editMode: true,
	});

	return formContent;
}
