import {
	Box,
	Button,
	Collapse,
	Flex,
	Heading,
	IconButton,
	Table,
	TableContainer,
	Text,
	Th,
	Thead,
	Tr,
	useBreakpoint,
	useDisclosure,
} from "@chakra-ui/react";
import { Badge } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import { patternFormatter } from "react-number-format";
import { Opportunity } from "../../../@types/Opportunity";
import { Card } from "../../../Componentes/Card";
import { Iconify } from "../../../Componentes/Iconify";
import { PendingReceipt } from "../../../hooks/query/useReceipts";
import { CNPJ_MASK } from "../../../Utils/Masks";
import { ReceiptCustomerTableBody } from "./ReceiptCustomerTableBody";
import { ReceiptCustomerTableFooter } from "./ReceiptCustomerTableFooter";

interface ReceiptCustomerTableProps {
	receipt: PendingReceipt;
}

export interface ReceiptFormProps {
	opportunities: (Opportunity & {
		isChecked: boolean;
		/**Campo extra necessário por conta da substituição do campo id pelo RHF ao usar useFieldArray */
		uid: string;
	})[];
	file: File | null;
	isTradePro: boolean;
}

export function ReceiptCustomerTable({ receipt }: ReceiptCustomerTableProps) {
	const collapseController = useDisclosure({ defaultIsOpen: true });
	const isBaseBreakpoint = useBreakpoint() === "base";

	const formMethods = useForm<ReceiptFormProps>({
		defaultValues: {
			opportunities: receipt.opportunities.map(op => ({
				...op,
				uid: op.id,
				isChecked: false,
			})),
			file: null,
			isTradePro: receipt.isTradePro,
		},
	});

	return (
		<FormProvider {...formMethods}>
			<Flex direction="column" gap={4}>
				<Card
					as="header"
					display="flex"
					alignItems={{ base: "stretch", sm: "center" }}
					gap={4}
					flexDir={{ base: "column", sm: "row" }}
					justifyContent="space-between"
					mx={2}>
					<Box>
						<Heading fontSize={"xl"}>{receipt.customerName}</Heading>
						<Box mt={2}>
							<Text lineHeight={1} mb={1}>
								<b>CNPJ:</b> {patternFormatter(receipt.customerCnpj, CNPJ_MASK)}
							</Text>
							<Text lineHeight={1}>
								<b>Email:</b> {receipt.customerEmail}
							</Text>
						</Box>
					</Box>
					<Badge badgeContent={receipt.opportunities.length} color="secondary">
						{isBaseBreakpoint ? (
							<Button
								w={"full"}
								onClick={collapseController.onToggle}
								leftIcon={
									<Iconify
										icon={
											collapseController.isOpen
												? "mdi:arrow-up"
												: "mdi:arrow-down"
										}
									/>
								}>
								{collapseController.isOpen ? "Ocultar" : "Expandir"}
							</Button>
						) : (
							<IconButton
								rounded={"full"}
								aria-label="Expandir"
								onClick={collapseController.onToggle}
								icon={
									<Iconify
										icon={
											collapseController.isOpen
												? "mdi:arrow-up"
												: "mdi:arrow-down"
										}
									/>
								}
							/>
						)}
					</Badge>
				</Card>

				<Collapse in={collapseController.isOpen} animateOpacity={false}>
					<Card p={0} m={2} overflow="hidden">
						<TableContainer>
							<Table>
								<Thead bg={"gray.100"}>
									<Tr h={16}>
										<Th textAlign="center">#</Th>
										<Th>Roteiro</Th>
										<Th>Loja</Th>
										<Th isNumeric>Valor</Th>
									</Tr>
								</Thead>
								<ReceiptCustomerTableBody />
								<ReceiptCustomerTableFooter />
							</Table>
						</TableContainer>
					</Card>
				</Collapse>
			</Flex>
		</FormProvider>
	);
}
