import {
	Button,
	Grid,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverProps,
	PopoverTrigger as OrigPopoverTrigger,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { Iconify } from "../Iconify";

const PopoverTrigger: React.FC<{ children: React.ReactNode }> = OrigPopoverTrigger;

interface ActionOptionsMenuProps extends PopoverProps {
	children: ReactNode;
	actions: {
		label: string;
		handle: VoidFunction;
		icon?: string;
		isDisabled?: boolean;
		isLoading?: boolean;
	}[];
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Renderiza um menu de ações personalizável.
 */
export function ActionOptionsMenu({ children, actions, ...restProps }: ActionOptionsMenuProps) {
	return (
		<Popover {...restProps}>
			{({ onClose }) => (
				<>
					<PopoverTrigger>{children}</PopoverTrigger>
					<PopoverContent w="fit-content" shadow="lg">
						<PopoverArrow />
						<PopoverBody p={0}>
							<Grid>
								{actions.map((action, index) => (
									<Button
										key={index}
										borderRadius={0}
										w="full"
										isDisabled={action.isDisabled}
										isLoading={action.isLoading}
										onClick={() => {
											action.handle();
											onClose();
										}}
										colorScheme="gray"
										variant="ghost"
										leftIcon={
											action.icon ? (
												<Iconify icon={action.icon} boxSize={6} />
											) : undefined
										}>
										{action.label}
									</Button>
								))}
							</Grid>
						</PopoverBody>
					</PopoverContent>
				</>
			)}
		</Popover>
	);
}
