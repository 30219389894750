import axios from 'axios';
import firebase from 'firebase';
import React, { Component } from 'react';
import { Form, Header, Icon, Select } from "semantic-ui-react";
import { db } from '../../config';

const tiposConta = [
    { text: 'Conta Corrente', key: '001', value: { nome: 'Corrente', id: 0 } },
    { text: 'Conta Poupança', key: '002', value: { nome: 'Poupança', id: 1 } },
]

const operacoes = [
    { text: '001 – Conta Corrente de Pessoa Física', key: '001', value: '001' },
    { text: '002 – Conta Simples de Pessoa Física', key: '002', value: '002' },
    { text: '003 – Conta Corrente de Pessoa Jurídica', key: '003', value: '003' },
    { text: '013 – Poupança de Pessoa Física', key: '013', value: '013' },
    { text: '022 – Poupança de Pessoa Jurídica', key: '022', value: '022' },
]

const Cabecalho = (props) => (
    <>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <Header as="h3" style={{ width: '100%' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>
                        <Icon name="info" size="big" />
                        <Header.Content>
                            {props.t('dados.bancarios')}
                            <Header.Subheader>
                                {props.t('editar.dados.bancarios')}
                            </Header.Subheader>
                        </Header.Content>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {props.inputIsOpen &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <Input
                                    required
                                    type="password"
                                    name="senha"
                                    onChange={props.handleChange}
                                    style={{ height: 35 }}
                                    placeholder={props.t('digite.sua.senha')} />

                            </div>
                        }
                        <Form.Button
                            loading={props.state.loading}
                            floated="right"
                            content={props.t('salvar')}
                            basic icon="pencil alternate" />
                        <Form.Button
                            color="red"
                            floated="right"
                            content={props.t('cancelar')}
                            basic icon="times"
                            onClick={(e) => {
                                e.preventDefault()
                                if (!props.inputIsOpen) {
                                    props.closeEditBank()
                                } else {
                                    props.toggleInput()
                                }
                            }} />
                    </div>
                </div>
                <ul style={{ textAlign: 'center', listStyle: 'none' }}>
                    {props.errors_list ? props.errors_list.map((e) =>
                        <li style={{
                            color: 'red',
                            marginBottom: 0,
                            fontSize: 13,
                            fontWeight: 'normal'
                        }}>{e}</li>
                    ) : null}
                </ul>
            </Header>
        </div>
        <hr />
    </>
)

const formatarInput = (mask, event, onChange) => {
    const format = mask;
    const _text = event.target.value.replace(/[^-0-9]/g, '');
    const dashIndex = format.indexOf("-");
    const expectedLength = format.replace(/-/, "").length;
    const rawTextLength = _text.replace(/-/, "").length;
    let result;
    if (rawTextLength < expectedLength) {
        result = (new Array(expectedLength - rawTextLength)).fill('0').join('') + event.target.value.replace(/-/, "");
    } else {
        result = _text.replace(/-/, "");
    }
    var conta = dashIndex > -1 ?
        result.replace(new RegExp(`^\\d*(\\d{${dashIndex}})(\\d{1})$`), "$1-$2") :
        result.replace(new RegExp(`^\\d*(\\d{${expectedLength}})$`), "$1");
    return onChange({ target: { name: event.target.name, value: conta } });
}

const Input = (props) => {
    return <div className="form-group">
        <input {...props} className={props.error && props.error.erro ? 'inputError' : props.className} />
        {props.error && props.error.erro ? (
            <p style={{
                textAlign: 'left',
                paddingLeft: '18px',
                marginTop: 0,
                color: '#b3b3b3',
                marginBottom: -13,
                fontSize: 13,
                fontWeight: 'normal'
            }}>{props.error.msg}</p>
        ) : null}
    </div>
}

const SelectInput = (props) => {
    return <>
        <Select {...props} className={props.error && props.error.erro ? 'inputError' : props.className} error={false} />
        <input type='text' value={props.value} required style={{ height: 0, padding: 0, opacity: 0 }} />
        {props.error && props.error.erro ? (
            <p style={{
                textAlign: 'left',
                paddingLeft: '18px',
                marginTop: -20,
                color: '#b3b3b3',
                marginBottom: 0,
                fontSize: 15,
                fontWeight: 'normal'
            }}>{props.error.msg}</p>
        ) : null}
    </>
}

const FormBank = (props) => {
    var objBank = props.state.bancos.find(e => e.banco === props.state.banco)
    objBank = objBank ? objBank : {}
    return (
        <Form
            onSubmit={props.handleSubmit}
        >
            <Cabecalho {...props} />
            <SelectInput
                error={{
                    erro: false,
                    msg: 'error'
                }}
                fluid
                placeholder={props.t('banco')}
                options={props.bancos}
                name="banco"
                onChange={(e, { name, value }) => props.handleChange({ target: { value, name } })}
                value={props.state.banco}
                required />
            <SelectInput
                error={{
                    erro: false,
                    msg: 'error'
                }}
                fluid
                placeholder={props.t('tipo.de.conta')}
                options={props.tipoConta}
                name="tipoConta"
                onChange={(e, { name, value }) => props.handleChange({ target: { value, name } })}
                value={props.state.tipoConta}
                required />
            <Input
                error={{
                    erro: false,
                    msg: 'error'
                }}  
                placeholder={props.t('agencia')}
                name="agencia"
                onChange={(e) => formatarInput(objBank.formatoAgencia, e, props.handleChange)}
                value={props.state.agencia}
                required />
            <Input
                error={{
                    erro: false,
                    msg: 'error'
                }}
                placeholder={props.t('conta')}
                name="conta"
                onChange={(e) => formatarInput(objBank.formatoConta, e, props.handleChange)}
                value={props.state.conta}
                required />
            {props.state.banco === 'Caixa Econômica' && <SelectInput
                error={{
                    erro: false,
                    msg: 'error'
                }}
                fluid
                placeholder={props.t('selecione.uma.operacao')}
                options={props.operacao}
                name="operacao"
                onChange={(e, { name, value }) => props.handleChange({ target: { value, name } })}
                value={props.state.operacao}
                required />}
        </Form>
    )
}

class EdicaoBancario extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submit: this.props.empresa.banco,
            inputIsOpen: false,
            bancos: [],
            error: {},
            loading: false
        }
    }

    componentDidMount() {
        db.collection('bancos').get().then(snap => {
            var bancos = [];
            snap.forEach(doc => {
                const banco = doc.data();
                bancos.push({ ...banco, text: banco.banco, key: banco.id, value: banco.banco })
            })
            this.setState({ bancos })
        }).then(() => this.setState({ ...this.state.submit, banco: this.state.submit.objetoBanco.banco }))
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value, error: { [e.target.name]: { erro: false, msg: '' } } })

    editarDomicilioBancario = ({ agencia, conta, tipoConta, objetoBanco, empresa }) => {
        return firebase.auth().currentUser.getIdToken(true).then(
            token_id => {
                var url = "https://us-central1-promotor-desenvolvimento.cloudfunctions.net/updatePerfil";
                var config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token_id
                    }
                };
                var data = {
                    data: {
                        agencia: agencia,
                        conta: conta,
                        tipoConta: tipoConta,
                        objetoBanco: objetoBanco,
                        empresa,
                        id: this.props.empresa.uid
                    }
                };
                return axios.post(url, data, config).then(
                    response => {
                        return response
                    }
                )
            }
        );
    }

    reAuthenticar = (senhaAtual) => {
        const user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            senhaAtual
        );
        return user.reauthenticateWithCredential(credential)
    }


    handleSubmit = () => {
        const { banco, tipoConta, agencia, conta, operacao, senha } = this.state
        var getBank = this.state.bancos.find((e) => banco === e.banco)
        if (!senha) {
            this.setState({ submit: { objetoBanco: getBank, tipoConta, agencia, conta, operacao, empresa: true } }, this.toggleInput)
        } else {
            this.reAuthenticar(senha)
                .then(() => this.setState({
                    senha: '',
                    inputIsOpen: true,
                    loading: true
                }, () => this.editarDomicilioBancario(this.state.submit)
                    .then((r) => { this.setState({ errors_list: r.data.result.base, loading: false }) })
                    .catch((e) => console.error(e))
                ))
                .catch(() => {
                    this.setState({ senha: '', error: { senha: { msg: this.props.t('senha.incorretos'), erro: true, loading: false } } })
                })
        }
    }

    toggleInput = () => this.setState({ inputIsOpen: !this.state.inputIsOpen, senha: '' })

    render() {
        var {t} = this.props
        return (
            <FormBank
                t={t}
                closeEditBank={this.props.closeEditBank}
                error={this.state.error}
                errors_list={this.state.errors_list}
                submit={this.state.submit}
                state={this.state}
                bancos={this.state.bancos}
                tipoConta={tiposConta}
                operacao={operacoes}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                inputIsOpen={this.state.inputIsOpen}
                toggleInput={this.toggleInput}
                showOp={this.state.tipoConta && (this.state.tipoConta.nome === 'Poupança')} />
        )
    }
}

export default EdicaoBancario