import LinearProgress from "@material-ui/core/LinearProgress";
import "cleave.js/dist/addons/cleave-phone.br";
import { Checkbox } from "primereact/checkbox";
import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import "./cadastro.css";
import { BotaoProximo } from "./CadastroEmpresa";
export default class Step5 extends Component {
	render() {
		const {
			t,
			step,
			carregando,
			empresa,
			senha,
			termos,
			erroSenha,
			erroCampo,
			handleChangeEmpresa,
			handleChange,
			verificarSenha,
			handleCheckbox,
		} = this.props;

		return (
			<div className={step === 7 ? "d-block" : "d-none"}>
				<div>
					<div className="titulo"></div>
					{t("dados.acesso")}
				</div>
				<div className="form-group">
					<input
						name="email"
						type="email"
						required
						maxLength={45}
						value={empresa.email}
						onChange={handleChangeEmpresa}
						placeholder={t("email")}
					/>
				</div>
				<div className="form-group">
					<input
						name="senha"
						type="password"
						required
						minLength={6}
						maxLength={20}
						value={senha}
						onChange={handleChange}
						placeholder={t("senha")}
					/>
				</div>
				<div className="form-group">
					<input
						name="senha2"
						type="password"
						required
						minLength={6}
						maxLength={20}
						style={erroSenha !== "" ? erroCampo : {}}
						onBlur={verificarSenha}
						placeholder={t("confirme.senha")}
					/>
				</div>

				<div>
					<p style={{ padding: 15, fontSize: 20 }}>{t("termos.de.uso")}</p>

					<Button
						size="medium"
						target="_blank"
						href={
							empresa.tipo === 2
								? "/imagens/Promotor-freelancer-termo-de-uso-AGENCIA.pdf"
								: "/imagens/Promotor-freelancer-termo-de-uso-EMPRESA.pdf"
						}
						basic
						content={t("visualizar.termos")}
						icon="file pdf"
					/>

					<div
						className="form-group d-flex"
						style={{ justifyContent: "center", paddingBottom: 20 }}>
						<div className="p-checkbox p-component">
							<div className="form-check opt-23">
								<Checkbox
									type="checkbox"
									name="termos"
									required
									className="form-check-input position-static"
									inputId="cb"
									checked={termos}
									onChange={handleCheckbox}
								/>
								<label for="opt-23"></label>
							</div>
						</div>
						<label for="cb" className="p-checkbox-label ml-4 mt-1">
							{t("eu.aceito.os")} {t("termos.e.condicoes")}
						</label>
					</div>
				</div>

				{carregando && (
					<div className="form-group mt-0 d-flex flex-column">
						<div className="mb-5 mt-5">
							<LinearProgress />
						</div>
					</div>
				)}
				<BotaoProximo t={t} label={t("salvar")} />
			</div>
		);
	}
}
