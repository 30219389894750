import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import SecaoSegment from '../../Interno/Roteiro/VerificarRoteiro/SecaoSegment';

const secaoEstaAberta = (sIndex, secaoAberta) => secaoAberta && secaoAberta[0] === sIndex;

const abrirSecao = (sIndex, setState, secaofon) => {
    const secaoAberta = [sIndex];
    if (secaoEstaAberta(sIndex, secaofon)) {
        setState({ secaoAberta: null })
        return;
    }
    setState({ secaoAberta });
}

export const renderSecao = (secao, roteiro, urlImagens, t, setState, secaoAberta) => {
    var abrir = () => abrirSecao(secao, setState, secaoAberta);
    var aberto = secaoEstaAberta(secao, secaoAberta);
    return <SecaoSegment item={secao}
        roteiro={roteiro}
        abrirSecao={abrir}
        aberto={aberto}
        urlImagens={urlImagens}
        t={t}
    // carregandoSecao={carregandoSecao} 
    />
}

export const verificarNotasPendentes = (notasPendentes, setState) => {
    if (!_.isEmpty(notasPendentes)) {
        let dataMenor = moment(_.minBy(notasPendentes, nota => nota.data).data, 'YYYY/MM/DD')
        let diff = moment().diff(dataMenor, 'days')
        if (diff > 15) {
            setState({ bloqueioPorNotasPendentes: true })
        }
    }
}

export const verificarRuptura = (roteiro, setState) => {
    let { secoes } = roteiro
    let produtos = _.reduce(secoes, (secao, secoes) => secao = { ...secao, ...secoes.produtos }, {})
    let ruptura = _.filter(produtos, produto => produto.ruptura === true)
    setState({ pedirContato: ruptura.length >= (_.size(produtos) * (60 / 100)) })
}

export const buscarInformacoesCandidatura = (roteiro, empresa, setState) => {
    if (_.includes(roteiro?.candidatos, 'E-' + empresa?.uid)) {
        setState({
            candidatura: true
        })
    }
}
