import firebase from "firebase";
import { orderBy } from "lodash";
import { toast } from "react-toastify";
import { Unsubscribe } from "../../@types/Firebase";
import { Negotiation } from "../../@types/Negotiation";
import { ThunkAppAction } from "../../@types/store/Store";
import { GenericError } from "../../errors/GenericError";
import { SliceError } from "../../errors/Slice.error";

export enum NegotiationActionsType {
    "NEGOTIATION_SET" = "NEGOTIATION_SET",
    "NEGOTIATION_SET_IS_FETCHING" = "NEGOTIATION_SET_IS_FETCHING",
    "NEGOTIATION_SET_INITIAL_FETCH_WAS_MADE" = "NEGOTIATION_SET_INITIAL_FETCH_WAS_MADE",
    "NEGOTIATION_SET_INITIAL_STATE_HAS_BEEN_LOADED" = "NEGOTIATION_SET_INITIAL_STATE_HAS_BEEN_LOADED",
    "NEGOTIATION_SET_ERROR" = "NEGOTIATION_SET_ERROR",
    "NEGOTIATION_ADD" = "NEGOTIATION_ADD",
    "NEGOTIATION_UPDATE" = "NEGOTIATION_UPDATE",
    "NEGOTIATION_REMOVE" = "NEGOTIATION_REMOVE",
}

export interface NegotiationActionsPayload {
    NEGOTIATION_SET: Negotiation[];
    NEGOTIATION_SET_IS_FETCHING: boolean;
    NEGOTIATION_SET_INITIAL_FETCH_WAS_MADE: boolean;
    NEGOTIATION_SET_INITIAL_STATE_HAS_BEEN_LOADED: boolean;
    NEGOTIATION_SET_ERROR: SliceError | undefined;
    NEGOTIATION_ADD: Negotiation;
    NEGOTIATION_UPDATE: Negotiation;
    NEGOTIATION_REMOVE: Negotiation | string;
}

export interface NegotiationActions<T extends NegotiationActionsType = any> {
    type: T;
    payload: NegotiationActionsPayload[T];
}

function setFetching(isFetching: boolean) {
    return {
        type: NegotiationActionsType["NEGOTIATION_SET_IS_FETCHING"],
        payload: isFetching,
    };
}

function setInitialFetchWasMade(initialFetchWasMade: boolean) {
    return {
        type: NegotiationActionsType["NEGOTIATION_SET_INITIAL_FETCH_WAS_MADE"],
        payload: initialFetchWasMade,
    };
}

const setInitialStateHasBeenLoaded = (initialStateHasBeenLoaded: boolean) => {
    return {
        type: NegotiationActionsType["NEGOTIATION_SET_INITIAL_STATE_HAS_BEEN_LOADED"],
        payload: initialStateHasBeenLoaded,
    };
};

function setError(error: SliceError) {
    return {
        type: NegotiationActionsType["NEGOTIATION_SET_ERROR"],
        payload: error,
    };
}

function setData(data: Negotiation[]) {
    return {
        type: NegotiationActionsType["NEGOTIATION_SET"],
        payload: data,
    };
}

function addNegotiation(data: Negotiation) {
    return {
        type: NegotiationActionsType["NEGOTIATION_ADD"],
        payload: data,
    };
}
function updateNegotiation(data: Negotiation) {
    return {
        type: NegotiationActionsType["NEGOTIATION_UPDATE"],
        payload: data,
    };
}
function removeNegotiation(data: Negotiation | string) {
    return {
        type: NegotiationActionsType["NEGOTIATION_REMOVE"],
        payload: data,
    };
}

function fetchData(): ThunkAppAction<Unsubscribe | void> {
    return async (dispatch, getState) => {
        const {
            NegotiationReducer: { initialFetchWasMade },
            AutenticacaoReducer: { empresa },
        } = getState();

        if (initialFetchWasMade) return;

        dispatch(setInitialFetchWasMade(true));
        dispatch(setFetching(true));

        console.log(firebase.auth().currentUser);

        const firebaseQuery = firebase
            .firestore()
            .collectionGroup("negociacoes")
            .where("idExecutor", "==", empresa.uid);

        return firebaseQuery.onSnapshot(
            async (querySnapshot) => {
                try {
                    const {
                        NegotiationReducer: { initialStateHasBeenLoaded },
                    } = getState();
                    dispatch(setFetching(true));

                    //NOTE: Se for a primeira vez, faz inclusão em massa
                    if (!initialStateHasBeenLoaded) {
                        const docs = querySnapshot.docs.map((doc) => doc.data() as Negotiation);

                        const orderedDocs = orderBy(docs, "criadoEm", "desc");

                        dispatch(setData(orderedDocs));
                        dispatch(setInitialStateHasBeenLoaded(true));
                    } else {
                        //NOTE: Se não for a primeira vez, faz inclusão, alteração e remoção individual
                        const changes = querySnapshot.docChanges();

                        for (const change of changes) {
                            const { type } = change;

                            const transformedData = change.doc.data() as Negotiation;

                            switch (type) {
                                case "added":
                                    dispatch(addNegotiation(transformedData));
                                    break;
                                case "modified":
                                    dispatch(updateNegotiation(transformedData));
                                    break;
                                case "removed":
                                    dispatch(removeNegotiation(transformedData));
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                } catch (e) {
                    const error = new GenericError(e);
                    dispatch(setError(new SliceError("NEGOTIATION", error.message)));
                    toast.error("Ocorreu um erro ao buscar as negociações. Tente novamente mais tarde.");
                } finally {
                    dispatch(setFetching(false));
                }
            },
            (e) => {
                const error = new GenericError(e);
                dispatch(setError(new SliceError("NEGOTIATION", error.message)));
                toast.error("Ocorreu um erro ao buscar as negociações. Tente novamente mais tarde.");
                dispatch(setFetching(false));
            }
        );
    };
}

export const actions = {
    fetchData,
    setFetching,
    setInitialFetchWasMade,
    setData,
    setInitialStateHasBeenLoaded,
    setError,
};
