export type SliceErrorContext = "ANY" | "REDES" | "NEGOTIATION";

export class SliceError extends Error {
    context: SliceErrorContext;

    constructor(context: SliceErrorContext, msg?: string) {
        super(msg);

        this.context = context;
        this.name = `SliceError(${context})`;

        Object.setPrototypeOf(this, SliceError.prototype);
    }
}
