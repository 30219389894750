import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import moment from "moment";
import { Log } from "../../@types/Logs";
import { StatusType } from "../../pages/LogList/LogListFilter";
import { mongoDBAtlas } from "../Api";

export interface FetchLogsReturn {
	totalPages: number;
	total: number;
	docs: Log[];
}

/**
 * Serviço para interagir com os recursos de log da aplicação.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export class LogService {
	private static instance: LogService;

	public static getInstance(): LogService {
		if (!LogService.instance) {
			LogService.instance = new LogService();
		}
		return LogService.instance;
	}

	/**
	 * @description Retorna os logs da empresa permitindo paginação.
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @param companyId Id da empresa associada aos logs.
	 * @param page Número da página a ser retornada.
	 * @param pageSize Quantidade de itens da página.
	 * @param filterText Texto para filtrar os logs.
	 * @returns Um objeto contendo os logs, a quantidade total de itens e a quantidade total de paginas.
	 */
	async fetchLogsPaginated(
		companyId: string,
		page: number,
		pageSize: number,
		filterText: string,
		filterDate: ParsableDate,
		statusFilter: StatusType[]
	): Promise<FetchLogsReturn> {
		const skips = pageSize * (page - 1);

		type FetchLogsReturn = {
			total: number;
			docs: Log[];
		};

		//NOTE: Cria o pipeline básico com ordenação por data e filtro pela empresa.
		const pipeline: any[] = [
			{
				$match: {
					usuario: companyId,
				},
			},
			{ $sort: { data: -1 } },
		];

		//NOTE: Se houver texto para filtrar, adiciona um $match de filtro.
		if (filterText) {
			pipeline.push({
				$match: {
					$or: [
						{ ip: { $regex: filterText, $options: "i" } },
						{ descricao: { $regex: filterText, $options: "i" } },
						{ "usuarioLogado.email": { $regex: filterText, $options: "i" } },
						{ "usuarioLogado.nome": { $regex: filterText, $options: "i" } },
						{ "usuarioLogado.uid": { $regex: filterText, $options: "i" } },
					],
				},
			});
		}

		//NOTE: Se houver data para filtrar, adiciona um $match de filtro.
		if (filterDate) {
			const startOfDay = moment(filterDate).utc().startOf("day").toDate();
			const endOfDay = moment(filterDate).utc().endOf("day").toDate();
			pipeline.push({
				$match: {
					data: {
						$gte: { $date: startOfDay },
						$lte: { $date: endOfDay },
					},
				},
			});
		}

		//NOTE: Se houver filtro de status, adiciona um $match de filtro.
		if (statusFilter.length > 0) {
			pipeline.push({
				$match: {
					acao: {
						$in: statusFilter.map(status => status.value),
					},
				},
			});
		}

		//NOTE: Ao final, adiciona o $facet e o $project ao pipeline para melhorar como os dados serão exibidos
		pipeline.push(
			{
				$facet: {
					total: [{ $count: "count" }],
					docs: [{ $skip: skips }, { $limit: pageSize }],
				},
			},
			{
				$project: {
					total: { $arrayElemAt: ["$total.count", 0] },
					docs: 1,
				},
			}
		);

		const { documents } = await mongoDBAtlas<FetchLogsReturn>(
			"POST",
			"logs",
			"/action/aggregate",
			{
				pipeline,
			}
		);

		const apiReturn = documents[0];

		const dataToReturn = {
			...apiReturn,
			total: apiReturn.total || 0,
			totalPages: Math.ceil(apiReturn.total / pageSize) || 0,
		};

		return dataToReturn;
	}
}
