import _ from "lodash";
import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import { Button, Divider, Grid, Header, Icon, Image, Modal, Table } from "semantic-ui-react";
import moment from "moment";
import { iconPorAtividade } from "../../Dashboard/DashboardComponents";

export default class SecaoSegment extends Component {
	state = {
		openModalFotoAntes: false,
		openModalFotoDepois: false,
		openModalFotoPesquisa: false,
		indexEscolhidoFotoAntes: 0,
		indexEscolhidoFotoDepois: 0,
		indexEscolhidoPesquisaPreco: 0,
	};

	handleFormatarPreco = preco => {
		return _.includes(preco, "R$")
			? preco
			: parseFloat(preco).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
	};

	openModalFotoAntes = indexFotoAntes => {
		this.setState({
			openModalFotoAntes: !this.state.openModalFotoAntes,
			indexEscolhidoFotoAntes: indexFotoAntes,
		});
	};

	openModalFotoPesquisa = indexFoto => {
		this.setState({ openModalFotoPesquisa: true, indexEscolhidoPesquisaPreco: indexFoto });
	};

	closeModalFoto = () => {
		this.setState({
			openModalFotoAntes: false,
			openModalFotoDepois: false,
			openModalFotoPesquisa: false,
		});
	};

	openModalFotoDepois = indexFotoDepois => {
		this.setState({
			openModalFotoDepois: !this.state.openModalFotoDepois,
			indexEscolhidoFotoDepois: indexFotoDepois,
		});
	};

	render() {
		const { item, roteiro, abrirSecao, aberto, t, urlImagens } = this.props;
		const { indexEscolhidoFotoAntes, indexEscolhidoFotoDepois, indexEscolhidoPesquisaPreco } =
			this.state;
		let imagesPesquisaPreco, todasFotos;
		const imagesFotoDepois = _.map(item.fotoDepois, ref => {
			return {
				original: urlImagens[ref],
				thumbnail: urlImagens[ref],
			};
		});

		const imagesFotoAntes = _.map(item.fotoAntes, ref => {
			return {
				original: urlImagens[ref],
				thumbnail: urlImagens[ref],
			};
		});

		if (roteiro.tipo.id === 4) {
			imagesPesquisaPreco = [...imagesFotoDepois, ...imagesFotoAntes];
			todasFotos = [...(item.fotoAntes ?? []), ...(item.fotoDepois ?? [])];
		}

		const gerarDadosSecao = item => {
			var produtos = Object.keys(item).reduce(result => {
				result.push(...item.produtos);
				return result;
			}, []);
			var percentualRuptura =
				produtos &&
				((_.filter(produtos, { ruptura: true }).length / produtos.length) * 100).toFixed(0);
			var percentualReposicao =
				produtos &&
				((_.filter(produtos, { reposto: true }).length / produtos.length) * 100).toFixed(0);
			return { percentualRuptura, percentualReposicao };
		};
		var secaoConsolidada = (item && gerarDadosSecao(item)) ?? {};
		return (
			<div>
				<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
					<div style={{ display: "flex", alignItems: "center", flex: 1 }}>
						<div
							style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
							<span style={{ fontWeight: "bold", fontSize: 20, marginBottom: 5 }}>
								{item.nome}
							</span>
							<div className="lista-icones-atividades-secoes">
								{item.atividades.map(atividade => (
									<div
										key={atividade}
										style={{
											padding: 10,
											backgroundColor: "#f6f6f6",
											fontSize: 18,
											alignItems: "center",
											borderRadius: 5,
											marginRight: 5,
											marginBottom: 5,
										}}>
										<Icon
											name={iconPorAtividade[atividade]}
											style={{
												color: "#666",
												margin: 0,
											}}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							color: "#666",
							textAlign: "center",
							marginRight: 20,
							marginLeft: 10,
						}}
						className="center-v">
						<span style={{ fontWeight: "bold", fontSize: 16 }}>
							{item.produtos.length}
						</span>
						<span>{t("produtos")}</span>
					</div>
					{_.includes(item.atividades, 6) && (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								color: "#35BE35",
								textAlign: "center",
								marginRight: 10,
								marginLeft: 10,
							}}
							className="center-v">
							<span style={{ fontWeight: "bold", fontSize: 16 }}>
								{secaoConsolidada.percentualReposicao}%
							</span>
							<span>{t("reposicao")}</span>
						</div>
					)}
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							color: "#F44336",
							textAlign: "center",
							marginRight: 20,
							marginLeft: 10,
						}}
						className="center-v">
						<span style={{ fontWeight: "bold", fontSize: 16 }}>
							{secaoConsolidada.percentualRuptura}%
						</span>
						<span>{t("ruptura")}</span>
					</div>
					{/* <div style={{ display: 'flex', alignItems: 'center', marginRight: 10, marginLeft: 10 }}>
                        <SvgCircleGraph name={t('reposicao')} percentage={secaoConsolidada.percentualReposicao} color='green' style={{ width: 60 }} />
                        <SvgCircleGraph name={t('ruptura')} percentage={secaoConsolidada.percentualRuptura} color='red' style={{ width: 60 }} />
                    </div> */}
					<div style={{ display: "flex", alignItems: "center" }}>
						<Button
							icon={aberto ? "angle up" : "angle down"}
							color="violet"
							content={!aberto ? t("detalhar") : t("recolher")}
							onClick={() => {
								abrirSecao(item);
							}}
							fluid
						/>
					</div>
				</div>
				{aberto && (
					<div>
						<Divider />
						{item.fotoDepois !== undefined && roteiro.tipo.id !== 4 ? (
							<Grid columns={2}>
								<Grid.Column width={8}>
									<Header as="h4" textAlign="center">
										<Icon name="photo" />
										<Header.Content>Fotos Antes</Header.Content>
									</Header>
									{_.map(item.fotoAntes, (ref, index) => {
										return (
											<Image
												as="button"
												style={{
													border: "none",
													background: "transparent",
													outline: "none",
												}}
												size="tiny"
												src={urlImagens[ref]}
												onClick={() => this.openModalFotoAntes(index)}
												key={ref}
											/>
										);
									})}
								</Grid.Column>
								<Grid.Column width={8}>
									<Header as="h4" textAlign="center">
										<Icon name="photo" />
										<Header.Content>Fotos Depois</Header.Content>
									</Header>
									{_.map(item.fotoDepois, (ref, index) => {
										return (
											<Image
												as="button"
												style={{
													border: "none",
													background: "transparent",
													outline: "none",
												}}
												size="tiny"
												src={urlImagens[ref]}
												onClick={() => this.openModalFotoDepois(index)}
												key={ref}
											/>
										);
									})}
								</Grid.Column>
							</Grid>
						) : (
							!_.isEmpty(todasFotos) && (
								<Grid stackable>
									<Grid.Column width={16}>
										<Header as="h4" textAlign="center">
											<Icon name="photo" />
											<Header.Content>Fotos</Header.Content>
										</Header>
										{_.map(todasFotos, (ref, index) => {
											return (
												<Image
													as="button"
													style={{
														border: "none",
														background: "transparent",
														outline: "none",
													}}
													size="tiny"
													src={urlImagens[ref]}
													onClick={() =>
														this.openModalFotoPesquisa(index)
													}
													key={ref}
												/>
											);
										})}
									</Grid.Column>
								</Grid>
							)
						)}
						<Table color="violet">
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t("produto")}</Table.HeaderCell>
									{_.includes(item.atividades, 6) && (
										<Table.HeaderCell>{t("reposto")}</Table.HeaderCell>
									)}
									<Table.HeaderCell>{t("ruptura")}</Table.HeaderCell>
									{_.includes(item.atividades, 3) && (
										<Table.HeaderCell>{t("preco")}</Table.HeaderCell>
									)}
									{_.includes(item.atividades, 2) && (
										<Table.HeaderCell>{t("validade")}</Table.HeaderCell>
									)}
									{_.includes(item.atividades, 2) && (
										<Table.HeaderCell>{t("quantidade")}</Table.HeaderCell>
									)}
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{item.produtos.map(produto => (
									<Table.Row key={produto.id}>
										<Table.Cell>{produto.descricao}</Table.Cell>
										{_.includes(item.atividades, 6) && (
											<Table.Cell>
												{produto.reposto ? (
													<p style={{ color: "green" }}>{t("sim")}</p>
												) : (
													<p style={{ color: "red" }}>{t("nao")}</p>
												)}
											</Table.Cell>
										)}
										<Table.Cell>
											{produto.ruptura ? (
												<p style={{ color: "red" }}>{t("sim")}</p>
											) : (
												<p style={{ color: "green" }}>{t("nao")}</p>
											)}
										</Table.Cell>
										{_.includes(item.atividades, 3) && (
											<Table.Cell>
												{produto.pesquisaDePreco === "ruptura" ||
												produto.ruptura === true
													? t("ruptura")
													: this.handleFormatarPreco(
															produto.pesquisaDePreco
														)}
											</Table.Cell>
										)}
										{_.includes(item.atividades, 2) && (
											<Table.Cell>
												{produto.pesquisaDeValidade === "ruptura" ||
												produto.ruptura === true
													? t("ruptura")
													: moment(produto.pesquisaDeValidade).format(
															"DD/MM/YY"
														)}
											</Table.Cell>
										)}
										{_.includes(item.atividades, 2) && (
											<Table.Cell>{produto.quantidade}</Table.Cell>
										)}
									</Table.Row>
								))}
							</Table.Body>
						</Table>
						{!_.isEmpty(item.concorrentes) && (
							<div>
								<Header as={"h4"} content="Concorrentes" />
								<Divider></Divider>
								<Table color="purple">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{t("produto")}</Table.HeaderCell>
											<Table.HeaderCell>{t("preco")}</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{item.concorrentes.map(concorrente => (
											<Table.Row key={concorrente}>
												<Table.Cell>{concorrente.nome}</Table.Cell>
												<Table.Cell>{concorrente.preco}</Table.Cell>
											</Table.Row>
										))}
									</Table.Body>
								</Table>
							</div>
						)}
						<Modal open={this.state.openModalFotoAntes} onClose={this.closeModalFoto}>
							<Modal.Header>{t("foto.antes")}</Modal.Header>
							<Modal.Content>
								<ImageGallery
									items={imagesFotoAntes}
									showPlayButton={false}
									additionalClass="atividade-image-gallery"
									startIndex={indexEscolhidoFotoAntes}
								/>
							</Modal.Content>
						</Modal>
						<Modal open={this.state.openModalFotoDepois} onClose={this.closeModalFoto}>
							<Modal.Header>{t("foto.depois")}</Modal.Header>
							<Modal.Content>
								<ImageGallery
									items={imagesFotoDepois}
									showPlayButton={false}
									additionalClass="atividade-image-gallery"
									startIndex={indexEscolhidoFotoDepois}
								/>
							</Modal.Content>
						</Modal>
						<Modal
							open={this.state.openModalFotoPesquisa}
							onClose={this.closeModalFoto}>
							<Modal.Header>Fotos</Modal.Header>
							<Modal.Content>
								<ImageGallery
									items={imagesPesquisaPreco}
									showPlayButton={false}
									additionalClass="atividade-image-gallery"
									startIndex={indexEscolhidoPesquisaPreco}
								/>
							</Modal.Content>
						</Modal>
					</div>
				)}
			</div>
		);
	}
}
