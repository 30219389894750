import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { Opportunities } from "./Opportunities";
import { Container, EmptyContainer } from "./styles";

interface UserProfileProps {
	opportunities: [];
	promoterName: string;
}

export function Opportunity({ opportunities, promoterName }: UserProfileProps) {
	const { t } = useTranslation();

	const EmptyMessage = useMemo(
		() => (
			<EmptyContainer>
				<Icon name="frown" style={{ color: "#7501c0" }} size="huge" />
				<h1>{t("nenhuma.oportunidade.executada")}</h1>
				<h2>{t("primeira.oportunidade").replace(/{nome}/, promoterName)}</h2>
			</EmptyContainer>
		),
		[t, promoterName]
	);

	return (
		<Container>
			{opportunities.length ? <Opportunities opportunities={opportunities} /> : EmptyMessage}
		</Container>
	);
}
