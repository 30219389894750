import { Text, Tooltip, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Ribbon } from "..";
import { Iconify } from "../../Iconify";

export function AgencyNetworkCredentialRibbon() {
	const { t } = useTranslation();

	return (
		<Ribbon color={["green.500", "green.700"]}>
			<Tooltip
				label={
					<VStack textAlign={"center"} mb={1}>
						<Text fontSize="lg">
							<Iconify icon={"mdi:check-decagram"} color="white" inline />{" "}
							{t("credencial-reconhecida")}
						</Text>
						<Text lineHeight={1.3}>{t("explicacao-agencia-credenciada-na-rede")}</Text>
					</VStack>
				}
				hasArrow>
				<Iconify icon={"mdi:check-decagram"} color="white" boxSize={5} mb={2} />
			</Tooltip>
		</Ribbon>
	);
}
