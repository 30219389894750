import { Modal, ModalContent, ModalProps, ModalContentProps } from "semantic-ui-react";
import styled from "styled-components";

export const Container: React.FC<ModalProps> = styled(Modal)``;
export const Content: React.FC<ModalContentProps> = styled(ModalContent)`
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 800px;
    height: 550px;
    overflow: auto !important;
    padding: 3rem !important;
`;

export const Image = styled.img`
    width: 100%;
`;
