export const AUTENTICAR_USUARIO = "AUTENTICAR_USUARIO";
export const SELECIONAR_PROMOTOR = "SELECIONAR_PROMOTOR";
export const ATUALIZAR_EMPRESA = "ATUALIZAR_EMPRESA";
export const ATUALIZAR_MERCADO = "ATUALIZAR_MERCADO";
export const DESAUTENTICA_USUARIO = "DESAUTENTICA_USUARIO";
export const CADASTRANDO_USUARIO = "CADASTRANDO_USUARIO";
export const ATUALIZAR_INFO_CONTA = "ATUALIZAR_INFO_CONTA";
export const ATUALIZAR_OPORTUNIDADES_MONGO = "ATUALIZAR_OPORTUNIDADES_MONGO";
export const ATUALIZAR_OPORTUNIDADES_FIREBASE = "ATUALIZAR_OPORTUNIDADES_FIREBASE";
export const ALTERNAR_MODULO = "ALTERNAR_MODULO";
export const MEUS_PROMOTORES = "MEUS_PROMOTORES";

export const DOWNLOADED_RECURSOS = "DOWNLOADED_RECURSOS";
export const NOTIFICADO_AVALIACAO_PENDENTE = "NOTIFICADO_AVALIACAO";
export const ROTEIRO_AVALIADO = "ROTEIRO_AVALIADO";

export const LOGADO = "LOGADO";
export const NAO_LOGADO = "NAO_LOGADO";
export const CADASTRANDO = "CADASTRANDO";

export const ROTEIROS = "ROTEIROS";
export const ROTEIROS_AGENCIAS = "ROTEIROS_AGENCIAS";
export const LOCALIZACAO = "LOCALIZACAO";
export const LOJAS_DA_EMPRESA = "LOJAS_DA_EMPRESA";
export const MARCAS_DA_EMPRESA = "MARCAS_DA_EMPRESA";
export const CONFIGURACAO = "CONFIGURACAO";
export const ROTEIRO_REEMBOLSADO = "ROTEIRO_REEMBOLSADO";
export const TREINAMENTOS = "TREINAMENTOS";

export const ATUALIZAR_MARCA = "ATUALIZAR_MARCA";
export const TRANSACOES = "TRANSACIOES";

export const ATUALIZAR_SALDOS = "ATUALIZAR_SALDOS";
export const ATUALIZAR_LIMITE = "ATUALIZAR_LIMITE";
export const NOTAS_PENDENTES = "NOTAS_PENDENTES";

export const IP_LOCATION = "IP_LOCATION";
export const ATUALIZAR_FATURA = "ATUALIZAR_FATURA";

export const SemLoja = Symbol("SemLoja");
export const SemMarca = Symbol("SemMarca");

export const RELATORIOS_DE_RUPTURA = "RELATORIOS_DE_RUPTURA";
export const RELATORIOS_PERIODO = "RELATORIOS_PERIODO";
export const RELATORIOS_OPORTUNIDADES = "RELATORIOS_OPORTUNIDADES";
export const OPORTUNIDADES_DASHBOARD = "OPORTUNIDADES_DASHBOARD";
export const OPORTUNIDADES_AGENCIA = "OPORTUNIDADES_AGENCIA";

export const GASTO_MENSAL = "GASTO_MENSAL";

export const ATUALIZAR_USUARIOS = "ATUALIZA_USUARIOS";

export const SET_REDES = "SET_REDES";

export const ATUALIZAR_COORDENADAS = "ATUALIZA_COORDENADAS";
export const SALVAR_FATURAS = "SALVAR_FATURAS";
