import React, { Component } from "react";
import firebase from "firebase";
import { Button, Icon, Table, Radio, Message } from "semantic-ui-react";
import "./recorrencia.scss";
import { Link } from "react-router-dom";
import { logging } from "../../../services/Api";
import RoteiroConnect from "../RoteiroConnect";
import { withTranslation } from "react-i18next";
import { ModalDeConfirmacao } from "../../../Componentes/ConfirmarRegistro";
import { ordenarDiasDaSemana } from "./utils";
import _ from "lodash";
import { Zoom } from "@material-ui/core";
import { withSnackbar } from "notistack";

class ListarRecorrencia extends Component {
	constructor(props) {
		super(props);
		const ipLocation = this.props.ipLocation;
		const empresa = this.props.empresa;
		this.state = {
			ipLocation,
			empresa,
			recorrencias: [],
			confirmOpen: false,
			confirmFunction: () => {},
		};
	}

	componentDidMount() {
		firebase
			.firestore()
			.collection("recorrencias")
			.where("idEmpresa", "==", this.props.idEmpresa)
			.onSnapshot(snap => {
				let docs = snap.docs.map(d => d.data());
				this.setState({ recorrencias: docs });
			});
	}

	deletarRecorrencia = id => {
		const { empresa, ipLocation } = this.state;
		return firebase
			.firestore()
			.collection("recorrencias")
			.doc(id)
			.delete()
			.then(() => {
				logging("DELETE", "log.delete.recorrencia", id);
			})
			.then(() => {
				this.props.enqueueSnackbar(this.props.t("recorrencia.removida"), {
					variant: "success",
				});
				this.setState({
					confirmOpen: false,
				});
			});
	};

	cancelarRecorrencia = id => {
		const { empresa, ipLocation } = this.state;
		firebase
			.firestore()
			.collection("recorrencias")
			.doc(id)
			.update({
				status: "inativo",
			})
			.then(() => {
				this.props.enqueueSnackbar(this.props.t("recorrencia.inativada"), {
					variant: "success",
				});
				logging("UPDATE", "log.update.recorrencia.desativada", id);
			});
	};

	ativarRecorrencia = id => {
		const { empresa, ipLocation } = this.state;
		firebase
			.firestore()
			.collection("recorrencias")
			.doc(id)
			.update({
				status: "ativo",
			})
			.then(() => {
				this.props.enqueueSnackbar(this.props.t("recorrencia.ativada"), {
					variant: "success",
				});
				logging("UPDATE", "log.update.recorrencia.ativada", id);
			});
	};

	listarRecorrencias = () => {
		return this.state.recorrencias.map(
			({ pdv: { fantasia }, modelo: { horaFim, horaInicio }, status, diasDaSemana, id }) => {
				let diasOrdenados = ordenarDiasDaSemana(diasDaSemana);
				return (
					<Table.Row verticalAlign="middle">
						<Table.Cell>
							<p style={{ fontWeight: "bold", lineHeight: 0 }}>
								{fantasia} <br />{" "}
								<p style={{ fontWeight: "initial" }}>{diasOrdenados}</p>
							</p>
						</Table.Cell>
						<Table.Cell>
							<p style={{ fontWeight: "bold" }}>
								{horaInicio} - {horaFim}
							</p>
						</Table.Cell>
						<Table.Cell style={{ textAlign: "center" }}>
							<Radio
								toggle
								checked={status === "ativo" ? true : false}
								onChange={() =>
									status === "ativo"
										? this.cancelarRecorrencia(id)
										: this.ativarRecorrencia(id)
								}
							/>
						</Table.Cell>
						<Table.Cell collapsing>
							<Button
								icon
								as={Link}
								to={`/recorrencia/novo/${id}`}
								basic
								color="blue">
								<Icon name="edit" color="blue" />
							</Button>
							<Button
								icon
								onClick={() =>
									this.setState({
										confirmOpen: true,
										confirmFunction: () => this.deletarRecorrencia(id),
									})
								}
								basic
								color="red">
								<Icon name="trash alternate outline" color="red" />
							</Button>
						</Table.Cell>
					</Table.Row>
				);
			}
		);
	};

	render() {
		let { t } = this.props;
		return (
			<>
				<ModalDeConfirmacao
					t={t}
					message="Você realmente deseja deletar está recorrência?"
					isOpen={this.state.confirmOpen}
					titulo={t("deletar.recorrencia")}
					onCancel={() => this.setState({ confirmOpen: false })}
					onConfirm={this.state.confirmFunction}
					cancelButton={t("cancelar")}
				/>
				<div style={{ position: "relative" }}>
					<Button
						style={{ marginTop: "10px", marginBottom: "10px" }}
						className="purple-gradient-87"
						as={Link}
						to="/recorrencia/novo/"
						content={"Nova Recorrência"}
						icon="plus"
						color="violet"
						floated="right"></Button>
				</div>
				{!_.isEmpty(this.state.recorrencias) ? (
					<Table color={"purple"} celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Local/Dias de execução</Table.HeaderCell>
								<Table.HeaderCell>Período</Table.HeaderCell>
								<Table.HeaderCell>Status</Table.HeaderCell>
								<Table.HeaderCell>Ações</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>{this.listarRecorrencias()}</Table.Body>
					</Table>
				) : (
					<Zoom
						in={_.isEmpty(this.state.recorrencias)}
						mountOnEnter
						unmountOnExit
						style={{ transitionDelay: "50ms", marginTop: 80 }}>
						<Message content={t("nenhuma.recorrencia.cadastrada")}></Message>
					</Zoom>
				)}
			</>
		);
	}
}

export default RoteiroConnect(withSnackbar(withTranslation()(ListarRecorrencia)));
