import { AlertProps, ThemeTypings } from "@chakra-ui/react";
import { IconProps } from "@iconify/react";
import { z } from "zod";
import {
	LogVariant,
	Negotiation,
	NegotiationLog,
	NegotiationStatus,
	Proposal,
} from "../../@types/Negotiation";

/**
 * @description Função que avalia se um dado é do tipo `Proposal`.
 * @param value Qualquer valor que será avaliado.
 * @returns Um booleano que infere que o `value`é do tipo `Proposal`.
 */
export function isProposal(value: any): value is Proposal {
	const schema = z.object({
		criadoEm: z.string(),
		editadoEm: z.string(),
		id: z.string(),
		idProponente: z.string(),
		idNegociacao: z.string(),
		idRoteiro: z.string(),
		valor: z.number(),
		descricao: z.string(),
	});

	return schema.safeParse(value).success;
}

/**
 * @description Função que retorna a cor ideal para determinado log de uma negociação.
 * @param log O log que será avaliado para determinar as cores.
 * @returns Um objeto contendo o `colorScheme` e o `color` no formato do `Chakra UI`.
 */
export function getLogColor(log: NegotiationLog): {
	colorScheme: ThemeTypings["colorSchemes"];
	color: ThemeTypings["colors"];
} {
	switch (log.variante) {
		case LogVariant.SUCESSO:
			return {
				colorScheme: "green",
				color: "green.500",
			};
		case LogVariant.ERRO:
			return {
				colorScheme: "red",
				color: "red.500",
			};
		case LogVariant.ATENÇÃO:
			return {
				colorScheme: "orange",
				color: "orange.500",
			};
		case LogVariant.AVISO:
			return {
				colorScheme: "yellow",
				color: "yellow.500",
			};
		default: {
			return {
				colorScheme: "gray",
				color: "gray.500",
			};
		}
	}
}

/**
 * Mapeia o resultado da função `getNegotiationStatusInfo`
 */
interface GetNegotiationStatusInfoResult {
	colorScheme: ThemeTypings["colorSchemes"];
	label: string;
	icon: IconProps["icon"];
	status: AlertProps["status"];
}

/**
 * @description Função que mapeia para um status de negociação, um conjunto de informações úteis para ser usado na aplicação. (cores, textos, propriedades específicas).
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @param status Status que será usado para recuperar mais informação.
 * @returns Um objeto de informações relevantes de um status de negociação.
 */
export function getNegotiationStatusInfo(
	status: NegotiationStatus
): GetNegotiationStatusInfoResult {
	switch (status) {
		case NegotiationStatus.PENDENTE:
			return {
				colorScheme: "yellow",
				label: "Pendente",
				icon: "material-symbols:pending-actions",
				status: "warning",
			};
		case NegotiationStatus.RECUSADO:
			return {
				colorScheme: "orange",
				label: "Recusado",
				icon: "mdi:close",
				status: "error",
			};
		case NegotiationStatus.EXPIRADO:
			return { colorScheme: "red", label: "Expirado", icon: "ooui:error", status: "error" };
		case NegotiationStatus["EM PAUSA"]:
			return { colorScheme: "gray", label: "Em Pausa", icon: "mdi:pause", status: "warning" };
		default: // NegotiationStatus.ACEITO
			return {
				colorScheme: "green",
				label: "Aceito",
				icon: "ph:seal-check-fill",
				status: "success",
			};
	}
}

/**
 * @description Função que retorna a ultima proposta de uma negociação.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @param negotiation Negociação que será feita a extração da última propostas.
 * @returns Null se não houver propostas ou se a negociação não for válida, caso haja proposta, retorna ela.
 */
export function getLastProposal(negotiation?: Negotiation | null) {
	if (!negotiation) return null;

	return [...negotiation.propostas].pop() || null;
}
