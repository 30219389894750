import firebase from "firebase";
import moment from "moment";
import { v4 } from "uuid";
import { NegotiationStatus, Proposal } from "../../../@types/Negotiation";
import { fCurrency } from "../../../Utils/Number";
import { NotificationService } from "../Notification";

/**
 * Serviço para trabalhar com operações de leitura e escrita e negociação no banco de dados.
 */
export class NegotiationService {
    private static instance: NegotiationService;

    public static getInstance(): NegotiationService {
        if (!NegotiationService.instance) {
            NegotiationService.instance = new NegotiationService();
        }
        return NegotiationService.instance;
    }

    /**
     * @description Função que marca a negociação como recusada.
     * @param id Id da negociação que será finalizada.
     * @param roteiroId Id do roteiro que a negociação pertence.
     * @param idRecusador Id da empresa que decidiu recusar.
     * @returns Uma promise void.
     */
    async recuseNegotiation(id: string, roteiroId: string, idRecusador: string) {
        const docRef = firebase.firestore().collection("roteiros").doc(roteiroId).collection("negociacoes").doc(id);

        return docRef.update({ status: NegotiationStatus.RECUSADO, idRecusador });
    }

    /**
     * @description Método para reabrir uma negociação que estava recusada.
     * @param negotiationId Id da negociação que será atualizada.
     * @param roteiroId Id do roteiro em que a negociação está associada.
     * @param proponentId Id de quem está solicitando a reabertura (proponente).
     * @param proponentName Nome do proponente.
     * @param acceptorId Id da empresa que deve aceitar a nova propostas de reabertura.
     * @param proposalValue Valor da nova proposta de reabertura.
     * @param proposalDescription Descrição da propostas.
     * @param amITheOpportunityCreator Booleano que indica se a empresa logada é a criadora do roteiro.
     */
    async reopenNegotiation(
        negotiationId: string,
        roteiroId: string,
        proponentId: string,
        proponentName: string,
        acceptorId: string,
        proposalValue: number,
        proposalDescription: string,
        amITheOpportunityCreator: boolean
    ) {
        //NOTE: Traz a referência a doc no firestore.
        const docRef = firebase
            .firestore()
            .collection("roteiros")
            .doc(roteiroId)
            .collection("negociacoes")
            .doc(negotiationId);

        //NOTE Cria uma nova proposta.
        const newProposal: Proposal = {
            id: v4(),
            descricao: proposalDescription,
            criadoEm: new Date().toISOString(),
            editadoEm: new Date().toISOString(),
            idNegociacao: negotiationId,
            idRoteiro: roteiroId,
            idProponente: proponentId,
            valor: proposalValue,
        };

        //NOTE: Atualiza a negociação reativando ela e adicionando a nova proposta.
        await docRef.update({
            status: NegotiationStatus.PENDENTE,
            propostas: firebase.firestore.FieldValue.arrayUnion(newProposal),
            idRecusador: firebase.firestore.FieldValue.delete(),
        });

        //NOTE: Notifica a empresa oposta que foram retomadas as negociações.
        await NotificationService.getInstance()
            .createOne({
                id: v4(),
                data: moment().format("YYYY-MM-DD"),
                descricao: `A empresa ${proponentName} decidiu voltar a negociar com você e propôs um novo valor. Valor de: ${fCurrency(
                    newProposal.valor
                )}.`,
                idEmpresa: acceptorId,
                link: amITheOpportunityCreator ? `/buscar/${roteiroId}` : `/oportunidade/info/${roteiroId}`,
                idRoteiro: roteiroId,
                titulo: "Você tem uma nova proposta!",
                visto: 0,
            })
            .catch((e) => console.error("Erro ao enviar notificação: ", e));
    }
}
