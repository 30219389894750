import React, { Component } from 'react';
import { Header, Icon } from 'semantic-ui-react';

export default class HeaderPadrao extends Component {
    render() {
        return (
            <Header as='h2' >
                <Icon name={this.props.icone} />
                <Header.Content>
                    {this.props.titulo}
                    <Header.Subheader>{this.props.subtitulo}</Header.Subheader>
                </Header.Content>
            </Header>
        )
    }
}