import { Icon as ChakraIcon, IconProps as ChakraIconProps } from "@chakra-ui/react";
import { Icon, IconProps } from "@iconify/react";
import { forwardRef } from "react";

export type IconifyProps = IconProps & ChakraIconProps;

export const Iconify = forwardRef<SVGSVGElement, IconifyProps>((props, ref) => {
	return <ChakraIcon as={Icon} ref={ref || undefined} {...props} />;
});

Iconify.displayName = "Iconify";
