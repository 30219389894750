import React from "react";
import { Control, FieldError, FieldPath, RegisterOptions, useController } from "react-hook-form";
import { Container, FooterError, FormErrorMessage, FormLabel, FormLimit, TextArea } from "./styles";

//TODO: Implementar mascara nos inputs de formulário

interface TextAreaFormProps<T>
    extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    label?: string;
    isRequired?: boolean;
    limit?: number;

    //*React Hook Form
    reactHookError?: FieldError;
    enableTextError?: boolean;
    control?: Control<T>;
    name: FieldPath<T>;
    rules?: RegisterOptions<T>;
}

export function TextAreaForm<T>({
    isRequired = false,
    limit,
    label,
    reactHookError,
    control,
    name,
    rules,
    enableTextError = true,
    ...rest
}: TextAreaFormProps<T>) {
    const {
        field: { onChange, value, ref },
    } = useController({
        name,
        control,
        rules,
    });

    return (
        <Container>
            {!!label && (
                <FormLabel htmlFor={name} error={!!reactHookError}>
                    {label}
                    {isRequired && <span> * </span>}
                </FormLabel>
            )}
            <TextArea
                {...rest}
                error={!!reactHookError}
                name={name}
                onChange={onChange}
                ref={ref}
                value={value as string}
            />
            <FooterError>
                {!!reactHookError && enableTextError && <FormErrorMessage>{reactHookError.message}</FormErrorMessage>}
                {limit ? <FormLimit error={!!reactHookError}>{(value as string).length + "/" + limit} </FormLimit> : ""}
            </FooterError>
        </Container>
    );
}
