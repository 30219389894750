import { Icon } from "@iconify/react";
import { DialogContent } from "@material-ui/core";
import { MapContainer } from "react-leaflet";
import styled from "styled-components";

export const Content = styled(DialogContent)`
	padding: 0 !important;

	@media (min-width: 30em) {
		height: 50rem;
	}
`;

export const OpenStreetMapContainer = styled(MapContainer)`
	height: 100%;
`;

export const MapIcon = styled(Icon)`
	color: #4f1e63;
	cursor: pointer;
	margin-left: 0.5rem;
	width: 26px;
	height: 16px;

	&:hover {
		opacity: 0.6;
	}

	transition: opacity 0.2s ease-in-out;
`;
