import { Collapse } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Header, Message } from "semantic-ui-react";
import { ITopUpFormData } from "..";
import { Container, InputCharge } from "./styles";

interface IOptionChargeValue {
    id: number;
    value: number;
    label: string;
    manualInsertion?: boolean;
}

const defaultChangeOptions: IOptionChargeValue[] = [
    { id: 1, value: 100, label: "R$ 100,00" },
    { id: 2, value: 150, label: "R$ 150,00" },
    { id: 3, value: 300, label: "R$ 300,00" },
    { id: 4, value: 500, label: "R$ 500,00" },
    { id: 5, value: 1000, label: "Outro Valor", manualInsertion: true },
];

export function SelectInvoiceValue() {
    const { t } = useTranslation();
    const { setValue, watch } = useFormContext<ITopUpFormData>();
    const [showInputManualInsertion, setShowInputManualInsertion] = useState(false);
    const [selectedChargeOption, setSelectedChargeOption] = useState<IOptionChargeValue | null>(null);

    const selectedValue = watch("value");

    function handleSelectOption(data: IOptionChargeValue) {
        setSelectedChargeOption(data);
        setValue("value", data.value, { shouldDirty: true });

        if (data.manualInsertion) {
            setShowInputManualInsertion(true);
        } else {
            setShowInputManualInsertion(false);
        }
    }

    function handleOnChangeValue(_: ChangeEvent<HTMLInputElement>, { value }: { value: number }) {
        setValue("value", value, { shouldDirty: true });
    }

    return (
        <Container>
            <Header as="h2">1 - {t("escolha.valor.carregar")}</Header>
            <Grid stackable columns={5}>
                {/*NOTE: ITERA AS OPÇÕES DE CARGAS PADRÃO */}
                {defaultChangeOptions.map((chargeOption, i) => {
                    return (
                        <Grid.Column key={i}>
                            <div
                                className={`valor-carregar ${
                                    selectedChargeOption?.id === chargeOption.id ? "green" : ""
                                }`}
                                onClick={() => handleSelectOption(chargeOption)}
                            >
                                <span>{chargeOption.label}</span>
                            </div>
                        </Grid.Column>
                    );
                })}
            </Grid>

            {/*NOTE: EXIBE CAMPO DE VALOR PERSONALIZADO QUANDO SELECIONADO 'Outro Valor' */}
            <Collapse in={showInputManualInsertion}>
                <InputCharge moeda="R$" placeholder={0} value={selectedValue} onChange={handleOnChangeValue} />
            </Collapse>

            {/*NOTE: EXIBIR ERRO CASO O USUÁRIO DIGITE UM VALOR ABAIXO DE R$100,00 */}
            <Collapse in={selectedValue < 100 && showInputManualInsertion}>
                <Message
                    style={{ textAlign: "center", marginTop: 14 }}
                    error
                    content={t("valor.minimo.menor.que.default").replace(/\{valor\}/, Number(100).toFixed(2))}
                />
            </Collapse>
        </Container>
    );
}
