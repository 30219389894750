import { GridColumn, Popup, PopupProps } from "semantic-ui-react";
import styled from "styled-components";

export const Container = styled(GridColumn).attrs({ width: 2 })`
    text-align: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
`;

export const ContentOpportunityValue = styled.span`
    line-height: 1;
    font-size: 0.8em;
    color: #999;
    font-weight: normal;
    white-space: nowrap;

    &:first-child {
        padding-top: 0.5rem;
    }
`;

export const RefundedValue: React.FC<PopupProps> = styled(Popup)``;

export const ContainerNewOpportunity = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
