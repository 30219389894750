import { isString } from "lodash";
import { Negotiation } from "../../@types/Negotiation";
import { SliceStateProps } from "../../@types/store/Store";
import { NegotiationActions, NegotiationActionsType } from "./actions";
import update from "immutability-helper";

interface NegotiationReducerState extends SliceStateProps<Negotiation[]> {}

const initialState: NegotiationReducerState = {
    data: [],
    initialFetchWasMade: false,
    initialStateHasBeenLoaded: false,
    isFetching: false,
};

function getTypedAction<G extends NegotiationActionsType>(genericAction: NegotiationActions) {
    return genericAction as NegotiationActions<G>;
}

function findIndex(objToSearch: Partial<Negotiation> | string, array: Negotiation[]): number {
    if (isString(objToSearch)) return array.findIndex((a) => a.id === objToSearch);

    return array.findIndex((a) => a.id === objToSearch?.id);
}

const NegotiationReducer = (state = initialState, genericAction: NegotiationActions): NegotiationReducerState => {
    switch (genericAction.type) {
        case NegotiationActionsType["NEGOTIATION_SET"]: {
            const action = getTypedAction<NegotiationActionsType.NEGOTIATION_SET>(genericAction);

            return {
                ...state,
                data: action.payload,
            };
        }
        case NegotiationActionsType["NEGOTIATION_SET_ERROR"]: {
            const action = getTypedAction<NegotiationActionsType.NEGOTIATION_SET_ERROR>(genericAction);

            return {
                ...state,
                error: action.payload,
            };
        }
        case NegotiationActionsType["NEGOTIATION_SET_INITIAL_FETCH_WAS_MADE"]: {
            const action = getTypedAction<NegotiationActionsType.NEGOTIATION_SET_INITIAL_FETCH_WAS_MADE>(genericAction);

            return {
                ...state,
                initialFetchWasMade: action.payload,
            };
        }
        case NegotiationActionsType["NEGOTIATION_SET_INITIAL_STATE_HAS_BEEN_LOADED"]: {
            const action =
                getTypedAction<NegotiationActionsType.NEGOTIATION_SET_INITIAL_STATE_HAS_BEEN_LOADED>(genericAction);

            return {
                ...state,
                initialStateHasBeenLoaded: action.payload,
            };
        }
        case NegotiationActionsType["NEGOTIATION_SET_IS_FETCHING"]: {
            const action = getTypedAction<NegotiationActionsType.NEGOTIATION_SET_IS_FETCHING>(genericAction);

            return {
                ...state,
                isFetching: action.payload,
            };
        }
        case NegotiationActionsType["NEGOTIATION_REMOVE"]: {
            const action = getTypedAction<NegotiationActionsType.NEGOTIATION_REMOVE>(genericAction);

            const index = findIndex(action.payload, state.data);
            if (index === -1) return state;

            return update(state, { data: { $splice: [[index, 1]] } });
        }
        case NegotiationActionsType["NEGOTIATION_UPDATE"]: {
            const action = getTypedAction<NegotiationActionsType.NEGOTIATION_UPDATE>(genericAction);

            const index = findIndex(action.payload, state.data);
            if (index === -1) return state;

            return update(state, { data: { [index]: { $set: action.payload } } });
        }
        case NegotiationActionsType["NEGOTIATION_ADD"]: {
            const action = getTypedAction<NegotiationActionsType.NEGOTIATION_ADD>(genericAction);

            return update(state, { data: { $unshift: [action.payload] } });
        }
        default:
            //NOTE: Se nenhuma ação correspondente for encontrada, retorne o estado inalterado
            return state;
    }
};

export default NegotiationReducer;
