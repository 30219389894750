import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Message } from "semantic-ui-react";
import { v4 } from "uuid";
import { HeaderPadrao } from "../../Componentes/Componentes";
import MensagemSalvo from "../../Componentes/MensagemSalvo";
import * as d from "../../Utils/Debugging";
import { codigoJaExiste } from "../../Utils/Loja";
import { downloadLojasDaEmpresa } from "../../Utils/Oportunidades";
import { lojasDaEmpresa } from "../../_actions/RecursoActions";
import { db } from "./../../config";
import { ModelLoja } from "./../../Model/model";
import { NovaLojaForm } from "./NovaLojaForm";
import { isValidCNPJ } from "@brazilian-utils/brazilian-utils";

class NovaLoja extends Component {
    state = {
        loja: new ModelLoja(),
        salvo: false,
        carregando: false,
        searchBox: this.props.match.params.pdv,
        ajuda: false,
    };

    componentWillMount() {
        d.logPath("Loja");
        this.props.lojas === null &&
            downloadLojasDaEmpresa(this.props.idEmpresa).onSnapshot((snap) => {
                d.logSnapshot("Loja", snap);
                this.props.dispatch(lojasDaEmpresa(snap));
            });
    }

    onSubmit = (event) => {
        event.preventDefault();

        const { t } = this.props;
        const idEmpresa = this.props.empresa.uid;
        const cnpj = this.state.loja.codigo.replace(/[^0-9]/g, "");

        if (isValidCNPJ(cnpj)) {
            this.setState({ carregando: true }, () => {
                const loja = {
                    ...this.state.loja,
                    codigo: cnpj,
                    id: v4(),
                    idEmpresa,
                };
                d.log("Nova Loja", "adicionando", loja);
                const lojas = _.isArray(this.props.lojas) ? this.props.lojas : [];
                codigoJaExiste(lojas, loja.codigo)
                    .then(() => db.collection("lojas").doc(loja.id).set(loja))
                    .then(() => {
                        this.setState({
                            loja: new ModelLoja(),
                            carregando: false,
                            salvo: true,
                            erroCodigo: null,
                            searchBox: null,
                        });
                    })
                    .catch((err) =>
                        this.setState({
                            erroCodigo: t(err.message),
                            carregando: false,
                        })
                    );
            });
        } else {
            this.setState({ erroCodigo: "CNPJ inválido." });
        }
    };

    render() {
        const { loja, ajuda, salvo } = this.state;
        const { t } = this.props;
        const passosTutorial = [
            t("clique.pesquisar.mapa"),
            t("busque.pela.loja.desejada"),
            t("clique.no.mapa.loja"),
            t("preencha.campos.obrigatorios.registro"),
        ];

        const location =
            (loja &&
                loja.endereco &&
                loja.endereco.coordenada && {
                    lat: loja.endereco.coordenada.latitude,
                    lng: loja.endereco.coordenada.longitude,
                }) ||
            null;

        if (salvo) {
            return (
                <div className="novo-roteiro" style={{ margin: "0 auto", width: "680px" }}>
                    <MensagemSalvo
                        novoRegistro={() => this.setState({ salvo: false })}
                        mensagem={t("pdv.salvo.com.sucesso")}
                        t={t}
                    />
                </div>
            );
        }

        return (
            <div style={{ margin: "0 auto", width: "80%" }}>
                <Button
                    onClick={() => this.setState({ ajuda: !this.state.ajuda })}
                    style={{ marginTop: "10px" }}
                    content={t("ajuda")}
                    icon={"help"}
                    floated="right"
                    basic
                />
                <HeaderPadrao icone="cart plus" titulo={t("nova.loja")} subtitulo={t("cadastre.nova.loja")} />
                {ajuda && <Message icon="info" list={passosTutorial} />}
                <NovaLojaForm
                    scope={this}
                    t={t}
                    searchBox={this.state.searchBox}
                    location={location}
                    erroCodigo={this.state.erroCodigo}
                    passosTutorial={passosTutorial}
                    carregando={this.state.carregando}
                    loja={loja}
                    empresa={this.props.empresa}
                    onSubmit={(event) => this.onSubmit(event)}
                ></NovaLojaForm>
            </div>
        );
    }
}

const mapStateToProps = d.logState("Nova Loja", (state, props) => {
    const lojas = state.RecursosReducer.lojas;
    return {
        idEmpresa: state.AutenticacaoReducer.empresa.uid,
        empresa: state.AutenticacaoReducer.empresa,
        lojas: lojas,
    };
});

export default connect(mapStateToProps)(withTranslation()(NovaLoja));
