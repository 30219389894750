import { Center, Checkbox, Text, Tfoot, Th, Tr } from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReceiptFormProps } from "..";
import { fCurrency } from "../../../../Utils/Number";
import { ReceiptFileSelector } from "./ReceiptFileSelector";

export function ReceiptCustomerTableFooter() {
	const { t } = useTranslation();
	const { watch, setValue, getValues } = useFormContext<ReceiptFormProps>();

	const opportunities = watch("opportunities");
	const fileField = watch("file");

	/**
	 * Verificar se todos os recibos foram marcados
	 */
	const allChecked = opportunities.every(op => op.isChecked);

	/**
	 * Verificar se há pelo menos uma oportunidade marcadas
	 */
	const isSomeReceiptChecked = opportunities.some(op => op.isChecked);

	/**
	 * Verificar se há pelo menos uma oportunidade marcadas mas garante que não todos.
	 */
	const isIndeterminate = isSomeReceiptChecked && !allChecked;

	/**
	 * Somar o valor das oportunidades marcadas
	 */
	const valueOfSelectedReceipts = opportunities.reduce(
		(acc, op) => acc + (op.isChecked ? op.pagamento.valorPromotor || 0 : 0),
		0
	);

	/**
	 * @description Função que marca/desmarca todos os checkboxes agrupados.
	 * @param e Evento de mudança de um checkbox
	 */
	function handleChangeAllCheckboxValues(e: ChangeEvent<HTMLInputElement>) {
		const currentOpportunities = getValues("opportunities");
		const newReceipts = currentOpportunities.map(op => ({
			...op,
			isChecked: e.target.checked,
		}));
		setValue("opportunities", newReceipts, { shouldDirty: true });
	}

	return (
		<Tfoot bg={"gray.100"}>
			<Tr>
				<Th textTransform="full-size-kana" fontWeight="normal" textAlign="center">
					<Checkbox
						position={"relative"}
						onChange={handleChangeAllCheckboxValues}
						size="lg"
						borderColor={"gray.400"}
						mb={0}
						ml={2}
						isDisabled={!!fileField}
						isChecked={allChecked}
						isIndeterminate={isIndeterminate}>
						<Text
							fontSize={"sm"}
							position="absolute"
							top="50%"
							transform="translateY(-50%)">
							{allChecked ? t("desmarcar-todos") : t("marcar-todos")}
						</Text>
					</Checkbox>
				</Th>
				<Th colSpan={2}>
					<Center>
						<ReceiptFileSelector
							isSomeReceiptChecked={isSomeReceiptChecked}
							receiptsTotalValue={valueOfSelectedReceipts}
						/>
					</Center>
				</Th>
				<Th isNumeric>
					<Text fontSize={"md"}>{fCurrency(valueOfSelectedReceipts)}</Text>
				</Th>
			</Tr>
		</Tfoot>
	);
}
