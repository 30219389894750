import moment from 'moment'

/**
* @name queriePorIdOportunidades
* @date Criado em 11/01/2022
* @author Alexandre Poletto
* @description buscar no mongodb oportunidades por id
* @param {Object} oportunidades ids das oportunidades
*/
export const queriePorIdOportunidades = (oportunidades) => [
    {
        $match: {
            '_id': { $in: oportunidades }
        }
    }
]

/**
* @name queriePorDataEStatus
* @date Criado em 5/1/2021
* @author Diego jimenes
* @description buscar no mongodb oportunidades por idEmpresa, statusRoteiro e data
* @param {Object} dados dados da busca
*/
export const queriePorDataEStatus = (dados) => [
    {
        $match: {
            'idEmpresa': dados.uid,
            'statusRoteiro.id': {
                $in: dados.status
            },
            data: {
                $gte: dados.dataInicio,
                $lte: dados.dataFinal
            }
        }
    }
]

/**
* @name oportunidadesFaturamentoMensal
* @date Criado em 28/09/2023
* @author Renahn Schultz
* @description buscar no mongodb oportunidades por idEmpresa, statusRoteiro, data e faturamento mensal que estão ativas
* @param {Object} dados dados da busca
*/
export const queryOportunidadesFaturamentoMensal = (dados) => [
    {
        $match: {
            'idEmpresa': dados.uid,
            'arquivado': false,
            'pagamento.pagarFaturamentoMensal': true,
            'statusRoteiro.id': {
                $in: dados.status
            },
            data: {
                $gte: dados.dataInicio,
                $lte: dados.dataFinal
            }
        }
    }
]

/**
* @name queriePorData
* @date Criado em 6/01/2021
* @author Vitor Andre Savian
* @description buscar no mongodb oportunidades por idEmpresa e data
* @param {Object} dados dados da busca
*/
export const queriePorData = (dados) => [
    {
        $match: {
            'arquivado': false,
            'idEmpresa': dados.uid,
            data: {
                $gte: dados.dataInicio,
                $lte: dados.dataFinal
            }
        }
    }
]

/**
* @name queriePorDataAgencia
* @date Criado em 03/02/2021
* @author Alexandre Poletto
* @description buscar no mongodb oportunidades por idEmpresa, data e agência
* @param {Object} dados dados da busca
*/
export const queriePorDataAgencia = (dados) => [
    {
        $match: {
            'arquivado': false,
            'usuario.uid': dados.uid,
            'usuario.sexo': 'Agência',
            'statusRoteiro.id': { $in: [3, 5] },
        },
    },
    {
        $sort: { 'data': -1 }
    },
    {
        $limit: 5
    }
]

/**
* @name queriePromotor
* @date Criado em 05/02/2021
* @author Alexandre Poletto
* @description buscar info do promotor pelo uid
* @param {Object} dados dados da busca
*/
export const queriePromotor = (data) => [
    {
        $match: {
            '_id': { $eq: data.uid },
        }
    }
]
