import { Container, Grid, GridItem, GridItemProps, Text, Tooltip } from "@chakra-ui/react";
import { map } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, SemanticICONS } from "semantic-ui-react";
import { Opportunity } from "../../@types/Opportunity";
import { useNegotiationContext } from "../../contexts/Negotiation";
import { getLastProposal } from "../../helpers/Negotiation";
import { useAppSelector } from "../../hooks/useAppSelector";
import { fCurrency } from "../../Utils/Number";

interface OpportunityBasicInformationBarProps {
	opportunity: Opportunity;
}

/**
 * Renderiza uma barra horizontal com informações básicas de uma oportunidade.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function OpportunityBasicInformationBar({
	opportunity,
}: OpportunityBasicInformationBarProps) {
	const { foundNegotiation } = useNegotiationContext();
	const moduleNumber = (useAppSelector(state => state.AutenticacaoReducer).modulo as number) || 1;
	const { t } = useTranslation();

	const icon = opportunity.tipo.icone as SemanticICONS;

	const sectionsLength = useMemo(() => map(opportunity.secoes).length, [opportunity.secoes]);

	const opportunityValueToShow = useMemo(() => {
		//NOTE: Traz a última proposta se houver.
		const lastProposal = getLastProposal(foundNegotiation);

		//NOTE: Se houver, usa o valor da proposta.
		if (lastProposal) return lastProposal.valor;

		//NOTE: Se não houver proposta, usa o valor da oportunidade dependendo do modulo ativo.
		return moduleNumber === 2
			? opportunity.pagamento.valorPromotor
			: opportunity.pagamento.valorTotal;
	}, [
		foundNegotiation,
		moduleNumber,
		opportunity.pagamento.valorPromotor,
		opportunity.pagamento.valorTotal,
	]);

	return (
		<Container
			as={Grid}
			bg="purple.500"
			color="white"
			maxW={"container.xl"}
			rounded="lg"
			minH="20"
			p={4}
			gap={4}
			alignItems="center"
			templateColumns={{ md: "repeat(6, 1fr)", sm: "repeat(3, 1fr)", base: "repeat(2, 1fr)" }}
			textAlign="center"
			shadow="base">
			<OpportunityBasicInformation
				icon={icon === "box" ? "clock" : icon}
				tooltipLabel={t("tipo-da-oportunidade")}>
				{t(opportunity.tipo.descricao)}
			</OpportunityBasicInformation>

			<OpportunityBasicInformation icon="hourglass start" tooltipLabel={t("Tempo.estimado")}>
				{opportunity?.tempoEstimado
					? `${opportunity.tempoEstimado} ${t("minutos")}`
					: t("nao.possui")}
			</OpportunityBasicInformation>

			<OpportunityBasicInformation
				icon="calendar alternate"
				tooltipLabel={t("data-da-oportunidade")}>
				{`${moment(opportunity.data).format("L")} 
				${opportunity.dataFim ? "- " + moment(opportunity.dataFim).format("L") : ""}`}
			</OpportunityBasicInformation>

			<OpportunityBasicInformation icon="time" tooltipLabel={t("horario-da-oportunidade")}>
				{`${opportunity.horaInicio} - ${opportunity.horaFim}`}
			</OpportunityBasicInformation>

			<OpportunityBasicInformation
				icon="clipboard"
				tooltipLabel={t("secoes-da-oportunidade")}>
				{`${t("secoes")}: ${sectionsLength}`}
			</OpportunityBasicInformation>

			<OpportunityBasicInformation icon="dollar" tooltipLabel={t("valor-da-oportunidade")}>
				{fCurrency(opportunityValueToShow)}
			</OpportunityBasicInformation>
		</Container>
	);
}

interface OpportunityBasicInformationProps extends GridItemProps {
	icon: SemanticICONS;
	tooltipLabel?: string;
}

function OpportunityBasicInformation({
	icon,
	children,
	tooltipLabel,
	...restProps
}: OpportunityBasicInformationProps) {
	return (
		<Tooltip label={tooltipLabel} hasArrow>
			<GridItem {...restProps}>
				<Icon name={icon} size="large" />
				<Text fontSize="md">{children}</Text>
			</GridItem>
		</Tooltip>
	);
}
