import { Avatar, Box, Flex, Link, Td, Tr, Text, Tbody, Center } from "@chakra-ui/react";
import { Iconify } from "../../../../Componentes/Iconify";
import { calculateEvaluationAverage, fCurrency } from "../../../../Utils/Number";
import { PATHS } from "../../../../Utils/Routes";
import { Link as RouterLink } from "react-router-dom";
import { type CostPerPromoterReportTableData } from "..";
import { useMemo } from "react";
import { EmptyContentMessage } from "../../../../Componentes/EmptyContentMessage";
import { useTranslation } from "react-i18next";

interface CostPerPromoterReportTableContentProps {
	tableData: CostPerPromoterReportTableData[];
}

export function CostPerPromoterReportTableContent({
	tableData,
}: CostPerPromoterReportTableContentProps) {
	const { t } = useTranslation();

	const tableContent = useMemo(() => {
		if (tableData.length === 0)
			return (
				<Tr>
					<Td colSpan={3}>
						<Center minH={"container.sm"}>
							<EmptyContentMessage
								title={t("relatorio-vazio")}
								message={t("tente-alterar-os-campos-de-data-e-filtro")}
							/>
						</Center>
					</Td>
				</Tr>
			);

		return tableData.map(row => (
			<Tr key={row.promoter.uid}>
				<Td>
					<Flex gap={4} alignItems={"center"}>
						<Avatar
							src={row.promoter.foto}
							name={row.promoter.nome}
							size={"lg"}></Avatar>
						<Box>
							<Text fontWeight={"bold"} color="purple.500">
								<Link
									as={RouterLink}
									to={PATHS.promoters.profile(row.promoter.uid)}>
									{row.promoter.nome}
								</Link>
							</Text>
							<Text fontSize="sm" color="gray.500" lineHeight={"1"}>
								{row.promoter.sexo}
								<Iconify icon="mdi:star" inline mx={1}></Iconify>
								{calculateEvaluationAverage(
									row.promoter.mediaAvaliacao,
									row.promoter.quantidadeAvaliacao
								)}
							</Text>
						</Box>
					</Flex>
				</Td>
				<Td textAlign={"center"} fontWeight={"bold"}>
					{row.finishedOpportunities}
				</Td>
				<Td isNumeric>
					<Box>
						<Text fontWeight={"bold"}>{fCurrency(row.totalCost)}</Text>

						<Text color="gray.500" fontSize={"sm"} lineHeight={"1"}>
							{fCurrency(row.totalPaidForPromoter)}
						</Text>
					</Box>
				</Td>
			</Tr>
		));
	}, [tableData, t]);

	return <Tbody>{tableContent}</Tbody>;
}
