import styled, { css } from "styled-components";
import { CSSProperties } from "react";

interface ContainerProps {
    circular?: boolean;
    styles?: CSSProperties;
    autoSize: boolean;
    borderRadius: number;
}

export const Container = styled.img<ContainerProps>`
    width: 90px;
    height: 90px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    ${({ borderRadius }) =>
        borderRadius > 0 &&
        css`
            border-radius: ${borderRadius}px;
        `}

    ${({ circular }) =>
        circular &&
        css`
            border-radius: 50%;
        `}

    ${({ autoSize }) =>
        autoSize &&
        css`
            width: 100%;
            height: 100%;
        `}

    ${(props) =>
        css`
            ${{ ...props.styles }}
        `}
`;
