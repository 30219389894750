import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import EditLoja from './EditarLoja'
import imgDefault from '../imagens/imagem-padrao-empresa.png'
import ImgCircular from './imgCircular'
import { formatCNPJ } from '../Utils/CNPJ'

export default class DashboardMercado extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        var {t} = this.props
        var { contato: { nome, telefone, email }, razaoSocial, codigo, img, id } = this.props.mercado
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10%'
            }}>
                <div style={{ textAlign: 'center' }}>
                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <ImgCircular src={img ? img : imgDefault} width={200} height={200} />
                        <h2 style={{ fontSize: 20, marginTop: 10 }}>{nome}</h2>
                        <span>{razaoSocial}</span>
                    </div>
                    <p>{telefone}</p>
                    <p>{email}</p>
                    <p>{formatCNPJ(codigo)}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Link to="/lojas">
                        <button
                            style={{
                                border: 'none',
                                background: '#662780',
                                color: "#fff",
                                fontSize: 15,
                                width: 350,
                                height: 50,
                                borderRadius: 60,
                                marginBottom: 25,
                                outline: 'none',
                                cursor: 'pointer'
                            }}
                        >
                            {t('lojas')}
                        </button>
                    </Link>
                    <EditLoja img={img} id={id} t={t} />
                </div>
            </div>
        );
    }
}
