import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Icon, Popup, SemanticICONS } from "semantic-ui-react";
import { Container, HeaderText } from "./styles";

interface Company {
    fantasia?: string;
}

interface Opportunity {
    id: string;
    tipo: {
        icone: SemanticICONS;
        descricao: string;
    };
    data: Date;
    loja: Company;
    fantasiaEmpresa: string;
}

interface OpportunityProps {
    opportunities: Opportunity[];
}

export function Opportunities({ opportunities }: OpportunityProps) {
    const { t } = useTranslation();

    return (
        <Container>
            <HeaderText>{t("ultimas.execucoes")} </HeaderText>

            {opportunities.map((opportunity) => (
                <Grid stackable key={opportunity.id} style={{ borderBottom: "1px solid #eee" }}>
                    <Grid.Column
                        width="11"
                        textAlign="left"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}>
                        <Popup
                            content={t(opportunity.tipo.descricao)}
                            trigger={<Icon size="large" name={opportunity.tipo.icone} />}
                        />
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                marginLeft: 10,
                            }}>
                            <div style={{ color: "#333", fontWeight: "bold", display: "block" }}>
                                {opportunity.loja.fantasia}
                            </div>
                            <div style={{ color: "#999", display: "block" }}>{opportunity.fantasiaEmpresa}</div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width="5" textAlign="right" style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ textAlign: "right" }}>{moment(opportunity.data).format("L")}</div>
                    </Grid.Column>
                </Grid>
            ))}
        </Container>
    );
}
