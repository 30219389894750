/**
 * Isso é um hack para determinar qual atividade,
 * necessário porque os tipo da atividade estão repetidos.
 */
export const atividadesCtor = (
    pergunta, pesquisaDePreco, pesquisaDeValidade,
    foto, fotoDeSaidaDeLoja, fotoFachada, fotoDeReposicao
) => _descricao => {
    const descricao = _descricao.toLowerCase();
    if ("Pergunta".toLowerCase() === descricao)
        return pergunta;
    if ("Pesquisa de Preço".toLowerCase() === descricao)
        return pesquisaDePreco;
    if ("Pesquisa de Validade".toLowerCase() === descricao)
        return pesquisaDeValidade;
    if ("Foto".toLowerCase() === descricao)
        return foto;
    if ("Foto de Saída de Loja".toLowerCase() === descricao ||
        "Check-out".toLowerCase() === descricao)
        return fotoDeSaidaDeLoja;
    if ("Foto de Fachada".toLowerCase() === descricao ||
        "Check-in".toLowerCase() === descricao)
        return fotoFachada;
    if ("Foto com Reposição".toLowerCase() === descricao ||
        "Reposição".toLowerCase() === descricao)
        return fotoDeReposicao;
};


