import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';
import { HeaderPadrao } from '../../Componentes/Componentes';
import { FilterButton } from '../../Componentes/FiltroBuscarOportunidade';
import RoteiroConnect from '../../Interno/Roteiro/RoteiroConnect';
import { roteirosAgencia } from '../../Utils/Roteiros';
import { salvarOportunidadeAgencia } from '../../_actions/RoteiroActions';
import { paginator } from './../../Utils/RoteiroPaginator';
import Filter from './filter';
import BuscarOportunidades from './BuscarOportunidades';
import DirecionarOportunidades from './DirecionarOportunidades';
import OportunidadesExecutas from './OportunidadesExecutas';
import OportunidadesPententes from './OportunidadesPententes';

/**
* @name Oportunidades
* @description devolve o JSX e controla o mesmo
*/
class Oportunidades extends Component {
    state = {
        filtroAberto: false,
        carregando: false,
        tabAtiva: this.props.match.params.aba,
        redirect: true
    }

    /**
    * @name abrirFiltro
    * @description seta o filtro para abrir
    */
    abrirFiltro = () => this.setState({
        filtroAberto: !this.state.filtroAberto
    });

    /**
    * @name limparDadosFiltro
    * @description Limpa todos os listener, changes e dados do paginator e apaga as oportunidades do redux
    * @param {Function} callback da função :D
    */
    limparDadosFiltro = (callback) => {
        paginator().offListeners()
        paginator().clear()
        callback({ data: [] })
    }

    /**
    * @name limparDadosFiltro
    * @description filtrar oprotunidades por data, cidade ou estado
    * @param data da oportunidade
    * @param campo da oportunidade
    * @param estado da oportunidade
    * @param cidade da oportunidade  
    * @param dataFim da oportunidade
    */
    filtrar = ({ data, campo, estado, cidade, dataFim }) => {
        this.setState({
            carregando: true,
            erroLocalizacao: false
        }, () => {
            var callback = ({ data }) => {
                this.props.dispatch(salvarOportunidadeAgencia(data))
                this.setState({ carregando: false })
            }
            this.limparDadosFiltro(callback)
            this.setState({ filtro: { data, campo, estado, cidade, dataFim }, carregando: true }, () =>
                paginator()
                    .get(roteirosAgencia(this.state.filtro, false), callback, 10, 'buscar')
            )
        })

    }


    render() {
        const { filtroAberto } = this.state;
        const { t } = this.props;
        const carregando = this.state.carregando;
        const panes = [
            {
                menuItem: { key: 'buscar', icon: 'search', content: t('buscar') },
                render: () => <BuscarOportunidades />,
            },
            {
                menuItem: { key: 'Direcionar', icon: 'users', content: t('Direcionar') },
                render: () => <DirecionarOportunidades />
            },
            {
                menuItem: { key: 'Pendentes', icon: 'calendar alternate', content: t('Pendentes') },
                render: () => <OportunidadesPententes />
            },
            {
                menuItem: { key: 'Executadas', icon: 'calendar check', content: t('Executadas') },
                render: () => <OportunidadesExecutas />
            }
        ]

        const subtitulos = {
            0: t('lista.oportunidades'),
            1: t('direcionar.oportunidades.descricao'),
            2: t('roteiros.executados.descricao.agencia'),
            3: t('roteiros.pendentes.descricao')
        }

        const { match: { params: { aba } } } = this.props;
        const { tabAtiva, redirect } = this.state
        
        if (redirect) {
            this.setState({ redirect: false })

            return <Redirect to={`/oportunidades/${tabAtiva ?? 0}`} />;
        }

        return (
            <>
                <FilterButton
                    t={t} aberto={filtroAberto}
                    onClick={this.abrirFiltro} />
                <div style={{ 'width': '70%' }}>
                    <HeaderPadrao icone='map signs'
                        titulo={t('oportunidades')}
                        subtitulo={subtitulos[tabAtiva === -1 ? 0 : tabAtiva]} />
                </div>
                {filtroAberto && <Filter filtrar={this.filtrar} carregando={carregando} t={t} />}
                <Tab
                    menu={{ color: "purple", secondary: true, pointing: true, className: "tab-bar-wrapped"  }}
                    panes={panes}
                    activeIndex={aba ?? 0}
                    onTabChange={(event, data) => this.setState({ redirect: true, tabAtiva: data.activeIndex })} />
            </>
        )
    }
}

export default RoteiroConnect(withTranslation()(Oportunidades));
