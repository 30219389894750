import firebase from "firebase";
import _ from "lodash";
import moment from "moment";
import { OpportunityStatus, ReceiptStatus } from "../@types/Opportunity";
import { db } from "./../config";
import { imageFromUrlReader } from "./File";

const DATA_ATUAL = moment().format("YYYY-MM-DD");

export const ROTEIRO_NOVO = 1;
export const ROTEIRO_PENDENTE = 2;
export const ROTEIRO_EXECUTADO = 3;
export const ROTEIRO_COM_PENDENCIA = 4;
export const ROTEIRO_FINALIZADO = 5;
export const ROTEIRO_EM_EXECUCAO = 6;
export const ROTEIRO_EXPIRADO = 7;

export const AGENCIA_AGUARDANDO_ENCAMINHAMENTO = 1;
export const AGENCIA_AGUARDANDO_EXECUCAO = 2;

export const verificarExpirada = (roteiro, atual) =>
	_.includes([1, 2, 4, 6, 7], roteiro.statusRoteiro && roteiro.statusRoteiro.id) &&
	moment(roteiro.dataFim ? roteiro.dataFim : roteiro.data).isBefore(atual);

/**
 *
 * @param {*} t
 * @param {*} roteiro
 * @returns {String} Status do roteiro.
 */
export const roteiroStatusToText = (t, roteiro) => {
	var atual = moment().format("YYYY-MM-DD");
	var msg = {
		4: t("execucao.finalizada.incompleta"),
		2: t("execucao.pendente"),
		1: t("roteiro.expirado"),
		7: t("roteiro.expirado"),
	};
	var id = roteiro.statusRoteiro.id;
	var emExecucao = verificarExpirada(roteiro, atual)
		? t("roteiro.incompleto")
		: t("esta.sendo.executado");
	var text =
		msg[4] && roteiro.acesso?.teveAcesso === false
			? t("sem.acesso.loja")
			: msg[id]
				? msg[id]
				: emExecucao;
	return text;
};

export const roteiroStatusToColor = {
	1: "purple",
	2: "purple",
	3: "green",
	4: "red",
	5: "blue",
	6: "orange",
	7: "purple",
};

export const roteirosBaseQuery = (idEmpresa, filtrarPorArquivado = null) => {
	let baseQuery = db.collection("roteiros").where("idEmpresa", "==", idEmpresa);
	return filtrarPorArquivado == null
		? baseQuery
		: baseQuery.where("arquivado", "==", filtrarPorArquivado);
};

export const roteirosBaseAgencia = (filtrarPorArquivado = null) => {
	let baseQuery = db.collection("roteiros");
	return filtrarPorArquivado == null
		? baseQuery
		: baseQuery.where("arquivado", "==", filtrarPorArquivado);
};

export const roteirosAgencia = (filtro, filtrarPorArquivado = null) => {
	var filtros = {
		estado: () =>
			roteirosBaseAgencia(filtrarPorArquivado).where(
				"loja.endereco.estado",
				"==",
				filtro.estado
			),
		cidade: () =>
			roteirosBaseAgencia(filtrarPorArquivado).where(
				"loja.endereco.cidade",
				"==",
				filtro.cidade
			),
	};
	if (filtro.dataFim) {
		return filtros[filtro.campo]()
			.where("data", ">=", filtro.data)
			.where("data", "<=", filtro.dataFim)
			.where("statusRoteiro.id", "==", ROTEIRO_NOVO)
			.orderBy("data", "asc");
	} else {
		return filtros[filtro.campo]()
			.where("data", ">=", filtro.data)
			.where("statusRoteiro.id", "==", ROTEIRO_NOVO)
			.orderBy("data", "asc");
	}
};

export const oportunidadesPendentes = (uid, status, filtrarPorArquivado = null) =>
	roteirosBaseAgencia(filtrarPorArquivado)
		.where("statusRoteiro.id", "==", ROTEIRO_PENDENTE)
		.where("statusAgencia", "==", status)
		.where("usuario.idUsuario", "==", uid)
		.where("data", ">=", DATA_ATUAL)
		.orderBy("data", "desc");

export const filtroOportunidades = (uid, status, filtrarPorArquivado = null, filtrarPorData) => {
	let query = () =>
		roteirosBaseAgencia(filtrarPorArquivado)
			.where("statusRoteiro.id", "==", status)
			.where("statusAgencia", "==", 2)
			.where("usuario.idUsuario", "==", uid)
			.orderBy("data", "desc");
	var filtros = {
		porData: () => query().where("data", ">=", DATA_ATUAL),
		semData: () => query(),
	};
	return filtros[filtrarPorData ? "porData" : "semData"]();
};
export const roteiroRefunded = roteiro => Boolean(!roteiro.pagamento || roteiro.pagamento.refunded);

export const roteirosAtivos = roteirosBaseQuery;

export const roteirosPorId = (idEmpresa, tipo, filtrarPorArquivado = null) =>
	roteirosBaseQuery(idEmpresa, filtrarPorArquivado).where("statusRoteiro.id", "==", tipo);

export const roteirosPorIdIn = (idEmpresa, tipo, filtrarPorArquivado = null) =>
	roteirosBaseQuery(idEmpresa, filtrarPorArquivado).where("statusRoteiro.id", "in", tipo);

export const roteirosAtivosPorId = roteirosPorId;

export const roteirosIncompletos = (idEmpresa, filtrarPorArquivado = null) =>
	roteirosPorIdIn(
		idEmpresa,
		[ROTEIRO_EM_EXECUCAO, ROTEIRO_COM_PENDENCIA, ROTEIRO_EXPIRADO],
		filtrarPorArquivado
	);

export const roteirosNovos = (idEmpresa, filtrarPorArquivado = null) =>
	roteirosPorId(idEmpresa, ROTEIRO_NOVO, filtrarPorArquivado);

export const roteirosPendentesSemWhere = (idEmpresa, filtrarPorArquivado = null) =>
	roteirosPorId(idEmpresa, ROTEIRO_PENDENTE, filtrarPorArquivado);

export const roteirosPendentes = (idEmpresa, filtrarPorArquivado = null) =>
	roteirosPorId(idEmpresa, ROTEIRO_PENDENTE, filtrarPorArquivado)
		.where("data", ">=", DATA_ATUAL)
		.orderBy("data", "desc");

export const roteirosExecutadosFinalizados = (idEmpresa, filtrarPorArquivado = null) =>
	roteirosPorIdIn(idEmpresa, [ROTEIRO_EXECUTADO, ROTEIRO_FINALIZADO], filtrarPorArquivado);

export const roteirosExecutados = (idEmpresa, filtrarPorArquivado = null) =>
	roteirosPorId(idEmpresa, ROTEIRO_EXECUTADO, filtrarPorArquivado);

export const roteirosPendentesEmExecucaoComPendencia = (idEmpresa, filtrarPorArquivado = null) =>
	roteirosBaseQuery(idEmpresa, filtrarPorArquivado).where("statusRoteiro.id", "in", [
		ROTEIRO_PENDENTE,
		ROTEIRO_EM_EXECUCAO,
	]);

export const roteirosComNotaAgencia = idEmpresa => {
	return db
		.collection("roteiros")
		.where("usuario.uid", "==", idEmpresa) //Oportunidade foi executada pela empresa logada
		.where("statusRoteiro.id", "in", [
			OpportunityStatus.Finalizado,
			OpportunityStatus.Executado,
		]) //Oportunidade está em um status final
		.where("notaFiscal.id", "==", ""); //Oportunidade tem o campo id na nota fiscal e ele deve ser vazio
};

export const criarFiltro = (dataInicioComparador, dataFinalComparador) => (baseQuery, filtros) => {
	const { tipo, dataInicio, dataFinal } = filtros;
	baseQuery = tipo ? baseQuery.where("tipo.id", "==", tipo.id) : baseQuery;
	baseQuery = !!dataInicio
		? baseQuery.where("data", dataInicioComparador, dataInicio)
		: baseQuery;
	baseQuery = !!dataFinal ? baseQuery.where("data", dataFinalComparador, dataFinal) : baseQuery;
	return baseQuery;
};

export const criarFiltroDataEspecifica = () => (baseQuery, filtros) => {
	const { tipo, data } = filtros;
	baseQuery = tipo ? baseQuery.where("tipo.id", "==", tipo.id) : baseQuery;
	baseQuery = !!data ? baseQuery.where("data", ">=", data) : baseQuery;
	return baseQuery;
};

export const arquivarRoteiro = idRoteiro =>
	db.collection("roteiros").doc(idRoteiro).update({ arquivado: true });

export const roteiroJaReembolsado = pagamento =>
	pagamento.hasOwnProperty("refunded") && pagamento.refunded === true;

export const podeReembolsar = roteiro =>
	moment(roteiro.data).isBefore(moment()) &&
	roteiro.pagamento &&
	!roteiroJaReembolsado(roteiro.pagamento) &&
	roteiro.statusRoteiro.id === ROTEIRO_PENDENTE;

export const roteiroQRCode = roteiro =>
	roteiro ? roteiro.id.replace(/-/g, "").toUpperCase() : null;

export const objGroupBy = (acc, k, i) => {
	acc[k] ? acc[k].push(i) : (acc[k] = [i]);
	return acc;
};

export const orderBy = (data, direction) =>
	Object.keys(data)
		.sort(function (a, b) {
			if (direction === "asc") {
				if (a < b) return -1;
				if (b < a) return 1;
			} else {
				if (a > b) return -1;
				if (b > a) return 1;
			}

			return 0;
		})
		.reduce((acc, key) => {
			return { ...acc, [key]: data[key] };
		}, {});

/**
 * Transforma o snapshot do firebase em um objeto
 * onde a chave é a data (YYYY-MM-DD) e o valor
 * a lista de roteiros nesse dia.
 * @param {Firebase#Snapshot} snap o Snapshot.
 * @param {Object} state o estado inicial para o reducer.
 * @return {Object}
 */
export const snapToRoteirosPorData = (roteiros, state) =>
	roteiros.reduce((datas, roteiro) => {
		return objGroupBy(datas, roteiro.data, roteiro);
	}, state);

export const imagensDaAtividadeP = atividade =>
	atividade.foto ? downloadImagensDaAtividade(atividade) : Promise.resolve(atividade);

export const coletaInformacoesDasExecucoes = execucoes =>
	execucoes.reduce(
		(acc, { atividades }) =>
			atividades.reduce(
				({ atividades, quantidadeAtividades, quantidadeFoto }, atividade) => ({
					atividades: objGroupBy(atividades, atividade.descricao, atividade),
					quantidadeAtividades: quantidadeAtividades + 1,
					quantidadeFoto: quantidadeFoto + (atividade.quantidadeFoto || 0),
				}),
				acc
			),
		{ atividades: {}, quantidadeAtividades: 0, quantidadeFoto: 0 }
	);

export const downloadImagensDaAtividade = atividade =>
	db
		.collection("execucaoAtividades")
		.doc(atividade.pai)
		.collection("atividades")
		.doc(atividade.id)
		.collection("fotos")
		.get()
		.then(images =>
			images.docs.reduce(
				(p, image) =>
					p.then(res => {
						const imageData = image.data();
						return imageFromUrlReader(
							`https://cors-anywhere.herokuapp.com/${imageData.foto}`
						)
							.then(results => res.concat([results]))
							.catch(results => res.concat([results]));
					}),
				Promise.resolve([])
			)
		)
		.then(images => ({ ...atividade, images }));

export const validarValorMinimo = (valorStr, valorMinimo) => {
	const valor = parseFloat(valorStr);
	return !_.isNaN(valor) && valor >= valorMinimo;
};

export const storeDocumentoDoRoteiro = (idRoteiro, documento) => {
	if (!documento) {
		return Promise.resolve(null);
	}

	const docRef = firebase.storage().ref().child(`roteiro/${idRoteiro}`);
	return docRef.put(documento).then(() => docRef.getDownloadURL());
};

export const alterarQuantidadeCandidaturas = idRoteiro => {
	return db
		.collection("roteiros")
		.doc(idRoteiro)
		.collection("candidatos")
		.get()
		.then(snap => {
			let quantidade = snap.docs.length;
			db.collection("roteiros").doc(idRoteiro).update({
				totalCandidatos: quantidade,
			});
		});
};

export const candidatarAgencia = (idRoteiro, idEmpresa) => {
	let ref = db
		.collection("roteiros")
		.doc(idRoteiro)
		.update({
			candidatos: firebase.firestore.FieldValue.arrayUnion("E-" + idEmpresa),
		})
		.catch(erro => console.log(erro));
	return ref;
};

export const cancelarCandidatura = (idRoteiro, idEmpresa) => {
	let ref = db
		.collection("roteiros")
		.doc(idRoteiro)
		.update({
			candidatos: firebase.firestore.FieldValue.arrayRemove("E-" + idEmpresa),
		})
		.catch(erro => console.log(erro));
	return ref;
};

export const relancarRoteiro = (idRoteiro, datatime) => {
	var batch = db.batch();
	var roteiro = db.collection("roteiros").doc(idRoteiro);
	batch.update(roteiro, {
		...datatime,
		totalCandidatos: 0,
		statusRoteiro: {
			id: 1,
		},
		usuario: firebase.firestore.FieldValue.delete(),
	});
	return db
		.collection("execucaoAtividades")
		.where("idRoteiro", "==", idRoteiro)
		.get()
		.then(snap => {
			snap.forEach(s => {
				batch.delete(s.ref);
			});
			return batch.commit();
		});
};

// NOTE: Na documentação do Realtime Database
// não existe a opção de deletar uma collection
// de uma vez. Então, é preciso carregar todos os
// documentos e excluir um-a-um.
export const removerCandidatos = idRoteiro =>
	db
		.collection("roteiros")
		.doc(idRoteiro)
		.collection("candidatos")
		.get()
		.then(snaps => {
			const batch = db.batch();
			snaps.forEach(snap => batch.delete(snap.ref));
			return batch.commit();
		});

export const ordenarPorData = (a, b) => {
	const dA = new Date(a.data);
	const dB = new Date(b.data);
	return dA < dB ? 1 : dA === dB ? 0 : -1;
};
