import { includes, isEmpty } from "lodash";
import moment, { duration, Duration } from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Dropdown, DropdownProps, Grid, Header, Message } from "semantic-ui-react";
import { Opportunity } from "../../../../@types/Opportunity";
import { Promoter } from "../../../../@types/Promoter";
import CardPromoter from "../../../../Componentes/CardPromoter";
import { PageHeader } from "../../../../Componentes/PageHeader";
import { Container } from "./styles";
import { TooltipOpportunitiesPerPromoter } from "./TooltipOpportunitiesPerPromoter/index";

//FIXME: Biblioteca de tipos está errada
//@ts-ignore
momentDurationFormatSetup(moment);

interface AgencyPromoter extends Promoter {
	imagemURL: string;
	idUsuario: string;
	fantasia: string;
}

interface Company {
	valorMaximoOportunidades: number;
	faturamentoMensal: MonthlyBilling;
}

interface MonthlyBilling {
	gastos: number;
}

interface WorkTime {
	humanized: string;
	duration: Duration;
}

//NOTE: Tipagem do redux deve ser feita futuramente
const mapStateToProps = (state: {
	RoteiroReducer: {
		oportunidadesPendentes: Opportunity[];
		oportunidadesFinalizadasNaSemana: Opportunity[];
	};
}) => ({
	pendingOpportunities: state.RoteiroReducer.oportunidadesPendentes,
	completedOpportunities: state.RoteiroReducer.oportunidadesFinalizadasNaSemana,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ListCandidatesProps extends PropsFromRedux {
	candidatos: Promoter[] | AgencyPromoter[];
	favoritos: any[];
	roteiro: Opportunity;
	ordena: string;
	ordemOpcoes: { text: string; value: any }[];
	saldos: { naoReservado: number };
	empresa: Company;
	faturamentoMensal: MonthlyBilling;
	handleChangeOrdena: (
		event: React.SyntheticEvent<HTMLElement, Event>,
		data: DropdownProps
	) => void;
	aprovarCandidato: (promoter: Promoter) => Promise<void>;
	setWorkTime: (workTime: WorkTime) => void;
}

function ListCandidates({
	candidatos,
	favoritos,
	empresa,
	faturamentoMensal,
	ordemOpcoes,
	ordena,
	roteiro,
	saldos,
	handleChangeOrdena,
	aprovarCandidato,
	pendingOpportunities,
	completedOpportunities,
	setWorkTime,
}: ListCandidatesProps) {
	const [seeNotes, setSeeNotes] = useState(false);
	const [opportunitiesPerPromoter, setOpportunitiesPerPromoter] = useState<Map<string, any[]>>(
		new Map()
	);
	const [workTimePendingPerPromoter, setWorkTimePendingPerPromoter] = useState<
		Map<string, WorkTime>
	>(new Map());
	const { t } = useTranslation();

	const toggleSeeNotes = useCallback(() => setSeeNotes(value => !value), []);

	const calcLimit = useCallback(
		(roteiro: Opportunity, empresa: Company, faturamentoMensal: MonthlyBilling) => {
			const spending = faturamentoMensal?.gastos;
			const max = empresa?.valorMaximoOportunidades ?? 0;
			let limit = max - spending;
			return roteiro.valor >= limit;
		},
		[]
	);

	const temFaturamentoMensal = useMemo(
		() => empresa?.faturamentoMensal ?? false,
		[empresa?.faturamentoMensal]
	);

	const weekOfYear = useMemo(() => moment(roteiro.data).week(), [roteiro.data]);

	const ultrapassaLimite = useMemo(
		() => calcLimit(roteiro, empresa, faturamentoMensal),
		[roteiro, empresa, faturamentoMensal, calcLimit]
	);

	useEffect(() => {
		const mapPendingOpportunitiesForToday = new Map<string, any[]>();
		const mapPendingWorkTime = new Map<string, WorkTime>();

		candidatos.forEach(candidato => {
			mapPendingOpportunitiesForToday.set(
				candidato.uid,
				pendingOpportunities.filter(
					value => value.data === roteiro.data && value.usuario.uid === candidato.uid
				)
			);

			const filteredPendingOpportunities = pendingOpportunities.filter(value => {
				const weekOfOpportunity = moment(value.data).week();

				return value.usuario.uid === candidato.uid && weekOfOpportunity === weekOfYear;
			});

			const reducer = filteredPendingOpportunities.reduce((oldValue, currentValue) => {
				if (currentValue.tipo.id === 5) {
					const initHour = moment(currentValue.horaInicio, "HH:mm");
					const endHour = moment(currentValue.horaFim, "HH:mm");
					return oldValue + endHour.diff(initHour);
				} else {
					return oldValue + Number(currentValue.tempoEstimado) * 60000;
				}
			}, 0);

			const duration = moment.duration(reducer);
			const daysInHours = duration.days() * 24;
			const hours = duration.hours() + daysInHours;
			const minutes = duration.minutes();

			const humanizeDuration =
				hours > 0 || minutes > 0
					? `${hours} hora(s) e ${minutes} minuto(s) ${t("pendentes.nessa.semana")}`
					: `${t("nenhuma.hora.pendente.nessa.semana")}`;

			mapPendingWorkTime.set(candidato.uid, { humanized: humanizeDuration, duration });
		});
		setOpportunitiesPerPromoter(mapPendingOpportunitiesForToday);
		setWorkTimePendingPerPromoter(mapPendingWorkTime);
	}, [candidatos, pendingOpportunities, roteiro.data, t, weekOfYear]);

	const workedTimesPerPromoter = useMemo(() => {
		const map = new Map<string, WorkTime>();

		candidatos.forEach(candidato => {
			const filteredCompletedOpportunities = completedOpportunities.filter(value => {
				const weekOfOpportunity = moment(value.data).week();

				return value.usuario.uid === candidato.uid && weekOfOpportunity === weekOfYear;
			});

			const reducer = filteredCompletedOpportunities.reduce((oldValue, currentValue) => {
				if (currentValue.iniciadoEm && currentValue.finalizadoEm) {
					const initHour = moment(currentValue.iniciadoEm);
					const endHour = moment(currentValue.finalizadoEm);
					return oldValue + endHour.diff(initHour);
				} else {
					return oldValue + Number(currentValue.tempoEstimado) * 60000;
				}
			}, 0);

			const duration = moment.duration(reducer);
			const hours = duration.hours();
			const minutes = duration.minutes();

			const humanizeDuration =
				hours > 0 || minutes > 0
					? `${hours} hora(s) e ${minutes} minuto(s) ${t("trabalhados.nessa.semana")}`
					: `${t("nenhuma.hora.trabalhada.nessa.semana")}`;

			map.set(candidato.uid, { humanized: humanizeDuration, duration });
		});

		return map;
	}, [candidatos, completedOpportunities, t, weekOfYear]);

	return (
		<Container>
			{saldos.naoReservado < roteiro.valor && (!temFaturamentoMensal || ultrapassaLimite) ? (
				<Grid textAlign="center" style={{ marginTop: 10, marginBottom: 10 }}>
					<Grid.Column width={16}>
						<Header
							as="h2"
							content={
								temFaturamentoMensal && ultrapassaLimite
									? t("voce.nao.tem.limite.suficiente")
									: t("voce.nao.tem.saldo.suficiente")
							}
						/>
						<Button
							as={Link}
							to={"/financeiro/"}
							content={t("carregar.saldo")}
							color="green"
							icon="dollar"
						/>
					</Grid.Column>
					<Grid.Column width={10} textAlign="left">
						<Header
							as="h2"
							icon="group"
							content={t("candidatos")}
							subheader={t("escolha.promotor.para.roteiro")}
						/>
					</Grid.Column>
					<Grid.Column textAlign="right" width={6}>
						<span>
							{t("ordene.os.candidatos.por")}{" "}
							<Dropdown
								name="ordena"
								value={ordena}
								inline
								options={ordemOpcoes}
								defaultValue={ordemOpcoes[0].value}
								onChange={handleChangeOrdena}
							/>
						</span>
					</Grid.Column>
				</Grid>
			) : (
				<Grid stackable>
					<Grid.Column width={10}>
						<PageHeader
							title={t("candidatos")}
							subtitle={t("escolha.promotor.para.roteiro")}
							icon="mdi:account-group"
						/>

						{candidatos.length > 0 && roteiro.treinamentos && (
							<>
								{!seeNotes ? (
									<Button
										icon="graduation"
										content={t("ver.nota.treinamento")}
										color="purple"
										onClick={toggleSeeNotes}></Button>
								) : (
									<Button
										icon="user"
										content={t("visualizar.perfil")}
										color="purple"
										onClick={toggleSeeNotes}></Button>
								)}
							</>
						)}
					</Grid.Column>
					<Grid.Column textAlign="right" width={6}>
						<span>
							{t("ordene.os.candidatos.por")}{" "}
							<Dropdown
								name="ordena"
								value={ordena}
								inline
								options={ordemOpcoes}
								defaultValue={ordemOpcoes[0].value}
								onChange={handleChangeOrdena}
							/>
						</span>
					</Grid.Column>
				</Grid>
			)}
			{candidatos.length === 0 ? (
				<Message content={t("nenhum.promotor.candidatou")}></Message>
			) : (
				<Grid stackable columns={5}>
					{roteiro.usuario && (
						<Grid.Column>
							<CardPromoter
								disabled={!isEmpty(roteiro.usuario)}
								user={roteiro.usuario}
								roteiro={roteiro}></CardPromoter>
						</Grid.Column>
					)}
					{candidatos.map(candidato => {
						const opportunity = opportunitiesPerPromoter.get(candidato.uid);
						const pendingWorkTime = workTimePendingPerPromoter.get(candidato.uid);
						const workTime = workedTimesPerPromoter.get(candidato.uid);
						const workTimeForThisPromoter = {
							duration: duration(0),
							...workTime,
							humanized: workTime
								? workTime.humanized
								: t("nenhuma.hora.trabalhada.nessa.semana"),
						};
						const pendingWorkTimeForThisPromoter = {
							duration: duration(0),
							...pendingWorkTime,
							humanized: pendingWorkTime
								? pendingWorkTime.humanized
								: t("nenhuma.hora.pendente.nessa.semana"),
						};
						const { sexo } = candidato;
						const candidatoTypeVariant = candidato as AgencyPromoter;
						const { mediaAvaliacao, quantidadeAvaliacao, uid, nivelExperiencia } =
							candidato;
						const user = !sexo
							? {
									...candidato,
									sexo: "Agência",
									foto: candidatoTypeVariant.imagemURL,
									idUsuario: candidatoTypeVariant.idUsuario,
									mediaAvaliacao,
									nome: candidatoTypeVariant.fantasia,
									quantidadeAvaliacao,
									uid,
								}
							: {
									...candidato,
									sexo,
									foto: candidato.foto,
									mediaAvaliacao,
									nome: candidato.nome,
									quantidadeAvaliacao,
									uid,
									nivelExperiencia,
								};

						if (roteiro.usuario && user.uid === roteiro.usuario.uid) {
							return null;
						}

						return (
							<TooltipOpportunitiesPerPromoter
								key={candidato.uid}
								opportunities={opportunity}>
								<Grid.Column>
									<CardPromoter
										esconderEscolher={
											saldos.naoReservado < roteiro.valor &&
											(!temFaturamentoMensal || ultrapassaLimite)
										}
										escolher
										disabled={
											roteiro.usuario && !(roteiro.usuario.uid === user.uid)
										}
										user={user}
										roteiro={roteiro}
										workTime={workTimeForThisPromoter}
										pendingWorkTime={pendingWorkTimeForThisPromoter}
										verNotas={seeNotes}
										onClick={() => {
											setWorkTime(workTimeForThisPromoter);
											aprovarCandidato(user as any);
										}}
										favorito={includes(favoritos, user.uid)}
									/>
								</Grid.Column>
							</TooltipOpportunitiesPerPromoter>
						);
					})}
				</Grid>
			)}
		</Container>
	);
}

export default connector(ListCandidates);
