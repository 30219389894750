import { Box, Container, Grid, GridItem } from "@chakra-ui/react";
import { isEmpty, pick } from "lodash";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Header } from "semantic-ui-react";
import { OpportunityInformationHeader } from "../../../Componentes/OpportunityInformationHeader";
import { db } from "../../../config";
import { NegotiationProvider } from "../../../contexts/Negotiation";
import AtividadesRoteiro from "../../../Interno/Roteiro/Aprovacao/AtividadesRoteiro";
import { buscarImagens } from "../../../Interno/Roteiro/VerificarRoteiro/VerificarRoteiro";
import { ROTEIRO_EM_EXECUCAO, ROTEIRO_EXECUTADO } from "../../../Utils/Roteiros";
import { direcionarPromotor } from "../../../_actions/RoteiroActions";
import { BasicOpportunityInformationCard } from "./BasicOpportunityInformationCard";
import {
	renderSecao,
	verificarNotasPendentes,
	verificarRuptura,
} from "../InformacoesOportunidadeMethods";
import { AlertAboutNegotiation } from "./AlertAboutNegotiation";
import { CandidateArea } from "./CandidateArea";

class InformacoesOportunidade extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roteiro: null,
			bloqueioPorNotasPendentes: false,
			valorSolicitadoPromotor: 0,
			secaoAberta: null,
			urlImagens: [],
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(this.props.notasPendentes) !== JSON.stringify(prevProps.notasPendentes)
		) {
			verificarNotasPendentes(this.props.notasPendentes, (object, call) =>
				this.setState(object, call ? call() : () => {})
			);
		}
		if (
			this.state.roteiro != null &&
			this.state.roteiro.atividades === undefined &&
			(this.state.roteiro.statusRoteiro.id === 3 ||
				this.state.roteiro.statusRoteiro.id === 5) &&
			isEmpty(this.state.urlImagens)
		) {
			buscarImagens(this.state.roteiro).then(urlImagens => this.setState({ urlImagens }));
		}

		if (this.state.roteiro !== prevState.roteiro) {
			verificarRuptura(this.state.roteiro, (object, call) =>
				this.setState(object, call ? call() : () => {})
			);
		}
	}

	componentDidMount() {
		this.carregarRoteiro();
	}

	carregarRoteiro = () =>
		this.buscarRoteiro().onSnapshot(snap => {
			this.setState({
				roteiro: snap.data(),
			});
		});

	buscarRoteiro = () => db.collection("roteiros").doc(this.props.match.params.id);

	render() {
		const { roteiro, urlImagens, secaoAberta } = this.state;
		const { t } = this.props;
		return (
			<Container
				maxW="container.lg"
				templateColumns={{ md: "repeat(2, 1fr)", base: "1fr" }}
				as={Grid}
				gap={7}>
				{roteiro && (
					<NegotiationProvider opportunityId={roteiro.id || ""}>
						<GridItem colSpan={{ md: 2, base: 1 }}>
							<OpportunityInformationHeader roteiro={roteiro} {...this.props} />
						</GridItem>
						<AlertAboutNegotiation opportunity={roteiro} />
						<GridItem>
							<BasicOpportunityInformationCard opportunity={roteiro} />
						</GridItem>
						<GridItem>
							<CandidateArea
								opportunity={roteiro}
								bloqueioPorNotasPendentes={this.state.bloqueioPorNotasPendentes}
							/>
						</GridItem>
						{(roteiro.statusRoteiro.id === ROTEIRO_EXECUTADO ||
							roteiro.statusRoteiro.id === ROTEIRO_EM_EXECUCAO) && (
							<GridItem colSpan={{ md: 2, base: 1 }}>
								<Box>
									<Header textAlign="center" as="h2">
										Seções
									</Header>
									{this.state.pedirContato && (
										<div style={{ marginBottom: 10 }}>
											<span
												style={{
													display: "block",
													fontSize: "18px",
													fontWeight: "bold",
													marginBottom: "5px",
												}}>
												{t("informacoes.encarregado")}
											</span>
											<span>{roteiro?.encarregadoDeloja?.telefone}</span>
											<br />
											<span>{roteiro?.encarregadoDeloja?.email}</span>
										</div>
									)}
									{roteiro.secoes &&
										Object.entries(roteiro.secoes).map(([_, secao]) => (
											<div
												style={{
													border: "1px solid #eee",
													borderRadius: 5,
													padding: 10,
													marginBottom: 10,
												}}>
												{renderSecao(
													secao,
													roteiro,
													urlImagens,
													t,
													(object, call) =>
														this.setState(
															object,
															call ? call() : () => {}
														),
													secaoAberta
												)}
											</div>
										))}
								</Box>
							</GridItem>
						)}
						<GridItem colSpan={{ md: 2, base: 1 }}>
							<AtividadesRoteiro {...this.props} roteiro={roteiro} t={t} />
						</GridItem>
					</NegotiationProvider>
				)}
			</Container>
		);
	}
}

const mapStateToProps = state => ({
	...state.AutenticacaoReducer,
	...pick(state.RoteiroReducer, [
		"novasOportunidades",
		"oportunidadesPendentes",
		"oportunidadesExecutadas",
		"oportunidadesExecutadas_Finalizadas",
		"oportunidadesIncompletas",
		"oportunidadesAgencia",
		"opAgPendentes",
		"opAgPendentesAguardandoPromotor",
		"opAgExecutadas",
	]),
});

export default connect(mapStateToProps, {
	direcionarPromotor,
})(withTranslation()(InformacoesOportunidade));
