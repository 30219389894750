import { Icon, IconProps } from "semantic-ui-react";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.2s;

    &:hover {
        cursor: pointer;
        background-color: #dfdfdf;
    }
    &:active {
        background-color: #ffff;
    }
`;

export const MenuItemIcon: React.FC<IconProps> = styled(Icon)`
    margin: 0 !important;
    line-height: 1 !important;
`;

export const MenuItemText = styled.p``;
