import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { IconifyIcon } from "@iconify/react";
import { forwardRef } from "react";
import { Iconify, IconifyProps } from "../Iconify";

interface iconifyIconButtonProps extends Omit<IconButtonProps, "icon"> {
	icon: IconifyIcon | string;
	iconifyProps?: Omit<IconifyProps, "icon" | "ref">;
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Renderiza um `IconButton` do `Chakra UI` que envolve um ícone do `Iconify`.
 */
export const IconifyIconButton = forwardRef<HTMLButtonElement, iconifyIconButtonProps>(
	function IconifyIconButton({ icon, iconifyProps, ...restProps }, ref) {
		return (
			<IconButton
				ref={ref}
				icon={<Iconify {...iconifyProps} icon={icon}></Iconify>}
				{...restProps}></IconButton>
		);
	}
);
