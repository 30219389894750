import ReactDOM from 'react-dom';
import _ from 'lodash';

export const gerarPdf = (title, componente, style, toogleLoad, size, componentesReact) => {
    // CRIA UM OBJETO WINDOW
    var win = null;
    if (size && size !== "") {
        win = window.open("", "", size);
    } else {
        win = window.open("", "", "height=700,width=700");
    }

    win.document.write("<html><head>");
    win.document.write(`<title>${title}</title>`); // <title> CABEÇALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write(`<body style='${size}'>`);
    win.document.write(componente); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");

    win.document.close(); // FECHA A JANELA
    var matches = win.document.querySelectorAll("img");

    (componentesReact ?? []).forEach(({ id, componente }) => {
        return ReactDOM.render(componente, win.document.getElementById(id));
    })

    matches[0].onload = function () {
        toogleLoad(false)
        win.print();
    };
}

/**
* @name gerarPdfs
* @date Criado em 15/12/2020
* @author Alexandre Poletto
* @description Gera uma lista de várias páginas para impressão
* @param {Object} lista lista dos arquivos para gerar
* @param {Object} style estilo da página
* @param {Boolean} toogleLoad loading
* @param {String} size Tamanho da página
*/
export const gerarPdfs = (lista, style, toogleLoad, size) => {
    // CRIA UM OBJETO WINDOW
    var win = null;
    if (size && size !== "") {
        win = window.open("", "", size);
    } else {
        win = window.open("", "", "height=700,width=700");
    }

    _.mapValues(lista, ({ title, componente }) => {
        win.document.write("<html><head>");
        win.document.write(`<title>${title}</title>`); // <title> CABEÇALHO DO PDF.
        win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
        win.document.write("</head>");
        win.document.write(`<body style='${size}'>`);
        win.document.write(componente); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
        win.document.write("</body></html>");
    })

    win.document.close(); // FECHA A JANELA
    var matches = win.document.querySelectorAll("img");

    matches[0].onload = function () {
        toogleLoad(false)
        win.print();
    };
}
