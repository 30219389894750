import firebase from "firebase";
import { toast } from "react-toastify";
import { IRede } from "../../@types/Rede";
import { ThunkAppAction } from "../../@types/store/Store";
import { GenericError } from "../../errors/GenericError";
import { SliceError } from "../../errors/Slice.error";

export enum RedeActionsType {
	"REDE_SET" = "REDE_SET",
	"REDE_SET_IS_FETCHING" = "REDE_SET_IS_FETCHING",
	"REDE_SET_INITIAL_FETCH_WAS_MADE" = "REDE_SET_INITIAL_FETCH_WAS_MADE",
	"REDE_SET_INITIAL_STATE_HAS_BEEN_LOADED" = "REDE_SET_INITIAL_STATE_HAS_BEEN_LOADED",
	"REDE_SET_ERROR" = "REDE_SET_ERROR",
}

export interface RedeActionsPayload {
	REDE_SET: IRede[];
	REDE_SET_IS_FETCHING: boolean;
	REDE_SET_INITIAL_FETCH_WAS_MADE: boolean;
	REDE_SET_INITIAL_STATE_HAS_BEEN_LOADED: boolean;
	REDE_SET_ERROR: SliceError | undefined;
}

export interface RedeActions<T extends RedeActionsType = any> {
	type: T;
	payload: RedeActionsPayload[T];
}

function setFetching(isFetching: boolean) {
	return {
		type: RedeActionsType["REDE_SET_IS_FETCHING"],
		payload: isFetching,
	};
}

function setInitialFetchWasMade(initialFetchWasMade: boolean) {
	return {
		type: RedeActionsType["REDE_SET_INITIAL_FETCH_WAS_MADE"],
		payload: initialFetchWasMade,
	};
}

const setInitialStateHasBeenLoaded = (initialStateHasBeenLoaded: boolean) => {
	return {
		type: RedeActionsType["REDE_SET_INITIAL_STATE_HAS_BEEN_LOADED"],
		payload: initialStateHasBeenLoaded,
	};
};

function setError(error: SliceError) {
	return {
		type: RedeActionsType["REDE_SET_ERROR"],
		payload: error,
	};
}

function setData(data: IRede[]) {
	return {
		type: RedeActionsType["REDE_SET"],
		payload: data,
	};
}

function fetchData(): ThunkAppAction {
	return async (dispatch, getState) => {
		const {
			RedeReducer: { initialFetchWasMade },
		} = getState();

		if (initialFetchWasMade) return;

		dispatch(setInitialFetchWasMade(true));
		dispatch(setFetching(true));

		try {
			const redesCollection = await firebase.firestore().collection("redes").get();

			const redes = redesCollection.docs.map(doc => doc.data() as IRede);

			dispatch(setData(redes));
			dispatch(setInitialStateHasBeenLoaded(true));
		} catch (e) {
			const error = new GenericError(e);

			dispatch(setError(new SliceError("REDES", error.message)));
			toast.error("Ocorreu um erro ao buscar as redes. Tente novamente mais tarde.");
		} finally {
			dispatch(setFetching(false));
		}
	};
}

export const actions = {
	fetchData,
	setFetching,
	setInitialFetchWasMade,
	setData,
	setInitialStateHasBeenLoaded,
	setError,
};
