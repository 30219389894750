import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";
import { initReactI18next  } from "react-i18next";

var options = {
    // order and from where user language should be detected
    order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag']
}

i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next ) // passes i18n down to react-i18next
    .init({
        detection: options,
        whitelist: ['en', 'pt-BR'],
        fallbackLng: "pt-BR", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        // react-i18next options
        react: {
            useSuspense: true
        }
    });

export default i18n;