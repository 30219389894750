import { Circle, Layer, Polygon } from "leaflet";
import { OperationAreaCircle } from "../../@types/OperationArea";
import { Polygon as GeoPolygon } from "geojson";

/**
 * @description Função que converte um layer Leaflet em um geoJSON.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @param layer Layer a ser convertida que vem da lib leaflet;
 * @returns Um geoJSON que corresponde ao layer informado.
 * @obs O layer deve ser do tipo `Circle` ou `Polygon`.
 */
export function layerToGeoJSON(layer: Layer): GeoPolygon | OperationAreaCircle {
	if (layer instanceof Circle) {
		const geoJSON = layer.toGeoJSON();
		return { ...geoJSON.geometry, radius: layer.getRadius() };
	}

	if (layer instanceof Polygon) {
		const geoJSON = layer.toGeoJSON();

		if (geoJSON.geometry.type !== "Polygon") {
			throw new Error("Invalid layer: expected Polygon, not MultiPolygon");
		}

		return geoJSON.geometry;
	}

	throw new Error("Invalid layer: expected Circle or Polygon");
}
