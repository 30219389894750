import { useDisclosure } from "@chakra-ui/react";
import { ReactNode, createContext, useContext } from "react";

interface ModalControllerContextProps extends ReturnType<typeof useDisclosure> {}

interface ModalControllerProviderProps {
    children: ReactNode;
    /**
     * Controlador de estado externo que o contexto vai utilizar.
     * @obs Se for omitido, será usado um estado interno ao contexto.
     */
    controller?: ReturnType<typeof useDisclosure>;
}

const ModalControllerContext = createContext<ModalControllerContextProps>({} as ModalControllerContextProps);

/**
 * @description Provider que fornece acessos a estados de ação de um modal. (`abrir`, `fechar`, `toggle` e etc)
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function ModalControllerProvider({ children, controller }: ModalControllerProviderProps) {
    const internController = useDisclosure();

    const controllerToUse = controller || internController;

    return <ModalControllerContext.Provider value={{ ...controllerToUse }}>{children}</ModalControllerContext.Provider>;
}

/**
 * @description Hook Utilizado para acessar o contexto do ModalController.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function useModalControllerContext() {
    const contextState = useContext(ModalControllerContext);

    if (!contextState) {
        throw new Error(
            "O hook não tem acesso ao contexto. Envolva os componentes necessários em um ModalControllerProvider"
        );
    }

    return contextState;
}
