import React from "react";
import Currency from "react-currency-formatter";
import { useTranslation } from "react-i18next";
import { Table } from "semantic-ui-react";
import { Opportunity } from "..";
import { StatusRoteiro } from "../../../Roteiro/Recorrencia/utils";
import { Container } from "./styles";

interface TableOpportunitiesPerDateProps {
    opportunities: Opportunity[];
}

export function TableOpportunitiesPerDate({ opportunities }: TableOpportunitiesPerDateProps) {
    const { t } = useTranslation();

    return (
        <Container>
            <Table sortable celled id="tabela-rel-horas">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("loja.cell")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("inicio")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("fim")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("status")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("horas.totais")}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">{t("valor")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {opportunities.map((opportunity) => (
                        <Table.Row key={opportunity._id}>
                            <Table.Cell>{opportunity.loja.fantasia}</Table.Cell>
                            <Table.Cell>{opportunity.iniciadoEm === "Invalid date" ? "-" : opportunity.iniciadoEm}</Table.Cell>
                            <Table.Cell>{opportunity.finalizadoEm === "Invalid date" ? "-" : opportunity.finalizadoEm}</Table.Cell>
                            <Table.Cell>{StatusRoteiro(opportunity?.statusRoteiro?.id).nome ?? t('sem.status')}</Table.Cell>
                            <Table.Cell>{opportunity.duration.format("HH[h] mm[m] ss[s]")}</Table.Cell>
                            <Table.Cell textAlign="right">
                                <Currency
                                    quantity={parseFloat(String(opportunity.valor))}
                                    locale="pt_BR"
                                    decimal=","
                                    group="."
                                    currency="R$ "
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Container>
    );
}
