import {
    RELATORIOS_DE_RUPTURA,
    RELATORIOS_PERIODO,
    RELATORIOS_OPORTUNIDADES
} from "./Types";

export const alterarEstadoDeUmRelatorio = action => ({
    type: RELATORIOS_DE_RUPTURA,
    ...action
});
export const alterarPeriodoRelatorio = payload => ({
    type: RELATORIOS_PERIODO,
    payload
});
export const alterarOportunidadesRelatorio = oportunidades => ({
    type: RELATORIOS_OPORTUNIDADES,
    oportunidades
});
