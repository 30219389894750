import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    width: 55%;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;
