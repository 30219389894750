import { Avatar, Box, BoxProps, Text, Tooltip } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Rating } from "semantic-ui-react";
import { Evaluation } from "../../@types/Evaluation";
import { Opportunity, OpportunityStatus } from "../../@types/Opportunity";
import { MenuCardPromoterEvaluation } from "../../Interno/Roteiro/VerificarRoteiro/MenuCardPromoterEvaluation";
import { PATHS } from "../../Utils/Routes";
import { Card } from "../Card";
import { Iconify } from "../Iconify";
import { ModalEvaluationPromoter } from "../ModalEvaluationPromoter";

interface CardEvaluationPromoterProps {
	opportunity: Opportunity;
	containerProps?: BoxProps;
}

function generateADefaultEvaluation(opportunity: Opportunity): Evaluation {
	return {
		id: "", // Será gerado pelo firebase se não existir
		idOportunidade: opportunity.id,
		empresa: {
			id: opportunity.idEmpresa,
			fantasia: opportunity.fantasiaEmpresa,
		},
		avaliacao: opportunity.statusRoteiro.avaliacao?.avaliacao || 1,
		comentario: opportunity.statusRoteiro.avaliacao?.comentario || "",
		data: opportunity.statusRoteiro.avaliacao?.data || new Date().toISOString(),
		...opportunity.statusRoteiro.avaliacao,
	};
}

export function CardEvaluationPromoter({
	opportunity,
	containerProps,
}: CardEvaluationPromoterProps) {
	const [evaluation, setEvaluation] = useState<Evaluation>(() =>
		generateADefaultEvaluation(opportunity)
	);

	const agency = useMemo(() => {
		if (opportunity.usuario?.sexo === "Agência") {
			return { promoterId: opportunity.promotor || "" };
		}
	}, [opportunity.promotor, opportunity.usuario?.sexo]);

	const doesModalNeedToBeRendered = useMemo(() => {
		const allowedOpportunityStatus: OpportunityStatus[] = [
			OpportunityStatus.Finalizado,
			OpportunityStatus.Executado,
		];

		return allowedOpportunityStatus.includes(opportunity.statusRoteiro.id);
	}, [opportunity.statusRoteiro.id]);

	function generateProfileLink() {
		return PATHS.promoters.profile(opportunity.usuario.uid || "");
	}

	const wasEvaluated = !!evaluation.id;

	return (
		<Card
			position={"relative"}
			textAlign={"center"}
			gap={4}
			display="flex"
			flexDirection="column"
			alignItems={"center"}
			{...containerProps}>
			<MenuCardPromoterEvaluation
				company={{ id: opportunity.idEmpresa }}
				promoter={{ id: opportunity.usuario.uid, name: opportunity.usuario.nome }}
				opportunity={opportunity}
			/>
			<Link to={generateProfileLink()}>
				<Tooltip
					label={
						<Text>
							<Iconify icon="mdi:share" inline /> Ver perfil
						</Text>
					}
					hasArrow
					placement="top">
					<Avatar
						src={opportunity.usuario?.foto}
						size="2xl"
						name={opportunity.usuario?.nome}
						bg="purple.500"
						color="white"
						_hover={{
							transform: "scale(1.05)",
							border: "2px solid",
							borderColor: "purple.500",
						}}
						transition="ease-in-out 0.2s"
					/>
				</Tooltip>
			</Link>
			<Box>
				<Text
					fontWeight="bold"
					lineHeight={1.2}
					noOfLines={1}
					maxW={{ base: "full", md: "xs" }}>
					{opportunity.usuario?.nome}
				</Text>
				<Text lineHeight={1} color="gray.500" fontSize={"sm"}>
					{opportunity.usuario?.sexo}
				</Text>
			</Box>

			{doesModalNeedToBeRendered && (
				<>
					{wasEvaluated && (
						<Tooltip label={evaluation?.comentario} hasArrow>
							<span>
								<Rating
									maxRating={5}
									rating={evaluation.avaliacao}
									disabled
									size="huge"
								/>
							</span>
						</Tooltip>
					)}
					<ModalEvaluationPromoter
						wasEvaluated={wasEvaluated}
						setEvaluation={setEvaluation}
						evaluation={evaluation}
						complementaryData={{
							agency,
							promoterOrAgencyId: opportunity.usuario.uid,
						}}
					/>
				</>
			)}
		</Card>
	);
}
