import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Heading } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Iconify } from "../Iconify";

interface BreadcrumbContent {
	to?: string;
	name: string;
}

interface HeaderProps {
	title: string;
	subtitle?: string;
	icon?: string;
	actions?: ReactNode[] | ReactNode;
	breadcrumb?: BreadcrumbContent[];
}

/**
 * Renderiza um cabeçalho de uma pagina.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function PageHeader({ icon, title, subtitle, actions, breadcrumb }: HeaderProps) {
	return (
		<Flex as="header" gap={3} flexDir="column">
			{!!breadcrumb?.length && (
				<Breadcrumb>
					{breadcrumb.map((item, index) => {
						const isCurrentPage = index === breadcrumb.length - 1;

						return (
							<BreadcrumbItem
								key={item.name}
								isCurrentPage={isCurrentPage}
								textDecoration="none"
								color={isCurrentPage ? "gray.500" : undefined}>
								{item.to ? (
									<BreadcrumbLink
										as={Link}
										to={item.to}
										_hover={{ color: "inherit", textDecoration: "underline" }}>
										{item.name}
									</BreadcrumbLink>
								) : (
									<BreadcrumbLink
										cursor="default"
										_hover={{ textDecoration: "none" }}>
										{item.name}
									</BreadcrumbLink>
								)}
							</BreadcrumbItem>
						);
					})}
				</Breadcrumb>
			)}
			<Flex
				alignItems={{ md: "center", base: "flex-start" }}
				justifyContent={"space-between"}
				gap={4}
				flexDir={{ md: "row", base: "column" }}>
				<Flex alignItems="center" gap={2}>
					{!!icon && <Iconify icon={icon} boxSize={{ md: 14, base: 12 }}></Iconify>}
					<Box>
						<Heading as="h1" fontSize={{ md: "2xl", base: "xl" }}>
							{title}
						</Heading>
						{!!subtitle && (
							<Heading
								as="h2"
								fontSize={{ md: "md", base: "sm" }}
								fontWeight="normal"
								color="gray.500">
								{subtitle}
							</Heading>
						)}
					</Box>
				</Flex>
				{!!actions && <Flex w={{ md: "fit-content", base: "100%" }}>{actions}</Flex>}
			</Flex>
		</Flex>
	);
}
