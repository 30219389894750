import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Opportunity } from "../../../../@types/Opportunity";
import { Card } from "../../../../Componentes/Card";
import { useNegotiationContext } from "../../../../contexts/Negotiation";
import { getLastProposal } from "../../../../helpers/Negotiation";
import { fCurrency } from "../../../../Utils/Number";

interface BasicOpportunityInformationCardProps {
	opportunity: Opportunity;
}

/**
 * Renderiza um componente de informação básica da oportunidade como descrição e valor promotor.
 *
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function BasicOpportunityInformationCard({
	opportunity,
}: BasicOpportunityInformationCardProps) {
	const { t } = useTranslation();

	const { foundNegotiation } = useNegotiationContext();

	const currentNegotiationValue = useMemo(() => {
		return getLastProposal(foundNegotiation)?.valor;
	}, [foundNegotiation]);

	const description = opportunity.descricao || t("sem-descricao").concat(".");

	return (
		<Card h="full" as={VStack}>
			<VStack as="header" textAlign="center">
				<Heading as="h3" fontSize="xl">
					{t("informacao.oportunidade")}
				</Heading>
				<Heading as="h4" fontSize="lg">
					{t("voce.recebera")}
				</Heading>
				<Text fontWeight="bold">
					{fCurrency(currentNegotiationValue || opportunity.pagamento.valorPromotor)}
				</Text>
			</VStack>
			<Box
				bg="gray.50"
				shadow="inner"
				p={4}
				w="full"
				flex={1}
				rounded="md"
				textAlign="justify">
				<Heading as="h4" fontSize="lg">
					{t("descricao.oportunidade")}
				</Heading>
				<Text fontSize="sm">{description}</Text>
			</Box>
		</Card>
	);
}
