import { Flex, FormControl, FormErrorMessage, FormLabel, Switch, SwitchProps } from "@chakra-ui/react";

import { Control, FieldPath, RegisterOptions, useController } from "react-hook-form";

interface SwitchFormProps<T> extends SwitchProps {
    label?: string;

    //NOTE: React Hook Form
    enableTextError?: boolean;
    control?: Control<T>;
    name: FieldPath<T>;
    rules?: RegisterOptions<T>;
}

export function SwitchForm<T>({ label, control, name, rules, enableTextError = true, ...rest }: SwitchFormProps<T>) {
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules,
    });

    return (
        <FormControl isInvalid={!!error}>
            <Flex alignItems="center">
                <Switch {...rest} ref={ref} name={name} isChecked={!!value} onChange={onChange} mb={0} />
                {!!label && (
                    <FormLabel htmlFor={name} mb={0} ml={2}>
                        {label}
                    </FormLabel>
                )}
            </Flex>

            {!!error && enableTextError && <FormErrorMessage>{error.message}</FormErrorMessage>}
        </FormControl>
    );
}
