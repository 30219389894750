import { Heading } from "@chakra-ui/react";
import { Divider, Zoom } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import Currency from "react-currency-formatter";
import { Button, Checkbox, Grid, Label, Message } from "semantic-ui-react";
import { ValorRoteiro } from "../../Componentes/ValorRoteiro";
import { getToken, solicitarSaque } from "../../Utils/Api";
import { formatCNPJ } from "../../Utils/CNPJ";
import { ROTEIRO_EXECUTADO, ROTEIRO_FINALIZADO } from "../../Utils/Roteiros";

const PedidosDeSaque = (props) => {
    const { t } = props;
    return (
        <div style={{ marginTop: 40 }}>
            <Heading as="h3" size="lg" mb={2}>
                {t("pedidos.de.saque")}
            </Heading>
            <Divider style={{ marginBottom: 15 }} />
            {_.isEmpty(props.saques) ? (
                <Message
                    style={{ textAlign: "center", backgroundColor: "#fcfcfc" }}
                    content={t("nenhum.pedido.de.saque")}
                ></Message>
            ) : (
                <div className="lista-saques">
                    {props.saques.map((s, i) => {
                        let banco = {};
                        if (typeof s?.bank_address === "string") {
                            banco = JSON.parse(s.bank_address);
                        } else {
                            banco = s.bank_address;
                        }
                        return (
                            <Zoom
                                key={i}
                                in={!_.isEmpty(props.saques)}
                                mountOnEnter
                                unmountOnExit
                                style={{ transitionDelay: !_.isEmpty(props.saques) ? 50 + i * 40 + "ms" : "0ms" }}
                            >
                                <div className="pedido-saque">
                                    <div className="content" style={{ flexDirection: "row" }}>
                                        <Label
                                            color={
                                                s.status === "accepted"
                                                    ? "green"
                                                    : s.status === "rejected"
                                                    ? "red"
                                                    : "yellow"
                                            }
                                            size="mini"
                                            circular
                                            style={{ marginRight: 10, flex: 0 }}
                                        />
                                        <div className="content">
                                            <b style={{ fontSize: 18 }}>
                                                <Currency
                                                    quantity={balanceToFloat(s.amount)}
                                                    currency="R$ "
                                                    locale="pt_BR"
                                                    decimal=","
                                                    group="."
                                                />
                                            </b>
                                            <span style={{ textTransform: "uppercase", fontSize: 12 }}>
                                                {t(s.status)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <span style={{ textTransform: "uppercase", fontSize: 12 }}>{t("destino")}</span>
                                        <b>{banco?.bank}</b>
                                    </div>
                                    <div>
                                        <span style={{ textTransform: "uppercase", fontSize: 12 }}>{t("agencia")}</span>
                                        <br></br>
                                        <b>{banco?.bank_ag}</b>
                                    </div>
                                    <div>
                                        <span style={{ textTransform: "uppercase", fontSize: 12 }}>{t("conta")}</span>
                                        <br></br>
                                        <b>{banco?.bank_cc}</b>
                                    </div>
                                    <div>
                                        <span style={{ textTransform: "uppercase", fontSize: 12 }}>{t("data")}</span>
                                        <br></br>
                                        <b>{moment(s.created_at).format("L")}</b>
                                    </div>
                                    {/* <div style={{ textAlign: 'right' }}>
                                        <Button basic content={t('ver.detalhes')} icon='chevron down' />
                                    </div> */}
                                </div>
                            </Zoom>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const balanceToFloat = (balance) => {
    if (_.includes(balance, "R$")) {
        return parseFloat(balance?.replace("R$ ", "").replace(".", "").replace(",", "."));
    } else if (_.includes(balance, "BRL")) {
        return parseFloat(balance?.replace(",", "").replace("BRL", ""));
    } else {
        return parseFloat(balance);
    }
};

const sacar = (valor, infoConta, empresa, disponivelSaque, t, buscarSaques) =>
    new Promise((resolve, rejected) => {
        if (valor <= balanceToFloat(infoConta.balance) && valor >= 8 && valor <= disponivelSaque) {
            getToken().then((token) =>
                solicitarSaque(token, { id: empresa.uid, valor })
                    .then((data) => {
                        buscarSaques();
                        resolve(data);
                    })
                    .catch((data) => rejected(data))
            );
        } else {
            rejected({ error: { message: t("falha.ao.sacar") } });
        }
    });

export default (props) => {
    const { t, empresa, infoConta, notasPendentes, buscarSaques } = props;
    const [saque, setSaque] = useState(0);
    const [check, setCheck] = useState(false);
    const [sacando, setSacando] = useState(false);
    const [error, setError] = useState("");

    const somaNotas = _.reduce(
        notasPendentes,
        (soma, roteiro) => {
            if (roteiro.statusRoteiro.id === ROTEIRO_EXECUTADO || roteiro.statusRoteiro.id === ROTEIRO_FINALIZADO) {
                return soma + roteiro.pagamento.valorPromotor;
            } else {
                return soma;
            }
        },
        0
    );

    const disponivelSaque = infoConta && balanceToFloat(infoConta && infoConta.balance) - somaNotas;

    return (
        <>
            <Grid columns="two" style={{ marginTop: 10 }} divided>
                <Grid.Column textAlign="center" width={8}>
                    <div>
                        <b>{t("quanto.quer.sacar")}</b>
                    </div>
                    <ValorRoteiro
                        moeda="R$"
                        placeholder={0}
                        value={parseFloat(saque)}
                        onChange={(e, { name, value }) => setSaque(value)}
                    />
                    <div style={disponivelStyle}>
                        <span>{t("disponivel.para.saque")}</span>{" "}
                        <Currency
                            quantity={disponivelSaque > 0 ? disponivelSaque : 0}
                            currency="R$ "
                            locale="pt_BR"
                            decimal=","
                            group="."
                        />
                    </div>
                    <Checkbox
                        style={checkboxStyle}
                        onChange={() => setCheck(!check)}
                        checked={check}
                        label={t("label.ciencia.saque")}
                    />
                    <p style={{ color: "#F44336" }}>
                        {t("os-saques-levam-ate")} <b>{t("48h-uteis")}</b> {t("para-serem-concretizados")}.
                    </p>
                    <p style={{ color: "#F44336" }}>{saque > 0 && saque < 8 ? t("valor.minimo.saque") : null}</p>
                    <p style={{ color: "#F44336" }}>
                        {saque > disponivelSaque ? t("valor.maior.que.disponivel") : null}
                    </p>
                    <p style={{ color: "#F44336" }}>{error ? error.message : null}</p>
                    <Button
                        style={{ marginTop: 20 }}
                        color="green"
                        content={t("solicitar.saque")}
                        onClick={() => {
                            setSacando(true);
                            sacar(saque, infoConta, empresa, disponivelSaque, t, buscarSaques)
                                .then(() => setSacando(false))
                                .catch((error) => {
                                    setSacando(false);
                                    setError(error.error ? error.error : "Erro Inesperado");
                                });
                        }}
                        disabled={
                            saque < 8 ||
                            saque > balanceToFloat(infoConta.balance) + 30 ||
                            saque > disponivelSaque ||
                            !check ||
                            sacando
                        }
                        loading={sacando}
                        icon="money"
                    />
                </Grid.Column>
                <Grid.Column width={8}>
                    <p style={tituloStyle}>{t("para.onde.vai.dinheiro")}</p>
                    <div className="dados-bancarios-saque">
                        <div>
                            <span>{t("nome")}</span>
                            <span style={fantasiaStyle}>{props.empresa.fantasia}</span>
                        </div>
                        <div>
                            <span>{t("cnpj")}</span>
                            {formatCNPJ(props.empresa.cnpj)}
                        </div>
                    </div>
                    <div className="dados-bancarios-saque">
                        <div>
                            <span>{t("banco")}</span>
                            {props.empresa.banco.objetoBanco.banco}
                        </div>
                        <div>
                            <span>{t("agencia")}</span>
                            {props.empresa.banco.agencia}
                        </div>
                    </div>
                    <div className="dados-bancarios-saque">
                        <div>
                            <span>{t("conta")}</span>
                            {props.empresa.banco.conta}
                        </div>
                        <div>
                            <span>{t("tipo.de.conta")}</span>
                            {t("conta.corrente")}
                        </div>
                    </div>
                    <Divider style={{ marginTop: 10 }} />
                    <div style={notasStyle}>
                        <span style={notasFiscaisStyle}>{t("notas.fiscais.pendentes")}</span>
                        <b>
                            <Currency quantity={somaNotas} currency="R$ " locale="pt_BR" decimal="," group="." />
                        </b>
                    </div>
                    <span style={valoresStyle}>{t("se.possui.valores.bloqueados")}</span>
                </Grid.Column>
            </Grid>
            <PedidosDeSaque {...props} />
        </>
    );
};

const disponivelStyle = {
    marginBottom: 25,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "lightgray",
    borderRadius: 12,
    display: "flex",
    justifyContent: "space-between",
};

const checkboxStyle = {
    marginLeft: 10,
    marginRight: 10,
    textAlign: "left",
};

const tituloStyle = {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
};

const notasStyle = {
    display: "flex",
    justifyContent: "space-between",
    margin: 10,
    marginTop: 20,
};

const valoresStyle = {
    margin: 10,
    color: "gray",
    display: "block",
};

const notasFiscaisStyle = {
    fontSize: 16,
    fontWeight: "bold",
};

const fantasiaStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
};
