import { EARTH_RADIUS } from "../../constants/earthRadius";

export class MongodbHelper {
	private static instance: MongodbHelper;

	public static getInstance(): MongodbHelper {
		if (!MongodbHelper.instance) {
			MongodbHelper.instance = new MongodbHelper();
		}
		return MongodbHelper.instance;
	}

	private constructor() {}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função que monta um pipeline para consulta no MongoDB que simula  a formula de haversine para saber se um ponto no mapa está dentro de uma circunferência.
	 * @param lat Ponto de latitude no mapa.
	 * @param long Ponto de longitude no mapa.
	 * @returns Um array de pipeline para o MongoDB.
	 */
	generateCircumferenceHaversinePipeline(lat: number, long: number) {
		return [
			{
				$match:
					//NOTE: Filtra apenas circunferências
					{
						"geometry.type": "Point",
					},
			},
			{
				$addFields: {
					//NOTE: Convertendo coordenadas de graus para radianos
					pointLatRad: {
						$multiply: [lat, Math.PI / 180],
					},
					pointLonRad: {
						$multiply: [long, Math.PI / 180],
					},
					centerLatRad: {
						$multiply: [
							{
								$arrayElemAt: ["$geometry.coordinates", 1],
							},
							Math.PI / 180,
						],
					},
					centerLonRad: {
						$multiply: [
							{
								$arrayElemAt: ["$geometry.coordinates", 0],
							},
							Math.PI / 180,
						],
					},
				},
			},
			{
				$addFields: {
					//NOTE: Calculando a diferença nas coordenadas
					deltaLat: {
						$subtract: ["$centerLatRad", "$pointLatRad"],
					},
					deltaLon: {
						$subtract: ["$centerLonRad", "$pointLonRad"],
					},
				},
			},
			{
				$addFields: {
					//NOTE: Aplicando a fórmula de Haversine
					a: {
						$add: [
							{
								$multiply: [
									{
										$sin: {
											$divide: ["$deltaLat", 2],
										},
									},
									{
										$sin: {
											$divide: ["$deltaLat", 2],
										},
									},
								],
							},
							{
								$multiply: [
									{
										$cos: "$pointLatRad",
									},
									{
										$cos: "$centerLatRad",
									},
									{
										$sin: {
											$divide: ["$deltaLon", 2],
										},
									},
									{
										$sin: {
											$divide: ["$deltaLon", 2],
										},
									},
								],
							},
						],
					},
				},
			},
			{
				$addFields: {
					distance: {
						$multiply: [
							2,
							EARTH_RADIUS,
							{
								$asin: {
									$sqrt: "$a",
								},
							},
						],
					},
				},
			},
			{
				$match: {
					//NOTE: Filtrando documentos onde a distância é menor ou igual ao raio
					$expr: {
						$lte: ["$distance", "$geometry.radius"],
					},
				},
			},
		];
	}
}
