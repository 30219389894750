import React from 'react';
import { withRouter } from 'react-router';

export default withRouter(props => {
    return (
        <>
            {!props.emailVerificado && (
                <div className="verificacao-email">
                    <p>Confirmação de e-mail pendente. Verifique no e-mail cadastrado o link para confirmação. <a style={{
                            color: '#fff', textDecoration: 'underline'
                        }} onClick={props.onClick}>Clique aqui</a> para pedir o e-mail, caso não tenha recebido.
                    </p>
                </div>
            )}
            {props.children}
        </>
    );
});
