import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import { Button, Dimmer, Divider, Grid, Icon, Loader, Message } from 'semantic-ui-react';

const DescricaoAtividade = ({
    atividade
}) => (
        <>
            <Icon size='big' name={atividade.icone} className='center-v' style={{ float: 'left', marginRight: 14, width: 40 }}></Icon>
            <span style={{ float: 'left', width: 'calc(100% - 40px - 14px)' }}>
                {atividade.descricao} {atividade.produto ? atividade.produto.descricao : ''}< br />
                <span className="roteiro-info-secondary">{atividade.descricaoFoto ? atividade.descricaoFoto : atividade.descricao}</span>
            </span>
        </>
    );

export const AtividadeFotoExpirada = ({
    t, item
}) => (
        <Grid className="roteiro-grid" stackable>
            <Grid.Column width={12}>
                <DescricaoAtividade t={t} atividade={item} />
            </Grid.Column>
        </Grid>
    );

export const HeaderDaAtividadeFoto = ({
    t, item, abrirAtividade, index, mostrarInformacao, executado
}) => {
    const foiExecutado = executado == null ? true : executado;
    console.log(index)
    return (
        <Grid className="roteiro-grid" stackable>
            <Grid.Column width={6}>
                <DescricaoAtividade t={t} atividade={item}
                />
            </Grid.Column>
            {foiExecutado &&
                <>
                    <Grid.Column width={2} className='align-center'>
                        <span>
                            {item.hora}<br />
                            <span className="roteiro-info-secondary">Hora</span>
                        </span>
                    </Grid.Column>
                    <Grid.Column width={2} className='align-center'>
                        {
                            item.foto &&
                            <span>
                                {item.quantidadeFoto}< br />
                                <span className="roteiro-info-secondary">{item.quantidadeFoto > 1 ? t('fotos') : t('foto')}</span>
                            </span>
                        }
                    </Grid.Column>
                    <Grid.Column width={2} className='align-center'>
                        {
                            (item.foto && item.tipo !== 6) &&
                            <span>
                                {item.fotosTiradas}< br />
                                <span className="roteiro-info-secondary">{item.quantidadeFoto > 1 ? t('feitas') : t('feita')}</span>
                            </span>
                        }
                        {
                            (item.foto && item.tipo === 6) &&
                            <span>
                                {item.caixasAbertas}< br />
                                <span className="roteiro-info-secondary">{item.caixasAbertas > 1 ? t('caixas.abertas') : t('caixa.aberta')}</span>
                            </span>
                        }
                    </Grid.Column>
                    <Grid.Column width={4} className='align-center'>
                        {
                            mostrarInformacao && item.foto &&
                            <Button className='center-v' basic color='grey' content={t('+.info')} onClick={() => { abrirAtividade(index) }} icon='photo' labelPosition='right' fluid />
                        }
                    </Grid.Column>
                </>
            }
        </Grid>
    );
}


export class AtividadeFoto extends Component {
    render() {
        const {
            t, item, index, abrirAtividade, aberto,
            carregandoAtividade, fotosExibir, executado
        } = this.props;
        const mostrarInformacao = this.props.mostrarInformacao == null ? true : this.props.mostrarInformacao;
        return (
            <div>
                <HeaderDaAtividadeFoto
                    executado={executado}
                    t={t} item={item} index={index}
                    abrirAtividade={abrirAtividade}
                    mostrarInformacao={mostrarInformacao} />
                {aberto &&
                    <div>
                        <Divider></Divider>

                        {carregandoAtividade &&
                            <Dimmer active inverted>
                                <Loader inverted>{t('recuperando.dados')}</Loader>
                            </Dimmer>
                        }
                        {!carregandoAtividade &&
                            <div>
                                {item.observacao &&
                                    <Message fluid icon='comment alternate outline' header={t('promotor.deixou.observacao')} content={item.observacao} />
                                }
                                <Grid className="roteiro-grid" stackable>
                                    <Grid.Column width={16}>
                                        <ImageGallery items={fotosExibir} showPlayButton={false} additionalClass='atividade-image-gallery' />
                                    </Grid.Column>
                                </Grid>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default withTranslation()(AtividadeFoto);