import { Button, Flex, Input, Table, TableContainer } from "@chakra-ui/react";
import { filter, groupBy, map, orderBy, reduce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Opportunity, OpportunityStatus } from "../../../@types/Opportunity";
import { Iconify } from "../../../Componentes/Iconify";
import { useSpreadsheet } from "../../../hooks/useSpreadhseet";
import { CostPerPromoterReportTableContent } from "./CostPerPromoterReportTableContent";
import { CostPerPromoterReportTableFooter } from "./CostPerPromoterReportTableFooter";
import { CostPerPromoterReportTableHeader } from "./CostPerPromoterReportTableHeader";

interface CostPerPromoterReportProps {
	reportData: {
		docs: Opportunity[];
	};
}

export interface CostPerPromoterReportTableData {
	finishedOpportunities: number;
	totalCost: number;
	totalPaidForPromoter: number;
	promoter: Opportunity["usuario"];
}

function isOpportunityFinished(opportunityStatus: OpportunityStatus) {
	const allowedOpportunityStatus: OpportunityStatus[] = [
		OpportunityStatus.Finalizado,
		OpportunityStatus.Executado,
	];

	return allowedOpportunityStatus.includes(opportunityStatus);
}

export interface OrderState {
	direction: "asc" | "desc";
	columnName: "promoter" | "finishedOpportunities" | "total";
}

export function CostPerPromoterReport({ reportData }: CostPerPromoterReportProps) {
	const { t } = useTranslation();
	const [order, setOrder] = useState<OrderState | null>(null);
	const [inputFilterValue, setInputFilterValue] = useState("");

	const sortMappedReportData = useCallback(
		(data: CostPerPromoterReportTableData[]) => {
			switch (order?.columnName) {
				case "promoter":
					return orderBy(data, "promoter.nome", order?.direction);
				case "finishedOpportunities":
					return orderBy(data, "finishedOpportunities", order?.direction);
				case "total":
					return orderBy(data, "totalCost", order?.direction);
				default:
					return data;
			}
		},
		[order?.columnName, order?.direction]
	);

	const sortedAndMappedReportData = useMemo<CostPerPromoterReportTableData[]>(() => {
		const dataGroupedByPromoter = groupBy(reportData.docs, "usuario.uid");
		const noOrderedData = map(dataGroupedByPromoter, value => {
			const finishedOpportunities = orderBy(
				filter(value, opportunity => isOpportunityFinished(opportunity.statusRoteiro.id)),
				"finalizadoEm"
			);

			return reduce(
				finishedOpportunities,
				(acc, opportunity) => {
					return {
						...acc,
						finishedOpportunities: acc.finishedOpportunities + 1,
						totalCost: acc.totalCost + opportunity.pagamento.valorTotal,
						totalPaidForPromoter:
							acc.totalPaidForPromoter + opportunity.pagamento.valorPromotor,
					};
				},
				{
					finishedOpportunities: 0,
					totalCost: 0,
					totalPaidForPromoter: 0,
					promoter: finishedOpportunities[0].usuario,
				}
			);
		});

		return sortMappedReportData(noOrderedData).filter(data => {
			return new RegExp(inputFilterValue, "i").test(data.promoter.nome);
		});
	}, [reportData.docs, sortMappedReportData, inputFilterValue]);

	const reportDataToExportInXLSX = useMemo(() => {
		return sortedAndMappedReportData.map(data => {
			return {
				"Nome do Promotor": data.promoter.nome,
				"Quantidade de Oportunidades": data.finishedOpportunities,
				"Custo Total": data.totalCost,
				"Total Pago para Promotor": data.totalPaidForPromoter,
			};
		});
	}, [sortedAndMappedReportData]);

	const { downloadXLSX } = useSpreadsheet({
		data: reportDataToExportInXLSX,
		fileName: "relatório-de-custo-por-promotor",
	});

	return (
		<Flex flexDir={"column"} gap={4}>
			<Flex
				gap={4}
				alignItems={"center"}
				justifyContent={"space-between"}
				flexDir={{ base: "column", md: "row" }}>
				<Input
					maxW={{ base: "full", md: "md" }}
					placeholder={t("busque-promotor-pelo-nome")}
					onChange={e => setInputFilterValue(e.target.value)}
				/>
				<Button
					size={"lg"}
					onClick={downloadXLSX}
					w={{ base: "full", md: "auto" }}
					disabled={!sortedAndMappedReportData.length}
					leftIcon={<Iconify icon="mdi:file-excel" boxSize={"6"} />}>
					{t("exportar-xlsx")}
				</Button>
			</Flex>
			<TableContainer border={"1px solid"} rounded="md" borderColor={"gray.300"}>
				<Table variant="striped" id="table-to-xls">
					<CostPerPromoterReportTableHeader order={order} setOrder={setOrder} />
					<CostPerPromoterReportTableContent tableData={sortedAndMappedReportData} />
					<CostPerPromoterReportTableFooter tableData={sortedAndMappedReportData} />
				</Table>
			</TableContainer>
		</Flex>
	);
}
