import { Box, Button, Flex, Input, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import update from "immutability-helper";
import { ChangeEvent, useRef } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { omitUndefinedProps } from "toolkit-extra";
import { ReceiptFormProps } from "../..";
import { Receipt, ReceiptStatus } from "../../../../../@types/Receipt";
import { ConfirmationDialog } from "../../../../../Componentes/ConfirmationDialog";
import { Iconify } from "../../../../../Componentes/Iconify";
import { IconifyIconButton } from "../../../../../Componentes/IconifyIconButton";
import { db, storage } from "../../../../../config";
import { GenericError } from "../../../../../errors/GenericError";
import { useAuth } from "../../../../../hooks/store/useAuth";
import { useBlocker } from "../../../../../hooks/store/useBlocker";

interface ReceiptFileSelectorProps {
	isSomeReceiptChecked?: boolean;
	receiptsTotalValue: number;
}

export function ReceiptFileSelector({
	isSomeReceiptChecked,
	receiptsTotalValue,
}: ReceiptFileSelectorProps) {
	const { t } = useTranslation();
	const { company } = useAuth();
	const queryClient = useQueryClient();

	const dialogController = useDisclosure();

	const inputFileRef = useRef<HTMLInputElement>(null);
	const {
		state: { isSendReceiptFileBlocked },
		utils: { setBlocker },
	} = useBlocker();

	const {
		watch,
		setValue,
		handleSubmit,
		reset,
		formState: { isSubmitting },
	} = useFormContext<ReceiptFormProps>();
	const fieldValue = watch("file");

	function handleOpenFileSelector() {
		inputFileRef.current?.click();
	}

	function clearFileInInput() {
		if (inputFileRef.current?.value) {
			inputFileRef.current.value = "";
		}
	}

	function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
		const file = event.target.files?.[0];
		clearFileInInput();

		if (file) {
			if (file.type !== "application/pdf") {
				toast.info(t("arquivo-na-o-permitido-por-favor-escolha-um-arquivo-pdf"));
			} else {
				setValue("file", file, { shouldDirty: true });
			}
		}
	}

	const onSubmit: SubmitHandler<ReceiptFormProps> = async data => {
		let receiptFileRef: firebase.default.storage.Reference | null = null;
		try {
			setBlocker({ isSendReceiptFileBlocked: true });

			//NOTE: valida a existência do arquivo
			if (!(data.file instanceof File)) {
				throw new GenericError("Nenhum arquivo selecionado.");
			}

			//NOTE: valida se tem pelo menos uma oportunidade foi selecionada
			if (data.opportunities.length === 0) {
				throw new GenericError("Nenhuma oportunidade selecionada.");
			}

			//NOTE: Garantimos que o batch do firestore não vai estourar. (499 atualizações e 1 criação)
			if (data.opportunities.length > 499) {
				throw new GenericError("Selecione no máximo 499 oportunidades.");
			}

			//NOTE: Cria uma doc para a nova nota fiscal
			const receiptDoc = db.collection("notasFiscais").doc();

			//NOTE: Separa as oportunidades que devem ser associadas a nota fiscal
			const checkedOpportunities = data.opportunities.filter(
				opportunity => opportunity.isChecked
			);

			//NOTE: Faz o upload do arquivo
			receiptFileRef = storage.ref(
				`notas/${company?.uid}/${checkedOpportunities[0].idEmpresa}/${receiptDoc.id}`
			);
			await receiptFileRef.put(data.file);
			const fileUrl = await receiptFileRef.getDownloadURL();

			//NOTE: Cria um batch para atualizar as oportunidades e criar uma nova notaFiscal atomicamente
			const atomicBatch = db.batch();

			//NOTE: Cria o objeto de nota fiscal que será salvo no banco
			const receiptData: Receipt = {
				id: receiptDoc.id,
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				status: data.isTradePro
					? ReceiptStatus["Enviado para análise"]
					: ReceiptStatus.Aceita,
				sender: {
					id: company?.uid || "",
					name: company?.fantasia || "",
					isAgency: true,
					cnpj: company?.cnpj || "",
					email: company?.email || "",
				},
				consumer: {
					id: checkedOpportunities[0].idEmpresa,
					name: checkedOpportunities[0].fantasiaEmpresa,
					isTradepro: data.isTradePro,
					cnpj: checkedOpportunities[0].cnpjEmpresa,
					email: checkedOpportunities[0].emailEmpresa,
				},
				file: {
					storageKey: receiptFileRef.fullPath,
					url: fileUrl,
				},
				opportunityIds: checkedOpportunities.map(opportunity => opportunity.id),
				opportunities: checkedOpportunities.map(opportunity => ({
					id: opportunity.id,
					date: opportunity.data,
					finishedAt: opportunity.horaFim || "",
					startedAt: opportunity.horaInicio || "",
					variant: opportunity.tipo.id,
					value: opportunity.pagamento.valorPromotor,
					feePercentage: opportunity.pagamento.percentualTaxa,
					feeValue: opportunity.pagamento.taxa,
					grossValue: opportunity.pagamento.valorTotal,
					promoter: {
						id: opportunity.usuario.uid,
						name: opportunity.usuario.nome,
						phone: "",
					},
					pdv: {
						id: opportunity.loja.id,
						name: opportunity.loja.fantasia,
						address: opportunity.loja.endereco.endereco,
					},
				})),
				value: receiptsTotalValue,
			};

			//NOTE: Se for uma nota que passou direito pela análise (Uma nota que não é para a TradePro).
			//Colocamos a data em que foi analisada como a data atual.
			if (!data.isTradePro) {
				receiptData.analyzedAt = new Date().toISOString();
			}

			//NOTE: Cria a nova nota fiscal no firestore
			atomicBatch.set(receiptDoc, omitUndefinedProps(receiptData));

			//NOTE: Atualiza os roteiros marcados com o id da nota enviada
			checkedOpportunities.forEach(receipt => {
				atomicBatch.update(db.collection("roteiros").doc(receipt.id), {
					"notaFiscal.id": receiptDoc.id,
				});
			});

			//NOTE: Perpetua as mudanças atômicas.
			await atomicBatch.commit();

			//NOTE: Se tudo deu certo, iremos resetar o formulário
			reset();

			//NOTE: Necessário fazer um setValue extra para resetar o estado dos checkboxes no useFieldArray
			setValue(
				"opportunities",
				data.opportunities.filter(op => !op.isChecked),
				{ shouldDirty: true }
			);

			//SECTION: Executa uma mutação no cache local
			//NOTE: Guarda a key da query que deve ser atualizada.
			const queryKey = ["receipts", company?.uid, receiptData.status, 10];

			//NOTE: Pega os dados da query
			const queryData = queryClient.getQueryData<{
				pages: { data: Receipt[]; totalItems: number }[];
			}>(queryKey);

			//NOTE: Se ele existir, acessamos o dado e adicionamos a receipt no começo da primeira página junto da soma de itens.
			if (queryData) {
				const updatedQueryData = update(queryData, {
					pages: {
						0: {
							data: { $unshift: [receiptData] },
							totalItems: totalItem => (totalItem || 0) + 1,
						},
					},
				});
				queryClient.setQueryData(queryKey, updatedQueryData);
			}
		} catch (error) {
			console.error(error);

			//NOTE: Dependendo do tipo do erro exibimos uma mensagem padronizada ou específica.
			if (error instanceof GenericError) {
				toast.warning(error.message);
			} else {
				toast.error(t("erro-ao-enviar-nota-fiscal-tente-novamente-mais-tarde"));
			}

			//NOTE: Se ocorreu algum erro tentamos apagar o arquivo que foi enviado, mas não ligamos se der erro de novo
			if (receiptFileRef) {
				await receiptFileRef
					.delete()
					.catch(err => console.error("Erro ao apagar arquivo: ", err));
			}
		} finally {
			setBlocker({ isSendReceiptFileBlocked: false });
		}
	};

	return (
		<Box>
			<ConfirmationDialog
				{...dialogController}
				title={t("enviar-nota-fiscal")}
				onConfirm={handleSubmit(onSubmit)}
				message={
					<Box>
						<Text color="red.400" lineHeight={1.2} fontWeight="medium">
							<Iconify icon="mdi:warning-decagram" inline />{" "}
							{t("aviso-exoneracao-de-responsabilidade-notas-enviadas-pela-agencia")}
						</Text>
						<Text>{t("voce-confirma-sua-escolha")}</Text>
					</Box>
				}
			/>
			{fieldValue ? (
				<Flex gap={2}>
					<Tooltip label={t("remover-arquivo")} hasArrow>
						<IconifyIconButton
							onClick={() => setValue("file", null)}
							icon="mdi:trash"
							aria-label="Remover arquivo"
							colorScheme={"red"}
							isDisabled={isSubmitting}
							iconifyProps={{ boxSize: 5 }}
						/>
					</Tooltip>
					<Button
						isLoading={isSubmitting}
						isDisabled={isSendReceiptFileBlocked}
						colorScheme={"green"}
						onClick={dialogController.onOpen}
						leftIcon={<Iconify icon="mdi:send" boxSize={6} />}>
						{t("enviar-nota-fiscal")}
					</Button>
				</Flex>
			) : (
				<Tooltip
					bg={isSomeReceiptChecked ? undefined : "red.500"}
					label={
						isSomeReceiptChecked
							? t("abrir-seletor-de-arquivos")
							: t("selecione-pelo-menos-uma-nota-fiscal")
					}
					hasArrow>
					<Box>
						<Button
							onClick={handleOpenFileSelector}
							isDisabled={!isSomeReceiptChecked}
							leftIcon={<Iconify icon="mi:attachment" boxSize={6} />}>
							{t("selecionar-arquivo")}
						</Button>
					</Box>
				</Tooltip>
			)}

			<Input
				type="file"
				hidden
				ref={inputFileRef}
				onChange={handleFileChange}
				accept=".pdf"
			/>
		</Box>
	);
}
