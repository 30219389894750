import { Box, Button, Flex, Input, Text, Tooltip, useDisclosure } from "@chakra-ui/react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import update from "immutability-helper";
import prettyBytes from "pretty-bytes";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Receipt, ReceiptStatus } from "../../../@types/Receipt";
import { useAuth } from "../../../hooks/store/useAuth";
import { useBlocker } from "../../../hooks/store/useBlocker";
import { ReceiptService } from "../../../services/firebase/Receipt";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import { Iconify } from "../../Iconify";
import { IconifyIconButton } from "../../IconifyIconButton";

interface ConfirmResendOfReceiptProps {
	receipt: Receipt;
}

export function ConfirmResendOfReceipt({ receipt }: ConfirmResendOfReceiptProps) {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [file, setFile] = useState<File | null>(null);
	const inputFileRef = useRef<HTMLInputElement>(null);
	const { company } = useAuth();
	const {
		state: { isCancelReceiptBlocked, isSendReceiptFileBlocked },
		utils: { setBlocker },
	} = useBlocker();

	const confirmationController = useDisclosure({ defaultIsOpen: false });

	const { mutate, isLoading } = useMutation({
		mutationFn: async () => {
			if (!file) throw new Error("Selecione um arquivo PDF.");

			return ReceiptService.getInstance().resendOne(
				file,
				receipt.id,
				receipt.file.storageKey
			);
		},
		onMutate: () => {
			setBlocker({ isCancelReceiptBlocked: true, isSendReceiptFileBlocked: true });
		},
		onSettled: () => {
			setBlocker({ isCancelReceiptBlocked: false, isSendReceiptFileBlocked: false });
		},
		onSuccess: updatedData => {
			toast.success(t("nota-fiscal-reenviada-com-sucesso").concat("!"));

			//NOTE: Guarda as keys das queries para uso posterior
			const queryKeyToRemove = ["receipts", company?.uid, ReceiptStatus.Recusada, 10];
			const queryKeyToUpdate = [
				"receipts",
				company?.uid,
				ReceiptStatus["Enviado para análise"],
				10,
			];

			//NOTE: Pega os dados de dentro das queries necessárias
			const queryDataToRemove = queryClient.getQueryData<{
				pages: { data: Receipt[]; totalItems: number | null }[];
			}>(queryKeyToRemove);
			const queryDataToUpdate = queryClient.getQueryData<{
				pages: { data: Receipt[]; totalItems: number | null }[];
			}>(queryKeyToUpdate);

			//NOTE: Se os dados existirem, atualizamos as notas rejeitadas excluindo a nota que foi reenviada
			if (queryDataToRemove) {
				const updatedQueryDataToRemove = update(queryDataToRemove, {
					pages: pages => {
						return pages.map(page => {
							return update(page, {
								totalItems: totalItems => {
									return (totalItems || 1) - 1;
								},
								data: { $set: page.data.filter(item => item.id !== receipt.id) },
							});
						});
					},
				});

				queryClient.setQueryData(queryKeyToRemove, updatedQueryDataToRemove);
			}

			//NOTE: Se os dados existirem, atualizamos as notas em análise para incluir essa nova nota.
			if (queryDataToUpdate) {
				const updatedQueryDataToUpdate = update(queryDataToUpdate, {
					pages: {
						0: {
							totalItems: totalItems => {
								return (totalItems || 0) + 1;
							},
							data: { $unshift: [{ ...receipt, ...updatedData }] },
						},
					},
				});

				queryClient.setQueryData(queryKeyToUpdate, updatedQueryDataToUpdate);
			}
		},
		onError: () => {
			toast.error(t("erro-ao-reenviar-a-nota-fiscal-tente-novamente-mais-tarde"));
		},
	});

	const isDisabled = isCancelReceiptBlocked || isSendReceiptFileBlocked;

	const confirmationContent = useMemo(() => {
		return (
			<Flex direction="column" gap={4}>
				<Input
					ref={inputFileRef}
					hidden
					type="file"
					onChange={e => {
						const currentFile = e.target.files?.[0];

						if (currentFile) {
							setFile(currentFile);
						}

						e.target.value = "";
					}}
					accept="application/pdf"
				/>
				{!!file && (
					<Flex
						alignItems="center"
						gap={2}
						shadow="inner"
						bg="gray.50"
						rounded={"md"}
						justifyContent="space-between"
						p={2}>
						<Flex gap={2} alignItems="center">
							<Iconify icon="mdi:file" boxSize={10} color="purple.500" />
							<Box>
								<Text fontWeight="bold">{file.name}</Text>
								<Text fontWeight={"light"} lineHeight={1}>
									{prettyBytes(file.size)}
								</Text>
							</Box>
						</Flex>
						<Tooltip label={t("remover-arquivo")} hasArrow>
							<IconifyIconButton
								icon="mdi:trash"
								aria-label="Excluir"
								size={"sm"}
								rounded="full"
								onClick={() => setFile(null)}
							/>
						</Tooltip>
					</Flex>
				)}
				{!file && (
					<Button
						leftIcon={<Iconify icon="mi:attachment" />}
						w="full"
						onClick={() => inputFileRef.current?.click()}>
						Anexar
					</Button>
				)}
			</Flex>
		);
	}, [file, t]);

	return (
		<>
			<ConfirmationDialog
				{...confirmationController}
				onConfirm={mutate}
				title={t("reenviar-nota-fiscal")}
				message={confirmationContent}
				confirmButtonProps={{
					isDisabled: !file,
				}}
				onCloseComplete={() => setFile(null)}
			/>

			<Tooltip textAlign="center" label={t("dica-reenvio-nota-fiscal-rejeitada")} hasArrow>
				<Button
					isDisabled={isDisabled}
					isLoading={isLoading}
					onClick={confirmationController.onOpen}
					colorScheme={"blue"}
					w="full"
					leftIcon={<Iconify icon="mi:attachment" />}>
					{t("anexar-novo-arquivo")}
				</Button>
			</Tooltip>
		</>
	);
}
