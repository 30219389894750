import { Link } from "react-router-dom";
import { Segment, SegmentProps } from "semantic-ui-react";
import styled from "styled-components";

export const Container = styled(Link)`
    color: #333;
    font-weight: bold;
    font-size: 1rem /* 16px -> 1rem */;

    &:hover {
        color: #333;
    }
`;

export const SegmentContainer: React.FC<SegmentProps> = styled(Segment)`
    margin-bottom: 1rem !important;
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 1020px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

export const LeftContent = styled.div`
    display: flex;
    gap: 1rem;

    @media (max-width: 1020px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;

export const RightContent = styled.div`
    display: flex;
    gap: 5rem;

    @media (max-width: 1020px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

export const Spacer = styled.div`
    flex: 1;
`;
