import styled from "styled-components";

export const Container = styled.div`
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;
    padding: 1rem;
`;

export const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
    }

    h2 {
        font-size: 1rem;
    }
`;
