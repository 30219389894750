import { Divider, Flex, Skeleton } from "@chakra-ui/react";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NegotiationStatus } from "../../@types/Negotiation";
import { useNegotiations } from "../../hooks/useNegotiations";
import { generateArray } from "toolkit-extra";
import { isDateBeforeToday } from "../../Utils/Date.utils";
import { EmptyMessage } from "../EmptyMessage";
import HeaderPadrao from "../HeaderPadrao";
import { NegotiationCard } from "./NegotiationCard";

type AvailableFilters = "ACCEPT" | "PENDING" | "EXPIRED" | "RECUSED" | "IN PAUSE";

export interface NegotiationListProps {
	header?: { title: string; subTitle: string; icon: string };
	filters?: AvailableFilters[];
}

export function NegotiationsList({ header, filters }: NegotiationListProps) {
	const { t } = useTranslation();
	const {
		state: { negotiations, isFetching },
	} = useNegotiations();

	const sortedAndFilteredNegotiations = useMemo(() => {
		const sortedArray = orderBy(
			negotiations,
			negotiation => {
				const lastProposal = negotiation.propostas[negotiation.propostas.length - 1];
				return lastProposal ? lastProposal.criadoEm : negotiation.criadoEm;
			},
			"desc"
		);

		const filteredArray = sortedArray.filter(negotiation => {
			if (!filters?.length) return true;

			//NOTE: Todas a negociações que estão ACEITAS, porém ainda estão pendentes de execução
			if (filters.includes("ACCEPT")) {
				const isValid =
					negotiation.status === NegotiationStatus.ACEITO &&
					!isDateBeforeToday(negotiation.roteiro.data);
				if (isValid) return true;
			}

			//NOTE: Todas as negociações que ainda estão sendo negociadas e podem estar sendo negociadas
			if (filters.includes("PENDING")) {
				const isValid =
					negotiation.status === NegotiationStatus.PENDENTE &&
					!isDateBeforeToday(negotiation.roteiro.data);
				if (isValid) return true;
			}

			//NOTE: Todas as negociações que foram recusadas, mas que ainda podem ser retomadas.
			if (filters.includes("RECUSED")) {
				const isValid =
					negotiation.status === NegotiationStatus.RECUSADO &&
					!isDateBeforeToday(negotiation.roteiro.data);
				if (isValid) return true;
			}

			//NOTE: Todas as negociações que estão pausadas aguardando transição para outro status
			if (filters.includes("IN PAUSE")) {
				const isValid =
					negotiation.status === NegotiationStatus["EM PAUSA"] &&
					!isDateBeforeToday(negotiation.roteiro.data);
				if (isValid) return true;
			}

			//NOTE: Todas as negociações que não podem mais ser negociadas.
			if (filters.includes("EXPIRED")) {
				const isValid =
					negotiation.status === NegotiationStatus.EXPIRADO ||
					isDateBeforeToday(negotiation.roteiro.data);
				if (isValid) return true;
			}

			return false;
		});

		return filteredArray;
	}, [filters, negotiations]);

	const content = useMemo(() => {
		if (isFetching)
			return generateArray(10).map(id => (
				<Skeleton key={id} minW={280} h={304} rounded="md" />
			));

		if (!sortedAndFilteredNegotiations.length)
			return (
				<EmptyMessage
					description={t(
						"volte-mais-tarde-por-enquanto-nao-ha-nada-aqui"
					)}></EmptyMessage>
			);

		return sortedAndFilteredNegotiations.map(negotiation => {
			return <NegotiationCard key={negotiation.id} negotiation={negotiation} />;
		});
	}, [isFetching, sortedAndFilteredNegotiations, t]);

	return (
		<Flex flexDir="column" w="full" borderColor="gray.200" py={6} minH={344}>
			{!!header && (
				<>
					<HeaderPadrao
						icone={header.icon}
						titulo={header.title}
						subtitulo={header.subTitle}
					/>
					<Divider mb={6} mt={1} />
				</>
			)}
			<Flex
				gap={4}
				overflow={"auto"}
				p={6}
				flex={1}
				bg="gray.50"
				rounded="lg"
				boxShadow="inner">
				{content}
			</Flex>
		</Flex>
	);
}
