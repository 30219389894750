import {
	Box,
	Collapse,
	Flex,
	Grid,
	Input,
	InputGroup,
	InputRightElement,
	Spinner,
	Text,
	Tooltip,
	useDisclosure,
} from "@chakra-ui/react";
import L from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { RawResult } from "leaflet-geosearch/dist/providers/openStreetMapProvider";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
import { debounce } from "lodash";
import { RefObject, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { OperationAreaFormProps } from "../..";
import { EmptyMessage } from "../../../../../Componentes/EmptyMessage";
import { IconifyIconButton } from "../../../../../Componentes/IconifyIconButton";

interface MapHeaderActionsProps {
	mapRef: RefObject<L.Map>;
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Renderiza um componente de header com ações para o mapa (filtro, centralizador e etc).
 */
export function MapHeaderActions({ mapRef }: MapHeaderActionsProps) {
	const inputRef = useRef<HTMLInputElement>(null);

	const [isLoading, setIsLoading] = useState(false);
	const [isMenuOptionsOpen, setIsMenuOptionsOpen] = useState(false);
	const [options, setOptions] = useState<SearchResult<RawResult>[]>([]);
	const [markerState, setMarkerState] = useState<L.Marker<any> | null>(null);

	const { t } = useTranslation();
	const collapseController = useDisclosure();
	const formController = useFormContext<OperationAreaFormProps>();

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Busca localizações usando a api do openstreetmap e salva os resultados em um estado para seleção posterior.
	 * @param value A string a ser pesquisado
	 * @returns Um array de resultados do GeoSearch buscados.
	 */
	async function searchAddress(value: string) {
		if (!value) {
			setOptions([]);
			return;
		}
		setIsLoading(true);

		const provider = new OpenStreetMapProvider();
		const results = await provider.search({ query: value }).catch(e => {
			console.error(e);
			toast.error(t("erro-ao-buscar-localizacao-tente-novamente-mais-tarde"));
			return [] as SearchResult<RawResult>[];
		});

		setOptions(results);

		setIsLoading(false);
		return results;
	}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Faz o debounce da busca de endereços.
	 */
	const handleSearchAddress = debounce(searchAddress, 700);

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função de manipulação pra evento de click nas opções do menu de localizações. Ao clicar em uma opção, colocamos um marcador no local, e levamos o mapa até essa localização.
	 * @param option A opção selecionada.
	 */
	function handleSelectOption(option: SearchResult<RawResult>) {
		if (inputRef.current) {
			inputRef.current.value = option.label;
		}

		if (mapRef.current && option.bounds) {
			markerState?.remove();

			const markerIcon = L.icon({
				iconUrl: "/imagens/pin-location-icon.svg",
				iconSize: [40, 40],
				iconAnchor: [15, 15],
			});

			const marker = L.marker([option.y, option.x], {
				icon: markerIcon,
				pmIgnore: true,
			}).addTo(mapRef.current);

			setMarkerState(marker);

			mapRef.current.flyTo(marker.getLatLng(), 15, { duration: 1.5 });
		}
	}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função que centraliza o mapa na localização central mais atual que está no formulário.
	 */
	function handleReCenterMap() {
		if (mapRef.current) {
			markerState?.remove();
			const mapCenter = formController.getValues("mapCenter");
			const zoomLevel = formController.getValues("zoomLevel");
			mapRef.current.flyTo(mapCenter, zoomLevel, { duration: 1.5 });
		}
	}

	return (
		<Grid gap={4} as="header">
			<Flex gap={2}>
				<Tooltip label={t("centralizar-mapa")} hasArrow placement="top">
					<IconifyIconButton
						icon="bx:target-lock"
						aria-label={t("centralizar-mapa")}
						onClick={handleReCenterMap}
					/>
				</Tooltip>
				<Tooltip
					label={
						collapseController.isOpen
							? t("fechar-busca-de-enderecos")
							: t("abrir-busca-de-enderecos")
					}
					hasArrow
					placement="top">
					<IconifyIconButton
						icon={collapseController.isOpen ? "mdi:filter-off" : "mdi:filter"}
						aria-label={t("abrir-fechar-busca-de-enderecos")}
						onClick={collapseController.onToggle}
					/>
				</Tooltip>
			</Flex>
			<Collapse in={collapseController.isOpen} style={{ overflow: "visible" }}>
				<Box
					position="relative"
					onBlur={() => {
						setTimeout(() => setIsMenuOptionsOpen(false), 200);
					}}
					onFocus={() => setIsMenuOptionsOpen(true)}>
					<InputGroup>
						<Input
							type="search"
							ref={inputRef}
							placeholder={t("placeholder-input-busca-de-enderecos-leaflet")}
							onChange={e => {
								handleSearchAddress(e.target.value);
							}}
							onKeyPress={e => {
								if (e.key === "Enter") {
									e.preventDefault();
								}
							}}
						/>
						{isLoading && (
							<InputRightElement width="4.5rem">
								<Spinner />
							</InputRightElement>
						)}
					</InputGroup>
					{isMenuOptionsOpen && (
						<Box
							bg="white"
							zIndex={99999}
							border="1px solid"
							borderColor="gray.200"
							rounded="md"
							position="absolute"
							left={0}
							right={0}
							mt={3}
							overflow="auto"
							maxH={"60"}>
							{!options.length && <EmptyMessage my={12} maxW="none" />}
							{options.map(item => (
								<Text
									key={item.raw.place_id}
									onClick={() => handleSelectOption(item)}
									p={4}
									py={3}
									cursor={"pointer"}
									_hover={{ bg: "gray.100" }}
									_active={{ bg: "gray.200" }}
									transition="all 0.2s ease-in-out">
									{item.label}
								</Text>
							))}
						</Box>
					)}
				</Box>
			</Collapse>
		</Grid>
	);
}
