import React, { Component } from "react";
import _ from "lodash";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/firestore";
import Pagination from "@material-ui/lab/Pagination";
import { Input, Select, Table, Checkbox, Modal, Popup, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { buscaProdutos } from "../../_nodeApi/produtos";
import importProd from "./metodos-importacao";
import "./importacao.css";
import ImagemCentralizada from "../../Componentes/ImagemCentralizada";
import Confirmar from "../../Componentes/Confirmar";
import { juntarProdutos, buscarProdutos, toggleProdutos, toggleAllProdutos, checkar } from "./metodos-gerais";
import { Redirect } from "react-router-dom";
import { HeaderPadrao } from "../../Componentes/Componentes";
/**
 * @name empresas
 * @date Criado em 14/12/2020
 * @author Diego jimenes
 * @description Lista de Empresas da triunfante
 */
export const empresas = {
    TCV: "cd2dfc8d-f2d3-4c2d-8855-f3bb86269b70",
    TBL: "01f26a1f-fb2e-4e74-a529-93e2fe39755b",
    TCG: "bde18bcd-6370-4015-90b6-798effcf119d",
    TSJ: "53964497-b5fa-4d9b-b2c2-cc15a0abb14a",
    TCA: "99350f78-f4d8-41da-a7cc-a5e44186989e",
    TPA: "bb7fb7fc-299d-4c17-9eb1-ccfa15fe7028",
    MCD: "f6963c85-10a8-4728-b7d1-17f2372ecc72",
    API: "61d95008-e6ce-40c7-951a-da7921491bbc",
    ABC: "13eb4e4d-0b30-4751-a6fa-5e64546a482e",
    TPH: "aa95a373-3a7e-4005-aee2-9cb13542e912",
    TBA: "31f77aab-8f0b-4736-96fe-5fc219af02cc",
};

/**
 * @name listaNome
 * @date Criado em 12/01/2021
 * @author Vitor Savian
 * @description Lista de Ids com o nome da empresa
 */
export const listaNome = {
    "cd2dfc8d-f2d3-4c2d-8855-f3bb86269b70": "TCV",
    "01f26a1f-fb2e-4e74-a529-93e2fe39755b": "TBL",
    "bde18bcd-6370-4015-90b6-798effcf119d": "TCG",
    "53964497-b5fa-4d9b-b2c2-cc15a0abb14a": "TSJ",
    "99350f78-f4d8-41da-a7cc-a5e44186989e": "TCA",
    "bb7fb7fc-299d-4c17-9eb1-ccfa15fe7028": "TPA",
    "f6963c85-10a8-4728-b7d1-17f2372ecc72": "MCD",
    "61d95008-e6ce-40c7-951a-da7921491bbc": "API",
    "13eb4e4d-0b30-4751-a6fa-5e64546a482e": "ABC",
    "aa95a373-3a7e-4005-aee2-9cb13542e912": "TPH",
    "31f77aab-8f0b-4736-96fe-5fc219af02cc": "TBA",
};

export const ListaDeEmpresas = [
    "cd2dfc8d-f2d3-4c2d-8855-f3bb86269b70",
    "01f26a1f-fb2e-4e74-a529-93e2fe39755b",
    "bde18bcd-6370-4015-90b6-798effcf119d",
    "53964497-b5fa-4d9b-b2c2-cc15a0abb14a",
    "99350f78-f4d8-41da-a7cc-a5e44186989e",
    "bb7fb7fc-299d-4c17-9eb1-ccfa15fe7028",
    "f6963c85-10a8-4728-b7d1-17f2372ecc72",
    "61d95008-e6ce-40c7-951a-da7921491bbc",
    "13eb4e4d-0b30-4751-a6fa-5e64546a482e",
    "aa95a373-3a7e-4005-aee2-9cb13542e912",
    "31f77aab-8f0b-4736-96fe-5fc219af02cc",
];
class EnviarEmails extends Component {
    state = {
        destinos: [],
        retornoProdutos: [],
        retornosGeral: {},
        produtosSelecionados: {
            1: {},
        },
        selectAll: {
            1: false,
        },
        origemInicial: "",
        origem: "cd2dfc8d-f2d3-4c2d-8855-f3bb86269b70",
        paginaAtual: 1,
        totalDePaginas: 1,
        produto: "",
        modalImportar: false,
        semProdutos: false,
    };

    /**
     * @name checkar
     * @date Criado em 14/12/2020
     * @author Diego jimenes
     * @description gerencia a seleção dos destinos
     */
    checkar = (valor) =>
        this.setState({
            destinos: checkar(valor, this.state.destinos),
        });
    /**
     * @name checkbox
     * @date Criado em 14/12/2020
     * @author Diego jimenes
     * @description listagem de destinos
     */
    checkbox = () => {
        return (
            <>
                <label className="label-importacao">Selecione as empresas de destinos:</label>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%" }}>
                    {_.map(empresas, (empresa, key) => {
                        return (
                            <div style={{ marginRight: 15, width: 80 }}>
                                <input
                                    style={{ marginRight: 15 }}
                                    type="checkbox"
                                    id={key}
                                    name={key}
                                    value={empresa}
                                    onChange={(e) => this.checkar(e.target.value)}
                                    checked={this.state.destinos.includes(empresa)}
                                />
                                <label for="scales">{key}</label>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };
    /**
     * @name select
     * @date Criado em 14/12/2020
     * @author Diego jimenes
     * @description input select onde é feito a seleção da origem dos dados
     */
    select = () => {
        let options = _.map(empresas, (empresa, key) => ({ key: empresa, value: empresa, text: key }));
        return (
            <>
                <label for="origem" className="label-importacao">
                    Selecione a empresa de origem:
                </label>
                <Select
                    placeholder="Origem"
                    options={options}
                    value={this.state.origem}
                    onChange={(e, data) => this.setState({ origem: data.value })}
                />
            </>
        );
    };
    /**
     * @name toggleAllProdutos
     * @date Criado em 14/12/2020
     * @author Diego jimenes
     * @description faz a seleção de todos os produtos da pagina atual
     */
    toggleAllProdutos = () => {
        let selectAll = !(this.state.selectAll[this.state.paginaAtual]
            ? this.state.selectAll[this.state.paginaAtual]
            : false);
        this.setState({ selectAll: { ...this.state.selectAll, [this.state.paginaAtual]: selectAll } }, () => {
            let selecionados = toggleAllProdutos(selectAll, this.state.produtosSelecionados, this.state.paginaAtual);
            this.setState({
                produtosSelecionados: selecionados,
            });
        });
    };
    /**
     * @name toggleProdutos
     * @date Criado em 14/12/2020
     * @author Diego jimenes
     * @description faz a seleção deu um produtos específico
     */
    toggleProdutos = (_id) => {
        let novos = toggleProdutos(_id, this.state.paginaAtual, this.state.produtosSelecionados);
        this.setState({
            produtosSelecionados: novos,
        });
    };
    /**
     * @name importarProdutos
     * @date Criado em 14/12/2020
     * @author Diego jimenes
     * @description invoca a importação de produtos
     */
    importarProdutos = () => {
        let produtos = juntarProdutos(this.state.produtosSelecionados, this.state.retornosGeral);
        importProd(
            this.state.destinos,
            produtos,
            firebase,
            this.props.empresa,
            this.state.origem,
            this.props.ipLocation
        );
    };
    /**
     * @name renderProdutos
     * @date Criado em 14/12/2020
     * @author Diego jimenes
     * @description renderiza a lista principal de produtos
     */
    renderProdutos = () => {
        let selectAll = this.state.selectAll[this.state.paginaAtual]
            ? this.state.selectAll[this.state.paginaAtual]
            : false;
        let { t } = this.props;
        if (this.state.semProdutos) {
            return <Message content={t("produto.nao.encontrado")}></Message>;
        }
        return (
            <>
                <Table celled compact definition>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Popup
                                content={t("selecionar.todos")}
                                trigger={
                                    <Table.HeaderCell>
                                        <Checkbox checked={selectAll} onChange={this.toggleAllProdutos} />
                                    </Table.HeaderCell>
                                }
                            />
                            <Table.HeaderCell>{t("foto")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("descricao")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("codigo.barras")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("preco")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.retornoProdutos.map(({ descricao, foto, codigoBarras, preco, _id }) => {
                            let produtoCheck = this.state.produtosSelecionados[this.state.paginaAtual]
                                ? this.state.produtosSelecionados[this.state.paginaAtual]
                                : { _id: false };
                            return (
                                <Table.Row>
                                    <Table.Cell collapsing>
                                        <Checkbox
                                            checked={produtoCheck[_id] ?? false}
                                            onChange={() => this.toggleProdutos(_id)}
                                        />
                                    </Table.Cell>
                                    <Table.Cell style={{ width: 60 }}>
                                        <ImagemCentralizada circular onClick={() => {}} src={foto} />
                                    </Table.Cell>
                                    <Table.Cell>{descricao}</Table.Cell>
                                    <Table.Cell>{codigoBarras}</Table.Cell>
                                    <Table.Cell>
                                        {(preco ? parseFloat(preco) : "").toLocaleString("BRL", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Pagination
                        shape="rounded"
                        count={this.state.totalDePaginas}
                        page={this.state.paginaAtual}
                        onChange={(e, page) => {
                            this.setState(
                                {
                                    paginaAtual: page,
                                },
                                () => {
                                    this.buscarProdutos();
                                }
                            );
                        }}
                    />
                </div>
            </>
        );
    };
    /**
     * @name componentDidMount
     * @date Criado em 14/12/2020
     * @author Diego jimenes
     * @description faz a primeira chamada com os produtos da primeira empresa da lista
     */
    componentDidMount() {
        this.buscarProdutos();
    }
    /**
     * @name buscarProdutos
     * @date Criado em 14/12/2020
     * @author Diego jimenes
     * @description executa a busca dos produtos e faz todas as validações necessárias
     */
    buscarProdutos = () => {
        this.setState({ loadingProdutos: true }, () => {
            buscarProdutos(
                this.state.origem,
                this.state.origemInicial,
                this.state.produto,
                this.state.paginaAtual,
                this.state.produtosSelecionados,
                this.state.selectAll,
                this.state.retornosGeral,
                axios,
                firebase,
                buscaProdutos
            )
                .then((res) => {
                    this.setState(res);
                })
                .catch(() => {
                    this.setState({ semProdutos: true });
                });
        });
    };
    /**
     * @name renderProdutosParaImportar
     * @date Criado em 14/12/2020
     * @author Diego jimenes
     * @description cuida da renderização dos produtos selecionados,
     * que devem ser exibidos em um modal para que o usuario possa fazer uma ultima verificação
     */
    renderProdutosParaImportar = () => {
        let produtos = juntarProdutos(this.state.produtosSelecionados, this.state.retornosGeral);
        let { t } = this.props;
        return (
            <>
                <div style={{ width: "100%", textAlign: "center", marginTop: 10, marginBottom: 25 }}>
                    <span style={{ lineHeight: 1 }}>{t("Verifique.os.produtos.antes.de.realizar.a.importacao")}</span>
                </div>
                <div style={{ overflowY: "scroll", height: 500 }}>
                    <Table celled compact definition>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>{t("foto")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("descricao")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("codigo.barras")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("preco")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {produtos.map(({ descricao, foto, codigoBarras, preco }) => {
                                return (
                                    <Table.Row>
                                        <Table.Cell style={{ width: 60 }}>
                                            <ImagemCentralizada circular onClick={() => {}} src={foto} />
                                        </Table.Cell>
                                        <Table.Cell>{descricao}</Table.Cell>
                                        <Table.Cell>{codigoBarras}</Table.Cell>
                                        <Table.Cell>
                                            {(preco ? parseFloat(preco) : "").toLocaleString("BRL", {
                                                style: "currency",
                                                currency: "BRL",
                                            })}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </div>
            </>
        );
    };

    render() {
        let { t } = this.props;

        if (!ListaDeEmpresas.includes(this.props.empresa.uid)) {
            return <Redirect to={{ pathname: "/404" }} />;
        }

        return (
            <div>
                <Confirmar
                    callback={() => this.setState({ confirmar: false }, () => this.importarProdutos())}
                    open={this.state.confirmar}
                    onClose={() => this.setState({ confirmar: false })}
                    cancelar={t("cancelar")}
                    confirmar={t("prosseguir")}
                    mensagem={"Tem certeza que deseja importar esses produtos?"}
                    titulo={"Importação de produtos"}
                />

                <HeaderPadrao
                    icone="boxes"
                    titulo="Importação de produtos"
                    subtitulo="Exportar produtos de uma empresa para n empresas com base na api do clube da venda."
                />

                <div style={{ display: "flex", flexDirection: "column" }}>
                    {this.select()}
                    {this.checkbox()}
                    <label for="produto" className="label-importacao">
                        Produto:
                    </label>
                    <Input
                        fluid
                        type="text"
                        id="Produto"
                        value={this.state.produto}
                        placeholder="Produto"
                        onChange={(e, data) => this.setState({ produto: data.value, paginaAtual: 1 })}
                    />
                    <button
                        onClick={this.buscarProdutos}
                        style={{
                            width: "auto",
                            height: 54,
                            border: "1px solid #602579",
                            backgroundColor: "#fff",
                            borderRadius: 4,
                            color: "#602579",
                            cursor: "pointer",
                            marginTop: 10,
                        }}
                    >
                        Buscar produtos
                    </button>
                    <button
                        onClick={() => {
                            if (
                                juntarProdutos(this.state.produtosSelecionados, this.state.retornosGeral).length === 0
                            ) {
                                return this.props.enqueueSnackbar(
                                    this.props.t("nenhum produto foi selecionado", { nome: "treinamentoModal.nome" }),
                                    { variant: "warning" }
                                );
                                // return alert('nenhum produto foi selecionado')
                            } else if (this.state.destinos.length === 0) {
                                return this.props.enqueueSnackbar(
                                    this.props.t("nenhuma empresa de destino foi selecionada", {
                                        nome: "treinamentoModal.nome",
                                    }),
                                    { variant: "warning" }
                                );
                                // return alert('nenhum destino foi selecionado')
                            } else {
                                this.setState({ modalImportar: true });
                            }
                        }}
                        style={{
                            width: "auto",
                            height: 54,
                            border: "1px solid #602579",
                            backgroundColor: "#fff",
                            borderRadius: 4,
                            color: "#602579",
                            cursor: "pointer",
                            marginTop: 10,
                        }}
                    >
                        Importar
                    </button>
                    <Modal
                        height={700}
                        onClose={() => this.setState({ modalImportar: false })}
                        open={this.state.modalImportar}
                        header="Importar Produtos"
                        content={this.renderProdutosParaImportar}
                        actions={[
                            {
                                key: "done",
                                style: {
                                    backgroundColor: "#F9FAFB",
                                    border: "1px solid #602579",
                                    color: "#602579",
                                    cursor: "pointer",
                                },
                                content: "Cancelar",
                                positive: false,
                                onClick: () => this.setState({ confirmar: false }),
                            },
                            {
                                key: "done",
                                style: {
                                    backgroundColor: "#F9FAFB",
                                    border: "1px solid #602579",
                                    color: "#602579",
                                    cursor: "pointer",
                                },
                                content: "Importar",
                                positive: true,
                                onClick: () => this.setState({ confirmar: true }),
                            },
                        ]}
                    />
                </div>
                {this.renderProdutos()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ipLocation: state.AutenticacaoReducer.ipLocation,
    };
};

export default connect(mapStateToProps)(withTranslation()(EnviarEmails));
