import _ from "lodash";
import moment from "moment";
import { Component, Fragment } from "react";
import Currency from "react-currency-formatter";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";
import {
	Button,
	Divider,
	Grid,
	Icon,
	Loader,
	Message,
	Pagination,
	Segment,
} from "semantic-ui-react";
import Confirmar from "../../Componentes/Confirmar";
import { chamarFunction, chamarNodeApi } from "../../Utils/Api";
import { db } from "../../config";
import "./detalhesFatura.css";
import { gerarNotaDeDepositoMensal, gerarNotaDeDepositoOmie } from "./notasFiscaisDeDeposito";
import { queriePorIdOportunidades } from "../../Utils/mongo/queriesMongo";
import { Text } from "@chakra-ui/react";

class DetalhesFatura extends Component {
	state = {
		oportunidades: [],
		fatura: null,
		activePage: 1,
		buscandoNota: false,
		buscandoNotaError: { error: false, mensagem: "" },
		buscandoNotaModal: false,
		loading: true,
	};

	componentDidMount() {
		if (this.props.history.location.fatura) {
			this.detalhesFatura(this.props.history.location.fatura.oportunidades);
			this.setState({ fatura: this.props.history.location.fatura });
		} else {
			this.buscaFatura();
		}
	}

	buscaFatura = async () => {
		this.setState({ loading: true });
		try {
			const snap = await db
				.collection("empresas")
				.doc(this.props.empresa.uid)
				.collection("faturas")
				.doc(this.props.match.params.id)
				.get();
			const data = snap.data();
			this.detalhesFatura(data.oportunidades);
			this.setState({ fatura: data });
		} catch (e) {
			console.log(e);
		}
		this.setState({ loading: false });
	};

	// detalhesFatura = oportunidadesFatura => {
	// 	let oportunidades = [];
	// 	// _.chunk(oportunidadesFatura, 10).map(oportunidade =>
	// 	// 	db
	// 	// 		.collection("roteiros")
	// 	// 		.where("id", "in", oportunidade)
	// 	// 		.get()
	// 	// 		.then(snap => {
	// 	// 			snap.docs.forEach(doc => oportunidades.push(doc.data()));
	// 	// 			this.setState({
	// 	// 				...this.state.oportunidades,
	// 	// 				oportunidades,
	// 	// 				loading: false,
	// 	// 			});
	// 	// 		})
	// 	// 		.catch(e => {
	// 	// 			console.log(e);
	// 	// 		})
	// 	// );
	// };

	detalhesFatura = async oportunidadesFatura => {
		try {
			const oportunidades = await chamarNodeApi(
				"roteiro/aggregate",
				{
					search: queriePorIdOportunidades(oportunidadesFatura),
				},
				"POST"
			);

			this.setState({ oportunidades, loading: false });
		} catch (erro) {
			this.setState({ loading: false });
			console.log(erro);
		}
	};

	/**
	 * @name emitirNFsMensal
	 * @date Criado em 11/06/2021
	 * @author Alexandre Poletto
	 * @description Gera a nota fiscal e a de débito do faturamento mensal
	 */
	emitirNFsMensal = () => {
		const erroNota = mensagem =>
			this.setState({
				buscandoNota: false,
				buscandoNotaError: {
					error: true,
					mensagem,
				},
			});
		const { fatura } = this.state;
		const {
			empresa,
			empresa: { endereco },
		} = this.props;
		const enderecoCompleto = `${endereco.logradouro}, ${endereco.numero} - ${endereco.complemento} - ${endereco.bairro}, ${endereco.cidade} - ${endereco.estado}, ${endereco.cep}`;
		const mensagem = "Nenhuma nota foi encontrada ou emitida";
		const ref = fatura.boleto.id
			? fatura.boleto.id.slice(0, 4) + "-" + fatura.boleto.id.slice(28, 32)
			: null;
		const dataFatura = moment(fatura.data.toDate()).format("DD/MM/YYYY");
		const dataDeVencimentoFatura = moment(fatura.boleto?.due_date).toDate();

		const roteiro = {
			id: "",
			data: dataFatura,
			fatura: fatura.referencia,
			dataPagamento: fatura?.dataPagamento ?? dataDeVencimentoFatura,
			idEmpresa: empresa.uid,
			emailEmpresa: empresa.email,
			fantasiaEmpresa: empresa.fantasia,
			cnpjEmpresa: empresa.cnpj,
			enderecoEmpresa: enderecoCompleto,
			pagamento: {
				taxa: fatura.totalTaxa,
				valorPromotor: fatura.totalPromotor,
			},
			valor: parseFloat(fatura.total),
		};

		try {
			this.setState(
				{
					buscandoNota: true,
					buscandoNotaModal: true,
					buscandoNotaError: { error: false, mensagem: "" },
				},
				async () => {
					if (fatura?.notaFiscal?.codigo_nfs) {
						gerarNotaDeDepositoOmie(roteiro, fatura.notaFiscal, true, ref);
						const nota = `${this.props?.configuracao?.notaFiscal}${fatura?.notaFiscal?.codigo_nfs}`;
						window.location.assign(nota);
						this.setState({ buscandoNota: false });
					} else if (ref === null) {
						erroNota(mensagem);
					} else {
						const ordemServico = await chamarFunction("listarNFSOmie", {
							codigo_nfs: fatura?.notaFiscal?.codigo_nfs ?? -1,
							ref,
							roteiro,
						});
						if (ordemServico === mensagem) {
							erroNota(mensagem);
						} else {
							erroNota(
								"Essa nota está em processo de emissão, aguarde alguns minutos e tente novamente."
							);
						}
					}
				}
			);
		} catch (erro) {
			erroNota(mensagem);
			console.log(erro);
		}
	};

	/**
	 * @name gerarFatura
	 * @date Criado em 20/01/2021
	 * @author Alexandre Poletto
	 * @description Gera a nota fiscal e a de débito do faturamento mensal
	 * @param {Object} _x evento padrão não utilizado
	 * @param {Object} _y evento padrão não utilizado
	 * @param {Object} fatura dados da fatura atual
	 * @param {Object} oportunidades oportunidades da fatura
	 * @param {Object} empresa dados da empresa
	 * @param {Object} chamarFuncao buscar a nota no firebase
	 * @param {Object} notaDeposito gerar a nota de depósito
	 * @param {Object} setState setar o estado
	 */
	gerarFatura = (
		_x,
		_y,
		fatura = this.state.fatura,
		oportunidades = this.state.oportunidades,
		empresa = this.props.empresa,
		chamarFuncao = chamarFunction,
		notaDeposito = gerarNotaDeDepositoMensal,
		setState = param => this.setState(param)
	) =>
		new Promise(async (res, rej) => {
			setState({
				buscandoNota: true,
				buscandoNotaModal: true,
				buscandoNotaError: { error: false, mensagem: "" },
			});
			const soma = _.reduce(
				oportunidades,
				(soma, roteiro) => soma + roteiro.pagamento.valorPromotor,
				0
			);
			const roteiro = {
				usuario: { sexo: "Empresa" },
				pagamento: { valorPromotor: soma },
				fantasiaEmpresa: oportunidades[0].fantasiaEmpresa,
				cnpjEmpresa: oportunidades[0].cnpjEmpresa,
				enderecoEmpresa: oportunidades[0].enderecoEmpresa,
			};
			const data = {
				emissao: fatura.data,
				referencia:
					fatura.referencia.substring(0, 2) + "/" + fatura.referencia.substring(2, 6),
			};
			try {
				const retorno = await chamarFuncao("buscarRefNFSmensal", {
					fatura: fatura,
					empresa: empresa,
				});
				notaDeposito(roteiro, data, retorno.numero_rps, retorno.body);
				setState({ buscandoNota: false });
				res(retorno);
			} catch (e) {
				setState({
					buscandoNota: false,
					buscandoNotaError: {
						error: true,
						mensagem:
							"Essa nota esta em processo de emissão, aguarde um momento e tente novamente.",
					},
				});
				rej("catch");
			}
		});

	/**
	 * @name pageChanger
	 * @author Vitor Savian
	 * @description troca a pagina se baseando no evento do semantic
	 * @param {Object} e evento
	 * @param {Object} data valores que vem do semantic
	 */
	pageChanger = (e, data) => {
		this.setState({ activePage: data.activePage });
	};

	render() {
		const { t } = this.props;
		const { oportunidades, fatura, activePage, loading } = this.state;
		const paginationOportunidades = _.orderBy(oportunidades, ["data"], ["desc"])
			.slice((activePage - 1) * 30)
			.slice(0, 30);
		let callbackConfirmar = () =>
			!this.state.buscandoNota ? this.setState({ buscandoNotaModal: false }) : () => {};

		const LinhaFaturaExcel = props => {
			return (
				<tr>
					<td>{moment(props.oportunidade.data).format("DD/MM/YYYY")}</td>
					<td>
						{props.oportunidade.statusRoteiro.id === 3 ||
						props.oportunidade.statusRoteiro.id === 5
							? t("executada")
							: t("nao.executada")}
					</td>
					<td>{props.oportunidade.loja.fantasia}</td>
					<td>
						{props.oportunidade.loja.endereco.rua},{" "}
						{props.oportunidade.loja.endereco.numero} -{" "}
						{props.oportunidade.loja.endereco.cidade},{" "}
						{props.oportunidade.loja.endereco.estado}
					</td>
					<td align="right">
						<Currency
							quantity={parseFloat(props.oportunidade.pagamento.valorTotal) ?? 0}
							locale="pt_BR"
							decimal=","
							group="."
							currency="R$ "
						/>
					</td>
				</tr>
			);
		};

		return (
			<div>
				<div style={styles.header}>
					<Icon
						name="arrow left"
						size="big"
						style={{ cursor: "pointer", justifySelf: "center" }}
						onClick={this.props.history.goBack}
					/>
					<Text fontSize={22} margin={"auto"} fontWeight={"bold"}>
						{t("fatura") + " - #" + this.props.match.params.id}
					</Text>
				</div>
				{_.isEmpty(oportunidades) && _.isEmpty(fatura) ? (
					<Message content={t("nenhuma.fatura.encontrada")} />
				) : (
					<>
						<div style={styles.fatura}>
							<Confirmar
								callback={callbackConfirmar}
								onClose={callbackConfirmar}
								open={this.state.buscandoNotaModal}
								isLoading={this.state.buscandoNota}
								error={this.state.buscandoNotaError.error}
								errorTitle={t("nota.fiscal")}
								errorMessage={this.state.buscandoNotaError.mensagem}
								loadMessage={t("buscando.nota.message")}
								confirmar={t("fechar")}
								fechar={t("fechar")}
								mensagem={t("buscando.nota.message.sucesso")}
								titulo={t("nota.fiscal")}
							/>
							<div style={{ textAlign: "left", flex: 1 }}>
								<div style={styles.divInfo}>
									<span style={styles.texto}>{t("mes.referencia")}</span>
									<br></br>
									<span style={styles.textoData}>
										{fatura.referencia.substring(0, 2) +
											"/" +
											fatura.referencia.substring(2, 6) +
											" " +
											fatura?.referencia?.substring(7, 9) ?? ""}
									</span>
								</div>

								<div style={styles.divInfo}>
									<span style={styles.texto}>{t("data.vencimento")}</span>
									<br></br>
									<span style={styles.textoData}>
										{moment(fatura.boleto?.due_date).format("L")}
									</span>
								</div>

								<div style={styles.divInfo}>
									<span style={styles.texto}>{t("situacao")}</span>
									<br></br>
									<div
										style={{
											...styles.textoPago,
											backgroundColor: fatura.pago ? "#35BE35" : "#F44336",
										}}>
										{fatura.pago ? t("paga") : t("nao.paga")}
									</div>
								</div>
							</div>

							<div style={{ textAlign: "left" }}>
								<div style={styles.divInfo}>
									<span style={styles.texto}>{t("dia.pagamento")}</span>
									<br></br>
									<span style={styles.textoData}>
										{fatura?.dataPagamento
											? moment(fatura.dataPagamento).format("DD/MM/YYYY")
											: "--"}
									</span>
								</div>
								<div style={styles.divInfo}>
									<span style={styles.texto}>
										{t("quantidade.oportunidades")}
									</span>
									<br></br>
									<span style={styles.textoData}>
										{oportunidades?.length || "--"}
									</span>
								</div>
								<div style={styles.divInfo}>
									<span style={styles.texto}>{t("total")}</span>
									<br></br>
									<b style={styles.textoData}>
										<Currency
											quantity={parseFloat(fatura?.total) ?? 0}
											locale="pt_BR"
											decimal=","
											group="."
											currency="R$ "
										/>
									</b>
								</div>
							</div>
						</div>

						<div style={styles.buttons}>
							<Button
								target="_blank"
								content={fatura.pago ? t("visualizar.fatura") : t("gerar-boleto")}
								icon="external square alternate"
								href={fatura.boleto.secure_url}
							/>
							{fatura.pago === true && (
								<Button
									content={
										fatura?.notaFiscal?.codigo_nfs
											? t("baixar.comprovantes")
											: t("emitir.nota.fiscal")
									}
									icon="download"
									onClick={() =>
										fatura?.notaFiscal?.omie
											? this.emitirNFsMensal()
											: this.gerarFatura()
									}
								/>
							)}
							{!loading && !_.isEmpty(oportunidades) ? (
								<ReactHTMLTableToExcel
									id="test-table-xls-button"
									className="download-table-xls-button "
									table="table-to-xls"
									filename="Detalhes-Fatura"
									sheet="Faturas"
									buttonText="Exportar XLS"
								/>
							) : null}
						</div>
						<div style={styles.divOportunidades}>
							<b style={styles.textoOportunidades}>
								{t("oportunidades.nesta.fatura")}
							</b>
						</div>
						{loading ? (
							<div>
								<Segment basic className="page-loading">
									<Loader style={{ display: "block" }} inline="centered">
										{t("carregando")}
									</Loader>
								</Segment>
							</div>
						) : (
							<div>
								<table
									style={{ display: "none" }}
									id="table-to-xls"
									className="ui celled table">
									<thead>
										<tr>
											<th>Data</th>
											<th>Status</th>
											<th>Loja</th>
											<th>Endereço</th>
											<th>Valor</th>
										</tr>
									</thead>
									<tbody>
										{oportunidades.map(oportunidade => (
											<Fragment key={oportunidade.id}>
												<LinhaFaturaExcel oportunidade={oportunidade} />
											</Fragment>
										))}
										<tr>
											<th colSpan="4" align="right">
												Total
											</th>
											<td align="right">
												<Currency
													quantity={parseFloat(fatura?.total) ?? 0}
													locale="pt_BR"
													decimal=","
													group="."
													currency="R$ "
												/>
											</td>
										</tr>
									</tbody>
								</table>

								{paginationOportunidades.map(oportunidade => {
									const endereco = oportunidade.loja.endereco;
									return (
										<Link
											key={oportunidade.id}
											style={styles.link}
											to={`/oportunidade/info/${oportunidade.id}`}>
											<div style={styles.divMapOportunidades}>
												<div style={{ width: "20%" }}>
													<span style={{ fontWeight: "bold" }}>
														{moment(oportunidade.data).format(
															"DD/MM/YYYY"
														)}
													</span>
													<br></br>
													<span>
														{oportunidade.statusRoteiro.id === 3 ||
														oportunidade.statusRoteiro.id === 5
															? t("executada")
															: t("nao.executada")}
													</span>
												</div>
												<div style={{ width: "70%" }}>
													<span style={{ fontWeight: "bold" }}>
														{oportunidade.loja.fantasia}
													</span>
													<br></br>
													<span>
														{endereco.rua}, {endereco.numero} -{" "}
														{endereco.cidade} / {endereco.estado}
													</span>
												</div>
												<span style={styles.totalOportunidade}>
													<Currency
														quantity={oportunidade.pagamento.valorTotal}
														locale="pt_BR"
														decimal=","
														group="."
														currency="R$ "
													/>
												</span>
											</div>
											<Divider />
										</Link>
									);
								})}
							</div>
						)}
						<Grid stackable>
							<Grid.Column width={13}>
								<Pagination
									boundaryRange={3}
									activePage={activePage}
									shape="rounded"
									prevItem={{ content: <Icon name="angle left" />, icon: true }}
									nextItem={{
										content: <Icon name="angle right" />,
										icon: true,
									}}
									firstItem={null}
									lastItem={null}
									onPageChange={this.pageChanger}
									totalPages={Math.ceil(oportunidades.length / 30)}
								/>
							</Grid.Column>
							<Grid.Column width={3} textAlign="right">
								<span style={styles.textoTotal}>{t("total")} </span>
								<b style={styles.valorTotal}>
									<Currency
										quantity={parseFloat(fatura?.total) ?? 0}
										locale="pt_BR"
										decimal=","
										group="."
										currency="R$ "
									/>
								</b>
							</Grid.Column>
						</Grid>
					</>
				)}
			</div>
		);
	}
}

const styles = {
	link: {
		display: "block",
		color: "#333",
	},
	fatura: {
		display: "flex",
	},
	divInfo: { marginTop: 20, marginBottom: 30 },
	buttons: {
		display: "flex",
	},
	divTotal: {
		display: "flex",
		flexDirection: "row",
	},
	divOportunidades: {
		paddingTop: 50,
		paddingBottom: 20,
		marginBottom: 15,
		borderBottom: "1px solid #ccc",
	},
	texto: {
		textTransform: "uppercase",
		fontSize: 16,
	},
	textoData: {
		fontSize: 24,
		fontWeight: "bold",
	},
	textoOportunidades: {
		textTransform: "uppercase",
		textAlign: "center",
		fontSize: 18,
	},
	divMapOportunidades: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	textoTotal: {
		textTransform: "uppercase",
		fontWeight: "bold",
		marginRight: 20,
		fontSize: 17,
	},
	totalOportunidade: {
		width: "10%",
		fontWeight: "bold",
		fontSize: 15,
	},
	valorTotal: {
		fontSize: 17,
		width: "10%",
	},
	textoPago: {
		textTransform: "uppercase",
		fontSize: 17,
		color: "white",
		paddingTop: 5,
		paddingBottom: 5,
		paddingLeft: 10,
		paddingRight: 10,
		borderRadius: 3,
		display: "inline-flex",
		marginTop: 5,
	},
	header: {
		paddingTop: "20px",
		paddingBottom: "20px",
		borderBottom: "1px solid #ccc",
		display: "flex",
		alignItems: "center",
	},
};

export default DetalhesFatura;

export const gerarFatura = new DetalhesFatura().gerarFatura;
