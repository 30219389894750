import { ModalContent, ModalContentProps } from "semantic-ui-react";
import styled from "styled-components";

export const Container = styled.div`
	border: 2px solid #7501c0;
	border-radius: 10px;
	margin-bottom: 1rem;
`;

export const Content: React.FC<ModalContentProps> = styled(ModalContent)`
	display: flex !important;
	justify-content: center;
	align-items: center;
	width: 800px;
	height: 400px;
	overflow: auto !important;
	padding: 3rem !important;
`;

export const Image = styled.img`
	border: 2px solid #4f1e63;
	border-radius: 10px;
	width: 300px;
	height: 300px;
`;
