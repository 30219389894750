import styled, { css } from "styled-components";

export const Container = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const Hour = styled.h1<{ opportunityType: number }>`
    font-size: 1.2rem;
    font-weight: bold;

    ${({ opportunityType }) =>
        opportunityType === 5 &&
        css`
            line-height: 1;
        `};
`;

export const Activities = styled.span`
    font-size: 0.8em;
    color: #999;
    font-weight: normal;
    word-wrap: none;
    white-space: nowrap;
`;
