export const ordenarDiasDaSemana = (diasDaSemana) => {
    let dias = {
        "Dom": 0,
        "Seg": 1,
        "Ter": 2,
        "Qua": 3,
        "Qui": 4,
        "Sex": 5,
        "Sab": 6,
    }

    let diasString = {
        "0": "Dom",
        "1": "Seg",
        "2": "Ter",
        "3": "Qua",
        "4": "Qui",
        "5": "Sex",
        "6": "Sab"
    }
    return diasDaSemana.map((dia) => dias[dia])
        .sort()
        .map((dia, i) => `${diasString[dia]}${diasDaSemana.length !== (i + 1) ? ', ' : '  '}`)
}

const tipos = {
	1: { nome: "Aguardando Candidatos", icone: "time", color: "yellow" },
	2: { nome: "Pendente", icone: "user", color: "blue" },
	3: { nome: "Executado", icone: "check", color: "green" },
	4: { nome: "Executado com pendências", icone: "user times", color: "brown" },
	5: { nome: "Finalizado", icone: "check", color: "purple" },
	6: { nome: "Em execução", icone: "fire", color: "blue" },
	7: { nome: "Expirado", icone: "times", color: "red" },
};

export const StatusRoteiro = id => tipos[id];
