import React, { Component } from 'react';
import Currency from 'react-currency-formatter';
import { withTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import { Button, Dimmer, Divider, Grid, Icon, Label, Loader, Message, Table } from 'semantic-ui-react';
import _ from 'lodash';

export const DescricaoDeAtividade = ({
    atividade
}) => (
        <>
            <Icon size='big' name={atividade.icone} className='center-v' style={{ float: 'left', marginRight: 14, width: 40 }}></Icon>
            <span style={{ float: 'left', width: 'calc(100% - 40px - 14px)' }}>
                {atividade.produto && atividade.produto.descricao}< br />
                <span className="roteiro-info-secondary">{atividade.descricao}</span>
            </span>
        </>
    );

export const AtividadeProdutoExpirado = ({
    t, item
}) => {
    return (
        <Grid className="roteiro-grid" stackable>
            <Grid.Column width={12}>
                <DescricaoDeAtividade t={t} atividade={item} />
            </Grid.Column>
        </Grid>
    );
};

export class AtividadeProduto extends Component {
    render() {
        const {
            t, item, index, abrirAtividade,
            aberto, carregandoAtividade, fotosExibir, executado
        } = this.props;
        const foiExecutado = executado == null ? true : executado;
        const mostrarInformacao = this.props.mostrarInformacao == null ? true : this.props.mostrarInformacao;
        const concorrentes = item.concorrentes || {}
        return (
            <div>
                <Grid className="roteiro-grid" stackable>
                    <Grid.Column width={foiExecutado ? 6 : 12}>
                        <DescricaoDeAtividade atividade={item} />
                    </Grid.Column>
		    {
		       foiExecutado && <>
		            <Grid.Column width={2} className='align-center'>
		                <span>
		                    {item.hora}<br />
		                    <span className="roteiro-info-secondary">Hora</span>
		                </span>
		            </Grid.Column>
		            <Grid.Column width={2} className='align-center'>
		                {
		                    item.foto &&
		                    <span>
		                        {item.quantidadeFoto}< br />
		                        <span className="roteiro-info-secondary">{item.quantidadeFoto > 1 ? t('fotos') : t('foto')}</span>
		                    </span>
		                }
		            </Grid.Column>
		            <Grid.Column width={2} className='align-center'>
		                {
		                    (item.dataValidade && item.existeProduto) &&
		                    <span className='center-v'>
		                        {item.dataValidade}
		                    </span>
		                }
		                {
		                    concorrentes &&
		                    <span className='center-v'>
		                        {_.keys(concorrentes).length}< br />
		                        <span className="roteiro-info-secondary">{_.keys(concorrentes).length > 1 ? t('concorrentes') : t('concorrente')}</span>
		                    </span>
		                }
		            </Grid.Column>
		            <Grid.Column width={4} className='align-center'>
		                {
		                    (mostrarInformacao && item.foto && item.existeProduto) && <Button className='center-v' basic color='grey' content={t('+.info')} onClick={() => { abrirAtividade(index) }} icon='photo' labelPosition='right' fluid />
		                }
		                {mostrarInformacao && !item.existeProduto && <Label className='center-v' content={t('ruptura')} icon='times' size='large' fluid color='red' basic />}
		            </Grid.Column>
			</>
		    }
                </Grid>
                {!item.existeProduto && item.observacao && (
                    <>
                        <Divider></Divider>
                        <Message fluid icon='comment alternate outline' header={t('promotor.deixou.observacao')} content={item.observacao} />
                    </>
                )}
                {item.foto && item.existeProduto && aberto &&
                    <div>
                        <Divider></Divider>
                        {carregandoAtividade &&
                            <Dimmer active inverted>
                                <Loader inverted>{t('recuperando.dados')}</Loader>
                            </Dimmer>
                        }
                        {!carregandoAtividade &&
                            <Grid stackable padded>
                                {(item.foto && !item.existeProduto) &&
                                    <Message fluid info icon='info' header={item.produto.descricao + ' ' + t('esta.em.ruptura.pdv')} />
                                }
                                {item.observacao &&
                                    <Message fluid icon='comment alternate outline' header={t('promotor.deixou.observacao')} content={item.observacao} />
                                }
                                {
                                    (item.dataValidade && item.existeProduto) &&
                                    <Grid.Column width={16}>
                                        <Table singleLine color='purple'>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{t('produto')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('quantidade')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('vencimento')}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>{item.produto.descricao}</Table.Cell>
                                                    <Table.Cell>{item.quantidade}</Table.Cell>
                                                    <Table.Cell>{item.dataValidade}</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                }
                                {concorrentes &&
                                    <Grid.Column width={16}>
                                        <Table singleLine color='purple'>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{t('produto')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('preco.loja')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('preco.sugerido')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('diferenca')}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>{item.produto.descricao}</Table.Cell>
                                                    <Table.Cell>
                                                        {item.existeProduto
                                                            ? <Currency
                                                                quantity={item.nossoPreco}
                                                                decimal=','
                                                                group='.'
                                                                currency="BRL"
                                                            />
                                                            : <Label content={t('ruptura')} icon='times' size='small' fluid color='red' basic />
                                                        }

                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {item.produto.preco
                                                            ? <Currency
                                                                quantity={item.produto.preco}
                                                                decimal=','
                                                                group='.'
                                                                currency="BRL"
                                                            />
                                                            : t('nao.informado')}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {item.produto.preco
                                                            ? <span>{item.existeProduto
                                                                ? <Currency
                                                                    quantity={parseFloat(item.nossoPreco - item.produto.preco).toFixed(2)}
                                                                    decimal=','
                                                                    group='.'
                                                                    currency="BRL"
                                                                />
                                                                : <Label content={t('ruptura')} icon='times' size='small' fluid color='red' basic />
                                                            }</span>
                                                            : t('nao.informado')}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <Table singleLine color='red'>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{t('concorrente')}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t('preco.concorrente')}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {
                                                    _.map(concorrentes, (concorrente) => {
                                                        return (
                                                            <Table.Row>
                                                                <Table.Cell>{concorrente.descricaoConcorrente}</Table.Cell>
                                                                <Table.Cell>
                                                                    <Currency
                                                                        quantity={concorrente.precoConcorrente}
                                                                        decimal=','
                                                                        group='.'
                                                                        currency="BRL"
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    })
                                                }
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                }
                                {(item.foto && item.existeProduto) &&
                                    <Grid.Column width={16}>
                                        <ImageGallery items={fotosExibir} showPlayButton={false} additionalClass='atividade-image-gallery' />
                                    </Grid.Column>
                                }
                            </Grid>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default withTranslation()(AtividadeProduto);
