import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useController, UseControllerProps } from "react-hook-form";
import { PatternFormat, PatternFormatProps } from "react-number-format";
import { Iconify } from "../../../Iconify";

type InputFormProps<T> = InputProps &
	UseControllerProps<T> & {
		label?: string;
		enableTextError?: boolean;
		patternProps?: PatternFormatProps;
	};

/**
 * Renderiza um input de formulário do `Chakra UI` controlado pelo `react-hook-form`
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function InputForm<T>({
	label,
	control,
	name,
	rules,
	enableTextError = true,
	defaultValue,
	isRequired,
	isDisabled,
	isReadOnly,
	patternProps,
	...rest
}: InputFormProps<T>) {
	const [showPassword, setShowPassword] = useState(false);

	const {
		field: { onChange, value, ref, onBlur },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
		defaultValue,
	});

	/**
	 * Alternando o estado para mostrar/esconder a senha
	 */
	function handleToggleShowPassword() {
		setShowPassword(oldState => !oldState);
	}

	const inputType = useMemo(() => {
		if (rest.type === "password") {
			return showPassword ? "text" : "password";
		} else {
			return rest.type;
		}
	}, [rest.type, showPassword]);

	return (
		<FormControl isInvalid={!!error} isDisabled={isDisabled} isReadOnly={isReadOnly}>
			{!!label && (
				<FormLabel
					htmlFor={name}
					_after={isRequired ? { content: `' *'`, color: "red" } : {}}>
					{label}
				</FormLabel>
			)}
			<InputGroup>
				{patternProps ? (
					<PatternFormat
						{...patternProps}
						w="full"
						customInput={Input}
						getInputRef={ref}
						name={name}
						onChange={onChange}
						onBlur={onBlur}
						value={value as string}
					/>
				) : (
					<Input
						w="full"
						{...rest}
						ref={ref}
						name={name}
						onChange={onChange}
						onBlur={onBlur}
						value={value as string}
						type={inputType}
					/>
				)}
				{rest.type === "password" && (
					<InputRightElement>
						<IconButton
							colorScheme="gray"
							size="xs"
							onClick={handleToggleShowPassword}
							aria-label="mostrar-esconder-senha">
							<Iconify icon={showPassword ? "mdi:eye" : "mdi:eye-off"} boxSize={4} />
						</IconButton>
					</InputRightElement>
				)}
			</InputGroup>
			{!!error && enableTextError && <FormErrorMessage>{error.message}</FormErrorMessage>}
		</FormControl>
	);
}
