import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Grid, Header, Icon, Image } from "semantic-ui-react";
import { auth } from "../../config";
import logo from "../../imagens/promotor.png";
import { chamarFunction } from "../../Utils/Api";
import { atualizarFatura, desautenticaUsuario } from "../../_actions/AutenticacaoActions";

class SaldoDevedor extends Component {
    state = {
        carregando: false,
    };

    /**
     * @name gerarBoleto
     * @date Editado em 03/06/2022
     * @author Alexandre Poletto
     * @description Verifica se já existe um boleto gerado,
     * caso contrário chama a function para regerar a fatura
     */
    gerarBoleto = () => {
        const referenciaData = moment().startOf("month").add(14, "days");
        const referenciaQuinzena = moment().startOf("day").isAfter(referenciaData) ? "_01" : "_02";
        if (
            this.props.fatura?.boleto?.due_date &&
            moment(this.props.fatura.boleto.due_date).isSameOrAfter(moment(), "day")
        ) {
            window.open(this.props.fatura.boleto.secure_url);
        } else {
            this.setState({ carregando: true }, () =>
                chamarFunction("regerarFatura", {
                    uid: this.props.empresa?.uid,
                    quantidadeDias: 5,
                    quinzena: referenciaQuinzena,
                })
                    .then(async (ref) => {
                        const snap = await firestore
                            .collection("empresas")
                            .doc(this.props.empresa?.uid)
                            .collection("faturas")
                            .doc(ref)
                            .get();
                        const fatura = snap.data();
                        this.props.atualizarFatura(fatura);
                        this.setState({ carregando: false });
                        window.open(fatura.boleto.secure_url);
                    })
                    .catch((erro) => {
                        console.log(erro);
                        this.setState({ carregando: false });
                    })
            );
        }
    };

    logout = () => {
        auth.signOut().then(() => {
            this.props.desautenticaUsuario();
            window.localStorage.removeItem("modulo");
            this.props.history.push("/login");
        });
    };

    render() {
        const { t, fatura, vencimento } = this.props;
        return (
            <div style={styles.principal}>
                <Image centered style={styles.imagem} src={logo} size="medium" />
                <Grid centered>
                    <Grid.Row>
                        <Icon name="warning circle" size="massive" color="red" />
                    </Grid.Row>
                    <Grid.Row>
                        <span style={styles.bloqueado}>{t("acesso.sistema.bloqueado")}</span>
                    </Grid.Row>
                    <Grid.Row>
                        <span style={styles.bloqueado}>
                            {t("fatura.referencia", {
                                fatura1: fatura?.referencia?.substring(0, 2),
                                fatura2: fatura?.referencia?.substring(2, 6),
                                vencimento: moment(vencimento).format("DD/MM/YYYY"),
                            })}
                        </span>
                    </Grid.Row>
                    <Grid.Row>
                        <Header
                            size="huge"
                            content={t("valor.em.aberto", {
                                valor: parseFloat(fatura?.total).toLocaleString("BRL", {
                                    style: "currency",
                                    currency: "BRL",
                                }),
                            })}
                            subheader={t("usar.sistema.apos.compensar")}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Button
                            loading={this.state.carregando}
                            disabled={this.state.carregando}
                            size="large"
                            color="purple"
                            icon="barcode"
                            style={styles.botao}
                            onClick={this.gerarBoleto}
                            content={t("gerar-boleto")}
                        />
                    </Grid.Row>
                </Grid>
                <Button style={styles.sair} icon="sign-out" onClick={this.logout} content={t("encerrar.sessao")} />
            </div>
        );
    }
}

const styles = {
    bloqueado: {
        fontSize: 25,
        lineHeight: 1.3,
        width: "35%",
    },
    valor: {
        marginTop: 20,
        fontSize: 28,
        fontWeight: "bold",
    },
    subheader: {
        fontSize: 18,
        color: "gray",
    },
    imagem: {
        marginTop: 30,
    },
    sair: {
        alignSelf: "center",
        width: 170,
        marginBottom: 30,
    },
    botao: {
        margin: 15,
        display: "flex",
        flexDirection: "row",
    },
    principal: {
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
};

const mapStateToProps = (state) => {
    return {
        fatura: state.AutenticacaoReducer.fatura,
    };
};

export default connect(mapStateToProps, { desautenticaUsuario, atualizarFatura })(withTranslation()(SaldoDevedor));
