import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Input, Button, Image, Label } from "semantic-ui-react";
import moment from "moment";
import _ from "lodash";
import { db } from "./../../config";
import { connect } from "react-redux";

class Mensagem extends Component {
	state = {
		closed: true,
		mensagem: "",
		mensagens: {},
	};

	componentDidMount() {
		this.handleMessages();
	}

	/**
	 * Converte um time originado de um date para moment.
	 */
	timeToMoment = time => moment(new Date(parseInt(time)));

	/**
	 * Quando o componente for atualizado e houver alteração nas mensagens do usuário ou da empresa
	 * será feito a atualização das mensagens no chat.
	 * Quando a alteração for feita no json da outra pessoa do chat, será atualizado o visto.
	 */
	componentDidUpdate(prevProps) {
		const { roteiro, empresa } = this.props;
		if (
			prevProps.roteiro.chatEmpresa !== roteiro.chatEmpresa ||
			prevProps.roteiro.chatUsuario !== roteiro.chatUsuario
		) {
			var chat = empresa.uid === roteiro.idEmpresa ? "chatUsuario" : "chatEmpresa";
			if (
				JSON.stringify(JSON.parse(prevProps.roteiro[chat] || "{}").messages) !==
					JSON.stringify(JSON.parse(roteiro[chat] || "{}").messages) &&
				!this.state.closed
			) {
				this.atualizarVisto();
			}
			this.handleMessages();
		}
		document.getElementById("chat").scrollTop = document.getElementById("chat").scrollHeight;
	}

	/**
	 * Somente para alterar o state do componente.
	 */
	changeState = (e, { name, value }) => this.setState({ [name]: value });

	/**
	 * Abre ou fecha o chat no canto inferior esquerdo.
	 */
	switchClosed = () => {
		if (this.state.closed) {
			this.atualizarVisto();
		}
		this.setState({ closed: !this.state.closed });
	};

	/**
	 * Função utilizada para atualizar o visto das mensagens.
	 */
	atualizarVisto = () => {
		const { roteiro, empresa } = this.props;
		var chat = JSON.parse(
			empresa.uid === roteiro.idEmpresa
				? roteiro.chatEmpresa || "{}"
				: roteiro.chatUsuario || "{}"
		);
		if (_.isEmpty(chat)) {
			chat = {
				visto: new Date().getTime(),
				sender: empresa.uid === roteiro.idEmpresa ? roteiro.idEmpresa : roteiro.usuario.uid,
				messages: [],
			};
		}
		db.collection("roteiros")
			.doc(roteiro.id)
			.update({
				[empresa.uid === roteiro.idEmpresa ? "chatEmpresa" : "chatUsuario"]: JSON.stringify(
					{ ...chat, visto: new Date().getTime() }
				),
			});
	};

	/**
	 * Envia mensagem e atualiza o roteiro
	 */
	enviarMensagem = e => {
		e.preventDefault();
		const { empresa, roteiro } = this.props;
		var mensagem = this.state.mensagem;
		this.setState({ mensagem: "" }, () => {
			var chat = JSON.parse(
				empresa.uid === roteiro.idEmpresa ? roteiro.chatEmpresa : roteiro.chatUsuario
			);
			chat.messages.push({ date: new Date().getTime(), message: mensagem });
			return db
				.collection("roteiros")
				.doc(roteiro.id)
				.update({
					[empresa.uid === roteiro.idEmpresa ? "chatEmpresa" : "chatUsuario"]:
						JSON.stringify(chat),
				});
		});
	};

	/**
	 * Agrupa as mensagens por minuto
	 */
	agruparMensagens = (mensagens, callback) => {
		var dados = {};
		mensagens.forEach(mensagem => {
			var time = mensagem.date;
			var key = `${mensagem.sender}/${this.timeToMoment(time).format("DD-MM-YY/HH:mm")}`;
			var value = {
				data: this.timeToMoment(time).format("HH:mm"),
				date: time,
				sender: mensagem.sender,
			};
			dados[key] = dados[key]
				? { ...value, messages: [...dados[key].messages, mensagem] }
				: { ...value, messages: [mensagem] };
		});
		callback(dados);
	};

	/**
	 * Calcula a quantidade de mensagens não visualizadas para informar ao usuário.
	 */
	calcularNaoVisualizadas = () => {
		const { empresa, roteiro } = this.props;
		const chat =
			JSON.parse(
				empresa.uid === roteiro.idEmpresa
					? roteiro.chatUsuario || "{}"
					: roteiro.chatEmpresa || "{}"
			) || {};
		const visto =
			!_.isEmpty(chat) &&
			this.timeToMoment(
				JSON.parse(
					empresa.uid === roteiro.idEmpresa
						? roteiro.chatEmpresa || "{}"
						: roteiro.chatUsuario || "{}"
				).visto || null
			);
		if (!_.isEmpty(chat))
			return (_.filter(chat.messages, m => this.timeToMoment(m.date).isAfter(visto)) || [])
				.length;
		return 0;
	};

	/**
	 * Adiciona no json de exibição as datas dos grupos de mensagens e o visualizado do usuário.
	 */
	adicionarDatasEVisualizado = (obj, visto) => {
		var novoJSON = {};
		var dia = "";
		Object.keys(obj).forEach(key => {
			if (dia === "" || this.timeToMoment(obj[key].date).diff(moment(dia), "days") >= 1) {
				dia = new Date(parseInt(obj[key].date));
				novoJSON["dia" + dia] = dia;
			}
			novoJSON[key] = obj[key];
			var visualizado =
				visto && this.timeToMoment(obj[key].date).isBefore(this.timeToMoment(visto));
			if (visualizado && obj[key].sender === this.props.empresa.uid) {
				delete novoJSON.visualizado;
				novoJSON.visualizado = true;
			}
		});
		return novoJSON;
	};

	/**
	 * Faz as tratativas para a exibição das mensagens.
	 */
	handleMessages = () => {
		const { chatEmpresa, chatUsuario, idEmpresa } = this.props.roteiro;
		let empresa = (chatEmpresa && JSON.parse(chatEmpresa)) || {
			sender: idEmpresa,
			messages: [],
		};
		let usuario = (chatUsuario && JSON.parse(chatUsuario)) || {
			sender: this.props.roteiro.usuario.uid,
			messages: [],
		};
		var mensagensEmpresa = empresa.messages.map(msg => ({
			...msg,
			sender: empresa.sender,
		}));
		var mensagensUsuario = usuario.messages.map(msg => ({
			...msg,
			sender: usuario.sender,
		}));
		let mensagens = _.orderBy([...mensagensEmpresa, ...mensagensUsuario], "date", ["asc"]);
		var visto = idEmpresa === this.props.empresa.uid ? usuario.visto : empresa.visto;
		var qtdMensagens =
			idEmpresa === this.props.empresa.uid
				? mensagensEmpresa.length
				: mensagensUsuario.length;
		this.agruparMensagens(mensagens, obj => {
			var naoVistos = this.calcularNaoVisualizadas();
			this.setState({
				mensagens: this.adicionarDatasEVisualizado(obj, visto),
				naoVistos,
				qtdMensagens,
				visto: { usuario: usuario.visto, empresa: empresa.visto },
			});
		});
	};

	render() {
		const { closed, mensagem, mensagens, naoVistos, qtdMensagens } = this.state;
		const { roteiro, t, empresa } = this.props;
		return (
			<div className="mensagem">
				<div className="header-mensagem" onClick={this.switchClosed}>
					<Image src={roteiro.usuario.foto} avatar />
					{/* {roteiro.usuario.nome} */}
					<label className="header-mensagem-promotor">{roteiro.usuario.nome}</label>
					{naoVistos > 0 && (
						<Label color="red" floating>
							{naoVistos}
						</Label>
					)}
				</div>
				<div className="chat-mensagem" style={{ height: closed ? 0 : "40vh" }}>
					<div className="chat-list" id="chat">
						{Object.keys(mensagens).map(key => {
							if (_.includes(key, "dia"))
								return (
									<div className="message-day">
										{moment(mensagens[key]).format("LL")}
									</div>
								);
							if (key === "visualizado")
								return (
									<div className="message-seen">
										<Image src={roteiro.usuario.foto} avatar />
									</div>
								);
							var classe =
								mensagens[key].sender === empresa.uid ? "sender" : "receiver";
							return (
								<>
									{mensagens[key].messages.map(msg => (
										<div className={`message-chat ${classe}`}>
											{msg.message}
										</div>
									))}
									<div className={`group-message-time ${classe}`}>
										{this.timeToMoment(mensagens[key].date).format("HH:mm")}
									</div>
								</>
							);
						})}
					</div>
					{this.props.roteiro.statusRoteiro.id != 3 &&
					this.props.roteiro.statusRoteiro.id != 5 &&
					qtdMensagens < 50 ? (
						<form
							autocomplete="off"
							className="send-message"
							onSubmit={this.enviarMensagem}>
							<Button
								icon="send"
								color="purple"
								size="huge"
								className="chat-button"
								type="submit"
							/>
							<Input
								maxLength={200}
								fluid
								value={mensagem}
								onChange={this.changeState}
								name="mensagem"
								placeholder={t("digite.sua.mensagem")}
							/>
						</form>
					) : (
						<div className="chat-blocked">{t("limite.mensagens.atingido")}</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	empresa: state.AutenticacaoReducer.empresa,
});

export default connect(mapStateToProps)(withTranslation()(Mensagem));
