import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Tooltip,
} from "@material-ui/core";
import firebase from "firebase";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import { Button as SemanticButton } from "semantic-ui-react";
import { Opportunity } from "../../@types/Opportunity";
import { db } from "../../config";
import { logging } from "../../services/Api";
import { chamarFunction } from "../../Utils/Api";

interface ModalCancelCandidatureProps {
	roteiro: Opportunity;
}

/**
 * @description Componente que renderiza um botão e um modal para confirmação da anulação de candidatura de um promotor aprovado.
 * @author Leonardo Petta do Nascimento
 */
export function ModalCancelCandidature({ roteiro }: ModalCancelCandidatureProps) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [showModal, toggleShowModal] = useToggle(false);
	const [loading, toggleLoading] = useToggle(false);
	const [motivo, setMotivo] = useState("");

	function handleCloseModal() {
		toggleShowModal(false);
	}

	function handleShowModal() {
		toggleShowModal(true);
	}

	async function handleCancelCandidature() {
		try {
			toggleLoading(true);
			const { usuario: user, promotor } = roteiro;
			const isAgencia = user.sexo === "Agência";

			type NotificationData = {
				idPromotor: string;
				idEmpresa?: string;
				fantasiaEmpresa: string;
				fantasiaLoja: string;
				dataExecucao: string;
				motivo?: string;
			};

			//NOTE: Monta o objeto de notificação para um promotor padrão
			const dataToNotification: NotificationData = {
				idPromotor: user.uid,
				fantasiaEmpresa: roteiro.fantasiaEmpresa,
				fantasiaLoja: roteiro.loja.fantasia,
				dataExecucao: moment(roteiro.data).toISOString(),
				motivo,
			};

			//NOTE: Se for agência então indicamos o promotor de outra propriedade e indicamos também o id da empresa
			if (isAgencia) {
				dataToNotification.idPromotor = promotor || user.uid || "NÃO CONSTA";
				dataToNotification.idEmpresa = user.uid;
			}

			const dataToUpdate = {
				usuario: firebase.firestore.FieldValue.delete(),
				statusAgencia: firebase.firestore.FieldValue.delete(),
				"statusRoteiro.id": 1,
				anulacaoPorEmpresa:
					roteiro?.selecaoAutomatica || roteiro?.selecaoAutomaticaFavorito
						? firebase.firestore.FieldValue.delete()
						: true,
			};

			await db.collection("roteiros").doc(roteiro.id).update(dataToUpdate);

			enqueueSnackbar(t("aprovacao.anulada"), {
				variant: "success",
			});

			await chamarFunction("Notification-notifyCancelCandidature", dataToNotification);
			logging(
				"DELETE",
				"log.cancelar.selecao.promotor",
				`Roteiro: ${roteiro.id}, Promotor: ${user.uid}`
			);
		} catch (error) {
			console.error(error);

			enqueueSnackbar(t("erro.anular.aprovacao"), {
				variant: "error",
			});
		} finally {
			handleCloseModal();
			toggleLoading(false);
		}
	}

	if (roteiro.statusRoteiro.id !== 2) return null;

	return (
		<>
			<SemanticButton
				style={{ marginTop: "12px" }}
				content={t("anular.aprovacao")}
				icon="times"
				color="red"
				basic
				onClick={handleShowModal}
			/>
			<Dialog open={showModal}>
				<DialogTitle>{t("voce-tem-certeza")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t(
							"voce-esta-prestes-a-anular-a-candidatura-de-um-promotor-se-quiser-prosseguir-com-sua-escolha-escreva-abaixo-o-motivo-da-anulacao"
						)}
					</DialogContentText>
					<TextField
						required
						autoFocus
						margin="dense"
						id="name"
						label={t("motivo-da-anulacao")}
						placeholder={t(
							"exemplo-estamos-buscando-um-promotor-com-uma-avaliacao-maior-que-4"
						)}
						fullWidth
						variant="outlined"
						multiline
						minRows={6}
						maxRows={6}
						onChange={e => setMotivo(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<SemanticButton onClick={handleCloseModal} disabled={loading} negative basic>
						{t("cancelar")}
					</SemanticButton>
					<Tooltip title={!motivo.length ? t("motivo-e-obrigatorio") : ""} arrow>
						<span>
							<SemanticButton
								onClick={handleCancelCandidature}
								disabled={loading || !motivo.length}
								loading={loading}
								positive>
								{t("confirmar")}
							</SemanticButton>
						</span>
					</Tooltip>
				</DialogActions>
			</Dialog>
		</>
	);
}
