import { createBreakpoint } from "react-use";

const breakpoints = {
	base: 0,
	sm: 480,
	md: 768,
	lg: 992,
	xl: 1280,
	"2xl": 1536,
};

const useBreakpoint = createBreakpoint(breakpoints);

type BreakpointKeys = keyof typeof breakpoints;

/**
 * @description Hook que mapeia os breakpoints do ChakraUI e determina se o breakpoint atual atingiu o ponto que foi passado.
 * @obs Hook útil para componentes que não usam ChakraUI e se torna obsoleto com a adição da lib ChakraUI.
 * @link https://chakra-ui.com/docs/styled-system/responsive-styles
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @param pointToCompare Ponto de quebra que você quer validar.
 * @returns Um objeto com o breakpoint atual e se ele atingiu o ponto que foi indicado em `pointToCompare`.
 */
export function useChakraUIBreakpoint(pointToCompare?: BreakpointKeys) {
	const breakpoint = useBreakpoint() as BreakpointKeys;

	return { currentBreakpoint: breakpoint, reachedBreakpoint: breakpoint === pointToCompare };
}
