import styled from "styled-components";

export const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
`;

export const ButtonWrapper = styled.header`
	display: flex;
	align-items: center;
`;
