import { useLeafletContext } from "@react-leaflet/core";
import turfCenter from "@turf/center";
import L from "leaflet";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { useEffectOnce } from "react-use";
import { OperationAreaFormProps } from "../..";
import { OperationArea } from "../../../../../@types/OperationArea";
import { useOperationArea } from "../../../../query/useOperationArea";

interface PolygonOrCircleToEditProps {
	/**
	 * Uma área de atuação no formato GeoJSON.
	 */
	shape: OperationArea["geometry"] | null;
	/**
	 * Define se está no modo de edição.
	 */
	editMode?: boolean;
	/**
	 * Função que é executada quando o shape é alterado.
	 */
	onChange: L.PM.EditEventHandler;
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Renderiza uma layer Editável de área de atuação e algumas outras layers não editáveis que representam outras áreas já criadas.
 */
export function PolygonOrCircleToEdit({ editMode, shape, onChange }: PolygonOrCircleToEditProps) {
	const mapContext = useLeafletContext();
	const currentOperationAreaColor = useWatch<OperationAreaFormProps>({
		name: "color",
	}) as string;
	const {
		queryMethods: { data },
	} = useOperationArea();
	const { id } = useParams<{ id: string }>();
	const { setValue } = useFormContext<OperationAreaFormProps>();

	//NOTE: Sempre que o shape for alterado, centralizamos o mapa no desenho.
	useEffect(() => {
		if (shape) {
			const centerPoint = turfCenter(shape);

			const position: [number, number] = [
				centerPoint.geometry.coordinates[1],
				centerPoint.geometry.coordinates[0],
			];
			setValue("mapCenter", position);
			mapContext.map.setView(position);
		}
	}, [mapContext.map, setValue, shape]);

	/**
	 * @description Função que gera uma layer para o mapa baseado na área de atuação.
	 * @param shape Uma área de atuação no formato GeoJSON.
	 * @param options As opções extras para personalizar o desenho no mapa.
	 * @returns Uma layer do Leaflet (Circle ou Polygon) se `shape`for válido, se não `null`.
	 */
	function operationAreaToMapLayer(
		shape: OperationArea["geometry"] | null,
		options?: L.CircleMarkerOptions | L.PolylineOptions
	) {
		const defaultConfigs: L.CircleMarkerOptions | L.PolylineOptions = {
			weight: 2,
			opacity: 1,
			fillOpacity: 0.3,
			color: currentOperationAreaColor,
			fillColor: currentOperationAreaColor,
			...options,
		};

		if (shape?.type === "Point") {
			const circle = L.circle([shape.coordinates[1], shape.coordinates[0]], {
				radius: shape.radius,
				...defaultConfigs,
			}).addTo(mapContext.map);

			return circle;
		}

		if (shape?.type === "Polygon") {
			const positions = shape.coordinates[0].map<[number, number]>(pos => [pos[1], pos[0]]);
			const polygon = L.polygon(positions, defaultConfigs).addTo(mapContext.map);

			return polygon;
		}

		return null;
	}

	//NOTE: Ao montar pela primeira vez(e só nela) adicionamos os desenhos no mapa.
	useEffectOnce(() => {
		//NOTE: Adiciona as áreas já cadastradas como Layers não editáveis no mapa.
		data?.forEach(operationArea => {
			if (operationArea._id === id) return;

			const layer = operationAreaToMapLayer(operationArea.geometry, {
				color: operationArea.color,
				fillColor: operationArea.color,
				pmIgnore: true,
			});

			if (layer) {
				layer.bindPopup(
					`NÃO EDITÁVEL - Área de atuação: ${operationArea.name} (${operationArea._id})`
				);
			}
		});

		//NOTE: Se estiver no modo edição, adicionamos a área de atuação que será editada como uma layer editável.
		if (editMode) {
			const geomanLayers = mapContext.map.pm.getGeomanLayers().length || 0;

			//NOTE: Se já houver algum desenho no mapa, só saímos da função
			if (geomanLayers >= 1) {
				return;
			}

			const newLayer = operationAreaToMapLayer(shape, { dashArray: "5, 5" });

			if (newLayer) {
				newLayer.on("pm:update", onChange);

				return () => {
					newLayer.off("pm:update");
				};
			}
		}
	});

	return null;
}
