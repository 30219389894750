import { Box, BoxProps, Tooltip } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Iconify, IconifyProps } from "../Iconify";

interface TipProps extends BoxProps {
	children: ReactNode;
	label: ReactNode;
	color?: IconifyProps["color"];
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Componente que exibe uma dica quando o mouse estiver sobre o elemento.
 */
export function Tip({ children, label, color = "blue.500", ...restProps }: TipProps) {
	return (
		<Box position="relative" w="fit-content" {...restProps}>
			{children}
			<Tooltip label={label} hasArrow textAlign="center">
				<Iconify
					icon="ri:question-fill"
					top={-1}
					right={-4}
					position="absolute"
					w={4}
					h={4}
					color={color}
				/>
			</Tooltip>
		</Box>
	);
}
