import React, { Component } from 'react';

export default class componentName extends Component {
    render() {
        var { src, width, height, style } = this.props
        return (
            <div
                style={{
                    ...style,
                    width: width,
                    height: height,
                    borderRadius: height,
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <img alt="" src={src} style={{ height: '100%' }} />
            </div>
        );
    }
}
