import React, { useMemo } from "react";
import { Rating } from "semantic-ui-react";
import imagePromoterDefault from "../../../imagens/imagem-padrao-empresa.png";
import { Avatar, Container, Name, RatingArea, RightContent } from "./styles";

interface CandidatesAreaProps {
    promoter?: {
        nome: string;
        mediaAvaliacao: number;
        quantidadeAvaliacao: number;
        foto?: string;
    };
}

export function CandidatesArea({ promoter }: CandidatesAreaProps) {
    const rantingValue = useMemo(
        () =>
            promoter?.mediaAvaliacao && promoter?.quantidadeAvaliacao
                ? Number((promoter.mediaAvaliacao / promoter.quantidadeAvaliacao).toFixed(2))
                : 0,
        [promoter?.mediaAvaliacao, promoter?.quantidadeAvaliacao]
    );

    if (!promoter) {
        return null;
    }

    return (
        <Container>
            <Avatar src={promoter.foto ? promoter.foto : imagePromoterDefault} />
            <RightContent>
                <Name>{promoter.nome}</Name>
                <RatingArea>
                    <Rating rating={1}></Rating>
                    <span>{rantingValue}</span>
                </RatingArea>
            </RightContent>
        </Container>
    );
}
