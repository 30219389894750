import _, { reduce, size } from "lodash";
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Table, TableFooter, TableRow, TableHeaderCell } from "semantic-ui-react";
import { alterarEstadoDeUmRelatorio } from "../../../_actions/RelatoriosActions";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

/**
 * @name RelatorioDeVisita
 * @date Criado em 22/01/2021
 * @author Vitor Andre Savian
 * @description classe JSX que vai mostrar a tabela de visita
 */

export const LinhaExcel = props => {
	return (
		<tr key={props.loja.uid}>
			<td>{props.loja.nome}</td>
			<td>{props.loja.endereco}</td>
			<td>{props.loja.visitasPendentes}</td>
			<td>{props.loja.visitasRealizadas}</td>
			<td>{props.loja.total}%</td>
		</tr>
	);
};
class RelatorioDeVisita extends Component {
	state = {
		order: {},
		oportunidadesPorLoja: {},
	};

	componentDidMount() {
		this.relatorioDeVisita(this.props.oportunidades, val => this.setState(val));
	}

	/**
	 * @name relatorioDeVisita
	 * @date Criado em 21/01/2021
	 * @author Vitor Andre Savian
	 * @description monta as informçoes que serão usadas no relatório
	 * @param {Object} oportunidades Objeto de oportunidades
	 * @param {Function} setState setState da classe pai
	 */
	relatorioDeVisita = (oportunidades = this.props.oportunidades, setState) => {
		var uidLojas = [];
		var oportunidadesLoja = {};
		var arrayLojas = {};

		oportunidades.docs.forEach(oportunidade => {
			let uid = oportunidade.loja.id;
			if (oportunidadesLoja[uid]) {
				oportunidadesLoja = {
					...oportunidadesLoja,
					[uid]: [...oportunidadesLoja[uid], oportunidade],
				};
			} else {
				oportunidadesLoja = { ...oportunidadesLoja, [uid]: [oportunidade] };
			}
			if (
				_.findIndex(uidLojas, loja => {
					return loja.uid === uid;
				}) < 0
			) {
				uidLojas.push({ uid });
			}
		});

		uidLojas.map(loja => {
			var nome,
				visitasRealizadas = 0,
				visitasPendentes = 0,
				total = 0,
				endereco;

			_.forEach(oportunidadesLoja[loja.uid], (value, key) => {
				nome = value.loja.fantasia;
				endereco = value.loja.endereco.endereco;
				if (value.statusRoteiro.id < 3 || value.statusRoteiro > 6) {
					visitasPendentes++;
				} else {
					visitasPendentes++;
					visitasRealizadas++;
				}
				total = parseFloat((visitasRealizadas * 100) / visitasPendentes).toFixed(2);
			});

			return (arrayLojas = {
				...arrayLojas,
				[loja.uid]: {
					uid: loja.uid,
					nome: nome,
					visitasPendentes: visitasPendentes,
					visitasRealizadas: visitasRealizadas,
					total: total,
					endereco: endereco,
				},
			});
		});

		return setState({ oportunidadesPorLoja: arrayLojas });
	};

	/**
	 * @name changeOrdenar
	 * @date Criado em 22/01/2021
	 * @author Vitor Andre Savian
	 * @description muda a ordenação
	 * @param {String} coluna coluna que será usada para ordenar
	 */
	changeOrdenar = coluna => () => {
		return this.setState({
			order: {
				coluna: coluna,
				ordenar: this.state.order?.ordenar === "ascending" ? "descending" : "ascending",
			},
		});
	};

	render() {
		let { oportunidadesPorLoja, order } = this.state;
		const { t } = this.props;

		const totalLojas = size(oportunidadesPorLoja);
		const totalPendentes = reduce(
			oportunidadesPorLoja,
			(sum, loja) => sum + loja.visitasPendentes,
			0
		);
		const totalRealizadas = reduce(
			oportunidadesPorLoja,
			(sum, loja) => sum + loja.visitasRealizadas,
			0
		);
		const totalPorcentagem = reduce(
			oportunidadesPorLoja,
			(sum, loja) => sum + parseFloat(loja.total),
			0
		);

		return (
			<div>
				<table style={{ display: "none" }} id="table-to-xls" className="ui celled table">
					<thead>
						<tr>
							<th>Loja</th>
							<th>Estado - Endereço</th>
							<th>Visitas Previstas</th>
							<th>Bisitas Realizadas</th>
							<th>Percentual de Visitas</th>
						</tr>
					</thead>
					<tbody>
						{Object.values(oportunidadesPorLoja).map(loja => (
							<Fragment key={loja.uid}>
								<LinhaExcel loja={loja} />
							</Fragment>
						))}
					</tbody>
				</table>

				{!_.isEmpty(oportunidadesPorLoja) ? (
					<div
						style={{
							marginTop: 15,
							paddingRight: 3.5,
							display: "flex",
							justifyContent: "flex-end",
						}}>
						<ReactHTMLTableToExcel
							id="test-table-xls-button"
							className="download-table-xls-button "
							table="table-to-xls"
							filename="Visitas"
							sheet="Oportunidades"
							buttonText="Exportar XLS"
						/>
					</div>
				) : null}
				<Table sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={order.coluna === "nome" ? order.ordenar : null}
								onClick={this.changeOrdenar("nome")}>
								{t("loja.cell")}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{t("estado")} - {t("endereco")}
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={order.coluna === "visitasPendentes" ? order.ordenar : null}
								onClick={this.changeOrdenar("visitasPendentes")}>
								{t("visitas.previstas")}
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={order.coluna === "visitasRealizadas" ? order.ordenar : null}
								onClick={this.changeOrdenar("visitasRealizadas")}>
								{t("visitas.realizadas")}
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={order.coluna === "total" ? order.ordenar : null}
								onClick={this.changeOrdenar("total")}>
								{t("percentual.de.visitas")}
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.isEmpty(oportunidadesPorLoja)
							? null
							: _.orderBy(
									oportunidadesPorLoja,
									[order.coluna],
									[order.ordenar === "descending" ? "desc" : order.ordenar]
								).map(loja => {
									return (
										<Table.Row key={loja.uid}>
											<Table.Cell>{loja.nome}</Table.Cell>
											<Table.Cell>{loja.endereco}</Table.Cell>
											<Table.Cell>{loja.visitasPendentes}</Table.Cell>
											<Table.Cell>{loja.visitasRealizadas}</Table.Cell>
											<Table.Cell>{loja.total}%</Table.Cell>
										</Table.Row>
									);
								})}
					</Table.Body>
					<TableFooter>
						<TableRow>
							<TableHeaderCell>{totalLojas}</TableHeaderCell>
							<TableHeaderCell />
							<TableHeaderCell>{totalPendentes}</TableHeaderCell>
							<TableHeaderCell>{totalRealizadas}</TableHeaderCell>
							<TableHeaderCell>
								{(totalPorcentagem / totalLojas).toFixed(2)}%
							</TableHeaderCell>
						</TableRow>
					</TableFooter>
				</Table>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	oportunidades: state.RelatoriosReducer.oportunidades,
});

export const relatorioDeVisita = new RelatorioDeVisita().relatorioDeVisita;

export default withTranslation()(
	connect(mapStateToProps, {
		alterarEstadoDeUmRelatorio,
	})(RelatorioDeVisita)
);
