import moment from "moment"
import { GASTO_MENSAL } from "./Types"
import { chamarNodeApi } from '../Utils/Api';
import { queriePorDataEStatus } from '../Utils/mongo/queriesMongo';


export const buscarRoteirosMensal = (uid) => async (dispatch) => {
    try {
        const inicioDoMes = moment().startOf('month').format('YYYY-MM-DD');
        const fimDoMes   = moment().endOf('month').format('YYYY-MM-DD');

      const oportunidadesFinalizadas = await chamarNodeApi(
        "roteiro/aggregate",
        {
          search: queriePorDataEStatus({
            uid: uid,
            status:[3 ,5],
            dataInicio: inicioDoMes,
            dataFinal: fimDoMes
        }),
        },
        "POST"
      );
  
      const quantidadeOportunidadesFinalizadas = oportunidadesFinalizadas.length;
      const gastoMensalReal = _.reduce(
        oportunidadesFinalizadas,
        (valor, roteiro) => valor + roteiro.pagamento.valorTotal,
        0
      );
   
      return dispatch({
        type: GASTO_MENSAL,
        payload: {
          oportunidadesFinalizadas: oportunidadesFinalizadas,
          gastoMensalReal,
          quantidadeOportunidadesFinalizadas,
        },
      });
    } catch (erro) {
      console.log(erro);
    }
  };