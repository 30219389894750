import firebase from "firebase";
import { Receipt, ReceiptStatus } from "../../../@types/Receipt";
import { db, storage } from "../../../config";

/**
 * @description Serviço para trabalhar com operações de leitura e escrita de notas fiscais no banco de dados.
 */
export class ReceiptService {
	private static instance: ReceiptService;
	public collectionName = "notasFiscais";

	public static getInstance(): ReceiptService {
		if (!ReceiptService.instance) {
			ReceiptService.instance = new ReceiptService();
		}
		return ReceiptService.instance;
	}

	private constructor() {}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função para deletar uma nota fiscal no sistema.
	 * @param receiptId Id da nota fiscal no banco de dados.
	 * @param storageKey Localização do arquivo no storage.
	 */
	async deleteOne(receiptId: string, storageKey: string) {
		//NOTE: Inicia uma transação no Firestore
		const firestoreBatch = db.batch();

		//NOTE: Busca a nota fiscal e salva uma operação de deleção
		const receiptDocRef = db.collection(this.collectionName).doc(receiptId);
		firestoreBatch.delete(receiptDocRef);

		//NOTE: Busca cada roteiro associado a essa nota e salva uma operação de atualização
		const opportunities = await db
			.collection("roteiros")
			.where("notaFiscal.id", "==", receiptId)
			.get();

		opportunities.forEach(opportunity => {
			firestoreBatch.update(opportunity.ref, {
				"notaFiscal.id": "",
				updatedAt: new Date().toISOString(),
			});
		});

		//NOTE: Perpetua a transação
		await firestoreBatch.commit();

		//NOTE: Deleta o arquivo da nota fiscal se tudo acima deu certo, porém não liga se houver erro na deleção.
		await storage
			.ref(storageKey)
			.delete()
			.catch(error => console.log("Erro ao apagar arquivo de nota fiscal: ", error));
	}

	/**
	 * @description Função para reenviar uma nota fiscal rejeitada para uma reavaliação pelo time da Tradepro.
	 * @param newFile Arquivo a ser enviado para o storage.
	 * @param receiptId Id da nota fiscal
	 * @param storageKey Localização do arquivo no storage
	 * @returns Um objeto de nota fiscal só com os dados que foram atualizados.
	 */
	async resendOne(newFile: File, receiptId: string, storageKey: string) {
		//NOTE: Faz o upload do novo arquivo
		const fileRef = await storage.ref(storageKey).put(newFile);
		const newUrl = (await fileRef.ref.getDownloadURL()) as string;

		//NOTE: Monta um objeto de atualização
		const dataToUpdate: Omit<Partial<Receipt>, "analyzedBy"> & {
			analyzedBy?: firebase.firestore.FieldValue;
		} = {
			status: ReceiptStatus["Enviado para análise"],
			updatedAt: new Date().toISOString(),
			file: {
				storageKey: fileRef.ref.fullPath,
				url: newUrl,
			},
			analyzedBy: firebase.firestore.FieldValue.delete(),
			analyzedAt: "",
			analysisComment: "",
		};

		//NOTE: Atualiza a nota fiscal
		await db.collection(this.collectionName).doc(receiptId).update(dataToUpdate);

		//NOTE: Monta um objeto de retorno
		const dataToReturn: Partial<Receipt> = {
			...dataToUpdate,
			analyzedBy: undefined,
		};

		//NOTE: Retorna o objeto de retorno
		return dataToReturn;
	}
}
