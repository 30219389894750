import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonProps, Icon, Popup } from "semantic-ui-react";

interface ButtonFavoriteProps extends ButtonProps {
	isFavorited: boolean;
}

export function ButtonFavorite({ isFavorited, ...rest }: ButtonFavoriteProps) {
	const { t } = useTranslation();

	return (
		<Popup
			content={isFavorited ? t("desfavoritar.promotor.texto") : t("favoritar.promotor.texto")}
			trigger={
				<Button basic color={isFavorited ? "pink" : "grey"} {...rest}>
					<Button.Content>
						<Icon name="heart" /> {t(isFavorited ? "favoritado" : "favoritar")}
					</Button.Content>
				</Button>
			}
		/>
	);
}
