import {
	Badge,
	Box,
	Button,
	Collapse,
	Divider,
	Flex,
	Heading,
	IconButton,
	Link,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Tfoot,
	Th,
	Thead,
	Tr,
	useBreakpoint,
	useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { Receipt, ReceiptStatus } from "../../@types/Receipt";
import {
	getChakraColoSchemerByReceiptStatus,
	getIconByReceiptStatus,
	getLabelByReceiptStatus,
} from "../../helpers/Receipt";
import { fCurrency } from "../../Utils/Number";
import { PATHS } from "../../Utils/Routes";
import { Card } from "../Card";
import { Iconify } from "../Iconify";
import { ConfirmCancelationOfReceipt } from "./ConfirmCancelationOfReceipt";
import { ConfirmResendOfReceipt } from "./ConfirmResendOfReceipt";

interface CardReceiptProps {
	receipt: Receipt;
}

export function CardReceipt({ receipt }: CardReceiptProps) {
	const { t } = useTranslation();
	const collapseController = useDisclosure({ defaultIsOpen: false });
	const isBaseBreakpoint = useBreakpoint() === "base";
	const isRejected = receipt.status === ReceiptStatus.Recusada;
	const isAccepted = receipt.status === ReceiptStatus.Aceita;

	const statusBadge = useMemo(() => {
		return (
			<Badge
				colorScheme={getChakraColoSchemerByReceiptStatus(receipt.status)}
				p={2}
				rounded="md">
				<Iconify icon={getIconByReceiptStatus(receipt.status)} inline mr={2} />
				{getLabelByReceiptStatus(receipt.status)}
			</Badge>
		);
	}, [receipt.status]);

	return (
		<Card p={0}>
			<Flex
				as="header"
				justifyContent={"space-between"}
				gap={4}
				p={4}
				alignItems={{ base: "stretch", sm: "center" }}
				flexDir={{ base: "column", sm: "row" }}>
				<Box>
					<Heading fontSize={"xl"}>{receipt.consumer.name}</Heading>
					<Flex mt={2} flexDir={"column"} gap={2}>
						<Text lineHeight={1}>
							<b>{t("status")}: </b> {statusBadge}
						</Text>
						<Text lineHeight={1}>
							<b>#ID:</b> {receipt.id}
						</Text>
						<Text lineHeight={1}>
							<b>{t("enviada-em")}:</b>{" "}
							{moment(receipt.createdAt).format("DD/MM/YYYY HH:mm:ss")}
						</Text>
						{(isRejected || isAccepted) && !!receipt.analyzedAt && (
							<Text lineHeight={1}>
								<b>{t("analisada-em")}:</b>{" "}
								{moment(receipt.analyzedAt).format("DD/MM/YYYY HH:mm:ss")}
							</Text>
						)}
						<Link
							w="fit-content"
							href={receipt.file.url}
							isExternal
							fontWeight={"bold"}
							color="purple.500"
							_hover={{ color: "purple.400", textDecoration: "underline" }}>
							<Iconify icon="mi:attachment" inline /> {t("ver-arquivo")}
						</Link>
					</Flex>
				</Box>

				{isBaseBreakpoint ? (
					<Button
						w={"full"}
						onClick={collapseController.onToggle}
						leftIcon={
							<Iconify
								icon={collapseController.isOpen ? "mdi:arrow-up" : "mdi:arrow-down"}
							/>
						}>
						{collapseController.isOpen ? t("ocultar") : t("expandir")}
					</Button>
				) : (
					<IconButton
						rounded={"full"}
						aria-label={t("expandir")}
						onClick={collapseController.onToggle}
						icon={
							<Iconify
								icon={collapseController.isOpen ? "mdi:arrow-up" : "mdi:arrow-down"}
							/>
						}
					/>
				)}
			</Flex>

			<Collapse in={collapseController.isOpen}>
				{isRejected && !!receipt.analysisComment && (
					<>
						<Divider />
						<Box m={4}>
							<Text fontWeight={"bold"}>{t("comentario-do-analista")}:</Text>
							<Box
								shadow={"inner"}
								bg="gray.50"
								p={4}
								rounded="md"
								overflow={"auto"}
								maxH="56">
								{receipt.analysisComment}
							</Box>
						</Box>
					</>
				)}
				<Divider />
				<TableContainer>
					<Table>
						<Thead bg={"gray.100"}>
							<Tr>
								<Th>{t("oportunidade")}</Th>
								<Th>{t("loja")}</Th>
								<Th isNumeric>{t("valor")}</Th>
							</Tr>
						</Thead>
						<Tbody>
							{receipt.opportunities.map(op => {
								return (
									<Tr key={op.id}>
										<Td>
											<Flex alignItems="center" gap={2} my={2}>
												<Flex flexDir={"column"} gap={2}>
													<Text fontSize="sm" lineHeight={1}>
														<Link
															fontWeight={"medium"}
															as={RouterLink}
															color="purple.500"
															_hover={{
																color: "purple.400",
																textDecoration: "underline",
															}}
															to={PATHS.oportunidades.buscar(op.id)}>
															{op.id}
														</Link>
													</Text>
													<Text
														fontSize={"sm"}
														lineHeight={1}
														color="gray.600">{`${moment(op.date).format("DD/MM/YYYY")} - ${op.startedAt} até ${op.finishedAt}`}</Text>
												</Flex>
											</Flex>
										</Td>
										<Td>
											<Box>
												<Text lineHeight={1} mb={2} fontWeight={"medium"}>
													{op.pdv.name}
												</Text>
												<Text
													lineHeight={1}
													color="gray.500"
													fontSize={"sm"}>
													{op.pdv.address}
												</Text>
											</Box>
										</Td>
										<Td isNumeric fontWeight={"medium"} fontSize={"sm"}>
											{fCurrency(op.value)}
										</Td>
									</Tr>
								);
							})}
						</Tbody>
						<Tfoot bg={"gray.100"}>
							<Tr>
								<Th></Th>
								<Th></Th>
								<Th isNumeric fontSize={"sm"}>
									{fCurrency(receipt.value)}
								</Th>
							</Tr>
						</Tfoot>
					</Table>
				</TableContainer>
				{isRejected && (
					<>
						<Divider />
						<Flex
							m={4}
							gap={2}
							justifyContent="space-between"
							flexDir={{ base: "column", sm: "row" }}>
							<ConfirmCancelationOfReceipt receipt={receipt} />
							<ConfirmResendOfReceipt receipt={receipt} />
						</Flex>
					</>
				)}
			</Collapse>
		</Card>
	);
}
