import { logging } from "../../services/Api";
/**
 * @name arquivarProdutos
 * @date Criado em 15/12/2020
 * @author Vitor Savian
 * @description faz a arquivação dos produtos
 * @param { Array } produtos produto que será arquivado
 * @param { Function } firebase
 * @param { Object } empresa informações da empresa
 * @param { Object } ipLocation objeto que contem o ip
 * @param { Function } log função de log
 */
const arquivarProdutos = (produto, firebase, empresa, ipLocation, log = logging) => {
	return firebase
		.firestore()
		.collection("produtos")
		.doc(produto.id)
		.update({ arquivado: true })
		.then(res => {
			log("DELETE", "log.delete.produto", produto.id);
			return res;
		});
};

/**
 * @name percorrerProdutos
 * @date Criado em 15/12/2020
 * @author Vitor Savian
 * @description percorre os produtos para a arquivação
 * @param { Array } produtos que realmente foram escolhidos
 * @param { Function } firebase
 * @param { Object } empresa informações da empresa
 * @param { Object } ipLocation objeto que contem o ip
 */
const percorrerProdutos = (produtos, firebase, empresa, ipLocation) => {
	return produtos.reduce((promise, produto) => {
		return promise.then(() => {
			return arquivarProdutos(produto, firebase, empresa, ipLocation);
		});
	}, Promise.resolve());
};

/**
 * @name excluirProd
 * @date Criado em 15/12/2020
 * @author Vitor Savian
 * @description pega os produtos já juntos e manda para percorrer
 * @param { Array } produtos Lista de produtos para importação
 * @param { Function } firebase
 * @param { Object } empresa informações da empresa
 * @param { Object } ipLocation objeto que contem o ip
 */
export default (produtos, firebase, empresa, ipLocation) =>
	percorrerProdutos(produtos, firebase, empresa, ipLocation);
