import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { HeaderPadrao } from '../../Componentes/Componentes';
import { db } from '../../config';
import * as d from '../../Utils/Debugging';
import { lojaSearchBoxString } from '../../Utils/Loja';
import { downloadLojasDaEmpresa } from '../../Utils/Oportunidades';
import { lojasDaEmpresa } from '../../_actions/RecursoActions';
import { NovaLojaForm } from './NovaLojaForm';

class EditarLoja extends React.Component {
    constructor(props) {
        super(props);
        let searchBox = !props.loja ? '' : lojaSearchBoxString(props.loja);
        this.state = {
            loja: {},
            searchBox,
            carregandoLojas: false
        };
    }

    componentWillMount() {
        d.logPath("Loja");
        if (this.props.lojas === null) {
            this.setState({ carregandoLojas: true }, () => {
                downloadLojasDaEmpresa(
                    this.props.idEmpresa
                ).onSnapshot(
                    snap => {
                        d.logSnapshot("Loja", snap);
                        this.props.dispatch(lojasDaEmpresa(snap));
                        this.setState({ carregandoLojas: false })
                    }
                );
            })
        }
    }

    componentWillUpdate() {
        const loja = this.props.loja;
        this.state.searchBox === '' && loja &&
            this.setState({ searchBox: lojaSearchBoxString(loja) });
    }

    onSubmit = (event) => {
        const { t } = this.props;
        event.preventDefault();
        this.setState({ carregando: true }, () => {
            const loja = { ...this.props.loja, ...this.state.loja };
            d.log("Editar Loja", "adicionando", loja);
            const ref = db.collection('lojas').doc(loja.id);
            ref.update(loja).then(
                () => this.setState({
                    loja: {},
                    carregando: false,
                    salvo: true,
                    erroCodigo: null
                }, this.finalizarEdicao)
            ).catch(err => this.setState({
                erroCodigo: t(err.message),
                carregando: false
            }));
        });
    }

    finalizarEdicao = () => this.props.history.push('/loja');

    formRef = ref => (this.form = ref);

    render() {
        if (this.state.carregandoLojas) {
            return <div style={{ margin: '0 auto', width: '80%' }}>
                <Dimmer active inverted>
                    <Loader inverted>Carregando</Loader>
                </Dimmer>
            </div>
        }
        const _loja = this.props.loja;
        if (_loja === null) {
            return <Redirect to='/dashboard' />
        }
        const { t } = this.props;
        const loja = {
            ...{ endereco: {}, contato: {} },
            ..._loja,
            ...this.state.loja
        };

        const location = (_loja && ({
            lat: loja.endereco.coordenada.latitude,
            lng: loja.endereco.coordenada.longitude,
        })) || null;

        return (
            <div style={{ margin: '0 auto', width: '80%' }}>
                <HeaderPadrao
                    icone='cart plus'
                    titulo={t("editar.loja")}
                    subtitulo={t("editar.informacoes.loja")} />
                <NovaLojaForm
                    scope={this}
                    ref={this.formRef}
                    t={t}
                    editando={true}
                    searchBox={this.state.searchBox}
                    location={location}
                    empresa={this.props.empresa}
                    loja={loja}
                    onSubmit={event => this.onSubmit(event)}>
                </NovaLojaForm>
            </div>
        );
    }
}

const mapStateToProps = d.logState("Editar Loja", (state, props) => {
    const lojas = state.RecursosReducer.lojas;
    const temLojas = _.isArray(lojas);
    return {
        idEmpresa: state.AutenticacaoReducer.empresa.uid,
        empresa: state.AutenticacaoReducer.empresa,
        lojas: lojas,
        loja: temLojas ? lojas.find(
            loja => loja.id === props.match.params.id
        ) : null
    }
});

export default connect(mapStateToProps)(
    withTranslation()(EditarLoja)
);
