import { Tbody } from "@chakra-ui/react";
import { useFieldArray } from "react-hook-form";
import { ReceiptFormProps } from "..";
import { ReceiptCustomerTableRow } from "../ReceiptCustomerTableRow";

export function ReceiptCustomerTableBody() {
	const { fields } = useFieldArray<ReceiptFormProps, "opportunities">({ name: "opportunities" });

	return (
		<Tbody>
			{fields.map((op, index) => {
				return <ReceiptCustomerTableRow key={op.id} opportunity={op} index={index} />;
			})}
		</Tbody>
	);
}
