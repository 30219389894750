import { Box, Button, Text, Tooltip, useDisclosure } from "@chakra-ui/react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Receipt } from "../../../@types/Receipt";
import { useBlocker } from "../../../hooks/store/useBlocker";
import { ReceiptService } from "../../../services/firebase/Receipt";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import { Iconify } from "../../Iconify";

interface ConfirmCancelationOfReceiptProps {
	receipt: Receipt;
}

/**
 * @description Componente para cancelar o envio de uma nota fiscal recusada.
 */
export function ConfirmCancelationOfReceipt({ receipt }: ConfirmCancelationOfReceiptProps) {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const {
		state: { isCancelReceiptBlocked, isSendReceiptFileBlocked },
		utils: { setBlocker },
	} = useBlocker();

	const confirmationController = useDisclosure({ defaultIsOpen: false });

	const { mutate, isLoading } = useMutation({
		mutationFn: () => {
			return ReceiptService.getInstance().deleteOne(receipt.id, receipt.file.storageKey);
		},
		onMutate: () => {
			setBlocker({ isCancelReceiptBlocked: true, isSendReceiptFileBlocked: true });
		},
		onSettled: () => {
			setBlocker({ isCancelReceiptBlocked: false, isSendReceiptFileBlocked: false });
		},
		onSuccess: () => {
			toast.success(t("nota-fiscal-excluida-com-sucesso").concat("!"));

			const cachedData = queryClient.getQueriesData<{
				pages: { data: Receipt[]; totalItems: number | null }[];
			}>(["receipts"]);

			//NOTE: Procura em todos os caches se a nota fiscal excluída está presente e se estiver, remove do cache.
			cachedData.forEach(([queryKey, queryData]) => {
				if (queryData) {
					const updatedQueryData = update(queryData, {
						pages: page => {
							return page.map(data => {
								return update(data, {
									data: {
										$set: data.data.filter(item => item.id !== receipt.id),
									},
									totalItems: totalItems => {
										return (totalItems || 1) - 1;
									},
								});
							});
						},
					});

					queryClient.setQueryData(queryKey, updatedQueryData);
				}
			});
		},
		onError: () => {
			toast.error(t("erro-ao-excluir-a-nota-fiscal-tente-novamente-mais-tarde"));
		},
	});

	const isDisabled = isCancelReceiptBlocked || isSendReceiptFileBlocked;

	return (
		<>
			<ConfirmationDialog
				{...confirmationController}
				onConfirm={mutate}
				title={t("cancelar-envio")}
				message={
					<Box>
						<Text color={"red.500"} lineHeight={1.3}>
							<Iconify icon="mdi:error" inline mr={2} />
							{t("explicacao-exclusa0-notas-fiscais-rejeitadas")}
						</Text>
						<Text>{t("voce-confirma-sua-decisao")}?</Text>
					</Box>
				}
			/>
			<Tooltip textAlign="center" label={t("dica-exclusao-nota-fiscal-rejeitada")} hasArrow>
				<Button
					isDisabled={isDisabled}
					isLoading={isLoading}
					onClick={confirmationController.onOpen}
					colorScheme={"red"}
					w="full"
					leftIcon={<Iconify icon="mdi:close" />}>
					{t("cancelar-envio")}
				</Button>
			</Tooltip>
		</>
	);
}
