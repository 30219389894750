import { Box, Checkbox, Flex, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon, SemanticICONS } from "semantic-ui-react";
import { ReceiptFormProps } from "..";
import { fCurrency } from "../../../../Utils/Number";
import { PATHS } from "../../../../Utils/Routes";

interface ReceiptCustomerTableRowProps {
	opportunity: ReceiptFormProps["opportunities"][number];
	index: number;
}

export function ReceiptCustomerTableRow({ opportunity, index }: ReceiptCustomerTableRowProps) {
	const { t } = useTranslation();
	const { register, watch } = useFormContext<ReceiptFormProps>();

	const fileField = watch("file");

	function getReceiptStatusLabel(status?: number) {
		switch (status) {
			case 0:
				return t("enviado");
			case 1:
				return t("pendente");
			case 2:
				return t("ilegivel");
			case 3:
				return t("valor.incorreto");
			case 4:
				return t("nota.invalida");
			default:
				return t("nao.encontrado");
		}
	}

	return (
		<Tr>
			<Td textAlign="center">
				<Checkbox
					isDisabled={!!fileField}
					size="lg"
					my={2}
					borderColor={"gray.300"}
					{...register(`opportunities.${index}.isChecked`)}
				/>
			</Td>
			<Td>
				<Flex alignItems="center" gap={2} my={2}>
					<Tooltip label={t(opportunity.tipo.descricao)} hasArrow>
						<span>
							<Icon name={opportunity.tipo.icone as SemanticICONS} size="large" />
						</span>
					</Tooltip>
					<Flex flexDir={"column"} gap={2}>
						<Text fontWeight="bold" fontSize="lg" lineHeight={1}>
							{getReceiptStatusLabel(opportunity.notaFiscal.status)}
						</Text>
						<Text fontSize="sm" lineHeight={1}>
							<Link to={PATHS.oportunidades.buscar(opportunity.uid)}>
								{opportunity.uid}
							</Link>
						</Text>
						<Text
							lineHeight={1}
							color="gray.600">{`${moment(opportunity.data).format("DD/MM/YYYY")} - ${opportunity.horaInicio} até ${opportunity.horaFim}`}</Text>
					</Flex>
				</Flex>
			</Td>
			<Td>
				<Box my={2}>
					<Text fontWeight="bold" fontSize="lg" lineHeight={1} mb={2}>
						{opportunity.loja.fantasia}
					</Text>
					<Text lineHeight={1} color="gray.600">
						{opportunity.loja.endereco.endereco}
					</Text>
				</Box>
			</Td>
			<Td isNumeric>
				<Text fontWeight="bold">{fCurrency(opportunity.pagamento.valorPromotor)}</Text>
			</Td>
		</Tr>
	);
}
