import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import facebook from "../../Externo/img/icon-facebook.svg";
import instagram from "../../Externo/img/icon-instagram.svg";
import linkedin from "../../Externo/img/icon-linkedin.svg";
import promotor from "../../imagens/logo-nome.svg";
import { FacebookLink, InstagramLink, LinkedinLink } from "../../Utils/Constantes";

import firebase from "firebase";
import { Center, HStack, Image, Text, VStack } from "@chakra-ui/react";

var conf = require("../../configuration.json");

const termosEmpresa = "Termos-De-Uso/Termos_de_uso_Empresa.pdf";
const termosAgencia = "Termos-De-Uso/Termos_de_uso_Agencia.pdf";

/**
 * @name Footer
 * Componente do footer do sistema.
 */
class Footer extends Component {
	termos = () =>
		firebase
			.storage()
			.ref(this.props.modulo && this.props.modulo === 1 ? termosEmpresa : termosAgencia)
			.getDownloadURL()
			.then(url => window.open(url))
			.catch(error => console.log(error));

	render() {
		const { t } = this.props;
		return (
			<div className="footer">
				<hr />
				<Grid columns={4} padded textAlign="center" stackable verticalAlign="middle">
					<Grid.Column verticalAlign="middle">
						<VStack>
							<Image
								src={promotor}
								alt="Promotor"
								w="194px"
								h="45x"
								objectFit="cover"
							/>
							<Text>
								{t("versao")} {conf.version.split("-")[0]}
							</Text>
						</VStack>
					</Grid.Column>

					<Grid.Column verticalAlign="middle" style={{}}>
						<HStack justifyContent={"center"}>
							<a href={FacebookLink} target="_blank" rel="noreferrer noopener">
								<img src={facebook} width="26" height="25" alt="Facebook" />
							</a>
							<a
								href={LinkedinLink}
								className="ml-3 mr-3"
								target="_blank"
								rel="noreferrer noopener">
								<img src={linkedin} width="26" height="25" alt="LinkedIn" />
							</a>
							<a href={InstagramLink} target="_blank" rel="noreferrer noopener">
								<img src={instagram} width="26" height="25" alt="Instagram" />
							</a>
						</HStack>
					</Grid.Column>

					<Grid.Column>
						{/* <h1 style={{ color: "#662780", fontSize: 22, fontWeight: 600 }}>{t('links.uteis')}</h1> */}
						<a href="/feedback">
							<p style={{ fontSize: 16, color: "black" }}>{t("feedback")}</p>
						</a>
						<a
							href="https://www.youtube.com/watch?v=Ox2cvHgK040"
							target="_blank"
							rel="noreferrer noopener">
							<p style={{ fontSize: 16, color: "black" }}>
								{t("video.apresentacao")}
							</p>
						</a>
					</Grid.Column>

					<Grid.Column>
						{/* <h1 style={{ color: "#662780", fontSize: 22, fontWeight: 600 }}>{t('politica.de.privacidade')}</h1> */}
						<div onClick={this.termos} style={{ cursor: "pointer" }}>
							<p style={{ fontSize: 16, color: "black" }}>
								{t("termos.e.condicoes")}
							</p>
						</div>
					</Grid.Column>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		modulo: state.AutenticacaoReducer.modulo,
	};
};

export default connect(mapStateToProps)(withTranslation()(Footer));
