import { Divider } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { noop } from "lodash";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router";
import { toast } from "react-toastify";
import { OperationArea } from "..";
import { OperationArea as OperationAreaType } from "../../../@types/OperationArea";
import { PageHeader } from "../../../Componentes/PageHeader";
import { GenericError } from "../../../errors/GenericError";
import {
	OperationAreaFormProps,
	useUpsertOperationAreaForm,
} from "../../../hooks/forms/useUpsertOperationAreaForm";
import { useOperationArea } from "../../../hooks/query/useOperationArea";
import { useAuth } from "../../../hooks/store/useAuth";
import { useConfigs } from "../../../hooks/store/useConfigs";
import { OperationAreaService } from "../../../services/OperationArea";
import { PATHS } from "../../../Utils/Routes";

export default function CreateOperationArea() {
	const { t } = useTranslation();
	const history = useHistory();
	const { quantMaxAreasDeAtuacao } = useConfigs();
	const { company } = useAuth();

	const queryClient = useQueryClient();

	const {
		blockers: { isCreateOperationAreaBlocked },
	} = useOperationArea();

	/**
	 * Função que realiza a operação de criação de uma nova área de atuação.
	 */
	function handleCreateOperationArea(data: OperationAreaFormProps) {
		return toast.promise(
			async () => {
				//NOTE: Se o shape for nulo, retorna um erro (nunca vais er segundo a validação zod feita pelo react-hook-form, porém isso garante que a tipagem esteja correta).
				if (!data.geometricShape) {
					throw new GenericError(t("crie-ao-menos-uma-area"));
				}

				//NOTE: Se a empresa for inexistente, retorna um erro.
				if (!company) {
					throw new GenericError(t("empresa-inexistente"));
				}

				//NOTE: Verifica se o limite de áreas de atuação foi ultrapassado.
				const currentCountOperationArea =
					await OperationAreaService.getInstance().countDocs(company.uid);

				if (currentCountOperationArea >= quantMaxAreasDeAtuacao) {
					throw new GenericError(
						t("aviso-quant-max-areas-de-atuacao", {
							quantMaxAreasDeAtuacao,
						})
					);
				}

				//NOTE: Cria a área de atenção se tudo acima for correto.
				return OperationAreaService.getInstance().createOne({
					name: data.name,
					description: data.description,
					geometry: data.geometricShape,
					companyId: company.uid,
					color: data.color,
					zoomLevel: data.zoomLevel,
				});
			},
			{
				pending: t("criando-area-de-atuacao").concat("..."),
				success: t("area-criada-com-sucesso"),
				error: {
					render: ({ data, toastProps }) => {
						if (data instanceof GenericError) {
							toastProps.type = "warning";
							return data.message;
						}

						return t("erro-ao-criar-a-area-de-atuacao-tente-novamente-mais-tarde");
					},
				},
			}
		);
	}

	/**
	 * Mutação com react-query para conseguirmos criar uma nova área de atuação local sem realizar novas requisições
	 */
	const { mutateAsync: createOperationAreaMutation } = useMutation({
		mutationFn: handleCreateOperationArea,
		onSuccess: data => {
			const cachedData = queryClient.getQueryData<OperationAreaType[]>([
				"operationAreas",
				company?.uid,
			]);

			//NOTE: Adicionando o novo registro se tiver dados anteriores.
			if (cachedData) {
				queryClient.setQueryData(["operationAreas", company?.uid], [...cachedData, data]);
			}

			history.push(PATHS.areaDeAtuacao.root);
		},
	});

	/**
	 * Função que será passada para o envio do formulário pelo react-hook-form.
	 */
	const onSubmit: SubmitHandler<OperationAreaFormProps> = data =>
		createOperationAreaMutation(data).catch(noop); //NOTE: ATENÇÃO: a função `mutateAsync` continua perpetuando o erro, mesmo ele já sendo tratado pelo react-query. Por isso ignoramos o erro que está por vir.

	const { formContent } = useUpsertOperationAreaForm({ onSubmit });

	if (isCreateOperationAreaBlocked) {
		toast.info(
			t("aviso-quant-max-areas-de-atuacao", {
				quantMaxAreasDeAtuacao,
			})
		);

		return <Redirect to={PATHS.areaDeAtuacao.root} />;
	}

	return (
		<OperationArea.Layout>
			<PageHeader
				title={t("nova-area-de-atuacao")}
				subtitle={t("subtitulo-criar-area-de-atuacao")}
				icon="mdi:map-plus"
				breadcrumb={[
					{ name: "Dashboard", to: PATHS.dashboard.root },
					{ name: t("areas-de-atuacao"), to: PATHS.areaDeAtuacao.root },
					{ name: t("nova-area-de-atuacao") },
				]}
			/>
			<Divider />
			{formContent}
		</OperationArea.Layout>
	);
}
