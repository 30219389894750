import firebase from "firebase";
import Configuration from "./configuration";

const url = "promotor.com.br";

export const redirectAfterPasswordReset = `http://${url}/login`;

firebase.initializeApp(Configuration.firebase);

firebase.analytics();

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();

export const projectId = Configuration.firebase.projectId;
export const mainTable =
	projectId + (projectId === "promotor-desenvolvimento" ? ".promotordev" : ".promotor");
