import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';

export const MensagemSalvoContent = props => (
    <>
        <Icon name='check' size='massive' />
        <h1>{props.t("concluido")}</h1>
        <h3>{props.mensagem || props.t("clique.para.novo.registro")}</h3>
    </>
);

export default props => (
    <Segment color='green' inverted
        style={{ 'height': '70vh' }}
        onClick={() => props.novoRegistro()}>
        <div style={{ 'textAlign': 'center', 'position': 'relative', 'top': '50%', 'transform': 'translateY(-50%)' }}>
            <MensagemSalvoContent t={props.t} mensagem={props.mensagem} />
        </div>
    </Segment>
);