import moment from "moment";
import { v4 } from "uuid";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Header, Segment, Message } from "semantic-ui-react";
import { Opportunity, OpportunityStatus } from "../../../../@types/Opportunity";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { NotificationService } from "../../../../services/firebase/Notification";
import { contaVerificadaIugu } from "../../../../Utils/Auth";
import { cancelarCandidatura, candidatarAgencia } from "../../../../Utils/Roteiros";
import { Company } from "../../../../@types/Company";
import { Button, Checkbox, Divider } from "@chakra-ui/react";
import { Iconify } from "../../../../Componentes/Iconify";
import { useNegotiationContext } from "../../../../contexts/Negotiation";

interface CandidateControllerProps {
	roteiro: Opportunity;
	bloqueioPorNotasPendentes: boolean;
}

/**
 * Controla as opções de candidatura de uma agência.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function CandidateController({
	roteiro,
	bloqueioPorNotasPendentes,
}: CandidateControllerProps) {
	const [checkboxState, setCheckboxState] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { t } = useTranslation();
	const { foundNegotiation } = useNegotiationContext();
	const empresa = useAppSelector(state => state.AutenticacaoReducer).empresa as Company;

	/**
	 * Verifica se a empresa logada teve sua conta verificada.
	 */
	const contaVerificada = contaVerificadaIugu(empresa.iugu.verificadoIugu);

	/**
	 * Verifica se a empresa logada é criadora da oportunidade.
	 */
	const currentOpportunityIsMy = roteiro.idEmpresa === empresa.uid;

	/**
	 * Verifica se a empresa logada tem candidatura na oportunidade.
	 */
	const isCandidate = useMemo(
		() => roteiro.candidatos?.includes("E-" + empresa.uid),
		[empresa.uid, roteiro.candidatos]
	);

	function handleChangeCheckboxState() {
		setCheckboxState(oldState => !oldState);
	}

	async function handleCandidate() {
		try {
			setIsLoading(true);

			await candidatarAgencia(roteiro.id, empresa.uid);

			setCheckboxState(false);

			await NotificationService.getInstance()
				.createOne({
					id: v4(),
					data: moment().format("YYYY-MM-DD"),
					idRoteiro: roteiro.id,
					idEmpresa: roteiro.idEmpresa,
					titulo: t("novo.candidato"),
					link: `/oportunidade/info/${roteiro.id}`,
					descricao: t("nova.candidatura", {
						nome: empresa.fantasia,
						loja: roteiro.loja.fantasia,
					}),
					visto: 0,
				})
				.catch(e => {
					console.error("Erro ao enviar notificação: ", e);
				});
		} catch (error) {
			console.error(error);
			toast.error(t("ocorreu-um-erro-ao-se-candidatar-tente-novamente-mais-tarde"));
		} finally {
			setIsLoading(false);
		}
	}

	async function handleCancelCandidate() {
		try {
			setIsLoading(true);
			await cancelarCandidatura(roteiro.id, empresa.uid);
		} catch (error) {
			console.error(error);
			toast.error(t("ocorreu-um-erro-ao-cancelar-a-candidatar-tente-novamente-mais-tarde"));
		} finally {
			setIsLoading(false);
		}
	}

	if (foundNegotiation) {
		return null;
	}

	//NOTE: Se a conta ainda não estiver verificada, exibimos um aviso
	if (!contaVerificada) {
		return (
			<div>
				<Divider />
				<div style={{ textAlign: "center", padding: "40px 0" }}>
					<p>{t("sua.conta.esta.em.verificacao")}</p>
					<p>{t("sua.conta.esta.em.verificacao2")}</p>
					<p>{t("sua.conta.esta.em.verificacao3")}</p>
				</div>
			</div>
		);
	}

	if (roteiro.statusRoteiro.id !== OpportunityStatus["Aguardando Candidatos"]) {
		return (
			<p style={{ textAlign: "center", marginBottom: "10px", fontSize: 17 }}>
				<span style={{ color: "green" }}>{t("nao.pode.candidatar")}</span>
				<br />
				<span style={{ color: "black" }}>{t("oportunidade.indisponivel")}</span>
			</p>
		);
	}

	if (isCandidate)
		return (
			<Segment color={"green"} textAlign={"center"} style={{ marginBottom: "10px" }}>
				<span style={{ color: "green", fontSize: "16px" }}>{t("voce.se.candidatou")}</span>
				<br />
				<span style={{ color: "black", fontSize: "12px" }}>{t("aguarde.aprovacao")}</span>
				<br />
				<Button
					onClick={handleCancelCandidate}
					mt={2}
					colorScheme="red"
					w="full"
					leftIcon={<Iconify icon="mdi:close" />}
					isLoading={isLoading}>
					{t("cancelar-candidatura")}
				</Button>
			</Segment>
		);

	if (currentOpportunityIsMy)
		return (
			<div style={{ marginTop: 10, fontSize: 13 }}>
				<Message color="red">
					{t("nao.e.possivel.se.candidatar.em.suas.oportunidades")}
				</Message>
			</div>
		);

	if (bloqueioPorNotasPendentes) {
		return (
			<div>
				<Segment color="red">
					<Header as="h3" textAlign="center" style={{ color: "#dc3545" }}>
						{t("notas.pendentes")}
					</Header>
				</Segment>
			</div>
		);
	}

	return (
		<div>
			<Checkbox
				style={{ fontSize: "13px" }}
				onChange={handleChangeCheckboxState}
				isChecked={checkboxState}>
				{t("confirmacao.candidatar")}
			</Checkbox>
			<Button
				onClick={handleCandidate}
				mt={2}
				w="full"
				colorScheme={"purple"}
				isDisabled={!checkboxState}
				leftIcon={<Iconify icon="mdi:check" />}
				isLoading={isLoading}>
				{t("candidatarse")}
			</Button>
		</div>
	);
}
