import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";
import { FileCompressError } from "../../errors/File.erros";
import { compressImage } from "../../Utils/File.utils";
import { Avatar } from "../Avatar";
import { ButtonChangePhoto, Container, InputFile } from "./styles";

interface AvatarUploadProps {
	defaultSrc?: string;
	circular?: boolean;
	isOnlyView?: boolean;
	isLoading?: boolean;
	autoSize?: boolean;
	borderRadius?: number;
	control: {
		file: File | null;
		setFile: (data: File | null) => void;
	};
}

const acceptFiles = ["image/png", "image/jpeg"];

export function AvatarUpload({
	defaultSrc,
	circular = false,
	isOnlyView = false,
	isLoading = false,
	autoSize = false,
	borderRadius = 0,
	control: { file, setFile },
}: AvatarUploadProps) {
	const inputRef = useRef<HTMLInputElement>(null);
	const [src, setSrc] = useState(defaultSrc ? defaultSrc : "");

	async function handleChangeFile(event: React.ChangeEvent<HTMLInputElement>) {
		const data = event.target.files?.item(0);
		if (data) {
			if (acceptFiles.includes(data.type)) {
				try {
					const compressedFile = await compressImage(data);
					setSrc(URL.createObjectURL(compressedFile));
					setFile(compressedFile as File);
				} catch (e) {
					if (e instanceof FileCompressError) {
						console.log(e);
						toast.warning(e.message);
					}
				}
			} else {
				console.log("Somente arquivos .png e .jpeg são aceitos.");
			}
		}
		event.target.value = "";
	}

	const handleResetStates = useCallback(() => {
		setSrc(defaultSrc ? defaultSrc : "");
		setFile(null);
	}, [defaultSrc, setFile]);

	useEffect(() => {
		isOnlyView && handleResetStates();
	}, [isOnlyView, handleResetStates]);

	return (
		<Container autoSize={autoSize} borderRadius={borderRadius}>
			<Avatar src={src} circular={circular} autoSize borderRadius={borderRadius} />
			{!isOnlyView && (
				<>
					<ButtonChangePhoto
						icon
						circular
						onClick={() => (file ? handleResetStates() : inputRef.current?.click())}
						disabled={isLoading}
						loading={isLoading}>
						<Icon name={file ? "undo" : "photo"} />
					</ButtonChangePhoto>
					<InputFile
						ref={inputRef}
						onChange={handleChangeFile}
						multiple={false}
						accept={acceptFiles.join(",")}
					/>
				</>
			)}
		</Container>
	);
}
