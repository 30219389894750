import firebase from "firebase";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Header, Icon, Button } from "semantic-ui-react";
import { ModalDeConfirmacao } from "../../Componentes/ConfirmarRegistro";
import { DataTable, Column } from "../../Componentes/Tabela";

/**
 * @name deletarModelo
 * @date Criado em 31/03/2021
 * @description deleta o modelo
 * @param {String} id id do modelo
 */
const deletarModelo = id => firebase.firestore().collection("modelosRoteiro").doc(id).delete();

/**
 * @name buscarModelos
 * @date Criado em 01/04/2021
 * @description buscar os modelos
 * @param {String} id id do modelo
 * @param {Function} setModelos seta o modelo para a listagem
 * @param {Function} setCarregando seta o carregando para a listagem
 */
const buscarModelos = (empresa, setModelos, setCarregando) => {
	return firebase
		.firestore()
		.collection("modelosRoteiro")
		.where("roteiro.idEmpresa", "==", empresa.uid)
		.onSnapshot(snap => {
			setModelos(
				_.orderBy(
					snap.docs.map(doc => doc.data()),
					["modelosRoteiro", "nome"],
					["asc"]
				)
			);
			setCarregando(false);
		});
};

/**
 * @name ListarModelos
 * @date Criado em 31/03/2021
 * @description Listar Modelos na tela
 */
export default function ListarModelos(props) {
	const [modelos, setModelos] = useState([]);
	const [carregando, setCarregando] = useState(false);
	const [confirmar, setConfirmar] = useState(false);
	const [modeloAtivo, setModeloAtivo] = useState(null);

	const { t, empresa } = props;
	const { selecionarModelo, voltarParaOpcoes, selecionarModeloEditar } = props.actions;

	const clickBotao = id => {
		setConfirmar(!confirmar);
		setModeloAtivo(id);
	};

	useEffect(() => {
		setCarregando(true);
		buscarModelos(
			empresa,
			val => setModelos(val),
			val => setCarregando(val)
		);
	}, [empresa]);

	return (
		<>
			<HeaderModelos t={t} />
			<div style={{ margin: "0 auto", width: 700 }}>
				{carregando ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<div
							className="ui inverted text loader relancar-roteiro-loader"
							style={{ display: "block" }}>
							<p style={{ color: "#ff6f00" }}>{t("carregando.modelos")}</p>
						</div>
					</div>
				) : (
					<TabelaDeModelos
						t={t}
						modelos={modelos}
						modeloAtivo={modeloAtivo}
						selecionarModelo={selecionarModelo}
						voltarParaOpcoes={voltarParaOpcoes}
						toggleConfirm={clickBotao}
						selecionarModeloEditar={selecionarModeloEditar}
						openConfirm={confirmar}
					/>
				)}
			</div>
		</>
	);
}

/**
 * @name HeaderModelos
 * @date Criado em 31/03/2021
 * @description Header dos modelos
 */
const HeaderModelos = props => {
	const { t } = props;
	return (
		<Header
			as="h1"
			style={{
				margin: "20px 0 40px",
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
			}}>
			<Header.Content style={{ color: "#7501c0", fontSize: "28px", fontWeight: "bold" }}>
				{t("escolher.modelo")}
				<Header.Subheader style={{ fontSize: "22px", color: "#999999" }}>
					{t("escolher.modelo.subtitulo")}
				</Header.Subheader>
			</Header.Content>
		</Header>
	);
};

/**
 * @name TabelaDeModelos
 * @date Criado em 31/03/2021
 * @description Retorna a tabela com os modelos
 */
const TabelaDeModelos = ({
	t,
	modelos,
	selecionarModelo,
	voltarParaOpcoes,
	toggleConfirm,
	openConfirm,
	modeloAtivo,
	selecionarModeloEditar,
}) => (
	<>
		<DataTable dataSource={modelos}>
			<Column
				label={t("nome")}
				property="nome"
				cell={props => <span style={{ flexGrow: 1 }}>{props.data}</span>}
			/>
			<Column
				label={t("secoes")}
				property="roteiro.atividades"
				cell={props =>
					props.rowData.roteiro.secoes ? (
						<span>{Object.keys(props.rowData.roteiro.secoes).length}</span>
					) : (
						<></>
					)
				}
			/>
			<Column
				label=""
				cell={props => (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}>
						<Button
							basic
							icon="checkmark"
							content={t("selecionar")}
							onClick={event => selecionarModelo(props.rowData)}
						/>
						{/* <Button
                        basic
                        icon="pencil"
                        content={"Editar"}
                        onClick={event => selecionarModeloEditar(props.rowData)} /> */}
						<ModalDeConfirmacao
							t={t}
							isOpen={openConfirm && props.rowData.id === modeloAtivo}
							titulo={t("apagar.modelo")}
							message={
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										textAlign: "center",
										marginBottom: 10,
										marginTop: -15,
									}}>
									<p>{t("apagar.modelo.msg")}</p>
									<span>{props.rowData.nome}</span>
								</div>
							}
							onCancel={() => toggleConfirm(null)}
							onConfirm={() =>
								deletarModelo(props.rowData.id)
									.then(() => toggleConfirm())
									.catch(() => toggleConfirm())
							}
						/>
						<button
							style={{
								background: "none",
								border: "none",
								fontSize: 20,
								outline: "none",
								cursor: "pointer",
								paddingRight: 1,
							}}
							onClick={() => selecionarModeloEditar(props.rowData)}>
							<Icon name="pencil" />
						</button>
						<button
							style={{
								background: "none",
								border: "none",
								fontSize: 20,
								outline: "none",
								cursor: "pointer",
								paddingLeft: 1,
							}}
							onClick={() => toggleConfirm(props.rowData.id)}>
							<Icon name="trash alternate outline" />
						</button>
					</div>
				)}
			/>
		</DataTable>
		<Button basic icon="arrow left" content={t("voltar")} onClick={() => voltarParaOpcoes()} />
	</>
);
