import { useCallback } from "react";
import { Blocker } from "../../@types/Blocker";
import { actions } from "../../store/slices/blockerSlice";

import { useAppDispatch } from "../useAppDispatch";
import { useAppSelector } from "../useAppSelector";

export const useBlocker = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.blocker);

    const setBlocker = useCallback(
        (blocker: Partial<Blocker>) => {
            dispatch(actions.setData(blocker));
        },
        [dispatch]
    );

    const { data, ...stateRest } = state;

    return { state: { ...stateRest, ...data }, actions, dispatch, utils: { setBlocker } };
};
