import React, { Component } from "react";
import Currency from "react-currency-formatter";
import { withTranslation } from "react-i18next";
import { Button, Grid, Modal } from "semantic-ui-react";
import ImagemCentralizada from "../../../Componentes/ImagemCentralizada";
import { db } from "../../../config";
import { logging } from "../../../services/Api";
import { contraProposta } from "../../Financeiro/Financeiro";
import { chamarFunction } from "../../../Utils/Api";

/**
 * Classe responsável por confirmar a vontade do usuário em selecionar determinado candidato
 * para executar a oportunidade em questão.
 * Autor: Renahn Schultz
 */
class ConfirmarSelecao extends Component {
	state = {
		carregando: false,
		openModal: false,
	};

	/**
	 * Cancela a seleção do candidato retornando o roteiro sem aprovar ninguém.
	 * Autor: Renahn Schultz
	 */
	cancelarSelecao = () => {
		var roteiro = this.props.roteiro;
		delete roteiro.usuario;
		roteiro.statusRoteiro = { id: 1 };
		logging("DELETE", "log.erro.selecao.promotor", roteiro.id);
		this.props.callback({ roteiro, confirmarAprovacao: false });
	};

	/**
	 * Atualiza o roteiro no firebase.
	 * Caso ocorra erro, chama método de cancelar seleção.
	 * As actions retornadas ao callback são responsáveis pela abertura dos modais pós aprovação.
	 * Autor: Renahn Schultz
	 */
	confirmarSelecao = () => {
		const { roteiro, callback, empresa, ipLocation, dadosDocumentos } = this.props;
		this.setState({ carregando: true }, () =>
			db
				.collection("roteiros")
				.doc(roteiro.id)
				.update(roteiro)
				.then(() => {
					this.setState({ carregando: false });
					logging(
						"CREATE",
						"log.selecao.promotor",
						`Roteiro: ${roteiro.id}, Promotor: ${roteiro.usuario.uid}`
					);
					callback({
						roteiro,
						confirmarAprovacao: false,
						actions: ["enviarNotificacao", "anexarDocumento"],
					});
					chamarFunction("enviarDocumentos", dadosDocumentos);
				})
				.catch(() => {
					this.setState({ carregando: false });
					this.cancelarSelecao();
				})
		);
	};

	handleOpenModal = () => {
		this.setState({ openModal: !this.state.openModal });
	};

	render() {
		const { carregando } = this.state;
		const { faturamentoMensal, limite, empresa, saldos, roteiro, t, workTime } = this.props;
		const contraproposta = contraProposta(
			roteiro.usuario.valorSolicitado,
			roteiro.pagamento.percentualTaxa
		);
		const gastos = limite?.gastos;
		let maximo = empresa?.valorMaximoOportunidades ?? 0;
		let restante = faturamentoMensal
			? isNaN(maximo - gastos)
				? 0
				: maximo - gastos
			: saldos.naoReservado;

		return (
			<>
				<p className="header-confirma-aprovacao">{t("confirmar.selecao.candidato")}</p>
				<Grid stackable>
					<Grid.Column width={5}>
						<div style={{ width: 110, float: "right" }}>
							<ImagemCentralizada
								src={roteiro.usuario.foto}
								style={{ borderRadius: 10 }}
							/>
						</div>
					</Grid.Column>
					<Grid.Column width={11}>
						<p className="tit-padrao">{roteiro.usuario.nome}</p>
						<p className="sub-padrao">{roteiro.usuario.sexo}</p>
						<p className="sub-padrao">
							{t("media.avaliacao")}:{" "}
							{roteiro.usuario.mediaAvaliacao && roteiro.usuario.quantidadeAvaliacao
								? (
										roteiro.usuario.mediaAvaliacao /
										roteiro.usuario.quantidadeAvaliacao
									).toFixed(2)
								: 0}
						</p>
						<p className="sub-padrao">{workTime.humanized}</p>
					</Grid.Column>
					<Grid.Column width={16} textAlign="center">
						{faturamentoMensal === true && (
							<div style={{ paddingBottom: 20 }}>
								<p className="tit-padrao">{t("seu.faturamento.mensal")}</p>
								<p className="sub-padrao">{t("info.faturamento")}</p>
							</div>
						)}
						{faturamentoMensal !== true && (
							<>
								{roteiro.usuario.valorSolicitado === 0 ||
								roteiro.usuario.valorSolicitado === undefined ? (
									<div>
										<p className="sub-padrao">
											{t("seu.saldo.atual")}:{" "}
											<Currency
												quantity={
													saldos && saldos.naoReservado
														? saldos.naoReservado
														: 0
												}
												decimal=","
												group="."
												currency="BRL"
											/>
										</p>
									</div>
								) : (
									<div>
										<p className="tit-padrao">{t("contra.proposta.aviso")}</p>
										<p className="tit-padrao">
											{t("seu.saldo.sera.reservado")}:{" "}
											<Currency
												quantity={contraproposta}
												decimal=","
												group="."
												currency="BRL"
											/>
										</p>
									</div>
								)}
							</>
						)}

						{faturamentoMensal === true && (
							<div>
								<p className="sub-padrao">
									{t("seu.limite.atual")}:{" "}
									<Currency
										quantity={restante}
										decimal=","
										group="."
										currency="BRL"
									/>
								</p>
							</div>
						)}
					</Grid.Column>
					{restante < this.props.roteiro.valor && (
						<Grid.Column width="16" textAlign="center">
							<p className="header-confirma-aprovacao">Limite insuficiente</p>
						</Grid.Column>
					)}
					{restante >= this.props.roteiro.valor && (
						<>
							<Grid.Column width="8" textAlign="right">
								<Button
									disabled={carregando}
									basic
									content={t("cancelar")}
									color="red"
									onClick={this.cancelarSelecao}
								/>
							</Grid.Column>
							<Grid.Column width="8" textAlign="left">
								<Button
									disabled={carregando}
									loading={carregando}
									content={t("confirmar.selecao")}
									color="green"
									icon="check"
									onClick={this.confirmarSelecao}
								/>
							</Grid.Column>
						</>
					)}
				</Grid>
			</>
		);
	}
}

export default withTranslation()(ConfirmarSelecao);
