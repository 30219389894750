import PptxGenJS from "pptxgenjs";
import { atividadesCtor } from './Atividades';
import {
    Foto, FotoDeReposicao, FotoDeSaidaDeLoja, FotoFachada, Pergunta, PesquisaDePreco, PesquisaDeValidade, Ruptura
} from './PPT/Atividade';
import { frontPage } from './PPT/Capa';
import { imagensDaAtividadeP } from './Roteiros';


/**
 * Paper size (10.00", 5.63", landscape)
 * Each text box has a padding of 1", so when you are positioning the text,
 * it is actually x + 1".
 */
const verificarRuptura = comp => (pptx, atividade, roteiro, assets) =>
    (atividade.hasOwnProperty('existeProduto') && !atividade.existeProduto ? Ruptura : comp)(
        pptx, atividade, roteiro, assets
    );

export const atividadePages = atividadesCtor(
    verificarRuptura(Pergunta),
    verificarRuptura(PesquisaDePreco),
    verificarRuptura(PesquisaDeValidade),
    verificarRuptura(Foto),
    verificarRuptura(FotoDeSaidaDeLoja),
    verificarRuptura(FotoFachada),
    verificarRuptura(FotoDeReposicao)
);

export const generateAtividadePage = (pptx, atividade, roteiro, assets) =>
    atividadePages(atividade.descricao)(pptx, atividade, roteiro, assets);

export const createPPT = context => {
    const pptx = new PptxGenJS();
    pptx.setLayout('LAYOUT_16x9');
    pptx.setAuthor('Promotor');
    pptx.setCompany('Promotor');
    pptx.setRevision('1');
    pptx.setSubject('Relatório');
    pptx.setTitle(`Relatório ${"x"}`);
    return Promise.resolve({ ...context, err: null, pptx });
};

export const makePages =
    ctx => Object.keys(ctx.atividades).reduce(
        (p, atividadeName) => ctx.atividades[atividadeName].reduce(
            (p, atividade) => p.then(ctx => imagensDaAtividadeP(atividade).then(
                atividade => ({
                    ...ctx,
                    err: null,
                    pptx: generateAtividadePage(ctx.pptx, atividade, ctx.roteiro, ctx.assets)
                })
            ).catch(
                err => Promise.reject({ ...ctx, err })
            )),
            p
        ),
        Promise.resolve(ctx)
    );

export const makeFrontPage = context => {
    frontPage(context.pptx, context.roteiro, context.assets)
    return context
};