import { useMediaQuery } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Content, Header, OpportunityCard, SubHeader, Text } from "./styles";

interface Opportunity {
    id: string;
    data: string;
    horaFim: string;
    horaInicio: string;
    loja: {
        fantasia: string;
        endereco: {
            endereco: string;
        };
    };
}

interface TooltipOpportunitiesPerPromoterProps {
    children: React.ReactElement;
    opportunities: Opportunity[] | undefined;
}

export function TooltipOpportunitiesPerPromoter({ children, opportunities }: TooltipOpportunitiesPerPromoterProps) {
    const { t } = useTranslation();
    const maxWidth765 = useMediaQuery("(max-width:765px)");

    return (
        <Container
            on={["click", "hover"]}
            trigger={children}
            offset={[0, -10]}
            position={maxWidth765 ? "bottom center" : "right center"}
            hoverable>
            {opportunities?.length ? (
                <>
                    <Header>{t("oportunidades.ja.confirmadas")}</Header>
                    <SubHeader>
                        {`${opportunities.length} ${t("oportunidades.confirmadas.para")} ${moment(
                            opportunities[0].data
                        ).format("DD/MM/YYYY")}`}
                    </SubHeader>
                    <Content>
                        {opportunities.map((opportunity) => (
                            <OpportunityCard key={opportunity.id}>
                                <Text isTitle>{opportunity.loja.fantasia}</Text>
                                <Text>
                                    <span>{t("hora")}: </span>
                                    {`${opportunity.horaInicio} ${t("ate")} ${opportunity.horaFim}`}
                                </Text>
                                <Text>
                                    <span>{t("endereco")}: </span>
                                    {opportunity.loja.endereco.endereco}
                                </Text>
                            </OpportunityCard>
                        ))}
                    </Content>
                </>
            ) : (
                t("sem.oportunidades.para.essa.data")
            )}
        </Container>
    );
}
