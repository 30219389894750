import { Popup, PopupProps } from "semantic-ui-react";
import styled from "styled-components";

export const Container: React.FC<PopupProps> = styled(Popup)`
    padding: 10px !important;
`;

export const ContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
