import { useTranslation } from "react-i18next";
import { Button, ButtonProps, Icon, Popup } from "semantic-ui-react";

export function ButtonAgencyLink({ ...rest }: ButtonProps) {
	const { t } = useTranslation();

	return (
		<Popup
			content={t("desvincular.agencia.texto")}
			trigger={
				<Button basic color={"violet"} {...rest}>
					<Button.Content>
						<Icon name="user times" /> {t("desvincular.agencia")}
					</Button.Content>
				</Button>
			}
		/>
	);
}
