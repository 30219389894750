import { Collapse } from "@material-ui/core";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Message } from "semantic-ui-react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { LogService } from "../../services/Log";
import { LogListFilter, StatusType } from "./LogListFilter";
import { LogListHeader } from "./LogListHeader";
import { LogListTable } from "./LogListTable";

export default function LogList() {
	//NOTE: States
	const [page, setPage] = useState(1);
	const [filterText, setFilterText] = useState("");
	const [dateFilter, setDateFilter] = useState<ParsableDate>(null);
	const [statusFilter, setStatusFilter] = useState<StatusType[]>([]);
	const [showFilterController, setShowFilterController] = useState(false);

	const countFiltersInUse = useMemo(() => {
		let filtersInUse = 0;
		if (filterText) filtersInUse++;
		if (dateFilter) filtersInUse++;
		if (statusFilter.length > 0) filtersInUse++;
		return filtersInUse;
	}, [dateFilter, filterText, statusFilter.length]);

	//NOTE: Hooks
	const { t } = useTranslation();
	const empresa = useAppSelector(state => state.AutenticacaoReducer).empresa;

	//NOTE: Constant
	const itemsPerPage = 20;

	//NOTE: react-query
	const { isLoading, isFetching, isError, error, data, refetch } = useQuery({
		queryKey: ["logs", page, empresa.uid, itemsPerPage, filterText, statusFilter, dateFilter],
		queryFn: () =>
			LogService.getInstance().fetchLogsPaginated(
				empresa.uid,
				page,
				itemsPerPage,
				filterText,
				dateFilter,
				statusFilter
			),
		keepPreviousData: true,
		staleTime: 1000 * 60 * 2, // 2 minutos até precisar fazer fetch de novo.
		meta: {
			errorMessage: t("ocorreu-um-erro-ao-buscar-os-logs-tente-novamente-mais-tarde"),
		},
	});

	//NOTE: Caso o número de páginas seja maior que a página atual, redireciona para a primeira.
	useEffect(() => {
		if ((data?.totalPages || 0) < page) {
			setPage(1);
		}
	}, [data?.totalPages, page]);

	//NOTE: Render
	return (
		<Container>
			<LogListHeader
				refetch={refetch}
				isFetching={isFetching}
				setShowFilterController={setShowFilterController}
				countFiltersInUse={countFiltersInUse}
			/>

			<Collapse in={showFilterController}>
				<LogListFilter
					dateFilter={dateFilter}
					setDateFilter={setDateFilter}
					setFilterText={setFilterText}
					isFetching={isFetching}
					statusFilter={statusFilter}
					setStatusFilter={setStatusFilter}
				/>
			</Collapse>

			{isError && (
				<Message
					error
					header={t("nao-foi-possivel-obter-os-logs-os-motivos-mais-comuns-sao")}
					list={[t("erro-log-motivo-indisponivel"), t("erro-log-sem-conexao")]}
					content={`${t("indicacao-para-chamar-suporte-com-mensagem-de-erro")}: \n"${(error as Error).message}"`}
				/>
			)}

			{!isError && (
				<LogListTable
					isFetching={isFetching}
					isLoading={isLoading}
					data={data}
					itemsPerPage={itemsPerPage}
					page={page}
					setPage={setPage}
				/>
			)}
		</Container>
	);
}
