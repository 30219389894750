import { Zoom } from '@material-ui/core';
import React from 'react';
import Currency from 'react-currency-formatter';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import { ImagemCircular } from '../../Componentes/Componentes';
import { iconePorStatus } from './DashboardComponents';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'
import moment from 'moment'

/**
 * Exibe cada linha de oportunidade dos detalhes do dia
 * @param {oportunidade a ser detalhada} oportunidade 
 * @param {posicao da oportunidade} index 
 * @param {posicao da oportunidade exibida em rightbox, usada para destacar na lista} pos 
 */
export const OportunidadeInfo = ({ oportunidade, oportunidade: { loja, usuario }, index, pos, modulo }) => {
    const { t } = useTranslation();
    const acesso = modulo === 2 ? '/buscar/' : '/oportunidade/info/'
    const avaliacao = usuario?.mediaAvaliacao && usuario?.quantidadeAvaliacao && !isNaN(usuario.mediaAvaliacao / usuario.quantidadeAvaliacao) ? (usuario.mediaAvaliacao / usuario.quantidadeAvaliacao).toFixed(1) : 5

    return (
        <Zoom key={index} in={oportunidade ? true : false} mountOnEnter unmountOnExit style={{ transitionDelay: ((50 + (index * 40)) + 'ms') }}>
             <Grid stackable>
                <Link
                    className={`oportunidade-info ${index === pos ? 'now' : ''}`}
                    to={`${acesso}${oportunidade._id}`}>
                    <div className='box-icon' >
                        <Icon name={iconePorStatus[oportunidade.statusRoteiro.id]} />
                    </div>
                    <div className='pdv'>
                        <span>{loja.fantasia}</span>
                        <span>{loja.endereco.endereco.split('-').slice(0, 2)}</span>
                    </div>
                    {oportunidade.usuario ?
                        <div className='promotor'>
                            <ImagemCircular size='pequeno' src={usuario.foto}></ImagemCircular>
                            <div className='info-promotor'>
                                <span>{usuario.nome}</span>
                                {usuario.sexo === 'Agência' ?
                                    <span className='info-promotor-stars'>{usuario.sexo}</span>
                                    :
                                    <span className='info-promotor-stars'><Icon name='star' /> {avaliacao}</span>}
                            </div>
                        </div>
                        : oportunidade.candidatos ?
                            <div className='tipo'>
                                <span>{oportunidade.candidatos.length}</span>
                                <span>{t('candidatos')}</span>
                            </div>
                            : <div className='tipo-sem-candidatos'>
                                <span>{t('sem.candidatos')}</span>
                                <span></span>
                            </div>
                    }
                    <div className='tipo'>
                        <span>{t(oportunidade.tipo.descricao)}</span>
                        <span>{t('tipo')}</span>
                    </div>
                    {!_.isEmpty(oportunidade?.secoes ?? {}) ?
                        <div className='secoes'>
                            <span>{Object.keys(oportunidade.secoes).length}</span>
                            <span>{t('secoes')}</span>
                        </div> :
                        <div className='secoes'>
                            <span>{moment.duration(moment(oportunidade.horaFim, 'HH:mm').diff(moment(oportunidade.horaInicio, 'HH:mm'), 'hour', true), 'hour').humanize()}</span>
                            <span>Estimado</span>
                        </div>
                    }
                    <div className='price'>
                        <Currency
                            quantity={oportunidade.valor}
                            locale="pt_BR"
                            decimal=','
                            group='.'
                            currency="R$ "
                        />
                    </div>
                </Link>
            </Grid>
        </Zoom>
    )
}