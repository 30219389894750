import React from "react";
import { SemanticICONS } from "semantic-ui-react";

import { Container, Icon } from "./styles";

interface IconAreaProps {
    icon: SemanticICONS;
}

export function IconArea({ icon }: IconAreaProps) {
    return (
        <Container>
            <Icon name={icon} />
        </Container>
    );
}
