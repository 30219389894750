import {
	Box,
	Button,
	Flex,
	Grid,
	Stat,
	StatArrow,
	StatArrowProps,
	StatLabel,
	StatNumber,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import moment from "moment";
import { useMemo } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { Company } from "../../../@types/Company";
import { Negotiation, NegotiationStatus, Proposal } from "../../../@types/Negotiation";
import { useModalControllerContext } from "../../../contexts/ModalControllerContext";
import { BackendApiError } from "../../../errors/BackendApi.error";
import { useBlocker } from "../../../hooks/store/useBlocker";
import { useAccountBalance } from "../../../hooks/useAccountBalance";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { NotificationService } from "../../../services/firebase/Notification";
import { chamarFunction } from "../../../Utils/Api";
import { fCurrency } from "../../../Utils/Number";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import { CollapseNewProposal } from "../CollapseNewProposal";

interface NegotiationBallonProps {
	previousValue?: number;
	data: Proposal;
	/**
	 * Determina se é a proposta que está valendo atualmente (última no array)
	 */
	isActive: boolean;
	negotiation: Negotiation;
	executor?: Company | null;
}

export function NegotiationBallon({
	data,
	isActive,
	previousValue,
	negotiation,
	executor,
}: NegotiationBallonProps) {
	const { t } = useTranslation();

	const company = useAppSelector(state => state.AutenticacaoReducer).empresa as Company;

	const { balanceIsSufficient } = useAccountBalance(data.valor);

	const {
		state: { isAcceptProposalBlocked, isSendCounterProposalBlocked },
		utils: { setBlocker },
	} = useBlocker();

	const {
		isOpen: isOpenCollapse,
		onClose: onCloseCollapse,
		onToggle: onToggleCollapse,
	} = useDisclosure();
	const {
		isOpen: isOpenConfirmation,
		onOpen: onOpenConfirmation,
		onClose: onCloseConfirmation,
	} = useDisclosure();
	const mainModalController = useModalControllerContext();

	const isMyProposal = company.uid === data.idProponente;

	const buttonConfirmValue = t("aceitar");

	const previousValueIsHigher = (previousValue || 0) > data.valor;

	/**
	 * Variável que determina se o usuário atual foi quem criou a negociação inicial.
	 *
	 * Importante para determinar se a nova proposta foi feita pela contratante ou pelo suposto contratado.
	 */
	const amITheNegotiationCreator = negotiation.idCriadorOportunidade === company.uid;

	/**
	 * Variável que diz se vamos exibir o menu de botões do balão.
	 *
	 * No geral só exibimos se:
	 *
	 * 1° For a proposta mais recente;
	 *
	 * 2° Não for a proposta do usuário logado;
	 *
	 * 3° O status da negociação deve ser `PENDENTE`;
	 */
	const showButtonMenu =
		isActive && !isMyProposal && negotiation.status === NegotiationStatus.PENDENTE;

	/**
	 * Variavel que determina qual vai ser a cor do ícone de incremento ou decremento do valor da proposta.
	 *
	 * No geral, como estamos falando de valores monetários entre um contratante e um contratado:
	 *
	 * Para agência(contratado), aumento é bom, diminuição é ruim.
	 *
	 * Para a criadora da oportunidade(contratante), aumento é ruim, diminuição é bom.
	 */
	const statArrowColor = useMemo<StatArrowProps["color"]>(() => {
		if (amITheNegotiationCreator) {
			return previousValueIsHigher ? "green.500" : "red.500";
		} else {
			return previousValueIsHigher ? "red.500" : "green.500";
		}
	}, [amITheNegotiationCreator, previousValueIsHigher]);

	/**
	 * @description Função que aceita uma proposta.
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 */
	function handleAcceptProposal() {
		const promise = async () => {
			try {
				mainModalController.onClose();
				setBlocker({
					isAcceptProposalBlocked: true,
					isFinishNegotiationBlocked: true,
					isSendCounterProposalBlocked: true,
				});

				await chamarFunction("Negotiation-acceptProposal", {
					idNegociacao: negotiation.id,
					idOportunidade: negotiation.roteiro.id,
				});

				/**
				 * Define o id da empresa que deve ser notificada;
				 */
				const idCompanyToNotify =
					negotiation.idExecutor === company.uid
						? negotiation.idCriadorOportunidade
						: negotiation.idExecutor;

				//NOTE: Envia notificação para a outra empresa que faz parte da negociação
				await NotificationService.getInstance()
					.createOne({
						id: v4(),
						data: moment().format("YYYY-MM-DD"),
						titulo: "Sua proposta foi aceita!",
						descricao: `A empresa ${company.fantasia}, aceitou sua proposta.`,
						idEmpresa: idCompanyToNotify,
						link: amITheNegotiationCreator
							? `/buscar/${negotiation.roteiro.id}`
							: `/oportunidade/info/${negotiation.roteiro.id}`,
						idRoteiro: negotiation.roteiro.id,
						visto: 0,
					})
					.catch(e => console.error("Erro ao enviar notificação: ", e));
			} finally {
				setBlocker({
					isAcceptProposalBlocked: false,
					isFinishNegotiationBlocked: false,
					isSendCounterProposalBlocked: false,
				});
			}
		};

		return toast.promise(promise, {
			error: {
				render({ data, toastProps }) {
					const error = new BackendApiError(data);

					toastProps.type = error.httpStatusCode >= 500 ? "error" : "warning";

					return error.message;
				},
			},
			pending: t("aceitando-proposta").concat("..."),
			success: t("proposta-aceita-com-sucesso").concat("!"),
		});
	}

	return (
		<Flex
			alignItems={isMyProposal ? "flex-end" : "flex-start"}
			w="full"
			flexDirection="column"
			gap={2}>
			<Flex
				gap={3}
				flexDirection="column"
				bg={isMyProposal ? "purple.500" : "gray.100"}
				p={3}
				borderRadius="lg"
				boxShadow="md"
				position="relative"
				w={{ base: "full", sm: "fit-content" }}
				minW={{ base: 0, sm: "md" }}
				color={isMyProposal ? "white" : "gray.900"}>
				<Text alignSelf={"self-end"} fontSize="smaller">
					{moment(data.criadoEm).format("DD/MM/YYYY HH:mm")}
				</Text>

				{!!data.descricao && <Text>{data.descricao}</Text>}

				<Box borderRadius="lg" w="full">
					<Stat>
						<StatLabel>{t("valor")}</StatLabel>
						<StatNumber>
							{previousValue && (
								<StatArrow
									type={previousValueIsHigher ? "decrease" : "increase"}
									color={statArrowColor}
								/>
							)}
							{fCurrency(data.valor)}
						</StatNumber>
					</Stat>
				</Box>

				{showButtonMenu && (
					<>
						<Grid
							w={"full"}
							gap={2}
							templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)" }}>
							<Button
								isDisabled={isSendCounterProposalBlocked}
								onClick={onToggleCollapse}
								colorScheme={"blue"}
								leftIcon={<Icon icon="solar:chat-round-money-bold" inline />}
								flex={{ base: undefined, sm: undefined }}>
								{t("contraproposta")}
							</Button>
							<Button
								isDisabled={isAcceptProposalBlocked}
								colorScheme={"green"}
								leftIcon={<Icon icon="el:ok-sign" inline />}
								onClick={onOpenConfirmation}>
								{buttonConfirmValue}
							</Button>
							<ConfirmationDialog
								onClose={onCloseConfirmation}
								isOpen={isOpenConfirmation}
								onConfirm={handleAcceptProposal}
								confirmButtonText={buttonConfirmValue}
								confirmButtonProps={{
									isDisabled: amITheNegotiationCreator && !balanceIsSufficient,
								}}
								message={
									<Flex flexDir={"column"} gap={2} textAlign="justify">
										{amITheNegotiationCreator && !balanceIsSufficient ? (
											<Text lineHeight={1.2}>
												{t(
													"aviso-saldo-insuficiente-para-aceitar-proposta"
												)}
											</Text>
										) : (
											<>
												<Text lineHeight={1.2}>
													{ReactHtmlParser(
														t("aviso-aceitar-proposta", {
															nomeBotao: buttonConfirmValue,
															valorProposta: fCurrency(data.valor),
															proponente: !amITheNegotiationCreator
																? t("voce").toUpperCase()
																: executor?.fantasia,
														})
													)}
												</Text>
												<Text lineHeight={1.2}>
													{t("voce-tem-certeza-de-sua-escolha")}
												</Text>
											</>
										)}
									</Flex>
								}
							/>
						</Grid>
						<CollapseNewProposal
							isOpen={isOpenCollapse}
							onClose={onCloseCollapse}
							proposal={data}
							amITheNegotiationCreator={amITheNegotiationCreator}
						/>
					</>
				)}
			</Flex>
		</Flex>
	);
}
