// import _ from 'lodash';
import React, { Component } from "react";
import { Editor } from "../../Componentes/SunEditor";
import { Button, Form, Header, Radio, Message } from "semantic-ui-react";
import { v4 } from "uuid";

/**
 * @name handleOpcao
 * @date Alterado em 24/11/2020
 * @author Vitor Andre Savian
 * @description Troca a descrição da opção de multipla escolha
 * se caso não tiver index aceito, retorna as opções do jeito que tava
 * @param {Object} e objeto do target
 * @param {Object} opcoes objeto das opcoes da multipla escolha
 */
export const handleOptions = (e, opcoes) => {
    const {
        target: { name, value },
    } = e;
    var arrayOpcoes = [...opcoes];
    var index = name - 1;
    if (index < 0 || index > 5) {
        return arrayOpcoes;
    } else {
        arrayOpcoes[index].descricao = value;
        return arrayOpcoes;
    }
};

/**
 * @name AtividadeMultiplaEscolha
 * @date Alterado em 01/01/2021
 * @author Vitor Andre Savian
 * @description Devolve o JSX da atividade multipla escolha
 */
class AtividadeMultiplaEscolha extends Component {
    state = {
        titulo: "",
        descricao: "",
        peso: "",
        erro: false,
        opcoes: [
            { descricao: "", id: 1 },
            { descricao: "", id: 2 },
            { descricao: "", id: 3 },
        ],
        correta: 1,
        tipo: "me",
    };

    componentDidMount() {
        this.setState({ ...this.props.atividade, id: this.props.atividade?.id ?? v4() });
    }

    adicionarOpcao = () => {
        var opcoes = this.state.opcoes;
        opcoes.push({ descricao: "", id: opcoes.length + 1 });
        this.setState({ opcoes });
    };

    handleCorreta = (id) => () => this.setState({ correta: id });

    /**
     * @name handleOpcao
     * @date Criado em 24/11/2020
     * @author Vitor Andre Savian
     * @description Direciona para o setState as opções
     * @param {Object} e objeto do target
     * @param {Object} arrayOpcoes objeto das opcoes da multipla escolha
     */
    handleSetOption = (e, arrayOpcoes) => {
        var opcoes = handleOptions(e, arrayOpcoes);
        this.setState({ opcoes });
    };

    handleInput = ({ target: { name, value } }) => {
        if (name === "peso") {
            this.setState({ [name]: parseFloat(value) });
        } else {
            this.setState({ [name]: value });
        }
    };

    /**
     * @name handleInputDescricao
     * @date Criado em 1/01/2021
     * @author Vitor Andre Savian
     * @description Define a descrição do component do sun editor
     * @param {String} descricao string que vem do suneditor
     */
    handleInputDescricao = (descricao) => {
        this.setState({ descricao: descricao });
    };

    /**
     * @name onSubmit
     * @description cria a atividade de multipla escolha
     */
    onSubmit = () => {
        if (this.state.descricao === "") {
            return this.setState({ erro: true });
        } else {
            this.setState({ erro: false });
            const atividade = { ...this.state };
            this.props.salvar(atividade);
        }
    };

    render() {
        const { opcoes, correta, titulo, descricao, peso, erro } = this.state;
        var BotaoCancelar = this.props.cancelar;
        console.log("descricao", descricao);
        return (
            <>
                <Header content="Multipla Escolha" as="h2" />
                <Form onSubmit={this.onSubmit}>
                    <Form.Input
                        fluid
                        name="titulo"
                        label="Título"
                        placeholder="Título da atividade que pode remeter a outra atividade (aparecerá com mais destaque)"
                        required
                        value={titulo}
                        onChange={this.handleInput}
                    ></Form.Input>
                    <Editor
                        required={true}
                        placeholder="Digite aqui um texto, pergunta ou afirmação que será respondido pelas opções abaixo."
                        label="Descrição"
                        name="descricao"
                        value={descricao}
                        handleInputDescricao={this.handleInputDescricao}
                    />
                    {/* <Form.TextArea required name="descricao" label='Descrição' placeholder="Digite aqui um texto, pergunta ou afirmação que será respondido pelas opções abaixo." value={descricao} onChange={this.handleInput} /> */}
                    <Form.Input
                        fluid
                        name="peso"
                        label="Peso"
                        placeholder="Valor desta questão"
                        min="0"
                        max="10"
                        value={peso}
                        required
                        type="number"
                        onChange={this.handleInput}
                    ></Form.Input>
                    <Header content="Opções" />
                    {opcoes.map((opcao) => (
                        <div
                            key={opcao.id}
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: 10,
                            }}
                        >
                            <Radio
                                style={{ margin: 10 }}
                                name="correta"
                                toggle
                                value={opcao.id}
                                onChange={this.handleCorreta(opcao.id)}
                                checked={opcao.id === correta}
                            />
                            <div style={{ flex: 1 }}>
                                <Form.Input
                                    placeholder={"Opção " + opcao.id}
                                    required
                                    value={opcao.descricao}
                                    onChange={(e) => this.handleSetOption(e, opcoes)}
                                    name={opcao.id}
                                />
                            </div>
                        </div>
                    ))}
                    {opcoes.length < 5 && (
                        <Button content="Adicionar Opção" type="button" onClick={this.adicionarOpcao}></Button>
                    )}
                    {erro && (
                        <Message negative content="A atividade deve ir com descrição para facilitar o entendimento." />
                    )}
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                        <BotaoCancelar />
                        <Button content="Salvar Atividade" color="green"></Button>
                    </div>
                </Form>
            </>
        );
    }
}

export default AtividadeMultiplaEscolha;
