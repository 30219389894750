import { Icon } from "@iconify/react";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 18px;
`;

export const OptionsWrapper = styled.main`
	display: flex;
	flex-direction: column;
	gap: 28px;
	justify-content: center;

	@media (min-width: 600px) {
		flex-direction: row;
	}
`;

export const CollapseWrapper = styled.div`
	margin-top: 18px;
`;

export const OptionIcon = styled(Icon).attrs({ inline: true })`
	width: 30px;
	height: 30px;
`;

export const OptionButton = styled(Button).attrs({
	color: "violet",
	type: "button",
})`
	display: flex !important;
	gap: 4px !important;
	align-items: center !important;
	justify-content: center !important;
	font-weight: 500 !important;
	min-width: 148px;
`;
