import { Dispatch, SetStateAction, useMemo } from "react";
import { Container, OptionButton } from "./styles";
import { FinishOpportunityOptions } from "..";
import { useTranslation } from "react-i18next";
import { Opportunity } from "../../../@types/Opportunity";
import { some } from "lodash";

interface ModalFinishOpportunityOfRepositionAndSearchProps {
    setOption: Dispatch<SetStateAction<FinishOpportunityOptions | null>>;
    option: FinishOpportunityOptions | null;
    roteiro: Opportunity;
}

export function ModalFinishOpportunityOfRepositionAndSearch({
    setOption,
    option,
    roteiro,
}: ModalFinishOpportunityOfRepositionAndSearchProps) {
    const { t } = useTranslation();

    const optionButtonToFinalizedSections = useMemo(() => {
        //NOTE: Se houver pelo menos uma seção com finalizadoEm e finalizado renderizamos esse botão
        if (some(roteiro.secoes, (section) => section.finalizadoEm && section.finalizado)) {
            return (
                <OptionButton
                    onClick={() => setOption(FinishOpportunityOptions.OPPORTUNITY_LAST_SECTION_TIME)}
                    $isSelected={option === FinishOpportunityOptions.OPPORTUNITY_LAST_SECTION_TIME}
                >
                    {t("horario-de-termino-da-ultima-secao")}
                </OptionButton>
            );
        }

        //NOTE: Se não, renderizamos esse
        return (
            <OptionButton
                onClick={() => setOption(FinishOpportunityOptions.INITIAL_TIME_SUM_ESTIMED_TIME)}
                $isSelected={option === FinishOpportunityOptions.INITIAL_TIME_SUM_ESTIMED_TIME}
            >
                {t("horario-de-inicio-da-oportunidade-tempo-estimado")}
            </OptionButton>
        );
    }, [option, roteiro.secoes, setOption, t]);

    return (
        <Container>
            <p>
                {t("para-oportunidades-de-pesquisa-e-reposicao-voce-pode-escolher")}:
                <span style={{ color: "red" }}>*</span>
            </p>
            <div style={{ display: "flex", gap: 4, justifyContent: "space-evenly" }}>
                <OptionButton
                    onClick={() => setOption(FinishOpportunityOptions.CURRENT_TIME)}
                    $isSelected={option === FinishOpportunityOptions.CURRENT_TIME}
                >
                    {t("horario-atual")}
                </OptionButton>
                {optionButtonToFinalizedSections}
            </div>
        </Container>
    );
}
