import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Flex,
	GridItem,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Company } from "../../../../@types/Company";
import { Negotiation, NegotiationStatus } from "../../../../@types/Negotiation";
import { Opportunity, OpportunityStatus } from "../../../../@types/Opportunity";
import { ModalShowOpportunityNegotiation } from "../../../../Componentes/ModalShowOpportunityNegotiation";
import { ModalStartOpportunityNegotiation } from "../../../../Componentes/ModalStartOpportunityNegotiation";
import { NegotiationNewsStatus } from "../../../../Componentes/NegotiationsList/NegotiationNewsStatus";
import { useNegotiationContext } from "../../../../contexts/Negotiation";
import { isCoordinateWithinPolygonArea } from "../../../../helpers/GeographyCalculation";
import { getNegotiationStatusInfo } from "../../../../helpers/Negotiation";
import { useOperationArea } from "../../../../hooks/query/useOperationArea";
import { useAuth } from "../../../../hooks/store/useAuth";
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface AlertAboutNegotiationProps {
	opportunity: Opportunity;
}

/**
 * Renderiza um componente de `Alert` informando que existe uma negociação associada a uma oportunidade.
 *
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function AlertAboutNegotiation({ opportunity }: AlertAboutNegotiationProps) {
	const { foundNegotiation } = useNegotiationContext();
	const company = useAppSelector(state => state.AutenticacaoReducer.empresa) as Company;
	const {
		queryMethods: { data },
	} = useOperationArea();

	/**
	 * `true` se a coordenada do roteiro estiver dentro de pelo menos uma área de atuação e `false` caso contrário.
	 */
	const isWithinOperationArea = useMemo(() => {
		const coord = {
			lat: opportunity.loja.endereco.coordenada._lat,
			long: opportunity.loja.endereco.coordenada._long,
		};

		//NOTE: Se a coordenada do roteiro estiver dentro de pelo menos uma área de atuação, retorna true, se não false.
		return !!data?.some(area =>
			isCoordinateWithinPolygonArea(coord.lat, coord.long, area.geometry)
		);
	}, [
		data,
		opportunity.loja.endereco.coordenada._lat,
		opportunity.loja.endereco.coordenada._long,
	]);

	//NOTE: Se a oportunidade estiver com a seleção automática ativa, não exibe o alerta
	if (opportunity.selecaoAutomatica || opportunity.selecaoAutomaticaFavorito) {
		return null;
	}

	//NOTE: Se a agência atual estiver como candidato, não é possível iniciar uma negociação.
	if (opportunity.candidatos?.includes(`E-${company.uid}`)) return null;

	//NOTE: Se não houver negociação e a oportunidade não estiver mais pendente, não exibimos o alerta.
	if (
		!foundNegotiation &&
		opportunity.statusRoteiro.id !== OpportunityStatus["Aguardando Candidatos"]
	)
		return null;

	//NOTE: Se a coordenada do roteiro não estiver em nenhuma área de atuação, não exibimos o alerta
	if (!isWithinOperationArea) {
		return (
			<GridItem colSpan={{ md: 2, base: 1 }}>
				<VariantIsNotWithinOperationArea />
			</GridItem>
		);
	}

	return (
		<GridItem colSpan={{ md: 2, base: 1 }}>
			{foundNegotiation ? (
				<VariantHasNegotiation foundNegotiation={foundNegotiation} />
			) : (
				<VariantDoNotHaveNegotiation opportunity={opportunity} />
			)}
		</GridItem>
	);
}

interface VariantHasNegotiationProps {
	foundNegotiation: Negotiation;
}

function VariantHasNegotiation({ foundNegotiation }: VariantHasNegotiationProps) {
	const { t } = useTranslation();
	const { company } = useAuth();

	const negotiationStatusInfo = getNegotiationStatusInfo(
		foundNegotiation?.status || NegotiationStatus["PENDENTE"]
	);

	return (
		<Alert
			textAlign="center"
			position="relative"
			status={negotiationStatusInfo.status}
			justifyContent="space-between"
			rounded="lg"
			shadow="base"
			gap={2}
			flexDir={{ md: "row", base: "column" }}
			overflow="visible">
			<Flex flexDir={{ md: "row", base: "column" }} alignItems="center">
				<AlertIcon />
				<AlertTitle>
					{t("ha-uma-negociacao-associado-a-oportunidade").concat("!")}
				</AlertTitle>
				<AlertDescription>
					{t("com-status")}: <b>"{negotiationStatusInfo.label}"</b>
				</AlertDescription>
			</Flex>
			<ModalShowOpportunityNegotiation
				executor={company || undefined}
				negotiation={foundNegotiation}
				buttonProps={{
					colorScheme: negotiationStatusInfo.colorScheme,
					variant: "ghost",
					width: "fit-content",
					leftIcon: undefined,
					flex: "initial",
					size: "sm",
				}}
			/>
			<NegotiationNewsStatus
				position="absolute"
				top={-3}
				right={3}
				negotiation={foundNegotiation}
			/>
		</Alert>
	);
}

interface VariantNoHasNegotiationProps {
	opportunity: Opportunity;
}

function VariantDoNotHaveNegotiation({ opportunity }: VariantNoHasNegotiationProps) {
	const { t } = useTranslation();
	const company = useAppSelector(state => state.AutenticacaoReducer.empresa) as Company;

	/**
	 * Calcula o valor sugerido para a agência baseado no valor por hora informado por ela.
	 */
	const totalBasedOnHourlyRate = company.valorPorHora
		? company.valorPorHora * (Number(opportunity.tempoEstimado) / 60)
		: 0;

	return (
		<Alert
			textAlign="center"
			position="relative"
			status={"info"}
			justifyContent="space-between"
			rounded="lg"
			shadow="base"
			gap={2}
			flexDir={{ md: "row", base: "column" }}
			overflow="visible">
			<Flex flexDir={{ md: "row", base: "column" }} alignItems="center">
				<AlertIcon />
				<Box textAlign={{ md: "left", base: "center" }}>
					<AlertTitle>{t("ei-que-tal-negociar")}</AlertTitle>
					<AlertDescription>
						{t(
							"usando-essa-modalidade-o-valor-pode-ser-negociado-com-a-criadora-da-oportunidade"
						)}
					</AlertDescription>
				</Box>
			</Flex>
			<ModalStartOpportunityNegotiation
				agency={company}
				opportunity={opportunity}
				totalValue={totalBasedOnHourlyRate}
				buttonProps={{
					colorScheme: "blue",
					variant: "ghost",
					width: "fit-content",
					leftIcon: undefined,
					flex: "initial",
					size: "sm",
					children: t("negociar"),
				}}
			/>
		</Alert>
	);
}

function VariantIsNotWithinOperationArea() {
	const { t } = useTranslation();

	return (
		<Alert
			textAlign="center"
			position="relative"
			status={"warning"}
			justifyContent="space-between"
			rounded="lg"
			shadow="base"
			gap={2}
			flexDir={{ md: "row", base: "column" }}
			overflow="visible">
			<Flex flexDir={{ md: "row", base: "column" }} alignItems="center">
				<AlertIcon />
				<Box textAlign={{ md: "left", base: "center" }}>
					<AlertTitle>{t("titulo-oportunidade-fora-da-area-de-atuacao")}</AlertTitle>
					<AlertDescription>
						{t("aviso-oportunidade-fora-da-area-de-atuacao")}
					</AlertDescription>
				</Box>
			</Flex>
		</Alert>
	);
}
