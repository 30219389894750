import { isThisISOWeek } from "date-fns";
import _, { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Currency from "react-currency-formatter";
import { connect } from "react-redux";
import {
	Button,
	Checkbox,
	Form,
	Grid,
	Icon,
	Input,
	Message,
	Popup,
	Radio,
	Segment,
	TextArea,
	Label,
	Divider,
} from "semantic-ui-react";
import {
	DataDaAtividadeInput,
	HorarioDaAtividadeInput,
	LojaDropdown,
} from "../../Componentes/NovoRoteiroInputs";
import Secao from "../../Componentes/Secao";
import { ValorRoteiro } from "../../Componentes/ValorRoteiro";
import { tipoDeRoteiroToOption } from "../../Utils/Oportunidades";
import ModalSecao from "./ModalSecao";
import ModalTreinamento from "./ModalTreinamento";
import { tiposDeRoteiros } from "./Roteiro";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Text } from "@chakra-ui/react";

const epis = ["oculos", "botas", "luvas", "protetor_auricular", "mascara", "capacete"];

export const NoResultsMessage = props => (
	<a target="_blank" rel="noopener noreferrer" href={`/loja/novo/${props.nome}`}>
		{props.t("criar.pdv").replace(/{nome}/, props.nome === "" ? "" : `: ${props.nome}`)}
	</a>
);

class RoteiroForm extends Component {
	state = {
		searchInput: null,
		checkRecorrencia: false,
		openSecaoModal: false,
		openModalTreinamento: false,
		epis: epis,
		options: false,
	};

	componentDidMount() {
		this.verificaSePossuiAutomatico();
	}

	verificaSePossuiAutomatico = () => {
		const { roteiro } = this.props;

		if (roteiro.selecaoAutomatica || roteiro.selecaoAutomaticaFavorito) {
			this.setState({ options: true });
		}
	};

	lojasOptions = () =>
		this.props.lojas.map(loja => ({
			key: loja.id,
			value: loja,
			text: loja.fantasia,
			content: (
				<div key={loja.id}>
					<span>{loja.fantasia}</span>
					<br />
					<p style={{ fontWeight: "normal", fontSize: 12, opacity: 0.6 }}>
						{loja.endereco.endereco}
					</p>
				</div>
			),
		}));

	tiposDeRoteirosOptions = () => tiposDeRoteiros.map(tipoDeRoteiroToOption(this.props.t));

	handleCheckRecorrencia = (e, { value }) => {
		this.setState({ checkRecorrencia: value });
	};

	handleAddEpi = () => {
		const value = this.state.newEpi;

		const found = this.state.epis.find(element => {
			return element.toLowerCase() === value.toLowerCase();
		});

		if (!found && value.length > 0) {
			this.setState({ epis: [...this.state.epis, value], newEpi: "" });
			this.props.handleCheckBoxEpis(value);
		} else if (value.length > 0) {
			this.setState({ alerted: true });
		}
	};

	handleNewEpi = name => {
		this.setState({ newEpi: name.target.value, alerted: false });
	};

	secaoModalOpen = secao => () =>
		this.setState({ openSecaoModal: !this.state.openSecaoModal, secao });

	salvarSecao = secao => () => {
		this.secaoModalOpen()();
		this.props.salvarSecao(secao);
	};

	modalTreinamento = () =>
		this.setState({ openModalTreinamento: !this.state.openModalTreinamento });

	salvarTreinamentos = treinamento => {
		this.modalTreinamento();
		this.props.salvarTreinamentos(treinamento);
	};

	render() {
		const {
			t,
			roteiro,
			validacao,
			valorSugestao,
			empresa,
			idSelecaoAutomatica,
			handleChangeSelecaoAutomatica,
			saldos,
			handleDeleteSecao,
			faturamentoMensal,
			handleCheckBoxEpis,
			handleChangeRadio,
			toggleRadios,
			defaultChecked,
			handleChangeRadioObrigatorio,
		} = this.props;

		const maximo = empresa?.valorMaximoOportunidades ?? 0;
		const restante = isNaN(maximo - faturamentoMensal?.gastos)
			? 0
			: maximo - faturamentoMensal?.gastos;
		const saldoNaoReservado = saldos?.naoReservado ?? 0;

		const { checkRecorrencia, openSecaoModal, secao, openModalTreinamento } = this.state;
		//Verifica se a data está setada no firebase, se não ele seta
		if (roteiro.data === "" || roteiro.data === null) {
			roteiro.data = moment(new Date()).format("YYYY-MM-DD");
		}

		//Verifica se a Hora inicio está setada, se não arruma para o horário padrao de 08:00 até as 18:00
		if (
			roteiro.horaInicio === "" ||
			roteiro.horaFim === null ||
			roteiro.horaFim === "" ||
			roteiro.horaFim === null
		) {
			roteiro.horaInicio = moment("08:00", "hh:mm").format("HH:mm");
			roteiro.horaFim = moment("18:00", "hh:mm").format("HH:mm");
		}

		var nome = "";
		if (this.state.searchInput) {
			nome = this.state.searchInput.searchQuery;
		}
		const handleOpenOptions = (e, { checked }) => {
			if (!checked) {
				toggleRadios();
			} else {
				defaultChecked();
			}
			this.setState({ options: !this.state.options });
		};

		return (
			<>
				<Form.Group widths="equal" style={{ margin: "auto", justifyContent: "center" }}>
					{this.tiposDeRoteirosOptions().map(({ text, icon, value, key }) => {
						if (key === 2 || key === 3) {
							return null;
						}
						return (
							<button
								key={key}
								onClick={e => {
									e.preventDefault();
									this.props.handleChangeSelectTipo(false, { value });
								}}
								className="btn-tipo-oportunidade"
								style={{
									background: _.isEqual(roteiro.tipo, value)
										? "rgba(102, 39, 128, 0.1)"
										: "",
									color: _.isEqual(roteiro.tipo, value) ? "#7501c0" : "",
									fontWeight: _.isEqual(roteiro.tipo, value) ? "bold" : "normal",
								}}>
								<Icon name={icon} size="large" style={{ marginBottom: 5 }} />
								<span>{text}</span>
							</button>
						);
					})}
				</Form.Group>
				{roteiro.tipo && (
					<Secao
						icon="info"
						titulo={t("informacoes")}
						subtitulo={t("informacoes.oportunidade")}>
						<div style={{ textAlign: "left" }}>
							<Form.Group
								widths="equal"
								className="roteiro-form"
								style={{ margin: "10px auto" }}>
								<DataDaAtividadeInput
									type="date"
									inicio={roteiro.data}
									fim={
										checkRecorrencia === "true" && roteiro.dataFim === ""
											? (roteiro.dataFim = moment(roteiro.data)
													.add(1, "day")
													.format("YYYY-MM-DD"))
											: roteiro.dataFim || ""
									}
									label={t("quando.sera.executado")}
									onChange={this.props.handleChangeRoteiro}
									checkRecorrencia={this.state.checkRecorrencia}
								/>
							</Form.Group>
							<Form.Group
								widths="equal"
								className="roteiro-form"
								style={{ margin: "10px auto" }}>
								<LojaDropdown
									label={t("onde.sera.executado")}
									placeholder={t("placeholder.loja")}
									options={this.lojasOptions()}
									onChange={this.props.handleChangeSelectLoja}
									search={true}
									value={roteiro.loja}
									onSearchChange={(e, data) =>
										this.setState({
											searchInput: data,
										})
									}
									noResultsMessage={<NoResultsMessage t={t} nome={nome} />}
									selection
									fluid
								/>
							</Form.Group>
							<Form.Group
								widths="equal"
								className="roteiro-form"
								style={{ margin: "10px auto" }}>
								<HorarioDaAtividadeInput
									type="time"
									inicioEFim
									inicio={roteiro.horaInicio}
									fim={roteiro.horaFim}
									label={
										roteiro.tipo.id === 5
											? t("horario.execucao")
											: t("sugestao.de.horario")
									}
									onChange={this.props.handleChangeRoteiro}
								/>
							</Form.Group>
							{!(roteiro.tipo.id === 5) ? (
								<>
									<Form.Group
										className="roteiro-form"
										style={{ display: "block", margin: "-10px auto 15px" }}>
										<div className="field">
											<label style={{ display: "block", fontWeight: "bold" }}>
												{roteiro.tempoEstimadoObrigatorio
													? t("tempo.obrigatorio.minutos")
													: t("tempo.estimado.minutos")}
											</label>
											<Input
												fluid
												placeholder={
													roteiro.tempoEstimadoObrigatorio
														? t("tempo.obrigatorio.minutos")
														: t("tempo.estimado.minutos")
												}
												type="number"
												required
												className="input-border-color"
												name="tempoEstimado"
												value={roteiro.tempoEstimado}
												onChange={this.props.handleChangeRoteiro}
											/>
											{roteiro.tempoEstimado > 0 &&
												roteiro.tempoEstimado < 30 && (
													<Text fontSize="sm" color={"red"}>
														{t("tempo.estimado.invalido")}
													</Text>
												)}
										</div>
									</Form.Group>
									<Form.Group
										className="roteiro-form"
										style={{ display: "block", margin: "-10px auto 15px" }}>
										{" "}
										<div className="field">
											<label style={{ display: "block", fontWeight: "bold" }}>
												{t("tempo.estimado.obrigatorio")}
											</label>
											<Radio
												toggle
												checked={roteiro.tempoEstimadoObrigatorio}
												value={roteiro.tempoEstimadoObrigatorio || false}
												onChange={handleChangeRadioObrigatorio}
											/>
										</div>
									</Form.Group>
								</>
							) : null}
							<Form.Group
								className="roteiro-form"
								style={{ display: "block", margin: "0 auto" }}>
								<div className="field">
									<label style={{ display: "block", fontWeight: "bold" }}>
										{t("descricao.geral")}
									</label>
									<TextArea
										value={roteiro.descricao}
										fluid="true"
										required={roteiro.tipo.id === 5 ? true : false}
										className="input-border-color"
										placeholder={
											roteiro.tipo.id === 5
												? t("descricao.por.tempo")
												: t("placeholder.descricao.roteiro")
										}
										onChange={this.props.handleChangeDescricaoGeral}
									/>
								</div>
							</Form.Group>
						</div>
					</Secao>
				)}
				{roteiro.tipo !== null && !(roteiro.tipo.id === 5) ? (
					<Secao icon="tags" titulo={t("secoes")} subtitulo={t("secoes.sub")}>
						{roteiro.secoes &&
							Object.keys(roteiro.secoes).map(key => {
								let secao = roteiro.secoes[key];
								return (
									<div
										className="secao-roteiro"
										onClick={this.secaoModalOpen(secao)}
										key={key}>
										<Grid stackable>
											<Grid.Column width={9} textAlign="left">
												<div className="secao-titulo">{secao.nome}</div>
												<div className="secao-atividades">
													{_.includes(secao.atividades, 6) && (
														<div>
															<Icon name="check" /> {t("reposicao")}
														</div>
													)}
													{_.includes(secao.atividades, 3) && (
														<div>
															<Icon name="check" />{" "}
															{t("pesquisa.de.preco")}
														</div>
													)}
													{_.includes(secao.atividades, 2) && (
														<div>
															<Icon name="check" />{" "}
															{t("pesquisa.de.validade")}
														</div>
													)}
													{_.includes(secao.atividades, 1) && (
														<div>
															<Icon name="check" />{" "}
															{t("foto.antes.depois")}
														</div>
													)}
												</div>
											</Grid.Column>
											<Grid.Column
												width={5}
												floated="right"
												style={{
													paddingRight: 0,
													display: "flex",
													flex: 1,
													alignItems: "center",
												}}>
												<div className="secao-n-produtos">
													<div className="numero">
														{secao.produtos.length}
													</div>
												</div>
											</Grid.Column>
											<Grid.Column
												width={2}
												floated="right"
												style={{
													display: "flex",
													alignItems: "center",
													paddingRight: 0,
													paddingLeft: 0,
												}}>
												<Button
													className="secao-delete-button"
													as="button"
													icon
													onClick={e =>
														handleDeleteSecao(key, e, roteiro)
													}>
													<Icon name="trash" />
												</Button>
											</Grid.Column>
										</Grid>
									</div>
								);
							})}
						{openSecaoModal && (
							<ModalSecao
								open={openSecaoModal}
								salvar={this.salvarSecao}
								cancelar={this.secaoModalOpen()}
								secao={secao}
								roteiro={roteiro}
							/>
						)}
						<br />
						<Button
							size="big"
							content={t("adicionar.secao")}
							icon="plus"
							color="violet"
							type="button"
							onClick={this.secaoModalOpen()}
							style={{ marginTop: 20 }}
						/>
					</Secao>
				) : null}
				{roteiro.tipo !== null && (
					<Secao
						titulo={"Treinamentos"}
						subtitulo={"Treinamentos para promotor"}
						icon="graduation">
						{!roteiro.treinamentos && <div></div>}
						{roteiro.treinamentos &&
							roteiro.treinamentos.map(treinamento => {
								return (
									<div
										className="secao-roteiro"
										onClick={this.modalTreinamento}
										key={treinamento.id}>
										<Grid stackable>
											<Grid.Column width={16} textAlign="left">
												<div className="secao-titulo">
													{treinamento.nome}
												</div>
												<div className="secao-atividades">
													{treinamento.notaAprovacao}
												</div>
											</Grid.Column>
										</Grid>
									</div>
								);
							})}
						{openModalTreinamento && (
							<ModalTreinamento
								open={openModalTreinamento}
								salvar={this.salvarTreinamentos}
								cancelar={this.modalTreinamento}
								roteiro={roteiro}
								empresa={empresa}
							/>
						)}
						<br />
						<Button
							size="big"
							content={"Adicionar treinamento"}
							type="button"
							onClick={this.modalTreinamento}
							icon="plus"
							color="violet"
							style={{ marginTop: 20 }}
						/>
					</Secao>
				)}
				<Secao titulo={t("pagarei")} icon="dollar" subtitulo={t("informe.quanto.pagara")}>
					<ValorRoteiro
						style={{ fontSize: 50 }}
						moeda="R$"
						placeholder={0}
						value={parseFloat(roteiro.valor)}
						onChange={this.props.handleChangeValorRoteiro}
					/>
					<>
						{empresa.faturamentoMensal
							? t("saldo.disponivel.faturamento.mensal")
							: t("saldo.disponivel")}
						<b>
							<Currency
								quantity={empresa.faturamentoMensal ? restante : saldoNaoReservado}
								locale="pt_BR"
								decimal=","
								group="."
								currency="R$"
							/>
						</b>
					</>
					{parseFloat(roteiro.valor) <= (valorSugestao * 70) / 100 && (
						<>{t("valor.sugerido")}</>
					)}

					{valorSugestao && valorSugestao === roteiro.valor && (
						<>
							{t("valor.sugestao")}
							<Popup
								content={t("valor.sugestao.calculo")}
								trigger={
									<Button
										size="mini"
										style={{ marginLeft: 5, color: "#662780" }}
										icon="help"
									/>
								}
							/>
						</>
					)}
				</Secao>
				{empresa.selecaoAutomatica && Object.keys(empresa.selecaoAutomatica).length > 0 ? (
					<Secao
						icon="star"
						titulo={t("selecao.automatica")}
						subtitulo={t("selecao.automatica.sub")}>
						<Segment style={{ marginTop: 15 }}>
							{Object.keys(empresa.selecaoAutomatica).map(key => (
								<div
									key={key}
									className={`linha-selecao-automatica ${
										idSelecaoAutomatica === key ? "selecionado" : ""
									}`}
									id={key}
									onClick={handleChangeSelecaoAutomatica}>
									<Radio
										name="selecaoAutomatica"
										value={key}
										checked={idSelecaoAutomatica === key}
										size="medium"
									/>{" "}
									{empresa.selecaoAutomatica[key].fantasia}
								</div>
							))}
						</Segment>
					</Secao>
				) : null}
				<Secao
					icon="certificate"
					titulo={t("aprovacao.automatica")}
					subtitulo={t("aprovacao.automatica.subtitulo")}>
					{/* <Segment style={{ marginTop: 15 }}>
            <Checkbox
              disabled={roteiro.selecaoAutomatica}
              checked={roteiro.selecaoAutomaticaFavorito}
              label={t("aprovacao.automatica.favorito.checkbox")}
              onChange={handleCheckBoxSelecaoAutomaticaFavorito}
            />
          </Segment> */}
					<Segment style={{ marginTop: 15 }}>
						<Checkbox
							checked={this.state.options}
							label={t("aprovacao.automatica.checkbox")}
							onChange={handleOpenOptions}
						/>
						{this.state.options && (
							<>
								<Divider />
								<div
									style={{
										marginTop: 15,
										display: "flex",
										justifyContent: "space-around",
									}}>
									<Radio
										label={t("qualquer.promotor")}
										name="automatico"
										value="automatico"
										checked={roteiro.selecaoAutomatica}
										onChange={handleChangeRadio}
									/>

									<Radio
										label={t("apenas.promotores.favoritos")}
										name="favoritoAutomatico"
										value="favorito"
										checked={roteiro.selecaoAutomaticaFavorito}
										onChange={handleChangeRadio}
									/>
								</div>
							</>
						)}
					</Segment>
				</Secao>
				<Secao
					icon="exclamation triangle"
					titulo={t("selecionar.epi.titulo")}
					subtitulo={t("selecionar.epi.subtitulo")}>
					<Segment style={{ marginTop: 15 }}>
						<div className="ui labels" style={{ marginBottom: 8 }}>
							{this.state.epis.map(epi => {
								return (
									<a
										style={
											roteiro.epis?.includes(epi)
												? {
														borderWidth: "1px",
														backgroundColor: "#7501c0",
														color: "#fff",
													}
												: {
														borderWidth: "1px",
														borderColor: "#7501c0",
														color: "#7501c0",
													}
										}
										className={
											roteiro.epis?.includes(epi)
												? "ui label"
												: "ui label basic"
										}
										onClick={() => handleCheckBoxEpis(epi)}
										key={Math.random() + Date.now()}>
										{t(epi)}
									</a>
								);
							})}
						</div>
						<div
							style={{
								flexDirection: "row",
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Input
								placeholder={t("epi.novo")}
								type="text"
								className="input-border-color"
								name="novo_epi"
								style={{ width: "100%" }}
								value={this.state.newEpi}
								onChange={value => this.handleNewEpi(value)}
							/>

							<Button
								type="button"
								color={"violet"}
								icon
								onClick={e => this.handleAddEpi(e)}
								style={{ marginLeft: 20 }}>
								<Icon name="add" />
							</Button>
						</div>
						{this.state.alerted && (
							<Label basic color="violet" pointing>
								{t("epi.alert")}
							</Label>
						)}
					</Segment>
				</Secao>
				{validacao && (
					<Alert status="error" borderRadius={5}>
						<AlertIcon />
						<Box>
							<AlertTitle>{t("problema.no.registro")}</AlertTitle>
							<AlertDescription>{validacao}</AlertDescription>
						</Box>
					</Alert>
				)}
			</>
		);
	}
}
const mapStateToProps = state => ({
	empresa: state.AutenticacaoReducer.empresa,
});

export default connect(mapStateToProps)(RoteiroForm);
