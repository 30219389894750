import { Divider } from "@chakra-ui/react";
import _ from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";
import { Rating } from "semantic-ui-react";
import ImageAvatar from "./ImageAvatar";

const TopUsuarioRuptura = (props) => {
    const { t } = props;

    return (
        <div className="top-user-ruptura">
            <hr style={{ borderWidth: 2, margin: "14px 0" }} />
            <h2 style={{ fontSize: "1.28571429rem", paddingLeft: 10, fontWeight: 700 }}>
                {t("usuarios.com.mais.rupturas")}
            </h2>
            <div style={{ paddingLeft: 10, paddingTop: 9 }}>
                {_.orderBy(props.usuario, "rupturas", "desc").map(
                    ({ foto, nome, rupturas, mediaAvaliacao, quantidadeAvaliacao }, key) => {
                        return (
                            <div key={key}>
                                <div className="container-top">
                                    <ImageAvatar url={foto} width="50px" circular />
                                    <div className="body-top">
                                        <div className="header-top">
                                            <p>{nome} | </p>
                                            <Rating
                                                style={{ marginLeft: 5 }}
                                                disabled
                                                icon="star"
                                                defaultRating={(mediaAvaliacao / quantidadeAvaliacao).toFixed(2)}
                                                maxRating={5}
                                            />
                                        </div>
                                        <div>
                                            <span>
                                                {t("quantidade.de.rupturas")}: {rupturas}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Divider my={3} />
                            </div>
                        );
                    }
                )}
            </div>
        </div>
    );
};

export default withTranslation()(TopUsuarioRuptura);
