import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	CloseButton,
	Collapse,
	Divider,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useContextOpportunityExecutionNegotiation } from "../../../../../contexts/ContextOpportunityExecutionNegotiation";
import { PATHS } from "../../../../../Utils/Routes";
import ReactHtmlParser from "react-html-parser";

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Componente que exibe uma mensagem de alerta caso o PDV não possua uma rede associada manualmente.
 */
export function AlertAssociatePdvToNetwork() {
	const { t } = useTranslation();
	const { isOpen, onToggle } = useDisclosure();

	const { hasDefaultNetwork, matchedNetwork, opportunity, agencyIsLoading } =
		useContextOpportunityExecutionNegotiation();

	//NOTE: Se estiver carregando agências ou o PDV já tiver uma rede associada, não exibimos o alerta.
	if (agencyIsLoading || hasDefaultNetwork) {
		return null;
	}

	//NOTE: Se não foi encontrado uma rede que combine com o PDV, não exibimos o alerta.
	if (!matchedNetwork) {
		return null;
	}

	return (
		<Collapse in={!isOpen}>
			<Alert status="info" rounded={"md"} mb={7} position="relative" shadow="md">
				<CloseButton top={2} right={2} onClick={onToggle} position="absolute" size="sm" />
				<AlertIcon />
				<Box>
					<AlertTitle>{t("atencao-com-seu-pdv")}!</AlertTitle>
					<AlertDescription>
						{t(
							"notamos-que-o-seu-pdv-nao-esta-associado-a-uma-rede-porem-nosso-sistema-analisou-as-opcoes-disponiveis-e-identificamos-que-poderia-ser-da"
						)}{" "}
						<b>"{matchedNetwork.nome}"</b>.
					</AlertDescription>
					<Text lineHeight={1.5}>
						{ReactHtmlParser(
							t(
								"por-isso-algumas-agencias-podem-ter-o-selo-de-less-than-b-greater-than-credenciada-less-than-b-greater-than-erroneamente-para-melhorar-a-sugestao-adicione-uma-rede-na"
							)
						)}{" "}
						<Link to={PATHS.pdv.editar(opportunity.loja.id)}>{t("edicao-de-pdv")}</Link>
						.
					</Text>
				</Box>
			</Alert>
			<Divider />
		</Collapse>
	);
}
