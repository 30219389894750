export function isTrue(values: boolean | string | null) {
    if (!values) {
        return false;
    }
    if (typeof values === "string") {
        values = values.toLowerCase();
        if (values === "true") {
            return true;
        }
        return false;
    }
    return true;
}