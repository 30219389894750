import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

export const Metric = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    text-align: center;

    h1 {
        font-size: 2.2rem;
        font-weight: bold;
    }

    h2 {
        font-size: 1.2rem;
        color: #8d99a3;
    }

    @media (max-width: 775px) {
        h1 {
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1rem;
        }
    }
`;

export const VerticalSeparator = styled.hr`
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 2px 0px 0px;
    border-style: solid;
    border-color: rgba(145, 158, 171, 0.24);
    height: auto;
    align-self: stretch;
`;
