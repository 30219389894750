import React from "react";
import Currency from "react-currency-formatter";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

/**
 * Renderiza a legenda da dashboard
 */
export const Legenda = () => {
  const { t } = useTranslation();
  return (
    <div className="dash-legenda">
      <div className="legenda">
        <div className="box-cor blue" />
        <span>{t("em.execucao")}</span>
      </div>
      <div className="legenda">
        <div className="box-cor red" />
        <span>{t("incompleto")}</span>
      </div>
      <div className="legenda">
        <div className="box-cor orange" />
        <span>{t("aguardando")}</span>
      </div>
      <div className="legenda">
        <div className="box-cor green" />
        <span>{t("executado")}</span>
      </div>
      <div className="legenda">
        <div className="box-cor" />
        <span>{t("sem.promotor")}</span>
      </div>
    </div>
  );
};

/**
 * Utilizado para exibir quando não há dados suficientes para exibição de alguma coisa
 */
export const SemDados = () => {
  const { t } = useTranslation();
  return <div className="sem-dados">{t("nao.ha.dados.suficientes")}</div>;
};

/**
 * Utilizado para exibir quando não há dados suficientes para exibição de alguma coisa
 */
export const SemConexao = () => {
  const { t } = useTranslation();
  return (
    <div className="sem-dados">
      <Icon name="server" style={{ marginRight: 10 }} />
      {t("conexao.servidor.indisponivel")}
    </div>
  );
};

/**
 * Retorna um icone por id de status
 */
export const iconePorStatus = {
  3: "check",
  5: "check",
  2: "clock outline",
  6: "fire",
  1: "users",
  7: "calendar times outline",
};

/**
 * Retorna um nome de status por id de status
 */
export const nomePorStatus = {
  3: "executado",
  5: "finalizado",
  2: "aguardando",
  6: "em.execucao",
  1: "em.aberto",
  7: "expirado",
};

/**
 * Retorna um icone por id de atividade
 */
export const iconPorAtividade = {
  6: "dolly",
  1: "photo",
  3: "dollar",
  2: "calendar times",
};

/**
 * Utilizado para exibir saldos do usuario
 * @param {valor, titulo} props
 */
export const InfoSaldoDashboard = (props) => {
  return <div className="dash-infosaldo">
    <div className="valor-infosaldo">
      {props?.carregandoLimite?.mongo || props?.carregandoLimite?.firebase ? (
        props.t("carregando") + "..."
      ) : (
        <Currency
          quantity={props.valor}
          locale="pt_BR"
          decimal=","
          group="."
          currency="R$ "
        />
      )}
    </div>
    <div className="titulo-infosaldo">{props.titulo}</div>
  </div>
};
