import firebase from "firebase";
import _ from "lodash";
import React, { Component } from "react";
import { Button, Form, Header, Icon, Message } from "semantic-ui-react";
import { v4 } from "uuid";
import { storage } from "../../config";

export default class TreinamentoPDF extends Component {
	state = {
		titulo: "",
		tipo: "pdf",
		treinamento: {},
		file: null,
		carregando: false,
		error: [],
		preview: "",
		url: "",
		id: v4(),
		novo: true,
	};

	componentDidMount() {
		if (this.props.atividade) {
			var { id, titulo, url, ordem, tipo } = this.props.atividade;
			this.setState({ id, titulo, url, ordem, tipo, novo: false });
			firebase
				.storage()
				.ref()
				.child(url)
				.getDownloadURL()
				.then(url => {
					this.setState({ preview: url });
				});
		}
	}

	handleSubmit = async e => {
		e.preventDefault();
		this.setState({ carregando: true }, () => {
			let idTreinamento = this.state.id;
			const { salvar } = this.props;
			const salvarNobanco = idTreinamento => {
				let { titulo, url, ordem, tipo } = this.state;
				salvar({
					titulo,
					ordem,
					url,
					tipo,
					id: idTreinamento,
				}).catch(err => {
					this.setState({
						carregando: false,
						error: [...this.state.error, "Erro no envio"],
					});
				});
			};

			this.uploadPdf()
				.then(() => salvarNobanco(idTreinamento))
				.catch(err => {
					console.log("err", err);
					this.setState({
						carregando: false,
						progress: 0,
						error: [...this.state.error, "Erro no envio"],
					});
				});
		});
	};

	uploadPdf = () =>
		new Promise((resolve, reject) => {
			let file = this.state.file;
			if (!file && this.state.url) {
				return resolve();
			}
			let { id } = this.state;
			this.setState({ carregando: true }, () => {
				const fileRef = storage.ref().child(`treinamentos/pdf/${id}-${file.name}`);
				let uploadTask = fileRef.put(file);

				uploadTask.then(() => {
					this.setState({ carregando: false }, () =>
						this.handlePdf(
							null,
							{ value: `treinamentos/pdf/${id}-${file.name}`, name: "url" },
							resolve()
						)
					);
				});
			});
		});

	handleChange = ({ target: { name, value } }) => {
		this.setState({ [name]: value });
	};

	handleInputPdf = e => {
		const pdf = e.target.files[0];
		this.setState({
			file: pdf,
			preview: URL.createObjectURL(pdf),
			url: `treinamentos/pdf/${this.state.id}-${pdf.name}`,
		});
	};

	handlePdf = (e, { value, name }, call) => {
		this.setState({ [name]: value }, call);
	};

	render() {
		var BotaoCancelar = this.props.cancelar;
		const { t } = this.props;
		const { titulo, url, preview } = this.state;
		return (
			<>
				<Header content="PDF" as="h2" />
				{!_.isEmpty(this.state.error) && (
					<Message error header="Ops algo deu errado" list={this.state.error} />
				)}
				<Form onSubmit={this.handleSubmit}>
					<Form.Field>
						<label>{t("titulo")}</label>
						<input
							type="text"
							required
							name="titulo"
							placeholder="Título do PDF (aparecerá com maior destaque)"
							value={titulo}
							onChange={this.handleChange}
						/>
					</Form.Field>

					<Form.Field className="button-wrapper">
						<label className="label-treinamento-imagem">{t("pdf.treinamento")}</label>
						<div className="button-wrapper">
							<div
								style={{
									width: 200,
									height: 40,
									backgroundColor: "#eee",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 5,
									cursor: "pointer",
									background: "#7501c0",
									color: "#fff",
								}}>
								<span style={{ textAlign: "center" }}>
									<Icon disabled name="file pdf" size="large" />
									{url === "" ? t("selecione.pdf") : t("selecione.outro.pdf")}
								</span>
							</div>

							<input
								accept="pdf/*"
								type="file"
								name="file"
								id="upload"
								required={preview === "" ? true : false}
								style={{
									display: "inline-block",
									position: "absolute",
									zIndex: 1,
									width: 200,
									height: 40,
									top: 0,
									left: 0,
									opacity: 0,
									cursor: "pointer",
								}}
								className="upload-box"
								placeholder="Upload File"
								onChange={e => this.handleInputPdf(e)}
							/>
						</div>
						{/* <input
                                type="file"
                                name="file"
                                accept="pdf/*"
                                class="upload-box"
                                onChange={e => this.handleInputPdf(e)}
                            /> */}
					</Form.Field>
					{preview === "" ? null : (
						<div>
							<iframe
								title="previewPDF"
								src={preview}
								style={{ boxShadow: "none" }}
								frameBorder="0"
								allowFullScreen
							/>
						</div>
					)}
					<br />
					<div style={{ justifyContent: "flex-end", display: "flex" }}>
						<BotaoCancelar />
						<Button
							positive
							type="submit"
							loading={this.state.carregando}
							disabled={this.state.carregando}>
							{this.state.novo ? t("salvar.atividade") : t("editar.atividade")}
						</Button>
					</div>
				</Form>
			</>
		);
	}
}
