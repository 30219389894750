import {
	AUTENTICAR_USUARIO,
	ATUALIZAR_EMPRESA,
	DESAUTENTICA_USUARIO,
	CADASTRANDO_USUARIO,
	ATUALIZAR_INFO_CONTA,
	ALTERNAR_MODULO,
	MEUS_PROMOTORES,
	ATUALIZAR_MERCADO,
	ATUALIZAR_SALDOS,
	ATUALIZAR_LIMITE,
	IP_LOCATION,
	ATUALIZAR_FATURA,
	ATUALIZAR_USUARIOS,
	ATUALIZAR_OPORTUNIDADES_MONGO,
	ATUALIZAR_OPORTUNIDADES_FIREBASE,
	ATUALIZAR_COORDENADAS,
} from "./Types";
import { RoteiroPaginator as RP } from "../Utils/RoteiroPaginator";

export const autenticarUsuario = (usuario, empresa) => dispatch => {
	dispatch({ type: AUTENTICAR_USUARIO, usuario, empresa });
	return Promise.resolve();
};

export const atualizarEmpresa = empresa => ({
	type: ATUALIZAR_EMPRESA,
	empresa,
});

export const atualizarMercado = empresa => ({
	type: ATUALIZAR_MERCADO,
	empresa,
});

export const desautenticaUsuario = () => {
	RP.flushAll();
	return { type: DESAUTENTICA_USUARIO };
};

export const cadastrandoUsuario = () => dispatch => {
	dispatch({ type: CADASTRANDO_USUARIO });
	return Promise.resolve();
};

export const atualizarInfoContaEmpresa = infoConta => ({
	type: ATUALIZAR_INFO_CONTA,
	infoConta,
});

export const atualizarSaldos = (saldo, valor) => ({
	type: ATUALIZAR_SALDOS,
	saldo,
	valor,
});

export const atualizarFaturamentoMensal = () => ({
	type: ATUALIZAR_LIMITE,
});

export const atualizarOportunidadesMongo = oportunidades => ({
	type: ATUALIZAR_OPORTUNIDADES_MONGO,
	oportunidades,
});

export const atualizarOportunidadesFirebase = oportunidades => ({
	type: ATUALIZAR_OPORTUNIDADES_FIREBASE,
	oportunidades,
});

export const alternarModulo = modulo => {
	localStorage.setItem("modulo", modulo);
	return {
		type: ALTERNAR_MODULO,
		modulo,
	};
};

export const salvarPromotores = promotores => ({
	type: MEUS_PROMOTORES,
	promotores,
});

export const guardarIPLocation = ipLocation => ({
	type: IP_LOCATION,
	ipLocation,
});

export const atualizarFatura = fatura => ({
	type: ATUALIZAR_FATURA,
	fatura,
});

export const salvarUsuarios = usuarios => ({
	type: ATUALIZAR_USUARIOS,
	usuarios,
});

export const salvarCoordenadas = coordenadas => ({
	type: ATUALIZAR_COORDENADAS,
	coordenadas,
});
