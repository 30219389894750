import styled from "styled-components";

interface PropsButton {
	backgroundColor?: string;
	hover?: string;
}

export const Container = styled.div`
	display: flex;
	height: 100vh;
	width: 100vw;
	background: linear-gradient(#7501c0, #7f2bb5);
	align-items: center;
	justify-content: center;
`;

export const ContainerBody = styled.div`
	flex-direction: column;
	background-color: white;
	margin: 15px;
	height: 70vh;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	text-align: center;
	border-radius: 10px;
	box-shadow: 0px 5px 10px 3px rgba(34, 36, 38, 0.6);
`;

export const ContainerBodyHeader = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-top: 15px;
`;

export const ContainerBodyContent = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 85%;
	margin-bottom: 10px;
`;

export const ContainerBodyFooter = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 20px;
	padding: 15px;
`;

export const Button = styled.button<PropsButton>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: ${props => (props.backgroundColor ? props.backgroundColor : "#7501c0")};
	width: 300px;
	height: 45px;
	text-align: center;
	border-radius: 10px;
	border-width: 0;
	cursor: pointer;
	margin-top: 5px;
	&:hover {
		background-color: ${props => (props.hover ? props.hover : "#5c2273")};
	}
`;

export const iconBloqueado = {
	display: "flex",
	fontSize: "100px",
	alignItems: "center",
	justifyContent: "center",
	marginTop: "20px",
};

export const iconWhatsapp = { marginRight: 10, fontSize: "25px" };
