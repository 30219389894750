import { BadgeProps } from "@chakra-ui/react";
import { ReceiptStatus } from "../../@types/Receipt";

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Função que mapeia um status de nota fiscal para um `colorScheme` do ChakraUI.
 */
export function getChakraColoSchemerByReceiptStatus(
	status: ReceiptStatus
): BadgeProps["colorScheme"] {
	switch (status) {
		case ReceiptStatus["Enviado para análise"]:
			return "yellow";
		case ReceiptStatus.Aceita:
			return "green";
		case ReceiptStatus.Recusada:
			return "red";
		default:
			return "gray";
	}
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Função que mapeia um status de nota fiscal para uma `label`.
 */
export function getLabelByReceiptStatus(status: ReceiptStatus) {
	switch (status) {
		case ReceiptStatus["Enviado para análise"]:
			return "Enviado para análise";
		case ReceiptStatus.Aceita:
			return "Aceita";
		case ReceiptStatus.Recusada:
			return "Recusada";
		default:
			return "Indefinido";
	}
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Função que mapeia um status de nota fiscal para um `icon` do `Iconify`.
 */
export function getIconByReceiptStatus(status: ReceiptStatus) {
	switch (status) {
		case ReceiptStatus["Enviado para análise"]:
			return "mdi:clock";
		case ReceiptStatus.Aceita:
			return "mdi:check";
		case ReceiptStatus.Recusada:
			return "mdi:close";
		default:
			return "mdi:help";
	}
}
