import Compressor from "compressorjs";
import { FileCompressError } from "../errors/File.erros";
import prettyBytes from "pretty-bytes";

export function compressImage(
    originalFile: File,
    maxSizeToCompress = 5e6, //5MB
    maxSizeResult = 500000, //500Kb
    compressorOptions?: Compressor.Options
) {
    return new Promise<File | Blob>((resolve, reject) => {
        if (originalFile.size > maxSizeToCompress)
            return reject(
                new FileCompressError(
                    `Arquivo muito grande pra ser enviado. Tamanho: ${prettyBytes(originalFile.size)}`
                )
            );
        new Compressor(originalFile, {
            quality: 0.6,
            convertTypes: ["image/png", "image/webp"],
            convertSize: 1000000, //1MB
            success: (result) => {
                if (result.size > maxSizeResult) {
                    return reject(
                        new FileCompressError(
                            `Arquivo muito grande pra ser enviado. Tamanho: ${prettyBytes(result.size)}`
                        )
                    );
                }

                return resolve(result);
            },
            error: (_) => reject(new FileCompressError(`Tivemos problemas para comprimir a imagem, tente novamente.`)),
            ...compressorOptions,
        });
    });
}
