import { Button, Flex, FlexProps, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useBlocker } from "../../../hooks/store/useBlocker";
import { useAccountBalance } from "../../../hooks/useAccountBalance";
import { PRICE_VALIDATION } from "../../../Utils/Zod";
import { TextAreaForm } from "../../Form/chakraUI/TextAreaForm";
import { PriceInputForm } from "../../Form/PriceInputForm";

interface NewProposalFormProps extends Omit<FlexProps, "onSubmit" | "as"> {
	onSubmit: SubmitHandler<FormProps>;
	onCancel: VoidFunction;
	resetWhenThisValueIsFalse?: boolean;
	isSubmittingObserver?: (isSubmitting: boolean) => void;
	amITheNegotiationCreator: boolean;
}

interface FormProps {
	newValue: number;
	description: string;
}

/**
 * Componente que renderiza um esqueleto formulário para enviar propostas em uma negociação.
 */
export function NewProposalForm({
	onSubmit,
	onCancel,
	resetWhenThisValueIsFalse,
	isSubmittingObserver,
	amITheNegotiationCreator,
	...restProps
}: NewProposalFormProps) {
	const { t } = useTranslation();
	const {
		state: { isSendCounterProposalBlocked },
	} = useBlocker();

	const NewProposalFormSchema = z.object({
		newValue: PRICE_VALIDATION.refine(value => value > 0, t("valor-deve-ser-maior-que-0")),
		description: z.string().min(0).max(450, t("a-descricao-nao-pode-passar-de-450-caracteres")),
	});

	const {
		watch,
		control,
		handleSubmit,
		formState: { isSubmitting },
		reset,
	} = useForm<FormProps>({
		resolver: zodResolver(NewProposalFormSchema),
		defaultValues: {
			newValue: 0,
			description: "",
		},
	});

	useEffect(() => {
		if (resetWhenThisValueIsFalse === false) {
			reset();
		}
	}, [reset, resetWhenThisValueIsFalse]);

	useEffect(() => {
		if (isSubmittingObserver) {
			isSubmittingObserver(isSubmitting);
		}
	}, [isSubmitting, isSubmittingObserver]);

	const proposalValue = watch("newValue");

	const { balanceIsSufficient } = useAccountBalance(PRICE_VALIDATION.parse(proposalValue));

	return (
		<Flex
			flexDir="column"
			gap={4}
			bg="white"
			borderRadius="lg"
			as="form"
			onSubmit={handleSubmit(onSubmit)}
			{...restProps}>
			<PriceInputForm control={control} name="newValue" label={t("valor-da-nova-proposta")} />
			<TextAreaForm
				control={control}
				name="description"
				label={t("descricao")}
				placeholder={t("coloque-aqui-a-justificativa-pelo-valor-proposto")}
				size="lg"
			/>
			{amITheNegotiationCreator && !balanceIsSufficient && (
				<Text color="red.500">• {t("valor-da-proposta-maior-que-o-saldo-disponivel")}</Text>
			)}
			<Flex gap={2} justify="flex-end">
				<Button
					w={{ base: "full", sm: "fit-content" }}
					colorScheme="red"
					onClick={onCancel}
					variant="outline"
					isDisabled={isSubmitting}
					leftIcon={<Icon icon="mdi:close-circle" width={20} />}>
					{t("cancelar")}
				</Button>
				<Button
					isDisabled={isSendCounterProposalBlocked}
					w={{ base: "full", sm: "fit-content" }}
					sx={{
						"& div": {
							padding: 0,
						},
					}}
					colorScheme="green"
					type="submit"
					isLoading={isSubmitting}
					leftIcon={<Icon icon="mingcute:send-fill" width={20} />}>
					{t("propor")}
				</Button>
			</Flex>
		</Flex>
	);
}
