import { Popup, PopupProps } from "semantic-ui-react";
import styled from "styled-components";

export const Container: React.FC<PopupProps> = styled(Popup)``;

export const Content = styled.div`
    display: flex;
    gap: 1rem;
    min-width: 25rem;
    max-width: 38rem;
    overflow: auto;

    @media (max-width: 765px) {
        flex-direction: column;
        max-height: 15rem;
    }
`;

export const Header = styled.h1`
    font-weight: bold;
    font-size: 1.5rem;
`;

export const SubHeader = styled.h2`
    font-weight: normal;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
`;

export const OpportunityCard = styled.div`
    min-width: 18rem;
    max-width: 18rem;
`;

export const Text = styled.p<{ isTitle?: boolean }>`
    font-weight: ${(props) => (props.isTitle ? "bold" : "normal")};

    span {
        font-weight: bold;
    }
`;
