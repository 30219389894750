import { toArray } from 'lodash';
export const DEFAULT_PER_PAGE = 5;

export const QUERING = 'quering';
export const END_OF_STREAM = 'eos';

let _cache = {};

export class RoteiroPaginator {
    static create = (name, baseQuery, limit) => (_cache[name] = new RoteiroPaginator(baseQuery, limit));
    static findByName = name => _cache[name];
    static deleteByName = name => { delete _cache[name]; };
    static flushAll = () => (_cache = {});

    constructor(baseQuery, limit) {
        this.limit = limit;
        this.status = QUERING;
        this.query = baseQuery.limit(limit);
        this.cursor = null;
    }

    __save_last_result = resolved => results => {
        // NOTE: o último roteiro será utilizado como cursor
        // para saber de onde a próxima query continuará.  
        this.status = QUERING;
        if (this.limit === results.size) {
            this.cursor = results.docs[results.size - 1];
        } else {
            this.status = END_OF_STREAM;
        }
        resolved({ status: this.status, results });
    };

    next = () => new Promise(((resolved, rejected) => {
        const save_last_result = this.__save_last_result(resolved).bind(this);
        if (this.hasEnded()) {
            return save_last_result({ size: 0, docs: [] })
        }
        if (this.cursor) {
            this.query = this.query.startAfter(this.cursor).limit(this.limit);
        }

        this.query.get().then(save_last_result).catch(rejected);
    }));

    hasEnded = () => this.status === END_OF_STREAM;
}

export var roteiros = {}
var listeners = []    // list of listeners
var end = {}       // end position of listener
var changes = []
var status = ""

const retirarDaLista = () => {
    changes = []
}

const atualizarLista = (changes, key) => {
    changes.forEach(change => {
        switch (change.type) {
            case 'removed': {
                delete roteiros[key][change.doc.id];
                break;
            }
            case 'added': {
                roteiros = { ...roteiros, [key]: { ...roteiros[key], [change.doc.id]: change.doc.data() } }
                break;
            }
            default: {
                roteiros = { ...roteiros, [key]: { ...roteiros[key], [change.doc.id]: change.doc.data() } }
            }
        }
    })
}

const verificarSeQueryTemResultados = (limit, snapshots) => {
    if (limit === snapshots.size) {
        status = false;
    } else {
        status = true;
    }
}

const handleSnap = (limit, callback, key) => (snap) => {
    changes = [...snap.docChanges()]
    end[key] = snap.docs[snap.docs.length - 1] ? snap.docs[snap.docs.length - 1] : end[key]
    atualizarLista(changes, key)
    verificarSeQueryTemResultados(limit, snap)
    retirarDaLista()
    return callback({ data: toArray(roteiros[key]) })
}

const saveListeners = (query, callback, limit, key) => {
    let listener = query;
    if (end[key]) {
        //após a primeira query, existe o start after.
        listener = listener.startAfter(end[key])
    }
    //padrão que todas as querys terão.
    listener = listener.limit(limit)
        .onSnapshot(handleSnap(limit, callback, key))
    listeners.push(listener)
}
/**
 * Get: chamando quando deseja obter registros, iniciais ou nao.
 */
export const paginator = () => ({
    get: (query, callback, limit, key) => {
        saveListeners(query, callback, limit, key)
    },
    getMultiple: (querys, callback, limit, key) => {
        querys.forEach((query) => saveListeners(query, callback, limit, key))
    },
    clear: () => {
        // limpa todos os registros do paginator
        roteiros = {}
        listeners = []
        end = {}
        changes = []
        status = ""
    },
    offListeners: () => {
        //desliga os listeber
        listeners.forEach(listener => listener())
    },
    hasEnded: () => status
})