import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.br";
import $ from "jquery";
import React, { Component } from "react";
import "./cadastro.css";
import { BotaoProximo } from "./CadastroEmpresa";
export default class Step4 extends Component {
	componentDidMount() {
		this.inputTelefone = new Cleave($("#telefone1"), {
			phone: true,
			phoneRegionCode: "BR",
		});
	}

	componentWillUnmount() {
		this.inputTelefone = null;
	}

	render() {
		return (
			<div className={this.props.step === 5 ? "d-block" : "d-none"}>
				<div>
					<div className="titulo">{this.props.t("responsavel.pela.empresa")}</div>
					<div className="form-group">
						<input
							maxLength={45}
							name="nome"
							value={this.props.empresa.responsavel.complemento}
							onChange={this.props.handleChangeEmpresaResponsavel}
							required
							type="text"
							placeholder={this.props.t("nome.responsavel")}
						/>
					</div>
					<div className="form-group">
						<input
							maxLength={45}
							name="email"
							value={this.props.empresa.responsavel.complemento}
							onChange={this.props.handleChangeEmpresaResponsavel}
							required
							type="text"
							placeholder={this.props.t("email.responsavel")}
						/>
					</div>
					<div className="form-group">
						<input
							maxLength={13}
							id="telefone1"
							name="telefone"
							value={this.props.empresa.responsavel.complemento}
							onBlur={this.props.handleChangeEmpresaResponsavel}
							onChange={this.props.handleChangeEmpresaResponsavel}
							required
							type="phone"
							placeholder={this.props.t("telefone.responsavel")}
						/>
					</div>
				</div>
				<BotaoProximo t={this.props.t} />
			</div>
		);
	}
}
