import {
	Button,
	Collapse,
	Divider,
	Grid,
	Heading,
	HStack,
	IconButton,
	Tooltip,
	useDisclosure,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import firebase from "firebase";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { z } from "zod";
import { Company, CompanyVariant } from "../../../../@types/Company";
import { Card } from "../../../../Componentes/Card";
import { PriceInputForm } from "../../../../Componentes/Form/PriceInputForm";
import { SwitchForm } from "../../../../Componentes/Form/SwitchForm";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { PRICE_VALIDATION } from "../../../../Utils/Zod";

interface FormProps {
	disableNotifications: boolean;
	valuePerHour: number;
}

const FormSchema = z.object({
	disableNotifications: z.boolean(),
	valuePerHour: PRICE_VALIDATION,
});

export function CompanySettingsForm() {
	const { t } = useTranslation();
	const { isOpen: editMode, onToggle: toggleEditMode } = useDisclosure();
	const company = useAppSelector(state => state.AutenticacaoReducer).empresa as Company;

	/**
	 * Diz se a empresa é do tipo agência.
	 */
	const companyIsAgency = company.tipo === CompanyVariant.AGENCIA;

	const {
		handleSubmit,
		control,
		reset,
		formState: { isSubmitting, isDirty },
	} = useForm<FormProps>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			disableNotifications: company.desabilitarNotificacoes || false,
			valuePerHour: company.valorPorHora || 0,
		},
	});

	const onSubmit: SubmitHandler<FormProps> = async formData => {
		try {
			//NOTE: Monta o objeto de configuração da empresa que será atualizado
			const partialCompanyConfigsToUpdate: Partial<Company> = {
				desabilitarNotificacoes: formData.disableNotifications,
				valorPorHora: formData.valuePerHour,
			};

			//NOTE: Bifurca o objeto acima para um objeto mais genérico de atualização do firebase
			const dataToUpdate: firebase.firestore.UpdateData = {
				...partialCompanyConfigsToUpdate,
			};

			//NOTE: Se a empresa não for agência, removemos o valor por hora
			if (!companyIsAgency) {
				dataToUpdate.valorPorHora = firebase.firestore.FieldValue.delete();
			}

			//NOTE: Atualiza a empresa
			await firebase.firestore().collection("empresas").doc(company.uid).update(dataToUpdate);

			toast.success(t("configuracoes-da-empresa-atualizadas-com-sucesso"));
			reset(formData);
		} catch (error) {
			console.error(error);
			toast.error(t("ocorreu-um-erro-ao-atualizar-as-configuracoes-da-empresa"));
		} finally {
			toggleEditMode();
		}
	};

	function handleToggleEditMode() {
		toggleEditMode();
		editMode && reset();
	}

	return (
		<Card p={4} borderRadius={"md"} display="flex" flexDir="column" gap={4}>
			<HStack as="header" justify="space-between">
				<HStack>
					<Icon icon="mdi:cog" width={30} />
					<Heading size={"lg"}>{t("configuracoes")}</Heading>
				</HStack>
				<Tooltip label={editMode ? t("parar-edicao") : t("editar")} hasArrow>
					<IconButton
						colorScheme={editMode ? "red" : "purple"}
						onClick={handleToggleEditMode}
						variant="outline"
						aria-label={editMode ? t("parar-edicao") : t("iniciar-edicao")}
						icon={<Icon icon={editMode ? "mdi:pencil-off" : "mdi:pencil"} width={20} />}
					/>
				</Tooltip>
			</HStack>
			<Divider />
			<Grid gap="3">
				<HStack>
					<Icon icon="mdi:bell" width={18} />
					<Heading as="h2" size="md">
						{t("notificacoes")}
					</Heading>
				</HStack>
				<Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={4}>
					<SwitchForm
						isReadOnly={!editMode}
						control={control}
						name="disableNotifications"
						label={t("desabilitar-notificacoes-por-email")}
					/>
				</Grid>
			</Grid>
			{companyIsAgency && (
				<>
					<Divider />
					<Grid gap="3">
						<HStack>
							<Icon icon="fa-solid:building" width={18} />
							<Heading as="h2" size="md">
								{t("agencia")}
							</Heading>
						</HStack>
						<Grid
							templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
							gap={4}>
							<PriceInputForm
								control={control}
								name="valuePerHour"
								label="Preço por hora"
								isReadOnly={!editMode}
							/>
						</Grid>
					</Grid>
				</>
			)}
			<Collapse in={editMode} animateOpacity>
				<>
					<Divider mb={4} />
					<Grid
						templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
						gap={4}>
						<Button
							onClick={() => reset()}
							variant="outline"
							size="lg"
							isDisabled={!isDirty || isSubmitting}>
							{t("resetar")}
						</Button>
						<Button
							size="lg"
							isLoading={isSubmitting}
							isDisabled={!isDirty}
							onClick={handleSubmit(onSubmit)}>
							{t("atualizar")}
						</Button>
					</Grid>
				</>
			</Collapse>
		</Card>
	);
}
