import { Heading } from "@chakra-ui/react";
import firebase from "firebase";
import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { ValorRoteiro } from "../../Componentes/ValorRoteiro";

const styles = {
	principal: {
		marginTop: 10,
	},
	titulo: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	botao: {
		display: "flex",
		flexDirection: "row",
	},
	valorRoteiro: {
		backgroundColor: "white",
		fontSize: 35,
	},
	botoes: {
		display: "flex",
		flexDirection: "row",
		marginTop: 10,
		justifyContent: "center",
		alignItems: "center",
	},
};

class FaturamentoMensal extends Component {
	constructor(props) {
		super(props);
		const { empresa } = this.props;
		this.state = {
			valor: empresa?.faturamentoSolicitacao ?? empresa?.valorMaximoOportunidades ?? 0,
			mostrarFaturamento: empresa?.valorMaximoOportunidades ? false : true,
		};
	}

	enviar = () => {
		const { valor } = this.state;
		if (valor > 0 || this.props.empresa?.valorMaximoOportunidades) {
			return firebase.firestore().collection("empresas").doc(this.props.empresa?.uid).update({
				faturamentoSolicitacao: valor,
			});
		} else {
			return false;
		}
	};

	cancelar = () => {
		this.setState({ valor: 0 });
		return firebase.firestore().collection("empresas").doc(this.props.empresa?.uid).update({
			faturamentoSolicitacao: firebase.firestore.FieldValue.delete(),
		});
	};

	faturamento = () => this.setState({ mostrarFaturamento: !this.state.mostrarFaturamento });

	changeValor = (e, { name, value }) => this.setState({ valor: value });

	render() {
		const { t, empresa, faturamentoMensal } = this.props;
		const { valor, mostrarFaturamento } = this.state;
		const gastos = faturamentoMensal?.gastos;
		let maximo = empresa?.valorMaximoOportunidades ?? 0;
		let restante = isNaN(maximo - faturamentoMensal?.gastos)
			? 0
			: maximo - faturamentoMensal?.gastos;
		return (
			<div style={styles.principal}>
				<div style={styles.titulo}>
					<Heading as="h3" size="md" mb={2}>
						{t("limite.mensal")}
					</Heading>

					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}>
						{empresa?.faturamentoMensal === true &&
							empresa?.valorMaximoOportunidades && (
								<Button
									size="medium"
									color="purple"
									style={styles.botao}
									onClick={this.faturamento}
									content={mostrarFaturamento ? t("ocultar") : t("detalhar")}
								/>
							)}
					</div>
				</div>
				<span style={{ color: "gray" }}>{t("solicitar.faturamento.mensal")}</span>
				<br />
				<span style={{ color: "gray" }}>{t("nota.faturamento.mensal")}</span>
				{mostrarFaturamento ? (
					<>
						<ValorRoteiro
							style={styles.valorRoteiro}
							disabled={empresa?.faturamentoSolicitacao ? true : false}
							moeda="R$"
							placeholder={0}
							value={valor}
							onChange={this.changeValor}
						/>
						{!isNaN(restante) && (
							<div style={{ width: "100%", textAlign: "center" }}>
								<span
									style={{
										fontSize: 16,
										marginRight: 10,
										color: "gray",
										fontFamily: "inherit",
										fontWeight: 600,
										lineHeight: 1.2,
										textAlign: "center",
									}}>
									{t("seu.limite.atual.restante")}:{" "}
									{parseFloat(restante).toLocaleString("BRL", {
										style: "currency",
										currency: "BRL",
									})}
								</span>
							</div>
						)}
						<div style={styles.botoes}>
							{empresa?.faturamentoSolicitacao ? (
								<Button
									size="medium"
									color="red"
									icon="times"
									style={styles.botao}
									onClick={this.cancelar}
									content={t("cancelar.solicitacao")}
								/>
							) : (
								<Button
									size="medium"
									color="purple"
									icon="check"
									style={{
										display: "flex",
										flexDirection: "row",
										opacity:
											valor > 0 || empresa?.valorMaximoOportunidades
												? 1
												: 0.5,
									}}
									onClick={this.enviar}
									content={
										empresa?.valorMaximoOportunidades
											? t("solicitar.aumento")
											: t("solicitar.avaliacao")
									}
								/>
							)}
						</div>
					</>
				) : (
					<div style={{ marginTop: 30 }} />
				)}
			</div>
		);
	}
}

export default FaturamentoMensal;
