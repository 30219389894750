import moment from "moment";
import { mongoDBAtlas } from "../services/Api";
import { db } from "./../config";
import {
	roteirosExecutados,
	roteirosExecutadosFinalizados,
	roteirosNovos,
	roteirosPendentesEmExecucaoComPendencia,
} from "./Roteiros";
import { queryOportunidadesFaturamentoMensal } from "./mongo/queriesMongo";

export const tipoDeAtividadeToOption = (atividade, index) => ({
	key: atividade.id,
	icon: atividade.icone,
	text: atividade.descricao,
	value: atividade,
});

export const tipoDeRoteiroToOption = t => tipo => ({
	key: tipo.id,
	value: tipo,
	icon: tipo.icone,
	text: t(tipo.descricao),
});

export const buscarOportunidadesFaturamentoMensalMongoDB = async idEmpresa => {
	let mes = moment();
	let primeiroDia = mes.startOf("month").format("YYYY-MM-DD");
	let ultimoDia = mes.endOf("month").format("YYYY-MM-DD");
	const res = await mongoDBAtlas("POST", "roteiros", "/action/aggregate", {
		pipeline: [
			...queryOportunidadesFaturamentoMensal({
				uid: idEmpresa,
				status: [1, 2, 3, 5, 6],
				dataInicio: primeiroDia,
				dataFinal: ultimoDia,
			}),
			{
				$project: {
					loja: 1,
					usuario: 1,
					data: 1,
					pagamento: 1,
					statusRoteiro: 1,
					valor: 1,
				},
			},
		],
	});
	return res?.documents;
};

export const downloadOportunidadesEmFaturamentoMensal = idEmpresa => {
	let hoje = moment();
	let primeiroDia = hoje.subtract(2, "days").format("YYYY-MM-DD");
	let ultimoDia = hoje.endOf("month").format("YYYY-MM-DD");
	return db
		.collection("roteiros")
		.where("arquivado", "==", false)
		.where("idEmpresa", "==", idEmpresa)
		.where("pagamento.pagarFaturamentoMensal", "==", true)
		.where("statusRoteiro.id", "in", [1, 2, 3, 5, 6])
		.where("data", ">=", primeiroDia)
		.where("data", "<=", ultimoDia)
		.orderBy("data", "desc");
};

export const downloadRoteirosPendentesEmExecucaoComPendencia = (idEmpresa, data) =>
	roteirosPendentesEmExecucaoComPendencia(idEmpresa, false)
		.where("data", ">=", data)
		.orderBy("data", "desc");

export const downloadRoteirosExecutados = (idEmpresa, data) =>
	roteirosExecutados(idEmpresa, false)
		.where("data", "<=", data)
		.limit(10)
		.orderBy("data", "desc");

export const downloadRoteirosFinalizadosNaSemana = (idEmpresa, data) =>
	roteirosExecutadosFinalizados(idEmpresa, false)
		.where("data", ">=", data)
		.orderBy("data", "desc");

export const downloadRoteirosNovosSemUsuario = (idEmpresa, data) =>
	roteirosNovos(idEmpresa, false)
		.where("usuario", "==", null)
		.where("data", "<", data)
		.orderBy("data", "desc");

export const downloadRoteirosNovos = (idEmpresa, data) =>
	roteirosNovos(idEmpresa, false).where("data", ">=", data);

export const downloadLojasDaEmpresa = idEmpresa =>
	db.collection("lojas").where("idEmpresa", "==", idEmpresa);

export const downloadRoteirosExpirados = (idEmpresa, data) =>
	roteirosNovos(idEmpresa, false).where("data", "<", data);

export const downloadMarcasDaEmpresa = idEmpresa =>
	db.collection("produtos").where("idEmpresa", "==", idEmpresa);

export const downloadConfiguracao = () => db.collection("configuracao").where("id", "==", 1);

export const downloadMeusPromotores = idEmpresa =>
	db.collection("perfis").where("agencia", "==", idEmpresa);
