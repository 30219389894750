import { Center, Divider } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { OperationArea } from "..";
import { Card } from "../../../Componentes/Card";
import { EmptyMessage } from "../../../Componentes/EmptyMessage";
import { ErrorMessageWithRetry } from "../../../Componentes/ErrorMessageWithRetry";
import { LoadingFullPage } from "../../../Componentes/LoadingFullPage";
import { PageHeader } from "../../../Componentes/PageHeader";
import { GenericError } from "../../../errors/GenericError";
import { useOperationArea } from "../../../hooks/query/useOperationArea";
import { PATHS } from "../../../Utils/Routes";
import { EditOperationAreaForm } from "./EditOperationAreaForm";

export default function EditOperationArea() {
	const { t } = useTranslation();
	const { id: operationAreaId } = useParams<{ id: string }>();
	const {
		queryMethods: { data, isLoading, error, refetch, isFetching },
	} = useOperationArea();

	const foundedOperationArea = useMemo(() => {
		return data?.find(operationArea => operationArea._id === operationAreaId) || null;
	}, [data, operationAreaId]);

	const pageContent = useMemo(() => {
		if (isLoading)
			return (
				<LoadingFullPage
					minH="container.sm"
					text={t("buscando-area-de-atuacao").concat("...")}
				/>
			);

		if (error)
			return (
				<Center minH="container.sm">
					<ErrorMessageWithRetry
						error={new GenericError(error)}
						retryFn={refetch}
						retryButtonProps={{ isLoading: isFetching }}
					/>
				</Center>
			);

		if (!foundedOperationArea)
			return (
				<Center minH="container.sm">
					<Card p={10}>
						<EmptyMessage
							text={t("area-de-atuacao-inexistente")}
							description={t("aviso-verificar-id-area-de-atuacao")}
						/>
					</Card>
				</Center>
			);

		return <EditOperationAreaForm operationArea={foundedOperationArea} />;
	}, [error, foundedOperationArea, isFetching, isLoading, refetch, t]);

	return (
		<OperationArea.Layout>
			<PageHeader
				title={t("editar-area-de-atuacao")}
				subtitle={t("editando-no-momento-a-area-de-id", {
					id: operationAreaId.toUpperCase(),
				})}
				icon="mdi:vector-square-edit"
				breadcrumb={[
					{ name: "Dashboard", to: PATHS.dashboard.root },
					{ name: t("areas-de-atuacao"), to: PATHS.areaDeAtuacao.root },
					{ name: operationAreaId.toUpperCase() },
				]}
			/>
			<Divider />
			{pageContent}
		</OperationArea.Layout>
	);
}
