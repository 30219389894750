import { filter, keyBy, merge, values } from "lodash";
import {
	AUTENTICAR_USUARIO,
	ATUALIZAR_EMPRESA,
	DESAUTENTICA_USUARIO,
	CADASTRANDO_USUARIO,
	ATUALIZAR_INFO_CONTA,
	MEUS_PROMOTORES,
	ATUALIZAR_MERCADO,
	ATUALIZAR_SALDOS,
	ATUALIZAR_LIMITE,
	ATUALIZAR_USUARIOS,
	ATUALIZAR_OPORTUNIDADES_MONGO,
	LOGADO,
	NAO_LOGADO,
	CADASTRANDO,
	ALTERNAR_MODULO,
	IP_LOCATION,
	ATUALIZAR_FATURA,
	ATUALIZAR_OPORTUNIDADES_FIREBASE,
	ATUALIZAR_COORDENADAS,
} from "../_actions/Types";
import moment from "moment";

const definirModuloDaEmpresa = empresa =>
	empresa && empresa.tipo === 2
		? localStorage.getItem("modulo")
			? JSON.parse(localStorage.getItem("modulo"))
			: empresa.tipo
		: 1;

const INITIAL_STATE = {
	firebaseReady: false,
	modulo: 0,
	autenticado: NAO_LOGADO,
	empresa: null,
	usuario: null,
	infoConta: null,
	promotores: [],
	mercado: null,
	saldos: null,
	ipLocation: {},
	faturamentoMensal: {},
	fatura: [],
	usuarios: [],
	carregandoLimite: {
		mongo: true,
		firebase: true,
	},
	coordenadas: {},
};

const table = {
	[AUTENTICAR_USUARIO]: (state, action) => ({
		...state,
		usuario: action.usuario,
		firebaseReady: true,
		modulo: 0,
		autenticado: !!action.usuario ? LOGADO : NAO_LOGADO,
	}),
	[ATUALIZAR_INFO_CONTA]: (state, action) => ({
		...state,
		infoConta: action.infoConta,
	}),
	[ATUALIZAR_EMPRESA]: (state, action) => ({
		...state,
		modulo: definirModuloDaEmpresa(action.empresa),
		empresa: action.empresa,
	}),
	[ATUALIZAR_MERCADO]: (state, action) => ({
		...state,
		modulo: 3,
		mercado: action.empresa,
	}),
	[CADASTRANDO_USUARIO]: (state, action) => ({
		...state,
		autenticado: CADASTRANDO,
	}),
	[ALTERNAR_MODULO]: (state, action) => ({ ...state, modulo: action.modulo }),
	[MEUS_PROMOTORES]: (state, action) => ({
		...state,
		promotores: action.promotores,
	}),
	[IP_LOCATION]: (state, action) => ({
		...state,
		ipLocation: action.ipLocation,
	}),
	[ATUALIZAR_FATURA]: (state, action) => ({ ...state, fatura: action.fatura }),
	[DESAUTENTICA_USUARIO]: (state, action) => ({
		...state,
		firebaseReady: false,
		modulo: 0,
		autenticado: NAO_LOGADO,
		empresa: null,
		usuario: null,
		infoConta: null,
		promotores: [],
		mercado: null,
		saldos: null,
		ipLocation: {},
		faturamentoMensal: {},
		fatura: [],
	}),
	[ATUALIZAR_SALDOS]: (state, action) => {
		if (state.saldos == null) {
			return { ...state, saldos: { [action.saldo]: action.valor } };
		}
		var retorno = {
			...state,
			saldos: { ...state.saldos, [action.saldo]: action.valor },
		};
		if (
			retorno.saldos &&
			retorno.saldos.total !== undefined &&
			retorno.saldos.reservado !== undefined &&
			retorno.saldos.reservadoNovo !== undefined
		) {
			retorno.saldos.naoReservado =
				retorno.saldos.total - (retorno.saldos.reservado + retorno.saldos.reservadoNovo);
		}
		return retorno;
	},
	[ATUALIZAR_LIMITE]: state => {
		const merged = merge(
			keyBy(state.faturamentoMensal.oportunidadesMongo, "_id"),
			keyBy(state.faturamentoMensal.oportunidadesFirebase, "id")
		);
		const oportunidadesJuntas = values(merged);
		const dia = moment().format("YYYY-MM-DD");
		const gastos = oportunidadesJuntas.reduce(
			(
				soma,
				{ data, valor, selecaoAutomatica, selecaoAutomaticaFavorito, statusRoteiro }
			) => {
				if (selecaoAutomatica || selecaoAutomaticaFavorito) {
					if (moment(data).isBefore(dia) && statusRoteiro.id === 2) {
						return soma;
					}
					return soma + valor;
				} else if (statusRoteiro.id !== 1) {
					return soma + valor;
				}
				return soma;
			},
			0
		);

		const oportunidadesFinalizadas = filter(
			oportunidadesJuntas,
			oportunidade =>
				oportunidade.statusRoteiro.id === 3 || oportunidade.statusRoteiro.id === 5
		);
		const gastoMensalReal = _.reduce(
			oportunidadesFinalizadas,
			(valor, roteiro) => valor + roteiro.pagamento.valorTotal,
			0
		);

		state.oportunidadesFinalizadas = oportunidadesFinalizadas;
		state.gastoMensalReal = gastoMensalReal;
		state.quantidadeOportunidadesFinalizadas = oportunidadesFinalizadas.length;
		return {
			...state,
			faturamentoMensal: { ...state.faturamentoMensal, gastos },
		};
	},
	[ATUALIZAR_OPORTUNIDADES_MONGO]: (state, action) => {
		let { oportunidades } = action;
		return {
			...state,
			carregandoLimite: {
				...state.carregandoLimite,
				mongo: false,
			},
			faturamentoMensal: {
				...state.faturamentoMensal,
				oportunidadesMongo: oportunidades,
			},
		};
	},
	[ATUALIZAR_OPORTUNIDADES_FIREBASE]: (state, action) => {
		let { oportunidades } = action;
		return {
			...state,
			carregandoLimite: {
				...state.carregandoLimite,
				firebase: false,
			},
			faturamentoMensal: {
				...state.faturamentoMensal,
				oportunidadesFirebase: oportunidades,
			},
		};
	},
	[ATUALIZAR_USUARIOS]: (state, action) => ({
		...state,
		usuarios: action.usuarios,
	}),
	[ATUALIZAR_COORDENADAS]: (state, action) => ({
		...state,
		coordenadas: action.coordenadas,
	}),
};

export default (state = INITIAL_STATE, action) => {
	const transform = table[action.type];
	if (transform) return transform(state, action);
	return state;
};
