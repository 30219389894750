import {
	Button,
	ButtonProps,
	Divider,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	ModalProps,
	useBreakpointValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useBoolean } from "react-use";
import { Company } from "../../@types/Company";
import { Negotiation } from "../../@types/Negotiation";
import { ModalControllerProvider } from "../../contexts/ModalControllerContext";
import { isProposal } from "../../helpers/Negotiation";
import { ModalShowOpportunityNegotiationFooter } from "./ModalShowOpportunityNegotiationFooter";
import { NegotiationBallon } from "./NegotiationBallon";
import { NegotiationLogRibbon } from "./NegotiationLogRibbon";

interface ModalShowOpportunityNegotiationProps
	extends Omit<ModalProps, "children" | "isOpen" | "onClose"> {
	negotiation: Negotiation;
	buttonProps?: ButtonProps;
	executor?: Company | null;
}

export function ModalShowOpportunityNegotiation({
	negotiation,
	buttonProps,
	executor,
	...restProps
}: ModalShowOpportunityNegotiationProps) {
	const { t } = useTranslation();
	const modalController = useDisclosure();
	const [isLoading] = useBoolean(false);
	const modalSize = useBreakpointValue({ base: "full", sm: "4xl" });

	/**
	 * Logs e propostas ordenadas juntas para exibição sequencial.
	 */
	const itemsUnion = useMemo(() => {
		const unionArray = [...negotiation.propostas, ...negotiation.logs];
		return orderBy(unionArray, ["criadoEm"]);
	}, [negotiation.logs, negotiation.propostas]);

	const bodyComponents = useMemo(() => {
		return itemsUnion.map(item => {
			if (isProposal(item)) {
				/**
				 * Variável que guarda em qual posição do array  de propostas esse item está.
				 * @obs Ação necessária, pois perdemos a rastreabilidade dos itens quando mesclamos propostas com logs.
				 */
				const proposalRealIndex = negotiation.propostas.findIndex(
					proposal => proposal.id === item.id
				);

				return (
					<NegotiationBallon
						key={item.id}
						data={item}
						previousValue={negotiation.propostas[proposalRealIndex - 1]?.valor}
						isActive={proposalRealIndex === negotiation.propostas.length - 1}
						negotiation={negotiation}
						executor={executor}
					/>
				);
			}

			return <NegotiationLogRibbon key={item.id} log={item} />;
		});
	}, [executor, itemsUnion, negotiation]);

	return (
		<ModalControllerProvider controller={modalController}>
			<Button
				size="lg"
				flex={1}
				leftIcon={<Icon icon="material-symbols:order-approve-rounded" width={25} />}
				onClick={modalController.onOpen}
				colorScheme="blue"
				{...buttonProps}>
				{t("ver-proposta")}
			</Button>
			<Modal
				{...restProps}
				isCentered
				onClose={modalController.onClose}
				scrollBehavior="inside"
				size={modalSize}
				isOpen={isLoading || modalController.isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t("proposta")}</ModalHeader>
					<ModalCloseButton />
					<Divider />
					<ModalBody p={0}>
						<VStack
							borderRadius="lg"
							minH={"xl"}
							flex={1}
							p={4}
							justify="flex-end"
							spacing={4}>
							{bodyComponents}
						</VStack>
					</ModalBody>
					<ModalShowOpportunityNegotiationFooter
						negotiation={negotiation}
						handleCloseModal={modalController.onClose}
					/>
				</ModalContent>
			</Modal>
		</ModalControllerProvider>
	);
}
