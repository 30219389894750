import {
	Badge,
	Button,
	Center,
	Divider,
	Flex,
	Progress,
	Skeleton,
	Spinner,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useBreakpoint,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generateArray } from "toolkit-extra";
import { ReceiptStatus } from "../../@types/Receipt";
import { Card } from "../../Componentes/Card";
import { CardReceipt } from "../../Componentes/CardReceipt";
import { EmptyContentMessage } from "../../Componentes/EmptyContentMessage";
import { ErrorMessageWithRetry } from "../../Componentes/ErrorMessageWithRetry";
import { PageHeader } from "../../Componentes/PageHeader";
import {
	useAllReceiptsStatus,
	usePendingReceipts,
	useReceipts,
} from "../../hooks/query/useReceipts";
import { PATHS } from "../../Utils/Routes";
import PageLayout from "../Layout";
import { ReceiptCustomerTable } from "./ReceiptCustomerTable";

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Página que mostra as notas fiscais pendentes para envio pela agência logada.
 */
export default function AgencyReceipt() {
	const [currentTabIndex, setCurrentTabIndex] = useState(0);

	const { t } = useTranslation();
	const { totals, underAnalysisReceipts, acceptedReceipts, rejectedReceipts } =
		useAllReceiptsStatus();

	return (
		<PageLayout>
			<PageHeader
				title={t("notas-fiscais")}
				subtitle={t("subTitulo-enviar-notas-fiscais")}
				icon="fa-solid:file-invoice-dollar"
				breadcrumb={[
					{ name: "Dashboard", to: PATHS.dashboard.root },
					{ name: t("notas-fiscais") },
				]}
			/>
			<Divider />
			<Tabs variant="enclosed-colored" isFitted onChange={setCurrentTabIndex}>
				<TabList overflowX="auto" overflowY="hidden">
					<Tab roundedTopLeft={"lg"}>
						{t("pendente-de-envio")} <Badge ml={2}>{totals.pendingReceipts}</Badge>
					</Tab>
					<Tab display={"flex"} gap={2}>
						{underAnalysisReceipts.isFetching && <Spinner size="xs" />}
						{t("em-analise")}{" "}
						<Badge colorScheme="yellow">{totals.underAnalysisReceipts}</Badge>
					</Tab>
					<Tab display={"flex"} gap={2}>
						{acceptedReceipts.isFetching && <Spinner size="xs" />}
						{t("aceitas")} <Badge colorScheme="green">{totals.acceptedReceipts}</Badge>
					</Tab>
					<Tab roundedTopRight={"lg"} display={"flex"} gap={2}>
						{rejectedReceipts.isFetching && <Spinner size="xs" />}
						{t("recusadas")} <Badge colorScheme="red">{totals.rejectedReceipts}</Badge>
					</Tab>
				</TabList>

				<TabPanels border={"1px"} borderColor="gray.200" roundedBottom={"lg"}>
					{/* NOTE: Para forçar a re-renderização inicial dos formulários, sempre que sair da tab, o componente é desmontado e remondado quando só quando voltar para a tab 0. */}
					<TabPanel>{currentTabIndex === 0 && <PendingReceiptsOfSendTabs />}</TabPanel>
					<TabPanel>
						<RestReceiptsTabs
							status={ReceiptStatus["Enviado para análise"]}
							title={t("notas-ficais-enviadas-para-analise")}
							subtitle={t("explicacao-tab-notas-fiscais-em-analise")}
							icon="mdi:clock"
						/>
					</TabPanel>
					<TabPanel>
						<RestReceiptsTabs
							status={ReceiptStatus["Aceita"]}
							title={t("notas-ficais-aceitas")}
							subtitle={t("explicacao-tab-notas-fiscais-aceitas")}
							icon="mdi:check"
						/>
					</TabPanel>
					<TabPanel>
						<RestReceiptsTabs
							status={ReceiptStatus["Recusada"]}
							title={t("notas-ficais-recusadas")}
							subtitle={t("explicacao-tab-notas-fiscais-recusadas")}
							icon="mdi:close"
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</PageLayout>
	);
}

function PendingReceiptsOfSendTabs() {
	const { t } = useTranslation();
	const pendingReceipts = usePendingReceipts();

	if (pendingReceipts.length === 0)
		return (
			<Center minH={{ base: "sm", md: "container.sm" }}>
				<EmptyContentMessage
					maxW={"lg"}
					title={t("nao-ha-nada-aqui")}
					message={t("aviso-sem-notas-fiscais-pendentes")}
				/>
			</Center>
		);

	return (
		<>
			{pendingReceipts.map(receipt => (
				<ReceiptCustomerTable key={receipt.customerCnpj} receipt={receipt} />
			))}
		</>
	);
}

interface RestReceiptsTabsProps {
	status: ReceiptStatus;
	pageSize?: number;
	staleTime?: number;
	icon: string;
	title: string;
	subtitle: string;
}

/**
 * Renderiza uma tab de conteúdo de listagem de notas ficais para o status "Enviado para analise", "Aceitas" ou "Recusadas".
 */
function RestReceiptsTabs({
	status,
	pageSize,
	staleTime,
	title,
	subtitle,
	icon,
}: RestReceiptsTabsProps) {
	const { t } = useTranslation();
	const isSm = useBreakpoint() === "sm";
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		total,
		error,
		refetch,
		isLoading,
		isFetching,
	} = useReceipts({
		status,
		pageSize,
		staleTime,
	});

	const content = useMemo(() => {
		if (isLoading)
			return generateArray(5).map(uid => <Skeleton key={uid} h="40" rounded={"md"} />);

		if (error) {
			return (
				<Center minH={{ base: "sm", md: "container.sm" }}>
					<ErrorMessageWithRetry
						error={error}
						retryFn={refetch}
						retryButtonProps={{ isLoading: isFetching }}
					/>
				</Center>
			);
		}

		if (!total)
			return (
				<Center minH={{ base: "sm", md: "container.sm" }}>
					<EmptyContentMessage
						maxW={"lg"}
						title={t("nao-ha-nada-aqui")}
						message={t("aviso-sem-notas-fiscais")}
					/>
				</Center>
			);

		return data?.pages.map(page =>
			page.data.map(receipt => <CardReceipt key={receipt.id} receipt={receipt} />)
		);
	}, [data?.pages, error, isFetching, isLoading, refetch, t, total]);

	return (
		<Flex flexDir={"column"} gap={4}>
			<Card>
				<PageHeader
					title={title}
					icon={icon}
					subtitle={subtitle}
					actions={
						isFetching ? (
							isSm ? (
								<Progress size="xs" isIndeterminate w="full" />
							) : (
								<Spinner />
							)
						) : undefined
					}
				/>
			</Card>
			<Divider />
			{content}
			{hasNextPage && (
				<Button onClick={() => fetchNextPage()} isLoading={isFetchingNextPage}>
					{t("carregar-mais")}
				</Button>
			)}
		</Flex>
	);
}
