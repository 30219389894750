import { Badge } from "@material-ui/core";
import { ReactNode, useMemo } from "react";
import { NegotiationStatus } from "../../@types/Negotiation";
import { useNegotiations } from "../../hooks/useNegotiations";

interface MenuCountBadgeProps {
    children: ReactNode;
    variant: "negociacoes";
}

interface BadgeProps {
    children: ReactNode;
}

/**
 * Renderiza um contador superior dependendo de qual menu está sendo renderizado como `children`.
 */
export function MenuCountBadge({ children, variant }: MenuCountBadgeProps) {
    if (variant === "negociacoes") return <NegotiationBadge>{children}</NegotiationBadge>;

    return children;
}

/**
 * Renderiza um contador de negociações não expiradas.
 */
export function NegotiationBadge({ children }: BadgeProps) {
    const {
        state: { negotiations },
    } = useNegotiations();

    const pendingNegotiationCount = useMemo(() => {
        return negotiations.reduce((acc, negotiation) => {
            return negotiation.status !== NegotiationStatus.EXPIRADO ? acc + 1 : acc;
        }, 0);
    }, [negotiations]);

    return (
        <Badge badgeContent={pendingNegotiationCount} color="secondary">
            {children}
        </Badge>
    );
}
