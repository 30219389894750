import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import Currency from 'react-currency-formatter';
import { useTranslation } from 'react-i18next';

/**
 * Gráfico de rosca e pizza que é exibido nos dias da semana da dashboard, feito em SVG
 * @param {Todas as infos necessárias para construção do gráfico} props 
 */
const DonutPieChart = (props) => {
    const { t } = useTranslation();
    return (
        <div style={{ width: '75%', marginTop: -10, marginBottom: -10 }}>
            <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
                <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
                <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" strokeWidth="3"></circle>
                {Object.entries(props.consolidado).map(([key, value]) => <circle key={key} className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke={value.cor} strokeWidth="3" strokeDasharray={`${value.percentual} ${value.sobra}`} strokeDashoffset={value.offset}></circle>)}
                <g className="chart-text">
                    <text x="50%" y="50%" className="chart-number">
                        {props.qtd}
                    </text>
                    <text x="50%" y="50%" className="chart-label">
                        {t('oport')}
                    </text>
                </g>
            </svg>
        </div>
    )
}

/**
 * Realiza a consolidação dos dados para criação dos gráficos exibidos nos dias de semana
 * @param {oportunidades do dia} oportunidades 
 * @param {dia em questao} dia 
 */
export const statusOportunidades = (oportunidades, dia) => {
    var total = oportunidades.length
    let consolidado = {
    }
    const calculaOffset = () => {
        var percentualAnteriores = 0
        Object.entries(consolidado).forEach(([key, val]) => percentualAnteriores += (val.percentual ?? 0))
        return 100 - percentualAnteriores + 25
    }

    const calculaPercentual = numero => numero === 0 ? 0 : numero / total * 100
    if (dia.isSameOrBefore(moment(), 'day')) {
        consolidado.executadas = {}
        consolidado.executadas.numero = (_.filter(oportunidades, ['statusRoteiro.id', 3])?.length ?? 0) + (_.filter(oportunidades, ['statusRoteiro.id', 5])?.length ?? 0)
        consolidado.executadas.offset = 25
        consolidado.executadas.percentual = calculaPercentual(consolidado.executadas.numero)
        consolidado.executadas.sobra = 100 - consolidado.executadas.percentual
        consolidado.executadas.cor = '#35BE35'
    }

    if (dia.isSameOrAfter(moment(), 'day')) {
        consolidado.pendentes = {}
        consolidado.pendentes.numero = _.filter(oportunidades, ['statusRoteiro.id', 2])?.length ?? 0
        consolidado.pendentes.offset = calculaOffset()
        consolidado.pendentes.percentual = calculaPercentual(consolidado.pendentes.numero)
        consolidado.pendentes.sobra = 100 - consolidado.pendentes.percentual
        consolidado.pendentes.cor = '#FF6F00'
    }

    if (dia.isSame(moment(), 'day')) {
        consolidado.execucao = {}
        consolidado.execucao.numero = _.filter(oportunidades, ['statusRoteiro.id', 6])?.length ?? 0
        consolidado.execucao.offset = calculaOffset()
        consolidado.execucao.percentual = calculaPercentual(consolidado.execucao.numero)
        consolidado.execucao.sobra = 100 - consolidado.execucao.percentual
        consolidado.execucao.cor = '#1E88E5'
    }

    if (dia.isBefore(moment(), 'day')) {
        consolidado.naoExecutada = {}
        consolidado.naoExecutada.numero = total - consolidado.executadas.numero
        consolidado.naoExecutada.offset = calculaOffset()
        consolidado.naoExecutada.percentual = calculaPercentual(consolidado.naoExecutada.numero)
        consolidado.naoExecutada.sobra = 100 - consolidado.naoExecutada.percentual
        consolidado.naoExecutada.cor = '#F44336'
    }

    return consolidado
}

/**
 * Renderiza os cards de cada dia da semana exibido na dashboard
 * @param {dia, oportunidades, changeDay, atual} param0 
 */
export const DiaSemana = ({ dia, oportunidades, changeDay, atual }) => {
    var consolidado = statusOportunidades(oportunidades, dia)
    return (
        <div onClick={changeDay(dia)} className={`dia-semana ${dia.isSame(atual, 'day') ? 'atual' : ''}`}>
            <div className='dia-titulo'>
                {dia.format('ddd')}
            </div>
            <div className='dia-dados'>
                <DonutPieChart qtd={oportunidades?.length} consolidado={consolidado}></DonutPieChart>
            </div>
            <div className='dia-valor'>
                <Currency
                    quantity={_.reduce(oportunidades, (valor, oportunidade) => {
                        if (dia.isBefore(moment(), 'day')) {
                            if (oportunidade.statusRoteiro.id === 3 || oportunidade.statusRoteiro.id === 5) {
                                return valor + oportunidade.valor
                            }
                            return valor
                        }
                        return valor + oportunidade.valor
                    }, 0)}
                    locale="pt_BR"
                    decimal=','
                    group='.'
                    currency="R$ "
                />
            </div>
        </div>
    )
}