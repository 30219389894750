import Configuration from '../configuration.json'
/**
 * @name buscaProdutos
 * @date Criado em 25/11/2020
 * @author diego jimenes <contato.diegojimenes@gmail.com>
 * @description busca produtos em nossa api node com mongo
 * @param {string} idEmpresa IdEmpresa
 * @param {string} descricao Descrição do produto
 * @param {Object} axios axios
 * @param {Object} firebase firebase
 */
export const buscaProdutos = (idEmpresa, descricao, axios, firebase, paginaAtual) => {
    return firebase.auth().currentUser.getIdToken(true).then(
        token_id => {
            var url = `${Configuration.nodeAPIURL}/produto/search`
            var config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token_id
                }
            }
            var data = {
                "search": descricao,
                "idEmpresa": idEmpresa,
                "pagina": paginaAtual,
                "dev": Configuration.dev,
            }
            
            if(!paginaAtual) delete data.pagina

            return axios.post(url, data, config).then(
                response => {
                    return response
                }
            )
        })

}

/**
* @name paginacaoProdutos
* @date Criado em 5/12/2020
* @author Vitor Savian
* @description busca produtos já com a paginação em nossa api node com mongo
* @param {String} idEmpresa IdEmpresa
* @param {String} descricao Descrição do produto
* @param {String} preco Preço do produto
* @param {Object} axios axios
* @param {Object} firebase firebase
* @param {Number} page pagina que vai ir pro paginaitor
*/
export const paginacaoProdutos = (idEmpresa, descricao, preco, axios, firebase, page) => {
    return firebase.auth().currentUser.getIdToken(true).then(
        token_id => {
            var url = `${Configuration.nodeAPIURL}/produto/paginationSearch`
            var config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer" + token_id
                }
            }
            console.log(`chamando api do node. dev: ${process.env.NODE_ENV === 'development'}`)
            var data = {
                "search": descricao,
                "preco": preco,
                "idEmpresa": idEmpresa,
                "page": page, 
                "dev": Configuration.dev,
            }
            return axios.post(url, data, config).then(
                response => {
                    return response
                }
            )
        }
    )
}