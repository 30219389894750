import { Heading } from "@chakra-ui/react";
import { map } from "lodash";
import moment from "moment";
import React from "react";
import { OpportunitySegment } from "../../../Componentes/OpportunitySegment";

export default ({ t, roteiros }) =>
    map(roteiros, (_roteiros, data) => (
        <div key={data}>
            <Heading mb="7px" mt="30px!important" size="md">
                {moment(data).format("LLLL")}
            </Heading>
            {map(_roteiros, (roteiro) => (
                <OpportunitySegment
                    opportunity={roteiro}
                    key={roteiro.id}
                    color={roteiro.usuario ? "green" : "purple"}
                    variant="new"
                />
            ))}
        </div>
    ));
