import { ChakraTheme, extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const config: Partial<ChakraTheme> = {
	colors: {
		purple: {
			"50": "#fbf3ff",
			"100": "#f5e3ff",
			"200": "#eccbff",
			"300": "#dea2ff",
			"400": "#c968ff",
			"500": "#b52fff",
			"600": "#a408ff",
			"700": "#9000f7",
			"800": "#7501c0",
			"900": "#6403a0",
			"950": "#45007a",
		},

		primary: {
			"50": "#fbf3ff",
			"100": "#f5e3ff",
			"200": "#eccbff",
			"300": "#dea2ff",
			"400": "#c968ff",
			"500": "#b52fff",
			"600": "#a408ff",
			"700": "#9000f7",
			"800": "#7501c0",
			"900": "#6403a0",
			"950": "#45007a",
		},
	},
	components: {
		Input: {
			defaultProps: {
				focusBorderColor: "purple.500",
				size: "lg",
			},
		},
		Textarea: {
			defaultProps: {
				focusBorderColor: "purple.500",
				size: "lg",
			},
		},
		Spinner: {
			baseStyle: {
				color: "purple.500",
			},
		},
		Switch: {
			defaultProps: {
				size: "md",
				mb: 0,
			},
		},
		NumberInput: {
			defaultProps: {
				focusBorderColor: "purple.500",
			},
		},
		Divider: {
			baseStyle: {
				borderColor: "gray.300",
			},
		},
	},
};

export const theme = extendTheme(
	config,
	withDefaultColorScheme({ colorScheme: "purple" }),
	withDefaultColorScheme({ colorScheme: "gray", components: ["Table"] })
);
