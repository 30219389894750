import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	InputGroup,
	Textarea,
	TextareaProps,
} from "@chakra-ui/react";
import { Control, FieldPath, RegisterOptions, useController } from "react-hook-form";

interface TextAreaFormProps<T> extends TextareaProps {
	label?: string;

	//NOTE: React Hook Form
	enableTextError?: boolean;
	control?: Control<T>;
	name: FieldPath<T>;
	rules?: RegisterOptions<T>;
}

export function TextAreaForm<T>({
	label,
	control,
	name,
	rules,
	enableTextError = true,
	isDisabled,
	isReadOnly,
	isRequired,
	...rest
}: TextAreaFormProps<T>) {
	const {
		field: { onChange, value, ref },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
	});

	return (
		<FormControl isInvalid={!!error} isDisabled={isDisabled} isReadOnly={isReadOnly}>
			{!!label && (
				<FormLabel
					htmlFor={name}
					_after={isRequired ? { content: `' *'`, color: "red" } : {}}>
					{label}
				</FormLabel>
			)}
			<InputGroup>
				<Textarea
					{...rest}
					w="full"
					ref={ref}
					name={name}
					onChange={onChange}
					value={value as string}
				/>
			</InputGroup>
			{!!error && enableTextError && <FormErrorMessage>{error.message}</FormErrorMessage>}
		</FormControl>
	);
}
