import { Box } from "@chakra-ui/react";
import { Zoom } from "@material-ui/core";
import firebase from "firebase";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { ModalDeConfirmacao } from "../../Componentes/ConfirmarRegistro";
import { db } from "../../config";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { logging } from "../../services/Api";
import { ROTEIRO_EM_EXECUCAO, ROTEIRO_EXECUTADO } from "../../Utils/Roteiros";
import { direcionarPromotor } from "../../_actions/RoteiroActions";

const SemPromotores = props => {
	const { t } = props;
	return (
		<div style={{ width: "100%", textAlign: "center", paddingTop: "10%" }}>
			<span>{t("NenhumPromotorEncontrado")}</span>
		</div>
	);
};

function Direcionar({ roteiro }) {
	const { t } = useTranslation();

	const empresa = useAppSelector(state => state.AutenticacaoReducer).empresa;
	const promotores = useAppSelector(state => state.AutenticacaoReducer).promotores;
	const ipLocation = useAppSelector(state => state.AutenticacaoReducer).ipLocation;

	const dispatch = useAppDispatch();

	const [busca, setBusca] = useState("");
	const formatFone = text => {
		return text.slice(3).replace(/(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
	};
	const [confirmModal, setConfirmModal] = useState(false);

	async function cancelarCandidaturaAgencia() {
		try {
			//NOTE: Atualizamos o roteiro
			await db.collection("roteiros").doc(roteiro.id).update({
				usuario: firebase.firestore.FieldValue.delete(),
				statusAgencia: firebase.firestore.FieldValue.delete(),
				"statusRoteiro.id": 1,
				anulacaoPorEmpresa: true,
			});

			logging("DELETE", "log.cancelar.selecao.promotor", `Roteiro: ${roteiro.id}`);
		} catch (error) {
			console.log(error);
		}
	}

	const handleOpenCloseModal = () => {
		setConfirmModal(!confirmModal);
	};

	return (
		<Box>
			<ModalDeConfirmacao
				t={t}
				isOpen={confirmModal}
				titulo={t("cancelar")}
				message={<p style={{ marginTop: 10 }}>{t("confirmar.agencia.cancelamento")}</p>}
				onConfirm={cancelarCandidaturaAgencia}
				onCancel={handleOpenCloseModal}
			/>
			{roteiro.statusAgencia !== 2 && (
				<div
					style={{
						width: "100%",
						height: 45,
						borderRadius: 50,
						border: "1px solid purple",
					}}>
					<i
						aria-hidden="true"
						className="search disabled icon"
						style={{
							color: "#333",
							position: "absolute",
							zIndex: 5,
							marginTop: 12.5,
							marginLeft: 10,
						}}></i>
					<input
						style={{
							width: "100%",
							height: "100%",
							borderRadius: 50,
							paddingLeft: 35,
							outline: "none",
						}}
						type="search"
						placeholder="Buscar Promotor"
						className="search-promoter"
						onChange={e => setBusca(e.target.value)}
					/>
				</div>
			)}
			<Zoom
				in={roteiro && roteiro.statusAgencia === 1}
				mountOnEnter
				unmountOnExit
				style={{
					transitionDelay: roteiro && roteiro.statusAgencia === 1 ? "100ms" : "0ms",
				}}>
				{promotores.length > 0 ? (
					<div>
						{promotores.reduce((acc, e) => {
							var data = e.data();
							if (data.nome.toLowerCase().includes(busca.toLowerCase())) {
								acc.push(
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											width: "100%",
											justifyContent: "space-between",
											alignItems: "center",
											marginTop: 20,
											marginBottom: 20,
										}}>
										<div style={{ display: "flex", flexDirection: "row" }}>
											<div
												style={{
													width: 60,
													height: 60,
													borderRadius: 60,
													marginRight: 15,
													background: `url(${data.foto})`,
													backgroundSize: "100%",
													backgroundPosition: "center",
													display: "flex",
													alignSelf: "center",
												}}
											/>

											<div
												style={{
													display: "flex",
													flexDirection: "column",
												}}>
												<h4 style={{ margin: 0 }}>{data.nome}</h4>
												<span>{`${data.rua}, ${data.numero} - ${data.cidade}/${data.uf}`}</span>
												<span>{formatFone(data.telefone)}</span>
											</div>
										</div>
										<Button
											onClick={() =>
												dispatch(
													direcionarPromotor(
														roteiro.id,
														{
															statusAgencia: 2,
															promotor: data.uid,
														},
														roteiro
													)
												)
											}
											to={"/direcionar/" + roteiro.id}
											basic
											content={t("direcionar")}
											icon="angle right"
											labelPosition="right"
										/>
									</div>
								);
							}
							return acc;
						}, [])}
					</div>
				) : (
					<SemPromotores t={t} />
				)}
			</Zoom>
			{!roteiro.promotor && (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "right",
					}}>
					<Button
						onClick={handleOpenCloseModal}
						basic
						color="red"
						content={t("cancelar-selecao")}
					/>
				</div>
			)}

			<Zoom
				in={roteiro && roteiro.statusAgencia === 2}
				mountOnEnter
				unmountOnExit
				style={{
					transitionDelay: roteiro && roteiro.statusAgencia === 2 ? "100ms" : "0ms",
				}}>
				{promotores.length > 0 ? (
					<div>
						{promotores.map(pmt => {
							var data = pmt.data();
							if (data.uid === roteiro.promotor) {
								return (
									<div
										style={{
											width: "100%",
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
										}}>
										<h4 style={{ marginBottom: 25, color: "#7501c0" }}>
											PROMOTOR ESCOLHIDO
										</h4>
										<div style={{ width: 250 }}>
											<div
												style={{
													border: "1.5px solid #7501c0",
													borderRadius: 20,
												}}>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
													}}>
													<div
														style={{
															background: "#fff",
															marginTop: -12.5,
															width: 50,
															textAlign: "center",
														}}>
														<i
															aria-hidden="true"
															className="star icon"
															style={{
																color: "#7501c0",
																fontSize: 25,
																marginLeft: 4,
															}}></i>
													</div>
													<div
														style={{
															margin: 15,
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}>
														<div
															style={{
																width: 100,
																height: 100,
																borderRadius: 100,
																marginBottom: 15,
																marginTop: 0,
																background: `url(${data.foto})`,
																backgroundSize: "100%",
																backgroundPosition: "center",
															}}
														/>
														<h4>{data.nome}</h4>
														<span>{`${data.rua}, ${data.numero} - ${data.cidade}/${data.uf}`}</span>
														<span>{formatFone(data.telefone)}</span>
													</div>
												</div>
											</div>
											{!(
												roteiro.statusRoteiro.id === ROTEIRO_EXECUTADO ||
												roteiro.statusRoteiro.id === ROTEIRO_EM_EXECUCAO
											) && (
												<button
													onClick={() =>
														dispatch(
															direcionarPromotor(roteiro.id, {
																statusAgencia: 1,
																promotor: "",
															})
														)
													}
													style={{
														width: "100%",
														height: 40,
														marginTop: 15,
														background:
															"linear-gradient(45deg, #4F1E63 0%, #662780 100%)",
														border: "none",
														borderRadius: 20,
														color: "#fff",
														outline: "none",
														cursor: "pointer",
													}}>
													Trocar Promotor
												</button>
											)}
										</div>
									</div>
								);
							}

							return <div></div>;
						})}
					</div>
				) : (
					<div></div>
				)}
			</Zoom>
		</Box>
	);
}

export default Direcionar;
