import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0.5rem 10rem;
    gap: 1.75rem;

    @media (max-width: 1300px) {
        margin: 0.5rem 7rem;
    }

    @media (max-width: 1024px) {
        margin: 0.5rem 4rem;
    }

    @media (max-width: 775px) {
        margin: 0.5rem 1rem;
    }
`;

export const Content = styled.div`
    display: flex;
    gap: 1.75rem;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;
