import { Modal } from "semantic-ui-react";
import { Container, Text } from "./styles";
import { propsModalBloqueioSistema } from "../../@types/componentes/modal";

export function ModalBloqueioSistema({
	aberto,
	setAberto,
	titulo,
	mensagem,
}: propsModalBloqueioSistema) {
	return (
		<Modal open={aberto} onClose={() => setAberto()} dimmer="blurring" size="tiny">
			<Container>
				<Text fontSize="25px" fontWeight="bold">
					{titulo}
				</Text>
				<Text fontSize="16px" fontWeight="normal">
					{mensagem}
				</Text>
			</Container>
		</Modal>
	);
}
