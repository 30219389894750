import { Badge } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Popup } from "semantic-ui-react";
import HeaderPadrao from "../../../Componentes/HeaderPadrao";
import { ButtonWrapper, Header } from "./styles";

interface LogListHeaderProps {
	isFetching: boolean;
	refetch: () => void;
	setShowFilterController: Dispatch<SetStateAction<boolean>>;
	countFiltersInUse: number;
}

export function LogListHeader({
	isFetching,
	refetch,
	setShowFilterController,
	countFiltersInUse,
}: LogListHeaderProps) {
	const { t } = useTranslation();

	return (
		<Header>
			<HeaderPadrao icone="bug" titulo="Logs" subtitulo={t("lista.de.logs")} />
			<ButtonWrapper>
				<Popup
					position="top center"
					content={t("abrir-filtros")}
					trigger={
						<Badge
							badgeContent={countFiltersInUse}
							color="primary"
							anchorOrigin={{ vertical: "top", horizontal: "left" }}>
							<Button
								icon
								onClick={() => setShowFilterController(oldState => !oldState)}>
								<Icon name="filter" />
							</Button>
						</Badge>
					}
				/>
				<Popup
					position="top center"
					content={t("refazer-busca")}
					trigger={
						<Button icon onClick={() => refetch()} loading={isFetching}>
							<Icon name="sync" />
						</Button>
					}
				/>
			</ButtonWrapper>
		</Header>
	);
}
