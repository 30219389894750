import React, { Component } from 'react'
import { Button, Modal, Icon, Header, Checkbox } from 'semantic-ui-react'
import { db } from '../../config';
import moment from 'moment';
import firebase from 'firebase';

export default class ModalTermos extends Component {
    state = {
        checked: false,
        modal: false
    }

    salvarTermos = () => {
        db.collection('empresas')
            .doc(this.props.empresa.uid)
            .update({
                termos: {
                    ...this.props.empresa.termos,
                    versaoEmpresa: this.props.configuracao.termos.versaoEmpresa,
                    dataEmpresa: moment().format('YYYY-MM-DD HH:mm')
                }
            })
        this.setState({ modal: false })
    }

    termos = () =>
        firebase.storage().ref(this.props.modulo && this.props.modulo === 1 ? 'Termos-De-Uso/Termos_de_uso_Empresa.pdf' : 'Termos-De-Uso/Termos_de_uso_Agencia.pdf').getDownloadURL()
            .then((url) => window.open(url))
            .catch((error) => console.log(error))

    render() {
        const { configuracao, empresa } = this.props
        const { checked, modal } = this.state
        const versaoConfiguracao = configuracao && configuracao.termos && configuracao.termos.versaoEmpresa
        const versao = empresa && empresa.termos && empresa.termos.versaoEmpresa
        const diferente = versaoConfiguracao !== null

        return (
            <div>
                <Modal
                    open={diferente && (!versao || diferente) && (versao !== versaoConfiguracao || modal)}>
                    <Modal.Header>Os Termos de uso foram alterados</Modal.Header>
                    <Modal.Content>
                        <p style={{ textAlign: "center", fontSize: 16 }}>Você deve ler e aceitar os novos termos para continuar</p>
                    </Modal.Content>

                    <div style={{ display: 'flex', justifyContent: 'center', padding: 10, color: 'gray', border: '1px solid #eee', borderRadius: 3 }} onClick={this.termos}>
                        <Header as='h3' icon size="huge">
                            <Icon name='book' />
                            Visualizar termos
                        </Header>
                    </div>
                    <br />
                    <Checkbox
                        onClick={() => this.setState({ checked: !checked })}
                        label='Aceito os termos de uso'
                        style={{ paddingBottom: 15, fontSize: 16, display: 'flex', justifyContent: 'center' }}
                    />

                    <Modal.Actions style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            disabled={!checked}
                            onClick={this.salvarTermos}
                            positive
                            labelPosition='right'
                            icon='checkmark'
                            content='Aceito'
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}
