import { OpportunityVariant } from "../Opportunity";

/**
 * Status que uma nota fiscal pode ter no fluxo de validação do envio para a Tradepro.
 */
export enum ReceiptStatus {
	/**
	 * Nota fiscal foi enviada para a Tradepro e aguarda análise pelo nosso time.
	 */
	"Enviado para análise",
	/**
	 * Nota fiscal foi revisada e aceita pelo time da Tradepro.
	 */
	"Aceita",
	/**
	 * Nota fiscal foi rejeitada pelo time da Tradepro e deve ser re-enviada.
	 */
	"Recusada",
}

/**
 * Mapeia um objeto de nota fiscal no app.
 */
export interface Receipt {
	id: string;
	createdAt: string;
	updatedAt: string;
	/**
	 * Data em que a nota fiscal foi analisada pela Tradepro.
	 */
	analyzedAt?: string;
	/**
	 * Usuário que analisou a nota fiscal.
	 */
	analyzedBy?: {
		id: string;
		name: string;
		email: string;
	};
	/**
	 * Comentário no analista da Tradepro
	 */
	analysisComment?: string;
	status: ReceiptStatus;
	/**
	 * Conhecido como emissor da nota fiscal.
	 */
	sender: {
		id: string;
		name: string;
		isAgency: boolean;
		cnpj: string;
		email: string;
	};
	/**
	 * Conhecido como consumidor da nota fiscal.
	 */
	consumer: {
		id: string;
		name: string;
		isTradepro: boolean;
		cnpj: string;
		email: string;
	};
	/**
	 * Array com os ids das oportunidades associadas para uma localização facilitada.
	 */
	opportunityIds: string[];
	/**
	 * Array com as oportunidades associadas a nota fiscal.
	 * @obs Na prática, só deve haver oportunidades finalizadas e/ou avaliadas aqui.
	 */
	opportunities: OpportunityReceipt[];
	/**
	 * Arquivo da nota fiscal guardado no nosso storage.
	 */
	file: {
		url: string;
		storageKey: string;
	};
	/**
	 * Valor total da nota fiscal
	 */
	value: number;
}

/**
 * Uma oportunidade associada a uma nota fiscal.
 * @obs Tem uma quantidade de dados resumida para se adequar a estar dentro de uma nota fiscal.
 */
export interface OpportunityReceipt {
	id: string;
	startedAt: string;
	finishedAt: string;
	date: string;
	variant: OpportunityVariant;
	promoter: {
		id: string;
		name: string;
		phone: string;
	};
	pdv: {
		id: string;
		name: string;
		address: string;
	};
	/**
	 * Valor total liquido da oportunidade (descontando taxa de serviço Tradepro)
	 */
	value: number;
	/**
	 * Valor bruto da oportunidade (sem descontar taxa de serviço Tradepro)
	 */
	grossValue: number;
	/**
	 * Valor de taxa de serviço aplicada na oportunidade.
	 */
	feeValue: number;
	/**
	 * Percentual de taxa de serviço aplicada a oportunidade.
	 */
	feePercentage: number;
}
