import { Heading } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Message, Segment } from "semantic-ui-react";
import { OpportunitySegment } from "../../Componentes/OpportunitySegment";
import RoteiroConnect from "../../Interno/Roteiro/RoteiroConnect";
import { orderBy, roteirosAgencia, snapToRoteirosPorData } from "../../Utils/Roteiros";
import { salvarOportunidadeAgencia } from "../../_actions/RoteiroActions";
import { paginator } from "./../../Utils/RoteiroPaginator";
import estados from "./estados";
const axios = require("axios");
var DATA_ATUAL = moment().format("YYYY-MM-DD");
class BuscarOportunidades extends Component {
	state = {
		filtroAberto: false,
		carregando: false,
		location: { lat: 0, lng: 0 },
		address: {},
		filtro: { data: DATA_ATUAL, campo: "", estado: "" },
		swGeopoint: 0,
		neGeopoint: 0,
		raio: 15000,
	};

	// busca endereço atual para setar como default do filtro
	buscarEndereco = ({ lat, lng }) =>
		axios
			.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`)
			.then(response => {
				var {
					data: {
						address: { city, state },
					},
				} = response;
				this.setState(
					{
						address: { city, state },
						filtro: { data: DATA_ATUAL, campo: "estado", estado: state },
						carregando: false,
					},
					() => this.buildPaginator()
				);
			});

	componentDidMount() {
		this.setState(
			{
				carregando: true,
			},
			() =>
				navigator.geolocation.getCurrentPosition(
					position => {
						this.buscarEndereco({
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						});
					},
					() => {
						let es = estados.find(x => x.sigla === this.props.empresa.endereco.estado);
						this.setState(
							{
								address: {
									city: this.props.empresa.cidade,
									state: this.props.empresa.endereco.cidade,
								},
								filtro: { data: DATA_ATUAL, campo: "estado", estado: es.nome },
								carregando: false,
							},
							() => this.buildPaginator()
						);
					}
				)
		);
	}

	// executa a primeira busca de oportunidades
	buildPaginator = event => {
		var callback = ({ data }) => this.props.dispatch(salvarOportunidadeAgencia(data));
		!_.isEmpty(this.state.address) &&
			paginator().get(roteirosAgencia(this.state.filtro, false), callback, 10, "buscar");
	};
	render() {
		const { t } = this.props;
		const carregando = this.state.carregando;
		const oportunidades = orderBy(
			snapToRoteirosPorData(this.props.oportunidadesAgencia || [], {}),
			"asc"
		);
		const paginatorEnd = paginator().hasEnded();

		return (
			<>
				{_.isEmpty(this.props.oportunidadesAgencia) ? (
					<Message content={t("nenhuma.oportunidade.publicada")}></Message>
				) : (
					_.map(oportunidades, (grupo, data) => (
						<div key={data} className="cor-padrao">
							<Heading mb="7px" mt="30px!important" size="md">
								{moment(data).format("LLLL")}
							</Heading>
							{_.map(grupo, roteiro => (
								<OpportunitySegment
									key={roteiro.id}
									opportunity={roteiro}
									color="violet"
									mode="agency"
								/>
							))}
						</div>
					))
				)}
				{paginatorEnd === false && (
					<Segment basic textAlign="center">
						<Button
							content="Carregar mais"
							loading={carregando}
							onClick={() => {
								var callback = ({ data }) => {
									this.props.dispatch(salvarOportunidadeAgencia(data));
									this.setState({ carregando: false });
								};
								this.setState({ carregando: true }, () =>
									paginator().get(
										roteirosAgencia(this.state.filtro, false),
										callback,
										10,
										"buscar"
									)
								);
							}}
							basic
							color="violet"></Button>
					</Segment>
				)}
			</>
		);
	}
}

export default RoteiroConnect(withTranslation()(BuscarOportunidades));
