import _ from "lodash";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { v4 } from "uuid";
import { db } from "../../../config";
import { useSnackbar } from "notistack";
import firebase from "firebase";

/**
 * Função do pipeline que grava o modelo no firebase.
 */
export const salvarModelo = (_roteiro, nomeModelo, idModelo) => {
    try {
        const id = idModelo ? idModelo : v4();
        let roteiro = { ..._roteiro };
        if (roteiro) {
            delete roteiro.anulacaoPorEmpresa;
            delete roteiro.candidatos;
            delete roteiro.chatEmpresa;
            delete roteiro.chatUsuario;
            delete roteiro.acesso;
            delete roteiro.fotoEntrada;
            delete roteiro.fotosDemanda;
            delete roteiro.fotoDeposito;
            delete roteiro.iniciadoEm;
            delete roteiro.finalizadoEm;
            delete roteiro.observacaoChecklist;
            delete roteiro.cadastroPorRecorrencia;
            delete roteiro.promotoresBloqueados;
            _.map(roteiro.secoes, (secao) => {
                if (secao) {
                    delete roteiro.secoes[secao.id].etapa;
                    delete roteiro.secoes[secao.id].finalizado;
                    delete roteiro.secoes[secao.id].finalizadoEm;
                    delete roteiro.secoes[secao.id].fotoAntes;
                    delete roteiro.secoes[secao.id].fotoDepois;
                    delete roteiro.secoes[secao.id].concorrentes;
                    delete roteiro.secoes[secao.id].motivoNaoReposto;
                }
                _.map(secao.produtos, (produto, index) => {
                    if (produto) {
                        delete roteiro.secoes[secao.id].produtos[index].reposto;
                        delete roteiro.secoes[secao.id].produtos[index].ruptura;
                        delete roteiro.secoes[secao.id].produtos[index].pesquisaDePreco;
                        delete roteiro.secoes[secao.id].produtos[index].pesquisaDeValidade;
                        delete roteiro.secoes[secao.id].produtos[index].quantidade;
                    }
                });
            });

            delete roteiro.data;
            delete roteiro.dataFim;
            delete roteiro.promotor;
            delete roteiro.quantidadeAtividadesExecutadas;
            delete roteiro.statusNotificacaoVisita;
            delete roteiro.statusAgencia;
            delete roteiro.usuario;
            delete roteiro.pagamento;
            delete roteiro.documento;
            roteiro.totalCandidatos = 0;
            roteiro.statusRoteiro = {
                id: 1,
            };
            delete roteiro.planogramas;
            roteiro.pagamento = {
                taxa: _roteiro.pagamento.taxa,
                valorPromotor: _roteiro.pagamento.valorPromotor,
                percentualTaxa: _roteiro.pagamento.percentualTaxa,
                valorTotal: _roteiro.valor,
                novo: true,
            };
        }
        roteiro.valor = parseFloat(roteiro.valor) || 0;
        if (roteiro?.loja?.endereco?.coordenada?._latitude) {
            const { GeoPoint } = firebase.firestore;
            roteiro.loja.endereco.coordenada = new GeoPoint(
                roteiro.loja.endereco.coordenada._latitude,
                roteiro.loja.endereco.coordenada._longitude
            );
        }

        const modelo = { nome: nomeModelo, roteiro, id };
        return db.collection("modelosRoteiro").doc(modelo.id).set(modelo);
    } catch (e) {
        return Promise.reject();
    }
};

/**
 * Transforma as informações do roteiro para gravar
 * no firebase.
 */
const commitarModelo = (context) => {
    const { roteiro, nome } = context;
    return salvarModelo(roteiro, nome, null)
        .then(() => context)
        .catch(Promise.reject());
};

export default (props) => {
    const { t, roteiro } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [nome, setNome] = useState("");
    const [confirmar, setConfirmar] = useState(false);
    const changeNome = (e) => setNome(e.target.value);
    const toggleConfirmar = () => setConfirmar(!confirmar);
    const notificar = (mensagem, variant) => enqueueSnackbar(mensagem, { variant });
    return (
        <>
            <Modal className="clonar-oportunidade" open={props.open}>
                <Modal.Header>{t("criar.modelo")}</Modal.Header>
                <Modal.Content>
                    <Form.Input
                        type="text"
                        value={nome}
                        onChange={changeNome}
                        name="oportunidade"
                        required
                        fluid
                        label={t("criar.modelo.oportunidade")}
                        placeholder={t("nome.modelo")}
                        icon={"copy"}
                        iconPosition="left"
                    />
                    <Button
                        icon="check"
                        color="purple"
                        className="purple-gradient-87"
                        content={t("criar.modelo")}
                        style={{ opacity: nome ? 1 : 0.5, marginTop: 10 }}
                        onClick={nome ? toggleConfirmar : null}
                    ></Button>
                    <Button basic color="red" style={{ marginTop: 10 }} onClick={props.fechar}>
                        {t("cancelar")}
                    </Button>
                </Modal.Content>
            </Modal>
            <Modal open={confirmar} className="modal-confirmacao">
                <Modal.Content>
                    <div className="modal-confirmacao-texto">
                        <p>
                            <b>{t("dejeja.criar.modelo")}</b>
                        </p>
                    </div>
                    <br />
                    <br />
                    <div className="modal-confirmacao-buttons">
                        <Button onClick={props.confirmar} basic color="red" content="Não" />
                        <Button
                            onClick={() =>
                                commitarModelo({ roteiro, nome })
                                    .then(toggleConfirmar)
                                    .then(props.fechar)
                                    .then(() => notificar(t("modelo.salvo"), "success"))
                                    .catch((e) => {
                                        toggleConfirmar();
                                        props.fechar();
                                        notificar(t("erro.salvar.modelo"), "error");
                                    })
                            }
                            className="green-gradient-light"
                            color="green"
                            content="Sim"
                        />
                    </div>
                </Modal.Content>
            </Modal>
        </>
    );
};
