import React, { Component } from 'react';
import firebase from 'firebase'
import { Icon, Image, Rating, Segment } from 'semantic-ui-react'
import moment from 'moment'
import _ from 'lodash'
import './mercado.css'
import { formatCNPJ } from '../Utils/CNPJ'
export default class Loja extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loja: {
                fantasia: "",
                codigo: "",
                contato: {
                    nome: "", email: "", telefone: ""
                },
                endereco: {
                    endereco: ""
                }
            },
            candidatos: [],
            loadMei: false,
            mei: {
                cnpj: "",
                rua: "",
                uf: "",
                cidade: "",
                numero: "",
                telefone: ""
            },
            roteiroAtivo: '',
            lastId: '',
            dataInicio: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            datafim: moment().add(7, 'days').format('YYYY-MM-DD'),
        };
    }
    componentDidMount() {
        var id = this.props.match.params.id
        firebase.firestore()
            .collection('lojasHomologadas')
            .doc(id)
            .get()
            .then((d) => this.setState({ loja: d.data() }))
            .then(() => this.buscarRoteiros(id))
    }

    buscarRoteiros = (id) => {
        firebase.firestore()
            .collection('roteiros')
            .where("data", ">=", this.state.dataInicio)
            .where("data", "<=", this.state.datafim)
            .where("statusRoteiro.id", "==", 2)
            .where("loja.id", "==", id)
            .orderBy("data","desc")
            .limit(300)
            .get()
            .then((s) => s.docs.map(e => {
                var { atividades, data, horaFim, horaInicio, tipo, usuario, id } = e.data()
                return { atividades, data, horaFim, horaInicio, tipo, usuario, id }
            }))
            .then((v) => this.setState({ candidatos: v }))
    }

    buscarDadosUsuario = (id, tipo, roteiro) => {
        var path = tipo === "Agência" ? "empresas" : "perfis"
        if (this.state.lastId !== roteiro) {
            this.setState({ loadMei: true, lastId: roteiro }, () => {
                firebase.firestore().collection(path).doc(id).get().then((s) => {
                    var obj = (d, p) => {
                        if (p === "empresas") {
                            return {
                                cnpj: d.cnpj,
                                rua: d.endereco.logradouro,
                                uf: d.endereco.estado,
                                cidade: d.endereco.cidade,
                                numero: d.endereco.numero,
                                telefone: d.telefone
                            }
                        } else {
                            return {
                                cnpj: d.cnpj,
                                rua: d.ruaEmpresa,
                                uf: d.ufEmpresa,
                                cidade: d.cidadeEmpresa,
                                numero: d.numeroEmpresa,
                                telefone: d.telefoneEmpresa
                            }
                        }
                    }
                    var data = obj(s.data(), path)
                    this.setState({ mei: data, loadMei: false, roteiroAtivo: roteiro })
                })
            })
        } else {
            this.setState({ loadMei: false, roteiroAtivo: this.state.lastId })
        }

    }

    sideBar = () => {
        var { fantasia, codigo, contato: { nome, email, telefone }, endereco: { endereco } } = this.state.loja
        return <div style={{ width: 300, marginRight: 30 }}>
            <div>
                <h2>{fantasia}</h2>
                <p style={{ fontSize: 20 }}>{formatCNPJ(codigo)}</p>
                <p>{endereco}</p>
                <hr />
            </div>
            <div>
                <h2>{this.props.t("contato")}</h2>
                <hr />
                <p>{nome}</p>
                <p>{email}</p>
                <p>{telefone}</p>
            </div>
        </div>
    }

    candidato = (c) => {
        return <Segment color='purple'>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Image src={c.usuario.foto ? c.usuario.foto : '/imagens/imagem-padrao-empresa.png'} avatar style={{ height: 50, width: 50 }} />
                    <div style={{ marginLeft: 20 }}>
                        <h3 style={{ fontSize: 17 }}>{c.usuario.nome}</h3>
                        <div>
                            <Icon name={c.tipo.icone} />
                            <Rating icon='star' defaultRating={c.usuario.mediaAvaliacao && c.usuario.quantidadeAvaliacao ? (c.usuario.mediaAvaliacao / c.usuario.quantidadeAvaliacao).toFixed(2) : 0} maxRating={5} disabled />
                        </div>
                    </div>
                </div>
                <p><Icon name="calendar alternate outline" />{moment(c.data).format('DD/MM/YYYY')}</p>
                <p><Icon name="clock" />{c.horaInicio} as {c.horaFim}</p>
                <button
                    style={{ border: 'none', outline: 'none', cursor: 'pointer' }}
                    onClick={() => {
                        if (this.state.roteiroAtivo !== '' && this.state.roteiroAtivo === c.id) {
                            this.setState({ roteiroAtivo: '' })
                        } else {
                            this.buscarDadosUsuario(c.usuario.uid, c.usuario.sexo, c.id)
                        }
                    }}>
                    <Icon name={(this.state.roteiroAtivo === c.id) && this.state.roteiroAtivo !== '' ? "chevron up" : "chevron down"} />
                </button>
            </div>
            {this.state.roteiroAtivo === c.id && this.state.loadMei === false ?
                <div style={{
                    marginLeft: '8%',
                    marginRight: '20%',
                    marginTop: 10
                }}>
                    <span>
                        {this.props.t('informacoes.mei')}
                    </span>
                    <hr />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                        <p><Icon name="file alternate" /> {this.state.mei.cnpj}</p>
                        <p><Icon name="point" /> {this.state.mei.rua},{this.state.mei.numero} - {this.state.mei.cidade}/{this.state.mei.uf}</p>
                        <p><Icon name="phone" /> {this.state.mei.telefone}</p>
                    </div>
                    <hr />
                    <span>
                        {this.props.t('atividades')}
                    </span>
                    <table style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginBottom: 5 }}>
                        <tbody>
                            {c.atividades.map(({ icone, descricao }) => {
                                return <tr>
                                    <td><Icon name={icone} /></td>
                                    <td>{descricao}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div> : null}
        </Segment>
    }

    listarCanditados = () => (
        <div style={{ width: "100%" }}>
            {this.filtro()}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {this.state.candidatos.length === 0 &&
                    <div style={{ width: '100%', marginTop: 50, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Icon name="users" size={'massive'} color="lightgrey" />
                <p>{this.props.t("nenhum.promotor.encontrado.periodo")}</p>
                    </div>}
                <div class="listPromotor">
                    {_.map(_.groupBy(this.state.candidatos, 'data'), (candidatos, data) => {
                        return <div>
                            <h2>{moment(data).format('LLLL')}</h2>
                            {candidatos.map((c) => {
                                return this.candidato(c)
                            })}
                        </div>
                    })}
                </div>
            </div>
        </div>
    )

    filtro = () => {
        return <div style={{ marginBottom: 20 }}>
            <input
                style={{
                    height: 40,
                    border: '1px solid #E5E5E5',
                    borderRadius: 50,
                    padding: 10,
                    marginRight: 10,
                    outline: 'none'
                }}
                type="date"
                value={this.state.dataInicio}
                onChange={(e) => this.setState({ dataInicio: e.target.value })} />
            <input
                style={{
                    height: 40,
                    border: '1px solid #E5E5E5',
                    borderRadius: 50,
                    padding: 10,
                    marginRight: 10,
                    outline: 'none'
                }}
                type="date"
                value={this.state.datafim}
                onChange={(e) => this.setState({ datafim: e.target.value })} />
            <button
                style={{
                    border: 'none',
                    background: '#5E2375',
                    height: 40,
                    borderRadius: 50,
                    outline: 'none',
                    cursor: 'pointer',
                    color: '#fff',
                    paddingLeft: 20,
                    paddingRight: 20,
                    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)'
                }}
                onClick={() => this.buscarRoteiros(this.props.match.params.id)}
            >
                {this.props.t("buscar")}
            </button>
        </div>
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {this.sideBar()}
                {this.listarCanditados()}
            </div>
        );
    }
}
