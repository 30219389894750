import pptRuptura from './../imagens/ppt_ruptura.png';
import pptFrontBackground from './../imagens/ppt_roteiro.png';
import pptInfoBackground from './../imagens/ppt_roteiro_info.png';
import pptAtividadeBackground from './../imagens/ppt_atividade.png';

export const blobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function() { resolve(reader.result) };
    reader.onerror = reject
    reader.readAsDataURL(blob);
});

export const requestAsBlob = url => fetch(url).then(response => response.blob());

export const importImageAsset = url => requestAsBlob(url).then(blobToBase64);

const pptLogoEmpresa = url => new Promise((resolved, rejected) => {
    var canvas = document.createElement('canvas');
    canvas.width = 196;
    canvas.height = 196;
    var context = canvas.getContext('2d');
    var imagem = new Image();
    imagem.crossOrigin = "Anonymous";
    imagem.onload = function() {
        context.beginPath();
        context.arc(98, 98, 98, 0, 2 * Math.PI);
        context.fillStyle = '#FFFFFF';
        context.fill();
        context.clip();
        context.drawImage(imagem, 0, 0, 196, 196);
        resolved(canvas.toDataURL());
    };
    imagem.onerror = function() { rejected(); };
    imagem.src = `https://cors-anywhere.herokuapp.com/${url}`;
});

export const importAssets = ctx => Promise.all([
    pptLogoEmpresa(ctx.assets.logo),
    importImageAsset(pptRuptura),
    importImageAsset(pptFrontBackground),
    importImageAsset(pptInfoBackground),
    importImageAsset(pptAtividadeBackground)
]).then(images => {
    const [logo, ruptura, front, info, pages] = images;
    return { ...ctx, assets: { logo, ruptura, front, info, pages } };
});
