import LinearProgress from "@material-ui/core/LinearProgress";
import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.br";
import firebase from "firebase";
import $ from "jquery";
import _ from "lodash";
import { Component, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button, Checkbox, Divider, Dropdown, Form, Grid, Header, Icon, Message } from "semantic-ui-react";
import { db } from "../../config";
import { FileCompressError } from "../../errors/File.erros";
import { formatCNPJ } from "../../Utils/CNPJ";
import { compressImage } from "../../Utils/File.utils";
import { HeaderPadrao } from "./../../Componentes/Componentes";
import { atualizarEmpresa } from "./../../_actions/AutenticacaoActions";
import { CompanyImage } from "./CompanyProfile/CompanyImage";
import { CompanySettingsForm } from "./CompanyProfile/CompanySettingsForm";
import EdicaoBancaria from "./EdicaoBancaria";

const nullableText = (text) => text || "-";
const estaEditando = (editando) => editando !== "view";

const viaCEPUrlBuilder = (cep) => `https://viacep.com.br/ws/${cep}/json/?callback=?`;

const Entry = (props) => (
    <div>
        <label className="entry-label">{props.label}</label>
        <p className="entry-text">{nullableText(props.text)}</p>
    </div>
);

const Required = (props) => (props.required ? <span style={{ color: "red" }}>*</span> : null);

const Field = (props) => {
    const { disabled, type, name, label, text, onBlur, onChange, required, width } = props;
    let wi = width ? { width } : {};
    return (
        <div style={{ marginBottom: "10px", ...wi }}>
            <label>
                {label} <Required required={required} />
            </label>
            <div className={`ui fluid ${disabled ? "disabled" : ""} input`}>
                <input
                    {...props}
                    name={name}
                    type={type || "text"}
                    value={text}
                    required={required}
                    disabled={disabled}
                    onChange={onChange || (() => {})}
                    onBlur={onBlur}
                />
            </div>
        </div>
    );
};

const Render = (props) => {
    const Renderer = props.render;
    return <Renderer label={props.label} text={props.text} {...props} />;
};

const MostrarErro = (props) =>
    props.erros && props.erros[props.name] ? (
        <Message color="red">
            <p>{props.erros[props.name]}</p>
        </Message>
    ) : null;

const EmailsAdicionais = (props) => {
    let value = props.empresa?.emailsAdicionais ?? {};
    const [emails, setEmail] = useState(value);
    const maior = _.max(_.map(emails, (e, key) => parseInt(key.split("-")[1])));
    const adicionar = () => {
        return (
            <center>
                {chaves.length ? null : (
                    <center>
                        <label style={{ fontWeight: props.render.name !== "Entry" ? "initial" : "bold" }}>
                            {props.t("emails.Adicionais")}
                        </label>
                        <p style={{ lineHeight: 0, color: "rgba(0,0,0,0.6)" }}>
                            {props.t("adicione.emails.adicionais")}
                        </p>
                        <br />
                    </center>
                )}
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        setEmail({ ...emails, [`email-${(maior ?? 0) + 1}`]: "" });
                    }}
                >
                    <Icon name="plus" /> Email Adicional
                </Button>
            </center>
        );
    };
    const removerEmail = (email) => {
        let em = emails;
        delete em[email];
        setEmail(em);
    };
    const chaves = Object.keys(emails);
    if (chaves.length) {
        return (
            <>
                <label style={{ fontWeight: props.render.name !== "Entry" ? "initial" : "bold" }}>
                    {props.t("emails.Adicionais")}
                </label>
                <p style={{ lineHeight: 0, color: "rgba(0,0,0,0.6)" }}>{props.t("adicione.emails.adicionais")}</p>
                <button
                    style={{ display: "none" }}
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                />
                {_.map(emails, (email, index) => {
                    return (
                        <div key={index} style={{ display: "flex", flexDirection: "row" }}>
                            <Render
                                {...props}
                                render={props.render}
                                required={false}
                                name={index}
                                width={"95%"}
                                // label={index}
                                text={value[index]}
                                {...props.disableFields}
                            />
                            {props.render.name !== "Entry" && (
                                <Button
                                    animated="vertical"
                                    style={{
                                        height: 40,
                                        marginTop: "auto",
                                        marginBottom: 10,
                                        marginLeft: 10,
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        removerEmail(index);
                                        props.onChange({ deletar: index });
                                    }}
                                >
                                    <Button.Content hidden>{props.t("excluir")}</Button.Content>
                                    <Button.Content visible>
                                        <Icon name="delete" />
                                    </Button.Content>
                                </Button>
                            )}
                        </div>
                    );
                })}
                {chaves.length < 3 && props.render.name !== "Entry" && adicionar()}
            </>
        );
    } else {
        return props.render.name !== "Entry" ? adicionar() : <></>;
    }
};

const Empresa = (props) => {
    const { erros, carregando, empresa, t, validarFantasia, validarRazaoSocial, validarTelefone, render, editando } =
        props;
    const disableFields = { disabled: carregando };

    return (
        <div>
            <Render
                {...props}
                render={render}
                required
                name="fantasia"
                label={t("nome.fantasia")}
                text={empresa.fantasia}
                onBlur={validarFantasia}
                {...disableFields}
            />
            <MostrarErro erros={erros} name={"fantasia"} />
            <Render
                {...props}
                render={render}
                required
                name="razaoSocial"
                label={t("razao.social")}
                text={empresa.razaoSocial}
                onBlur={validarRazaoSocial}
                {...disableFields}
            />
            <MostrarErro erros={erros} name={"razaoSocial"} />
            <Entry label={t("cnpj")} text={formatCNPJ(empresa.cnpj)} />
            <Entry label={t("inscricao.estadual")} text={empresa.inscEstadual} />
            <Entry label={t("email")} text={empresa.email} />
            {editando === "view-editando" &&
                EmailsAdicionais({ ...props, render, validarTelefone, disableFields, empresa, t })}
            <Render
                {...props}
                render={render}
                required
                name="telefone"
                label={t("telefone")}
                text={empresa.telefone}
                onBlur={validarTelefone}
                {...disableFields}
            />
            <MostrarErro erros={erros} name="telefone" />
        </div>
    );
};

const Endereco = (props) => {
    const { erros, carregando, carregandoCEP, empresa, t, render, buscarCep } = props;
    const disableFields = { disabled: carregando };
    return (
        <div>
            <Render
                {...props}
                render={render}
                required
                name="cep"
                label={t("cep")}
                text={empresa.endereco.cep}
                onBlur={buscarCep}
                {...disableFields}
            />
            {carregandoCEP && (
                <div className="form-group mt-0 d-flex flex-column">
                    <LinearProgress />
                </div>
            )}
            <MostrarErro erros={erros} name={"cep"} />
            <Render
                {...props}
                render={render}
                required
                name="logradouro"
                label={t("logradouro")}
                text={empresa.endereco.logradouro}
                {...disableFields}
            />
            <MostrarErro erros={erros} name={"logradouro"} />
            <Render
                {...props}
                render={render}
                required
                name="numero"
                label={t("numero")}
                text={empresa.endereco.numero}
                {...disableFields}
            />
            <MostrarErro erros={erros} name={"numero"} />
            <Render
                {...props}
                render={render}
                name="complemento"
                label={t("complemento")}
                text={empresa.endereco.complemento}
                {...disableFields}
            />
            <Render
                {...props}
                render={render}
                name="bairro"
                required
                label={t("bairro")}
                text={empresa.endereco.bairro}
                {...disableFields}
            />
            <MostrarErro erros={erros} name={"bairro"} />
            <Render
                {...props}
                render={render}
                name="cidade"
                required
                label={t("cidade")}
                text={empresa.endereco.cidade}
                {...disableFields}
            />
            <MostrarErro erros={erros} name={"cidade"} />
            <Render
                {...props}
                render={render}
                name="estado"
                required
                label={t("estado")}
                text={empresa.endereco.estado}
                {...disableFields}
            />
            <MostrarErro erros={erros} name={"estado"} />
        </div>
    );
};

const PerfilForm = (props) => (estaEditando(props.editando) ? <Form>{props.children}</Form> : props.children);

const MensagemDeError = (props) =>
    props.erros && props.erros.length > 0 ? (
        <Message color="red">
            {props.erros.map((erro, i) => (
                <p key={i}>{erro}</p>
            ))}
        </Message>
    ) : null;

const AlterarSenha = (props) => {
    const {
        erros,
        carregando,
        edicao,
        pristine,
        autenticacao,
        t,
        handleAlterarSenha,
        handleChangePassword,
        handleCancel,
    } = props;

    const { senha_atual, nova_senha, repeticao_senha } = autenticacao;

    const canSave =
        erros && erros.length === 0 && !pristine && senha_atual !== "" && nova_senha !== "" && repeticao_senha !== "";
    const disableFields = { disabled: carregando };

    return (
        <PerfilForm editando={true}>
            <Header as="h3">
                <Button
                    floated="right"
                    content="Salvar"
                    icon="disk"
                    color={edicao.color}
                    style={{ position: "relative" }}
                    type="submit"
                    {...{ disabled: !canSave, loading: carregando }}
                    basic
                    onClick={handleAlterarSenha}
                />
                <Button
                    floated="right"
                    content="Cancelar"
                    icon="cancel"
                    color="red"
                    style={{ position: "relative" }}
                    type="button"
                    onClick={handleCancel}
                />
                <Icon name="info" />
                <Header.Content>
                    {t("perfil.view.alterar.senha")}
                    <Header.Subheader>{edicao.subtitulo}</Header.Subheader>
                </Header.Content>
            </Header>
            <Divider />
            <Field
                name="senha_atual"
                type="password"
                label={t("perfil.view.senha.atual")}
                {...disableFields}
                onChange={handleChangePassword}
            />
            <Field
                name="nova_senha"
                type="password"
                {...disableFields}
                label={t("perfil.view.nova.senha")}
                onChange={handleChangePassword}
            />
            <Field
                name="repeticao_senha"
                type="password"
                {...disableFields}
                label={t("perfil.view.repetir.senha")}
                onChange={handleChangePassword}
            />
            <MensagemDeError erros={erros} />
        </PerfilForm>
    );
};

const EditarPerfil = (props) => {
    const {
        t,
        erros,
        pristine,
        carregando,
        carregandoCEP,
        editando,
        edicao,
        empresa,
        validarFantasia,
        validarRazaoSocial,
        validarTelefone,
        buscarCep,
        handleCancel,
        handleChangeEmpresaEndereco,
        handleChangeEmpresa,
        emailsAntesDaEdicao,
    } = props;

    const noErros = erros !== null && Object.values(erros || {}).reduce((acc, b) => !b, true);
    const canSave = !pristine && noErros;

    let button = { loading: carregando };
    button = edicao.label === "Editar" ? button : { ...button, disabled: !canSave };

    return (
        <PerfilForm editando={editando}>
            <Header as="h3">
                <Button
                    floated="right"
                    content={edicao.label}
                    icon={edicao.icon}
                    color={edicao.color}
                    basic
                    {...button}
                    style={{ position: "relative" }}
                    type="button"
                    onClick={edicao.action}
                />
                {estaEditando(editando) && (
                    <Button
                        floated="right"
                        content="Cancelar"
                        icon="cancel"
                        color="red"
                        style={{ position: "relative" }}
                        type="button"
                        onClick={handleCancel}
                    />
                )}
                <Icon name="info" />
                <Header.Content>
                    {t("perfil.view.empresa")}
                    <Header.Subheader>{edicao.subtitulo}</Header.Subheader>
                </Header.Content>
            </Header>
            <Divider />
            <Empresa
                t={t}
                carregando={carregando}
                render={edicao.render}
                erros={erros}
                empresa={empresa}
                onChange={handleChangeEmpresa}
                editando={editando}
                validarFantasia={validarFantasia}
                emailsAntesDaEdicao={emailsAntesDaEdicao}
                validarRazaoSocial={validarRazaoSocial}
                validarTelefone={validarTelefone}
            />
            <Header as="h3" style={{ marginTop: 50 }}>
                <Icon name="map marker alternate" />
                <Header.Content>{t("perfil.view.endereco")}</Header.Content>
            </Header>
            <Divider />
            <Endereco
                t={t}
                carregando={carregando}
                carregandoCEP={carregandoCEP}
                render={edicao.render}
                erros={erros}
                empresa={empresa}
                onChange={handleChangeEmpresaEndereco}
                buscarCep={buscarCep}
            />
            <Divider />
            <CompanySettingsForm />
        </PerfilForm>
    );
};

const table = {
    view: EditarPerfil,
    "view-editando": EditarPerfil,
    password: AlterarSenha,
    "edit-bank-acount": EdicaoBancaria,
};

const fixCleaveEvent = (name, fn) => (event) =>
    fn({
        target: { value: event.target.value, name },
    });

class Perfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carregando: false,
            erros: null,
            pristine: true,
            editando: "view",
            empresa: { ...props.empresa },
            autenticacao: null,
            fotoEmpresa: null,
            logoEmpresa: null,
            emailsAntesDaEdicao: { ...props.empresa.emailsAdicionais },
        };
        this.salvarEmpresa = this.salvarEmpresa.bind(this);
    }

    toggleEditing = () => {
        const editando = estaEditando(this.state.editando);

        this.setState(
            {
                carregando: false,
                erros: {},
                pristine: true,
                editando: editando ? "view" : "view-editando",
            },
            () => {
                new Cleave("input[name=telefone]", {
                    phone: true,
                    phoneRegionCode: "BR",
                    onValueChanged: fixCleaveEvent("telefone", this.handleChangeEmpresa),
                    onBlur: fixCleaveEvent("telefone", this.validarTelefone),
                });
                new Cleave("input[name=cep]", {
                    blocks: [5, 3],
                    delimiters: ["-"],
                    onValueChanged: fixCleaveEvent("cep", this.handleChangeEmpresaEndereco),
                    onBlur: fixCleaveEvent("cep", this.buscarCep),
                });
            }
        );
    };

    toggleAlterarSenha = () => {
        const editando = this.state.editando === "password";
        this.setState({
            erros: [],
            editando: editando ? "view" : "password",
            pristine: true,
            carregando: false,
            autenticacao: editando
                ? null
                : {
                      senha_atual: "",
                      nova_senha: "",
                      repeticao_senha: "",
                  },
        });
    };

    fileUploadHandler = async (event) => {
        var reader = new FileReader();
        var file = event.target.files[0];
        if (file) {
            try {
                const fileCompressed = await compressImage(file);

                reader.onload = function (e) {
                    this.setState({
                        editando: "view-editando",
                        logoEmpresa: e.target.result,
                    });
                }.bind(this);
                reader.readAsDataURL(fileCompressed);
            } catch (e) {
                if (e instanceof FileCompressError) {
                    console.log(e);
                    toast.warning(e.message);
                }
            } finally {
                event.target.value = "";
            }
        }
    };

    validarCampoPreenchido = (event) => {
        const { t } = this.props;
        const { name, value } = event.target;
        let erros = { ...this.state.erros };
        if (value === "") {
            erros[name] = t("campo.em.branco");
        } else {
            delete erros[name];
        }
        this.setState({ erros });
    };

    validarTelefone = (event) => {
        const { t } = this.props;
        const { name, value } = event.target;
        let erros = { ...this.state.erros };
        const mascaraDeTelefone = /\d{2}\s\d+\s\d{4}/;
        if (!mascaraDeTelefone.test(value)) {
            erros[name] = t("numero.invalido");
            value === "" && (erros[name] = t("campo.em.branco"));
        } else {
            delete erros[name];
        }
        this.setState({ erros });
    };

    buscarCep = (event) => {
        const { t } = this.props;
        const { empresa } = this.state;
        const { name, value } = event.target;
        let erros = { ...this.state.erros };

        if (empresa.endereco.cep === "") return;

        let hasError = false;
        const mascaraDeCEP = /\d{5}[\s-]?\d{3}/;
        if (!mascaraDeCEP.test(value)) {
            erros[name] = t("cep.invalido");
            hasError = true;
        }
        if (value === "") {
            erros[name] = t("campo.em.branco");
            hasError = true;
        }

        !hasError &&
            this.setState({ carregandoCEP: true }, () =>
                $.getJSON(viaCEPUrlBuilder(empresa.endereco.cep.replace("-", "")), (dados) => {
                    if (!("erro" in dados)) {
                        const endereco = {
                            ...empresa.endereco,
                            logradouro: dados.logradouro,
                            bairro: dados.bairro,
                            cidade: dados.localidade,
                            estado: dados.uf,
                        };

                        this.setState({
                            erros: { ...erros, cep: null },
                            empresa: { ...empresa, endereco },
                            carregandoCEP: false,
                        });
                    } else {
                        this.setState({
                            erros: { ...erros, cep: t("cep.nao.encontrado") },
                            carregandoCEP: false,
                        });
                    }
                })
            );
        hasError && this.setState({ erros });
    };

    resetState = () =>
        this.setState(
            {
                carregando: false,
                erros: null,
                editando: "view",
                fotoEmpresa: null,
                logoEmpresa: null,
                empresa: { ...this.props.empresa, emailsAdicionais: { ...this.state.emailsAntesDaEdicao } },
            },
            () => {
                this.props.dispatch(atualizarEmpresa(this.state.empresa));
            }
        );

    salvarEmpresa = (event) =>
        this.setState(
            {
                carregando: true,
            },
            () => {
                //REVIEW: Removemos alguns campos que são atualizados por outro local. Uma refatoração geral do Perfil é necessária para evitar esse comportamento.
                const { image, imagemURL, desabilitarNotificacoes, valorPorHora, ...rest } = this.state.empresa;
                db.collection("empresas")
                    .doc(this.props.empresa.uid)
                    .update(rest)
                    .then((x) =>
                        this.setState(
                            {
                                editando: "view",
                                carregando: false,
                                emailsAntesDaEdicao: this.state.empresa.emailsAdicionais,
                            },
                            () => this.props.dispatch(atualizarEmpresa(this.props.empresa))
                        )
                    )
                    .catch(this.firebaseError);
            }
        );

    reauthenticate = (currentPassword) => {
        var user = firebase.auth().currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
        return user.reauthenticateAndRetrieveDataWithCredential(cred);
    };

    setAutenticacaoErro = (mensagemErro) =>
        this.setState({
            ...this.state,
            carregando: false,
            erros: [mensagemErro],
        });

    firebaseError = (setErroState) => (error) =>
        setErroState(
            {
                "auth/wrong-password": this.props.t("perfil.view.senhas.errada.erro"),
                "auth/weak-password": this.props.t("perfil.view.senhas.fraca.erro"),
            }[error.code]
        );

    handleAlterarSenha = () => {
        const { nova_senha, senha_atual } = this.state.autenticacao;
        this.setState({ carregando: true }, () => {
            this.reauthenticate(senha_atual)
                .then(() => {
                    var user = firebase.auth().currentUser;
                    user.updatePassword(nova_senha)
                        .then(() =>
                            this.setState({
                                ...this.state,
                                carregando: false,
                                editando: "view",
                                autenticacao: null,
                                pristine: true,
                            })
                        )
                        .catch(this.firebaseError(this.setAutenticacaoErro));
                })
                .catch(this.firebaseError(this.setAutenticacaoErro));
        });
    };

    handleChangePassword = (event) => {
        const { t } = this.props;
        const { pristine } = this.state;
        const { name, value } = event.target;
        const { nova_senha, repeticao_senha } = this.state.autenticacao;
        let erros = [];

        if (["nova_senha", "repeticao_senha"].includes(name)) {
            nova_senha !== value && erros.push(t("perfil.view.senhas.diferentes.erro"));
            !pristine && nova_senha === "" && repeticao_senha === "" && erros.push(t("perfil.view.senhas.vazias.erro"));
        }

        this.setState({
            pristine: false,
            erros,
            autenticacao: { ...this.state.autenticacao, [name]: value },
        });
    };

    handleChangeEmpresa = (event, data) => {
        if (event.deletar) {
            let emails = this.state.empresa.emailsAdicionais ?? {};
            delete emails[event.deletar];
            return this.setState({
                pristine: false,
                erros: { ...this.state.erros },
                empresa: { ...this.state.empresa, emailsAdicionais: { ...emails } },
            });
        }

        const { t } = this.props;
        let erros = { ...this.state.erros };
        const { empresa } = this.state;

        const { name, value } = event.target;

        value === "" && (erros[name] = t("campo.em.branco"));

        if (name.includes("email-")) {
            let emails = empresa.emailsAdicionais ?? {};
            return this.setState({
                pristine: false,
                erros,
                empresa: { ...empresa, emailsAdicionais: { ...emails, [name]: value } },
            });
        }

        this.setState({
            pristine: false,
            erros,
            empresa: { ...empresa, [name]: value },
        });
    };

    handleChangeEmpresaEndereco = (event) => {
        const { t } = this.props;
        const { empresa, erros } = this.state;

        const mensagemDeErro =
            event.target.name !== "complemento" && event.target.value === "" ? t("campo.em.branco") : null;

        this.setState({
            pristine: false,
            erros: {
                ...erros,
                [event.target.name]: mensagemDeErro,
            },
            empresa: {
                ...empresa,
                endereco: {
                    ...empresa.endereco,
                    [event.target.name]: event.target.value,
                },
            },
        });
    };

    handleCancel = (event) => this.resetState();

    closeEditBank = () => this.setState({ editando: "view" });

    render() {
        const { carregando, carregandoCEP, editando, pristine, erros, autenticacao, empresa, emailsAntesDaEdicao } =
            this.state;
        const {
            t,
            empresa: { imagemURL, uid },
        } = this.props;

        const emEdicao = estaEditando(editando);

        const edicao = {
            subtitulo: emEdicao ? "Modo edição" : "Modo visualização",
            label: emEdicao ? "Salvar" : "Editar",
            icon: emEdicao ? "disk" : "pencil",
            color: emEdicao ? "green" : "grey",
            render: emEdicao ? Field : Entry,
            action: emEdicao ? this.salvarEmpresa : this.toggleEditing,
        };

        const CurrentView = table[this.state.editando];

        return (
            <Grid stackable>
                <Grid.Row>
                    <HeaderPadrao
                        icone="map signs"
                        titulo={t("perfil.view")}
                        subtitulo={t("perfil.view.visualizacao")}
                    />
                </Grid.Row>
                <Grid.Row>
                    <Grid stackable style={{ width: "100%" }}>
                        <Grid.Column width={4}>
                            <CompanyImage company={{ uid, image: imagemURL }} />
                            <Button
                                floated="right"
                                basic
                                fluid
                                content="Alterar senha"
                                icon="pencil"
                                style={{ position: "relative" }}
                                type="button"
                                onClick={this.toggleAlterarSenha}
                            />

                            <Button
                                floated="right"
                                basic
                                fluid
                                content={t("editar.dados.bancarios")}
                                icon="pencil"
                                style={{ position: "relative", marginTop: 7 }}
                                type="button"
                                onClick={() => this.setState({ editando: "edit-bank-acount" })}
                            />
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <CurrentView
                                t={t}
                                closeEditBank={this.closeEditBank}
                                carregando={carregando}
                                carregandoCEP={carregandoCEP}
                                pristine={pristine}
                                editando={editando}
                                edicao={edicao}
                                empresa={empresa}
                                erros={erros}
                                emailsAntesDaEdicao={emailsAntesDaEdicao}
                                autenticacao={autenticacao}
                                validarFantasia={this.validarCampoPreenchido}
                                validarRazaoSocial={this.validarCampoPreenchido}
                                validarTelefone={this.validarTelefone}
                                buscarCep={this.buscarCep}
                                handleCancel={this.handleCancel}
                                handleChangePassword={this.handleChangePassword}
                                handleChangeEmpresaEndereco={this.handleChangeEmpresaEndereco}
                                handleChangeEmpresa={this.handleChangeEmpresa}
                                handleAlterarSenha={this.handleAlterarSenha}
                            />
                        </Grid.Column>
                    </Grid>
                </Grid.Row>
            </Grid>
        );
    }
}

const mapsStateToProps = (state) => ({ ...state.AutenticacaoReducer });

export default connect(mapsStateToProps)(withTranslation()(Perfil));
