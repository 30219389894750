export function removeDuplicates<T>(arr: T[], equalityFn: (a: T, b: T) => boolean): T[] {
	const result: T[] = [];

	for (const item of arr) {
		if (!result.some(existingItem => equalityFn(existingItem, item))) {
			result.push(item);
		}
	}

	return result;
}
