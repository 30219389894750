import firebase from "firebase";
import { v4 } from "uuid";
// TODO: renomear essa função.
import { cadastrarEmpresa as _ce } from "./Api";

/**
 * Criar a conta no firebase para o usuário.
 */
export const createUser = (context) =>
    firebase
        .auth()
        .createUserWithEmailAndPassword(context.usuario.email, context.usuario.password)
        .then((snap) => ({ ...context, usuario: snap.user }))
        .catch((err) => Promise.reject({ ...context, error: err }));

/**
 * Envia o e-mail de verificação.
 */
export const enviarEmailDeVerificacao = (context) =>
    context.usuario
        .sendEmailVerification()
        .then(() => context)
        .catch((err) => Promise.reject({ ...context, error: err }));

/**
 * Pede o token do usuário recém logado.
 */
export const tokenDoUsuario = (context) =>
    firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => ({ ...context, token }))
        .catch((err) => {
            console.error(err);
            return { ...context, error: "unable_to_get_token", code: "auth/token" };
        });

const storageRefOf = (ref) => firebase.storage().ref().child(ref);

/**
 * Envia a imagem para o storage, caso seja definida.
 */
export const storeImage = (imagem) => (empresa) => {
    const imagemRef = storageRefOf(`empresas/${empresa.uid}`);

    const _empresa = { ...empresa, imagemURL: null };

    if (!imagem) {
        return Promise.resolve(_empresa);
    }

    return imagemRef
        .put(imagem)
        .then((x) => imagemRef.getDownloadURL().then((url) => ({ ..._empresa, imagemURL: url })))
        .catch(() => {
            console.warn("Falied to upload the image.");
            return _empresa;
        });
};

/**
 * Delega para a function a criação do objeto da empresa
 * e a criação da conta iugu.
 */
export const registrarEmpresa = (context) => {
    const empresa = {
        ...context.empresa,
        uid: v4(),
        idUsuario: context.usuario.uid,
        usuarios: [context.usuario.uid],
    };
    return _ce(context.token, empresa)
        .then((response) => ({ ...context, empresa: response.perfil }))
        .catch((err) => {
            console.log(err);
            return Promise.reject({
                ...context,
                error: "unable_to_call",
                code: "function/unable_to_call",
            });
        });
};

/**
 * Em caso de falha, o usuário será deletado.
 */
export const dropUsuario = (context) =>
    firebase
        .auth()
        .currentUser.delete()
        .then(() => Promise.reject(context))
        .catch(() => Promise.reject(context));

/**
 * Pipeline principal.
 * O context deve ser um objeto,
 * com a informação parcial do usuário (email e senha),
 * as informações da empresa e foto da empresa.
 */
export const cadastrarEmpresa = (context) =>
    createUser(context)
        .then(enviarEmailDeVerificacao)
        .then(tokenDoUsuario)
        .then((context) =>
            storeImage(context.fotoEmpresa)(context.empresa).then((empresa) => ({
                ...context,
                empresa,
            }))
        )
        .then(registrarEmpresa)
        .catch((context) => {
            return firebase.auth().currentUser ? dropUsuario() : Promise.reject(context);
        });

export const contaVerificadaIugu = (verificacao) => verificacao === true || verificacao === "verificado";

export const contaAguardandoOuVerificada = (verificacao) =>
    verificacao === true || ["aguardando", "verificado"].includes(verificacao);

export const contaNaoVerificadaIugu = (verificacao) => verificacao === false || verificacao === "erro";
