import { Evaluation } from "./Evaluation";
import { IPdv } from "./Pdv";
import { Promoter } from "./Promoter";
import { Training } from "./Training";

export interface Opportunity {
	_id?: string;
	id: string;
	acesso?: {
		teveAcesso?: boolean;
		motivo?: string;
	};
	arquivado: boolean;
	candidatos?: string[];
	cnpjEmpresa: string;
	criadoEm: string;
	data: string;
	dataFim: string;
	descricao: string;
	promotor?: string;
	documento: {
		status: number;
		url: string;
	} | null;
	emailEmpresa: string;
	enderecoEmpresa: string;
	fantasiaEmpresa: string;
	fotoDeposito?: string[];
	fotoEntrada?: string[];
	horaFim: string;
	horaInicio: string;
	idEmpresa: string;
	imagemEmpresa?: string;
	imagemURL: string | null;
	iniciadoEm?: string;
	finalizadoEm?: string;
	observacaoChecklist: string | null;
	encarregadoDeloja: {
		telefone: string;
		email: string;
	};
	loja: IPdv;
	notaFiscal: {
		id?: string;
		requerida: boolean;
		status?: number;
		numero?: string;
		url?: string;
	};
	pagamento: {
		novo: boolean;
		percentualTaxa: number;
		taxa: number;
		refNF?: string;
		valorPromotor: number;
		valorTotal: number;
		faturamentoMensal?: true;
		pagarFaturamentoMensal?: boolean;
		promotorPago: string;
		taxaPaga: string;
	};
	secoes?: any;
	selecaoAutomatica?: boolean;
	selecaoAutomaticaFavorito?: boolean;
	statusNotificacaoVisita?: number | null;
	statusRoteiro: {
		id: OpportunityStatus;
		avaliacao?: EvaluationInOpportunity | null;
	};
	tempoEstimado: string;
	tipo: {
		descricao: string;
		icone: string;
		id: number;
	};
	usuario: PromoterOrAgency;
	valor: number;
	treinamentos: Training[];
	statusAgencia?: number;
	tempoEstimadoObrigatorio?: boolean;
}

export enum OpportunityVariant {
	REPOSITIONS = 1,
	SEARCH = 4,
	TIME = 5,
}

export enum OpportunityStatus {
	"Aguardando Candidatos" = 1,
	"Pendente de Execução" = 2,
	"Executado" = 3,
	"Incompleta" = 4,
	"Finalizado" = 5,
	"Em Execução" = 6,
	"Expirado" = 7,
}

type PromoterOrAgency = Promoter & {
	idUsuario?: string;
};

export type EvaluationInOpportunity = Omit<Evaluation, "empresa">;
