import { Container } from "@chakra-ui/react";
import { ReactNode } from "react";

interface RedeLayoutProps {
	children: ReactNode;
}

export default function RedeLayout({ children }: RedeLayoutProps) {
	return (
		<Container maxW="container.xl" display="flex" flexDir="column" gap={5}>
			{children}
		</Container>
	);
}
