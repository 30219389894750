import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.br";
import $ from "jquery";
import React, { Component } from "react";
import { isValidCNPJ } from "@brazilian-utils/brazilian-utils";
import "./cadastro.css";
import { BotaoProximo } from "./CadastroEmpresa";
export default class Step2 extends Component {
	componentDidMount() {
		this.inputCNPJ = new Cleave($("#cnpj"), {
			blocks: [2, 3, 3, 4, 2],
			delimiters: [".", ".", "/", "-"],
		});
		this.inputTelefone = new Cleave($("#telefone"), {
			phone: true,
			phoneRegionCode: "BR",
		});
	}

	componentWillUnmount() {
		this.inputCNPJ = null;
		this.inputTelefone = null;
	}

	render() {
		return (
			<div className={this.props.step === 2 ? "d-block" : "d-none"}>
				<div>
					<div className="titulo">{this.props.t("dados.da.empresa")}</div>
					<div className="form-group">
						<input
							name="razaoSocial"
							type="text"
							required
							maxLength={60}
							value={this.props.empresa.razaoSocial}
							onChange={this.props.handleChangeEmpresa}
							placeholder={this.props.t("razao.social")}
						/>
					</div>
					<div className="form-group">
						<input
							name="fantasia"
							type="text"
							required
							maxLength={60}
							value={this.props.empresa.fantasia}
							onChange={this.props.handleChangeEmpresa}
							placeholder={this.props.t("nome.fantasia")}
						/>
					</div>
					<div className="form-group">
						{/* TODO: usar src/Componente/CNPJInput.js */}
						<input
							id="cnpj"
							name="cnpj"
							type="text"
							required
							maxLength={18}
							className={this.props.cnpjValido === false ? "inputError" : ""}
							value={this.props.empresa.cnpj}
							onChange={this.props.handleChangeEmpresa}
							placeholder={this.props.t("cnpj")}
							onBlur={event => {
								this.props.handleChangeEmpresa(event);
								this.props.setState({
									cnpjValido: isValidCNPJ(event.target.value),
								});
							}}
						/>
						{this.props.cnpjValido === false ? (
							<p style={{ textAlign: "left", paddingLeft: "18px" }}>
								{this.props.t("cnpj.invalido")}
							</p>
						) : null}
					</div>
					<div className="form-group">
						<input
							id="telefone"
							name="telefone"
							maxLength={13}
							value={this.props.empresa.telefone}
							onChange={this.props.handleChangeEmpresa}
							onBlur={this.props.handleChangeEmpresa}
							required
							type="phone"
							placeholder={"DDD + " + this.props.t("telefone")}
						/>
					</div>
				</div>
				<BotaoProximo t={this.props.t} />
			</div>
		);
	}
}
