import {
	Button,
	ButtonProps,
	Divider,
	Flex,
	Grid,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalProps,
	Text,
	Tooltip,
	useBreakpointValue,
	useDisclosure,
} from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import firebase from "firebase";
import { keys, size } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useBoolean } from "react-use";
import { v4 } from "uuid";
import { Company } from "../../@types/Company";
import {
	LogVariant,
	Negotiation,
	NegotiationLog,
	NegotiationStatus,
	Proposal,
} from "../../@types/Negotiation";
import { Opportunity } from "../../@types/Opportunity";
import { useAccountBalance } from "../../hooks/useAccountBalance";
import { useAppSelector } from "../../hooks/useAppSelector";
import { NotificationService } from "../../services/firebase/Notification";
import { OpportunityService } from "../../services/firebase/Opportunity";
import { fCurrency } from "../../Utils/Number";
import { PriceInput } from "../PriceInput";

interface ModalStartOpportunityNegotiationProps
	extends Omit<ModalProps, "children" | "isOpen" | "onClose"> {
	/**
	 * @description A agência que vai fazer parte da negociação.
	 */
	agency: Company;
	/**
	 * @description A oportunidade que vai ser negociada.
	 */
	opportunity: Opportunity;
	/**
	 * @description O valor da primeira proposta
	 * @obs Se uma agência iniciar uma negociação, esse valor deve ser preenchido com o valor da hora da agência vezes o tempo estimado da oportunidade.
	 */
	totalValue: number;
	buttonProps?: ButtonProps;
}

type ModalContentVariant = "default" | "priceNeedChange" | "agency";

export function ModalStartOpportunityNegotiation({
	agency,
	opportunity,
	totalValue,
	buttonProps,
	...restProps
}: ModalStartOpportunityNegotiationProps) {
	const [inputValue, setInputValue] = useState(totalValue);
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isLoading, setIsLoading] = useBoolean(false);
	const modalSize = useBreakpointValue({ base: "full", sm: "xl" });
	const company = useAppSelector(state => state.AutenticacaoReducer.empresa) as Company;
	const { isBalanceSufficient } = useAccountBalance();

	/**
	 * @description Determina se o usuário atual é o criador da oportunidade.
	 */
	const currentUserIsTheOpportunityCreator = company.uid === opportunity.idEmpresa;

	/**
	 * @description Determina se o valor da agência é maior que o valor já cadastrado na oportunidade.
	 */
	const totalValueIGreaterThanOpportunityValue = totalValue > opportunity.valor;

	/**
	 * @description Determina o valor mínimo permitido para a proposta
	 */
	const minProposalValue = 0;

	/**
	 * @description Determina o valor máximo permitido para a proposta
	 */
	const maxProposalValue = 1500;

	/**
	 * @description Determina se o botão de iniciar negociação esta desabilitado.
	 */
	const confirmButtonIsDisabled =
		!inputValue || inputValue < minProposalValue || inputValue > maxProposalValue;

	/**
	 * @description Determina se o valor da proposta está dentro dov alor que a empresa tem disponível
	 */
	const companyBalanceIsSufficient = useMemo(() => {
		if (!currentUserIsTheOpportunityCreator) return true;

		return isBalanceSufficient(inputValue);
	}, [currentUserIsTheOpportunityCreator, inputValue, isBalanceSufficient]);

	const content = useMemo(() => {
		let variant: ModalContentVariant = "default";

		if (totalValueIGreaterThanOpportunityValue) {
			variant = "priceNeedChange";
		}

		if (!currentUserIsTheOpportunityCreator) {
			variant = "agency";
		}

		switch (variant) {
			case "priceNeedChange":
				return (
					<Flex gap={2} flexDirection="column">
						<Heading
							size="md"
							color="red.400"
							display="flex"
							gap={1}
							alignItems="center">
							<Icon icon="mdi:alert-decagram" /> {t("atencao")}
						</Heading>
						<Text lineHeight={1.5}>
							{t("avisoMudancaDeValorOportunidadeParaAgencia1")},{" "}
							<b>{agency.fantasia}</b>.
						</Text>
						<Text lineHeight={1.5}>
							{ReactHtmlParser(
								t("avisoMudancaDeValorOportunidadeParaAgencia2", {
									nomeBotao: t("propor"),
									valorAntigo: fCurrency(opportunity.valor),
									valorNovo: fCurrency(totalValue),
								})
							)}
						</Text>
					</Flex>
				);
			case "agency": {
				return (
					<Grid gap={2}>
						<Text lineHeight={1.5}>
							{!!totalValue
								? ReactHtmlParser(
										t("aviso-sugestao-valor-da-proposta", {
											valor: `<b>${fCurrency(totalValue)}</b>`,
										})
									)
								: t("aviso-nao-foi-possivel-sugerir-proposta")}
						</Text>
						<PriceInput
							onValueChange={e => setInputValue(e.floatValue || 0)}
							value={inputValue}
						/>
						<Text color="red.400" lineHeight={1.5} mt={2}>
							• {t("aviso-enviar-negociacao-bloqueia-candidatura-normal")}
						</Text>
					</Grid>
				);
			}
			default:
				return (
					<Text lineHeight={1.5}>
						{ReactHtmlParser(
							t("avisoConfirmacaoDaSolicitacaoDeExecaucaoPelaAgencia", {
								fantasia: agency.fantasia,
							})
						)}
					</Text>
				);
		}
	}, [
		agency.fantasia,
		currentUserIsTheOpportunityCreator,
		inputValue,
		opportunity.valor,
		t,
		totalValue,
		totalValueIGreaterThanOpportunityValue,
	]);

	/**
	 * @description Função que cria uma solicitação para a agência executar a oportunidade.
	 */
	async function handleRequestExecutionForAgency() {
		try {
			//NOTE: Se a oportunidade for do tipo seleção automática, não executamos a função.
			if (opportunity.selecaoAutomatica) return;

			setIsLoading(true);

			//NOTE: Pega a doc da negociação no firestore.
			const negotiationDocRef = firebase
				.firestore()
				.collection("roteiros")
				.doc(opportunity.id)
				.collection("negociacoes")
				.doc();

			//NOTE: Cria o objeto do primeiro log da negociação.
			const log: NegotiationLog = {
				id: v4(),
				criadoEm: new Date().toISOString(),
				editadoEm: new Date().toISOString(),
				titulo: "A negociação está pendente.",
				descricao: "Aceite, recuse ou faça uma contraproposta.",
				variante: LogVariant.PADRÃO,
			};

			//NOTE: Cria o objeto da primeira proposta da negociação.
			const proposal: Proposal = {
				criadoEm: new Date().toISOString(),
				editadoEm: new Date().toISOString(),
				id: v4(),
				idRoteiro: opportunity.id,
				idNegociacao: negotiationDocRef.id,
				idProponente: company.uid,
				valor: Number(inputValue.toFixed(2)),
				descricao: "Olá! Temos uma proposta para você.",
			};

			//NOTE: Cria o objeto da negociação
			const negotiationToCreate: Negotiation = {
				criadoEm: new Date().toISOString(),
				editadoEm: new Date().toISOString(),
				status: NegotiationStatus.PENDENTE,
				statusAnterior: NegotiationStatus.PENDENTE,
				idExecutor: agency.uid,
				id: negotiationDocRef.id,
				idCriadorOportunidade: opportunity.idEmpresa,
				loja: {
					id: opportunity.loja.id,
					nome: opportunity.loja.fantasia,
					enderecoCompleto: opportunity.loja.endereco.endereco,
				},
				roteiro: {
					id: opportunity.id,
					data: opportunity.data,
					horaFim: opportunity.horaFim,
					horaInicio: opportunity.horaInicio,
					quantidadeSecoes: opportunity.secoes ? size(keys(opportunity.secoes)) : 0,
					tipo: opportunity.tipo.id,
					tempoDeExecucaoObrigatorio: !!opportunity.tempoEstimadoObrigatorio,
				},
				logs: [log],
				propostas: [proposal],
			};

			//NOTE: Remove a candidatura da agência se existir.
			await OpportunityService.getInstance().removeCandidature(
				opportunity.id,
				agency.uid,
				true
			);

			//NOTE: Cria a negociação no firestore.
			await negotiationDocRef.set(negotiationToCreate);

			//NOTE: Cria a notificação para a outra parte.
			await NotificationService.getInstance()
				.createOne({
					id: v4(),
					data: moment().format("YYYY-MM-DD"),
					descricao: `A empresa ${company.fantasia} fez uma nova proposta para você. Valor de: ${fCurrency(
						proposal.valor
					)}.`,
					idEmpresa: currentUserIsTheOpportunityCreator
						? agency.uid
						: opportunity.idEmpresa,
					link: currentUserIsTheOpportunityCreator
						? `/buscar/${opportunity.id}`
						: `/oportunidade/info/${opportunity.id}`,
					idRoteiro: negotiationToCreate.roteiro.id,
					titulo: "Você tem uma nova proposta!",
					visto: 0,
				})
				.catch(e => console.error("Erro ao enviar notificação: ", e));

			onClose();
			toast.success(t("proposta-enviada-com-sucesso"));
		} catch (error) {
			console.error(error);
			toast.error(t("erro-ao-propor-execucao-tente-novamente-mais-tarde"));
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<Button
				size="lg"
				flex={1}
				leftIcon={<Icon icon="ph:seal-check-fill" width={20} />}
				onClick={onOpen}
				{...buttonProps}>
				{buttonProps?.children || t("propor-execucao")}
			</Button>
			<Modal
				{...restProps}
				isCentered
				onClose={onClose}
				size={modalSize}
				isOpen={isLoading || isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t("iniciar-uma-negociacao")}</ModalHeader>
					<ModalCloseButton />
					<Divider />
					<ModalBody my={4}>
						{content}
						<Text color="red.400" lineHeight={1.5} mt={2}>
							• {t("avisoAguardarAceiteAgenciaNaProposta")}
						</Text>
						{!companyBalanceIsSufficient && (
							<Text color="red.400" lineHeight={1.5} mt={2}>
								• {t("aviso-saldo-insuficiente-para-iniciar-negociacao")}
							</Text>
						)}
					</ModalBody>
					<Divider />
					<ModalFooter
						gap={2}
						borderBottomRadius={"lg"}
						flexDir={{ base: "column", sm: "row" }}
						m={0}
						bg="transparent">
						<Button
							w={{ base: "full", sm: "fit-content" }}
							colorScheme="red"
							onClick={onClose}
							variant="outline"
							leftIcon={<Icon icon="mdi:close-circle" width={20} />}>
							{t("cancelar")}
						</Button>
						<Tooltip
							bg="red.500"
							textAlign="center"
							hasArrow
							label={
								confirmButtonIsDisabled
									? t("o-valor-deve-ser-maior-que-min-e-menor-que-max", {
											min: fCurrency(minProposalValue),
											max: fCurrency(maxProposalValue),
										})
									: ""
							}>
							<span>
								<Button
									w={{ base: "full", sm: "fit-content" }}
									sx={{
										"& div": {
											padding: 0,
										},
									}}
									isDisabled={confirmButtonIsDisabled}
									isLoading={isLoading}
									autoFocus
									colorScheme="green"
									leftIcon={<Icon icon="mingcute:send-fill" width={20} />}
									onClick={handleRequestExecutionForAgency}>
									{t("propor")}
								</Button>
							</span>
						</Tooltip>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
