import { CloseButton, Collapse, Flex, Heading } from "@chakra-ui/react";
import firebase from "firebase";
import moment from "moment";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMountedState } from "react-use";
import { v4 } from "uuid";
import { Company } from "../../../@types/Company";
import { Proposal } from "../../../@types/Negotiation";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { NotificationService } from "../../../services/firebase/Notification";
import { fCurrency } from "../../../Utils/Number";
import { NewProposalForm } from "../NewProposalForm";

interface CollapseNewProposalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	proposal: Proposal;
	amITheNegotiationCreator: boolean;
}

interface NewProposalFormProps {
	newValue: number;
	description: string;
}

export function CollapseNewProposal({
	isOpen,
	onClose,
	proposal,
	amITheNegotiationCreator,
}: CollapseNewProposalProps) {
	const { t } = useTranslation();
	const isMounted = useMountedState();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const company = useAppSelector(state => state.AutenticacaoReducer).empresa as Company;

	function handleChangeIsSubmitting(isSubmitting: boolean) {
		isMounted() && setIsSubmitting(isSubmitting);
	}

	const onSubmit: SubmitHandler<NewProposalFormProps> = async data => {
		try {
			const negotiationRef = firebase
				.firestore()
				.collection("roteiros")
				.doc(proposal.idRoteiro)
				.collection("negociacoes")
				.doc(proposal.idNegociacao);

			const newProposal: Proposal = {
				id: v4(),
				descricao: data.description,
				criadoEm: new Date().toISOString(),
				editadoEm: new Date().toISOString(),
				idNegociacao: proposal.idNegociacao,
				idRoteiro: proposal.idRoteiro,
				idProponente: company.uid,
				valor: data.newValue,
			};

			await negotiationRef.update(
				"propostas",
				firebase.firestore.FieldValue.arrayUnion(newProposal)
			);

			await NotificationService.getInstance()
				.createOne({
					id: v4(),
					data: moment().format("YYYY-MM-DD"),
					descricao: `A empresa ${company.fantasia} fez uma nova proposta para você. Valor de: ${fCurrency(
						newProposal.valor
					)}.`,
					idEmpresa: proposal.idProponente,
					link: amITheNegotiationCreator
						? `/buscar/${proposal.idRoteiro}`
						: `/oportunidade/info/${proposal.idRoteiro}`,
					idRoteiro: proposal.idRoteiro,
					titulo: "Você tem uma nova proposta!",
					visto: 0,
				})
				.catch(e => console.error("Erro ao enviar notificação: ", e));

			onClose();
		} catch (error) {
			console.error(error);
			toast.error(t("ocorreu-um-erro-ao-fazer-sua-proposta-tente-novamente-mais-tarde"));
		}
	};

	return (
		<Collapse in={isSubmitting || isOpen}>
			<Flex p={4} flexDir="column" gap={4} bg="white" borderRadius="lg">
				<Flex justify="space-between">
					<Heading size="md">{t("criar-nova-proposta")}</Heading>
					<CloseButton isDisabled={isSubmitting} onClick={onClose} alignSelf="flex-end" />
				</Flex>
				<NewProposalForm
					amITheNegotiationCreator={amITheNegotiationCreator}
					onSubmit={onSubmit}
					onCancel={onClose}
					resetWhenThisValueIsFalse={isOpen}
					isSubmittingObserver={handleChangeIsSubmitting}
				/>
			</Flex>
		</Collapse>
	);
}
