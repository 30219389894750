import _ from "lodash";
import { v4 } from "uuid";

const verificarRuptura = ({ secoes, id }) => {
    let produtosSeparadosPorSecao = (secoe) =>
        secoe.produtos.reduce((acc, prod) => {
            acc[v4()] = prod;
            return acc;
        }, {});
    let produtos = _.reduce(
        secoes,
        (acumulador, secoe) => Object.assign(acumulador, produtosSeparadosPorSecao(secoe)),
        {}
    );
    let rupturas = _.filter(produtos, (produto) => produto.ruptura === true);
    let percentual = (rupturas.length / _.size(produtos)) * 100;
    return {
        percentual,
        produtos: _.size(produtos),
        rupturas: rupturas.length,
        id,
    };
};

const rupturaPorProdutos = (docs, step, t) => {
    step(t("gerando.dados.de.ruptura.por.produto"), true);
    // agrupando rupturas por produtos
    let agrupadoPorProduto = {};
    docs.forEach((roteiro) => {
        let data = roteiro;
        if (data.secoes) {
            // let produtos = _.reduce(data.secoes, (acumulador, secoes) => (Object.assign(acumulador, secoes.produtos)), {})
            let produtos = _.flatMap(data.secoes, ({ produtos }) => produtos);
            return (produtos = _.forEach(
                produtos,
                (produto) => {
                    let last = agrupadoPorProduto[produto.id] ?? [];
                    last.push({ ruptura: produto.ruptura, produto });
                    agrupadoPorProduto[produto.id] = last;
                    return agrupadoPorProduto;
                },
                {}
            ));
        }
    });

    return _.map(agrupadoPorProduto, (prod, key) => {
        let rupturas = prod.filter(({ ruptura }) => ruptura === true);
        let percentual = (rupturas.length / prod.length) * 100;
        return { frequencia: prod.length, rupturas: rupturas.length, percentual, produto: prod[0].produto, key };
    });
};

const rupturaPorDia = (docs, step, t) => {
    step(t("gerando.dados.de.ruptura.por.dia"), true);
    // agrupando rupturas por produtos
    let agrupadoPorDia = docs.reduce((acc, roteiro) => {
        let data = roteiro;
        if (data.secoes) {
            let last = acc[data.data] ?? [];
            last.push(verificarRuptura(data));
            acc[data.data] = last;
            return acc;
        } else {
            return acc;
        }
    }, {});

    return _.reduce(
        agrupadoPorDia,
        (acc, roteiros, key) => {
            acc[key] = roteiros.reduce((soma, { rupturas }) => {
                return soma + rupturas;
            }, 0);
            return acc;
        },
        {}
    );
};

const rupturaPorUsuario = (docs, step, t) => {
    step(t("gerando.dados.de.ruptura.por.usuario"), true);
    // agrupando rupturas por produtos
    let usuarios = {};
    let agrupadoPorUsuario = docs.reduce((acc, roteiro) => {
        let data = roteiro;
        if (data.usuario) {
            let last = acc[data.usuario.uid] ?? [];
            usuarios[data.usuario.uid] = data.usuario;
            last.push(verificarRuptura(data));
            acc[data.usuario.uid] = last;
            return acc;
        } else {
            return acc;
        }
    }, {});
    return _.reduce(
        agrupadoPorUsuario,
        (acc, roteiros, key) => {
            // id: "61707204-2803-4f93-92e7-26b5a2800299"
            // percentual: 9.090909090909092
            // produtos: 11
            // rupturas: 1
            return _.merge({}, acc, {
                [key]: _.merge({}, usuarios[key], {
                    rupturas: roteiros.reduce((soma, { rupturas }) => {
                        return soma + rupturas;
                    }, 0),
                }),
            });
        },
        {}
    );
};

/**
 * Função que consolida os valores de rupturas em um determinado periodo.
 * @author diego jimenes
 * @param {Array} docs "snap.docs" o Snapshot.
 * @param {Function} t translate.
 * @param {Function} step função de callback que indica qual o estagio do processo de consolidação.
 * @returns {Object} {pdvs: {Object}, produtos: {Array}, total: {Object}}
 * @example 
 * const dados = gerarDadosPorPdvEOportunidade(docs, t, (step, status) => {
        this.setState({
            carregando: {
                status: status,
                step: step
            }
        })
    })
 */
export const gerarDadosPorPdvEOportunidade = (relatorio, docs, t, step) => {
    step(t("gerando.dados"), true);
    // adicionando percentuais gerais
    relatorio.produtos = rupturaPorProdutos(docs, step, t);
    relatorio.dia = rupturaPorDia(docs, step, t);
    relatorio.usuario = rupturaPorUsuario(docs, step, t);
    return relatorio;
};
