import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import { Dimmer, Loader } from 'semantic-ui-react'

export default function Loading(props) {
    const [redirecionar, setRedirecionar] = useState(false)
    const { link, linkAgencia, modulo } = props.history.location.state
    
    useEffect(() => {
        setTimeout(() => {
            setRedirecionar(true)   
        }, 2000)
    }, []) 

    return (
    <Dimmer active>
        {redirecionar && <Redirect
            push
            to={{
                pathname: modulo === 2 ? linkAgencia : link
            }} 
         />}
        <Loader size='massive'>Carregando informações</Loader>
    </Dimmer>
    )
}