import { Zoom } from "@material-ui/core";
import firebase from "firebase";
import _ from "lodash";
import moment, { duration } from "moment";
import { withSnackbar } from "notistack";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Container, Grid, Header, Loader, Message, Segment } from "semantic-ui-react";
import { auth, db } from "../../../config";
import {
	SEM_DOCUMENTO,
	VISITA_NOTIFICACAO_CANCELADA,
	VISITA_NOTIFICADA,
} from "../../../Model/model";
import { comunicarLojaPorEmail } from "../../../Utils/Api";
import Mensagem from "../Mensagem";
import AtividadesRoteiro from "./AtividadesRoteiro";
import ConfirmarSelecao from "./ConfirmarSelecao";
import DescricaoRoteiro from "./DescricaoRoteiro";
import ListCandidates from "./ListCandidates/index";
import { OpportunityNegotiationArea } from "./OpportunityNegotiationArea";
import PromotorAprovado from "./PromotorAprovado";

const documentoJaEnviado = documento =>
	(documento && _.isPlainObject(documento) && documento.status !== SEM_DOCUMENTO) || false;

const BannerSelecaoAutomatica = props => (
	<>
		<Segment className="selecao-automatica-info">
			<Header
				content={props.t("aprovacao.automatica")}
				subheader={props.t("oportunidade.conf.selecao.automatica")}
				icon="wrench"
			/>
		</Segment>
		<Button
			onClick={props.desligarSelecaoAutomatica}
			icon="power off"
			color="red"
			content={props.t("desligar.selecao.automatica")}
			style={{
				margin: "auto",
				display: "block",
				position: "relative",
				marginTop: 15,
			}}
		/>
	</>
);

class AprovarCandidato extends Component {
	state = {
		roteiro: null,
		actions: [],
		candidatos: [],
		favoritos: [],
		carregando: false,
		comunicar: {
			representanteComercial: "",
			loja: "",
			adicional: "",
		},
		comunicadoEnviado: false,
		enviadoDocumento: false,
		apagarRoteiroConfirmacao: false,
		carregandoConfirmacao: false,
		redirect: false,
		dadosPromotor: {},
		dadosDocumentos: {},
		workTime: {
			duration: duration(0),
			humanized: "",
		},
	};

	ordemOpcoes = [
		{
			text: this.props.t("melhor.avaliacao"),
			value: "melhores",
		},
		{
			text: this.props.t("pior.avaliacao"),
			value: "pirores",
		},
	];

	componentDidMount() {
		this.buscarRoteiro();
	}

	componentDidUpdate(prevProps) {
		if (this.props.roteiro.candidatos !== prevProps.roteiro.candidatos) {
			this.buscarRoteiro();
		}
	}

	setWorkTime = workTime => {
		this.setState({ workTime });
	};

	buscarRoteiro = () => {
		const _roteiro = this.props.roteiro;
		this.buscarCandidatos(_roteiro).then(cand => {
			const actions = [];
			if (!_roteiro.selecaoAutomatica) {
				_roteiro.statusNotificacaoVisita == null && actions.push("enviarNotificacao");
				_roteiro.documento == null && actions.push("anexarDocumento");
			}
			this.setState(
				{
					actions,
					roteiro: {
						..._roteiro,
						documento: _roteiro.documento || null,
						statusNotificacaoVisita: _roteiro.statusNotificacaoVisita || null,
					},
					candidatos: cand,
					enviadoDocumento: documentoJaEnviado(_roteiro.documento),
					carregando: false,
				},
				() => {
					this.buscarFavoritos();
					if (this.state.roteiro.loja.idEmpresa) {
						this.buscarComunicarLoja();
					} else {
						this.setState({
							comunicar: {
								representanteComercial: "",
								loja: "",
								adicional: "",
							},
						});
					}
				}
			);
		});
	};

	buscarCandidatos = roteiro => {
		let candidato = roteiro?.candidatos ?? [];
		return candidato.reduce((promise, ref) => {
			return promise.then(acumulador => {
				let id = ref.split("-").slice(1).join("-");
				let tipo = ref.split("-", 1)[0];
				let col = {
					P: "perfis",
					E: "empresas",
				};
				return firebase
					.firestore()
					.collection(col[tipo])
					.doc(id)
					.get()
					.then(snap => {
						const data = snap.data();
						return Promise.resolve([...acumulador, data]);
					});
			});
		}, Promise.resolve([]));
	};
	buscarFavoritos = () => {
		const docRef = db.collection("empresas").doc(this.props.empresa.uid);
		docRef
			.collection("favoritos")
			.get()
			.then(snap => this.setState({ favoritos: snap.docs.map(doc => doc.data().uid) }));
	};

	buscarComunicarLoja = () => {
		db.collection("lojas")
			.doc(this.state.roteiro.loja.id)
			.get()
			.then(loja => {
				this.setState({
					comunicar: loja.data().comunicar || {
						representanteComercial: "",
						loja: "",
						adicional: "",
					},
				});
			});
	};

	buscarDadosPromotor = async promotor => {
		await db
			.collection(promotor.sexo === "Agência" ? "empresas" : "perfis")
			.doc(promotor.uid)
			.get()
			.then(snap => {
				this.setState({ dadosPromotor: snap.data() });
			});
	};

	aprovarCandidato = async user => {
		var promotor = user;
		await this.buscarDadosPromotor(promotor);
		const faturamentoMensal = this.props.empresa?.faturamentoMensal ?? false;
		if (!promotor.nivelExperiencia) {
			delete promotor.nivelExperiencia;
		}
		var roteiro = {
			...this.state.roteiro,
			usuario: promotor,
			statusAgencia: 1,
			pagamento: {
				...this.state.roteiro.pagamento,
				novo: true,
			},
			statusRoteiro: { id: 2 },
		};
		if (faturamentoMensal) {
			roteiro.pagamento.pagarFaturamentoMensal = true;
		}
		promotor.sexo !== "Agência" && delete roteiro.statusAgencia;

		var dadosDocumentos = {
			nomePromotor: promotor.nome,
			cnpjPromotor: this.state.dadosPromotor.cnpj,
			email: this.state.dadosPromotor.email,
			fotoPromotor: this.state.dadosPromotor.foto,
			cpfPromotor: this.state.dadosPromotor.cpf,
			servico:
				promotor.sexo !== "Agência"
					? "PROMOTOR DE VENDAS AUTÔNOMO"
					: "PROMOTOR DE VENDAS CLT",
			loja: this.state.roteiro.loja.fantasia,
			empresa: {
				fantasia: this.state.roteiro.fantasiaEmpresa,
				cnpj: this.state.roteiro.cnpjEmpresa,
				responsavel: {
					telefone: this.props.empresa.responsavel.telefone,
					nome: this.props.empresa.responsavel.nome,
				},
			},
			plataforma: "PROMOTOR FREELANCE",
			data: moment().format("DD/MM/YYYY"),
			dataFormatada: moment().format("LL"),
			sexoPromotor: promotor.sexo,
		};

		this.setState({
			roteiro,
			dadosDocumentos,
			confirmarAprovacao: true,
		});
	};

	handleChangeOrdena = (e, { name, value }) => {
		this.setState({
			ordena: value,
			candidatos: _.orderBy(
				this.state.candidatos,
				["usuario.mediaAvaliacao"],
				[value === "melhores" ? "desc" : "asc"]
			),
		});
	};

	handleComunicarEmails = event =>
		this.setState({
			comunicar: {
				...this.state.comunicar,
				[event.target.name]: event.target.value,
			},
		});

	storeEmailsParaComunicarComALoja = (idLoja, emails) =>
		db
			.collection("lojas")
			.doc(idLoja)
			.update({
				comunicar: emails,
			})
			.then(() => emails);

	atualizarStatusNotificacaoVisita = (idRoteiro, status) =>
		new Promise((resolved, rejected) => {
			db.collection("roteiros")
				.doc(idRoteiro)
				.update({
					statusNotificacaoVisita: status,
				})
				.then(() =>
					this.setState(
						{
							roteiro: {
								...this.state.roteiro,
								statusNotificacaoVisita: status,
							},
						},
						resolved
					)
				)
				.catch(rejected);
		});

	onSubmitComunicarEmail = event => {
		const { empresa } = this.props;
		const {
			roteiro,
			comunicar,
			comunicar: { representanteComercial, loja, adicional },
		} = this.state;
		const canSend = !_.every([representanteComercial, loja, adicional], item => !item);
		if (!canSend) {
			return;
		}
		const _roteiro = { ...roteiro };
		delete _roteiro.refEmpresa;
		return auth.currentUser
			.getIdToken(true)
			.then(token_id => {
				this.storeEmailsParaComunicarComALoja(this.state.roteiro.loja.id, comunicar)
					.then(emails => comunicarLojaPorEmail(token_id, empresa, _roteiro, emails))
					.catch(e => {
						console.error("failed", e);
					});
			})
			.then(() => this.atualizarStatusNotificacaoVisita(roteiro.id, VISITA_NOTIFICADA))
			.catch(() => console.error("Falha ao requisitar token."));
	};

	onCancelComunicarEmail = () =>
		this.atualizarStatusNotificacaoVisita(this.state.roteiro.id, VISITA_NOTIFICACAO_CANCELADA);

	onDownloadDocumento = event => {
		const { roteiro } = this.state;
		const a = document.createElement("a");
		a.onclick = function (event) {
			a.href = roteiro.documento.url;
			a.target = "_blank";
		};
		a.click();
	};

	onSelecionarDocumento = event => {
		this.setState({
			actions: [...this.state.actions, "anexarDocumento"],
		});
	};

	onSelecionarNotificacao = event => {
		this.setState({
			actions: [...this.state.actions, "enviarNotificacao"],
		});
	};
	/**
	 * Seta o valor da variável actions para controlar o abrir e fechar o modal
	 */
	tryRemoveAction = name => {
		const action = this.state.actions[0] || null;
		if (action === name) {
			this.state.actions.shift();
			this.setState({ actions: [...this.state.actions] });
		}
	};

	/**
	 * Callback da confirmação de seleção de candidato.
	 * Apenas seta o novo state pq as ações são feitas dentro do próprio componente.
	 * Autor: Renahn Schultz
	 */
	callbackConfirmacao = state => this.setState({ ...state });

	/**
	 * Muda a seleção automática do roteiro para 'false'.
	 * Autor: Renahn Schultz
	 */
	desligarSelecaoAutomatica = () => {
		db.collection("roteiros")
			.doc(this.props.roteiro.id)
			.update({ selecaoAutomatica: false, selecaoAutomaticaFavorito: false });
	};

	render() {
		const idEmpresa = this.props.empresa.uid;
		const { roteiro, carregando, confirmarAprovacao, redirect, dadosDocumentos } = this.state;
		const { t, empresa, saldos } = this.props;
		const faturamentoMensal = this.props.empresa.faturamentoMensal;
		const action = this.state.actions[0] || null;

		if (redirect) {
			return <Redirect to={"/oportunidades"} />;
		}

		if (carregando) {
			return (
				<Segment basic className="page-loading">
					<Loader style={{ display: "block" }} inline="centered">
						{t("carregando")}
					</Loader>
				</Segment>
			);
		}

		if (!roteiro || roteiro.idEmpresa !== idEmpresa) {
			return (
				<Message
					icon="bug"
					header={t("oportunidade.inexistente")}
					content={t("oportunidade.inexistente.descricao")}
					info
				/>
			);
		}
		return (
			<div>
				{this.props.roteiro.usuario && this.props.roteiro.usuario.sexo !== "Agência" && (
					<Mensagem roteiro={this.props.roteiro} />
				)}
				<Container>
					<Grid stackable>
						<Grid.Column width={16}>
							<Zoom
								in={roteiro.usuario && !confirmarAprovacao}
								style={{
									transitionDelay:
										roteiro.usuario && !confirmarAprovacao ? "100ms" : "0ms",
								}}>
								<div>
									{roteiro.usuario && !confirmarAprovacao && (
										<PromotorAprovado
											roteiro={roteiro}
											onDownloadDocumento={event =>
												this.onDownloadDocumento(event)
											}
											onSelecionarDocumento={event =>
												this.onSelecionarDocumento(event)
											}
											onSelecionarNotificacao={event =>
												this.onSelecionarNotificacao(event)
											}
										/>
									)}
								</div>
							</Zoom>
							<Zoom
								in={!roteiro.usuario}
								mountOnEnter
								unmountOnExit
								style={{ transitionDelay: !roteiro.usuario ? "50ms" : "0ms" }}>
								<div>
									{this.props.roteiro.selecaoAutomaticaFavorito && (
										<BannerSelecaoAutomatica
											desligarSelecaoAutomatica={
												this.desligarSelecaoAutomatica
											}
											t={t}
										/>
									)}
									<OpportunityNegotiationArea opportunity={this.props.roteiro} />
									{!this.props.roteiro.usuario &&
									!this.props.roteiro.selecaoAutomatica ? (
										<ListCandidates
											{...this.state}
											{...this.props}
											handleChangeOrdena={this.handleChangeOrdena}
											ordemOpcoes={this.ordemOpcoes}
											aprovarCandidato={this.aprovarCandidato}
											setWorkTime={this.setWorkTime}
										/>
									) : (
										<BannerSelecaoAutomatica
											desligarSelecaoAutomatica={
												this.desligarSelecaoAutomatica
											}
											t={t}
										/>
									)}
								</div>
							</Zoom>
							<Zoom
								in={roteiro.usuario && confirmarAprovacao}
								mountOnEnter
								unmountOnExit
								style={{
									transitionDelay:
										roteiro.usuario && confirmarAprovacao ? "50ms" : "0ms",
								}}>
								<div>
									{roteiro.usuario && confirmarAprovacao && (
										<ConfirmarSelecao
											ipLocation={this.props.ipLocation}
											limite={this.props.faturamentoMensal}
											roteiro={roteiro}
											empresa={empresa}
											saldos={saldos}
											faturamentoMensal={faturamentoMensal}
											callback={this.callbackConfirmacao}
											dadosDocumentos={dadosDocumentos}
											workTime={this.state.workTime}
										/>
									)}
								</div>
							</Zoom>
						</Grid.Column>
						<Grid.Column width={16}>
							{/* <Header as="h2" align="center">{t("descricao.oportunidade")}</Header>
							<Segment color='purple'>
								{roteiro.descricao}
							</Segment> */}
							<DescricaoRoteiro roteiro={roteiro} t={t} />
						</Grid.Column>
					</Grid>
					<AtividadesRoteiro {...this.props} roteiro={roteiro} />
				</Container>
				{/* {roteiro.usuario && !confirmarAprovacao && (
          <>
            <NotificarVisitaModal
              t={t}
              open={action === "enviarNotificacao"}
              comunicar={this.state.comunicar}
              handleComunicarEmails={(event) =>
                this.handleComunicarEmails(event)
              }
              onFinishSubmit={(event) => {
                this.onSubmitComunicarEmail(event).then(() =>
                  this.tryRemoveAction("enviarNotificacao")
                );
              }}
              onCancel={(event) => {
                this.onCancelComunicarEmail(event).then(() =>
                  this.tryRemoveAction("enviarNotificacao")
                );
              }}
            />
            <AnexarDocumentModal
              t={t}
              open={action === "anexarDocumento"}
              idRoteiro={this.state.roteiro.id}
              onFinishSubmit={(documento) => {
                const update = { documento };
                db.collection("roteiros")
                  .doc(this.state.roteiro.id)
                  .update(update)
                  .then(() =>
                    this.setState(
                      {
                        roteiro: {
                          ...this.state.roteiro,
                          documento,
                        },
                        enviadoDocumento: true,
                      },
                      () => this.tryRemoveAction("anexarDocumento")
                    )
                  );
              }}
            />
          </>
        )} */}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	empresa: state.AutenticacaoReducer.empresa,
	ipLocation: state.AutenticacaoReducer.ipLocation,
	promotorEscolhido: state.PagamentoReducer.promotorEscolhido,
	saldos: state.AutenticacaoReducer.saldos,
	faturamentoMensal: state.AutenticacaoReducer.faturamentoMensal,
});

export default withSnackbar(connect(mapStateToProps)(withTranslation()(AprovarCandidato)));
