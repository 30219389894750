import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Segment } from 'semantic-ui-react';
import { defaultFilterEvent } from '../../Componentes/FiltroOportunidade';
import { orderBy, roteirosIncompletos, roteirosNovos } from '../../Utils/Roteiros';
import { oportunidadesIncompletas } from '../../_actions/RoteiroActions';
import { DEFAULT_PER_PAGE, paginator } from './../../Utils/RoteiroPaginator';
import ListarRoteiros from './ListarRoteiros';
import ListarRoteirosIncompletos from './Listas/ListarRoteirosIncompletos';
import RoteiroConnect from './RoteiroConnect';

const DATA_ATUAL = moment().format('YYYY-MM-DD')

class RoteirosIncompletos extends Component {
    state = { filtroAberto: false, carregando: false, filtro: {} };

    componentWillMount() {
        this.props.oportunidadesIncompletas == null &&
            this.executarQuery(defaultFilterEvent());
    }

    QUERY = (filtro) => {
        var query = roteirosIncompletos(this.props.empresa.uid, false)
        if (filtro.dataInicio) query = query.where('data', '>=', filtro.dataInicio)
        if (filtro.dataFinal) query = query.where('data', '<=', filtro.dataFinal)
        if (filtro.tipo && filtro.tipo.id) query = query.where('tipo.id', '==', filtro.tipo.id)
        return query.orderBy("data", "desc")
    }

    QUERY2 = (filtro) => {
        var query = roteirosNovos(this.props.empresa.uid, false)
        if (filtro.tipo && filtro.tipo.id) query = query.where('tipo.id', '==', filtro.tipo.id)
        return query.where('data', '<', DATA_ATUAL).orderBy("data", "desc")
    }

    executarQuery = event => {
        var callback = ({ data }) => {
            this.props.dispatch(oportunidadesIncompletas(data))
            this.setState({ carregando: false })
        }
        _.isEmpty(this.props.oportunidadesIncompletas) &&
            paginator()
                .getMultiple([this.QUERY(this.state.filtro), this.QUERY2(this.state.filtro)], callback, DEFAULT_PER_PAGE, 'incompleto')
    };

    render() {
        const { t } = this.props;
        const { carregando } = this.state;
        const oportunidades = orderBy(_.groupBy(this.props.oportunidadesIncompletas, 'data'))

        return (
            <div>
                <ListarRoteiros carregando={this.props.oportunidadesIncompletas == null}
                    roteiros={oportunidades} t={t}
                    renderer={ListarRoteirosIncompletos} />

                <Segment basic textAlign='center'>
                    <Button
                        content="Carregar mais"
                        loading={carregando}
                        onClick={() => {
                            var callback = ({ data }) => {
                                this.props.dispatch(oportunidadesIncompletas(data))
                                this.setState({ carregando: false })
                            }
                            paginator()
                                .getMultiple([this.QUERY(this.state.filtro), this.QUERY2(this.state.filtro)], callback, DEFAULT_PER_PAGE, 'incompleto')
                        }}
                        basic color='purple'></Button>
                </Segment>
            </div>
        )
    }
}

export default RoteiroConnect(
    withTranslation()(RoteirosIncompletos)
);