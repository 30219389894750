import { Avatar, FormControl, FormLabel, Switch, Text } from "@chakra-ui/react";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { deleteDuplicate } from "toolkit-extra";
import { IRede } from "../../@types/Rede";
import { useAuth } from "../../hooks/store/useAuth";
import { Card } from "../Card";
import { Iconify } from "../Iconify";

interface RedeCardProps {
	data: IRede;
	isActive?: boolean;
}

export function RedeCard({ data, isActive }: RedeCardProps) {
	const { t } = useTranslation();
	const { company } = useAuth();

	async function handleChangeSwitch(status: boolean) {
		try {
			if (!company) {
				throw new Error("Empresa inexistente.");
			}

			//NOTE: Adiciona o id no array de ids únicos
			let idsRedesDeAtuacao = deleteDuplicate<string>([
				...(company.idsRedesDeAtuacao || []),
				data.id,
			]);

			//NOTE: Adiciona a rede de atuação dentro das redes da agência
			let redesDeAtuacao = company.redesDeAtuacao || [];
			redesDeAtuacao.push({
				id: data.id,
				nome: data.nome,
				palavrasChave: data.palavrasChave,
			});

			//NOTE: Se o status é falso, remove a rede de atuação da agência e o id adicionado cima
			if (!status) {
				redesDeAtuacao = redesDeAtuacao.filter(rede => rede.id !== data.id);
				idsRedesDeAtuacao = idsRedesDeAtuacao.filter(id => id !== data.id);
			}

			await firebase.firestore().doc(`empresas/${company.uid}`).update({
				redesDeAtuacao,
				idsRedesDeAtuacao,
			});
		} catch (error) {
			console.error(error);
			toast.error(t("erro-ao-editar-atuacao-tente-novamente-mais-tarde"));
		}
	}

	return (
		<Card display="flex" gap={5} justifyContent="space-between">
			<Avatar
				size={"lg"}
				src={data.foto?.url || ""}
				icon={<Iconify icon={"mdi:store"} color="white" boxSize={10} />}
			/>
			<Text flex={1} alignSelf="center">
				{data.nome}
			</Text>
			<FormControl display="flex" alignItems="center" w="fit-content">
				<FormLabel htmlFor="operation-switch" mb="0">
					{isActive ? t("atua") : t("nao-atua")}
				</FormLabel>
				<Switch
					mb={0}
					id="operation-switch"
					onChange={e => handleChangeSwitch(e.target.checked)}
					isChecked={isActive}
				/>
			</FormControl>
		</Card>
	);
}
