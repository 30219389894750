import { SELECIONAR_PROMOTOR, DESAUTENTICA_USUARIO, SALVAR_FATURAS } from "../_actions/Types";

const INITIAL_STATE = {
	promotorEscolhido: "",
	faturas: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SELECIONAR_PROMOTOR:
			return { ...state, promotorEscolhido: action.payload };
		case DESAUTENTICA_USUARIO:
			return { promotorEscolhido: "" };
		case SALVAR_FATURAS:
			return { faturas: action.payload };
		default:
			break;
	}
	return state;
};
