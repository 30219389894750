import firebase from "firebase";
import { MarkRequired } from "ts-essentials";
import { Opportunity } from "../../../@types/Opportunity";

/**
 * Serviço para trabalhar com operações de leitura e escrita de oportunidades no banco de dados.
 */
export class OpportunityService {
	private static instance: OpportunityService;

	public static getInstance(): OpportunityService {
		if (!OpportunityService.instance) {
			OpportunityService.instance = new OpportunityService();
		}
		return OpportunityService.instance;
	}

	/**
	 * @description Retorna uma referencia ao documento da oportunidade no firestore.
	 */
	private getDocRef(opportunityId: string) {
		return firebase.firestore().collection("roteiros").doc(opportunityId);
	}

	/**
	 * @description Função para remover uma candidatura da oportunidade.
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @param opportunityId Id da oportunidade.
	 * @param candidatureId Id do candidato (Sem E ou P).
	 * @param isAgency Booleano que indica se o candidato é uma agência.
	 * @returns uma promise void.
	 */
	removeCandidature(opportunityId: string, candidatureId: string, isAgency = false) {
		const docRef = this.getDocRef(opportunityId);

		return docRef.update({
			candidatos: firebase.firestore.FieldValue.arrayRemove(
				`${isAgency ? "E" : "P"}-${candidatureId}`
			),
		});
	}

	/**
	 * @description Atualiza os dados de uma oportunidade no banco de dados.
	 */
	updateOne(partialData: MarkRequired<Partial<Opportunity>, "id">) {
		const docRef = this.getDocRef(partialData.id);

		return docRef.update(partialData);
	}
}
