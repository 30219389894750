import { Box, Divider, Flex, Heading, IconButton, Tag, Text, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Negotiation } from "../../../@types/Negotiation";
import { getNegotiationStatusInfo } from "../../../helpers/Negotiation";
import { fCurrency } from "../../../Utils/Number";
import { Card } from "../../Card";
import { Iconify } from "../../Iconify";
import { ModalShowOpportunityNegotiation } from "../../ModalShowOpportunityNegotiation";
import { NegotiationNewsStatus } from "../NegotiationNewsStatus";

interface NegotiationCardProps {
	negotiation: Negotiation;
}

export function NegotiationCard({ negotiation }: NegotiationCardProps) {
	const { t } = useTranslation();
	const history = useHistory();

	const lastProposal = [...negotiation.propostas].pop();

	const negotiationStatusInfos = getNegotiationStatusInfo(negotiation.status);

	return (
		<Flex
			as={Card}
			p={4}
			minW={80}
			maxW={80}
			flexDir="column"
			gap={4}
			minH="full"
			position="relative"
			overflow="visible">
			<NegotiationNewsStatus
				position="absolute"
				top={-2}
				right={2}
				negotiation={negotiation}
			/>
			<Flex gap={4} color="gray.700" alignItems="center">
				<Tooltip label={t("local-da-oportunidade")} hasArrow>
					<Iconify icon="fa-solid:store-alt" w={6} h={6} />
				</Tooltip>
				<Box>
					<Heading fontSize={"lg"} mb={0.5}>
						{negotiation.loja.nome}
					</Heading>
					<Text color="gray.500" fontSize="sm" lineHeight={1.2}>
						{negotiation.loja.enderecoCompleto}
					</Text>
				</Box>
			</Flex>
			<Flex gap={4} color="gray.700" alignItems="center">
				<Tooltip label={t("data-da-oportunidade")} hasArrow>
					<Iconify icon="fa-solid:calendar" w={6} h={6} />
				</Tooltip>
				<Text color="gray.500" lineHeight={1.2}>
					{moment(negotiation.roteiro.data).format("DD/MM/YYYY")}
				</Text>
			</Flex>
			<Flex gap={4} color="gray.700" alignItems="center">
				<Tooltip label={t("horario-de-inicio-e-fim-da-oportunidade")} hasArrow>
					<Iconify icon="fa-solid:clock" w={6} h={6} />
				</Tooltip>
				<Text color="gray.500" lineHeight={1.2}>
					{negotiation.roteiro.horaInicio} - {negotiation.roteiro.horaFim}
				</Text>
			</Flex>
			<Flex gap={4} color="gray.700" alignItems="center">
				<Tooltip label={t("ultimo-valor-negociado")} hasArrow>
					<Iconify icon="mdi:currency-usd" w={6} h={6} />
				</Tooltip>
				<Text color="gray.500" lineHeight={1.2}>
					{fCurrency(lastProposal?.valor || 0)}
				</Text>
			</Flex>
			<Divider />
			<Tag
				colorScheme={negotiationStatusInfos.colorScheme}
				justifyContent="center"
				size="lg"
				title={negotiationStatusInfos.label}>
				<Iconify icon={negotiationStatusInfos.icon} mr={1} />
				{negotiationStatusInfos.label}
			</Tag>
			<Divider />
			<Flex gap={3} w="full">
				<Tooltip label={t("ver-oportunidade")} hasArrow>
					<IconButton
						onClick={() => history.push(`/buscar/${negotiation.roteiro.id}`)}
						variant="outline"
						aria-label={t("vai-para-a-pagina-da-oportunidade")}
						icon={<Iconify icon="mdi:routes" width={6} h={6} />}
					/>
				</Tooltip>
				<ModalShowOpportunityNegotiation
					negotiation={negotiation}
					buttonProps={{ colorScheme: "purple", size: "md", leftIcon: undefined }}
				/>
			</Flex>
		</Flex>
	);
}
