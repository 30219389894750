import _ from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Container, Grid, Icon, Segment, Button, Form, Message } from "semantic-ui-react";
import RoteiroConnect from "./RoteiroConnect";
import { oneOf } from "../../Utils/Validacao";
import AtividadesRoteiro from "./Aprovacao/AtividadesRoteiro";
import { AtividadeFotoExpirada } from "../../Componentes/AtividadeFoto";
import { AtividadeProdutoExpirado } from "../../Componentes/AtividadeProduto";
import { AtividadePerguntaExpirada } from "../../Componentes/AtividadePergunta";
import { DataDaAtividadeInput, HorarioDaAtividadeInput } from "../../Componentes/NovoRoteiroInputs";
import { relancarRoteiro, removerCandidatos } from "../../Utils/Roteiros";
import { MensagemSalvoContent } from "../../Componentes/MensagemSalvo";
import { CardEvaluationPromoter } from "../../Componentes/CardEvaluationPromoter";

const noop = () => {};

const descricaoStyle = {
	display: "block",
	fontSize: "18px",
	fontWeight: "bold",
	marginBottom: "5px",
};

export class RelancarRoteiroModal extends Component {
	state = {
		salvando: false,
		salvo: false,
		erro: null,
		mensagem: "",
		datatime: {
			data: "",
			dataFim: "",
			horaInicio: "",
			horaFim: "",
		},
	};

	/**
	 * Atualiza a propriedade do objeto roteiro no state.
	 * @param {string} name Nome da propriedade.
	 * @param {any} value O valor a ser definido.
	 */
	handleUpdateDataTime = (name, value) =>
		this.setState({ datatime: { ...this.state.datatime, [name]: value } });

	/**
	 * Calback para inputs de texto, data...
	 */
	handleChangeDataTime = event =>
		this.handleUpdateDataTime(event.target.name, event.target.value);

	validacao = () => {
		const { t } = this.props;
		const {
			datatime: { data, dataFim, horaInicio, horaFim },
		} = this.state;
		const ontem = moment().subtract(1, "day");

		if (
			moment(data, "YYYY-MM-DD").isBefore(ontem) ||
			moment(dataFim, "YYYY-MM-DD").isBefore(ontem)
		) {
			return t("data.invalida.anterior.hoje");
		}

		if (horaInicio === "" && horaFim === "") {
			return t("horarios.devem.ser.definidos");
		}

		if (horaInicio === horaFim) {
			return t("horarios.devem.ser.diferente");
		}

		if (moment(horaInicio, "HH:mm").isAfter(moment(horaFim, "HH:mm"))) {
			return t("horario.inicial.posterior.a.horario.termino");
		}

		return null;
	};

	onSubmitRelancarRoteiro = event => {
		const { t } = this.props;
		const idRoteiro = this.props.roteiro.id;

		const estaValido = this.validacao();
		if (!estaValido) {
			this.setState(
				{
					salvando: true,
					salvo: false,
					erro: null,
					mensagem: t("relancando.roteiro"),
				},
				() =>
					relancarRoteiro(idRoteiro, this.state.datatime)
						.then(() => removerCandidatos(idRoteiro))
						.then(
							() =>
								new Promise(resolved =>
									this.setState(
										{
											salvando: false,
											salvo: true,
											erro: null,
											mensagem: t("roteiro.relancado"),
										},
										resolved
									)
								)
						)
						.catch(err => {
							this.setState({
								salvando: false,
								salvo: false,
								erro: t("falha.ao.relancar.roteiro"),
								message: "",
							});
						})
			);
		} else {
			this.setState({ erro: estaValido });
		}
	};

	onCancelarRelancamento = event => this.props.onCancelarRelancamento();

	render() {
		const { t, roteiro } = this.props;
		const { data, dataFim, horaInicio, horaFim } = this.state.datatime;

		if (this.state.salvando) {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<div className="ui inverted text loader relancar-roteiro-loader">
						<p style={{ color: "#ff6f00" }}>{t(this.state.mensagem)}</p>
					</div>
				</div>
			);
		}

		if (this.state.salvo) {
			return (
				<div
					onClick={this.props.onFinishRelancamento}
					style={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						alignItems: "center",
						color: "#21ba45",
						cursor: "pointer",
					}}>
					<MensagemSalvoContent t={t} mensagem={this.state.mensagem} />
				</div>
			);
		}

		//Verifica se caso não tem nada dentro da data, se não tiver já seta o state com os valores padrão
		if (data === "" && horaInicio === "" && horaFim === "") {
			this.setState({
				datatime: {
					...this.state.datatime,
					data: moment(new Date()).format("YYYY-MM-DD"),
					horaInicio: moment("08:00", "HH:mm").format("HH:mm"),
					horaFim: moment("18:00", "HH:mm").format("HH:mm"),
				},
			});
		}

		//Se caso a data do roteiro for anterior ao que o usuario está hoje, ele seta os valores padrões (Dia, Hora e HoraFim)
		if (moment(roteiro.data).isBefore(data)) {
			roteiro.data = data;
			roteiro.horaInicio = horaInicio;
			roteiro.horaFim = horaFim;
		}

		return (
			<Form
				onSubmit={this.onSubmitRelancarRoteiro.bind(this)}
				style={{ margin: "0 auto", width: "90%" }}>
				{this.state.erro && (
					<Message negative>
						<p>{this.state.erro}</p>
					</Message>
				)}
				<DataDaAtividadeInput
					type="date"
					inicioEFim={!roteiro.tipo || roteiro.tipo.id !== 1}
					inicio={data}
					fim={dataFim || ""}
					label={t("quando.sera.executado")}
					onChange={this.handleChangeDataTime.bind(this)}
				/>
				<HorarioDaAtividadeInput
					type="time"
					inicioEFim
					inicio={horaInicio}
					fim={horaFim}
					label={t("sugestao.de.horario")}
					onChange={this.handleChangeDataTime.bind(this)}
				/>
				<div style={{ marginTop: 40, textAlign: "center" }}>
					<Button
						type="button"
						className="red-gradient"
						/*style={{ color: '#fff' }}*/ color="negative"
						onClick={this.onCancelarRelancamento.bind(this)}>
						<Icon name="times"></Icon>
						{t("cancelar")}
					</Button>
					<Button
						type="submit"
						className="green-gradient-light"
						/*style={{ color: '#fff' }}*/ color="positive">
						<Icon name="check"></Icon>
						{t("confirmar")}
					</Button>
				</div>
			</Form>
		);
	}
}

class RoteiroExpirado extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roteiro: null,
			modalRelancarAberto: false,
		};
	}

	componentDidMount() {
		this.carregarRoteiro();
	}

	componentDidUpdate(nextProps) {
		if (this.props.match.params.id !== nextProps.match.params.id) {
			this.carregarRoteiro();
		}
	}
	carregarRoteiro = () => {
		const roteiro = this.props.roteiro;
		const data = moment(`${roteiro.data} ${roteiro.horaInicio}`, "YYYY-MM-DD HH:mm");

		if (moment().isBefore(data)) {
			this.redirecionarParaAprovar(roteiro.id);
			return;
		}

		this.setState({ roteiro });
	};

	switchModalRelancarOportunidade = event =>
		this.setState({
			modalRelancarAberto: !this.state.modalRelancarAberto,
		});

	redirecionarParaAprovar = idRoteiro =>
		this.props.history.push(`/oportunidade/info/${idRoteiro}`);

	onFinishRelancamento = () => this.redirecionarParaAprovar(this.state.roteiro.id);

	render() {
		const { roteiro } = this.state;
		const { t } = this.props;
		let statusRoteiro = roteiro?.tipo?.id ?? 0;

		if (!roteiro) {
			return <div>{/* TODO(dias): verificar quando não existir roteiro. */}</div>;
		}

		return (
			<div>
				<Container>
					<Grid stackable>
						{roteiro.usuario && (
							<Grid.Column width={3}>
								<CardEvaluationPromoter opportunity={roteiro} />
							</Grid.Column>
						)}
						<Grid.Column width={roteiro.usuario ? 13 : 16}>
							{roteiro.statusRoteiro?.id === 4 &&
								roteiro.acesso?.teveAcesso === false && (
									<div style={{ marginBottom: 10 }}>
										<span style={descricaoStyle}>{t("sem.acesso.loja")}</span>
										{roteiro.acesso?.motivo}
									</div>
								)}
							{roteiro.secoes && _.size(roteiro.secoes) > 0 ? null : (
								// <div style={{ width: '100%', marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
								//     {verificarExpirada(roteiro, atual) &&
								//         <Button
								//             onClick={this.switchModalRelancarOportunidade.bind(this)}
								//             content={t('relancar.roteiro')}
								//             icon={'rocket'}
								//             color='yellow'>
								//         </Button>}
								// </div>
								<>
									<div
										style={{
											width: "100%",
											marginBottom: 20,
											display: "flex",
											justifyContent: "space-between",
										}}>
										<h3 style={{ fontWeight: "bold" }}>
											{statusRoteiro === 5 ? t("por.tempo") : t("atividades")}
										</h3>
									</div>
								</>
							)}

							{roteiro.descricao && (
								<div style={{ marginBottom: 10 }}>
									<span style={descricaoStyle}>{t("descricao")}</span>
									{roteiro.descricao}
								</div>
							)}

							{statusRoteiro !== 5 && (
								<>
									{roteiro.secoes && _.size(roteiro.secoes) > 0 ? ( //
										<AtividadesRoteiro {...this.props} roteiro={roteiro} />
									) : (
										roteiro?.atividades.map((atividade, aIndex) => (
											<Segment color="purple" key={aIndex}>
												{atividade.tipo === 4 && (
													<AtividadePerguntaExpirada
														t={t}
														item={atividade}
														mostrarInformacoes={false}
													/>
												)}
												{oneOf([2, 3, 5, 6], atividade.tipo) && (
													<AtividadeProdutoExpirado
														t={t}
														item={atividade}
														mostrarInformacoes={false}
														abrirAtividade={noop}
														aberto={noop}
														carregandoAtividade={noop}
														fotosExibir={noop}
													/>
												)}
												{atividade.tipo === 1 && (
													<AtividadeFotoExpirada
														t={t}
														item={atividade}
														mostrarInformacoes={false}
														abrirAtividade={noop}
														aberto={noop}
														carregandoAtividade={noop}
														fotosExibir={noop}
													/>
												)}
											</Segment>
										))
									)}
								</>
							)}
						</Grid.Column>
					</Grid>
				</Container>
			</div>
		);
	}
}

export default RoteiroConnect(withTranslation()(withRouter(RoteiroExpirado)));
