import _ from 'lodash';
import firebase from 'firebase';
import React from 'react';
import { connect } from 'react-redux';


const mapStateToProps = state => {
    const auth = firebase.auth().currentUser;
    const empresa = state.AutenticacaoReducer.empresa;
    const idEmpresa = !empresa ? null : empresa.uid;
    return {
        idEmpresa,
        emailVerificado: auth.emailVerified || false,
        empresa: state.AutenticacaoReducer.empresa,
        ipLocation: state.AutenticacaoReducer.ipLocation,
        saldos: state.AutenticacaoReducer.saldos,
        faturamentoMensal: state.AutenticacaoReducer.faturamentoMensal,
        configuracao: state.RecursosReducer.configuracao,
        localizacao: state.RecursosReducer.localizacao,
        lojas: state.RecursosReducer.lojas,
        marcas: state.RecursosReducer.marcas,
        notasPendentes: state.RecursosReducer.notasPendentes,
        bloquearCadastro: state.RoteiroReducer.bloquearCadastro,
        treinamentos: state.RoteiroReducer.treinamentos,
        oportunidadesPendentesPaginado: state.RoteiroReducer.oportunidadesPendentesPaginado,
        ..._.pick(state.RoteiroReducer, [
            'novasOportunidades',
            'oportunidadesPendentes',
            'oportunidadesExecutadas',
            'oportunidadesExecutadas_Finalizadas',
            'oportunidadesExecutadasPorPeriodo',
            'oportunidadesIncompletas',
            'oportunidadesAgencia',
            'opAgPendentes',
            'opAgPendentesAguardandoPromotor',
            'opAgExecutadas'
        ])
    };
};

export default Component => connect(mapStateToProps)(
    props => <Component {...props} />
);