import "cleave.js/dist/addons/cleave-phone.br";
import _ from "lodash";
import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { db } from "../../config";
import "./cadastro.css";
import { BotaoProximo } from "./CadastroEmpresa";

const operacoes = [
	{ text: "001 – Conta Corrente de Pessoa Física", key: "001", value: "001" },
	{ text: "002 – Conta Simples de Pessoa Física", key: "002", value: "002" },
	{ text: "003 – Conta Corrente de Pessoa Jurídica", key: "003", value: "003" },
	{ text: "013 – Poupança de Pessoa Física", key: "013", value: "013" },
	{ text: "022 – Poupança de Pessoa Jurídica", key: "022", value: "022" },
];

const tipoConta = [
	{ text: "Conta Corrente", key: "001", value: { nome: "Corrente", id: 0 } },
	{ text: "Conta Poupança", key: "002", value: { nome: "Poupança", id: 1 } },
];

export default class Step6 extends Component {
	state = {
		bancos: [],
		banco: "",
	};

	componentDidMount() {
		db.collection("bancos")
			.get()
			.then(snap => {
				var bancos = [];
				snap.forEach(doc => {
					const banco = doc.data();
					bancos.push({ ...banco, text: banco.banco, key: banco.id, value: banco.id });
				});
				this.setState({ bancos });
			});
	}

	changeBanco = e => {
		let result;
		let format;
		const _text = e.target.value.replace(/[^-0-9]/g, "");
		var name = e.target.name;
		var banco = this.state.bancos.find(
			({ id }) => id === this.props.empresa.banco.objetoBanco.id
		);
		if (banco) {
			switch (name) {
				case "conta":
					format = banco.formatoConta;
					break;
				case "agencia":
					format = banco.formatoAgencia;
					break;
				default:
					format = "";
			}
			const dashIndex = format.indexOf("-");
			const expectedLength = format.replace(/-/, "").length;
			const rawTextLength = _text.replace(/-/, "").length;
			if (rawTextLength < expectedLength) {
				result =
					new Array(expectedLength - rawTextLength).fill("0").join("") +
					e.target.value.replace(/-/, "");
			} else {
				result = _text.replace(/-/, "");
			}
			var texto =
				dashIndex > -1
					? result.replace(new RegExp(`^\\d*(\\d{${dashIndex}})(\\d{1})$`), "$1-$2")
					: result.replace(new RegExp(`^\\d*(\\d{${expectedLength}})$`), "$1");
			return this.props.handleChangeEmpresaBanco(name, texto, null);
		} else {
			return "...";
		}
	};

	render() {
		return (
			<div className={this.props.step === 4 ? "d-block" : "d-none"}>
				<div>
					<div className="titulo">{this.props.t("dados.bancarios")}</div>
					<div className="form-group">
						<Dropdown
							className="dropdown-cadastro-empresa"
							placeholder={this.props.t("selecione.um.banco")}
							onChange={(e, { name, value }) => {
								this.props.handleChangeEmpresaBanco(
									name,
									this.state.bancos.find(({ id }) => id === value)
								);
							}}
							value={this.props.empresa.banco.objetoBanco.id}
							fluid
							name="objetoBanco"
							selection
							options={this.state.bancos}
						/>
						<input
							type="text"
							value={this.props.empresa.banco.objetoBanco.banco}
							required
							style={{ height: 0, padding: 0 }}
						/>
					</div>
					{!_.isEmpty(this.props.empresa.banco.objetoBanco) ? (
						<>
							<div className="form-group">
								<Dropdown
									className="dropdown-cadastro-empresa"
									placeholder={this.props.t("selecione.uma.operacao")}
									onChange={(e, { name, value }) => {
										this.props.handleChangeEmpresaBanco(name, value, null);
									}}
									value={this.props.empresa.banco.tipoConta}
									fluid
									name="tipoConta"
									selection
									required
									options={tipoConta}
								/>
								<input
									type="text"
									value={this.props.empresa.banco.tipoConta.id}
									required
									style={{ height: 0, padding: 0 }}
								/>
							</div>
							<div className={"form-group"}>
								{/* TODO: usar src/Componente/BancoInput.js */}
								<input
									name="agencia"
									id="agencia"
									type="text"
									value={this.props.empresa.banco.agencia}
									onChange={this.changeBanco}
									placeholder={this.props.t("agencia")}
								/>
								{this.props.erros.agencia}
							</div>
							<div className="form-group">
								{/* TODO: usar src/Componente/BancoInput.js */}
								<input
									name="conta"
									id="conta"
									type="text"
									required
									value={this.props.empresa.banco.conta}
									onChange={this.changeBanco}
									placeholder={this.props.t("conta")}
								/>
								{this.props.erros.conta}
							</div>
							{this.props.empresa.banco.objetoBanco.operacao && (
								<div className="form-group">
									<Dropdown
										className="dropdown-cadastro-empresa"
										placeholder={this.props.t("selecione.uma.operacao")}
										onChange={(e, { name, value }) => {
											this.props.handleChangeEmpresaBanco(name, value, null);
										}}
										value={this.props.empresa.banco.operacao}
										fluid
										name="operacao"
										selection
										required
										options={operacoes}
									/>
								</div>
							)}
						</>
					) : (
						<div style={{ marginBottom: "300px" }}></div>
					)}
				</div>
				<BotaoProximo t={this.props.t} />
			</div>
		);
	}
}
