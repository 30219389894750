import { Badge, BadgeProps, Tooltip } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Company } from "../../../@types/Company";
import { Negotiation, NegotiationStatus } from "../../../@types/Negotiation";
import { useAppSelector } from "../../../hooks/useAppSelector";

interface NegotiationNewsStatusProps extends BadgeProps {
	negotiation: Negotiation;
}

/**
 * Renderiza um componente de `Badge` indicando que há novidades em uma negociação.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function NegotiationNewsStatus({ negotiation, ...restProps }: NegotiationNewsStatusProps) {
	const { t } = useTranslation();
	const company = useAppSelector(state => state.AutenticacaoReducer.empresa) as Company;

	const lastProposal = useMemo(() => [...negotiation.propostas].pop(), [negotiation.propostas]);

	const isLastProposalMy = lastProposal?.idProponente === company.uid;

	const hasNews = !isLastProposalMy && negotiation.status === NegotiationStatus.PENDENTE;

	if (!hasNews) return null;

	return (
		<Tooltip
			label={t("ha-novas-atualizacoes-sobre-esta-negociacao-de-uma-olhada")}
			hasArrow
			textAlign="center">
			<Badge
				colorScheme="green"
				fontSize="sm"
				className="animate__animated animate__bounce"
				{...restProps}>
				{t("novo").toUpperCase()}
			</Badge>
		</Tooltip>
	);
}
