import { Badge, Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import SyncIcon from "@material-ui/icons/Sync";
import PropTypes from "prop-types";
import React from "react";
import Currency from "react-currency-formatter";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Dropdown, Image } from "semantic-ui-react";
import { modelEmpresa } from "../Model/model";
import { filtrarMenuToList, filtroMenuPorTexto } from "../Utils/menus";
import { alternarModulo, desautenticaUsuario } from "../_actions/AutenticacaoActions";
import logo from "./../imagens/logo-nome.svg";
import menus from "./../Utils/menus";
import VerificNav from "./VerificarNavegador";

const styles = theme => ({
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	buttonRoot: {
		root: {
			borderRadius: 20,
		},
	},
	title: {
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(3),
			width: "auto",
		},
	},
	searchIcon: {
		width: theme.spacing(9),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputRoot: {
		color: "inherit",
		width: "100%",
	},
	inputInput: {
		paddingTop: theme.spacing(),
		paddingRight: theme.spacing(),
		paddingBottom: theme.spacing(),
		paddingLeft: theme.spacing(10),
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: 200,
		},
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
});

class PrimaryAppBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
			mobileMoreAnchorEl: null,
			menus: null,
			aberto: false,
		};
	}

	handleProfileMenuOpen = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleMenuClose = () => {
		this.setState({ anchorEl: null });
		this.handleMobileMenuClose();
	};

	handleMobileMenuOpen = event => {
		this.setState({ mobileMoreAnchorEl: event.currentTarget });
	};

	handleMobileMenuClose = () => {
		this.setState({ mobileMoreAnchorEl: null });
	};

	filtrarMenu = event => {
		const { t } = this.props;
		let updateMenu = null;
		const modulo = this.props.modulo || 0;
		if (event.target.value !== "") {
			const texto = event.target.value.toLowerCase();
			const filtro = filtroMenuPorTexto(t, texto);
			updateMenu = filtrarMenuToList(t, modulo, filtro, menus);
		}
		this.setState({ menus: updateMenu });
	};

	/* eslint-disable no-restricted-globals */
	onSelectedItem = (event, { key, value }) => this.props.history.push(value);
	/* eslint-enable no-restricted-globals */
	render() {
		const { anchorEl, mobileMoreAnchorEl } = this.state;
		const { classes, t, saldos, modulo, faturamentoMensal } = this.props;
		const empresa = this.props.empresa || modelEmpresa;
		const isMenuOpen = Boolean(anchorEl);
		const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
		const maximo = empresa?.valorMaximoOportunidades ?? 0;
		const restante = isNaN(maximo - faturamentoMensal?.gastos)
			? 0
			: maximo - faturamentoMensal?.gastos;
		const saldoNaoReservado = saldos?.naoReservado ?? 0;

		const renderMenu = (
			<Menu
				anchorEl={anchorEl}
				style={{ "z-index": "2000" }}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={isMenuOpen}
				onClose={this.handleMenuClose}>
				<Link to="/perfil">
					<MenuItem>
						<AccountCircle style={{ marginRight: 10 }} /> {t("meus.dados")}
					</MenuItem>
				</Link>
			</Menu>
		);

		const renderMobileMenu = (
			<Menu
				anchorEl={mobileMoreAnchorEl}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={isMobileMenuOpen}
				onClose={this.handleMobileMenuClose}>
				<MenuItem>
					<Link to="/financeiro" as="span" className="saldo-appbar">
						<Currency
							quantity={empresa.faturamentoMensal ? restante : saldoNaoReservado}
							locale="pt_BR"
							decimal=","
							group="."
							currency="R$ "
						/>
						{empresa.faturamentoMensal && (
							<span className="saldo-faturamento">{t("mensal")}</span>
						)}
					</Link>
				</MenuItem>
				<MenuItem
					onClick={() => {
						this.props.alternarModulo(modulo === 2 ? 1 : 2);
						this.props.history.push("/dashboard");
					}}>
					<IconButton color="inherit">
						<SyncIcon />
					</IconButton>
					<p>{modulo === 1 ? t("vendo.empresa") : t("vendo.agencia")}</p>
				</MenuItem>
				<MenuItem
					onClick={() => {
						this.props.checkNotificacao();
					}}
					className="open-notificacao">
					<IconButton color="inherit">
						<Badge
							overlap="rectangular"
							badgeContent={this.props.numeroNotificacoes}
							color="secondary">
							<NotificationsIcon className="open-notificacao" />
						</Badge>
					</IconButton>
					<p>{t("notificacoes")}</p>
				</MenuItem>
			</Menu>
		);

		return (
			<div className="supbar">
				<VerificNav t={t} />
				<Image className="logo" as={Link} to={"/dashboard"} src={logo} size="small"></Image>
				<div className={classes.grow}>
					<Dropdown
						className="app-search"
						icon="search"
						selection
						search
						floating
						placeholder="Pesquisa..."
						selectOnBlur={false}
						selectOnNavigation={false}
						style={{ float: "right", top: "50%", transform: "translateY(-50%)" }}
						onSearchChange={this.filtrarMenu.bind(this)}
						onChange={this.onSelectedItem.bind(this)}
						autoComplete="false"
						name="busca-menu"
						options={this.state.menus || this.props.menus}
					/>
				</div>

				{this.props.modulo === 3 ? null : (
					<div className={classes.sectionDesktop}>
						<Link to="/financeiro" as="span" className="saldo-appbar">
							<Currency
								quantity={empresa.faturamentoMensal ? restante : saldoNaoReservado}
								locale="pt_BR"
								decimal=","
								group="."
								currency="R$ "
							/>
							{empresa.faturamentoMensal && (
								<span className="saldo-faturamento">{t("mensal")}</span>
							)}
						</Link>
						<IconButton
							className="open-notificacao"
							color="inherit"
							onClick={() => {
								this.props.checkNotificacao();
							}}>
							{this.props.numeroNotificacoes > 0 ? (
								<Badge
									overlap="rectangular"
									badgeContent={this.props.numeroNotificacoes}
									color="secondary">
									<NotificationsIcon className="open-notificacao" />
								</Badge>
							) : (
								<NotificationsIcon />
							)}
						</IconButton>
						{!empresa
							? null
							: empresa.tipo === 2 && (
									<Button
										style={{ borderRadius: 50 }}
										color="inherit"
										onClick={() => {
											this.props.alternarModulo(modulo === 2 ? 1 : 2);
											this.props.history.push("/dashboard");
										}}>
										<SyncIcon />{" "}
										{modulo === 1 ? t("vendo.empresa") : t("vendo.agencia")}
									</Button>
								)}
					</div>
				)}

				<div className={classes.sectionMobile}>
					<IconButton
						aria-haspopup="true"
						onClick={this.handleMobileMenuOpen}
						color="inherit">
						<MoreIcon />
					</IconButton>
				</div>
				{renderMenu}
				{renderMobileMenu}
			</div>
		);
	}
}

PrimaryAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => ({
	...state.AutenticacaoReducer,
	menus: filtrarMenuToList(props.t, state.AutenticacaoReducer.modulo, () => true, menus),
});

const ConnectedAppBar = withTranslation()(
	connect(mapStateToProps, { alternarModulo, desautenticaUsuario })(
		withStyles(styles)(PrimaryAppBar)
	)
);

export default withRouter(ConnectedAppBar);
