import { IconButton } from "@material-ui/core";
import { CheckCircle, Close, Error, Info, Warning } from "@material-ui/icons";
import _ from "lodash";
import moment from "moment";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import { RelancarRoteiroModal } from "../Interno/Roteiro/RoteiroExpirado";
import CriarModelo from "../Interno/Roteiro/VerificarRoteiro/CriarModelo";
import { logging } from "../services/Api";
import { imagemOuDefault } from "../Utils/Login";
import { arquivarRoteiro, verificarExpirada } from "../Utils/Roteiros";
import AtividadeFoto1 from "./AtividadeFoto";
import AtividadePergunta1 from "./AtividadePergunta";
import AtividadeProduto1 from "./AtividadeProduto";
import Confirmar from "./Confirmar";
import { ModalDeConfirmacao } from "./ConfirmarRegistro";
import HeaderPadrao1 from "./HeaderPadrao";
import ImagemCentralizada1 from "./ImagemCentralizada";
import ImagemCircular1 from "./ImagemCircular";

export var HeaderPadrao = HeaderPadrao1;
export var ImagemCircular = ImagemCircular1;
export var AtividadeProduto = AtividadeProduto1;
export var AtividadeFoto = AtividadeFoto1;
export var AtividadePergunta = AtividadePergunta1;
export var ImagemCentralizada = ImagemCentralizada1;

export const PerfilEmpresa = props => (
	<>
		<ImagemCircular size="extra-grande" src={imagemOuDefault(props.imagemURL)} />
		<span
			style={{
				fontSize: "22px",
				fontWeight: "bold",
				display: "block",
				marginTop: 20,
			}}>
			{props.fantasia}
		</span>
		<span style={{ display: "block" }}>{props.razaoSocial}</span>
		<span style={{ display: "block" }}>{props.email}</span>
	</>
);

export const Stat = props => (
	<div className={"saldo" + (props.inverted ? " inverted" : "")} {...props}>
		<p>{props.title}</p>
		<p>{props.value}</p>
	</div>
);

export const SnackbarProv = props => {
	const notistackRef = React.createRef();
	const onClickDismiss = key => () => {
		notistackRef.current.closeSnackbar(key);
	};
	return (
		<SnackbarProvider
			ref={notistackRef}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			iconVariant={{
				success: <CheckCircle />,
				warning: <Warning />,
				error: <Error />,
				info: <Info />,
			}}
			action={key => (
				<IconButton
					key="close"
					aria-label="close"
					color="inherit"
					onClick={onClickDismiss(key)}>
					<Close />
				</IconButton>
			)}
			classes={{
				root: "snack",
				variantSuccess: "snack success",
				variantError: "snack error",
				variantWarning: "snack warning",
				variantInfo: "snack info",
				containerAnchorOriginTopRight: "snack margin",
			}}>
			{props.children}
		</SnackbarProvider>
	);
};

const gerarDadosOportunidade = roteiro => {
	var produtos = Object.keys(roteiro.secoes).reduce((result, key) => {
		result.push(...roteiro.secoes[key].produtos);
		return result;
	}, []);
	var percentualRuptura =
		produtos &&
		((_.filter(produtos, { ruptura: true }).length / produtos.length) * 100).toFixed(0);
	var percentualReposicao =
		produtos &&
		((_.filter(produtos, { reposto: true }).length / produtos.length) * 100).toFixed(0);
	return { percentualRuptura, percentualReposicao };
};

export const BannerRoteiro = props => {
	const { roteiro, t, switchModalRelancarOportunidade, modalRelancarAberto } = props;
	const [modalModelo, setModalModelo] = useState(false);
	const [modalCancelar, setModalCancelar] = useState(false);
	const [modalErroCancelar, setModalErroCancelar] = useState(false);
	const [cancelarCarregando, setCancelarCarregando] = useState(false);
	const { empresa, ipLocation } = useSelector(
		({ AutenticacaoReducer: { empresa, ipLocation } }) => ({
			empresa,
			ipLocation,
		})
	);
	const redirecionarParaAprovar = idRoteiro =>
		props.history.push(`/oportunidade/info/${idRoteiro}`);
	const onFinishRelancamento = () => redirecionarParaAprovar(roteiro.id);
	const { enqueueSnackbar } = useSnackbar();
	const toggleModalModelo = () => setModalModelo(!modalModelo);
	const toggleModalCancelar = () => setModalCancelar(!modalCancelar);
	const toggleModalErroCancelar = () => setModalErroCancelar(!modalErroCancelar);
	const apagarRoteiro = async () => {
		let agora = moment(new Date()); //todays date
		let dataInicio = moment(
			roteiro.data + " " + roteiro.horaInicio + ":00",
			"YYYY-MM-DD hh:mm:ss"
		);
		let diferenca = moment.duration(dataInicio.diff(agora));
		let horas = diferenca.asHours();

		if (roteiro.statusRoteiro.id > 2 || (roteiro.statusRoteiro.id === 2 && horas < 2)) {
			toggleModalCancelar();
			toggleModalErroCancelar();
		} else {
			arquivarRoteiro(roteiro.id)
				.then(() => {
					logging("DELETE", "log.cancelar.roteiro", roteiro.id);
					toggleModalCancelar();
					setCancelarCarregando("redirect");
					enqueueSnackbar(t("oportunidade.cancelada"), { variant: "success" });
				})
				.catch(() => {
					toggleModalCancelar();
					setCancelarCarregando(false);
					enqueueSnackbar(t("erro.cancelar.oportunidade"), {
						variant: "error",
					});
				});
		}
	};

	var atual = moment().format("YYYY-MM-DD");
	// Retorna para a listagem de roteiros após cancelar
	if (cancelarCarregando === "redirect") {
		return <Redirect to="/oportunidades" />;
	}

	// Opções do menu da oportunidade
	const options = [
		{
			key: "copy",
			text: t("criar.modelo"),
			icon: "copy",
			onClick: toggleModalModelo,
		},
	];
	roteiro.statusRoteiro.id === 1 &&
		options.push({
			key: "pencil",
			text: t("editar"),
			icon: "pencil",
			as: Link,
			to: `/roteiro/editar/${props.match.params.id}`,
		});
	roteiro.statusRoteiro.id <= 2 &&
		options.push({
			key: "times",
			text: t("cancelar"),
			icon: "times",
			onClick: toggleModalCancelar,
		});
	if (roteiro.secoes && verificarExpirada(roteiro, atual)) {
		options.push({
			key: "rocket",
			text: "Relançar Oportunidade",
			icon: "rocket",
			onClick: switchModalRelancarOportunidade,
		});
	}

	//gerar gráficos de execução (criar um metodo futuramente)
	var oportunidadeConsolidada = (roteiro.secoes && gerarDadosOportunidade(roteiro)) ?? {};
	const validacoesParaGraficos =
		[3, 5].includes(roteiro.statusRoteiro.id) && !_.isEmpty(oportunidadeConsolidada);
	const validacoesParaDropDown = _.isEmpty(roteiro.atividades);

	/**
	 * Retorna um booleano para determinar se o banner de informação deve ser exibido ou não
	 */
	const showBannerInfo =
		!!validacoesParaGraficos ||
		(validacoesParaDropDown && validacoesParaGraficos) ||
		(validacoesParaDropDown && props.modulo !== 2);

	return (
		<div className="banner-roteiro">
			<Modal
				open={modalRelancarAberto}
				onClose={switchModalRelancarOportunidade}
				className="relancamento-roteiro">
				<Modal.Header>{t("relancar.roteiro")}</Modal.Header>
				<Modal.Content>
					{modalRelancarAberto && (
						<RelancarRoteiroModal
							t={t}
							roteiro={roteiro}
							onCancelarRelancamento={switchModalRelancarOportunidade.bind(this)}
							onFinishRelancamento={onFinishRelancamento.bind(this)}
						/>
					)}
				</Modal.Content>
			</Modal>
			<ModalDeConfirmacao
				t={t}
				isOpen={modalCancelar}
				onLoading={cancelarCarregando}
				titulo={t("cancelar.oportunidade")}
				message={<p style={{ marginTop: 10 }}>{t("modal.cancelar.oportunidade")}</p>}
				onConfirm={apagarRoteiro}
				onCancel={toggleModalCancelar}
			/>

			<Confirmar
				callback={toggleModalErroCancelar}
				open={modalErroCancelar}
				onClose={toggleModalErroCancelar}
				confirmar={t("ok")}
				mensagem={t("nao.possivel.cancelar.roteiro")}
				titulo={t("cancelamento.roteiro")}
			/>

			<CriarModelo t={t} roteiro={roteiro} fechar={toggleModalModelo} open={modalModelo} />

			<div className="bg-teste">
				<div className="banner-font">
					<span className="title">{props.title}</span>
					<span className="sub">{props.subtitle}</span>
				</div>

				{showBannerInfo && (
					<div className="banner-info">
						{validacoesParaGraficos && (
							<>
								<SvgCircleGraph
									name={t("reposicao")}
									percentage={oportunidadeConsolidada.percentualReposicao}
									color="green"
									style={{ width: 75 }}
								/>
								<SvgCircleGraph
									name={t("ruptura")}
									percentage={oportunidadeConsolidada.percentualRuptura}
									color="red"
									style={{ width: 75 }}
								/>
							</>
						)}
						{validacoesParaDropDown && validacoesParaGraficos && (
							<div style={{ width: 20, height: 5 }}></div>
						)}
						{validacoesParaDropDown && props.modulo !== 2 && (
							<Dropdown
								trigger={<Button icon="ellipsis vertical" />}
								options={options}
								selectOnBlur={false}
								pointing="top right"
								icon={null}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export const SvgCircleGraph = props => (
	<svg viewBox="0 0 36 36" class="circular-chart" style={props.style}>
		<path
			class="circle-bg"
			d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
		/>
		<path
			class={`circle ${props.color}`}
			stroke-dasharray={props.percentage + ", 100"}
			d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
		/>
		<text x="18" y="18.35" class="percentage">
			{props.percentage}%
		</text>
		<text x="18" y="23.35" class="graph-name">
			{props.name}
		</text>
	</svg>
);
