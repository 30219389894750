import { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "semantic-ui-css/semantic.min.css";
import "./Externo/css/bootstrap.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./i18n";
import "./Interno/Template/template.css";
import "./Interface.scss";
import C from "./configuration.json";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme/chakraUI/theme";

console.log(`Promotor Freelance
Versão: ${C.version} (${C.sha1.slice(0, 8)})`);

ReactDOM.render(
    <Suspense fallback="loading">
        <ChakraProvider theme={theme}>
            <App />
        </ChakraProvider>
    </Suspense>,
    document.getElementById("root")
);
registerServiceWorker();
