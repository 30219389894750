import { LinearProgress, Zoom } from "@material-ui/core";
import _ from "lodash";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, Message, Modal, Segment } from "semantic-ui-react";
import { db } from "../../config";
import { logging } from "../../services/Api";
import { salvarTreinamentos } from "../../_actions/RoteiroActions";
import { HeaderPadrao } from "./../../Componentes/Componentes";
import NovoTreinamento from "./NovoTreinamento";

class Treinamentos extends Component {
	state = {
		empresa: this.props.empresa,
		ipLocation: this.props.ipLocation,
		treinamentos: [],
		openTreinamento: false,
		aberto: false,
		treinamentoModal: {},
	};

	componentDidMount() {
		if (!this.props.treinamentos) {
			this.setState(
				{
					loading: true,
					ref: db
						.collection("empresas")
						.doc(this.props.empresa.uid)
						.collection("treinamentos"),
				},
				async () => {
					try {
						this.state.ref.onSnapshot(snap => {
							var treinamentos = [];
							snap.forEach(t => treinamentos.push(t.data()));
							this.props.dispatch(salvarTreinamentos(treinamentos));
							this.setState({ loading: false, treinamentos });
						});
					} catch (err) {
						console.log(err);
					}
				}
			);
		} else {
			this.setState({ loading: false, treinamentos: this.props.treinamentos });
		}
	}

	handleCloseModal = () => {
		this.setState({ aberto: false, treinamentoModal: {} });
	};

	handleOpenTreinamentoModal = () => {
		this.setState({ openTreinamento: !this.state.openTreinamento });
	};

	handleOpenModal = treinamento => {
		this.setState({ aberto: true, treinamentoModal: treinamento });
	};

	remover = treinamentoModal => () => {
		var treinamentos = [...this.state.treinamentos];
		const indexTreinamento = _.findIndex(treinamentos, {
			id: treinamentoModal.id,
		});
		const ipLocation = this.state.ipLocation;
		const empresa = this.state.empresa;
		db.collection("empresas")
			.doc(empresa.uid)
			.collection("treinamentos")
			.doc(treinamentoModal.id)
			.delete()
			.then(a => {
				this.props.enqueueSnackbar(
					this.props.t("treinamento.removido", { nome: treinamentoModal.nome }),
					{ variant: "success" }
				);
			})
			.then(() => {
				logging("DELETE", "log.delete.treinamento", treinamentoModal.id);
			});
		this.setState({
			aberto: false,
			treinamentoModal: {},
			treinamentos: treinamentos.filter((object, index) => indexTreinamento !== index),
		});
	};

	render() {
		const { t } = this.props;
		const { treinamentos, aberto, treinamentoModal, openTreinamento } = this.state;
		return (
			<div>
				<div
					className="container-header-padrao"
					style={{ justifyContent: "space-between" }}>
					<Button
						style={{ marginTop: "10px" }}
						className="purple-gradient-87"
						content={t("novo.treinamento")}
						icon="plus"
						color="violet"
						floated="right"
						onClick={this.handleOpenTreinamentoModal}
					/>
					<Modal open={openTreinamento}>
						<Modal.Content>
							<NovoTreinamento
								handleOpenTreinamentoModal={this.handleOpenTreinamentoModal}
							/>
						</Modal.Content>
					</Modal>
					<Modal open={aberto} onClose={this.handleCloseModal} size={"tiny"}>
						<Modal.Header>
							{t("excluir.treinamento")} "{treinamentoModal.nome}"?
						</Modal.Header>
						<Modal.Content>
							<Grid stackable>
								<Grid.Column width={16}>
									<label style={{ fontWeight: "bold" }}>
										{t("nota.de.aprovacao")} : {treinamentoModal.notaAprovacao}
									</label>
									<p>{treinamentoModal.descricao}</p>
								</Grid.Column>
								<Grid.Column width={8} style={{ textAlign: "right" }}>
									<span>
										<Button
											color="purple"
											onClick={this.handleCloseModal}
											content={t("cancelar")}
										/>
									</span>
								</Grid.Column>
								<Grid.Column width={8} style={{ textAlign: "left" }}>
									<span>
										<Button
											color="red"
											basic
											onClick={this.remover(treinamentoModal)}
											content={t("remover")}
										/>
									</span>
								</Grid.Column>
							</Grid>
						</Modal.Content>
					</Modal>

					<div style={{ paddingBottom: 20 }}>
						<HeaderPadrao
							icone="graduation"
							titulo={t("treinamentos")}
							subtitulo={t("seus.treinamentos")}
						/>
					</div>
				</div>
				{this.state.loading && <LinearProgress />}

				{_.isEmpty(treinamentos) && this.state.loading === false ? (
					<Zoom
						in={_.isEmpty(treinamentos)}
						mountOnEnter
						unmountOnExit
						style={{ transitionDelay: "50ms", marginTop: 60 }}>
						<Message content={t("nenhum.treinamento.publicado")}></Message>
					</Zoom>
				) : (
					_.map(treinamentos, (treinamento, index) => (
						<Segment key={index} color="violet">
							<Grid className="roteiro-grid" stackable>
								<Grid.Column width={10} textAlign="left" verticalAlign="middle">
									{treinamento.nome} <br />
									<span className="roteiro-info-secondary">
										{treinamento.descricao}
									</span>
								</Grid.Column>
								<Grid.Column width={3} textAlign="center">
									{parseFloat(treinamento.notaAprovacao).toFixed(1)}
									<br />
									<span className="roteiro-info-secondary">
										{t("nota.aprovacao")}
									</span>
								</Grid.Column>

								<Grid.Column width={3} textAlign="right">
									<Link
										to={"/treinamento/" + treinamento.id}
										style={{ color: "#333", marginRight: 5 }}>
										<Button
											basic
											circular
											color="violet"
											icon="pencil"></Button>
									</Link>
									<Button
										basic
										circular
										onClick={() => this.handleOpenModal(treinamento)}
										color="violet"
										icon="trash"></Button>
								</Grid.Column>
							</Grid>
						</Segment>
					))
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	empresa: state.AutenticacaoReducer.empresa,
	treinamentos: state.RoteiroReducer.treinamentos,
	ipLocation: state.AutenticacaoReducer.ipLocation,
});

export default withSnackbar(connect(mapStateToProps)(withTranslation()(Treinamentos)));
