import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import firebase from "firebase";
import _ from "lodash";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
	Button,
	Container,
	Grid,
	GridColumn,
	Icon,
	Loader,
	Modal,
	Segment,
} from "semantic-ui-react";
import { v4 } from "uuid";
import Notificacoes from "../../Componentes/Notificacoes";
import VerificacaoDeBanco from "../../Componentes/VerificacaoDeBanco";
import VerificacaoDeEmail from "../../Componentes/VerificacaoDeEmail";
import { db } from "../../config";
import { contaNaoVerificadaIugu } from "../../Utils/Auth";
import { todayYYYYMMDD } from "../../Utils/Date.utils";
import { atualizarEmpresa } from "../../_actions/AutenticacaoActions";
import { notificadoAvaliacaoPendente } from "../../_actions/RecursoActions";
import AppBar from "./../../Componentes/AppBar";
import Menu from "./../../Componentes/Menu";
import Footer from "./Footer";
import moment from "moment";
var conf = require("../../configuration.json");

class Template extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			checkedNotificacoes: false,
			qtdNotificacoes: 0,
			notificacoes: [],
			noticia: this.props.configuracao?.noticiasWeb,
			menuAberto: true,
		};
		this.checkMenu = this.checkMenu.bind(this);
	}

	componentDidMount() {
		if (this.props.modulo !== 3) {
			const idEmpresa = this.props.empresa.uid;
			db.collection("notificacoes")
				.where("idEmpresa", "==", idEmpresa)
				.where("data", ">=", moment().subtract(2, "days").format("YYYY-MM-DD"))
				.orderBy("data", "desc")
				.limit(100)
				.onSnapshot(this.controleRetornoNotificacoes);
		}
		window.onscroll = this.onScrollHandle;
	}

	onScrollHandle = () => {
		var supbar = document.getElementsByClassName("supbar")[0]?.style ?? {};
		var bannerRoteiro = document.getElementsByClassName("banner-roteiro")[0]?.style ?? {};
		if (window.scrollY > 0) {
			supbar.borderBottom = "1px solid #eee";
			bannerRoteiro.borderBottom = "1px solid #eee";
		} else {
			supbar.borderBottom = "1px solid #fff";
			bannerRoteiro.borderBottom = "1px solid #fff";
		}
	};

	controleRetornoNotificacoes = snap => {
		const { oportunidadesExecutadas } = this.props;

		let qtdNotificacoes = 0;
		let notificacoes = [];

		snap.forEach(doc => {
			var notificacao = doc.data();
			qtdNotificacoes += 1;
			notificacoes.push(notificacao);
		});
		qtdNotificacoes += this.notificarCasoExistamRoteirosSemAvaliacao(
			oportunidadesExecutadas.length,
			notificacoes
		);

		this.setState({ notificacoes, qtdNotificacoes });
	};

	notificarCasoExistamRoteirosSemAvaliacao = (qtdaRoteiros, notificacoes) => {
		const { t, notificarAvaliacaoPendente } = this.props;
		if (notificarAvaliacaoPendente && qtdaRoteiros > 0 && qtdaRoteiros < 10) {
			let descricao = t("oportunidade.pendente.aprovacao");
			if (qtdaRoteiros > 1) {
				descricao = t("oportunidades.pendente.aprovacao").replace(
					/\{count\}/,
					qtdaRoteiros
				);
			}
			notificacoes.push({
				data: todayYYYYMMDD(),
				descricao,
				id: v4(),
				idRoteiro: v4(),
				link: "/oportunidades",
				titulo: t("aprovacao.pendente"),
				visto: 0,
			});
			this.props.dispatch(notificadoAvaliacaoPendente());
			return 1;
		}
		return 0;
	};

	checkMenu() {
		this.setState({ checked: !this.state.checked });
	}
	checkNotificacoes = () => {
		this.setState({ checkedNotificacoes: !this.state.checkedNotificacoes });
	};

	removerNotificacao = (item, index) => {
		db.collection("notificacoes").doc(item.id).delete();
		_.pullAt(this.state.notificacoes, index);
		this.setState({ notificacoes: this.state.notificacoes });
	};

	removerTodasNotificacoes = () => {
		this.setState({ apagando: true });
		var batch = db.batch();
		this.state.notificacoes.forEach(item => {
			var ref = db.collection("notificacoes").doc(item.id);
			batch.delete(ref);
		});
		batch
			.commit()
			.then(() => {
				this.setState({ apagando: false, notificacoes: [] });
			})
			.catch(() => {
				this.setState({ apagando: false });
			});
	};

	pedirEmailDeVerificacao = () => {
		const user = firebase.auth().currentUser;
		user.reload().then(
			() =>
				!user.emailVerified &&
				user
					.sendEmailVerification()
					.then(() =>
						this.props.enqueueSnackbar(this.props.t("confirmacao.enviada"), {
							variant: "success",
						})
					)
					.catch(() =>
						this.props.enqueueSnackbar(this.props.t("confirmacao.ja.foi.enviada"), {
							variant: "warning",
						})
					)
		);
	};

	atualizarinformacoesDaEmpresa = event =>
		db
			.collection("empresas")
			.doc(this.props.empresa.uid)
			.get()
			.then(empresaDoc => this.props.dispatch(atualizarEmpresa(empresaDoc.data())));

	openMenu = () => {
		this.setState({
			menuAberto: !this.state.menuAberto,
		});
	};

	handleNovoCadastro = empresa => {
		db.collection("empresas").doc(empresa.uid).update({
			novoCadastro: false,
		});
	};

	/**
	 * @name handleDismiss
	 * @description cuida para que a noticia seja fechada
	 */
	handleDismiss = () => {
		this.setState({ noticia: { ...this.state.noticia, escondida: true } });
	};

	/**
	 * @name handleOpenNoticia
	 * @description abre a noticia quando se aperta o botao
	 */
	handleOpenNoticia = () => {
		this.setState({ noticia: { ...this.state.noticia, escondida: false } });
	};

	render() {
		const { t, empresa, infoConta } = this.props;
		const { checked, checkedNotificacoes, qtdNotificacoes, notificacoes, apagando, noticia } =
			this.state;

		const verificacaoPendente =
			!infoConta || infoConta.last_verification_request_status !== "pending";
		const contaIuguNaoVerificada =
			empresa && (!empresa.iugu || contaNaoVerificadaIugu(empresa.iugu.verificadoIugu));
		const requisitarVerificacao =
			Boolean(contaIuguNaoVerificada) && Boolean(verificacaoPendente);

		const Component = this.props.component;
		const carregando = !this.props.usuario && !this.props.empresa && !this.props.mercado;

		return (
			<>
				<div>
					{carregando ? (
						<Segment
							style={{
								margin: 0,
								minHeight: "100vh",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Loader style={{ display: "block" }} inline="centered">
								Loading
							</Loader>
						</Segment>
					) : (
						<>
							<div
								className={"noticiaWeb"}
								style={{
									width: "100%",
									zIndex: 3000,
									position: "sticky",
									top: 0,
									marginBottom:
										noticia?.mostrar && !noticia?.escondida ? "1rem" : "0",
								}}>
								{noticia &&
									noticia?.mostrar === true &&
									noticia?.escondida !== true && (
										<Grid
											style={{
												backgroundColor: noticia.cor ?? "#b0b0b0",
												marginBottom: 0.3,
											}}>
											<GridColumn
												width={16}
												textAlign="center"
												style={{ padding: "1rem" }}>
												<span style={{ paddingTop: 10, paddingBottom: 5 }}>
													<Icon name={noticia.icone} size="large" />
													{noticia.texto}
												</span>
												<br />
												<Icon
													name="chevron up"
													bordered
													onClick={() => this.handleDismiss()}
													style={{
														cursor: "pointer",
														position: "absolute",
														top: "100%",
														zIndex: 9999,
														marginTop: "0.5rem",
													}}
												/>
											</GridColumn>
										</Grid>
									)}
								{noticia &&
									noticia?.mostrar === true &&
									noticia?.escondida === true && (
										<Grid stackable>
											<GridColumn width={16} textAlign="center">
												<Icon
													onClick={() => this.handleOpenNoticia()}
													name="chevron down"
													bordered
													style={{
														cursor: "pointer",
														position: "absolute",
														top: "100%",
														zIndex: 9999,
														transform: "translateY(-50%)",
													}}
												/>
											</GridColumn>
										</Grid>
									)}
							</div>
							<div>
								<Menu
									openMenu={this.openMenu}
									menuAberto={this.state.menuAberto}
									location={this.props.location}
									checkMenu={this.checkMenu}
									checked={checked}></Menu>
								<div
									className={
										"relative-margin-left" +
										(this.state.menuAberto
											? " relative-margin-left-fechado"
											: "")
									}>
									<AppBar
										checkMenu={this.checkMenu}
										checkNotificacao={this.checkNotificacoes}
										numeroNotificacoes={qtdNotificacoes}></AppBar>
									<Slide
										direction="left"
										in={checkedNotificacoes}
										mountOnEnter
										unmountOnExit>
										<Paper elevation={0}>
											<Notificacoes
												modulo={this.props.modulo}
												history={this.props.history}
												checkNotificacao={this.checkNotificacoes}
												checked={checkedNotificacoes}
												apagando={apagando}
												location={this.props.location}
												notificacoes={notificacoes}
												removerNotificacao={this.removerNotificacao}
												removerTodas={
													this.removerTodasNotificacoes
												}></Notificacoes>
										</Paper>
									</Slide>
									<div
										className="relative-margin-top"
										style={{ backgroundColor: "#fdfdfd" }}>
										{this.props.modulo !== 3 ? (
											<VerificacaoDeEmail
												emailVerificado={this.props.emailVerificado}
												onClick={this.pedirEmailDeVerificacao.bind(this)}>
												<Container
													fluid={this.props.fluid}
													style={{
														paddingTop: "20px",
														paddingBottom: "30px",
														minHeight: "90vh",
													}}>
													<Component {...this.props} />
												</Container>
											</VerificacaoDeEmail>
										) : (
											<Container
												fluid={this.props.fluid}
												style={{
													paddingTop: "20px",
													paddingBottom: "30px",
													minHeight: "90vh",
												}}>
												<Component {...this.props} />
											</Container>
										)}
									</div>
									<Footer></Footer>
								</div>
							</div>
						</>
					)}
				</div>
				<Modal
					open={
						process.env.NODE_ENV === "production" &&
						this.props.configuracao &&
						conf.version.split("-")[0] !== this.props.configuracao.atualizacaoWeb.versao
					}
					className="atualizacao">
					<Modal.Header>{t("atualizacao.disponivel")}</Modal.Header>
					<Modal.Content style={{ textAlign: "center", fontSize: 16 }}>
						<p>{t("identificamos.atualizacao")}</p>
						<p>
							{t("para.ter.acesso")} <br />
							<b>CTRL</b> e <b>F5</b>(<b>CTRL + F5</b>) {t("ao.mesmo.tempo")}
						</p>
						<img
							src={require("./../../imagens/ctrlf5.png")}
							width={"80%"}
							style={{ margin: 10 }}
							alt="Exemplo de como atualizar seu navegador."
						/>
						<p>
							<b>{t("nota")}</b>
							{t("tela.sumira")}
						</p>
					</Modal.Content>
				</Modal>
				{empresa && this.props.empresa.novoCadastro === true ? (
					<Modal open={this.props.empresa.novoCadastro}>
						<Modal.Header>{t("modal.novo.cadastro.header")}</Modal.Header>
						<Modal.Content style={{ textAlign: "center" }}>
							<p style={{ fontSize: "17px", lineHeight: "1.3" }}>
								{t("modal.novo.cadastro.descricao")}
							</p>
							<br />
							<Grid columns={2}>
								<Grid.Column textAlign={"right"}>
									<Button
										basic
										color="red"
										onClick={() => {
											this.handleNovoCadastro(empresa);
										}}>
										{t("cancelar")}
									</Button>
								</Grid.Column>
								<Grid.Column textAlign={"left"}>
									<Button positive>
										<Link
											to="/selecaoAutomatica"
											onClick={() => {
												this.handleNovoCadastro(empresa);
											}}
											style={{
												textDecoration: "none",
												fontWeight: "bold",
												color: "white",
											}}>
											{t("continuar")}
										</Link>
									</Button>
								</Grid.Column>
							</Grid>
						</Modal.Content>
					</Modal>
				) : null}
				{empresa && (
					<VerificacaoDeBanco
						t={t}
						open={requisitarVerificacao}
						empresa={empresa}
						onVerificacaoDeConta={this.atualizarinformacoesDaEmpresa.bind(this)}
					/>
				)}
			</>
		); //#ffbb57 text: #794a03
	}
}
const mapStateToProps = state => {
	const auth = firebase.auth().currentUser;
	return {
		emailVerificado: auth.emailVerified || false,
		infoConta: state.AutenticacaoReducer.infoConta,
		empresa: state.AutenticacaoReducer.empresa,
		oportunidadesExecutadas: state.RoteiroReducer.oportunidadesExecutadas,
		oportunidadesNovas: state.RoteiroReducer.oportunidadesNovas,
		notificarAvaliacaoPendente: state.RecursosReducer.notificarAvaliacaoPendente,
		configuracao: state.RecursosReducer.configuracao,
		modulo: state.AutenticacaoReducer.modulo,
		mercado: state.AutenticacaoReducer.mercado,
	};
};

export default withSnackbar(connect(mapStateToProps)(withTranslation()(Template)));
