import { Button } from "semantic-ui-react";
import styled from "styled-components";

export const InvoiceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 26px;
`;

export const InvoiceView = styled.iframe`
    border: 0;
    height: 900px;
    box-shadow: none;
    max-width: initial;
`;

export const ButtonClose = styled(Button)``;
