import axios from "axios";
import firebase from "firebase";
import _ from "lodash";
import request from "request";
import Configuration from "../configuration.json";
import { log } from "./Debugging.js";

/**
 * Pega o token do usuário logado.
 * Autor: Renahn Schultz
 */
export const getToken = () => firebase.auth().currentUser.getIdToken(true);

const onResponse = (resolved, rejected) => (errors, response, body) => {
	if (process.env.NODE_ENV === "development") {
		console.log("Resposta da Api:");
		console.log("Error:", errors);
		console.log("Response object:", response);
		console.log("Response data:", body);
	}
	const hasError = _.isEmpty(body.result) || (body.result.data && body.result.data.error);
	if (!hasError) {
		return resolved(body.result.data || body.result.body || body.result);
	}
	const erroDefault = body.result ? body.result.data : { message: "Ocorreu um erro inesperado." };
	return rejected(erroDefault);
};

export const chamarFunction = (funcao, data) =>
	new Promise((resolved, rejected) =>
		getToken().then(token_id =>
			axios(
				`https://tradepro-cors.herokuapp.com/https://${Configuration.baseUrl}/${funcao}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + token_id,
					},
					data: { data },
					json: true,
				}
			)
				.then(res => {
					return onResponse(resolved, rejected)([], res, res.data);
				})
				.catch(err => rejected(err))
		)
	);
export const chamarNodeApi = (funcao, data, method = "GET") =>
	new Promise((resolved, rejected) =>
		getToken().then(token_id =>
			request(
				{
					method: method,
					url: `${Configuration.nodeAPIURL}/${funcao}`,
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + token_id,
					},
					body: { ...data, dev: Configuration.dev },
					json: true,
				},
				(errors, response, body) => {
					try {
						if (response?.statusCode === 404) {
							rejected(response?.statusCode || 404);
						} else {
							resolved(body);
						}
					} catch (e) {
						rejected(e);
					}
				}
			)
		)
	);

export const cadastrarEmpresa = (token_id, empresa) =>
	new Promise((resolved, rejected) =>
		request(
			{
				method: "POST",
				url: `https://${Configuration.baseUrl}/cadastroEmpresa`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token_id,
				},
				body: { data: { empresa } },
				json: true,
			},
			(error, response, body) => (error ? rejected(error) : resolved(body.result))
		)
	);

export const comunicarLojaPorEmail = (token_id, empresa, roteiro, emails) => {
	log("NotificarVisita", emails);
	return new Promise((resolved, rejected) =>
		request(
			{
				method: "POST",
				url: `https://${Configuration.baseUrl}/comunicarVisitaDoPromotor`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token_id,
				},
				body: { data: { empresa, roteiro, emails } },
				json: true,
			},
			onResponse(resolved, rejected)
		)
	);
};

const errorDoFirebase = data => data.hasOwnProperty("error");

export const informacoesConta = (token_id, id) =>
	new Promise((resolved, rejected) =>
		request(
			{
				method: "POST",
				url: `https://${Configuration.baseUrl}/informacoesConta`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token_id,
				},
				body: { data: { empresa: true, id } },
				json: true,
			},
			(error, response, body) =>
				error || errorDoFirebase(body)
					? rejected(error || body)
					: resolved(body.result.body)
		)
	);
export const listarSaques = (token_id, id) =>
	new Promise((resolved, rejected) =>
		request(
			{
				method: "POST",
				url: `https://${Configuration.baseUrl}/listarSaques`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token_id,
				},
				body: { data: { empresa: true, id } },
				json: true,
			},
			(error, response, body) =>
				error || errorDoFirebase(body)
					? rejected(error || body)
					: resolved(body.result.body)
		)
	);

export const verificarSubConta = (token_id, data) =>
	new Promise((resolved, rejected) =>
		request(
			{
				method: "POST",
				url: `https://${Configuration.baseUrl}/verificacaoSubconta`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token_id,
				},
				body: { data: { empresa: true, ...data } },
				json: true,
			},
			(error, response, body) => {
				if (error) {
					rejected({ error: "firebase_api_error", code: "Firebase/Api" });
					return;
				}
				if (body.result.error) {
					rejected(body.result);
					return;
				}
				resolved(body);
			}
		)
	);

export const solicitarSaque = (token_id, data) =>
	new Promise((resolved, rejected) =>
		request(
			{
				method: "POST",
				url: `https://${Configuration.baseUrl}/pedirSaque`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token_id,
				},
				body: {
					data: {
						empresa: data.id,
						valor: data.valor,
					},
				},
				json: true,
			},
			(error, response, body) =>
				error || errorDoFirebase(body)
					? rejected(error || body)
					: resolved(body.result.body)
		)
	);

export const validarPromotorAgencia = (token_id, phoneNumber) =>
	new Promise((resolved, rejected) =>
		request(
			{
				method: "POST",
				url: `https://${Configuration.baseUrl}/validaPromotorAgencia`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token_id,
				},
				body: { data: { phoneNumber } },
				json: true,
			},
			(error, response, body) =>
				error || errorDoFirebase(body) ? rejected(error || body) : resolved(body.result)
		)
	);

export const vincularPromotorAgencia = (token_id, uid, uidEmpresa) =>
	new Promise((resolved, rejected) =>
		request(
			{
				method: "POST",
				url: `https://${Configuration.baseUrl}/vinculaPromotorAgencia`,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token_id,
				},
				body: { data: { uid: uid, uidEmpresa: uidEmpresa } },
				json: true,
			},
			(error, response, body) =>
				error || errorDoFirebase(body) ? rejected(error || body) : resolved(body.result)
		)
	);

export const bigQuery = query => chamarFunction("queryBigQuery", { query });
