import firebase from "firebase";
import { Notification } from "../../../@types/Notification";

export class NotificationService {
    private static instance: NotificationService;

    public static getInstance(): NotificationService {
        if (!NotificationService.instance) {
            NotificationService.instance = new NotificationService();
        }
        return NotificationService.instance;
    }

    async createOne(data: Notification) {
        const docRef = firebase.firestore().collection("notificacoes").doc(data.id);

        await docRef.set(data);

        return data;
    }
}
