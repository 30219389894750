import moment from "moment";
import img from "../../imagens/icon-min.png";
import { formatCNPJ } from "../../Utils/CNPJ";
import { gerarPdf, gerarPdfs } from "../../Utils/gerar-pdf";

var formatter = new Intl.NumberFormat("PT-BR", {
	style: "currency",
	currency: "BRL",
});

const header = () => /*html*/ `
    <section class="header">
        <img class="logo"
            src="${img}" />
        <hr>
    </section>
    `;

const notaDeDebitoMensalOmie = ({ roteiro, notaFiscal, ref }) => /*html*/ `
    <section>
        <h2 class="titulo">Nota de débito</h2>
        <table>
            <tr>
                <th>
                    Emitente
                </th>
                <th>
                    Dados da nota de débito
                </th>
            </tr>
            <tr>
                <td>
                    <p><b>Nome:</b> TRADEPRO TECNOLOGIA LTDA</p>
                    <p><b>CNPJ:</b> 13.735.269/0001-68</p>
                    <p><b>Insc. Municipal:</b> 60558</p>
                    <p><b>Endereço:</b> Rodovia João Lepoldo Jacomel,<br> 12475, Sala 24, Centro, Pinhais, PR</p>
                    <p><b>E-mail:</b> contato@promotor.com.br</p>
                    <p><b>Site:</b> www.promotor.com.br</p>
                    <p><b>Telefone:</b> 41 3056-0645</p>
                </td>
                <td>
                    <p><b>Número:</b> ${ref}</p>
                    <p><b>Data de Emissão:</b> ${notaFiscal?.emissao ? moment(notaFiscal.emissao).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")}</p>
                    <p><b>Período de referência:</b> ${roteiro?.fatura?.substring(0, 2) + "/" + roteiro?.fatura?.substring(2, 6)}</p>
                    <p><b>Natureza da Operação:</b> Prestação de serviço</p>
                    <p><b>Prestação de serviços:</b> Serviço de merchandising</p>
                    <p><b>Dia do Pagamento:</b> ${moment(roteiro?.dataPagamento).format("DD/MM/YYYY")}</p>
                </td>
            </tr>
        </table>
    </section>
`;

const notaDeDebito = ({ data, ref }) => /*html*/ `
    <section>
        <h2 class="titulo">Nota de débito</h2>
        <table>
            <tr>
                <th>
                    Emitente
                </th>
                <th>
                    Dados da nota de débito
                </th>
            </tr>
            <tr>
                <td>
                    <p><b>Nome:</b> MAGICTRADE SISTEMAS EIRELI</p>
                    <p><b>CNPJ:</b> 26.579.906/0001-04</p>
                    <p><b>Insc. Municipal:</b> 68472</p>
                    <p><b>Endereço:</b> Rodovia João Lepoldo Jacomel,<br> 12475, Sala 24, Centro, Pinhais, PR</p>
                    <p><b>E-mail:</b> contato@promotor.com.br</p>
                    <p><b>Site:</b> www.promotor.com.br</p>
                    <p><b>Telefone:</b> 41 3056-0645</p>
                </td>
                <td>
                    <p><b>Número:</b> ${ref}</p>
                    <p><b>Data de Emissão:</b> ${moment().format("DD/MM/YYYY")}</p>
                    <p><b>Período de referência:</b> ${moment(data).format("MM/YYYY")}</p>
                    <p><b>Natureza da Operação:</b> Prestação de serviço</p>
                    <p><b>Prestação de serviços:</b> Serviço de merchandising</p>
                    <p><b>Dia do Pagamento:</b> ${moment(data).format("DD/MM/YYYY")}</p>
                </td>
            </tr>
        </table>
    </section>
`;

/**
 * @name notaDeDebitoMensal
 * @date Criado em 21/01/2021
 * @author Alexandre Poletto
 * @description Layout da nota de débito
 * @param {String} emissao data de emissão e pagamento
 * @param {String} referencia data referência
 * @param {String} rps número rps da nota
 */
const notaDeDebitoMensal = ({ emissao, referencia, rps }) => /*html*/ `
    <section>
        <h2 class="titulo">Nota de débito</h2>
        <table>
            <tr>
                <th>
                    Emitente
                </th>
                <th>
                    Dados da nota de débito
                </th>
            </tr>
            <tr>
                <td>
                    <p><b>Nome:</b> Promotor Freelance S.A</p>
                    <p><b>CNPJ:</b> 26.579.906/0001-04</p>
                    <p><b>Insc. Municipal:</b> 68472</p>
                    <p><b>Endereço:</b> Rodovia João Lepoldo Jacomel,<br> 12475, Sala 24, Centro, Pinhais, PR</p>
                    <p><b>E-mail:</b> contato@promotor.com.br</p>
                    <p><b>Site:</b> www.promotor.com.br</p>
                    <p><b>Telefone:</b> 41 3056-0645</p>
                </td>
                <td>
                    <p><b>Número:</b> ${rps}</p>
                    <p><b>Data de Emissão:</b> ${moment(emissao.toDate()).format("DD/MM/YYYY")}</p>
                    <p><b>Período de referência:</b> ${referencia}</p>
                    <p><b>Natureza da Operação:</b> Prestação de serviço</p>
                    <p><b>Prestação de serviços:</b> Serviço de merchandising</p>
                    <p><b>Dia do Pagamento:</b> ${moment(emissao.toDate()).format("DD/MM/YYYY")}</p>
                </td>
            </tr>
        </table>
    </section>
`;

const tomadorDeServico = ({ fantasiaEmpresa, cnpjEmpresa, enderecoEmpresa }) => /*html*/ `
        <section>
            <h2 class="titulo">Tomador de Serviço</h2>
            <table>
                <tr>
                    <td>
                        <p><b>Nome:</b> ${fantasiaEmpresa}</p>
                        <p><b>Endereço:</b> ${enderecoEmpresa}</p>
                        <p><b>CNPJ:</b> ${formatCNPJ(cnpjEmpresa)}</p>
                    </td>
                </tr>
            </table>
        </section>
`;

const sumarioDeDespesas = ({ valor }) => /*html*/ `
        <section>
            <h2 class="titulo">Sumário de despesas</h2>
            <table>
                <tr>
                    <th>
                        Discriminação dos Serviços Prestados
                    </th>
                    <th>
                        Valor
                    </th>
                </tr>
                <tr>
                    <td>
                        <p style="text-align: justify; margin-right: 10px">Essa nota de débito consolida os valores de serviços de merchandising realizados através da Plataforma Promotor Freelance, pelo prestador de serviços (ou pelos prestadores de serviços) na data informada.
                        Tal serviço não sofrerá retenções em razão dos prestadores de serviços de merchandising (MEI), serem beneficiados pelo Regime Tributário LC nº 123/06 e LC nº 128/08 (Lei do Simples Nacional e do Microempreendedor Individual).</p>
                    </td>
                    <td style="min-width: 100px;">
                        <p>${formatter.format(valor)}</p>
                    </td>
                </tr>
            </table>
        </section>
`;

const outrasInformacoes = () => /*html*/ `
        <section>
            <h2 class="titulo">Outras informações</h2>
            <hr>
            <p style="font-size: 11px; text-align: justify;">O serviço de merchandising não sofrerá retenções em razão dos prestadores de serviços de merchandising serem Micro Empreendedores Individuais - MEI - beneficiados pelo Regime Tributário previsto na LC nº 123/06 e LC nº 128/08 (Lei do Simples Nacional e do Microempreendedor Individual).
            De acordo com o decreto 1609/2014, Capítulo VI, SEÇÃO IV do município de Pinhais - PR, as empresas enquadradas no regime de Microempreendedor Individual - MEI optante do SIMEI  inscritos e licenciados no Município, Estão dispensados da emissão da NFS-e.
            </p>
        </section>
`;
/**
 * @name gerarNotaDeDepositoOmie
 * @description Gerar a nota de depósito
 * @param roteiro um roteiro específico enviado
 * @param notaFiscal dados da nota fiscal
 * @param mensal se é de faturamento mensal
 * @param ref número da nota de débito
 */
export const gerarNotaDeDepositoOmie = (roteiro, notaFiscal, mensal, ref) => {
	const { fantasiaEmpresa, cnpjEmpresa, enderecoEmpresa, data } = roteiro;
	const toggleLoad = () => {};
	let componente = /*html*/ `
        <div class='tabbed'>
            <div class="container">
                ${header()}
                ${mensal ? notaDeDebitoMensalOmie({ roteiro, notaFiscal, ref }) : notaDeDebito({ data, ref })}
                ${tomadorDeServico({ fantasiaEmpresa, cnpjEmpresa, enderecoEmpresa })}
                ${sumarioDeDespesas({ valor: roteiro?.pagamento?.valorPromotor })}
                ${outrasInformacoes()}
            </div>
        </div>`;
	return gerarPdf("Nota de Débito", componente, style, toggleLoad, "height=1200,width=700");
};

/**
 * @name gerarNotaDeDepositoMensal
 * @date Criado em 21/01/2021
 * @author Alexandre Poletto
 * @description Gera a nota de depósito e nota fiscal
 * @param {Object} roteiro um roteiro específico enviado
 * @param {Object} data data de emissão e referência
 * @param {String} rps número rps da nota
 * @param {String} nfs body da nota fiscal
 */
export const gerarNotaDeDepositoMensal = (roteiro, data, rps, nfs) => {
	const { fantasiaEmpresa, cnpjEmpresa, enderecoEmpresa } = roteiro;
	const { emissao, referencia } = data;
	const toggleLoad = () => {};
	let componente = /*html*/ `
        <div class='tabbed'>
            ${
				nfs !== "" || nfs !== undefined
					? `<div class='break-after'>
                ${nfs}
            </div>`
					: `<div />`
			}
            <div class="container">
                ${header()}
                ${notaDeDebitoMensal({ emissao, referencia, rps })}
                ${tomadorDeServico({ fantasiaEmpresa, cnpjEmpresa, enderecoEmpresa })}
                ${sumarioDeDespesas({ valor: roteiro.pagamento.valorPromotor })}
                ${outrasInformacoes()}
            </div>
        </div>`;
	return gerarPdf("", componente, style, toggleLoad, "height=1200,width=700");
};

/**
 * @name gerarNotaDeDeposito
 * @description Gera a nota de depósito e nota fiscal
 * @param {Object} roteiro um roteiro específico enviado
 * @param {Object} style estilo da página
 * @param {Boolean} toogleLoad loading
 * @param {String} rps número rps da nota
 * @param {String} nfs body da nota fiscal
 */
export const gerarNotaDeDeposito = (roteiro, toogleLoad, t, rps, nfs) => {
	const { fantasiaEmpresa, cnpjEmpresa, enderecoEmpresa, data } = roteiro;
	let componente = /*html*/ `
        <div class='tabbed'>
            ${
				nfs !== "" || nfs !== undefined
					? `<div class='break-after'>
                ${nfs}
            </div>`
					: `<div />`
			}
            <div class="container">
                ${header()}
                ${notaDeDebito({ data, rps })}
                ${tomadorDeServico({ fantasiaEmpresa, cnpjEmpresa, enderecoEmpresa })}
                ${sumarioDeDespesas({ valor: roteiro.pagamento.valorPromotor })}
                ${outrasInformacoes()}
            </div>
        </div>`;
	var title = roteiro.usuario.sexo === "Agência" ? "Nota fiscal" : "";
	return gerarPdf(title, componente, style, toogleLoad, "height=1200,width=700");
};

/**
 * @name gerarNotasDeDeposito
 * @date Criado em 15/12/2020
 * @author Alexandre Poletto
 * @description Gera várias notas de depósito e notas fiscais
 * @param {Object} notas as notas a serem geradas
 * @param {Boolean} toogleLoad loading
 */
export const gerarNotasDeDeposito = (notas, toogleLoad) => {
	let notasReduce = Object.values(notas).reduce((acc, nota) => {
		const { fantasiaEmpresa, cnpjEmpresa, enderecoEmpresa, data, pagamento, usuario, id } =
			nota.roteiro;
		const { nfs } = nota;
		const rps = nota.rps === undefined ? "Sem número" : nota.rps;
		let componente = /*html*/ `
            <div class='tabbed'>
                ${
					nfs !== undefined
						? `<div class='containerNotas'>
                    ${nfs}
                </div>`
						: `<div />`
				}
                <div class="container">
                    ${header()}
                    ${notaDeDebito({ data, rps })}
                    ${tomadorDeServico({ fantasiaEmpresa, cnpjEmpresa, enderecoEmpresa })}
                    ${sumarioDeDespesas({ valor: pagamento.valorPromotor })}
                    ${outrasInformacoes()}
                </div>
            </div>`;
		let title = usuario.sexo === "Agência" ? `Nota fiscal` : `Nota de débito`;
		return { ...acc, [id]: { title, componente } };
	}, {});
	return gerarPdfs(notasReduce, style, toogleLoad, "height=1200,width=700");
};

const style = /*html*/ `<style>
    body {
        font-family: 'Segoe UI', sans-serif;
        display: block;
    }
    .tabbed{
        float: none;
    }
    .titulo {
        text-align: center;
        font-weight: 500;
        font-size: 18px;
    }
    .break-after {
        display: block;
        page-break-after: always;
        position: relative;
    }
    .container {
        display: block;
        page-break-before: always;
        position: relative;
        width: 80%;
        margin: auto;
    }
    .containerNotas {
        display: block;
        page-break-before: always;
        position: relative;
        width: 100%;
        margin: auto;
    }

    .logo {
        width: 65px;
        margin: 20px 0px 10px 0px;
    }

    p {
        margin: 0px 0px 3px 0px;
    }

    table {
        width: 100%;
        font-size: 11px;
    }

    th {
        text-align: left;
        padding-left: 10px;
    }

    td {
        vertical-align: top;
        padding-left: 10px;
    }

    table,
    th,
    td {
        border: 1px solid #666666;
        border-collapse: collapse;
    }
</style>`;
