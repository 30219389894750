import chalk from "chalk";
import update from "immutability-helper";
import { forEach } from "lodash";
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef } from "react";
import { Unsubscribe } from "../../@types/Firebase";
import { useAppSelector } from "../../hooks/useAppSelector";

interface UnsubscribeFunctions {
    negotiations: Unsubscribe | null;
}

interface FirebaseUnsubscribeFunctionsContextProps {
    setUnsubscribe: (key: keyof UnsubscribeFunctions, unsubscribe: Unsubscribe | null) => void;
    runUnsubscribes: () => void;
    runUnsubscribe: (key: keyof UnsubscribeFunctions) => void;
    getUnsubscribe: (key: keyof UnsubscribeFunctions) => Unsubscribe | null;
}

interface FirebaseUnsubscribeFunctionsProviderProps {
    children: ReactNode;
}

const initialState: UnsubscribeFunctions = {
    negotiations: null,
};

type UnsubscribeKeys = keyof UnsubscribeFunctions;

const UnsubscribeColors: { [key in UnsubscribeKeys]: chalk.Chalk } = {
    negotiations: chalk.blue,
};

const FirebaseUnsubscribeFunctionsContext = createContext<FirebaseUnsubscribeFunctionsContextProps | null>(null);

export function FirebaseUnsubscribeFunctionsProvider({ children }: FirebaseUnsubscribeFunctionsProviderProps) {
    const unsubscribes = useRef<UnsubscribeFunctions>(initialState);
    const { autenticado } = useAppSelector((state) => state.AutenticacaoReducer);

    const getUnsubscribe = useCallback((key: keyof UnsubscribeFunctions) => unsubscribes.current[key], []);

    const logProvider = useCallback((context: UnsubscribeKeys, message: string) => {
        const title = chalk.bold(`[Snapshots]`);
        const contextColor = UnsubscribeColors[context](context);

        console.log(`${title} ${contextColor}: ${message}`);
    }, []);

    const runUnsubscribes = useCallback(() => {
        forEach(unsubscribes.current, (unsubscribe, key) => {
            if (unsubscribe) {
                unsubscribe();
                logProvider(key as UnsubscribeKeys, "Desinscrito");
            }
        });

        unsubscribes.current = initialState;
    }, [logProvider]);

    const runUnsubscribe = useCallback(
        (key: UnsubscribeKeys) => {
            const unsubscribe = unsubscribes.current[key];
            if (unsubscribe) {
                unsubscribe();
                logProvider(key, "Desinscrito");
                unsubscribes.current = update(unsubscribes.current, { [key]: { $set: null } });
            }
        },
        [logProvider]
    );

    const setUnsubscribe = useCallback(
        (key: UnsubscribeKeys, unsubscribe: Unsubscribe | null) => {
            runUnsubscribe(key);
            unsubscribes.current = update(unsubscribes.current, { [key]: { $set: unsubscribe } });
            logProvider(key, "Inscrito");
        },
        [logProvider, runUnsubscribe]
    );

    //NOTE: Ao deslogar o usuário, desinscreve de todos os snapshots
    useEffect(() => {
        if (autenticado !== "LOGADO") {
            runUnsubscribes();
        }
    }, [autenticado, runUnsubscribes]);

    return (
        <FirebaseUnsubscribeFunctionsContext.Provider
            value={{ setUnsubscribe, runUnsubscribes, runUnsubscribe, getUnsubscribe }}
        >
            {children}
        </FirebaseUnsubscribeFunctionsContext.Provider>
    );
}

export function useFirebaseUnsubscribeFunctions() {
    const context = useContext(FirebaseUnsubscribeFunctionsContext)!;

    if (!context)
        throw new Error(
            "FirebaseUnsubscribeFunctionsContext is not provided. Please wrap your component with FirebaseUnsubscribeFunctionsProvider."
        );

    return context;
}
