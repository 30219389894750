import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonProps, Icon, Popup } from "semantic-ui-react";

interface Promoter {
    fantasia: string;
    id: string;
    status: string;
}

interface ButtonAutomaticSelectionProps extends ButtonProps {
    automaticPromoter?: Promoter | null;
}

export function ButtonAutomaticSelection({ automaticPromoter, ...rest }: ButtonAutomaticSelectionProps) {
    const { t } = useTranslation();

    return (
        <Popup
            content={
                automaticPromoter ? t("remover.da.selecao.automatica.texto") : t("propor.selecao.automatica.texto")
            }
            trigger={
                <Button basic color={automaticPromoter ? "red" : "yellow"} {...rest}>
                    <Button.Content>
                        <Icon name={automaticPromoter ? "times" : "star"} />
                        {automaticPromoter ? t("remover.da.selecao.automatica") : t("propor.selecao.automatica")}
                    </Button.Content>
                </Button>
            }
        />
    );
}
