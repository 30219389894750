import { Input, InputGroup, InputLeftAddon, InputProps } from "@chakra-ui/react";
import { forwardRef, ReactNode } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { PRICE_MASK } from "../../Utils/Masks";

export interface PriceInputProps extends NumericFormatProps<InputProps> {
	leftAddon?: ReactNode;
}

/**
 * Componente que renderiza um input com uma mascara de preços.
 */
export const PriceInput = forwardRef<HTMLInputElement, PriceInputProps>(function PriceInput(
	{ leftAddon, ...rest },
	ref
) {
	return (
		<InputGroup>
			<InputLeftAddon>{leftAddon || "R$"}</InputLeftAddon>
			<NumericFormat
				customInput={Input}
				w="full"
				getInputRef={ref}
				placeholder="0,00"
				{...PRICE_MASK}
				{...rest}
			/>
		</InputGroup>
	);
});
