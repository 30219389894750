import firebase from "firebase";
import _ from "lodash";
import { withSnackbar } from "notistack";
import React, { Component, useState } from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { Button, Dropdown, Form, Grid, Icon, Message } from "semantic-ui-react";
import { v4 } from "uuid";
import { HeaderPadrao } from "../../../Componentes/Componentes";
import { logging } from "../../../services/Api";
import { downloadLojasDaEmpresa } from "../../../Utils/Oportunidades";
import { lojasDaEmpresa } from "../../../_actions/RecursoActions";
import RoteiroConnect from "../RoteiroConnect";
import cadastarRecorrencias from "./CadastrarProximasRecorrencias";
import "./recorrencia.scss";
import { ordenarDiasDaSemana } from "./utils";
const DefaultDropdown = props => (
	<>
		<label>{props.label}</label>
		<Dropdown
			fluid
			{...props}
			className={`dropdown-style input-border-color ${props.className}`}
			value={props.value}
			placeholder={props.placeholder}
			selectOnBlur={false}
			options={props.options}
			onChange={props.onChange}
		/>
	</>
);

const DiasDaSemana = ({ value, onChange, label }) => {
	let lista = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"];
	let letra = {
		Seg: "S",
		Ter: "T",
		Qua: "Q",
		Qui: "Q",
		Sex: "S",
		Sab: "S",
		Dom: "D",
	};

	let ativo = text => value.includes(text);

	let adicionar = text => {
		if (!ativo(text)) {
			onChange([...value, text]);
		} else {
			let remove = value.filter(e => e !== text);
			onChange([...remove]);
		}
	};

	let Item = ({ text }) => (
		<div
			style={{
				width: 60,
				height: 60,
				backgroundColor: ativo(text) ? "#b33dff" : "#fff",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				cursor: "pointer",
				border: `2px solid ${ativo(text) ? "#7501c0" : "#eee"}`,
				borderRadius: 5,
			}}
			onClick={() => adicionar(text)}>
			<span
				style={{
					margin: 0,
					padding: 0,
					textAlign: "center",
					color: ativo(text) ? "#fff" : "#666",
					fontSize: 10,
				}}>
				<span style={{ fontSize: 15 }}>{letra[text]}</span>
				<br />
				{text}
			</span>
		</div>
	);

	return (
		<>
			<label>{label}</label>
			<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
				{lista.map(text => (
					<Item text={text} />
				))}
			</div>
		</>
	);
};

const Formulario = props => {
	let {
		t,
		onSubmit,
		onChangeModelo,
		onSearchChange,
		onChangePdv,
		onChangeDias,
		dias,
		lojas,
		modelos,
		error,
		carregando,
		pdv,
		modelo,
	} = props;
	const [redirecionar, setRedirecionar] = useState(false);
	let pdvs = lojas?.map(loja => ({
		key: loja.id,
		value: loja,
		text: loja.fantasia,
		content: (
			<div>
				<span>{loja.fantasia}</span>
				<br />
				<p style={{ fontWeight: "normal", fontSize: 12, opacity: 0.6 }}>
					{loja.endereco.endereco}
				</p>
			</div>
		),
	}));

	let models = modelos?.map(model => ({
		key: model.id,
		value: model,
		text: model.nome,
		content: (
			<div>
				<span>{model.nome}</span>
				<br />
			</div>
		),
	}));

	return (
		<section>
			{redirecionar && <Redirect to="/oportunidades/4" />}
			<Form className="Formulario-Recorrencia" onSubmit={onSubmit}>
				<DefaultDropdown
					required
					name="loja"
					className="select-loja"
					label={"Selecione um modelo."}
					placeholder={"Selecione um modelo."}
					value={modelo}
					options={models}
					onChange={onChangeModelo}
					search={true}
					onSearchChange={onSearchChange}
					noResultsMessage={<span>{t("Nenhum.resultado.encontrado")}</span>}
					selection
					fluid
				/>
				<DefaultDropdown
					required
					name="loja"
					className="select-loja"
					label={t("onde.sera.executado")}
					placeholder={t("onde.sera.executado")}
					value={pdv}
					options={pdvs}
					onChange={onChangePdv}
					search={true}
					onSearchChange={onSearchChange}
					noResultsMessage={<span>{t("Nenhum.resultado.encontrado")}</span>}
					selection
					fluid
				/>
				<DiasDaSemana
					onChange={onChangeDias}
					value={dias}
					label="Selecione os dias que serão executados."
				/>
				{error === true && (
					<Message negative>
						<Message.Header>{t("Ops.Algo.de.errado.aconteceu")}</Message.Header>
						<p>{t("Preencha.todos.os.campos")}</p>
					</Message>
				)}
				<div style={{ display: "flex" }}>
					<Form.Button
						color={error === true ? "red" : "green"}
						style={{ marginTop: 10 }}
						loading={carregando}>
						Salvar
					</Form.Button>
					<Form.Button
						color="red"
						style={{ marginTop: 10 }}
						loading={carregando}
						content={props.t("cancelar")}
						onClick={e => {
							e.preventDefault();
							setRedirecionar(true);
						}}
					/>
				</div>
			</Form>
		</section>
	);
};

const Placeholder = ({ width }) => (
	<div style={{ width, height: 30, marginBottom: 10 }} className={"placeholder-animado"} />
);

const renderSecoes = (secoes, t) => {
	return _.map(secoes, (secao, index) => {
		return (
			<div key={secao.id} style={{ marginLeft: 10 }}>
				<Grid stackable>
					<Grid.Column width={10} textAlign="left">
						<h3 style={{ fontSize: 13, fontWeight: "bold" }}>{secao.nome}</h3>
						<div style={{ marginLeft: 10, color: "#666666" }}>
							{_.includes(secao.atividades, 6) && (
								<div>
									<Icon name="check" /> {t("reposicao")}
								</div>
							)}
							{_.includes(secao.atividades, 3) && (
								<div>
									<Icon name="check" /> {t("pesquisa.de.preco")}
								</div>
							)}
							{_.includes(secao.atividades, 2) && (
								<div>
									<Icon name="check" /> {t("pesquisa.de.validade")}
								</div>
							)}
							{_.includes(secao.atividades, 1) && (
								<div>
									<Icon name="check" /> {t("foto.antes.depois")}
								</div>
							)}
						</div>
					</Grid.Column>
					<Grid.Column
						width={4}
						style={{
							paddingRight: 10,
							display: "flex",
							flex: 1,
							justifyContent: "flex-end",
						}}>
						<h3 style={{ fontSize: 13, fontWeight: "bold" }}>
							{secao.produtos.length}{" "}
							{secao.produtos.length > 1 ? t("produtos") : t("produto")}
						</h3>
					</Grid.Column>
				</Grid>
				{Object.keys(secoes)[Object.keys(secoes).length - 1] !== index ? <hr /> : <></>}
			</div>
		);
	});
};

const renderTreinamentos = treinamentos => {
	return treinamentos.map(({ id, nome, notaAprovacao }, index) => {
		return (
			<div key={id} style={{ marginLeft: 10 }}>
				<h3
					style={{
						fontSize: 13,
						fontWeight: "bold",
						marginTop: index === 0 ? 10 : 20,
						lineHeight: 0,
					}}>
					{nome}
				</h3>
				<span
					style={{
						fontSize: 12,
						fontWeight: "lighter",
						color: "#666",
						margin: 0,
						padding: 0,
					}}>
					{"Nota.de.aprovação"}: {notaAprovacao}
				</span>
				{treinamentos.length - 1 !== index ? <hr /> : <></>}
			</div>
		);
	});
};

const Info = ({ modelo, pdv, dias, t, handleChangeRedirecionaModelo, redirecionaModelo }) => {
	let labelStyle = { fontSize: 18 };
	return (
		<section className="info-recorrencia">
			{redirecionaModelo && (
				<Redirect
					to={{
						pathname: `/roteiro/novo`,
						state: modelo,
					}}
				/>
			)}
			{!pdv?.fantasia && !modelo?.id ? (
				<>
					<Placeholder width={300} />
					<Placeholder width={100} />
					<Placeholder width={250} />
					<Placeholder width={50} />
					<Placeholder width={200} />
					<Placeholder width={60} />
					<Placeholder width={70} />
					<Placeholder width={30} />
				</>
			) : (
				<>
					<h2>{pdv?.fantasia ?? "PDV"}</h2>
					{modelo?.id && (
						<div>
							<label style={labelStyle}>{t("Periodo")}:</label>
							<p>
								{" "}
								{modelo?.roteiro?.horaInicio} - {modelo?.roteiro?.horaFim}
							</p>
							<hr />
							{!_.isEmpty(dias) && (
								<>
									<label style={labelStyle}>{t("dias.de.execucao")}:</label>
									<p>{dias.map(dia => dia)}</p>
									<hr />
								</>
							)}
							<label style={labelStyle}>{t("tempo.estimado")}:</label>
							<p>{modelo?.roteiro?.tempoEstimado}</p>
							<hr />
							<label style={labelStyle}>{t("descricao")}:</label>
							<p>{modelo?.roteiro?.descricao}</p>
							<hr />
							{modelo?.roteiro?.treinamentos && (
								<>
									<label style={labelStyle}>{t("treinamentos")}:</label>
									<br />
									{renderTreinamentos(modelo?.roteiro?.treinamentos)}
									<hr />
								</>
							)}
							{modelo?.roteiro?.secoes && (
								<>
									<label style={labelStyle}>{t("secoes")}:</label>
									{renderSecoes(modelo?.roteiro?.secoes, t)}
									<hr />
								</>
							)}
							<label style={{ fontSize: 20 }}>
								{t("valor")}:{" "}
								{modelo?.roteiro?.valor.toLocaleString("BRL", {
									style: "currency",
									currency: "BRL",
								})}
							</label>
							<p style={{ margin: 0, lineHeight: 1, color: "#666" }}>
								{t("Valor.por.oportunidade.publicada")}
							</p>
							<hr />
							<Button
								fluid
								color="violet"
								content="Editar Modelo"
								onClick={e => {
									e.preventDefault();
									handleChangeRedirecionaModelo();
								}}
							/>
						</div>
					)}
				</>
			)}
		</section>
	);
};

class CadastroRecorrencia extends Component {
	constructor(props) {
		super(props);
		const ipLocation = this.props.ipLocation;
		const empresa = this.props.empresa;
		this.state = {
			dias: [],
			ipLocation,
			empresa,
			pdv: null,
			modelo: null,
			buscandoRecorrencia: true,
			redirecionaModelo: false,
			promotoresBloqueados: [],
		};
	}

	buscarRecorrencias = () => {
		let id = this.props.match.params.id;
		if (id) {
			return firebase
				.firestore()
				.collection("recorrencias")
				.doc(id)
				.get()
				.then(doc => {
					let { diasDaSemana, id, modelo, pdv } = doc.data();
					this.setState({
						dias: diasDaSemana,
						id,
						modelo: this.state.modelos.find(({ id }) => id === modelo.id),
						pdv: this.props.lojas?.find(({ id }) => id === pdv.id),
						buscandoRecorrencia: false,
					});
				});
		} else {
			return new Promise(res => {
				this.setState(
					{
						buscandoRecorrencia: false,
					},
					() => res()
				);
			});
		}
	};

	componentDidMount() {
		this.setState(
			{
				buscandoRecorrencia: true,
			},
			() => {
				this.buscarBloqueados();
				this.buscarModelos();
				if (this.props.lojas != null) {
					this.buscarRecorrencias();
				}
				this.props.lojas == null &&
					firebase
						.firestore()
						.collection("lojasHomologadas")
						.get()
						.then(d => {
							var i = d.docs;
							return i;
						})
						.then(p => {
							return downloadLojasDaEmpresa(this.props.idEmpresa).onSnapshot(snap => {
								var s = {
									docs: _.concat(snap.docs, p),
								};
								this.setState({ error: false });
								this.props.dispatch(lojasDaEmpresa(s));
							});
						})
						.then(() =>
							this.setState(
								{
									buscandoRecorrencia: true,
								},
								() =>
									this.buscarRecorrencias().then(() => {
										this.setState({ error: false });
									})
							)
						);
			}
		);
	}

	/**
	 * @name handleChangeRedirecionaModelo
	 * @date Criado em 24/03/2021
	 * @author Vitor Andre Savian
	 * @description Seta o redicionar
	 */
	handleChangeRedirecionaModelo = () => {
		this.setState({ redirecionaModelo: true });
	};

	buscarModelos = () => {
		const idEmpresa = this.props.idEmpresa;
		firebase
			.firestore()
			.collection("modelosRoteiro")
			.where("roteiro.idEmpresa", "==", idEmpresa)
			.onSnapshot(snap =>
				this.setState({
					modelos: _.orderBy(
						snap.docs.map(doc => doc.data()),
						["modelosRoteiro", "nome"],
						["asc"]
					),
				})
			);
	};

	buscarBloqueados = async () => {
		const idEmpresa = this.props.idEmpresa;

		let bloqueados = [];
		let bloqueadosUid = [];

		await firebase
			.firestore()
			.collection("empresas")
			.doc(idEmpresa)
			.collection("bloqueados")
			.get()
			.then(snap => {
				snap.docs.forEach(doc => bloqueados.push(doc.data()));
			});

		bloqueados.forEach(promotor => {
			bloqueadosUid.push(promotor.uid);
		});

		this.setState({ promotoresBloqueados: bloqueadosUid });
	};
	onSearchChange = (e, data) =>
		this.setState({
			searchInput: data,
		});

	onSubmit = e => {
		e.preventDefault();
		let { modelo, pdv, dias, empresa, ipLocation } = this.state;
		this.setState({ carregando: true }, () => {
			if (_.isEmpty(modelo) || _.isEmpty(pdv) || _.isEmpty(dias)) {
				return this.setState({ error: true, carregando: false });
			} else {
				this.setState({ error: false });
			}
			let id = this.props.match.params.id ?? v4();
			let idEmpresa = this.props.idEmpresa;
			let data = {
				empresa: this.props.empresa,
				modelo: {
					horaFim: modelo.roteiro.horaFim,
					horaInicio: modelo.roteiro.horaInicio,
					fantasiaEmpresa: modelo.roteiro.fantasiaEmpresa,
					id: modelo.id,
					nome: modelo.nome,
				},
				pdv: {
					fantasia: pdv.fantasia,
					endereco: pdv.endereco.endereco,
					idEmpresa: pdv.idEmpresa,
					id: pdv.id,
				},
				idEmpresa: idEmpresa,
				diasDaSemana: dias,
				status: "ativo",
				id,
			};

			firebase
				.firestore()
				.collection("recorrencias")
				.doc(id)
				.set(data)
				.then(() => {
					this.props.enqueueSnackbar(this.props.t("recorrencia.cadastrada.com.sucesso"), {
						variant: "success",
					});
					this.setState({ carregando: false, redirect: true });
				})
				.then(() => {
					cadastarRecorrencias(
						data,
						this.props.configuracao,
						this.state.promotoresBloqueados
					).then(() => {
						logging("CREATE", "log.criacao.proximas.recorrencias", id);
					});
					id === this.props.match.params.id
						? logging("UPDATE", "log.update.recorrencia", id)
						: logging("CREATE", "log.criacao.recorrencia", id);
				})
				.catch(() => {
					this.props.enqueueSnackbar(this.props.t("erro.ao.cadastrar.recorrencia"), {
						variant: "error",
					});
					this.setState({ error: true, carregando: false });
				});
		});
	};

	onChangeModelo = (e, { value }) => {
		this.setState({ modelo: value, error: false });
	};

	onChangePdv = (e, { value }) => {
		this.setState({ pdv: value, error: false });
	};

	onChangeDias = e => {
		this.setState({ dias: e, error: false });
	};

	render() {
		let { t, lojas } = this.props;
		let {
			modelos,
			dias,
			pdv,
			modelo,
			error,
			carregando,
			buscandoRecorrencia,
			redirect,
			redirecionaModelo,
		} = this.state;
		let diasOrdenados = ordenarDiasDaSemana(dias);
		if (redirect) {
			return <Redirect to="/oportunidades/4" />;
		}
		return (
			<>
				<Grid columns={16} centered>
					<Grid.Column width={13}>
						<HeaderPadrao
							icone="map signs"
							titulo={t("Cadastro.de.recorrência")}
							subtitulo={t("Oportunidades.cadastradas.automaticamente.toda.semana")}
						/>
					</Grid.Column>
				</Grid>
				<Grid columns={16} stackable divided centered>
					{!buscandoRecorrencia ? (
						<>
							<Grid.Column width={8}>
								<Formulario
									t={t}
									onSearchChange={this.onSearchChange}
									onChangeModelo={this.onChangeModelo}
									onChangePdv={this.onChangePdv}
									onChangeDias={this.onChangeDias}
									onSubmit={this.onSubmit}
									lojas={lojas}
									modelos={modelos}
									modelo={modelo}
									pdv={pdv}
									dias={dias}
									error={error}
									carregando={carregando}
								/>
							</Grid.Column>
							<Grid.Column width={5}>
								<Info
									handleChangeRedirecionaModelo={
										this.handleChangeRedirecionaModelo
									}
									redirecionaModelo={redirecionaModelo}
									pdv={pdv}
									modelo={modelo}
									dias={diasOrdenados}
									t={t}
								/>
							</Grid.Column>
						</>
					) : (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<div
								className="ui inverted text loader relancar-roteiro-loader"
								style={{ display: "block" }}>
								<p style={{ color: "#ff6f00" }}>{t("Carregando.recorrencia")}</p>
							</div>
						</div>
					)}
				</Grid>
			</>
		);
	}
}

export default RoteiroConnect(withSnackbar(withTranslation()(CadastroRecorrencia)));
