import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { v4 } from "uuid";
import { HeaderPadrao } from "../../Componentes/Componentes";
import Link from "../../Componentes/Link";
import MensagemSalvo from "../../Componentes/MensagemSalvo";
import { ModelProduto } from "../../Model/model";
import * as d from "../../Utils/Debugging";
import { downloadMarcasDaEmpresa } from "../../Utils/Oportunidades";
import { registrarProduto } from "../../Utils/Produto";
import { marcasDaEmpresa } from "../../_actions/RecursoActions";
import NovoProdutoForm from "./NovoProdutoForm";

export class NovoProduto extends Component {
	state = {
		input: "",
		produto: new ModelProduto(),
		fotoFile: null,
		planogramaFile: null,
		salvo: false,
		carregando: false,
		ajuda: false,
	};

	componentDidUpdate(prevProps) {
		this.props.idEmpresa &&
			this.props.produtos == null &&
			downloadMarcasDaEmpresa(this.props.idEmpresa).onSnapshot(snap =>
				this.props.dispatch(marcasDaEmpresa(snap))
			);
	}

	onSubmit = event => {
		const { idEmpresa } = this.props;
		const produto = { ...this.state.produto, id: v4(), idEmpresa };
		// const produtos = _.isArray(this.props.produtos) ? this.props.produtos : [];
		d.log("Nova Marca", "adicionando", produto);
		this.setState({ carregando: true, erroCodigo: null }, () =>
			new Promise((res, rej) => res())
				.then(() =>
					registrarProduto(
						{
							produto,
							foto: this.state.fotoFile,
							planograma: this.state.planogramaFile,
						},
						"set"
					)
				)
				.then(() => {
					this.setState({
						produto: new ModelProduto(),
						foto: null,
						fotoFile: null,
						planogramaFile: null,
						planograma: null,
						salvo: true,
						carregando: false,
					});
				})
		);
	};

	novoRegistro = () => this.setState({ salvo: false });

	render() {
		const { carregando, salvo, produto, erroCodigo } = this.state;
		const { t } = this.props;

		return (
			<div style={{ margin: " 0px auto", width: "100%" }}>
				<div
					className="container-header-padrao padding-padrao-segment"
					style={{ display: "flex", justifyContent: "space-between", marginBottom: 30 }}>
					<div className="buttons-in-column">
						<Button
							to="/produto/importar"
							as={Link}
							content={"Importar"}
							icon="plus"
							style={{ marginBottom: 10 }}
							color="violet"
							floated="right"
						/>
						<Button
							onClick={() => this.setState({ ajuda: !this.state.ajuda })}
							content={t("ajuda")}
							icon={"help"}
							floated="right"
							basic
						/>
					</div>
					<div className="container-header-desc">
						<HeaderPadrao
							icone="boxes"
							titulo={t("novo.produto")}
							subtitulo={t("cadastre.novo.produto")}
						/>
					</div>
				</div>

				{salvo ? (
					<MensagemSalvo t={t} novoRegistro={this.novoRegistro} />
				) : (
					<NovoProdutoForm
						scope={this}
						t={t}
						erroCodigo={erroCodigo}
						carregando={carregando}
						editando={false}
						produto={produto}
						onSubmit={this.onSubmit.bind(this)}>
						<div
							style={{
								display: "table",
								width: "100%",
								marginTop: "1em",
								marginRight: 10,
							}}>
							<Button
								className="green-gradient-light"
								content={t("salvar")}
								style={{ marginRight: 12 }}
								disabled={carregando}
								icon="check"
								color="green"
								floated="right"></Button>
							<Button
								as={Link}
								to="/produto"
								content={t("cancelar")}
								icon="left arrow"
								color="red"
								floated="right"
								disabled={carregando}></Button>
						</div>
					</NovoProdutoForm>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	const marcas = state.RecursosReducer.marcas;
	return {
		idEmpresa: state.AutenticacaoReducer.empresa.uid,
		empresa: state.AutenticacaoReducer.empresa,
		produtos: _.isArray(marcas) ? marcas : [],
	};
};

export default connect(mapStateToProps)(withTranslation()(NovoProduto));
