import React, { Component } from 'react';
import firebase from 'firebase'
import { Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const item = (props) => {
    var { fantasia, endereco, codigo, id } = props
    return <Table.Row>
        <Table.Cell><Link to={`/loja/${id}`}>{fantasia}</Link></Table.Cell>
        <Table.Cell>{endereco}</Table.Cell>
        <Table.Cell>{codigo}</Table.Cell>
    </Table.Row>
}

const Input = (props) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label for={props.id} >{props.placeholder}</label>
        <input
            {...props}
            type="text"
            style={{
                height: 30,
                border: '1px solid #E0E0E0',
                borderRadius: 5,
                paddingLeft: 10,
                outline: 'none'
            }}
        />
    </div>
)

const search = {
    fantasia: (props, busca) => props.fantasia.toLowerCase().includes(busca.toLowerCase()) && item(props),
    codigo: (props, busca) => props.codigo.toLowerCase().includes(busca.toLowerCase()) && item(props),
    endereco: (props, busca) => props.endereco.toLowerCase().includes(busca.toLowerCase()) && item(props)
}

export default class Lojas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lojas: [],
            filtro: {
                ativo: false,
                campo: '',
                busca: ''
            }
        };
    }

    componentDidMount() {
        var { id } = this.props.mercado
        firebase.firestore()
            .collection('lojasHomologadas')
            .where(`whiteList.${id}.ativo`, "==", true)
            .get().then((s) => s.docs.map((d) => d.data()))
            .then((v) => this.setState({ lojas: v }))
    }

    setFilter = (e, campo) => {
        if (e.target.value) {
            this.setState({ filtro: { ativo: true, campo, busca: e.target.value } })
        } else {
            this.setState({ filtro: { ativo: false, campo: '', busca: "" } })
        }
    }

    render() {
        var { t } = this.props
        var lojas = this.state.lojas
        var { ativo, campo, busca } = this.state.filtro
        return (
            <div>
                <h1>Lojas</h1>
                <Table celled color={'purple'}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Input
                                    name="fantasia"
                                    id="fantasia"
                                    placeholder={t('nome.fantasia')}
                                    onChange={(e) => this.setFilter(e, "fantasia")} />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Input
                                    name="endereco"
                                    id="endereco"
                                    placeholder={t('perfil.view.endereco')}
                                    onChange={(e) => this.setFilter(e, "endereco")} />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Input
                                    name="codigo"
                                    id="codigo"
                                    placeholder={t('codigo')}
                                    onChange={(e) => this.setFilter(e, "codigo")} />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {lojas.map(({ fantasia, id, codigo, endereco: { endereco } }) => {
                            var props = { fantasia, codigo, endereco, id }
                            if (ativo === true) {
                                return search[campo](props, busca)
                            } else {
                                return item(props)
                            }
                        })}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}
