import { Icon } from "@iconify/react";

export const DataTableVazia = (props) => {
    return (
        <div>
            <input id="arquivo" name="arquivo" type="file" onChange={props.onSelectFile} style={{ display: "none" }} />
            <section onClick={props.selectFile} className="containerTabelaVazia">
                <Icon icon="mdi:shopping" width={150} height={150} />
                <p>{props.t("selecionar.csv.com.produtos")}</p>
            </section>
        </div>
    );
};
