import _ from "lodash";
import moment from "moment";
import {
    ROTEIROS,
    DESAUTENTICA_USUARIO,
    ROTEIRO_REEMBOLSADO,
    ROTEIROS_AGENCIAS,
    ROTEIRO_AVALIADO,
    TREINAMENTOS,
} from "../_actions/Types";

const INITIAL_STATE = {
    novasOportunidades: null,
    oportunidadesPendentes: null,
    oportunidadesPendentesPaginado: null,
    oportunidadesExecutadas: [],
    oportunidadesFinalizadasNaSemana: [],
    oportunidadesExecutadas_Finalizadas: null,
    oportunidadesExecutadasPorPeriodo: null,
    oportunidadesIncompletas: null,
    oportunidadesAgencia: null,
    opAgPendentes: null,
    opAgPendentesAguardandoPromotor: null,
    opAgExecutadas: null,
    bloquearCadastro: false,
    treinamentos: null,
};
const estadoQueNaoAcumula = [
    "novasOportunidades",
    "oportunidadesIncompletas",
    "opAgPendentes",
    "opAgPendentesAguardandoPromotor",
    "oportunidadesExecutadas",
    "oportunidadesFinalizadasNaSemana",
    "oportunidadesExecutadas_Finalizadas",
    "oportunidadesExecutadasPorPeriodo",
    "opAgExecutadas",
    "oportunidadesPendentes",
    "oportunidadesPendentesPaginado",
];

const dataAnterior = moment().subtract(3, "months").format("YYYY-MM-DD");

const reducers = {
    [ROTEIROS_AGENCIAS]: (state, { kind, payload }) => {
        return { ...state, oportunidadesAgencia: payload };
    },
    [ROTEIROS]: (state, { kind, payload }) => {
        let roteiros = payload;
        if (kind === "oportunidadesExecutadas") {
            const roteirosBloqueio = _.filter(roteiros, (roteiro) => moment(roteiro.data).isAfter(dataAnterior));
            return { ...state, oportunidadesExecutadas: roteiros, bloquearCadastro: roteirosBloqueio.length >= 10 };
        }
        if (kind === "oportunidadesPendentesEmExecucaoComPendencia") {
            state.oportunidadesPendentes = [];
            roteiros.forEach((roteiro) => {
                if (roteiro.statusRoteiro.id === 2) {
                    state.oportunidadesPendentes.push(roteiro);
                }
            });
            return { ...state };
        }
        if (!estadoQueNaoAcumula.includes(kind)) {
            roteiros = payload != null ? (state[kind] || []).concat(payload) : null;
        }
        return { ...state, [kind]: roteiros };
    },
    [DESAUTENTICA_USUARIO]: (state, action) => ({
        novasOportunidades: null,
        oportunidadesPendentes: null,
        oportunidadesPendentesPaginado: null,
        oportunidadesExecutadas: [],
        oportunidadesFinalizadasNaSemana: [],
        oportunidadesExecutadas_Finalizadas: null,
        oportunidadesExecutadasPorPeriodo: null,
        oportunidadesIncompletas: null,
        oportunidadesAgencia: null,
        opAgPendentes: null,
        opAgPendentesAguardandoPromotor: null,
        opAgExecutadas: null,
        bloquearCadastro: false,
        treinamentos: null,
    }),
    [ROTEIRO_REEMBOLSADO]: (state, action) => ({
        ...state,
        oportunidadesIncompletas: _.reduce(
            state.oportunidadesIncompletas,
            (acc, _roteiros, data) => {
                const roteiro = _roteiros.find((roteiro) => roteiro.id === action.id);
                roteiro.pagamento.refunded = true;
                acc[data] = _roteiros;
                return acc;
            },
            {}
        ),
    }),
    [TREINAMENTOS]: (state, action) => {
        return {
            ...state,
            treinamentos: action.treinamentos,
        };
    },
    [ROTEIRO_AVALIADO]: (state, action) => {
        const roteiros = state.oportunidadesExecutadas.filter((roteiro) => roteiro.id !== action.idRoteiro);
        const roteirosBloqueio = _.filter(roteiros, (roteiro) => moment(roteiro.data).isAfter(dataAnterior));
        return {
            ...state,
            oportunidadesExecutadas: roteiros,
            bloquearCadastro: roteirosBloqueio.length >= 10,
        };
    },
};

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type];
    if (reducer) return reducer(state, action);
    return state;
};
