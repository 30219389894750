import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 350px;

    justify-content: center;

    @media (max-width: 1020px) {
        align-items: center;
    }
`;

export const Title = styled.h1`
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    margin-bottom: 0.1rem;
`;

export const Description = styled.span`
    font-size: 0.9rem;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.6);
`;
