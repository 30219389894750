import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    margin-top: 1rem;
`;

export const TextArea = styled.textarea<{ error?: boolean }>`
    padding: 1rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    height: 200px;

    ${({ error }) =>
        error &&
        css`
            border-color: #e0b4b4;
            background-color: #fff6f6;
            color: #9f3a38;

            ::placeholder {
                color: #9f3a38;
            }
        `}

    ::placeholder {
        opacity: 0.4;
    }

    :focus {
        outline: none;
        ${({ error }) =>
            !error &&
            css`
                border: 1px solid #85b7d9;
            `}
        ::placeholder {
            opacity: 0.7;
        }
    }
`;

export const FormLabel = styled.label<{ error?: boolean }>`
    margin: 0;
    font-weight: 500;
    font-size: 1.1rem;

    span {
        color: #9f3a38;
    }

    ${({ error }) =>
        error &&
        css`
            color: #9f3a38;
        `}
`;

export const FooterError = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const FormErrorMessage = styled.span`
    flex: 1;
    color: #9f3a38;
`;

export const FormLimit = styled.span<{ error?: boolean }>`
    ${({ error }) =>
        error &&
        css`
            color: #9f3a38;
        `}
`;
