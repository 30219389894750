import { Box, BoxProps } from "@chakra-ui/react";

type CardProps = BoxProps;

/**
 * @description Renderiza um componente `Box` dp ChakraUI que representa um wrapper de `Card`.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function Card(props: CardProps) {
	return <Box bg="white" p={4} rounded="md" shadow="base" {...props}></Box>;
}
