import { Icon as IconSemantic } from "semantic-ui-react";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Icon = styled(IconSemantic).attrs({ size: "big" })`
    margin: 0 !important ;
`;
