import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    margin-top: 1rem;
`;

export const FormLabel = styled.label<{ error?: boolean }>`
    margin: 0;
    font-weight: 500;
    font-size: 1.1rem;

    span {
        color: #9f3a38;
    }

    ${({ error }) =>
        error &&
        css`
            color: #9f3a38;
        `}
`;

export const FormErrorMessage = styled.span`
    color: #9f3a38;
`;
