/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Funções para gerar cores aleatórias.
 * @returns {String} Hexadecimal
 */
export function generateRandomHexColor(): string {
	// Gera um componente de cor (R, G ou B) como um valor hexadecimal
	const generateHexComponent = () =>
		Math.floor(Math.random() * 256)
			.toString(16)
			.padStart(2, "0");

	// Gera os componentes vermelho, verde e azul
	const red = generateHexComponent();
	const green = generateHexComponent();
	const blue = generateHexComponent();

	// Concatena os componentes para formar a cor hexadecimal
	return `#${red}${green}${blue}`;
}

/**
 * @description Função que determina o nível de luminosidade de uma cor.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @param hex Uma string no formato hexadecimal.
 */
function calculateLuminance(hex: string): number {
	const rgb = [
		parseInt(hex.substr(1, 2), 16),
		parseInt(hex.substr(3, 2), 16),
		parseInt(hex.substr(5, 2), 16),
	];
	const a = rgb.map(function (v) {
		v /= 255;
		return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
	});
	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

/**
 * @description Função que calcula o nível de contraste entre duas cores.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @param hex1 Uma string no formato hexadecimal.
 * @param hex2 Uma string no formato hexadecimal.
 */
function calculateColorContrast(hex1: string, hex2: string): number {
	const luminance1 = calculateLuminance(hex1) + 0.05;
	const luminance2 = calculateLuminance(hex2) + 0.05;
	return luminance1 > luminance2 ? luminance1 / luminance2 : luminance2 / luminance1;
}

/**
 * @description Função que corrige o contraste entre uma cor e um fundo.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @param backgroundHex Uma string no formato hexadecimal.
 */
export function enhancedContrastColor(backgroundHex: string): string {
	// Corrige a verificação para cores extremamente claras ou escuras
	if (backgroundHex.toUpperCase() === "#000000") {
		return "#FFFFFF"; // Branco para preto puro
	} else if (backgroundHex.toUpperCase() === "#FFFFFF") {
		return "#000000"; // Preto para branco puro
	}

	const contrastColors = ["#000000", "#FFFFFF", "#555555", "#AAAAAA"]; // Preto, Branco, Cinza escuro, Cinza claro
	let highestContrast = 0;
	let selectedColor = "#FFFFFF"; // Define branco como cor padrão de contraste

	for (const color of contrastColors) {
		const contrastRatio = calculateColorContrast(backgroundHex, color);
		if (contrastRatio > highestContrast) {
			highestContrast = contrastRatio;
			selectedColor = color;
		}
	}

	return selectedColor;
}
