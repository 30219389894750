import _ from "lodash";
import moment from "moment";
import { withSnackbar } from "notistack";
import { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Icon, Loader, Pagination } from "semantic-ui-react";
import Confirmar from "../../Componentes/Confirmar";
import { queriePorData } from "../../Utils/mongo/queriesMongo";
import { alterarOportunidadesDashboard } from "../../_actions/DashboardActions";
import { mongoDBAtlas } from "../../services/Api";
import { InfoSaldoDashboard, Legenda, SemConexao, SemDados } from "./DashboardComponents";
import { DiaSemana } from "./DiasSemana";
import ModalTermos from "./ModalTermos";
import { OportunidadeInfo } from "./OportunidadeInfo";
import { RightBoxContent } from "./RightBox";

class Dashboard extends Component {
	state = {
		oportunidadesPorDia: {},
		atual: moment(),
		oportunidade: null,
		posRightBox: 0,
		cookies: null,
		validacaoFatura: null,
		carregando: true,
		activePage: 1,
		erroConexaoServidor: false,
	};

	/**
	 * @name buscaOportunidades
	 * @date Criado em 08/01/2021
	 * @author Vitor Andre Savian
	 * @description busca as oportunidades dentro do mongo
	 * @param {String} tresAntes data de 3 dias antes
	 * @param {String} tresDepois data de 3 dias depois
	 * @param {Object} empresa objeto da empresa
	 * @param {Function} querie querie do mongodb
	 */
	buscaOportunidades = async (tresAntes, tresDepois, empresa, querie = queriePorData) => {
		try {
			const res = await mongoDBAtlas("POST", "roteiros", "/action/aggregate", {
				pipeline: [
					...querie({
						uid: empresa.uid,
						dataInicio: tresAntes,
						dataFinal: tresDepois,
					}),
				],
			});
			return res?.documents;
		} catch (erro) {
			console.log(erro);
			this.setState({ carregando: false, erroConexaoServidor: true });
		}
	};

	/**
	 * Busca oportunidades 3 dias apos e antes ao dia atual.
	 * Separa as oportunidades por dia agrupando em um array adicionado com key sendo o dia em questão à oportunidadesPorDia.
	 * Ao final seta a data de hoje, tresAntes, tresDepois, as oportunidades agrupadas, qual dia está sendo visto e qual oportunidade está sendo detalhada na rightbox.
	 */
	componentDidMount() {
		const { empresa } = this.props;
		let hoje = moment();
		let tresAntes = moment().subtract(3, "days").format("YYYY-MM-DD");
		let tresDepois = moment().add(3, "days").format("YYYY-MM-DD");
		let oportunidadesPorDia = this.props.oportunidadesPorDia;

		this.getStorage("cookies");
		this.getStorage("validacaoFatura");
		if (_.isEmpty(oportunidadesPorDia)) {
			this.buscaOportunidades(tresAntes, tresDepois, empresa).then(oportunidades => {
				if (oportunidades?.error) {
					this.setState({ carregando: false });
					return false;
				}
				oportunidades &&
					oportunidades.forEach(oportunidade => {
						var dia = moment(oportunidade.data).format("DD/MM/YY");
						if (oportunidadesPorDia[dia]) {
							oportunidadesPorDia[dia].push(oportunidade);
						} else {
							oportunidadesPorDia[dia] = [oportunidade];
						}
					});
				var oportunidade = oportunidadesPorDia[hoje.format("DD/MM/YY")]
					? oportunidadesPorDia[hoje.format("DD/MM/YY")][0]
					: null;
				this.props.alterarOportunidadesDashboard(oportunidadesPorDia);
				this.setState({
					oportunidadesPorDia,
					hoje,
					tresAntes,
					tresDepois,
					atual: hoje,
					oportunidade,
					carregando: false,
				});
			});
		} else {
			var oportunidade = oportunidadesPorDia[hoje.format("DD/MM/YY")]
				? oportunidadesPorDia[hoje.format("DD/MM/YY")][0]
				: null;
			this.setState({
				oportunidadesPorDia,
				hoje,
				tresAntes,
				tresDepois,
				atual: hoje,
				oportunidade,
				carregando: false,
			});
		}
	}

	/**
	 * Verifica se a flag {nome} está salva no localStorage
	 */
	getStorage = nome => {
		let valor = localStorage.getItem(nome);
		this.setState({ [nome]: valor });
	};

	/**
	 * Grava a flag {nome} com o {valor} no localStorage
	 */
	setStorage = (nome, valor) => {
		localStorage.setItem(nome, valor);
		this.setState({ [nome]: valor });
	};

	/**
	 * Valida a fatura em aberto e direciona para a fatura
	 */
	faturaEmAberto = () => {
		this.setStorage("validacaoFatura", moment().format("YYYY-MM-DD"));
		window.open(`/fatura/${this.props.fatura?.referencia}`);
	};

	/**
	 * Define os dias da semana que serão exibidos.
	 */
	diasSemana = () => {
		const { tresAntes, tresDepois } = this.state;
		let inicio = moment(tresAntes);
		let dias = [];
		while (inicio.isSameOrBefore(moment(tresDepois))) {
			dias.push(inicio);
			inicio = moment(inicio).add(1, "days");
		}
		return dias;
	};

	/**
	 * Alterna o dia que será detalhado
	 * @param {novo dia a ser exibido} day
	 */
	changeDay = day => () =>
		this.setState({
			atual: day,
			oportunidade: this.state.oportunidadesPorDia[day.format("DD/MM/YY")]
				? this.state.oportunidadesPorDia[day.format("DD/MM/YY")][0]
				: null,
			posRightBox: 0,
			activePage: 1,
		});

	/**
	 * No rightbox move para a oportunidade anterior do dia, se possivel.
	 * @param {Object} oportunidades as oportunidades que vem no paginator
	 */
	previousOportunidade = oportunidades => {
		var prev = this.state.posRightBox - 1;
		// var oportunidades = this.state.oportunidadesPorDia[this.state.atual.format('DD/MM/YY')]
		if (prev >= 0) {
			this.setState({ oportunidade: null });
			this.setState({ posRightBox: prev, oportunidade: oportunidades[prev] });
		}
	};

	/**
	 * No rightbox, move para a proxima oportunidade do dia, se possivel.
	 * @param {Object} oportunidades as oportunidades que vem no paginator
	 */
	nextOportunidade = oportunidades => {
		var next = this.state.posRightBox + 1;
		// var oportunidades = this.state.oportunidadesPorDia[this.state.atual.format('DD/MM/YY')]
		if (next < oportunidades.length) {
			this.setState({ posRightBox: next, oportunidade: oportunidades[next] });
		}
	};

	render() {
		const { t, empresa, saldos, faturamentoMensal, fatura, carregandoLimite } = this.props;
		const {
			oportunidadesPorDia,
			atual,
			oportunidade,
			posRightBox,
			cookies,
			validacaoFatura,
			activePage,
		} = this.state;
		const oportunidades = oportunidadesPorDia[atual?.format("DD/MM/YY")] ?? [];
		const paginationOportunidades = oportunidades.slice((activePage - 1) * 5).slice(0, 5);
		const maximo = empresa?.valorMaximoOportunidades ?? 0;
		const restante = isNaN(maximo - faturamentoMensal?.gastos)
			? 0
			: maximo - faturamentoMensal?.gastos;
		return (
			<div className="dashboard">
				<Helmet>
					<script>
						{`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '739732460511854');
            fbq('track', 'PageView');`}
					</script>
					<noscript>
						{`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=739732460511854&ev=PageView&noscript=1"
            />`}
					</noscript>
				</Helmet>
				<div className="dashboard-box">
					<Confirmar
						callback={() => this.setStorage("cookies", true)}
						open={cookies === null}
						confirmar={t("prosseguir")}
						mensagem={t("detalhes.sobre.cookies")}
						titulo={t("sobre.cookies")}
					/>
					{!_.isEmpty(fatura) && (
						<Confirmar
							callback={this.faturaEmAberto}
							open={
								fatura?.pago === false &&
								validacaoFatura !== moment().format("YYYY-MM-DD") &&
								moment().isAfter(moment(fatura?.data?.toDate())) &&
								moment().isBefore(moment(fatura?.boleto?.due_date).add(1, "day"))
							}
							onClose={() =>
								this.setStorage("validacaoFatura", moment().format("YYYY-MM-DD"))
							}
							cancelar={t("fechar")}
							confirmar={t("ver.fatura")}
							mensagem={
								t("fatura.referencia", {
									fatura1: fatura?.referencia?.substring(0, 2),
									fatura2:
										fatura?.referencia?.substring(2, 6) +
											" " +
											fatura?.referencia?.substring(7, 9) ?? "",
									vencimento: moment(fatura?.boleto?.due_date).format(
										"DD/MM/YYYY"
									),
								}) + t("caso.tenha.pago.fatura")
							}
							titulo={t("fatura.em.aberto")}
						/>
					)}
					<ModalTermos {...this.props} />
					<div>
						<div className="dash-div-title">
							<span className="dash-title">{t("resumo")}</span>
							{empresa.faturamentoMensal ? (
								<div className="dash-saldos">
									<InfoSaldoDashboard
										titulo={t("limite.disponivel")}
										valor={restante ?? 0}
										carregandoLimite={carregandoLimite}
										t={t}
									/>
									<InfoSaldoDashboard titulo={t("limite.total")} valor={maximo} />
								</div>
							) : (
								<div className="dash-saldos">
									<InfoSaldoDashboard
										titulo={t("saldo")}
										valor={saldos?.total ?? 0}
									/>
									<InfoSaldoDashboard
										titulo={t("reservado")}
										valor={saldos?.reservado + saldos?.reservadoNovo ?? 0}
									/>
									<InfoSaldoDashboard
										titulo={t("nao.reservado")}
										valor={saldos?.naoReservado ?? 0}
									/>
								</div>
							)}
						</div>
						{this.state.carregando ? (
							<div className="dashboard.loader">
								<Loader active>{t("carregando.oportunidades")}...</Loader>
							</div>
						) : (
							<>
								<Legenda />
								<div className="dash-dias-semana">
									{this.diasSemana().map(dia => (
										<DiaSemana
											key={dia}
											changeDay={this.changeDay}
											atual={atual}
											dia={dia}
											oportunidades={
												oportunidadesPorDia[dia.format("DD/MM/YY")] ?? []
											}
										/>
									))}
								</div>
								<span className="dash-title">{t("detalhes.do.dia")}</span>
								<div className="dash-detalhes-dia">
									<div className="dia-oportunidades">
										<div className="detalhes-dia">
											{!_.isEmpty(oportunidades) ? (
												<>
													{paginationOportunidades.map(
														(oportunidade, index) => (
															<OportunidadeInfo
																oportunidade={oportunidade}
																index={index}
																key={index}
																pos={posRightBox}
															/>
														)
													)}
													<div
														style={{
															marginTop: "20px",
															display: "flex",
															justifyContent: "center",
															alignContent: "center",
														}}>
														<Pagination
															boundaryRange={3}
															shape="rounded"
															prevItem={{
																content: <Icon name="angle left" />,
																icon: true,
															}}
															nextItem={{
																content: (
																	<Icon name="angle right" />
																),
																icon: true,
															}}
															activePage={activePage}
															firstItem={null}
															lastItem={null}
															onPageChange={(e, data) =>
																this.setState({
																	activePage: data.activePage,
																	oportunidade: oportunidades
																		.slice(
																			(data.activePage - 1) *
																				5
																		)
																		.slice(0, 5)[0],
																	posRightBox: 0,
																})
															}
															totalPages={Math.ceil(
																oportunidades.length / 5
															)}
														/>
													</div>
												</>
											) : this.state.erroConexaoServidor ? (
												<SemConexao />
											) : (
												<SemDados />
											)}
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
				{this.state.carregando === false && (
					<div className="dashboard-box-right">
						{oportunidade ? (
							<>
								<div className="header-box-right">
									<div
										className="box-icon"
										onClick={() =>
											this.previousOportunidade(paginationOportunidades)
										}>
										<Icon name="chevron left" />
									</div>
									<span className="dash-title">
										{oportunidade?.loja.fantasia}
									</span>
									<div
										className="box-icon"
										onClick={() =>
											this.nextOportunidade(paginationOportunidades)
										}>
										<Icon name="chevron right" />
									</div>
								</div>
								<RightBoxContent oportunidade={oportunidade} />
							</>
						) : (
							<SemDados />
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	empresa: state.AutenticacaoReducer.empresa,
	ipLocation: state.AutenticacaoReducer.ipLocation,
	saldos: state.AutenticacaoReducer.saldos,
	fatura: state.AutenticacaoReducer.fatura,
	carregandoLimite: state.AutenticacaoReducer.carregandoLimite,
	oportunidadesPorDia: state.DashboardReducer.oportunidadesPorDia,
	faturamentoMensal: state.AutenticacaoReducer.faturamentoMensal,
});

export default connect(mapStateToProps, {
	alterarOportunidadesDashboard,
})(withSnackbar(withTranslation()(Dashboard)));

export const buscaOportunidades = new Dashboard().buscaOportunidades;
