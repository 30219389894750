import moment from "moment";
import React, { Component } from "react";
import { Button, Form, Input, Segment } from "semantic-ui-react";

export const defaultFilterEvent = () => ({
	filterData: { tipo: null, dataInicio: "", dataFinal: "" },
});

const DateInput = props => (
	<Input
		name={props.name}
		type="date"
		iconPosition="left"
		icon="calendar"
		onChange={props.onChange}
		value={props.value}
	/>
);

export const botalFiltroLabel = (t, state) =>
	!state ? t("botao.filtro.abrir") : t("botao.filtro.fechar");

export const FilterButton = props => (
	<Button
		style={{ marginTop: "10px" }}
		content={botalFiltroLabel(props.t, props.aberto)}
		icon="filter"
		color="violet"
		floated="right"
		basic
		onClick={props.onClick}></Button>
);

export default class FiltroOportunidade extends Component {
	state = { tipo: null, dataInicio: "", dataFinal: "" };

	handleChangeSelectTipo = (event, { value }) => this.setState({ tipo: value });

	handleChangeFilterDate = field => event =>
		this.setState({
			[field]: !!event.target.value ? moment(event.target.value).format("YYYY-MM-DD") : "",
		});

	aplicarFiltro = event => this.props.aplicarFiltro({ ...event, filterData: { ...this.state } });

	render() {
		const { tipo, dataInicio, dataFinal } = this.state;
		const { t, types, aberto } = this.props;
		return !aberto ? null : (
			<Segment basic className="purple-gradient-45">
				<Form className="filtrar-roteiros">
					<Form.Group style={{ margin: "0 !important" }}>
						<Form.Input width="4">
							<DateInput
								name="start"
								fluid
								onChange={this.handleChangeFilterDate("dataInicio")}
								value={dataInicio}
							/>
						</Form.Input>
						<Form.Input width="4">
							<DateInput
								name="end"
								fluid
								onChange={this.handleChangeFilterDate("dataFinal")}
								value={dataFinal}
							/>
						</Form.Input>
						<Form.Dropdown
							name="tipo"
							value={tipo}
							fluid
							width="6"
							onChange={this.handleChangeSelectTipo}
							placeholder={t("placeholder.atividade.principal")}
							search
							selection
							options={types.concat({ key: 0, value: null, label: "Limpar" })}
						/>
						<Form.Button
							width="2"
							icon="filter"
							fluid
							content={t("filtrar")}
							style={{ display: "flex" }}
							onClick={event => this.aplicarFiltro(event)}></Form.Button>
					</Form.Group>
				</Form>
			</Segment>
		);
	}
}
