import React, { Component } from "react";
import { toast } from "react-toastify";
import {
	Divider,
	Form,
	Grid,
	Header,
	Icon,
	Input,
	Label,
	Message,
	Popup,
	Segment,
} from "semantic-ui-react";
import { Avatar } from "../../Componentes/Avatar";
import { FileCompressError } from "../../errors/File.erros";
import { compressImage } from "../../Utils/File.utils";

const defaultProductImage = "https://react.semantic-ui.com/images/wireframe/square-image.png";

export default class NovoProdutoForm extends Component {
	updateState = state => this.props.scope.setState(state);
	scopeState = () => this.props.scope.state;

	handleChangeProduto = event =>
		this.updateState({
			produto: {
				...this.scopeState().produto,
				[event.target.name]: event.target.value,
			},
		});

	clicarArquivo = () => document.getElementById("arquivo-input").click();

	readURL = event => {
		const file = event.target.files[0];

		if (file) {
			compressImage(file)
				.then(result => {
					this.updateState({
						fotoFile: result,
						produto: {
							...this.scopeState().produto,
							foto: URL.createObjectURL(result),
						},
					});
				})
				.catch(e => {
					if (e instanceof FileCompressError) {
						console.log(e);
						toast.warning(e.message);
					}
				})
				.finally(() => {
					event.target.value = "";
				});
		}
	};

	handlePlanograma = event => {
		const file = event.target.files[0];

		if (file) {
			compressImage(file)
				.then(result => {
					this.updateState({
						planogramaFile: result,
						produto: {
							...this.scopeState().produto,
							planograma: URL.createObjectURL(result),
						},
					});
				})
				.catch(e => {
					if (e instanceof FileCompressError) {
						console.log(e);
						toast.warning(e.message);
					}
				})
				.finally(() => {
					event.target.value = "";
				});
		}
	};

	fileInputRef = ref => (this.fileInput = ref);

	finalizarEdicao = () => this.props.history.push("/produto");

	discartarPlanograma = () =>
		this.updateState({
			produto: { ...this.scopeState().produto, planograma: null },
			planogramaFile: null,
		});

	onSubmit = event => {
		event.preventDefault();
		this.props.onSubmit(event);
	};

	render() {
		const { t, carregando, editando } = this.props;
		const { produto, planogramaFile } = this.scopeState();

		const { descricao, preco, foto, codigoBarras, planograma } = {
			...this.props.produto,
			...produto,
		};

		const passosTutorial = [
			t("digite.descricao.produto"),
			t("foto.cod.barras.opcional"),
			t("preco.sugerido.opcional.usado.pesq.preco"),
		];

		return (
			<Form className="form-produto" onSubmit={event => this.onSubmit(event)}>
				<Grid stackable>
					{this.scopeState().ajuda && (
						<Grid.Column width={16}>
							<Message
								icon="info"
								header={t("voce.sabe.como.cadastra.marca")}
								list={passosTutorial}
							/>
						</Grid.Column>
					)}

					<Grid.Column width={16}>
						<Segment color="violet">
							<Header
								as="h3"
								icon="photo"
								content={t("imagem")}
								subheader={t("imagem.produto")}
							/>
							<Divider />
							<Form.Field style={{ textAlign: "center" }}>
								<div
									onClick={event => this.clicarArquivo(event)}
									className="produto-foto-adicionada"
									style={{
										margin: "auto",
										width: "150px",
										height: "150px",
										pointerEvents: carregando ? "none" : "auto",
									}}>
									<Avatar autoSize src={foto || defaultProductImage} />
								</div>

								<input
									type="file"
									id="arquivo-input"
									onChange={event => this.readURL(event)}
								/>
								{!foto && (
									<Label color="blue" pointing style={{ margin: "auto" }}>
										Clique para adicionar uma foto
									</Label>
								)}
							</Form.Field>
						</Segment>

						<Segment color="violet">
							<Header
								as="h3"
								icon="box"
								content={t("informacoes")}
								subheader={t("informacoes.produto")}
							/>
							<Divider />
							<Form.Group>
								<Popup
									on="click"
									trigger={
										<Form.Input
											maxLength={70}
											disabled={carregando}
											width={16}
											onChange={event => this.handleChangeProduto(event)}
											name="descricao"
											value={descricao || ""}
											fluid
											label={t("descricao")}
											required
											placeholder={t("exemplo.produto.novo")}
										/>
									}>
									<Popup.Content>{t("produto.novo.descricao")}</Popup.Content>
								</Popup>
							</Form.Group>
							<Form.Group>
								<Form.Input
									maxLength={30}
									disabled={carregando}
									width={8}
									icon="barcode"
									iconPosition="left"
									onChange={event => this.handleChangeProduto(event)}
									name="codigoBarras"
									value={codigoBarras}
									fluid
									label={t("codigo.barras")}
									placeholder="0000+0000"
								/>
								<Form.Field disabled={carregando} width={8}>
									<label>{t("preco.sugerido")}</label>
									<Input
										max={1000000000}
										min={0.01}
										step="0.01"
										onChange={event => this.handleChangeProduto(event)}
										name="preco"
										value={preco}
										type="number"
										fluid
										label="R$"
										placeholder="50.00"
									/>
								</Form.Field>
							</Form.Group>
							<Form.Group style={{ marginLeft: 0, marginTop: 25 }}>
								<button
									type="button"
									disabled={carregando}
									className="ui gray button right"
									onClick={event => this.fileInput && this.fileInput.click()}>
									<Icon name="file" />
									<span>{`${t("planograma")}${
										planogramaFile ? `: ${planogramaFile.name}` : ""
									}`}</span>
								</button>
								{planograma && planograma !== "" && this.props.verPlanograma && (
									<button
										type="button"
										className="ui gray button right"
										onClick={event => this.props.verPlanograma(planograma)}>
										{t("ver.planograma")}
									</button>
								)}
								{editando && (planogramaFile || produto?.planograma) && (
									<button
										type="button"
										className="ui red button right"
										icon="times"
										disabled={carregando}
										onClick={this.discartarPlanograma}>
										<span>{t("remover.planograma")}</span>
									</button>
								)}
								<input
									ref={this.fileInputRef}
									style={{ display: "none" }}
									type="file"
									onChange={event => this.handlePlanograma(event)}
								/>
							</Form.Group>
						</Segment>
					</Grid.Column>
				</Grid>
				{this.props.children}
			</Form>
		);
	}
}
