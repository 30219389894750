function ImageReader(){
    this.xhr = new XMLHttpRequest();
    this.onload = null;
    this.onerror = null;
}

ImageReader.prototype.fromUrl = function(url) {
    var self = this;
    var xhr = this.xhr;
    xhr.open('get', url);
    xhr.responseType = 'blob';
    var fr = new FileReader();
    xhr.onload = function() {
      fr.onload = function() { self.onload(this.result); };
      fr.onerror = function() { self.onerror(this.result); };
      fr.readAsDataURL(xhr.response);
    };
    xhr.onerror = function() { self.onerror(this.result); };
    xhr.send();
};

export const fileReaderToPromise = (ty, onSuccess, onError, readAs) => file =>
    new Promise((resolved, rejected) => {
        var reader = new ty();
        reader.onload = function (e) { resolved(onSuccess(file, e)); };
        reader.onerror = function (e) { rejected(onError(file, e )); };
        readAs(reader, file);
    });

export const imageFromUrlReader = fileReaderToPromise(
    ImageReader,
    (file, data) => ({ file, data }),
    error => error,
    (reader, file) => reader.fromUrl(file)
);
export const imageFileReader = fileReaderToPromise(
    FileReader,
    (file, event) => ({ file, data: event.target.result }),
    error => error,
    (reader, file) => reader.readAsDataURL(file)
);
export const textFileReader = fileReaderToPromise(
    FileReader,
    (file, event) => ({ file, data: event.target.result }),
    error => error,
    (reader, file) => reader.readAsText(file, 'ISO-8859-1') // utf-8
);

export const idFileReader = file => Promise.resolve({ file, data: null });