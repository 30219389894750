import React, { Component } from 'react';
import { Divider, Grid, Header, Icon, List, Rating } from 'semantic-ui-react';
import ImagemCentralizada from '../../Componentes/ImagemCentralizada';
import { db } from '../../config';
import moment from 'moment';

export default class Avaliacoes extends Component {
    state = {
        avaliacoes: []
    }

    componentDidMount() {
        db.collection('empresas').doc(this.props.empresa.uid).collection('avaliacoes').limit(20).orderBy('data', 'desc').onSnapshot(
            snap => this.setState({ avaliacoes: snap.docs.map(data => data.data()) })
        );
    }

    render() {
        const { t, empresa } = this.props

        return (
            <>
                <Header as='h1' style={{ marginTop: 20 }}>
                    <Icon name='comment' />
                    <Header.Content>
                        Minhas Avaliações
                    <Header.Subheader>Avaliações Recentes</Header.Subheader>
                    </Header.Content>
                </Header>

                <Grid style={{ marginTop: 20 }} stackable>

                    <Grid.Row>
                        <Grid.Column centered="true" width={4}>
                            <ImagemCentralizada circular
                                onClick={() => { }}
                                src={empresa.imagemURL} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                                <Rating
                                    size='huge'
                                    maxRating={5}
                                    defaultRating={empresa.mediaAvaliacao && empresa.quantidadeAvaliacao ? (empresa.mediaAvaliacao / empresa.quantidadeAvaliacao).toFixed(2) : 0 }
                                    icon='star'
                                    disabled
                                />
                            </div>
                            <Header textAlign='center'><span style={{ fontSize: 40, color: '#767676', fontWeight: 400 }}>{empresa.quantidadeAvaliacao ? empresa.quantidadeAvaliacao : 0}</span></Header>
                            <Header as='p' style={{ fontSize: 20 }} textAlign='center'>{t('avaliacoes')}</Header>

                        </Grid.Column>

                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={9}>
                            {this.state.avaliacoes.map(_avaliacao => {
                                const { avaliacao, comentario, data } = _avaliacao;
                                const temComentario = comentario != null;

                                return (
                                    <List key={_avaliacao.id}>
                                        {_avaliacao?.empresa?.fantasia && <List.Item style={{ fontSize: 20 }}>{_avaliacao.empresa.fantasia}</List.Item>}
                                        <List.Item>{moment(data).format() === 'Invalid date' ? data : moment(data).format('DD/MM/YYYY HH:mm:ss')}</List.Item>
                                        <List.Item>
                                            {temComentario ? `"${comentario}"` : ('Sem Comentários')}
                                            <Rating
                                                style={{ float: 'right' }}
                                                disabled
                                                maxRating={5}
                                                defaultRating={avaliacao}
                                                icon='star'
                                            />
                                        </List.Item>
                                        <Divider style={{ color: '#768aba' }} />
                                    </List>
                                )
                            })}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </>
        );
    }
}