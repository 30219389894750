export enum NegotiationStatus {
	/**
	 * Negociação está pendente de aprovação. Aguardando resposta.
	 */
	"PENDENTE",
	/**
	 * Negociação deve ser encerrada, pois alguma das partes recusou a última proposta.
	 * Porém, ela pode ser retomada por qualquer uma das parts futuramente.
	 */
	"RECUSADO",
	/**
	 * Negociação foi aceita e a agência da negociação será quem vai executar a oportunidade.
	 */
	"ACEITO",
	/**
	 * A negociação foi expirada por expiração do roteiro ou alguma outra negociação foi aceita antes.
	 */
	"EXPIRADO",
	/**
	 * Status de transição que indica que a negociação não está nem expirada ou pendente, está em pausa até ser transicionada para `EXPIRADO`, `RECUSADO`ou em `PENDENTE`;
	 */
	"EM PAUSA",
}

export enum LogVariant {
	"PADRÃO",
	"SUCESSO",
	"AVISO",
	"ATENÇÃO",
	"ERRO",
}

export interface Proposal {
	criadoEm: string;
	editadoEm: string;
	id: string;
	/**
	 * @description Aquele quem fez a proposta
	 */
	idProponente: string;
	idNegociacao: string;
	idRoteiro: string;
	valor: number;
	descricao: string;
}

export interface NegotiationLog {
	criadoEm: string;
	editadoEm: string;
	id: string;
	titulo: string;
	descricao: string;
	variante: LogVariant;
}

export interface Negotiation {
	criadoEm: string;
	editadoEm: string;
	/**
	 * Status atual da negociação.
	 */
	status: NegotiationStatus;
	/**
	 * Status anterior ao atual da negociação.
	 */
	statusAnterior: NegotiationStatus;

	id: string;
	/**
	 * @description Id da empresa que vai executar a oportunidade
	 */
	idExecutor: string;
	/**
	 * @description Id de quem criou a oportunidade
	 */
	idCriadorOportunidade: string;

	loja: {
		id: string;
		nome: string;
		enderecoCompleto: string;
	};

	roteiro: {
		id: string;
		quantidadeSecoes: number;
		horaInicio: string;
		horaFim: string;
		data: string;
		tipo: number;
		tempoDeExecucaoObrigatorio: boolean;
	};

	propostas: Proposal[];
	logs: NegotiationLog[];

	/**
	 * Id da empresa ou agência que recusou a negociação por último
	 */
	idRecusador?: string;
}
