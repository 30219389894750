import { Flex, Spinner, Switch, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMountedState } from "react-use";
import { CompanyUser } from "../../@types/User";
import { BackendApiError } from "../../errors/BackendApi.error";
import { useBlocker } from "../../hooks/store/useBlocker";
import { CompanyUserService } from "../../services/firebase/CompanyUser";
import { ActionOptionsMenu } from "../ActionOptionsMenu";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { IconifyIconButton } from "../IconifyIconButton";
import { UpsertUserDrawer } from "../UpsertUserDrawer";

interface UsersTableRowProps {
	user: CompanyUser;
}

export function UsersTableRow({ user }: UsersTableRowProps) {
	const [switchIsChecked, setSwitchIsChecked] = useState(user.ativo);

	const drawerController = useDisclosure();
	const confirmationController = useDisclosure();

	const {
		utils: { setBlocker },
		state: { isDeleteCompanyUserBlocked, isUpdateCompanyUserBlocked },
	} = useBlocker();

	const { t } = useTranslation();
	const isMounted = useMountedState();

	function toggleSwitchIsChecked() {
		isMounted() && setSwitchIsChecked(oldState => !oldState);
	}

	const { mutateAsync: deleteUserMutate } = useMutation({
		mutationKey: ["deleteUser"],
		mutationFn: () => CompanyUserService.getInstance().deleteOne(user.uid),
	});

	const { mutate: updateUserMutate, isLoading: isLoadingUpdateUser } = useMutation({
		mutationKey: ["updateUser"],
		mutationFn: (isActive: boolean) =>
			CompanyUserService.getInstance().patchOne({ id: user.uid, isActive }),
		onMutate: () => {
			toggleSwitchIsChecked();
			setBlocker({ isUpdateCompanyUserBlocked: true });
		},
		onError: () => {
			toggleSwitchIsChecked();
			toast.error(t("erro-ao-atualizar-o-status-do-usuario"));
		},
		onSettled: () => {
			setBlocker({ isUpdateCompanyUserBlocked: false });
		},
	});

	function handleDeleteUser() {
		setBlocker({ isDeleteCompanyUserBlocked: true });
		toast
			.promise(deleteUserMutate, {
				error: {
					render: ({ data, toastProps }) => {
						const error = new BackendApiError(data);

						if (error.httpStatusCode >= 500) {
							toastProps.type = "error";
							return t("mensagem-generica-erro-excluir-usuario");
						} else {
							toastProps.type = "warning";
							return error.message;
						}
					},
				},
				pending: t("excluindo-o-usuario").concat("..."),
				success: t("usuario-excluido-com-sucesso"),
			})
			.catch(() => {})
			.finally(() => setBlocker({ isDeleteCompanyUserBlocked: false }));
	}

	const isAllActionButtonsDisabled = useMemo(() => {
		return isDeleteCompanyUserBlocked || isUpdateCompanyUserBlocked;
	}, [isDeleteCompanyUserBlocked, isUpdateCompanyUserBlocked]);

	return (
		<Tr>
			<Td>{user.nome}</Td>
			<Td>{user.email}</Td>
			<Td>{user.telefone}</Td>
			<Td textAlign={"center"}>{moment(user.criadoEm).format("DD/MM/YYYY HH:mm")}</Td>
			<Td textAlign={"center"}>
				{user.editadoEm ? moment(user.editadoEm).format("DD/MM/YYYY HH:mm") : "---"}
			</Td>
			<Td textAlign="center">
				<Flex alignItems={"center"} justifyContent={"center"}>
					<Switch
						isDisabled={isAllActionButtonsDisabled}
						isChecked={switchIsChecked}
						mb={0}
						onChange={e => updateUserMutate(e.target.checked)}></Switch>
					<Spinner size="xs" ml={2} hidden={!isLoadingUpdateUser} />
				</Flex>
			</Td>
			<Td>
				<ActionOptionsMenu
					placement="bottom-end"
					actions={[
						{
							label: t("editar"),
							icon: "mdi:edit",
							handle: drawerController.onOpen,
							isDisabled: isAllActionButtonsDisabled,
						},
						{
							label: t("excluir"),
							icon: "mdi:trash",
							handle: confirmationController.onOpen,
							isDisabled: isAllActionButtonsDisabled,
						},
					]}>
					<IconifyIconButton
						icon="mdi:dots-vertical"
						aria-label={t("mais-opcoes")}
						iconifyProps={{ boxSize: 6 }}
						variant="ghost"
					/>
				</ActionOptionsMenu>
				<UpsertUserDrawer hideTrigger {...drawerController} userToEdit={user} />
				<ConfirmationDialog
					{...confirmationController}
					onConfirm={handleDeleteUser}
					title={t("excluir-usuario")}
					message={t("mensagem-confirmacao-excluir-usuario")}
				/>
			</Td>
		</Tr>
	);
}
