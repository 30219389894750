import { Box, Container } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { Component } from "react";
import Currency from "react-currency-formatter";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Grid, Loader, Statistic } from "semantic-ui-react";
import { Card } from "../../Componentes/Card";
import { PerfilEmpresa } from "../../Componentes/Componentes";
import Confirmar from "../../Componentes/Confirmar";
import { NegotiationsList } from "../../Componentes/NegotiationsList";
import { SemDados } from "../../Interno/Dashboard/DashboardComponents";
import { OportunidadeInfo } from "../../Interno/Dashboard/OportunidadeInfo";
import { RightBoxContent } from "../../Interno/Dashboard/RightBox";
import { chamarNodeApi } from "../../Utils/Api";
import { queriePorDataAgencia, queriePromotor } from "../../Utils/mongo/queriesMongo";
import { alterarOportunidadesAgencia } from "../../_actions/DashboardActions";
import ModalTermos from "./ModalTermos";

class Dashboard extends Component {
	state = {
		cookies: null,
		validacaoFatura: null,
		carregando: true,
	};

	componentDidMount() {
		this.getStorage("cookies");
		this.getStorage("validacaoFatura");
		if (_.isEmpty(this.props.oportunidadesAgencia)) {
			this.buscaOportunidades();
		} else {
			this.setState({ carregando: false });
		}
	}

	/**
	 * Verifica se a flag {nome} está salva no localStorage
	 */
	getStorage = nome => {
		let valor = localStorage.getItem(nome);
		this.setState({ [nome]: valor });
	};

	/**
	 * Grava a flag {nome} com o {valor} no localStorage
	 */
	setStorage = (nome, valor) => {
		localStorage.setItem(nome, valor);
		this.setState({ [nome]: valor });
	};

	/**
	 * Valida a fatura em aberto e direciona para a fatura
	 */
	faturaEmAberto = () => {
		this.setStorage("validacaoFatura", moment().format("YYYY-MM-DD"));
		window.open(`/fatura/${this.props.fatura?.referencia}`);
	};

	/**
	 * @name buscaOportunidades
	 * @date Criado em 05/02/2021
	 * @author Alexandre Poletto
	 * @description busca as oportunidades de agência
	 * e procura o promotor que executou
	 */
	buscaOportunidades = async () => {
		let oportunidades = [];

		await chamarNodeApi(
			"roteiro/aggregate",
			{
				search: queriePorDataAgencia({
					uid: this.props.empresa.uid,
				}),
			},
			"POST"
		).then(roteiros => {
			if (roteiros !== undefined && _.isArray(roteiros)) {
				roteiros
					.reduce((promise, roteiro) => {
						return promise.then(() =>
							this.buscaPromotor(roteiro.usuario.idUsuario).then(promotor => {
								if (!_.isEmpty(promotor)) {
									let oportunidade = [];
									oportunidade = roteiro;
									oportunidade.usuario.nome = promotor[0].nome;
									oportunidade.usuario.foto = promotor[0].foto;
									oportunidades.push(oportunidade);
								} else {
									oportunidades.push(roteiro);
								}
								return oportunidades;
							})
						);
					}, Promise.resolve())
					.then(() => {
						this.props.dispatch(alterarOportunidadesAgencia(oportunidades));
						this.setState({ carregando: false });
					})
					.catch(err => {
						console.log(err);
						this.setState({ carregando: false });
					});
			} else {
				this.setState({ carregando: false });
			}
		});
	};

	/**
	 * @name buscaPromotor
	 * @date Criado em 05/02/2021
	 * @author Alexandre Poletto
	 * @description busca o promotor pelo id
	 * @param {String} idUsuario uid do usuário
	 */
	buscaPromotor = async idUsuario => {
		return await chamarNodeApi(
			"promotor/aggregate",
			{
				search: queriePromotor({
					uid: idUsuario,
				}),
			},
			"POST"
		).then(promotor => promotor);
	};

	render() {
		const { t, empresa, saldos, faturamentoMensal, fatura, oportunidadesAgencia } = this.props;
		const { cookies, validacaoFatura, oportunidade } = this.state;
		const somaSaldo = saldos && saldos.reservado + saldos.reservadoNovo;
		const maximo = empresa?.valorMaximoOportunidades ?? 0;
		const restante = isNaN(maximo - faturamentoMensal?.gastos)
			? 0
			: maximo - faturamentoMensal?.gastos;
		const saldo = saldos && saldos.naoReservado ? saldos.naoReservado : 0;
		const reservado = saldos ? somaSaldo : 0;

		return (
			<Container maxW="container.xl">
				<div className="dashboard">
					<div className="dashboard-box">
						<ModalTermos {...this.props} />

						<Confirmar
							callback={() => this.setStorage("cookies", true)}
							open={cookies === null}
							confirmar={t("prosseguir")}
							mensagem={t("detalhes.sobre.cookies")}
							titulo={t("sobre.cookies")}
						/>
						{!_.isEmpty(fatura) && (
							<Confirmar
								callback={this.faturaEmAberto}
								open={
									fatura?.pago === false &&
									validacaoFatura !== moment().format("YYYY-MM-DD") &&
									moment().isAfter(moment(fatura?.data?.toDate())) &&
									moment().isBefore(
										moment(fatura?.boleto?.due_date).add(1, "day")
									)
								}
								onClose={() =>
									this.setStorage(
										"validacaoFatura",
										moment().format("YYYY-MM-DD")
									)
								}
								cancelar={t("fechar")}
								confirmar={t("ver.fatura")}
								mensagem={
									t("fatura.referencia", {
										fatura1: fatura?.referencia?.substring(0, 2),
										fatura2: fatura?.referencia?.substring(2, 6),
										vencimento: moment(fatura?.boleto?.due_date).format(
											"DD/MM/YYYY"
										),
									}) + t("caso.tenha.pago.fatura")
								}
								titulo={t("fatura.em.aberto")}
							/>
						)}

						<Grid columns={2} textAlign="center" stackable>
							<Grid.Column>
								<PerfilEmpresa {...empresa} />
							</Grid.Column>
							<Grid.Column>
								<Grid.Row>
									<Statistic size="small">
										<Statistic.Label>
											{empresa.faturamentoMensal
												? t("limite.disponivel")
												: t("saldo")}
										</Statistic.Label>
										<Statistic.Value>
											{
												<Currency
													quantity={
														empresa.faturamentoMensal ? restante : saldo
													}
													locale="pt_BR"
													decimal=","
													group="."
													currency="R$ "
												/>
											}
										</Statistic.Value>
									</Statistic>
									<Statistic size="small">
										<Statistic.Label>
											{empresa.faturamentoMensal
												? t("limite.total")
												: t("reservado")}
										</Statistic.Label>
										<Statistic.Value>
											{
												<Currency
													quantity={
														empresa.faturamentoMensal
															? maximo
															: reservado
													}
													locale="pt_BR"
													decimal=","
													group="."
													currency="R$ "
												/>
											}
										</Statistic.Value>
									</Statistic>
								</Grid.Row>
								<Grid.Row style={{ marginTop: 50 }}>
									<Statistic size="small">
										<Statistic.Label>{t("promotores")}</Statistic.Label>
										<Statistic.Value>
											{this.props.promotores.length}
										</Statistic.Value>
									</Statistic>
									<Statistic size="small">
										<Statistic.Label>
											{t("trabalhos.realizados")}
										</Statistic.Label>
										<Statistic.Value>
											{oportunidadesAgencia.length || 0}
										</Statistic.Value>
									</Statistic>
									<Statistic size="small">
										<Statistic.Label>{t("avaliacoes")}</Statistic.Label>
										<Statistic.Value>
											{this.props.empresa.quantidadeAvaliacao || 0}
										</Statistic.Value>
									</Statistic>
								</Grid.Row>
							</Grid.Column>
						</Grid>

						{this.state.carregando ? (
							<div className="dashboard.loader">
								<Loader active>{t("carregando.oportunidades")}...</Loader>
							</div>
						) : (
							<Grid style={{ marginTop: 40 }}>
								<div className="dash-detalhes-dia">
									<div className="dia-oportunidades">
										<div className="detalhes-dia">
											<p style={{ fontSize: 24, fontWeight: "bold" }}>
												{t("ultimos.5.trabalhos")}
											</p>
											<br />
											{!_.isEmpty(oportunidadesAgencia) ? (
												oportunidadesAgencia.map((oportunidade, index) => (
													<OportunidadeInfo
														modulo={this.props.modulo}
														oportunidade={oportunidade}
														index={index}
														key={index}
														pos={this.state.index}
													/>
												))
											) : (
												<SemDados />
											)}
										</div>
									</div>
								</div>
								<div className="dashboard-box-right">
									<p style={{ fontSize: 24, fontWeight: "bold" }}>
										{t("informacoes")}
									</p>
									<br />
									{oportunidade ? (
										<RightBoxContent
											oportunidade={oportunidade}
											promotor={this.state.promotor}
										/>
									) : (
										<SemDados />
									)}
								</div>
							</Grid>
						)}
					</div>
				</div>
				<Box mx={4} borderY="1px" borderColor="gray.200">
					<Card px={5} my={6}>
						<NegotiationsList
							header={{
								title: t("negociacoes-em-andamento"),
								subTitle: t(
									"negociacoes-que-ainda-estao-sendo-analisadas-entre-as-duas-partes-e-estao-pendentes-de-aceite"
								),
								icon: "balance scale",
							}}
							filters={["PENDING"]}
						/>
					</Card>
				</Box>
			</Container>
		);
	}
}

const mapStateToProps = state => ({
	...state.AutenticacaoReducer,
	fatura: state.AutenticacaoReducer.fatura,
	modulo: state.AutenticacaoReducer.modulo,
	oportunidadesAgencia: state.DashboardReducer.oportunidadesAgencia,
});

export default connect(mapStateToProps)(withTranslation()(Dashboard));
