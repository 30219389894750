import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Container, Divider, Grid, Header, Icon, Segment, Table } from "semantic-ui-react";

const divider = {
	width: "100%",
	marginTop: 0,
	marginBottom: 0,
	marginLeft: 40,
	marginRight: 40,
};

/**
 * Exibe as atividades da oportunidade
 * Autor: Alexandre Poletto
 */
export default function AtividadesRoteiro({ roteiro }) {
	const { t } = useTranslation();

	if (roteiro.tipo.id === 5) return null;

	return (
		<Box mt={7}>
			<Header textAlign="center" as="h2">
				{roteiro.secoes ? t("resumo-das-secoes") : t("resumo-das-atividades")}
			</Header>
			{roteiro.secoes && _.size(roteiro.secoes) > 0 ? (
				<Table color="violet" size="large">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>{t("nome")}</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">
								{t("qtd.produtos")}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">{t("reposicao")}</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">
								{t("pesquisa.de.preco")}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">
								{t("pesquisa.de.validade")}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center">
								{t("foto.antes.depois")}
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{Object.keys(roteiro.secoes).map(key => {
							let secao = roteiro.secoes[key];
							return (
								<Table.Row key={key}>
									<Table.Cell>{secao.nome}</Table.Cell>
									<Table.Cell textAlign="center">
										{secao.produtos.length}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{_.includes(secao.atividades, 6) ? t("sim") : t("nao")}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{_.includes(secao.atividades, 3) ? t("sim") : t("nao")}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{_.includes(secao.atividades, 2) ? t("sim") : t("nao")}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{_.includes(secao.atividades, 1) ? t("sim") : t("nao")}
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			) : (
				<Segment color="purple">
					<Grid stackable style={{ marginTop: 0 }}>
						<Grid.Column width={2} />
						<Grid.Column width={5}>
							<Header as="h3">{t("atividade")}</Header>
						</Grid.Column>
						<Grid.Column width={6}>
							<Header as="h3">{t("descricao")}</Header>
						</Grid.Column>
						<Grid.Column style={{ marginBottom: 5 }} width={3} textAlign="center">
							<Header as="h3">{t("fotos")}</Header>
						</Grid.Column>
						<Divider style={divider} />
					</Grid>
					{_.map(roteiro.atividades, (atividade, i) => (
						<Grid key={i} stackable>
							<Grid.Column textAlign="center" width={2}>
								<Icon name={atividade.icone} size="large" />
							</Grid.Column>
							<Grid.Column width={5}>
								<span>{atividade.descricao}</span>
							</Grid.Column>
							<Grid.Column width={6}>
								{atividade.tipo === 1 && <span>{t("nenhuma.descricao")}</span>}
								{atividade.tipo === 4 && <span>{atividade.pergunta}</span>}
								{atividade.produto && <span>{atividade.produto.descricao}</span>}
								{atividade.descricaoFoto !== "" && (
									<span style={{ wordWrap: "break-word" }}>
										{atividade.descricaoFoto}
									</span>
								)}
							</Grid.Column>
							<Grid.Column width={3} textAlign="center">
								<span>{atividade.foto ? atividade.quantidadeFoto : 0}</span>
							</Grid.Column>
							{roteiro.atividades.length - 1 !== i && <Divider style={divider} />}
						</Grid>
					))}
				</Segment>
			)}
			{(roteiro.tipo.id === 2 || roteiro.tipo.id === 3) && roteiro.material ? (
				<>
					<Divider></Divider>
					<Segment color="purple" size="medium" padded>
						<Container textAlign="justified">
							{roteiro.material.fornecido ? (
								<Header as="h3">
									<Icon name="check" color="green"></Icon>
									{t("fornece.materiais")}
								</Header>
							) : (
								<Header as="h3">
									<Icon name="times" color="red"></Icon>
									{t("nao.fornece.materiais")}
								</Header>
							)}
							<div style={{ wordWrap: "break-word" }}>
								<p>{roteiro.material.descricao}</p>
							</div>
						</Container>
					</Segment>
				</>
			) : null}
		</Box>
	);
}
