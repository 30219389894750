import MomentUtils from "@date-io/moment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import "date-fns";
import moment from "moment";
import { useState } from "react";
import { Dropdown, Form } from "semantic-ui-react";

export const theme = createTheme({
	palette: {
		primary: {
			main: "#662780",
		},
	},

	overrides: {
		MuiInputBase: {
			root: {
				width: "100%",
			},
		},
		MuiFormControl: {
			root: {
				width: "100%",
			},
		},
		MuiTextField: {
			root: {
				border: "1px solid #662780 !important",
				borderRadius: "5px",
				width: "100%",
				cursor: "pointer",
			},
		},
	},
	props: {
		MuiInput: {
			disableUnderline: true,
		},
	},
});

const DefaultDropdown = props => (
	<Form.Field>
		<label>{props.label}</label>
		<Dropdown
			fluid
			{...props}
			className={`dropdown-style input-border-color ${props.className}`}
			placeholder={props.placeholder}
			selectOnBlur={false}
			options={props.options}
			onChange={props.onChange}
		/>
	</Form.Field>
);

export const LojaDropdown = props => (
	<DefaultDropdown name="loja" className="select-loja" {...props} />
);

export const TipoDeRoteiroDropdown = props => (
	<DefaultDropdown name="tipo" className="select-tipo" {...props} />
);

export const DataDaAtividadeInput = props => {
	//Est
	const [dataInicial, setDataInicial] = useState(moment());
	const [dataFinal, setDataFinal] = useState(moment(dataInicial).add(1, "day"));
	return (
		<ThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<Form.Field>
					<label>{props.label}</label>
					<Form.Group widths="equal">
						{props.checkRecorrencia === "true" ? (
							<>
								<DatePicker
									className="input-border-color"
									value={dataInicial <= moment() ? moment() : dataInicial}
									disablePast
									invalidDateMessage=""
									format="DD/MM/YYYY"
									onChange={value => {
										setDataInicial(value);
										props.onChange({
											target: {
												value: moment(value).format("YYYY-MM-DD"),
												name: "data",
											},
										});
									}}
								/>

								<span style={{ margin: "11px 10px 0" }}>até</span>
								<DatePicker
									className="input-border-color"
									value={dataFinal <= dataInicial ? dataInicial : dataFinal}
									disablePast
									minDate={moment(dataInicial).add(1, "day")}
									invalidDateMessage=""
									format="DD/MM/YYYY"
									onChange={value => {
										setDataFinal(value);
										props.onChange({
											target: {
												value: moment(value).format("YYYY-MM-DD"),
												name: "dataFim",
											},
										});
									}}
								/>
							</>
						) : (
							<>
								<DatePicker
									className="input-border-color"
									value={dataInicial <= moment() ? moment() : dataInicial}
									disablePast
									invalidDateMessage=""
									format="DD/MM/YYYY"
									onChange={value => {
										setDataInicial(value);
										props.onChange({
											target: {
												value: moment(value).format("YYYY-MM-DD"),
												name: "data",
											},
										});
									}}
								/>
							</>
						)}
					</Form.Group>
				</Form.Field>
			</MuiPickersUtilsProvider>
		</ThemeProvider>
	);
};

export const HorarioDaAtividadeInput = props => {
	const [horaInicial, setHoraInicial] = useState(moment(props.inicio, "HH:mm"));
	const [horaFinal, setHoraFinal] = useState(moment(props.fim, "HH:mm"));
	return (
		<ThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<Form.Field>
					<label>{props.label}</label>
					<Form.Group widths="equal">
						<TimePicker
							value={horaInicial}
							invalidDateMessage={""}
							ampm={false}
							onChange={value => {
								setHoraInicial(value);
								props.onChange({
									target: {
										value: moment(value).format("HH:mm"),
										name: "horaInicio",
									},
								});
							}}
						/>
						<span style={{ margin: "11px 10px 0" }}>às</span>
						<TimePicker
							value={horaFinal}
							invalidDateMessage={""}
							ampm={false}
							onChange={value => {
								setHoraFinal(value);
								props.onChange({
									target: {
										value: moment(value).format("HH:mm"),
										name: "horaFim",
									},
								});
							}}
						/>
					</Form.Group>
				</Form.Field>
			</MuiPickersUtilsProvider>
		</ThemeProvider>
	);
};
