import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, TextArea } from 'semantic-ui-react';

class TreinamentoForm extends Component {

    render() {
        const {
            t, treinamento, handleInput, handleInputDescricao
        } = this.props
        return (
            <>
                <Form.Group style={{ display: 'block', margin: '-10px auto 15px', width: '100%' }} >
                    <div className="field">
                        <label style={{ display: 'block', fontWeight: 'bold' }}>{t('digite.nome.treinamento')}</label>
                        <Input
                            fluid
                            placeholder={t('nome.treinamento')}
                            type='text'
                            required
                            className="input-border-color"
                            name='nome'
                            value={treinamento.nomeTreinamento}
                            onChange={handleInput}
                        />
                    </div>
                </Form.Group>
                <Form.Group style={{ display: 'block', margin: '-10px auto 15px', width: '100%' }} >
                    <div className="field">
                        <label style={{ display: 'block', fontWeight: 'bold' }}>{t('nota.aprovaçao.treinamento')}</label>
                        <Input
                            fluid
                            placeholder={t("nota.de.aprovacao")}
                            type="number"
                            required
                            max={10}
                            min={0}
                            className="input-border-color"
                            name='notaAprovacao'
                            value={treinamento.notaAprovacao}
                            onChange={handleInput}
                        />
                    </div>
                </Form.Group>
                <Form.Group style={{ display: 'block', margin: '0 auto', width: '100%' }}>
                    <div className="field">
                        <label style={{ display: 'block', fontWeight: 'bold' }}>{t('descricao.treinamento')}</label>
                        <TextArea
                            fluid="true"
                            className="input-border-color"
                            placeholder={t('descricao.geral.treinamento')}
                            value={treinamento.descricao}
                            onChange={(e) => handleInputDescricao(e.target.value)}
                        />
                    </div>
                </Form.Group>
            </>
        )
    }
}

const mapStateToProps = state => ({
    empresa: state.AutenticacaoReducer.empresa
})

export default connect(mapStateToProps)(TreinamentoForm)