import React from 'react';
import { isEmpty } from 'lodash';
import { Message, Segment, Loader } from 'semantic-ui-react';


export default props => {
    const { t, carregando, roteiros } = props;
    const Component = props.renderer;

    return (
        <>
            {carregando ? (
                <Segment basic className="page-loading">
                    <Loader style={{ display: "block" }} inline='centered'>Loading</Loader>
                </Segment>
            ) : ((isEmpty(roteiros) && (
                <Message content={t('nenhuma.oportunidade.publicada')}></Message>
            )) || (
                    <Component {...props} />
                ))}
        </>
    );
};