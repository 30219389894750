import "cleave.js/dist/addons/cleave-phone.br";
import React from "react";
import ImagemCentralizada from "../../Componentes/ImagemCentralizada";
import "./cadastro.css";
import { BotaoProximo } from "./CadastroEmpresa";
export default props => (
	<div className={"d-block"}>
		<div>
			<div className="titulo">
				{props.t("logo.empresa")}{" "}
				<span className="d-block font-14 text-muted">
					{" "}
					{props.t("sugestao.tamanho.logo.empresa")}
				</span>
			</div>
			<div className="logo-empresa-img">
				<ImagemCentralizada src={props.logoEmpresa} onClick={() => {}} />
			</div>
			<div className="form-group">
				<input type="file" accept="image/*" onChange={props.fileUploadHandler} />
			</div>
		</div>
		<BotaoProximo t={props.t} />
	</div>
);
