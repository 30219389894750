import saveAs from "file-saver";
import { useCallback } from "react";
import { v4 } from "uuid";
import { utils, writeFileXLSX } from "xlsx";

interface UseSpreadsheetProps {
	fileName?: string;
	data: any[];
}

/**
 * @description Hook que possibilita o download de um objeto passado no formato XLSX e CSV.
 */
export function useSpreadsheet({ fileName, data }: UseSpreadsheetProps) {
	const defaultName = `${fileName || v4}`;

	const downloadXLSX = useCallback(() => {
		const ws = utils.json_to_sheet(data);
		const wb = utils.book_new();
		utils.book_append_sheet(wb, ws, "Data");

		writeFileXLSX(wb, `${defaultName}.xlsx`);
	}, [data, defaultName]);

	const downloadCSV = useCallback(() => {
		const ws = utils.json_to_sheet(data);
		const csv = utils.sheet_to_csv(ws, { FS: ";" });
		const blob = new Blob([csv], { type: "text/plain;charset=UTF-8" });

		saveAs(blob, `${defaultName}.csv`);
	}, [data, defaultName]);

	return {
		downloadXLSX,
		downloadCSV,
	};
}
