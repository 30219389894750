import { IRede } from "../../@types/Rede";
import { SliceStateProps } from "../../@types/store/Store";
import { RedeActions, RedeActionsType } from "./actions";

interface RedeReducerState extends SliceStateProps<IRede[]> {}

const initialState: RedeReducerState = {
	data: [],
	initialFetchWasMade: false,
	initialStateHasBeenLoaded: false,
	isFetching: false,
};

function getTypedAction<G extends RedeActionsType>(genericAction: RedeActions) {
	return genericAction as RedeActions<G>;
}

const RedeReducer = (state = initialState, genericAction: RedeActions): RedeReducerState => {
	switch (genericAction.type) {
		case RedeActionsType["REDE_SET"]: {
			const action = getTypedAction<RedeActionsType.REDE_SET>(genericAction);

			return {
				...state,
				data: action.payload,
			};
		}
		case RedeActionsType["REDE_SET_ERROR"]: {
			const action = getTypedAction<RedeActionsType.REDE_SET_ERROR>(genericAction);

			return {
				...state,
				error: action.payload,
			};
		}
		case RedeActionsType["REDE_SET_INITIAL_FETCH_WAS_MADE"]: {
			const action =
				getTypedAction<RedeActionsType.REDE_SET_INITIAL_FETCH_WAS_MADE>(genericAction);

			return {
				...state,
				initialFetchWasMade: action.payload,
			};
		}
		case RedeActionsType["REDE_SET_INITIAL_STATE_HAS_BEEN_LOADED"]: {
			const action =
				getTypedAction<RedeActionsType.REDE_SET_INITIAL_STATE_HAS_BEEN_LOADED>(
					genericAction
				);

			return {
				...state,
				initialStateHasBeenLoaded: action.payload,
			};
		}
		case RedeActionsType["REDE_SET_IS_FETCHING"]: {
			const action = getTypedAction<RedeActionsType.REDE_SET_IS_FETCHING>(genericAction);

			return {
				...state,
				isFetching: action.payload,
			};
		}
		default:
			//NOTE: Se nenhuma ação correspondente for encontrada, retorne o estado inalterado
			return state;
	}
};

export default RedeReducer;
