import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface RibbonProps {
	children?: ReactNode;
	color?: [BoxProps["bg"], BoxProps["bg"]];
	distance?: number;
}

export function Ribbon({
	color = ["purple.500", "purple.900"],
	distance = 2,
	children,
}: RibbonProps) {
	return (
		<Box as="span" position="absolute" left={5} top={-distance}>
			<Box
				h={16}
				w={8}
				display="flex"
				justifyContent="center"
				alignItems="center"
				bg={color[0]}
				style={{
					clipPath: "polygon(100% 0, 100% 50%, 100% 100%, 50% 75%, 0 100%, 0% 0%)",
				}}>
				{children}
			</Box>
			<Box
				h={distance}
				w={2}
				position="absolute"
				top={0}
				right={"-0.45rem"}
				bg={color[1]}
				style={{ clipPath: "polygon(0 0, 0 0, 100% 100%, 0% 100%)" }}></Box>
		</Box>
	);
}
