interface Address {
	logradouro?: string;
	bairro?: string;
	cidade?: string;
	estado?: string;
	numero?: number;
	cep?: string;
}

export enum CompanyVariant {
	"NORMAL" = 1,
	"AGENCIA" = 2,
}

export interface Company {
	uid: string;
	tipo: CompanyVariant;
	criadoEm: string;
	imagemURL?: string;
	fantasia?: string;
	razaoSocial?: string;
	endereco?: Address;
	telefone?: string;
	email?: string;
	inativo?: boolean;
	cnpj?: string;
	idUsuario?: string;
	responsavel?: {
		email: string;
		nome: string;
		telefone: string;
	};
	mediaAvaliacao?: number;
	quantidadeAvaliacao?: number;
	faturamentoMensal?: boolean;
	bloqueioPromotor?: boolean;
	desabilitarNotificacoes?: boolean;
	taxaPersonalizada?: number;
	valorMaximoOportunidades?: number;
	valorPorHora?: number;
	iugu: {
		contaConfigurada: boolean;
		account_id: string;
		contaVerificada: boolean;
		verificadoIugu: string;
	};
	/**
	 * Array com as redes de atuação que uma agência trabalha diretamente.
	 */
	redesDeAtuacao?: OperationNetworkInCompany[];
	/**
	 * Campo auxiliar ao `redesDeAtuacao` que guarda os ids das redes de atuação que uma agência trabalha diretamente.
	 * @obs
	 * Este campo foi criado para auxiliar no filtro do firebase.
	 */
	idsRedesDeAtuacao?: string[];
}

export interface OperationNetworkInCompany {
	id: string;
	nome: string;
	palavrasChave: string[];
}
