import calcArea from "@turf/area";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import turfCircle from "@turf/circle";
import { point } from "@turf/helpers";
import { Polygon as GeoPolygon } from "geojson";
import { OperationAreaCircle } from "../../@types/OperationArea";

export function getAreaAndGeometryFromLayer(data: GeoPolygon | OperationAreaCircle) {
	let geometry: GeoPolygon | OperationAreaCircle | null = null;
	let area = 0;

	if (data.type === "Polygon") {
		area = calcArea(data);
	} else {
		const circle = turfCircle(data, data.radius, {
			units: "meters",
			steps: 80,
		});

		area = calcArea(circle);
	}

	return {
		area,
		geometry,
	};
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Função diz se um ponto está dentro de uma área de um polígono
 * @param lat Latitude do ponto.
 * @param long Longitude do ponto.
 * @param polygon Polígono que será verificado se o ponto está dentro dele.
 * @returns Se o ponto estiver dentro da área, retorna `true`, caso contrário, retorna `false`.
 */
export function isCoordinateWithinPolygonArea(
	lat: number,
	long: number,
	polygon: GeoPolygon | OperationAreaCircle
): boolean {
	//NOTE: Converte a coordenada para um Point do Turf
	const coordinateAsPoint = point([long, lat]);

	//NOTE: Se for um polígono normal, só faz a operação.
	if (polygon.type === "Polygon") {
		return booleanPointInPolygon(coordinateAsPoint, polygon);
	} else {
		//NOTE: Se for um polígono do tipo circunferência, converte em um polígono do Turf
		const circle = turfCircle(polygon, polygon.radius, {
			units: "meters",
			steps: 80,
		});

		return booleanPointInPolygon(coordinateAsPoint, circle);
	}
}
