import { AuthUserRecord } from "firebase-functions/lib/common/providers/identity";
import { useMemo } from "react";
import { Company } from "../../@types/Company";
import { useAppSelector } from "../useAppSelector";

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Hook que retorna as informações de autenticação da aplicação que estão na `Store`.
 */
export function useAuth() {
	const company = useAppSelector(state => state.AutenticacaoReducer.empresa) as Company | null;
	const user = useAppSelector(
		state => state.AutenticacaoReducer.usuario
	) as AuthUserRecord | null;

	const isUserAdmin = useMemo(() => {
		return company?.idUsuario === user?.uid;
	}, [company?.idUsuario, user?.uid]);

	return { company: company || null, user: user || null, isUserAdmin };
}
