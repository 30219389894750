import { Flex } from "@chakra-ui/react";
import { Zoom } from "@material-ui/core";
import firebase from "firebase";
import _ from "lodash";
import moment from "moment";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import { Button, Grid, Header, Image, Message, Modal, Segment } from "semantic-ui-react";
import { v4 } from "uuid";
import { CardEvaluationPromoter } from "../../../Componentes/CardEvaluationPromoter";
import {
	AtividadeFoto,
	AtividadePergunta,
	AtividadeProduto,
} from "../../../Componentes/Componentes";
import Confirmar from "../../../Componentes/Confirmar";
import { ModalFinishOpportunity } from "../../../Componentes/ModalFinishOpportunity";
import { OpportunityExecutionTimes } from "../../../Componentes/OpportunityExecutionTimes/index";
import { db } from "../../../config";
import imgPlaceholder from "../../../imagens/image-placeholder.png";
import { createPPT, makeFrontPage, makePages } from "../../../Utils/ExportarPPT";
import { importAssets } from "../../../Utils/Imagem";
import { coletaInformacoesDasExecucoes } from "../../../Utils/Roteiros";
import { buscarNFs, emitirNFsOmie } from "../../Financeiro/OportunidadesPagas";
import Mensagem from "../Mensagem";
import { ModelAvaliacao } from "./../../../Model/model";
import RoteiroConnect from "./../RoteiroConnect";
import SecaoSegment from "./SecaoSegment";

const atividadeEstaAberta = (aberto, eIndex, aIndex) =>
	aberto && aberto[0] === eIndex && aberto[1] === aIndex;
const findAtividadeDaExecucao = (execucoes, eIndex, aIndex) => execucoes[eIndex].atividades[aIndex];
const secaoEstaAberta = (aberto, sIndex) => aberto && aberto[0] === sIndex;

const exportarPDF = context =>
	importAssets(context).then(createPPT).then(makeFrontPage).then(makePages);

const buscarImagem = ref =>
	firebase
		.storage()
		.ref(ref)
		.getDownloadURL()
		.then(url => url);
/**
 * buscar imagens do roteiro no storage do firebase
 * imagens de foto de entrada, secoes e demanda
 * @param {Object} roteiro roteiro completo
 */
export const buscarImagens = async roteiro => {
	let _urlImagens = {};
	if (roteiro.fotoEntrada) {
		_urlImagens[roteiro.fotoEntrada[0]] = _.includes(roteiro.fotoEntrada[0], "http")
			? roteiro.fotoEntrada[0]
			: await buscarImagem(roteiro.fotoEntrada[0]);
	}
	if (roteiro.fotoDeposito) {
		await roteiro.fotoDeposito.reduce((promisse, ref) => {
			return promisse.then(() => {
				if (_.includes(ref, "http")) {
					_urlImagens[ref] = ref;
					return Promise.resolve();
				} else {
					return buscarImagem(ref).then(url => {
						_urlImagens[ref] = url;
					});
				}
			});
		}, Promise.resolve());
	}
	if (roteiro.secoes) {
		for (const key of Object.keys(roteiro.secoes)) {
			let secao = roteiro.secoes[key];
			let fotos = [...(secao.fotoAntes ?? []), ...(secao.fotoDepois ?? [])];
			for (const ref of fotos) {
				if (_.includes(ref, "http")) {
					_urlImagens[ref] = ref;
				} else {
					_urlImagens[ref] = await buscarImagem(ref);
				}
			}
		}
	}
	if (roteiro.tipo.id === 5 && roteiro.fotosDemanda) {
		for (const ref of roteiro.fotosDemanda) {
			if (_.includes(ref, "http")) {
				_urlImagens[ref] = ref;
			} else {
				_urlImagens[ref] = await buscarImagem(ref);
			}
		}
	}
	return _urlImagens;
};

class VerificarRoteiro extends Component {
	constructor(props) {
		super(props);
		this.state = {
			podeExportarPPT: /finalizado/.test(props.match.path),
			indexFotoDeposito: null,
			galeriaFotoDeposito: false,
			exportando: false,
			execucoes: [],
			avaliacao: new ModelAvaliacao(props.empresa),
			editar: false,
			reembolsando: false,
			clonar: false,
			confirmar: false,
			concluir: false,
			nomeModelo: "",
			atividades: [],
			carregando: false,
			urlImagens: {},
			carregandoFotos: true,
			buscandoNotaModal: false,
			buscandoNotaError: { error: false, mensagem: "" },
		};
	}

	componentDidMount() {
		this.buscarInformacoesDoRoteiro();
		buscarImagens(this.props.roteiro).then(urlImagens => this.setState({ urlImagens }));
		this.verificarRuptura();
		this.setState({ carregandoFotos: true }, () => {
			buscarImagens(this.props.roteiro).then(urlImagens => {
				this.setState({ urlImagens, carregandoFotos: false });
			});
		});
	}

	componentDidUpdate(nextProps) {
		if (this.props.match.params.id !== nextProps.match.params.id) {
			this.buscarAtividades();
		}
	}

	atividadesDaExecucao = id =>
		db.collection("execucaoAtividades").doc(id).collection("atividades");

	/**
	 * iterando as excuções para buscar as atividades e armazenar em um array
	 * @param {object} snap snapshot de retorno do firebase
	 * @type {promise}
	 * @returns {array}
	 */
	acumulandoAtividas = snap =>
		snap.docs.reduce((promise, documento) => {
			const execucao = documento.data();
			return promise.then(acumulador => {
				return this.atividadesDaExecucao(execucao.id)
					.get()
					.then(snap => {
						const execucaoAtividades = {
							execucao,
							atividades: snap.docs.map(data => ({
								...data.data(),
								pai: execucao.id,
							})),
						};
						return [...acumulador, execucaoAtividades];
					});
			});
		}, Promise.resolve([]));

	/**
	 * buscando as atividades executadas de um roteiro
	 * e empresas favoritas
	 */
	buscarInformacoesDoRoteiro = () => {
		const roteiro = this.props.roteiro;
		this.setState({ roteiro }, () => {
			db.collection("execucaoAtividades")
				.where("idRoteiro", "==", this.props.roteiro.id)
				.get()
				.then(snap => {
					this.acumulandoAtividas(snap).then(a =>
						this.setState({ execucoes: a }, () => {
							this.buscarAtividades().then(val => {
								this.existeExecucao(val).then(arr =>
									this.setState({ atividades: arr })
								);
							});
						})
					);
				});
			db.collection("empresas")
				.doc(this.props.empresa.uid)
				.collection("favoritos")
				.get()
				.then(snap => {
					snap.forEach(doc => {
						if (doc.data().uid === this.props.roteiro.usuario.uid) {
							this.setState({ favorito: true });
						}
					});
				});
		});
	};

	/**
	 * abre o accordion de execução de atividade
	 * e busca as imagens da execução
	 * @param {number} eIndex indice da execução
	 * @param {number} aIndex indice da atividade
	 */
	abrirAtividade = (eIndex, aIndex) => {
		const aberto = [eIndex, aIndex];
		this.setState({ carregandoAtividade: true });
		if (atividadeEstaAberta(this.state.aberto, eIndex, aIndex)) {
			this.setState({ aberto: null, carregandoAtividade: false });
			return;
		}
		this.setState({ aberto, fotosExibir: [] }, () => {
			var atividade = findAtividadeDaExecucao(this.state.execucoes, eIndex, aIndex);
			db.collection("execucaoAtividades")
				.doc(atividade.pai)
				.collection("atividades")
				.doc(atividade.id)
				.collection("fotos")
				.get()
				.then(snap => {
					snap.forEach(data => {
						var foto = data.data().foto;
						this.state.fotosExibir.push({ original: foto, thumbnail: foto });
					});
					this.setState({ fotosExibir: this.state.fotosExibir });
				});
			this.setState({ carregandoAtividade: false });
		});
	};

	/**
	 * abre o accordion de seção
	 * @param {number} sIndex indice da seção
	 */
	abrirSecao = sIndex => {
		const aberto = [sIndex];
		if (secaoEstaAberta(this.state.aberto, sIndex)) {
			this.setState({ aberto: null, carregandoSecao: false });
			return;
		}
		this.setState({ aberto });
	};
	/**
	 * compilando informações da execução para exportar em um pdf
	 */
	exportarPDF = () =>
		this.setState({ exportando: true }, () => {
			const { data, tipo } = this.state.roteiro;
			const { atividades, quantidadeAtividades, quantidadeFoto } =
				coletaInformacoesDasExecucoes(this.state.execucoes);
			exportarPDF({
				roteiro: {
					...this.state.roteiro,
					quantidadeFoto,
					quantidadeAtividades,
				},
				atividades,
				err: null,
				pptx: null,
				assets: { logo: this.props.empresa.imagemURL },
			})
				.then(
					context =>
						new Promise(resolved =>
							context.pptx.save(
								`roteiro-${tipo.descricao}-${moment(data).format("DD-MM-YYYY")}.pptx`,
								resolved
							)
						)
				)
				.catch(context => console.error(context.err))
				.finally(() => this.setState({ exportando: false }));
		});

	/**
	 * itera as execuções comparando as atividades
	 * e acumula em um array
	 * @type {promise}
	 * @returns {array}
	 */
	buscarAtividades = () =>
		new Promise(res => {
			var atividades = this.state.roteiro ? this.state.roteiro.atividades : [];

			var reduce = this.state.execucoes.reduce((promise, execucao) => {
				return promise.then(acumulador => {
					let diff = _.differenceBy(atividades, execucao.atividades, "id");
					return Promise.resolve([
						...acumulador,
						{ execucao, atividades: execucao.atividades.concat(diff) },
					]);
				});
			}, Promise.resolve([]));

			return reduce.then(val => res(val));
		});

	/**
	 * verifica se a execução foi iniciada
	 * @param {array} atividades
	 * @type {promise}
	 * @returns {array}
	 */
	existeExecucao = atividades =>
		new Promise(res => {
			var length = atividades.length;
			// var at = this.state.roteiro ? this.state.roteiro.atividades : []
			// var { idEmpresa, data, id } = this.state.roteiro
			if (length > 0) {
				res(atividades);
			}
		});
	// essa função renderiza de acordo com o tipo da atividade
	// tipo 1: foto de fachada e foto de saida
	// tipo 4: pergunta
	// tipo 0: qualquer outra atividade com foto
	renderAtividades = (tipo, atividade, aIndex, eIndex) => {
		var abrirAtividade = () => this.abrirAtividade(eIndex, aIndex);
		var aberto = atividadeEstaAberta(this.state.aberto, eIndex, aIndex);
		var executado = atividade.executado === 2;
		var c = {
			1: (state, atividade) => (
				<AtividadeFoto
					executado={executado}
					item={atividade}
					abrirAtividade={abrirAtividade}
					aberto={aberto}
					carregandoAtividade={state.carregandoAtividade}
					fotosExibir={state.fotosExibir}
				/>
			),
			4: (state, atividade) => <AtividadePergunta executado={executado} item={atividade} />,
			0: (state, atividade) => (
				<AtividadeProduto
					executado={executado}
					item={atividade}
					abrirAtividade={abrirAtividade}
					aberto={aberto}
					carregandoAtividade={state.carregandoAtividade}
					fotosExibir={state.fotosExibir}
				/>
			),
		};

		return c[tipo === 1 || tipo === 4 ? tipo : 0](this.state, atividade, aIndex, eIndex);
	};
	/**
	 * renderiza o conteudo de uma seção
	 * @param {object} secao
	 * @returns {React.Component}
	 */
	renderSecao = secao => {
		var abrirSecao = () => this.abrirSecao(secao);
		var aberto = secaoEstaAberta(this.state.aberto, secao);
		return (
			<SecaoSegment
				item={secao}
				roteiro={this.state.roteiro}
				abrirSecao={abrirSecao}
				aberto={aberto}
				urlImagens={this.state.urlImagens}
				t={this.props.t}
				carregandoSecao={this.state.carregandoSecao}
			/>
		);
	};

	openModalFotoDeposito = indexFoto =>
		this.setState({ galeriaFotoDeposito: true, indexFotoDeposito: indexFoto });
	closeModalFotoDeposito = () =>
		this.setState({ galeriaFotoDeposito: false, indexFotoDeposito: null });

	openModalFotoDemanda = () =>
		this.setState({ openModalFotoDemanda: !this.state.openModalFotoDemanda });

	/**
	 * renderiza o conteudo da execução de demanda
	 * @returns {React.Component}
	 */
	renderFotosDemanda = () => {
		const { roteiro } = this.state;
		var imagensFotoDemanda = _.map(roteiro.fotosDemanda, ref => ({
			original: this.state.urlImagens[ref],
			thumbnail: this.state.urlImagens[ref],
		}));
		return (
			<>
				{roteiro.fotosDemanda.map(ref => (
					<div
						onClick={this.openModalFotoDemanda}
						style={{
							cursor: "pointer",
							background: "#eee",
							width: 100,
							height: 100,
							margin: 5,
							display: "inline-block",
							overflow: "hidden",
							borderRadius: 5,
						}}>
						<img
							alt=""
							src={this.state.urlImagens[ref] ?? imgPlaceholder}
							style={{
								minWidth: "100%",
								maxWidth: "120%",
								minHeight: "100%",
								maxHeight: "150%",
							}}
						/>
					</div>
				))}
				<Modal open={this.state.openModalFotoDemanda} onClose={this.openModalFotoDemanda}>
					<Modal.Header>Fotos</Modal.Header>
					<Modal.Content>
						<ImageGallery
							items={imagensFotoDemanda}
							showPlayButton={false}
							additionalClass="atividade-image-gallery"
						/>
					</Modal.Content>
				</Modal>
			</>
		);
	};

	verificarRuptura = () => {
		let { secoes } = this.props.roteiro;
		let produtos = _.reduce(
			secoes,
			(secao, secoes) => (secao = { ...secao, ...secoes.produtos }),
			{}
		);
		let ruptura = _.filter(produtos, produto => produto.ruptura === true);
		this.setState({
			pedirContato: ruptura.length >= _.size(produtos) * (60 / 100),
		});
	};

	notaFiscal = roteiro => {
		const erroNota = mensagem =>
			this.setState({
				buscandoNota: false,
				buscandoNotaError: {
					error: true,
					mensagem,
				},
			});
		this.setState(
			{
				buscandoNota: true,
				buscandoNotaModal: true,
				buscandoNotaError: { error: false, mensagem: "" },
			},
			async () => {
				if (!roteiro?.notaFiscal?.codigo_nfs && roteiro?.notaFiscal?.numero) {
					await buscarNFs(roteiro, this.props.t, erroNota);
					this.setState({ buscandoNota: false });
				} else {
					await emitirNFsOmie(roteiro, erroNota, this.props?.configuracao?.notaFiscal);
					this.setState({ buscandoNota: false });
				}
			}
		);
	};

	render() {
		const { roteiro, atividades, urlImagens, galeriaFotoDeposito, indexFotoDeposito } =
			this.state;
		const { t } = this.props;

		const imagesFotoDeposito = _.map(this.props.roteiro.fotoDeposito, ref => {
			return {
				original: urlImagens[ref],
				thumbnail: urlImagens[ref],
			};
		});

		if (!roteiro) {
			return <div>{/* TODO(dias): verificar quando não existir roteiro. */}</div>;
		}
		let callbackConfirmar = () =>
			!this.state.buscandoNota ? this.setState({ buscandoNotaModal: false }) : () => {};
		return (
			<div>
				<Confirmar
					callback={callbackConfirmar}
					onClose={callbackConfirmar}
					open={this.state.buscandoNotaModal}
					isLoading={this.state.buscandoNota}
					error={this.state.buscandoNotaError.error}
					errorTitle={t("nota.fiscal")}
					errorMessage={this.state.buscandoNotaError.mensagem}
					loadMessage={t("buscando.nota.message")}
					confirmar={t("fechar")}
					fechar={t("fechar")}
					mensagem={t("buscando.nota.message.sucesso")}
					titulo={t("nota.fiscal")}
				/>
				{this.state.roteiro.usuario && this.state.roteiro.usuario.sexo !== "Agência" && (
					<Mensagem roteiro={this.state.roteiro} />
				)}

				{roteiro.atividades && (
					<Message negative>
						<Message.Header>{t("roteiro.desatualizado")}</Message.Header>
						<p>{t("roteiro.com.atividade")}</p>
					</Message>
				)}
				<Flex gap={7} flexDir={{ base: "column", md: "row" }}>
					<Flex flexDir={"column"} gap={4}>
						<CardEvaluationPromoter opportunity={this.state.roteiro} />
						{this.state.podeExportarPPT && (
							<Button
								basic
								content={"Exportar PPT"}
								icon="file"
								color="purple"
								loading={this.state.exportando}
								disabled={!roteiro.statusRoteiro.avaliacao || this.state.exportando}
								onClick={this.exportarPDF.bind(this)}></Button>
						)}
						{roteiro.pagamento?.pagarFaturamentoMensal !== true &&
						[3, 5].includes(roteiro.statusRoteiro.id) ? (
							<Button
								content={
									roteiro?.notaFiscal?.numero
										? t("baixar.comprovantes")
										: t("emitir.nota.fiscal")
								}
								icon="download"
								onClick={() => this.notaFiscal(roteiro)}
							/>
						) : null}
						{roteiro.pagamento?.pagarFaturamentoMensal === true &&
							[3, 5].includes(roteiro.statusRoteiro.id) && (
								<div
									style={{
										width: "100%",
										borderRadius: 5,
										padding: 5,
										backgroundColor: "#eee",
										color: "#333",
										boxShadow: "0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5",
										display: "flex",
										flexDirection: "column",
									}}>
									<span>{t("oportunidade.em.faturamento.mensal")}</span>
								</div>
							)}
						<OpportunityExecutionTimes
							iniciadoEm={this.props.roteiro.iniciadoEm}
							finalizadoEm={this.props.roteiro.finalizadoEm}
						/>
						<ModalFinishOpportunity roteiro={this.props.roteiro} />
					</Flex>
					<Flex flexDir={"column"} flex={1}>
						<div style={{ display: "flex", flexDirection: "row" }}>
							{
								roteiro.fotoEntrada && roteiro.fotoEntrada.length > 0 && (
									<div
										style={{
											marginRight: 15,
											borderRadius: 5,
											overflow: "hidden",
											textAlign: "center",
											display: "block",
											backgroundImage: `url(${urlImagens[roteiro.fotoEntrada[0]]})`,
											backgroundSize: "cover",
										}}
										onClick={() => this.setState({ abrirFotoEntrada: true })}>
										<div
											style={{
												width: 100,
												height: 100,
												overflow: "hidden",
												alignSelf: "flex-end",
												borderRadius: 5,
											}}></div>
										<div
											style={{
												background: "rgba(0,0,0,0.6)",
												padding: 10,
												color: "#fff",
												alignSelf: "flex-end",
												fontWeight: "bold",
											}}>
											{t("foto.de.entrada")}
										</div>
										<Modal
											open={this.state.abrirFotoEntrada}
											onClose={() =>
												this.setState({ abrirFotoEntrada: false })
											}>
											<Modal.Header>{t("foto.de.entrada")}</Modal.Header>
											<Modal.Content>
												<ImageGallery
													items={[
														{
															original:
																urlImagens[roteiro.fotoEntrada[0]],
															thumbnail:
																urlImagens[roteiro.fotoEntrada[0]],
														},
													]}
													showPlayButton={false}
													additionalClass="atividade-image-gallery"
												/>
											</Modal.Content>
										</Modal>
									</div>
								)
								// <div style={{ marginBottom: 10 }}>
								//     <span style={{ display: 'block', fontSize: '18px', fontWeight: 'bold', marginBottom: '5px' }}>{t('foto.entrada')}</span>
								//     <ImageGallery disableThumbnailScroll={true} items={_.reduce(roteiro.fotoEntrada, (result, value) => {result.push({original: value, thumbnail: value}); return result}, [])} showPlayButton={false} additionalClass='atividade-image-gallery' />
								// </div>
							}
							<div
								style={{
									display: "flex",
									flex: 1,
									flexDirection: "column",
								}}>
								{roteiro.descricao && (
									<div style={{ marginBottom: 10 }}>
										<span
											style={{
												display: "block",
												fontSize: "18px",
												fontWeight: "bold",
												marginBottom: "5px",
											}}>
											{t("descricao")}
										</span>
										{roteiro.descricao}
									</div>
								)}
								{roteiro.observacaoChecklist && (
									<div style={{ marginBottom: 10 }}>
										<span
											style={{
												display: "block",
												fontSize: "18px",
												fontWeight: "bold",
												marginBottom: "5px",
											}}>
											{t("observacoes.do.promotor")}
										</span>
										{roteiro.observacaoChecklist}
									</div>
								)}
								{this.state.pedirContato && (
									<div style={{ marginBottom: 10 }}>
										<span
											style={{
												display: "block",
												fontSize: "18px",
												fontWeight: "bold",
												marginBottom: "5px",
											}}>
											{t("informacoes.encarregado")}
										</span>
										<span>{roteiro?.encarregadoDeloja?.telefone}</span>
										<br />
										<span>{roteiro?.encarregadoDeloja?.email}</span>
									</div>
								)}
							</div>
						</div>
						<>
							<Header
								as="h3"
								icon="clipboard list"
								content={
									roteiro.tipo.id === 5
										? t("fotos")
										: roteiro.secoes === undefined
											? t("atividades")
											: t("secoes")
								}
							/>
							{roteiro.secoes === undefined &&
								!_.isEmpty(atividades) &&
								atividades.map(({ execucao, atividades }, eIndex) => (
									<div key={execucao.id}>
										<h3 key={v4()} style={{ marginTop: "20px" }}>
											{moment(execucao.data).format("LLLL")}{" "}
										</h3>
										{atividades.map((atividade, aIndex) => (
											<Segment
												color={
													atividade.executado === 2 ? "green" : "yellow"
												}
												key={aIndex}>
												{this.renderAtividades(
													atividade.tipo,
													atividade,
													aIndex,
													eIndex
												)}
											</Segment>
										))}
									</div>
								))}
							{roteiro.secoes &&
								Object.entries(roteiro.secoes).map(([key, secao], index) => {
									return (
										<Zoom
											key={secao.id}
											in={roteiro && !_.isEmpty(roteiro.secoes)}
											mountOnEnter
											unmountOnExit
											style={{
												transitionDelay: index * 60 + 60 + "ms",
											}}>
											<Segment
												key={secao}
												style={{ transition: "ease-in-out 0.3s" }}
												color="purple">
												{this.renderSecao(secao)}
											</Segment>
										</Zoom>
									);
								})}
							{roteiro.tipo.id === 5 &&
								roteiro.fotosDemanda &&
								this.renderFotosDemanda()}
							{roteiro.fotoDeposito && roteiro.fotoDeposito.length > 0 && (
								<Segment>
									<Grid stackable>
										<Grid.Column
											width={16}
											style={{
												display: "flex",
												justifyItems: "center",
												alignItems: "center",
											}}>
											<div style={{ fontWeight: "bold", fontSize: 18 }}>
												{roteiro.fotoDeposito.length > 1
													? t("fotos.deposito")
													: t("foto.deposito")}
											</div>
										</Grid.Column>
										<Grid.Column width={16} style={{ display: "flex" }}>
											{!_.isEmpty(urlImagens) &&
												_.map(roteiro.fotoDeposito, (ref, index) => {
													return (
														<Image
															key={index}
															as="button"
															style={{
																border: "none",
																borderRadius: "5px",
																background: "transparent",
																outline: "none",
															}}
															size="tiny"
															src={
																urlImagens[
																	roteiro.fotoDeposito[index]
																]
															}
															onClick={() =>
																this.openModalFotoDeposito(index)
															}
														/>
													);
												})}
										</Grid.Column>
									</Grid>
									<Modal
										open={galeriaFotoDeposito}
										onClose={this.closeModalFotoDeposito}>
										<Modal.Header>
											{roteiro.fotoDeposito.length > 1
												? t("fotos.deposito")
												: t("foto.deposito")}
										</Modal.Header>
										<br />
										<Modal.Description>
											<ImageGallery
												startIndex={indexFotoDeposito}
												items={imagesFotoDeposito}
												showPlayButton={false}
												additionalClass="atividade-image-gallery"
											/>
										</Modal.Description>
									</Modal>
								</Segment>
							)}
						</>
					</Flex>
				</Flex>
			</div>
		);
	}
}

export default withSnackbar(RoteiroConnect(withTranslation()(VerificarRoteiro)));
