import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";

const pegaNavegador = () => {
	//Todos os navegadores tem o Motor do Chrome (Menos o Firefox e talvez o Safari (não tenho certeza)), então o IF pega se tem Chrome e verifica (se tiver OPR é opera, se tiver Edge é IE)
	if (navigator.userAgent.indexOf("Chrome") >= 0) {
		if (navigator.userAgent.indexOf(" Edge/") >= 0) {
			return "IE";
		} else if (navigator.userAgent.indexOf(" OPR/") >= 0) {
			return "Opera";
		} else {
			return "Chrome";
		}
	} else if (navigator.userAgent.indexOf("Firefox") >= 0) {
		return "Firefox";
	} else {
		return "Unknown";
	}
};

export default class verificNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Modal: false,
			navegador: pegaNavegador() === "Chrome" || pegaNavegador() === "Firefox",
			open: false,
		};
	}

	componentDidMount() {
		this.buscarModal();
	}

	close = () => {
		localStorage.setItem("escondeModalBrowser", true);
		this.setState({ open: false });
	};

	buscarModal = () => {
		var esconderModal = localStorage.getItem("escondeModalBrowser") || this.state.navegador;
		this.setState({ open: esconderModal ? false : true });
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<Modal size={"mini"} open={this.state.open} onClose={this.close}>
					<Modal.Header>{t("navegadores.titulo.modal")}</Modal.Header>
					<Modal.Content>
						<p>{t("navegadores.suportados.modal")}</p>
					</Modal.Content>
					<Modal.Actions>
						<Button color={"violet"} onClick={this.close}>
							{t("botao.modal")}
						</Button>
					</Modal.Actions>
				</Modal>
			</>
		);
	}
}
