import { z } from "zod";

/**
 * @description Validador Zod para preço que converte a vírgula em ponto e valores null/undefined para 0 e permitir apenas números positivos
 */
export const PRICE_VALIDATION = z.coerce
	.string()
	.default("0")
	.transform(value => {
		//NOTE: Remove tudo que não for número ou vírgula
		const regex = /[^0-9,]/g;

		//NOTE: Substitui a vírgula por ponto
		const newValue = value.replaceAll(regex, "").replaceAll(",", ".");
		return Number(newValue);
	})
	.refine(
		value => !isNaN(value),
		"O valor deve ser um número, tente utilizar a notação de ponto, exemplo: 1.99"
	)
	.refine(value => value >= 0, "O valor deve ser maior que 0");
