import { Flex, Heading } from "@chakra-ui/react";
import { Button, Header, Icon, Popup, Segment } from "semantic-ui-react";
import { CardEvaluationPromoter } from "./CardEvaluationPromoter";
import Link from "./Link";

const style = {
	display: "flex",
	justifyItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	textAlign: "center",
};

export default function MensagemCadastroBloqueado(props) {
	const { oportunidades } = props;

	return (
		<div>
			<Segment color="red" style={style}>
				<Header>
					<p style={{ marginBottom: "20px" }}>
						<Icon size="huge" color="red" name="warning sign" />
					</p>
					<h4>{props.t("cadastro.bloqueado")}</h4>
				</Header>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<div style={{ width: 300 }}>
						<p>{props.t("cadastro.bloqueado.subtitulo")}</p>
					</div>
					<Popup
						style={{ marginLeft: 15 }}
						content={
							<div style={{ width: 300 }}>
								<p style={{ color: "gray" }}>
									{props.t("por.que.avaliar?.resposta")}
								</p>
							</div>
						}
						trigger={
							<Icon
								size="big"
								name="info circle"
								style={{ color: "gray", paddingLeft: 20 }}
							/>
						}
					/>
				</div>
			</Segment>
			<Flex flexDir={"column"} alignItems={"center"} gap={10} mt={10}>
				<Heading as="h4" size="md">
					{props.t("avalie.oportunidades")}
				</Heading>
				<Flex flexWrap={"wrap"} justifyContent={"space-around"} gap={10} w="full">
					{!_.isEmpty(oportunidades) &&
						_.map(oportunidades.slice(0, 3), oportunidade => {
							return (
								<CardEvaluationPromoter
									containerProps={{
										flex: 1,
									}}
									opportunity={oportunidade}
									key={oportunidade.id}
								/>
							);
						})}
				</Flex>
				<Button
					as={Link}
					to="/oportunidades/3"
					className="purple-gradient"
					content={props.t("ver.oportunidades.nao.avaliadas")}
					color="purple"
				/>
			</Flex>
		</div>
	);
}
