import {
  ROTEIROS,
  ROTEIRO_REEMBOLSADO,
  ROTEIROS_AGENCIAS,
  ROTEIRO_AVALIADO,
  TREINAMENTOS,
} from "./Types";
import firebase from "firebase";
import { chamarFunction } from "../Utils/Api";

import moment from "moment";

const guardar = (kind) => (snap) => ({
  type: ROTEIROS,
  kind,
  payload: snap,
});
const guardarPromise = (kind) => (snap) => (dispatch) => {
  dispatch({
    type: ROTEIROS,
    kind,
    payload: snap,
  });
  return Promise.resolve();
};

export const salvarOportunidadeAgencia = (data) => ({
  type: ROTEIROS_AGENCIAS,
  payload: data,
});

export const oportunidadesNovas = guardar("novasOportunidades");
export const oportunidadesPendentes = guardar("oportunidadesPendentes");
export const oportunidadesPendentesPaginado = guardar(
  "oportunidadesPendentesPaginado"
);
export const oportunidadesPendentesEmExecucaoComPendencia = guardar(
  "oportunidadesPendentesEmExecucaoComPendencia"
);
export const oportunidadesIncompletas = guardar("oportunidadesIncompletas");
export const oportunidadesExecutadasAction = guardar("oportunidadesExecutadas");
export const oportunidadesFinalizadasNaSemanaAction = guardar("oportunidadesFinalizadasNaSemana");
export const oportunidadesExecutadas_Finalizadas = guardar(
  "oportunidadesExecutadas_Finalizadas"
);
export const oportunidadesExecutadasPorPeriodo = guardar(
  "oportunidadesExecutadasPorPeriodo"
);
export const oportunidadesAgencia = guardarPromise("oportunidadesAgencia");
export const oportunidadesAgPendentes = guardar("opAgPendentes");
export const oportunidadesAgPendentesAguandoPromotor = guardar(
  "opAgPendentesAguardandoPromotor"
);
export const oportunidadesAgExecutadas = guardar("opAgExecutadas");

export const direcionarPromotor =
  (idRoteiro, data, roteiro) => async (dispatch) => {
    if (data.statusAgencia === 2) {
      let candidato;
      await firebase
        .firestore()
        .collection("perfis")
        .doc(data.promotor)
        .get()
        .then((snap) => {
          candidato = snap.data();
        });

      await firebase
        .firestore()
        .collection("empresas")
        .doc(candidato.agencia)
        .get()
        .then(async (snap) => {
          let agencia = snap.data();
          if (agencia) {
            let dadosDocumentos = {
              nomePromotor: candidato.nome,
              cnpjPromotor: candidato.cnpj,
              fotoPromotor: candidato.foto,
              cpfPromotor: candidato.cpf,
              sexoPromotor: "Agência",
              email: candidato.email,
              servico: "PROMOTOR DE VENDAS CLT",
              loja: roteiro.loja.fantasia,
              empresa: {
                fantasia: roteiro.fantasiaEmpresa,
                cnpj: roteiro.cnpjEmpresa,
              },
              agencia: {
                fantasia: agencia.fantasia,
                cnpj: agencia.cnpj,
                responsavel: {
                  telefone: agencia.responsavel.telefone,
                  nome: agencia.responsavel.nome,
                },
              },
              plataforma: "PROMOTOR FREELANCE",
              data: moment().format("DD/MM/YYYY"),
              dataFormatada: moment().format("LL"),
            };
            chamarFunction("enviarDocumentos", dadosDocumentos);
          }
        });
    }

    return firebase
      .firestore()
      .collection("roteiros")
      .doc(idRoteiro)
      .update(data);
  };

export const roteiroReembolsado = (idRoteiro) => ({
  type: ROTEIRO_REEMBOLSADO,
  id: idRoteiro,
});

export const salvarTreinamentos = (treinamentos) => ({
  type: TREINAMENTOS,
  treinamentos: treinamentos,
});

export const roteiroAvaliado = (idRoteiro) => ({
  type: ROTEIRO_AVALIADO,
  idRoteiro,
});
