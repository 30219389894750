import React from "react";
import { SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { CandidatesArea } from "./CandidatesArea";
import { CompanyAreaInformation } from "./CompanyAreaInformation";
import { IconArea } from "./IconArea/index";
import { OpportunityAreaInformation } from "./OpportunityAreaInformation/index";
import { Container, Content, LeftContent, RightContent, SegmentContainer } from "./styles";
import { ValueAndMaterialsArea } from "./ValueAndMaterialsArea/index";

export interface Opportunity {
    id: string;
    candidatos?: [];
    horaInicio: string;
    horaFim: string;
    tipo: {
        id: number;
        icone: SemanticICONS;
    };
    loja: {
        fantasia: string;
        endereco: {
            endereco: string;
        };
    };
    secoes?: {};
    atividades: [];
    valor: number;
    pagamento: {
        valorPromotor: number;
    };
    material?: {
        fornecido: {};
    };
    statusRoteiro: {
        id: number;
    };
    selecaoAutomatica?: boolean;
    usuario: {
        nome: string;
        mediaAvaliacao: number;
        quantidadeAvaliacao: number;
        foto: string;
    };
}

export type OpportunitySegmentVariant = "incomplete" | "executed" | "new" | "default";

export type OpportunitySegmentMode = "company" | "agency";

interface OpportunitySegmentProps {
    color: SemanticCOLORS;
    variant: OpportunitySegmentVariant;
    opportunity: Opportunity;
    mode?: OpportunitySegmentMode;
}

export function OpportunitySegment({ color, mode = "company", variant, opportunity }: OpportunitySegmentProps) {
    const link = mode === "company" ? "/oportunidade/info/" + opportunity.id : "/buscar/" + opportunity.id;
    return (
        <Container to={link}>
            <SegmentContainer color={color}>
                <Content>
                    <LeftContent>
                        <IconArea icon={opportunity.tipo.icone} />
                        <CompanyAreaInformation opportunity={opportunity} type={variant} />
                    </LeftContent>
                    <RightContent>
                        <CandidatesArea
                            promoter={opportunity.usuario ? opportunity.usuario : undefined}></CandidatesArea>
                        <OpportunityAreaInformation opportunity={opportunity} />
                        <ValueAndMaterialsArea opportunity={opportunity} mode={mode} type={variant} />
                    </RightContent>
                </Content>
            </SegmentContainer>
        </Container>
    );
}
