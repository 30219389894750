import { Heading } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";
import { OpportunitySegment } from "../../Componentes/OpportunitySegment";
import RoteiroConnect from "../../Interno/Roteiro/RoteiroConnect";
import {
    AGENCIA_AGUARDANDO_EXECUCAO,
    filtroOportunidades,
    oportunidadesPendentes,
    orderBy,
    ROTEIRO_EM_EXECUCAO,
} from "../../Utils/Roteiros";
import { oportunidadesAgPendentes } from "../../_actions/RoteiroActions";

class Pendentes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lista1: [],
            lista2: [],
        };
    }

    componentDidMount() {
        this.props.opAgPendentes === null &&
            this.pendentes().then((l1) =>
                this.emExecucao().then((l2) => {
                    return this.props.dispatch(oportunidadesAgPendentes(l1.concat(l2)));
                })
            );
    }

    pendentes = () => {
        return new Promise((s) =>
            oportunidadesPendentes(this.props.empresa.idUsuario, AGENCIA_AGUARDANDO_EXECUCAO).onSnapshot((snap) => {
                let l = [];
                snap.forEach((d) => l.push(d.data()));
                return s(l);
            })
        );
    };

    emExecucao = () => {
        return new Promise((s) =>
            filtroOportunidades(this.props.empresa.idUsuario, ROTEIRO_EM_EXECUCAO, true).onSnapshot((snap) => {
                let l = [];
                snap.forEach((d) => l.push(d.data()));
                return s(l);
            })
        );
    };

    componentWillUnmount() {
        this.props.dispatch(oportunidadesAgPendentes(null));
    }

    render() {
        const { t, opAgPendentes } = this.props;
        const grupos = orderBy(_.groupBy(opAgPendentes, "data"), "asc");
        return (
            <>
                {_.isEmpty(opAgPendentes) ? (
                    <Message content={t("nenhuma.oportunidade.publicada")}></Message>
                ) : (
                    _.map(grupos, (grupo, data) => (
                        <div key={data} className="cor-padrao">
                            <Heading mb="7px" mt="30px!important" size="md">
                                {moment(data).format("LLLL")}
                            </Heading>
                            {_.map(grupo, (roteiro) => (
                                <OpportunitySegment
                                    key={roteiro.id}
                                    opportunity={roteiro}
                                    color={roteiro.statusRoteiro.id === 6 ? "yellow" : "green"}
                                    mode="agency"
                                />
                            ))}
                        </div>
                    ))
                )}
            </>
        );
    }
}

export default RoteiroConnect(withTranslation()(Pendentes));
