import { Box, Divider, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { OperationArea } from "../../@types/OperationArea";
import { useAuth } from "../../hooks/store/useAuth";
import { useBlocker } from "../../hooks/store/useBlocker";
import { OperationAreaService } from "../../services/OperationArea";
import { PATHS } from "../../Utils/Routes";
import { ActionOptionsMenu } from "../ActionOptionsMenu";
import { Card } from "../Card";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { Iconify } from "../Iconify";
import { IconifyIconButton } from "../IconifyIconButton";

interface CardOperationAreaProps {
	operationArea: OperationArea;
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Renderiza um `Card` com as informações de uma `OperationArea`.
 */
export function CardOperationArea({ operationArea }: CardOperationAreaProps) {
	const { t } = useTranslation();

	const {
		isOpen: isConfirmationDialogOpen,
		onClose: onConfirmationDialogClose,
		onOpen: onConfirmationDialogOpen,
	} = useDisclosure();

	const {
		state: { isDeleteOperationAreaBlocked },
		utils: { setBlocker },
	} = useBlocker();

	const { company } = useAuth();

	const history = useHistory();

	const queryClient = useQueryClient();

	function handleDeleteOperationArea() {
		return toast.promise(OperationAreaService.getInstance().deleteOne(operationArea._id), {
			pending: t("operationArea-delete-pending"),
			error: t("operationArea-delete-error"),
			success: t("operationArea-delete-success"),
		});
	}

	const { mutate: deleteOperationArea } = useMutation({
		mutationFn: handleDeleteOperationArea,
		onSuccess: () => {
			const cachedData = queryClient.getQueryData<OperationArea[]>([
				"operationAreas",
				company?.uid,
			]);

			//NOTE: Removendo da lista caso exista
			if (cachedData) {
				const filteredData = cachedData.filter(op => op._id !== operationArea._id);
				queryClient.setQueryData(["operationAreas", company?.uid], filteredData);
			}
		},
		onMutate: () => {
			setBlocker({ isDeleteOperationAreaBlocked: true });
		},
		onSettled: () => {
			setBlocker({ isDeleteOperationAreaBlocked: false });
		},
	});

	function handleEditOperationArea() {
		history.push(PATHS.areaDeAtuacao.editar(operationArea._id));
	}

	return (
		<Card
			borderTop={`5px solid ${operationArea.color}`}
			p={2}
			display="flex"
			flexDirection="column">
			<Flex
				as="header"
				justifyContent="space-between"
				alignItems="center"
				p={1}
				py={0}
				gap={2}>
				<Heading
					title={operationArea.name}
					fontSize={"lg"}
					overflow="hidden"
					whiteSpace="nowrap"
					textOverflow="ellipsis">
					{operationArea.name}
				</Heading>
				<ActionOptionsMenu
					placement="bottom-end"
					actions={[
						{ label: t("editar"), icon: "mdi:edit", handle: handleEditOperationArea },
						{
							label: t("excluir"),
							icon: "mdi:trash",
							handle: onConfirmationDialogOpen,
							isDisabled: isDeleteOperationAreaBlocked,
						},
					]}>
					<IconifyIconButton
						icon="mdi:dots-vertical"
						aria-label={t("mais-opcoes")}
						iconifyProps={{ boxSize: 6, color: operationArea.color }}
						variant="ghost"
						colorScheme="gray"
					/>
				</ActionOptionsMenu>
				<ConfirmationDialog
					isOpen={isConfirmationDialogOpen}
					onClose={onConfirmationDialogClose}
					onConfirm={deleteOperationArea}
					title={t("excluir-area-de-atuacao").concat("?")}
					message={
						<Box>
							<Text fontWeight={"bold"} color="red.400" fontSize="lg">
								<Iconify icon="mdi:error" inline /> {t("atencao")}
							</Text>
							<Text lineHeight={1.4} color="gray.700">
								{t("aviso-operacao-irreversivel")}
							</Text>
						</Box>
					}
					cancelButtonProps={{ autoFocus: true, variant: "solid" }}
					confirmButtonProps={{ variant: "outline" }}
				/>
			</Flex>
			<Divider mt={2} mb={4} />
			<Flex alignItems={"center"} gap={2} mb={4}>
				<Iconify
					color={operationArea.color}
					boxSize="10"
					textAlign={"right"}
					icon={
						operationArea.geometry.type === "Point"
							? "ph:circle-dashed-duotone"
							: "ph:polygon"
					}
				/>
				<Text fontWeight={"bold"}>
					{operationArea.geometry.type === "Point" ? "Circunferência" : "Polígono"}
				</Text>
			</Flex>
			<Flex as="main" shadow="inner" bg="gray.100" rounded="md" p={2} noOfLines={3} flex={1}>
				<Text>
					{operationArea.description || t("nenhuma-descricao-informada").concat("...")}
				</Text>
			</Flex>
		</Card>
	);
}
