import moment from 'moment';
import {
    backgroundStyle, textStyle, textUsuario, textItems,
    toInches
} from './Styles';


const informacoesDoRorteiro = (slide, roteiro, { info }, y) => {
    slide.addText(roteiro.loja.fantasia.toUpperCase(), { ...textStyle, bold: true, fontSize: 28, y: (y = 1.6), align: 'c' });
    slide.addText((`Por: ${roteiro.usuario.nome}`).toUpperCase(), { ...textUsuario, align: 'c', y: (y = 2) });
    const w = toInches(1156 * 0.5);
    const h = toInches(152 * 0.5);
    let x = ((10 - w) * 0.5);
    y = 0.3 + ((5.63 - h) * 0.5);
    slide.addImage({ data: info, x, y, w, h });
    y += h + 0.1;
    let textW = (w + 2.5);
    x = (10 - (textW - 1.5)) * 0.5;
    textW *= 0.3;
    const infoRoteiro = (slide, value, title, x, y, w) => {
        slide.addText(value,
                    { ...textItems, bold: true, align: 'c', x, y, w });
        slide.addText(title,
                    { ...textItems, align: 'c', x, y: y + 0.25, w });
    };
    infoRoteiro(slide, (`${moment(roteiro.data, "YYYY-MM-DD").format("DD/MM/YYYY")}`).toUpperCase(),
        (`Execução`).toUpperCase(),
        x + 0.1, y, (w * 0.3));

    infoRoteiro(slide, (`${roteiro.quantidadeAtividades}`).toUpperCase(),
        (`Atividades`).toUpperCase(),
        (x += textW), y, (w * 0.3));

    infoRoteiro(slide, (`${roteiro.quantidadeFoto}`).toUpperCase(),
            (`Imagens`).toUpperCase(),
        (x += textW), y, (w * 0.3));
    return y;
}

const frontPageAssets = (slide, { front }) =>
    slide.addImage({ ...backgroundStyle, data: front });

const frontPageHeader = (slide, loja, tipo, logo) => {
    slide.addImage({ data: logo, x: 0.1, y: 0.1, w: toInches(98), h: toInches(98) });
    slide.addText(loja,
                  { ...textStyle, x: 1.3, y: 0.15, w: 6.1, bold: true,
                    align: 'l', color: 'ffffff' });
    slide.addText(tipo,
                  { ...textStyle, x: 7.6, y: 0.25, w: 2.3, bold: true,
                    align: 'c', color: 'ffffff' });
};

const PaginaDeReposicao = (pptx, slide, roteiro, assets) => {
    let y = 1;
    frontPageAssets(slide, assets);
    frontPageHeader(slide,
        roteiro.loja.fantasia.toUpperCase(),
        `reposição`.toUpperCase(),
        assets.logo);
    informacoesDoRorteiro(slide, roteiro, assets, y);
};

const PaginaDeAbordagem = (pptx, slide, roteiro, assets) => {
    let y = 1;
    frontPageAssets(slide, assets);
    frontPageHeader(slide,
        roteiro.loja.fantasia.toUpperCase(),
        `Abordagem`.toUpperCase(),
        assets.logo);
    informacoesDoRorteiro(slide, roteiro, assets, y);
};

const PaginaDeDegustacao = (pptx, slide, roteiro, assets) => {
    let y = 1;
    frontPageAssets(slide, assets);
    frontPageHeader(slide,
        roteiro.loja.fantasia.toUpperCase(),
        `Degustação`.toUpperCase(),
        assets.logo);
    informacoesDoRorteiro(slide, roteiro, assets, y);
};

const frontPages = {
    "reposicao": PaginaDeReposicao,
    "abordagem": PaginaDeAbordagem,
    "degustacao": PaginaDeDegustacao
};

export const frontPage = (pptx, roteiro, assets) => {
    const slide = pptx.addNewSlide();
    frontPages[roteiro.tipo.descricao](pptx, slide, roteiro, assets);
    return pptx;
};