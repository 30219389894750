import moment from "moment";
import React, { Component } from "react";
import { Form, Header, Icon } from "semantic-ui-react";
import MensagemSalvo from "../../Componentes/MensagemSalvo";
import { db } from "../../config";

class FeedBack extends Component {
	state = {
		name: "",
		title: "",
		tipo: "",
		descricao: "",
		carregando: false,
		mensagem: false,
		dataHora: moment().format("DD/MM/YYYY HH:mm"),
	};

	handleName = e => {
		this.setState({ name: e.target.value });
	};

	handleTitle = e => {
		this.setState({ title: e.target.value });
	};

	handleTipo = e => {
		this.setState({ tipo: e.target.value });
	};

	handleDescription = (e, { value }) => {
		this.setState({ descricao: value });
	};

	handleVazio = e => {
		this.setState({
			name: "",
			title: "",
			tipo: "",
			descricao: "",
			mensagem: false,
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		this.setState({ carregando: true }, () => {
			return db
				.collection("feedback")
				.add({
					name: this.state.name,
					title: this.state.title,
					tipo: this.state.tipo,
					descricao: this.state.descricao,
					dataHora: this.state.dataHora,
					status: 0,
				})
				.then(this.setState({ carregando: false, mensagem: true }))
				.catch(err => {
					this.setState({ carregando: false, mensagem: false });
				});
		});
	};

	render() {
		const { t } = this.props;
		const { tipo, descricao, carregando } = this.state;

		if (this.state.mensagem) {
			return (
				<div className="novo-roteiro" style={{ margin: "0 auto", width: "680px" }}>
					<MensagemSalvo
						mensagem={t("feedback.enviado")}
						novoRegistro={() => this.handleVazio()}
						t={t}
					/>
				</div>
			);
		}

		return (
			<div className="div-feedback">
				<Form
					size="large"
					onSubmit={this.handleSubmit}
					style={{ paddingLeft: 12, width: 800 }}>
					<Header as="h1" style={{ paddingLeft: 14 }}>
						<Icon name="feed" />
						<Header.Content>{t("feedback")}</Header.Content>
					</Header>

					<Form.Group widths="equal">
						<Form.Field
							required
							label={t("nome")}
							control="input"
							value={this.state.name}
							onChange={this.handleName}
						/>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field
							required
							label={t("titulo")}
							control="input"
							value={this.state.title}
							onChange={this.handleTitle}
						/>
					</Form.Group>

					<Form.Group inline>
						<label style={{ paddingLeft: 14, fontSize: 18 }}>
							{t("tipo.feedback")}
						</label>
						<Form.Field
							required
							label="Bug"
							value="Bug"
							control="input"
							type="radio"
							name="htmlRadios"
							onChange={this.handleTipo}
						/>
						<Form.Field
							required
							label={t("sugestão")}
							value={t("sugestão")}
							control="input"
							type="radio"
							name="htmlRadios"
							onChange={this.handleTipo}
						/>
						<Form.Field
							required
							label={t("opinião")}
							value={t("opinião")}
							control="input"
							type="radio"
							name="htmlRadios"
							onChange={this.handleTipo}
						/>
					</Form.Group>

					<Form.TextArea
						rows={15}
						label={t("descricao")}
						placeholder={t("conte.mais")}
						value={descricao}
						onChange={this.handleDescription}
						required
					/>

					<Form.Button
						className="form-button"
						style={{ alignSelf: "center" }}
						size="big"
						basic
						color="violet"
						icon="feed"
						disabled={tipo === "" && descricao === ""}
						loading={carregando}>
						{t("enviar.feedback")}
					</Form.Button>
				</Form>
			</div>
		);
	}
}

export default FeedBack;
