import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { isString } from "lodash";
import { ReactNode, useEffect } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../hooks/useAppSelector";

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (_, query) => {
			if (query.meta?.errorMessage && isString(query.meta?.errorMessage)) {
				toast.error(query.meta.errorMessage);
			}
		},
	}),
});

interface ReactQueryProviderProps {
	children: ReactNode;
}

/**
 * Provider que fornece acesso aos recursos do `react-query`
 */
export function ReactQueryProvider({ children }: ReactQueryProviderProps) {
	const authState = useAppSelector(state => state.AutenticacaoReducer).autenticado as string;

	useEffect(() => {
		if (authState !== "LOGADO") {
			queryClient.clear();
		}
	}, [authState]);

	return (
		<QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
		</QueryClientProvider>
	);
}
