import { Collapse } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";
import { ITopUpFormData } from "..";
import { PaymentMethodSelected } from "../SelectedPaymentMethod";
import { CollapseWrapper, Container, OptionButton, OptionIcon, OptionsWrapper } from "./styles";

export function SelectedPaymentMethod() {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext<ITopUpFormData>();

    const currentPaymentMethod = watch("paymentMethod");

    function handleSelectPaymentMethod(data: ITopUpFormData["paymentMethod"]) {
        setValue("paymentMethod", data, { shouldDirty: true });
    }

    return (
        <Container>
            <Header as="h2">2 - {t("escolha-o-metodo-de-pagamento")}</Header>
            <OptionsWrapper>
                <OptionButton
                    basic={currentPaymentMethod !== "bank_slip"}
                    onClick={() => handleSelectPaymentMethod("bank_slip")}
                >
                    <OptionIcon icon="ic:outline-barcode" />
                    {t("bankSlip")}
                </OptionButton>
                <OptionButton basic={currentPaymentMethod !== "pix"} onClick={() => handleSelectPaymentMethod("pix")}>
                    <OptionIcon icon="ic:baseline-pix" />
                    {t("pix")}
                </OptionButton>
            </OptionsWrapper>
            <CollapseWrapper>
                <Collapse in={!!currentPaymentMethod}>
                    <Header as="h2">3 - {t("realize.pagamento")}</Header>
                    <PaymentMethodSelected />
                </Collapse>
            </CollapseWrapper>
        </Container>
    );
}
