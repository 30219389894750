import { max } from "lodash";
import { OperationNetworkInCompany } from "../../@types/Company";
import { Opportunity } from "../../@types/Opportunity";
import { MarkOptional } from "ts-essentials";

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Função que determina e retorna a rede que mais combina com o PDV passado.
 * @param pdv PDV (loja) que será usado
 * @param operationNetworks Redes de atuação que podem combinar com o PDV.
 * @returns Retorna `null` se nenhuma rede combinar com o PDV ou retorna a rede que combina o melhor com o PDV.
 * @obs Se o PDV já tiver uma rede associada manualmente, ela será retornada.
 */
export function getBestMatchOperationNetwork(
	pdv: Pick<Opportunity["loja"], "rede" | "razaoSocial" | "fantasia">,
	operationNetworks: OperationNetworkInCompany[]
): MarkOptional<OperationNetworkInCompany, "palavrasChave"> | null {
	if (pdv.rede) {
		return pdv.rede;
	}

	if (!operationNetworks.length) return null;

	/**
	 * Uma string completa contendo a razão social e fantasia do PDV
	 */
	const pdvKeywords = String(pdv.razaoSocial + " " + pdv.fantasia).toLowerCase();
	/**
	 * Um array contendo a quantidade de matches que cada rede teve com o pdv.
	 */
	const matchArray: { network: OperationNetworkInCompany; matches: number }[] = [];

	//NOTE: Percorremos cada rede e contamos a quantidade de vezes que ela combinou com um pdv.
	operationNetworks.forEach(network => {
		const networkMarchResult = network.palavrasChave.reduce(
			(acc, current) => {
				const match = pdvKeywords.includes(current.toLowerCase());
				if (match) {
					acc.matches += 1;
				}

				return acc;
			},
			{ network: network, matches: 0 }
		);
		networkMarchResult.matches > 0 && matchArray.push(networkMarchResult);
	});

	const maxMatch = max(matchArray.map(m => m.matches));

	const networksToBreakADraw = matchArray.filter(m => m.matches === maxMatch);

	if (networksToBreakADraw.length === 1) {
		return networksToBreakADraw[0].network;
	} else {
		return null;
	}
}
