import { useInfiniteQuery } from "@tanstack/react-query";
import { forEach, groupBy } from "lodash";
import { useMemo } from "react";
import { Opportunity } from "../../@types/Opportunity";
import { Receipt, ReceiptStatus } from "../../@types/Receipt";
import { mongoDBAtlas } from "../../services/Api";
import { useAuth } from "../store/useAuth";
import { useAppSelector } from "../useAppSelector";

export type PendingReceipt = {
	customerName: string;
	customerCnpj: string;
	customerEmail: string;
	isTradePro: boolean;
	opportunities: Opportunity[];
};

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Hook para acesso as notas ficais pendentes de envio para a Tradepro.
 */
export function usePendingReceipts() {
	const opportunitiesWithPendingReceipt = useAppSelector(
		state => state.RecursosReducer?.notasPendentes || []
	) as Opportunity[];

	/**
	 * Notas fiscais pendentes de envio
	 */
	const pendingReceipts = useMemo(() => {
		//NOTE: Agrupa as oportunidades por cnpj e filtra as que não tem faturamento anual
		const pendingReceiptsGrouped = groupBy(
			opportunitiesWithPendingReceipt
				.filter(op => !op.pagamento.faturamentoMensal)
				.slice(0, 499), //NOTE: Permite somente o máximo de 499 itens
			"cnpjEmpresa"
		);

		//NOTE: Cria o objeto que vai ser retornado e como primeiro item, colocamos a Tradepro como recebedora da nota para oportunidades com faturamento mensal.
		const mappedReceipts: PendingReceipt[] = [
			{
				customerCnpj: "13735269000168",
				customerEmail: "TRADEPRO TECNOLOGIA LTDA",
				customerName: "contato@tradepro.com.br",
				isTradePro: true,
				opportunities: opportunitiesWithPendingReceipt
					.filter(op => op.pagamento.faturamentoMensal)
					.slice(0, 499), //NOTE: Permite somente o máximo de 499 itens
			},
		];

		//NOTE: Para cada oportunidade agrupada, criar uma nota fiscal com os dados mínimos da empresa recebedora.
		forEach(pendingReceiptsGrouped, (op, cnpj) => {
			mappedReceipts.push({
				customerCnpj: cnpj,
				customerEmail: op[0].emailEmpresa,
				customerName: op[0].fantasiaEmpresa,
				isTradePro: false,
				opportunities: op,
			});
		});

		return mappedReceipts.filter(receipt => receipt.opportunities.length > 0);
	}, [opportunitiesWithPendingReceipt]);

	return pendingReceipts;
}

interface UseReceiptsProps {
	status: ReceiptStatus;
	pageSize?: number;
	staleTime?: number;
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Hook para acesso as notas ficais dependendo do status passado.
 */
export function useReceipts({ status, pageSize = 10, staleTime }: UseReceiptsProps) {
	const { company } = useAuth();

	interface ReceiptsApiResponse {
		data: Receipt[];
		nextPageToken?: number;
		totalItems: number;
	}

	const queryResult = useInfiniteQuery<ReceiptsApiResponse, Error>({
		queryKey: ["receipts", company?.uid, status, pageSize],
		queryFn: async ({ pageParam = 1 }) => {
			//NOTE: Calcula quantos resultados são necessários filtrar
			const skips = pageSize * ((pageParam || 1) - 1);

			let orderPipeline: { $sort: { [key: string]: number } } = { $sort: { createdAt: -1 } };

			//NOTE: Se o status for diferente de enviado para análise, ordenamos por data de análise e não por data de criação
			if (status !== ReceiptStatus["Enviado para análise"]) {
				orderPipeline.$sort = { analyzedAt: -1 };
			}

			//NOTE: Monta o pipeline para buscar as notas
			const pipeline = [
				{
					//NOTE: Filtra por empresa que enviou e pelo status passado.
					$match: {
						"sender.id": company?.uid,
						status: status,
					},
				},
				//NOTE: Ordena pela mais atual
				{ ...orderPipeline },
				//NOTE: Remove um campo sigiloso
				{
					$unset: "analyzedBy",
				},
				{
					//NOTE: Divide a operação pra sabermos quantos resultados temos e quais são eles
					$facet: {
						total: [{ $count: "count" }],
						docs: [{ $skip: skips }, { $limit: pageSize }],
					},
				},
				{
					//NOTE: Melhora a visualização final
					$project: {
						total: { $arrayElemAt: ["$total.count", 0] },
						docs: 1,
					},
				},
			];

			const { documents } = await mongoDBAtlas<{
				total: number;
				docs: Receipt[];
			}>("POST", "notasFiscais", "/action/aggregate", {
				pipeline,
			});

			const apiResult = documents[0];

			//NOTE: Calcula quantas paginas de resultado temos.
			const totalPages = Math.ceil(apiResult.total / pageSize);

			//NOTE: Retorna o resultado
			return {
				data: apiResult.docs,
				totalItems: apiResult.total,
				//NOTE: Se estivermos na última página, não iremos retornar a referência para a próxima.
				nextPageToken: totalPages > pageParam ? pageParam + 1 : undefined,
			};
		},
		getNextPageParam: lastPage => lastPage.nextPageToken,
		staleTime: staleTime || 1000 * 60 * 5,
		keepPreviousData: true,
	});

	const total = useMemo(() => {
		return queryResult.data?.pages[0]?.totalItems || 0;
	}, [queryResult.data?.pages]);

	return { ...queryResult, total };
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Hook que retorna todas as variantes de notas ficais buscadas na base de dados.
 */
export function useAllReceiptsStatus() {
	const pendingReceipts = usePendingReceipts();

	const acceptedReceipts = useReceipts({ status: ReceiptStatus.Aceita });

	const underAnalysisReceipts = useReceipts({ status: ReceiptStatus["Enviado para análise"] });

	const rejectedReceipts = useReceipts({ status: ReceiptStatus.Recusada });

	const totals = useMemo(() => {
		return {
			pendingReceipts: pendingReceipts.length,
			acceptedReceipts: acceptedReceipts.total,
			underAnalysisReceipts: underAnalysisReceipts.total,
			rejectedReceipts: rejectedReceipts.total,
		};
	}, [
		acceptedReceipts.total,
		pendingReceipts.length,
		rejectedReceipts.total,
		underAnalysisReceipts.total,
	]);

	return { pendingReceipts, underAnalysisReceipts, acceptedReceipts, rejectedReceipts, totals };
}
