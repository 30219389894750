import { combineReducers, applyMiddleware, createStore, AnyAction } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import ReduxThunk, { ThunkDispatch } from "redux-thunk";
import AutenticacaoReducer from "./AutenticacaoReducer";
import PagamentoReducer from "./PagamentoReducer";
import RecursosReducer from "./RecursosReducer";
import RoteiroReducer from "./RoteiroReducer";
import TransacoesReducer from "./TransacoesReducer";
import RelatoriosReducer from "./RelatoriosReducer";
import DashboardReducer from "./DashboardReducer";
import GastoMensalReducer from "./GastoMensalReducer";
import RedeReducer from "./Rede/reducer";
import NegotiationReducer from "./Negotiation/reducer";
import BlockerReducer from "../store/slices/blockerSlice";

const reducers = combineReducers({
    AutenticacaoReducer,
    PagamentoReducer,
    RecursosReducer,
    RoteiroReducer,
    TransacoesReducer,
    RelatoriosReducer,
    DashboardReducer,
    GastoMensalReducer,
    RedeReducer,
    NegotiationReducer,
    blocker: BlockerReducer,
});

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(ReduxThunk)));

export type AppDispatch = ThunkDispatch<ReturnType<typeof reducers>, undefined, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;
