import React from 'react';
import { Modal, Message, Button } from 'semantic-ui-react';


export default props => (
    <Modal open={props.open}
        style={{
            width: 500, height: "auto",
            left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}
        closeOnEscape>
        <Modal.Header>{props.t('nome.do.modelo')}</Modal.Header>
        <Modal.Content>
            <div className="ui input fluid">
                <input
                    className="input-border-color input-radius"
                    type="text"
                    nome="modelo"
                    value={props.nomeDoModelo}
                    onChange={props.handleChangeNomeDoModelo} />
            </div>
            {props.validacao && (
                <Message negative>
                    <Message.Header>{props.t('problema.no.registro')}</Message.Header>
                    <p>{props.validacao}</p>
                </Message>
            )}
            <div style={{ marginTop: 30, textAlign: "center" }}>
                <Button type="button" className="voltar-botao" content={props.t('cancelar')}
                    icon="left arrow" color="red"
                    onClick={props.onCancel}></Button>
                <Button
                    type="button"
                    className="green-gradient-light"
                    content={props.t('pronto')}
                    onClick={props.onSubmit}></Button>
            </div>
        </Modal.Content>
    </Modal>
);
