import { Button, Dialog, DialogActions, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Circle, TileLayer } from "react-leaflet";
import { useChakraUIBreakpoint } from "../../hooks/useChakraUIBreakpoint";
import { Content, MapIcon, OpenStreetMapContainer } from "./styles";

interface ModalShowBasicMapProps {
	lat: number;
	lng: number;
}

export function ModalShowBasicMap({ lat, lng }: ModalShowBasicMapProps) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const { reachedBreakpoint: isBase } = useChakraUIBreakpoint("base");

	function handleCloseDialog() {
		setOpen(false);
	}

	function handleOpenDialog() {
		setOpen(true);
	}

	return (
		<>
			<Tooltip title={t("localizar-no-mapa")} arrow>
				<MapIcon icon="mdi:map-search" inline onClick={handleOpenDialog} />
			</Tooltip>
			<Dialog
				onClose={handleCloseDialog}
				aria-labelledby="dialog-map"
				open={open}
				fullWidth
				fullScreen={isBase}
				maxWidth="lg">
				<Content>
					<OpenStreetMapContainer center={[lat, lng]} zoom={isBase ? 16 : 17}>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
						<Circle
							center={[lat, lng]}
							pathOptions={{ fillColor: "purple", color: "purple" }}
							radius={315}
						/>
					</OpenStreetMapContainer>
				</Content>
				<DialogActions>
					<Button variant="contained" color="primary" onClick={handleCloseDialog}>
						{t("fechar")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
