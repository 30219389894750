import { Button, ButtonProps } from "semantic-ui-react";
import styled, { css } from "styled-components";

interface ContainerProps {
	autoSize: boolean;
	borderRadius: number;
}

export const Container = styled.div<ContainerProps>`
	width: 150px;
	height: 150px;
	position: relative;

	${({ autoSize }) =>
		autoSize &&
		css`
			width: 100%;
			height: 100%;
		`}

	${({ borderRadius }) =>
		borderRadius > 0 &&
		css`
			border-radius: ${borderRadius}px;
		`}
`;

export const InputFile = styled.input.attrs({ type: "file" })`
	display: none;
`;

export const ButtonChangePhoto: React.FC<ButtonProps> = styled(Button)`
	position: absolute !important;
	background-color: #7501c0 !important;
	color: white !important;
	top: -20px;
	right: -20px;

	transition: filter 0.3s !important;

	&:hover {
		filter: opacity(0.9);
	}
`;
