import { Icon } from "@iconify/react";
import { Grid, IconButton, IconButtonProps, TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { debounce } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

export interface StatusType {
	value: string;
	name: string;
}

interface LogListFilterProps {
	isFetching: boolean;
	setFilterText: Dispatch<SetStateAction<string>>;

	dateFilter: ParsableDate;
	setDateFilter: Dispatch<SetStateAction<ParsableDate>>;

	statusFilter: StatusType[];
	setStatusFilter: Dispatch<SetStateAction<StatusType[]>>;
}

export function LogListFilter({
	isFetching,
	setFilterText,
	dateFilter,
	setDateFilter,
	setStatusFilter,
	statusFilter,
}: LogListFilterProps) {
	const { t } = useTranslation();

	const handleDebouceChangeFilter = debounce(setFilterText, 1500);

	const handleClearDateFilter: IconButtonProps["onClick"] = e => {
		e.preventDefault();
		e.stopPropagation();
		setDateFilter(null);
	};

	const handleChangeStatusFilter: AutocompleteProps<
		StatusType,
		true,
		false,
		false
	>["onChange"] = (_, value) => {
		setStatusFilter(value);
	};

	const status: StatusType[] = [
		{ value: "CREATE", name: t("CREATE") },
		{ value: "UPDATE", name: t("UPDATE") },
		{ value: "DELETE", name: t("DELETE") },
	];

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<TextField
					label={t("busca-geral")}
					fullWidth
					variant="outlined"
					disabled={isFetching}
					placeholder={t("digite-um-termo-para-pesquisar-nos-logs")}
					onChange={e => handleDebouceChangeFilter(e.target.value)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<DatePicker
					label={t("data")}
					fullWidth
					disabled={isFetching}
					InputProps={{
						endAdornment: dateFilter ? (
							<IconButton aria-label="clear-date" onClick={handleClearDateFilter}>
								<Icon icon="mdi:close" />
							</IconButton>
						) : undefined,
					}}
					inputVariant="outlined"
					format="DD/MM/YYYY"
					variant="inline"
					placeholder={t("filtro-por-data-do-log")}
					value={dateFilter}
					onChange={setDateFilter}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Autocomplete
					multiple
					fullWidth
					disabled={isFetching}
					value={statusFilter}
					onChange={handleChangeStatusFilter}
					options={status}
					getOptionLabel={option => option.name}
					filterSelectedOptions
					getOptionSelected={(option, value) => option.value === value.value}
					renderInput={params => (
						<TextField {...params} variant="outlined" label={t("status")} />
					)}
				/>
			</Grid>
		</Grid>
	);
}
