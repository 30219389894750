import axios from "axios";
import firebase from "firebase/app";
import "firebase/firebase-auth";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import Currency from "react-currency-formatter";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Checkbox, Grid, Header, Icon, Input, Message, Modal } from "semantic-ui-react";
import { v4 } from "uuid";
import { buscaProdutos } from "../../_nodeApi/produtos";

const secaoNova = () => ({
    id: v4(),
    nome: "Nova Seção",
    produtos: [],
    atividades: [],
});

const Produto = (props) => {
    const produto = props.produto;
    return (
        <div className="produto-secao" onClick={props.onClick(produto)}>
            <div className="nome">{produto.descricao}</div>
            <div className="sub">
                <span>{produto.codigo}</span>
                {produto.preco && (
                    <span>
                        <Currency quantity={produto.preco} locale="pt_BR" decimal="," group="." currency="R$" />
                    </span>
                )}
            </div>
        </div>
    );
};

class ModalSecao extends Component {
    state = {
        secao: { produtos: [] },
        retornoProdutos: [],
    };

    /**
     * @name buscarProdutos
     * @date Criado em 25/11/2020
     * @author diego jimenes <contato.diegojimenes@gmail.com>
     * @description busca produtos em nossa api node com mongo
     */
    buscarProdutos = () => {
        let valor = this.state.buscaProdutos;
        if (valor && valor.length >= 3) {
            this.setState(
                {
                    loadingProdutos: true,
                },
                () => {
                    buscaProdutos(this.props.empresa.uid, valor, axios, firebase).then((result) => {
                        this.setState({
                            retornoProdutos: [result.data],
                            loadingProdutos: false,
                        });
                    });
                }
            );
        }
    };

    componentDidMount() {
        let novaSecao = secaoNova();
        if (this.props.roteiro.tipo.id !== 4) {
            novaSecao.atividades = [...novaSecao.atividades, 6];
        }
        this.setState({ secao: this.props.secao ? this.props.secao : novaSecao });
    }

    handleCheckBox = (id) => (e) => {
        const _atividades = [...this.state.secao.atividades];
        if (!_.includes(_atividades, id)) {
            _atividades.push(id);
        } else {
            _.pull(_atividades, id);
        }
        this.setState({ secao: { ...this.state.secao, atividades: _atividades } });
    };

    changeSecao = (e) =>
        this.setState({
            secao: { ...this.state.secao, [e.target.name]: e.target.value },
        });
    changeState = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.key === "Enter") {
            this.buscarProdutos();
        }
    };

    /**
     * @name changeProduto
     * @date Criado em 25/11/2020
     * @author diego jimenes <contato.diegojimenes@gmail.com>
     * @description faz a seleção e remoção de produtos
     */
    changeProduto = (produto) => () => {
        const _produtos = [...this.state.secao.produtos];
        if (!_.find(_produtos, { _id: produto._id })) {
            delete produto.arquivado;
            delete produto.__v;
            if (produto._id !== undefined) {
                produto.id = produto._id;
            }
            _produtos.push(produto);
        } else {
            _.pull(_produtos, produto);
        }
        this.setState({ secao: { ...this.state.secao, produtos: _produtos } });
    };

    closeComponent() {
        var container = ReactDOM.findDOMNode(this).parentNode;
        ReactDOM.unmountComponentAtNode(container);
    }

    salvar = () => {
        const { secao } = this.state;
        const { t, salvar } = this.props;
        if (_.isEmpty(secao.atividades)) {
            return this.setState({ erro: t("secao.sem.atividades") });
        }
        if (_.isEmpty(secao.produtos)) {
            return this.setState({ erro: t("secao.sem.produtos") });
        }
        salvar(secao)();
    };

    render() {
        const { secao, editarNome, retornoProdutos, loadingProdutos, erro } = this.state;
        const { open, t, cancelar, roteiro } = this.props;
        return (
            <Modal
                open={open}
                style={{ maxWidth: "1000px", maxHeight: "80vh", overflow: "auto" }}
                className="modal-secoes"
            >
                <Modal.Header className="header-secao">
                    {editarNome ? (
                        <div className="div-header">
                            <Input name="nome" value={secao.nome} onChange={this.changeSecao} />
                            <span className="botao-nome" onClick={() => this.setState({ editarNome: false })}>
                                <Icon name="check" size="tiny" color="green" />
                            </span>
                        </div>
                    ) : (
                        <div className="div-header">
                            {secao.nome}
                            <span className="botao-nome" onClick={() => this.setState({ editarNome: true })}>
                                <Icon name="pencil" size="tiny" color="grey" />
                            </span>
                        </div>
                    )}
                </Modal.Header>
                <Modal.Content>
                    <Grid stackable>
                        <Grid.Column width={16}>
                            <Header>{t("atividades")}</Header>
                            <div className="checkboxes">
                                {/* this.handleCheckBox(6) */}
                                {roteiro.tipo.id !== 4 ? (
                                    <span>
                                        <Checkbox
                                            checked={_.includes(secao.atividades, 6)}
                                            onChange={() => {}}
                                            label={t("reposicao")}
                                        />
                                    </span>
                                ) : null}
                                <Checkbox
                                    checked={_.includes(secao.atividades, 1)}
                                    onChange={this.handleCheckBox(1)}
                                    label={roteiro.tipo.id === 4 ? t("foto") : t("foto.antes.depois")}
                                />
                                <Checkbox
                                    checked={_.includes(secao.atividades, 3)}
                                    onChange={this.handleCheckBox(3)}
                                    label={t("pesquisa.de.preco")}
                                />
                                <Checkbox
                                    checked={_.includes(secao.atividades, 2)}
                                    onChange={this.handleCheckBox(2)}
                                    label={t("pesquisa.de.validade")}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16} tablet={16}>
                            <Header>{t("produtos")}</Header>
                            <div className="lista-produtos">
                                {secao.produtos?.map((produto, index) => (
                                    <Fragment key={index}>
                                        <Produto produto={produto} onClick={this.changeProduto} />
                                    </Fragment>
                                ))}
                                {_.isEmpty(secao.produtos) && (
                                    <div className="sem-produtos">
                                        <Icon name="boxes" size="huge" />
                                        {t("nenhum.produto.adicionado")}
                                        <span>{t("utilize.busca.ao.lado.produtos")}</span>
                                    </div>
                                )}
                            </div>
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16} tablet={16}>
                            <Header>{t("adicionar.produtos")}</Header>
                            <Input
                                action={
                                    <Button
                                        button
                                        basic
                                        floating
                                        content={t("buscar")}
                                        type="button"
                                        onClick={this.buscarProdutos}
                                        disabled={loadingProdutos}
                                        loading={loadingProdutos}
                                    />
                                }
                                icon="search"
                                iconPosition="left"
                                placeholder={t("busque.produtos")}
                                size="large"
                                onKeyUp={this.changeState}
                                //onChange={this.changeState}
                                name="buscaProdutos"
                                fluid
                            />
                            {/*Verifica se caso tem produtos para mostrar, se tiver segue normalmente, se não avisa que não joga a mensagem que nao tem produto */}
                            <br />
                            <div className="adicionar-produtos">
                                {retornoProdutos.map((produtos) => {
                                    if (produtos.length > 0) {
                                        return produtos.map((produto) => {
                                            return (
                                                !_.includes(secao.produtos, produto) && (
                                                    <Produto produto={produto} onClick={this.changeProduto} />
                                                )
                                            );
                                        });
                                    } else if (produtos.length === 0) {
                                        return (
                                            <div className="div-header right" style={{ marginTop: "5px" }}>
                                                <Message content={t("produto.nao.encontrado")} color="red" />
                                            </div>
                                        );
                                    } else {
                                        return <></>;
                                    }
                                })}
                            </div>
                        </Grid.Column>
                        <Grid.Column width="8" display={{ display: "flex" }}>
                            {erro && (
                                <div className="div-header right">
                                    <Message content={erro} icon="info" color="red" />
                                </div>
                            )}
                        </Grid.Column>
                        <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            className="botoes-secao"
                            style={{
                                position: "sticky",
                                bottom: 0,
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button content={t("cancelar")} basic onClick={cancelar} color="red" />
                            <Button content={t("salvar")} icon="check" color="green" onClick={this.salvar} />
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    empresa: state.AutenticacaoReducer.empresa,
});

export default connect(mapStateToProps)(withTranslation()(ModalSecao));
