import Cleave from "cleave.js";
import $ from "jquery";
import _ from "lodash";
import React from "react";
import { Button, Divider, Form, Grid, Header, Label, Segment } from "semantic-ui-react";
import Link from "../../Componentes/Link";
import Mapa from "../../Componentes/Mapa";
import { cnpjRegex, formatCNPJ } from "../../Utils/CNPJ";
import { lojaSearchBoxString } from "../../Utils/Loja";
import firebase from "firebase";
import { z } from "zod";
import { Text } from "@chakra-ui/react";
import { Tip } from "../../Componentes/Tip";

const NovaLojaFormButtons = ({ t, carregando }) => {
	return (
		<div style={{ display: "table", width: "100%", marginTop: "1em" }}>
			<Button
				loading={carregando}
				content={t("salvar")}
				disabled={carregando}
				icon="check"
				color="green"
				floated="right"></Button>
			<Button
				as={Link}
				to="/loja"
				content={t("cancelar")}
				icon="left arrow"
				color="red"
				floated="right"
				disabled={carregando}></Button>
		</div>
	);
};

export class NovaLojaForm extends React.Component {
	state = {
		redes: [],
	};

	componentDidMount() {
		this.inputCNPJ = new Cleave($("#codigo"), {
			blocks: [2, 3, 3, 4, 2],
			delimiters: [".", ".", "/", "-"],
		});
		this.buscarRedes();
	}

	componentWillUnmount() {
		this.inputCNPJ = null;
	}

	updateState = state => this.props.scope.setState(state);
	scopeState = () => this.props.scope.state;

	handleChange = event => {
		this.updateState({
			[event.target.name]: event.target.value,
		});
	};

	handleChangeLoja = event => {
		this.updateState({
			loja: {
				...this.scopeState().loja,
				[event.target.name]: event.target.value,
			},
		});
	};

	handleChangeRede = (_, data) => {
		const dadoASerSetadoNoEstado = {
			id: "",
			nome: "",
		};

		if (data.value) {
			const { value } = data;
			const redeEncontrada = data.options.find(o => o.key === value);

			if (redeEncontrada) {
				dadoASerSetadoNoEstado.id = redeEncontrada.key;
				dadoASerSetadoNoEstado.nome = redeEncontrada.text;
			}
		}

		this.updateState({
			loja: {
				...this.scopeState().loja,
				rede: dadoASerSetadoNoEstado,
			},
		});
	};

	buscarRedes = async () => {
		try {
			await firebase
				.firestore()
				.collection("redes")
				.get()
				.then(snap => {
					var redes = [];
					snap.forEach(doc => {
						redes.push(doc.data());
					});

					this.setState({ redes: redes });
				});
		} catch (error) {
			console.log(error);
		}
	};

	handleChangeLojaCNPJOnBlur = event => {
		const value = event.target.value.replace(/[^0-9]/g, "");
		let error = null;
		if (!cnpjRegex.test(value)) {
			error = "CNPJ inválido.";
		}
		this.updateState({
			loja: { ...this.scopeState().loja, codigo: value },
			erroCodigo: error,
		});
	};

	handleChangeLojaContato = event => {
		const loja = this.scopeState().loja;
		this.updateState({
			loja: {
				...loja,
				contato: {
					...loja.contato,
					[event.target.name]: event.target.value,
				},
			},
		});
	};

	handleChangeLocation = event => {
		const { loja } = event;
		this.updateState({
			searchBox: lojaSearchBoxString(loja),
			loja: { ...this.scopeState().loja, ...loja },
		});
	};

	RedesCadastradas = redes => {
		const redesOrdenadas = _.reduce(
			redes,
			(res, val, key) => [...res, { key: val.id, value: val.id, text: val.nome }],
			[]
		);
		return redesOrdenadas.sort(function (a, b) {
			if (a.key > b.key) {
				return 1;
			}
			if (a.key < b.key) {
				return -1;
			}
			return 0;
		});
	};

	render() {
		const { t, loja, onSubmit, carregando, searchBox, location, editando, erroCodigo } =
			this.props;

		//NOTE: garante que a rede selecionada será nulo ou uma rede com valor para ser usada no seletor
		const redeSchema = z
			.object({
				id: z.string(),
				nome: z.string(),
			})
			.default("")
			.transform(rede => rede.id || "");

		const redeSelecionada = redeSchema.parse(loja?.rede);

		return (
			<Form onSubmit={onSubmit}>
				<Grid stackable>
					<Grid.Column style={{ marginTop: 5 }}>
						<Segment color="violet">
							<Header
								as="h3"
								icon="map signs"
								content={t("endereco")}
								subheader={t("voce.precisa.selecionar.mapa")}
							/>
							<Divider />
							<Form.Group widths="equal">
								<Form.Input
									loading={carregando}
									icon="search"
									id="searchBox"
									name="searchBox"
									value={searchBox}
									onChange={this.handleChange.bind(this)}
									fluid
									label={t("pesquisar.mapa")}
									placeholder={t("digite.aqui.busca.mapa")}
								/>
							</Form.Group>
							<div
								style={{
									width: "100%",
									height: "260px",
									position: "relative",
									marginBottom: "20px",
								}}>
								<Mapa
									endereco={this.props.empresa.endereco}
									location={location}
									customMarker={_.get(loja, ["place_id"], "")}
									onClickLocation={this.handleChangeLocation.bind(this)}
									searchBoxInput={document.getElementById("searchBox")}></Mapa>
							</div>
							<Form.Group widths="equal">
								<Form.Field>
									<Form.Input
										fluid
										required
										loading={carregando}
										value={_.get(loja, "endereco.endereco", "")}
										icon="map marker alternate"
										iconPosition="left"
										label={t("endereco")}
										placeholder={t("aguardando.selecao.mapa")}
									/>
								</Form.Field>
							</Form.Group>
						</Segment>
						<Segment color="violet">
							<Header
								as="h3"
								icon="id badge"
								content={t("identificacao")}
								subheader={t("identificacao.loja")}
							/>
							<Divider />
							<Form.Group widths="equal">
								<Form.Field>
									<Form.Input
										fluid
										loading={carregando}
										onChange={this.handleChangeLoja.bind(this)}
										onBlur={this.handleChangeLojaCNPJOnBlur.bind(this)}
										id="codigo"
										name="codigo"
										value={formatCNPJ(_.get(loja, ["codigo"], ""))}
										label={t("cnpj")}
										disabled={editando}
										required
										placeholder="000001"
									/>
									{erroCodigo && (
										<Label basic color="red" pointing>
											{erroCodigo}
										</Label>
									)}
								</Form.Field>
								<Form.Input
									fluid
									loading={carregando}
									onChange={this.handleChangeLoja.bind(this)}
									name="fantasia"
									value={_.get(loja, "fantasia")}
									label={t("nome")}
									required
									placeholder="Supermercado Exemplo"
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Input
									fluid
									loading={carregando}
									onChange={this.handleChangeLoja.bind(this)}
									name="razaoSocial"
									value={_.get(loja, "razaoSocial")}
									label={t("razao.social")}
									placeholder="Supermercado Exemplo LTDA"
								/>
							</Form.Group>
						</Segment>
						<Segment color="violet">
							<Header
								as="h3"
								icon="id badge"
								content={
									<Tip label={t("dica-associacao-de-pdv-a-rede")}>
										{t("redes")}
									</Tip>
								}
								subheader={t("redes.cadastradas")}
							/>
							<Divider />

							<Form.Group widths="equal">
								<Form.Select
									fluid
									loading={carregando}
									onChange={this.handleChangeRede.bind(this)}
									name="rede"
									value={redeSelecionada}
									options={this.RedesCadastradas(this.state.redes)}
									label={t("selecione.uma.rede")}
									placeholder="Selecione"
									clearable
								/>
							</Form.Group>
							<Text color="orange.400" lineHeight={1.2} fontWeight="medium">
								• {t("aviso-importancia-associar-pdv-a-rede")}
							</Text>
						</Segment>
						<Segment color="violet">
							<Header
								as="h3"
								icon="mail"
								content={t("contato")}
								subheader={t("informacoes.contato")}
							/>
							<Divider />
							<Form.Group widths="equal">
								<Form.Input
									fluid
									loading={carregando}
									onChange={this.handleChangeLojaContato.bind(this)}
									name="nome"
									value={_.get(loja, "contato.nome")}
									label={t("nome.do.contato")}
									placeholder="João da Silva"
								/>
								<Form.Input
									fluid
									loading={carregando}
									onChange={this.handleChangeLojaContato.bind(this)}
									type="tel"
									name="telefone"
									value={_.get(loja, "contato.telefone", "")}
									label={t("telefone")}
									placeholder="(55) 5555-5555"
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Input
									fluid
									loading={carregando}
									onChange={this.handleChangeLojaContato.bind(this)}
									type="email"
									name="email"
									value={_.get(loja, "contato.email")}
									label={t("email")}
									placeholder="exemplo@email.com"
								/>
							</Form.Group>
						</Segment>
					</Grid.Column>
				</Grid>
				<NovaLojaFormButtons t={t} disabled={carregando} carregando={carregando} />
				{this.props.children}
			</Form>
		);
	}
}
