import { Rating as RatingSemantic, RatingProps as RatingSemanticProps } from "semantic-ui-react";
import styled from "styled-components";
import ImagemCentralizada from "../../../../Componentes/ImagemCentralizada";
import background from "../../../../imagens/bg-menu.jpg";

export const Container = styled.div`
    width: 100%;
    height: 350px;
    background-color: white;
    padding: 0 !important;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    position: relative;
`;

export const BackgroundImage = styled.div`
    flex: 1;
    display: flex;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: 100% 150%;
    overflow: hidden;
`;

export const BackgroundLayer = styled.div`
    flex: 1;
    display: flex;
    background-color: rgb(79, 30, 99, 0.5);

    @media (max-width: 775px) {
        align-items: center;
        justify-content: center;
    }
`;

export const PromotorInfo = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-start;
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    gap: 2rem;

    @media (max-width: 775px) {
        flex-direction: column;
        position: inherit;
        gap: 0;
        align-items: center;
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;

    @media (max-width: 775px) {
        justify-content: center;
    }
`;

export const ButtonArea = styled.div`
    z-index: 1;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
`;

export const AvatarArea = styled.div`
    width: 130px;

    * {
        border: 3px solid white;
    }

    @media (max-width: 775px) {
        width: 100px;
    }
`;

export const BasicInfo = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 0.1;

    @media (max-width: 775px) {
        align-items: center;
    }
`;

export const TextPrincipal = styled.h1`
    margin: 0;
    font-weight: bold;
    font-size: 2rem;
    color: white;

    @media (max-width: 775px) {
        font-size: 1.5rem;
    }
`;

export const TextSecondary = styled.h2`
    margin: 0;
    font-weight: 100;
    font-size: 1.5rem;
    color: white;

    @media (max-width: 775px) {
        font-size: 1rem;
    }
`;

export const RatingCard = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
`;

export const Rating: React.FC<RatingSemanticProps> = styled(RatingSemantic).attrs({ maxRating: 5, disabled: true })``;

export const Text = styled.p`
    margin: 0;
    padding: 0;
`;

export const Avatar = styled(ImagemCentralizada)``;
