import { omitProp, omitUndefinedProps } from "toolkit-extra";
import { MarkOptional } from "ts-essentials";
import { Evaluation } from "../../../@types/Evaluation";
import { db } from "../../../config";
import { OpportunityService } from "../Opportunity";

export class EvaluationService {
	private static instance: EvaluationService;

	public static getInstance(): EvaluationService {
		if (!EvaluationService.instance) {
			EvaluationService.instance = new EvaluationService();
		}
		return EvaluationService.instance;
	}

	private constructor() {}

	private getDocRef(ownerId: string, evaluationId?: string, isAgency?: boolean) {
		return db
			.collection(isAgency ? "empresas" : "perfis")
			.doc(ownerId)
			.collection("avaliacoes")
			.doc(evaluationId);
	}

	/**
	 * Avalia/re-avalia um promotor ou agência no banco de dados.
	 */
	async evaluate(data: UpsertOneParams) {
		// Pega a doc da avaliação da coleção correspondente em uma agência ou promotor
		const evaluationDocRef = this.getDocRef(
			data.promoterOrAgencyId,
			data.basicEvaluationData.id,
			!!data.agency
		);

		// Monta o objeto base da avaliação para salvar em vários lugares.
		const evaluationToUpsert: Evaluation = omitUndefinedProps({
			...data.basicEvaluationData,
			id: evaluationDocRef.id,
		});

		// Salva a avaliação no firestore usando a ref gerada acima.
		await evaluationDocRef.set(evaluationToUpsert);

		// Atualiza o roteiro colocando a oportunidade e alterando o status.
		await OpportunityService.getInstance().updateOne({
			id: data.opportunityId,
			statusRoteiro: {
				id: 5,
				avaliacao: omitProp(evaluationToUpsert, ["empresa"]),
			},
		});

		// Se a executora for agência, setamos a avaliação também no promotor que executou.
		if (data.agency?.promoterId) {
			const evaluationPromoterDocRef = this.getDocRef(
				data.agency.promoterId,
				evaluationToUpsert.id,
				false
			);
			await evaluationPromoterDocRef.set(evaluationToUpsert);
		}

		return evaluationToUpsert;
	}
}

type UpsertOneParams = {
	basicEvaluationData: MarkOptional<Evaluation, "id">;
	opportunityId: string;
	promoterOrAgencyId: string;
	agency?: {
		promoterId: string;
	};
};
