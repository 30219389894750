import { Button } from "semantic-ui-react";
import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const OptionButton = styled(Button)<{ $isSelected: boolean }>`
    height: 100px;
    width: 150px;

    ${({ $isSelected }) =>
        $isSelected &&
        css`
            background-color: #4f1e63 !important;
            color: white !important;
        `}
`;
