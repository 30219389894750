import {
    OPORTUNIDADES_DASHBOARD, OPORTUNIDADES_AGENCIA
} from "./Types";

export const alterarOportunidadesDashboard = oportunidadesPorDia => ({
    type: OPORTUNIDADES_DASHBOARD,
    oportunidadesPorDia
});

export const alterarOportunidadesAgencia = oportunidadesAgencia => ({
    type: OPORTUNIDADES_AGENCIA,
    oportunidadesAgencia
});
