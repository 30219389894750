import React, { Component } from "react";
import { Form, Icon, Button, Message, Header } from "semantic-ui-react";
import { v4 } from "uuid";
import { Editor } from "../../../Componentes/SunEditor";
import firebase from "firebase";
import { storage } from "../../../config";
import "./atividadeTipoVideo.scss";
import Preview from "./preview";
import _ from "lodash";
import { Video } from "video-metadata-thumbnails";

export default class AtividadeTipoVideo extends Component {
    constructor(props) {
        super(props);
        let { descricao, id, ordem, tipo, titulo, url } = props.atividade ?? {};
        this.state = {
            treinamento: {},
            carregando: false,
            progress: 0,
            file: {},
            erro: false,
            urlAtual: url ?? "",
            titulo: titulo ?? "",
            descricao: descricao ?? "",
            url: url ?? "",
            id: id ?? v4(),
            ordem: ordem ?? undefined,
            tipo: tipo ?? "video",
            preview: url ? "carregando" : "",
            error: [],
        };
    }

    componentDidMount() {
        let ref = this.state.preview;
        if (ref === "carregando") {
            firebase
                .storage()
                .ref()
                .child(this.state.url)
                .getDownloadURL()
                .then((url) => {
                    this.setState({ preview: url });
                });
        }
    }

    uploadThumb = (blob) => {
        let {
            treinamento: { id },
        } = this.props;
        let ref = `treinamentos/thumb/${id}.jpeg`;
        return storage
            .ref()
            .child(ref)
            .put(blob)
            .then(() => ref);
    };

    /**
     * @name onSubmit
     * @description envia a atividade de tipo video
     */
    onsubmit = () => {
        let { thumb } = this.state;
        if (_.isEmpty(thumb) && !_.isEmpty(this.state.blobs)) return alert("Selecione uma thumbnail");
        this.setState(
            {
                carregando: true,
            },
            () => {
                if (this.state.descricao === "") {
                    return this.setState({ erro: true, carregando: false });
                } else {
                    this.setState({ erro: false });
                }
                let idTreinamento = this.state.id;
                let { salvar, atividade } = this.props;
                let { urlAtual } = this.state;
                const salvarNobanco = (idTreinamento) => {
                    let { titulo, descricao, url, thumbAtiva, ordem } = this.state;
                    const verificarThumb = () => {
                        if (thumbAtiva) {
                            return this.uploadThumb(thumb.blob);
                        } else {
                            return Promise.resolve(atividade.thumb);
                        }
                    };
                    verificarThumb().then((thumbnail) => {
                        salvar({
                            titulo,
                            descricao,
                            url: urlAtual ? urlAtual : url,
                            ordem: ordem,
                            tipo: "video",
                            id: idTreinamento,
                            thumb: thumbnail,
                        }).catch((err) => {
                            this.setState({
                                carregando: false,
                                progress: 0,
                                error: [...this.state.error, "Erro no banco de dados"],
                                urlAtual: "",
                            });
                        });
                    });
                };
                if (!urlAtual) {
                    this.uploadVideo()
                        .then(() => salvarNobanco(idTreinamento))
                        .catch((err) => {
                            this.setState({
                                carregando: false,
                                progress: 0,
                                error: [...this.state.error, "Erro no envio"],
                            });
                        });
                } else {
                    salvarNobanco(idTreinamento);
                }
            }
        );
    };

    handleInput = (e, { value, name }, call) => {
        this.setState({ [name]: value }, call);
    };

    getVideo = (e) => {
        let file = e.target.files[0];
        this.setState({ file: file, preview: URL.createObjectURL(file) }, async () => {
            const video = new Video(file);
            let thumbs = await video.getThumbnails({
                quality: 0.6,
                start: 3,
                end: 6,
            });
            this.setState({ blobs: thumbs.map(({ blob }) => ({ blob, url: URL.createObjectURL(blob), id: v4() })) });
        });
    };

    uploadVideo = () =>
        new Promise((resolve, reject) => {
            let file = this.state.file;
            let {
                treinamento: { id },
            } = this.props;
            this.setState({ carregando: true }, () => {
                const fileRef = storage.ref().child(`treinamentos/videos/${id}-${file.name}`);
                let uploadTask = fileRef.put(file);

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        this.setState({ progress });
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                break;
                            default:
                                break;
                        }
                    },
                    (error) => {
                        this.setState({ error: [...this.state.error, "Erro no envio"] });
                        reject(error);
                    },
                    () => {
                        this.handleInput(null, { value: `treinamentos/videos/${id}-${file.name}`, name: "url" }, () =>
                            resolve()
                        );
                    }
                );
            });
        });

    selecionarThumb = (id) => this.setState({ thumb: this.state.blobs.find((blob) => blob.id === id), thumbAtiva: id });

    /**
     * @name handleInputDescricao
     * @date Criado em 1/01/2021
     * @author Vitor Andre Savian
     * @description Define a descrição do component do sun editor
     * @param {String} descricao string que vem do suneditor
     */
    handleInputDescricao = (descricao) => {
        this.setState({ descricao: descricao });
    };

    render() {
        var BotaoCancelar = this.props.cancelar;
        let { titulo, descricao, preview, progress, carregando, erro } = this.state;
        return (
            <div>
                <Header content="Vídeo" as="h2" />
                {!_.isEmpty(this.state.error) && <Message error header="Ops algo deu errado" list={this.state.error} />}
                <Form onSubmit={this.state.carregando ? () => {} : this.onsubmit}>
                    <Form.Input
                        required
                        type="text"
                        value={titulo}
                        name="titulo"
                        placeholder="Titulo do vídeo (aparecerá com maior destaque)"
                        label="Titulo"
                        onChange={this.handleInput}
                    />
                    <Editor
                        required={true}
                        placeholder="Descrição do vídeo (texto mais longo e detalhado)"
                        label="Descrição"
                        name="descricao"
                        value={descricao}
                        handleInputDescricao={this.handleInputDescricao}
                    />
                    {/* <Form.TextArea required name="descricao" value={descricao} placeholder="Descrição do vídeo (texto mais longo e detalhado)" label="Descrição" onChange={this.handleInput} /> */}
                    {!preview ? (
                        <div className="button-wrapper">
                            <div className="selecao-de-video">
                                <span style={{ textAlign: "center" }}>
                                    <Icon disabled name="video" size="large" />
                                    <br />
                                    <br />
                                    Selecione seu video
                                </span>
                            </div>

                            <input
                                accept="video/*"
                                required
                                type="file"
                                name="upload"
                                id="upload"
                                className="upload-box"
                                placeholder="Upload File"
                                onChange={this.getVideo}
                            />
                        </div>
                    ) : (
                        <Preview
                            titulo={titulo}
                            descricao={descricao}
                            preview={preview}
                            progress={progress}
                            carregando={carregando}
                            blobs={this.state.blobs}
                            thumbAtiva={this.state.thumbAtiva}
                            selecionarThumb={this.selecionarThumb}
                            getVideo={this.getVideo}
                        />
                    )}
                    {erro && (
                        <Message negative content="A atividade deve ir com descrição para facilitar o entendimento." />
                    )}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <BotaoCancelar />
                        <Button type="submit" positive loading={carregando}>
                            Salvar Atividade
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}
