import { useQuery } from "@tanstack/react-query";
import { isUndefined } from "lodash";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OperationAreaService } from "../../services/OperationArea";
import { useAuth } from "../store/useAuth";
import { useBlocker } from "../store/useBlocker";
import { useConfigs } from "../store/useConfigs";

interface UseOperationAreaProps {
	/**
	 * Indica se o toast de erro deve ser exibido por padrão se houver um erro.
	 *
	 * @default false
	 */
	throwToastError?: boolean;
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Hook para obter as áreas de atuação da aplicação utilizando `react-query`.
 */
export function useOperationArea(props?: UseOperationAreaProps) {
	const { t } = useTranslation();
	const { company } = useAuth();
	const {
		state: { isCreateOperationAreaBlocked, isDeleteOperationAreaBlocked },
		utils: { setBlocker },
	} = useBlocker();

	const { quantMaxAreasDeAtuacao } = useConfigs();

	//NOTE: react-query
	const queryMethods = useQuery({
		queryKey: ["operationAreas", company?.uid],
		queryFn: () => OperationAreaService.getInstance().fetchOperationArea(company?.uid || ""),
		keepPreviousData: true,
		staleTime: 1000 * 60 * 5, // 5 minutos até precisar fazer fetch de novo.
		meta: {
			errorMessage: props?.throwToastError
				? t("ocorreu-um-erro-ao-buscar-as-areas-de-atuacao-tente-novamente-mais-tarde")
				: undefined,
		},
	});

	const { data } = queryMethods;

	const currentOperationAreaQuant = useMemo(
		() => (isUndefined(data) ? quantMaxAreasDeAtuacao : data.length),
		[data, quantMaxAreasDeAtuacao]
	);

	useEffect(() => {
		if (currentOperationAreaQuant >= quantMaxAreasDeAtuacao) {
			setBlocker({
				isCreateOperationAreaBlocked: true,
			});
		} else {
			setBlocker({
				isCreateOperationAreaBlocked: false,
			});
		}
	}, [currentOperationAreaQuant, quantMaxAreasDeAtuacao, setBlocker]);

	return {
		queryMethods,
		blockers: {
			isCreateOperationAreaBlocked,
			isDeleteOperationAreaBlocked,
		},
	};
}
