import { Stack, Text, Tfoot, Th, Tr } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CostPerPromoterReportTableData } from "..";
import { fCurrency } from "../../../../Utils/Number";

interface CostPerPromoterReportTableFooterProps {
	tableData: CostPerPromoterReportTableData[];
}

export function CostPerPromoterReportTableFooter({
	tableData,
}: CostPerPromoterReportTableFooterProps) {
	const { t } = useTranslation();

	const totals = useMemo(() => {
		return tableData.reduce(
			(acc, row) => ({
				...acc,
				finishedOpportunitiesQuantity:
					acc.finishedOpportunitiesQuantity + row.finishedOpportunities,
				totalPaidForPromoter: acc.totalPaidForPromoter + row.totalPaidForPromoter,
				totalCost: acc.totalCost + row.totalCost,
			}),
			{
				finishedOpportunitiesQuantity: 0,
				totalPaidForPromoter: 0,
				totalCost: 0,
			}
		);
	}, [tableData]);

	return (
		<Tfoot>
			<Tr>
				<Th>{t("total")}</Th>
				<Th textAlign={"center"} fontSize={"md"}>
					{totals.finishedOpportunitiesQuantity}
				</Th>
				<Th isNumeric fontSize={"md"}>
					<Stack spacing={2}>
						<Text lineHeight={1}>{fCurrency(totals.totalCost)}</Text>
						<Text
							color={"gray.500"}
							fontWeight={"normal"}
							lineHeight={1}
							fontSize={"sm"}>
							{fCurrency(totals.totalPaidForPromoter)}
						</Text>
					</Stack>
				</Th>
			</Tr>
		</Tfoot>
	);
}
