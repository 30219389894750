import React from "react";
import Currency from "react-currency-formatter";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
import ImagemCentralizada from "../../../Componentes/ImagemCentralizada";
import { DOCUMENTO_ENVIADO } from "../../../Model/model";
import { ModalCancelCandidature } from "../../../Componentes/ModalCancelCandidature";

const PromotorAprovado = (props) => {
    const { roteiro, t } = props;
    const documentoJaEnviado = props.roteiro.documento && props.roteiro.documento.status === DOCUMENTO_ENVIADO;
    const documentoLabel = `${documentoJaEnviado ? "Abrir" : "Anexar"} Documento`;
    const actionDocumento = documentoJaEnviado ? props.onDownloadDocumento : props.onSelecionarDocumento;
    const actionNotificacao = props.onSelecionarNotificacao;
    return (
        <>
            <Grid stackable>
                <Grid.Row columns={2}>
                    <Grid.Column width={3} style={{ paddingLeft: 50 }}>
                        <div style={{ width: "100%", display: "block" }}>
                            <ImagemCentralizada
                                src={roteiro.usuario.foto}
                                style={{ borderRadius: 10 }}
                                onClick={() => {}}
                            />
                        </div>
                    </Grid.Column>

                    <Grid.Column width={5}>
                        <div
                            style={{
                                padding: "10px 15px",
                                lineHeight: "30px",
                                display: "grid",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "2em",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {roteiro.usuario.nome}
                            </span>
                            <p className="sub-padrao">{roteiro.usuario.sexo}</p>
                            <p className="sub-padrao">
                                {t("media.avaliacao")}:{" "}
                                {roteiro.usuario.mediaAvaliacao && roteiro.usuario.quantidadeAvaliacao
                                    ? (roteiro.usuario.mediaAvaliacao / roteiro.usuario.quantidadeAvaliacao).toFixed(2)
                                    : 0}
                            </p>
                            {!roteiro.promotor && roteiro.usuario.sexo === "Agência" && (
                                <p className="sub-aviso">{t("aguardando.confirmacao.agencia")}</p>
                            )}

                            <Button
                                style={{ marginTop: "12px" }}
                                content="Ver Perfil"
                                icon="user"
                                basic
                                as={Link}
                                to={"/perfil/" + roteiro.usuario.uid}
                            />
                            <ModalCancelCandidature roteiro={props.roteiro} />
                        </div>
                    </Grid.Column>

                    <Grid.Column width={8} textAlign="center">
                        {roteiro.pagamento.pagarFaturamentoMensal ? (
                            <>
                                <p className="tit-padrao">{t("faturamento.mensal")}</p>
                                <p className="tit-padrao">
                                    <Currency quantity={roteiro.valor} decimal="," group="." currency="BRL" />
                                </p>
                                <span className="sub-padrao">{t("mensagem.sobre.faturamento")}</span> <br></br>
                            </>
                        ) : (
                            <>
                                <p className="tit-padrao">{t("saldo.reservado")}</p>
                                <p className="tit-padrao">
                                    <Currency quantity={roteiro.valor} decimal="," group="." currency="BRL" />
                                </p>
                                <span className="sub-padrao">{t("mensagem.reserva.saldo")}</span> <br></br>
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={8} style={{ marginLeft: "20px" }}>
                        <Button
                            basic
                            onClick={actionNotificacao}
                            icon="bell"
                            style={{ marginBottom: 5 }}
                            content={"Enviar Notificações"}
                        />
                        <br />
                        <Button basic onClick={actionDocumento} icon="file alternate" content={documentoLabel} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};
export default withTranslation()(PromotorAprovado);
