import {
	Avatar,
	Center,
	Divider,
	Flex,
	Heading,
	IconButton,
	Stat,
	StatGroup,
	StatHelpText,
	StatLabel,
	StatNumber,
	Tag,
	Tooltip,
	VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { NegotiationStatus } from "../../@types/Negotiation";
import {
	AgencyWithNegotiation,
	useContextOpportunityExecutionNegotiation,
} from "../../contexts/ContextOpportunityExecutionNegotiation";
import { getLastProposal, getNegotiationStatusInfo } from "../../helpers/Negotiation";
import { useAccountBalance } from "../../hooks/useAccountBalance";
import { fCurrency, fShortenNumber } from "../../Utils/Number";
import { Card } from "../Card";
import { Iconify } from "../Iconify";
import { ModalShowOpportunityNegotiation } from "../ModalShowOpportunityNegotiation";
import { ModalStartOpportunityNegotiation } from "../ModalStartOpportunityNegotiation";
import { NegotiationNewsStatus } from "../NegotiationsList/NegotiationNewsStatus";
import { AgencyNetworkCredentialRibbon } from "../Ribbon/AgencyNetworkCredentialRibbon";
import { Tip } from "../Tip";

interface AgencyInformationCardProps {
	data: AgencyWithNegotiation;
}

export function AgencyInformationCard({
	data: { agency, negotiation },
}: AgencyInformationCardProps) {
	const { t } = useTranslation();
	const history = useHistory();
	const { opportunity } = useContextOpportunityExecutionNegotiation();

	/**
	 * Valor por hora da agência;
	 */
	const valuePerHour = agency.valorPorHora || 0;
	/**
	 * Valor por hora da agência, porém formatado
	 */
	const formattedValuePerHour = valuePerHour ? fCurrency(valuePerHour) : null;

	/**
	 * Booleano que diz se há negociação.
	 */
	const hasNegotiation = !!negotiation;

	/**
	 * @description Valor calculado que a oportunidade deve custar para ser aceitável pela agência
	 */
	const totalValue = useMemo(() => {
		//NOTE: Se a agência tiver uma negociação, usamos o valor da negociação.
		if (hasNegotiation) return getLastProposal(negotiation)?.valor || 0;

		//NOTE: Se não houver o valor por hora da agência, usamos o valor da oportunidades.
		if (!valuePerHour) return opportunity.valor;

		//NOTE: Se houver tempo estimado, calculamos o valor por hora, vezes o tempo estimado em horas e retornamos o resultado se ele for acima do valor da oportunidade.
		if (opportunity.tempoEstimado) {
			const estimatedTimeInHours = Number(opportunity.tempoEstimado) / 60;
			const totalByAgencyHour = valuePerHour * estimatedTimeInHours;

			return opportunity.valor < totalByAgencyHour ? totalByAgencyHour : opportunity.valor;
		} else {
			//NOTE: Se não houver tempo estimado, calculamos o tempo estimado entre a hora fim e hora inicio da oportunidade e fazemos a mesma validação.
			const horaFim = moment(opportunity.horaFim, "HH:mm");
			const horaInicio = moment(opportunity.horaInicio, "HH:mm");
			const duration = horaFim.diff(horaInicio, "minutes") / 60;
			const totalByAgencyHour = valuePerHour * duration;

			return opportunity.valor < totalByAgencyHour ? totalByAgencyHour : opportunity.valor;
		}
	}, [
		hasNegotiation,
		opportunity.horaFim,
		opportunity.horaInicio,
		opportunity.tempoEstimado,
		opportunity.valor,
		negotiation,
		valuePerHour,
	]);

	const { balanceIsSufficient } = useAccountBalance(totalValue);
	const formattedTotalValue = fCurrency(totalValue);

	/**
	 * Guarda algumas propriedades para usar em componentes abaixo que mudam baseado em alguns estados.
	 */
	const totalValueVariantProps = useMemo(() => {
		//NOTE: Se já tiver negociação, validamos se o valor da ultima proposta é maior que o disponível no saldo.
		if (hasNegotiation) {
			const totalValueIGreaterThan = !balanceIsSufficient;
			return {
				totalValueIGreaterThan,
				tooltipLabel: totalValueIGreaterThan
					? t("valor-proposto-e-maior-que-o-disponivel-em-saldo-faca-uma-recarga")
					: "",
			};
		} else {
			//NOTE: Se ainda não há negociação, validamos se o valor mínimo que será proposto é maior que o valor da oportunidade
			const totalValueIGreaterThan = totalValue > opportunity.valor;
			return {
				totalValueIGreaterThan,
				tooltipLabel: totalValueIGreaterThan
					? t("valor-da-oportunidade-esta-abaixo-do-valor-aceitavel-para-a-agencia")
					: "",
			};
		}
	}, [balanceIsSufficient, hasNegotiation, opportunity.valor, t, totalValue]);

	/**
	 * @description O Valor que será exibido para mostrar a quantidade de avaliações.
	 */
	let formattedComments: number | string = agency.quantidadeAvaliacao
		? agency.quantidadeAvaliacao
		: 0;
	formattedComments = formattedComments > 100 ? "+100" : formattedComments;

	/**
	 * @description O valor da média de avaliação quq será exibido.
	 */
	const starsCount = fShortenNumber(agency.mediaAvaliacaoCalculada);

	/**
	 * @description Informações e propriedades baseadas no status atual da negociação se houver
	 */
	const negotiationStatusInfos = getNegotiationStatusInfo(
		negotiation?.status || NegotiationStatus.PENDENTE
	);

	return (
		<VStack
			as={Card}
			p={4}
			maxW="xs"
			w="xs"
			textAlign="center"
			minH={"full"}
			position="relative"
			overflow="visible">
			{hasNegotiation && (
				<NegotiationNewsStatus position="absolute" right={4} negotiation={negotiation} />
			)}
			{agency.estaCredenciadaNaRede && <AgencyNetworkCredentialRibbon />}
			<Avatar src={agency.imagemURL} w={150} h={150}></Avatar>
			<Heading as="h1" size="md" isTruncated title={agency.fantasia} w="full">
				{agency.fantasia}
			</Heading>
			<StatGroup w="full">
				<Stat>
					<StatLabel>{t("media")}</StatLabel>
					<StatNumber>
						<Iconify icon="mdi:star" inline color="orange.300" mr={1} />
						{starsCount}/5
					</StatNumber>
				</Stat>
				<Stat>
					<StatLabel>{t("coment")}</StatLabel>
					<StatNumber>
						<Iconify icon="mdi:comment-quote" inline color="blue.300" mr={1} />
						{formattedComments}
					</StatNumber>
				</Stat>
			</StatGroup>
			<Stat w="full">
				<StatLabel>{hasNegotiation ? t("ultima-proposta") : t("valor-total")}</StatLabel>
				<Tooltip
					textAlign="center"
					label={totalValueVariantProps.tooltipLabel}
					hasArrow
					placement="top">
					<StatNumber
						isTruncated
						title={formattedTotalValue}
						w="full"
						color={totalValueVariantProps.totalValueIGreaterThan ? "red.400" : "black"}>
						{formattedTotalValue}
					</StatNumber>
				</Tooltip>
				{!!formattedValuePerHour && !hasNegotiation && (
					<Center>
						<Tip label={t("dica-valor-por-hora")}>
							<StatHelpText isTruncated title={formattedValuePerHour} w="full">
								{formattedValuePerHour} / {t("hora")}
							</StatHelpText>
						</Tip>
					</Center>
				)}
			</Stat>
			{hasNegotiation && (
				<>
					<Divider />
					<Tag
						colorScheme={negotiationStatusInfos.colorScheme}
						justifyContent="center"
						size="lg"
						w="full"
						title={negotiationStatusInfos.label}>
						<Iconify icon={negotiationStatusInfos.icon} mr={1} />
						{negotiationStatusInfos.label}
					</Tag>
					<Divider />
				</>
			)}
			<Flex gap={3} w="full">
				<Tooltip label={t("ver-perfil")} hasArrow>
					<IconButton
						size="lg"
						onClick={() => history.push(`/perfil/${agency.uid}`)}
						variant="outline"
						aria-label={t("ir-para-o-perfil-da-agencia")}
						icon={<Iconify icon="fa6-solid:building-user" />}
					/>
				</Tooltip>
				{hasNegotiation ? (
					<ModalShowOpportunityNegotiation negotiation={negotiation} executor={agency} />
				) : (
					<ModalStartOpportunityNegotiation
						opportunity={opportunity}
						agency={agency}
						totalValue={totalValue}
					/>
				)}
			</Flex>
		</VStack>
	);
}
