import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { HeaderPadrao } from "../../Componentes/Componentes";
import Link from "../../Componentes/Link";
import MensagemSalvo from "../../Componentes/MensagemSalvo";
import { ModalPlanogram } from "../../Componentes/ModalPlanogram/index";
import { registrarProduto } from "../../Utils/Produto";
import NovoProdutoForm from "./NovoProdutoForm";

class EditarProduto extends Component {
    state = {
        salvo: false,
        produto: this.props.location.state.produto,
        products: this.props.location.state.products,
        erroCodigo: null,
        fotoFile: null,
        planogramaFile: null,
        planograma: null,
        carregando: false,
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({ carregando: true, erroCodigo: null }, () => {
            return registrarProduto(
                {
                    produto: {
                        ...this.state.produto,
                    },
                    foto: this.state.fotoFile,
                    planograma: this.state.planogramaFile,
                },
                "update"
            )
                .then((produto) => {
                    this.setState({ salvo: true, carregando: false }, this.finalizarEdicao(produto));
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        erroCodigo: this.props.t(err.message),
                        carregando: false,
                    });
                });
        });
    };

    finalizarEdicao = (produto) => {
        this.props.history.push({
            pathname: "/produto",
            state: {
                produto,
            },
        });
    };

    verPlanograma = (image) => {
        this.setState({
            planograma: image,
        });
    };

    closePlanograma = (image) => this.setState({ planograma: null });

    render() {
        if (this.state.carregando) {
            return (
                <div style={{ margin: "0 auto", width: "80%" }}>
                    <Dimmer active inverted>
                        <Loader inverted>Carregando</Loader>
                    </Dimmer>
                </div>
            );
        }
        const { salvo, carregando, produto } = this.state;
        const { t } = this.props;
        if (produto == null) {
            return <Redirect to="/dashboard" />;
        }

        return (
            <div>
                <HeaderPadrao icone="boxes" titulo={t("novo.produto")} subtitulo={t("cadastre.novo.produto")} />

                {salvo ? (
                    <MensagemSalvo
                        t={t}
                        novoRegistro={this.finalizarEdicao.bind(this)}
                        mensagem={t("edicao.concluida")}
                    />
                ) : (
                    <>
                        <NovoProdutoForm
                            scope={this}
                            t={t}
                            carregando={carregando}
                            editando
                            produto={{
                                ...this.state.produto,
                            }}
                            verPlanograma={this.verPlanograma.bind(this)}
                            onSubmit={this.onSubmit.bind(this)}>
                            <div style={{ display: "table", width: "100%", marginTop: 10 }}>
                                <Button
                                    className="green-gradient-light"
                                    content={t("salvar")}
                                    disabled={carregando}
                                    icon="check"
                                    color="green"
                                    floated="right"></Button>
                                <Button
                                    as={Link}
                                    to="/produto"
                                    content={t("voltar")}
                                    icon="left arrow"
                                    color="red"
                                    floated="right"
                                    disabled={carregando}></Button>
                            </div>
                        </NovoProdutoForm>
                        <ModalPlanogram
                            isOpen={!!this.state.planograma}
                            image={this.state.planograma || ""}
                            onClose={this.closePlanograma.bind(this)}
                        />
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        idEmpresa: state.AutenticacaoReducer.empresa.uid,
        empresa: state.AutenticacaoReducer.empresa,
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(EditarProduto)));
