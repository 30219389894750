import { AppConfigs } from "../../@types/AppConfigs";
import { useAppSelector } from "../useAppSelector";

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Hook que retorna as configurações da aplicação que estão na `Store`.
 */
export function useConfigs() {
	const configs = useAppSelector(state => state.RecursosReducer).configuracao as AppConfigs;

	return configs;
}
