import { db } from './../config';


/**
 * Verifica se o código do produto já existe no firebase.
 * @param {Arrray} lojas Lista de lojas para pesquisa.
 * @param {String} codigo Codigo do produto.
 * @return Promise
 */
// codigoJaExiste :: (Array[Loja], String) -> Promise () e
export const codigoJaExiste = (lojas, codigo) => new Promise((resolved, rejected) => {
    if (lojas.find(loja => loja.codigo === codigo)) {
        return rejected({
            code: "loja/codigo-ja-existe",
            message: "codigo.existente"
        });
    }
    return resolved();
});

export const arquivarLoja = idLoja =>
    db.collection('lojas').doc(
        idLoja
    ).update({ arquivado: true });


export const lojaSearchBoxString = loja => {
    let searchBox = `${loja.endereco.endereco}`;
    if (loja.place_id !== '') {
        searchBox = `${loja.fantasia} - ${searchBox}`;
    }
    return searchBox;
};