import _ from "lodash";

import moment, { Duration, duration as momentDuration } from "moment";

import momentDurationFormatSetup from "moment-duration-format";
import React, { useEffect, useState } from "react";
import Currency from "react-currency-formatter";
//FIXME: Biblioteca sem atualizações e sem tipagens. recomendado a retirada
//@ts-ignore
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import {
	Accordion,
	Checkbox,
	CheckboxProps,
	Grid,
	Loader,
	Popup,
	Search,
	SearchProps,
	Table,
} from "semantic-ui-react";
import { isTrue } from "../../../Utils/isTrue";
import { alterarEstadoDeUmRelatorio } from "../../../_actions/RelatoriosActions";
import { TableOpportunitiesPerDate } from "./TableOpportunitiesPerDate";

//FIXME: Biblioteca de tipos está errada
//@ts-ignore
momentDurationFormatSetup(moment);

//NOTE: Tipagem do redux deve ser feita futuramente
const mapStateToProps = (state: {
	RelatoriosReducer: {
		oportunidades: {
			docs: Opportunity[];
		};
	};
}) => {
	return {
		opportunitiesStoreState: state.RelatoriosReducer.oportunidades.docs,
	};
};

const mapDispatchToProps = {
	handleChangeStateReport: alterarEstadoDeUmRelatorio,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Promoter {
	uid: string;
	nome: string;
}

interface PromoterOption {
	id: string;
	title: string;
	description: string;
}

export interface Opportunity {
	_id: string;
	loja: {
		fantasia: string;
	};
	iniciadoEm: string;
	finalizadoEm: string;
	duration: Duration;
	valor: number;
	usuario: Promoter;
	data: string;
	statusRoteiro: {
		id: number;
	};
	tempoEstimado: string;
}

interface MappedOpportunity {
	id: string;
	data: string;
	promotor: Promoter;
	oportunidades: Opportunity[];
	duration: Duration;
	valor: number;
}

interface TimeReportProps extends PropsFromRedux {}

function TimeReport({ opportunitiesStoreState }: TimeReportProps) {
	const [mappedOpportunities, setMappedOpportunities] = useState<MappedOpportunity[]>([]);
	const [totals, setTotals] = useState({
		duration: momentDuration(0),
		valor: 0,
	});

	const [optionsForSearch, setOptionsForSearch] = useState<PromoterOption[]>([]);
	const [optionsResults, setOptionsResults] = useState<PromoterOption[]>([]);
	const [selectedOption, setSelectedOption] = useState<PromoterOption | null>(null);

	const [isLoading, setIsLoading] = useState(false);
	const [showMoreOpportunities, setShowMoreOpportunities] = useState<boolean>();

	const { t } = useTranslation();

	useEffect(() => {
		const selectedOptionInStorage = window.sessionStorage.getItem("TimeReport.selectedOption");
		if (selectedOptionInStorage) {
			setSelectedOption(JSON.parse(selectedOptionInStorage));
		}
		const checkedOption = window.sessionStorage.getItem("TimeReport.checkedOption");
		setShowMoreOpportunities(isTrue(checkedOption));
	}, []);

	useEffect(() => {
		setIsLoading(true);
		async function mountReport() {
			const opportunitiesPerDateAndPromoter: MappedOpportunity[] = [];
			const promotoresOptions: PromoterOption[] = [];

			opportunitiesStoreState.forEach(oportunidade => {
				if (!_.find(promotoresOptions, { id: oportunidade.usuario.uid })) {
					promotoresOptions.push({
						id: oportunidade.usuario.uid,
						title: oportunidade.usuario.nome,
						description: oportunidade.usuario.nome,
					});
				}

				const oportunidadeEncontrada = opportunitiesPerDateAndPromoter.findIndex(
					op =>
						op.promotor.uid === oportunidade.usuario.uid &&
						op.data === oportunidade.data
				);

				const finalizadoEm = moment(oportunidade.finalizadoEm, "YYYY-MM-DD HH:mm:ss");
				const iniciadoEm = moment(oportunidade.iniciadoEm, "YYYY-MM-DD HH:mm:ss");
				const tempoEstimado =
					showMoreOpportunities &&
					(oportunidade.statusRoteiro.id === 2 || oportunidade.statusRoteiro.id === 6);
				const diffTempo = tempoEstimado
					? moment.duration(oportunidade.tempoEstimado || 0, "minutes").asSeconds()
					: finalizadoEm.diff(iniciadoEm, "seconds");
				const duration = moment.duration(diffTempo, "seconds");

				const refactorOportunidade = {
					...oportunidade,
					finalizadoEm: finalizadoEm.format("HH:mm"),
					iniciadoEm: iniciadoEm.format("HH:mm"),
					diffTempo,
					duration,
				};

				//Se houver uma oportunidade no array
				if (oportunidadeEncontrada !== -1) {
					const newDuration = momentDuration(duration);
					//soma com o tempo ja existente
					const sumDuration = newDuration.add(
						opportunitiesPerDateAndPromoter[oportunidadeEncontrada].duration
					);

					//adiciona no array de promotores os novos dados {oportunidade, duracao nova e soma o valor}
					opportunitiesPerDateAndPromoter[oportunidadeEncontrada] = {
						...opportunitiesPerDateAndPromoter[oportunidadeEncontrada],
						oportunidades: [
							...opportunitiesPerDateAndPromoter[oportunidadeEncontrada]
								.oportunidades,
							refactorOportunidade,
						],
						duration: sumDuration,
						valor:
							opportunitiesPerDateAndPromoter[oportunidadeEncontrada].valor +
							oportunidade.valor,
					};
				} else {
					//se ainda nao houver o objeto do promotor no array adiciona o objeto novo com os dados do promotor e oportunidades
					opportunitiesPerDateAndPromoter.push({
						id: oportunidade.data + oportunidade.usuario.nome,
						data: oportunidade.data,
						promotor: oportunidade.usuario,
						oportunidades: [refactorOportunidade],
						duration,
						valor: oportunidade.valor,
					});
				}
			});

			const filteredSelectedOptions = selectedOption
				? _.filter(
						opportunitiesPerDateAndPromoter,
						o => o.promotor.uid === selectedOption.id
					)
				: opportunitiesPerDateAndPromoter;

			setOptionsForSearch(promotoresOptions);
			setMappedOpportunities(filteredSelectedOptions);
			console.log(filteredSelectedOptions);
			setTotals(oldState => ({
				...oldState,
				duration: filteredSelectedOptions.reduce(
					(acc, curr) => acc.add(curr.duration),
					momentDuration(0)
				),
				valor: filteredSelectedOptions.reduce((acc, curr) => acc + curr.valor, 0),
			}));
			setIsLoading(false);
		}

		mountReport();
	}, [opportunitiesStoreState, selectedOption, showMoreOpportunities]);

	function persistStateInSessionStorage<T>(local: string, value: T) {
		if (value) {
			window.sessionStorage.setItem(local, JSON.stringify(value));
		} else {
			window.sessionStorage.setItem(local, "null");
		}
	}

	function handleChangeCheckbox(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) {
		const checked = data?.checked ?? false;
		window.sessionStorage.setItem("TimeReport.checkedOption", JSON.stringify(checked));
		setShowMoreOpportunities(checked);
	}

	function handleChangeSelectedOption(
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		data: SearchProps
	) {
		persistStateInSessionStorage("TimeReport.selectedOption", data.result);
		setSelectedOption(data.result);
	}

	function handleSearchChange(
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		data: SearchProps
	) {
		handleChangeSelectedOption(event, { result: null });
		const result = data.value;
		if (result) {
			const filteredResults = _.filter(optionsForSearch, option =>
				option.title.toLowerCase().includes(result.toLowerCase())
			);

			setOptionsResults(filteredResults);
		}
	}

	if (isLoading) return <Loader active inline="centered" />;

	return (
		<div>
			{mappedOpportunities.length ? (
				<>
					<Grid stackable>
						<Grid.Column width={5}>
							<Search
								placeholder={t("busque.por.um.promotor")}
								onResultSelect={handleChangeSelectedOption}
								onSearchChange={handleSearchChange}
								resultRenderer={props => <>{props.description}</>}
								results={optionsResults}
								fluid={true}
								defaultValue={selectedOption ? selectedOption.title : ""}
								noResultsMessage={t("Nenhum.resultado.encontrado")}
							/>
						</Grid.Column>
						<Grid.Column width={7} textAlign="left" verticalAlign="middle">
							<Popup
								content={t("clique.gerar.relatorio")}
								trigger={
									<Checkbox
										checked={showMoreOpportunities}
										onChange={handleChangeCheckbox}
										label={t("calcular.oportunidades")}
									/>
								}
							/>
						</Grid.Column>
						<Grid.Column width={4} textAlign="right">
							<ReactHtmlTableToExcel
								id="botao-excel"
								className="download-table-xls-button excel-oportunidades"
								table={"tabela-rel-horas"}
								filename="Rel-Horas"
								sheet="Relatorio de Horas"
								buttonText={t("exportar.xls")}
							/>
						</Grid.Column>
					</Grid>
					<Table sortable celled id="tabela-rel-horas">
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{t("data")}</Table.HeaderCell>
								<Table.HeaderCell>{t("promotor")}</Table.HeaderCell>
								<Table.HeaderCell>{t("horas.totais")}</Table.HeaderCell>
								<Table.HeaderCell textAlign="right">{t("valor")}</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{mappedOpportunities.map(oportunidade => (
								<Table.Row key={oportunidade.id}>
									<Table.Cell>
										{moment(oportunidade.data, "YYYY-MM-DD").format("DD/MM/YY")}
									</Table.Cell>
									<Table.Cell width={11}>
										<Accordion
											panels={[
												{
													key: oportunidade.id,
													title: oportunidade.promotor.nome,
													content: {
														content: (
															<TableOpportunitiesPerDate
																opportunities={
																	oportunidade.oportunidades
																}
															/>
														),
													},
												},
											]}
										/>
									</Table.Cell>
									<Table.Cell>
										{oportunidade.duration.format("HH[h] mm[m] ss[s]")}
									</Table.Cell>
									<Table.Cell textAlign="right">
										<Currency
											quantity={parseFloat(String(oportunidade.valor))}
											locale="pt_BR"
											decimal=","
											group="."
											currency="R$ "
										/>
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
						<Table.Footer>
							<Table.Row>
								<Table.HeaderCell colSpan={2}></Table.HeaderCell>
								<Table.HeaderCell textAlign="left" style={{ fontWeight: "bold" }}>
									{totals.duration.format("HH[h] mm[m] ss[s]")}
								</Table.HeaderCell>
								<Table.HeaderCell textAlign="right" style={{ fontWeight: "bold" }}>
									<Currency
										quantity={totals.valor}
										locale="pt_BR"
										decimal=","
										group="."
										currency="R$ "
									/>
								</Table.HeaderCell>
							</Table.Row>
						</Table.Footer>
					</Table>
				</>
			) : (
				<></>
			)}
		</div>
	);
}

export default connector(TimeReport);
