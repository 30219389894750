import List from "./List";
import Create from "./Create";
import Edit from "./Edit";
import Layout from "../Layout";

export const OperationArea = {
	List,
	Create,
	Edit,
	Layout,
};
