import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { createControlComponent } from "@react-leaflet/core";
import L, { PM } from "leaflet";

/**
 * Referência `PM.ToolbarOptions`, porém tira a chave [key: string] para melhorar a legibilidade e permitir que a chaves sejam buscadas facilmente.
 */
interface ToolbarOptions {
	/** Toolbar position. */
	position?: L.ControlPosition;

	/** The position of each block can be customized. If not set, the value from position is taken. */
	positions?: PM.BlockPositions;

	/** Adds button to draw Markers (default:true) */
	drawMarker?: boolean;

	/** Adds button to draw CircleMarkers (default:true) */
	drawCircleMarker?: boolean;

	/** Adds button to draw Line (default:true) */
	drawPolyline?: boolean;

	/** Adds button to draw Rectangle (default:true) */
	drawRectangle?: boolean;

	/** Adds button to draw Polygon (default:true) */
	drawPolygon?: boolean;

	/** Adds button to draw Text (default:true) */
	drawText?: boolean;

	/** Adds button to draw Circle (default:true) */
	drawCircle?: boolean;

	/** Adds button to toggle edit mode for all layers (default:true) */
	editMode?: boolean;

	/** Adds button to toggle drag mode for all layers (default:true) */
	dragMode?: boolean;

	/** Adds button to cut a hole in a polygon or line (default:true) */
	cutPolygon?: boolean;

	/** Adds a button to remove layers (default:true) */
	removalMode?: boolean;

	/** Adds a button to rotate layers (default:true) */
	rotateMode?: boolean;

	/** All buttons will be displayed as one block Customize Controls (default:false) */
	oneBlock?: boolean;

	/** Shows all draw buttons / buttons in the draw block (default:true) */
	drawControls?: boolean;

	/** Shows all edit buttons / buttons in the edit block (default:true) */
	editControls?: boolean;

	/** Shows all buttons in the custom block (default:true) */
	customControls?: boolean;
}

type GeomanProps = L.ControlOptions &
	ToolbarOptions & {
		/**
		 * Callback para o evento de criação de uma layer.
		 */
		pmCreate?: PM.CreateEventHandler;
		/**
		 * Callback para a remoção de uma layer.
		 */
		pmRemove?: PM.RemoveEventHandler;
		/**
		 * Callback para a edição de uma layer.
		 */
		pmUpdate?: PM.EditEventHandler;
	};

const Geoman = L.Control.extend({
	options: {} as GeomanProps,
	initialize(options: GeomanProps) {
		L.setOptions(this, options);
	},

	addTo(map: L.Map) {
		if (!map.pm) return;

		map.pm.setLang("pt_br");

		//NOTE: Separa as opções dos handlers.
		const { pmCreate, pmRemove, pmUpdate, ...restOptions } = this.options;

		//NOTE: Adiciona as opções ao mapa.
		map.pm.addControls({
			...restOptions,
		});

		//NOTE: Adiciona os handlers.
		map.on("pm:create", e => {
			pmCreate && pmCreate(e);
			pmUpdate && e.layer.on("pm:update", pmUpdate);
		});

		pmRemove && map.on("pm:remove", pmRemove);
	},
});

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Um componente que permite criar uma instância do `Geoman` porém não renderiza um elemento para ser renderizado na DOM
 */
export function UncontrolledGeomanComponent(props: GeomanProps) {
	return new Geoman(props);
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Um componente que permite criar uma instância do `Geoman` e renderiza-lo na DOM.
 * @obs Componente não é reativo.
 */
export const GeomanControl = createControlComponent(UncontrolledGeomanComponent);
