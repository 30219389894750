import { createTheme } from "@material-ui/core";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#4f1e63",
        },
        secondary: {
            main: "#4CD964",
        },
    },
});

export default muiTheme;
