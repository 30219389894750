import { useState } from "react";

interface PaginationData<T> {
    currentPage: number;
    getCurrentData: () => T[];
    totalPages: number;
    handleChangePage: (pageNumber: number) => void;
    paginatedData: T[];
}

const usePagination = <T,>(data: T[], itemsPerPage: number): PaginationData<T> => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handleChangePage = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const getCurrentData = (): T[] => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        return data.slice(startIndex, endIndex);
    };

    const paginatedData = getCurrentData();

    return {
        currentPage,
        getCurrentData,
        totalPages,
        handleChangePage,
        paginatedData,
    };
};

export default usePagination;
