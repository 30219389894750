import { Container } from "@chakra-ui/react";
import { ReactNode } from "react";

interface PageLayoutProps {
	children: ReactNode;
}

export default function PageLayout({ children }: PageLayoutProps) {
	return (
		<Container maxW="container.xl" display="flex" flexDir="column" gap={5}>
			{children}
		</Container>
	);
}
