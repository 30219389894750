import React from "react";
import { Icon } from "semantic-ui-react";
import { useDisclosure } from "../../../../hooks/useDisclosure";
import { MenuItem } from "./MenuItem";
import { ModalComplaint } from "./ModalComplaint/index";
import { Container, ContainerContent } from "./styles";

export interface Promoter {
    id: string;
    name: string;
}

export interface Opportunity {
    id: string;
}

export interface Company {
    id: string;
}
interface MenuCardPromoterEvaluationProps {
    promoter: Promoter;
    opportunity: Opportunity;
    company: Company;
}

export function MenuCardPromoterEvaluation({ ...propsRest }: MenuCardPromoterEvaluationProps) {
    const { onToggle, ...disclosureRest } = useDisclosure();
    const { isOpen: popupIsOpen, onClose: popupOnClose, onOpen: popupOnOpen } = useDisclosure();

    return (
        <>
            <ModalComplaint {...disclosureRest} {...propsRest} />
            <Container
                position="bottom left"
                offset={[-7, 0]}
                on="click"
                onOpen={popupOnOpen}
                onClose={popupOnClose}
                open={popupIsOpen}
                content={
                    <ContainerContent>
                        <MenuItem
                            icon="warning sign"
                            text="Denunciar"
                            onClick={() => {
                                popupOnClose();
                                disclosureRest.onOpen();
                            }}
                        />
                    </ContainerContent>
                }
                trigger={
                    <Icon
                        style={{ position: "absolute", top: 10, right: 0, cursor: "pointer" }}
                        name="ellipsis vertical"
                        color="grey"
                        size="large"
                    />
                }
            />
        </>
    );
}
