import { v4 } from "uuid";
import { db, storage } from "./../config";

/**
 * Verifica se o código do produto já existe no firebase.
 * @param {Array} marcas Lista de marcas para pesquisa.
 * @param {String} codigo Codigo do produto.
 * @return Promise
 */
// codigoJaExiste :: (Array[Marcas], String) -> Promise () e
// export const codigoJaExiste = (marcas, codigo, idEmpresa) => new Promise((resolved, rejected) => {
//     return firebase.firestore().collection('produtos').where("idEmpresa", "==", idEmpresa).where('codigo', "==", codigo).get().then((prod) => {
//         if(prod.size > 0){
//             return rejected({
//                 code: "marca/codigo-ja-existe",
//                 message: "codigo.existente"
//             });
//         } else {
//             return resolved();
//         }
//     })
// });

// array.reduce(() => {

// }, Promise.resolve([]))

/**
 * Envia o objeto do produto para o firebase.
 * @param {Object} produto Produto a ser enviado para o firebase.
 */
// persistirProduto :: produto -> Promise Object e
export const persistirProduto = (produto, op) =>
    db
        .collection("produtos")
        .doc(produto.id)
        [op](produto)
        .then(() => produto)
        .catch(Promise.reject);

/**
 * Executa o upload da imagem.
 * Essa promise sempre resolve, com a URL da imagem, ou,
 * null em caso de falha ou não ter imagem para fazer upload.
 * @param {File} foto File a ser enviado para o firebase
 * @param {String} id UUID do produto.
 */
// uploadImage :: (File, id) -> Promise (URL | null) ()
export const uploadImage = (foto, id) =>
    !foto
        ? Promise.resolve(null)
        : new Promise((resolved) => {
              const imageRef = storage.ref().child("produtos/" + id);
              return imageRef
                  .put(foto)
                  .then((snap) => snap.ref.getDownloadURL())
                  .then(resolved)
                  .catch(() => resolved(null));
          });

/**
 * Pipeline para registrar/atualizar um produto.
 * @param {Object} context Object with the context to pass thru the pipeline.
 * @return Promise
 */
// type Context = {Produto, Foto}
// registrarProduto :: Context -> Promise Produto e

const defineUrlIfExists = (key, url) => (url == null ? {} : { [key]: url });

const uploadImageForKey = (produto, key, fotoId, file) =>
    uploadImage(file, fotoId)
        .then((url) => ({ ...produto, ...defineUrlIfExists(key, url) }))
        .catch(() => produto);

export const registrarProduto = (context, op) =>
    Promise.resolve(context)
        .then((context) =>
            uploadImageForKey(context.produto, "foto", context.produto.id, context.foto)
                .then((produto) => ({ ...context, produto: { ...produto } }))
                .catch(() => context)
        )
        .then((context) =>
            uploadImageForKey(context.produto, "planograma", v4(), context.planograma)
                .then((produto) => ({ ...context, produto: { ...produto } }))
                .catch(() => context)
        )
        .then((context) => persistirProduto(context.produto, op));

export const arquivarProduto = (idProduto) => db.collection("produtos").doc(idProduto).update({ arquivado: true });
