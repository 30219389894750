
export const defaultFont = { fontFace: 'Arial' };
export const backgroundStyle = { x: 0, y: 0, w: 10, h: 5.64 };
export const textHeader = { ...defaultFont, x: 0.3, w: 9.8, fontSize: 18, color: '452a7a' };
export const textUsuario = { ...defaultFont, x: 0.1, w: 9.8, fontSize: 14, color: 'c8b7e9' };
export const textStyle = { ...defaultFont, x: .1, w: 9.8, fontSize: 16, color: '6747a3' };
export const textItems = { ...textStyle, fontSize: 14 };


export const toInches = x => x * 0.0104166667;
export const isNaN = x => x.toString() === "NaN";
export const numberOrDefault = (x, defaultValue) => isNaN(x) ? defaultValue : x;