import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { Button, Tab } from "semantic-ui-react";
import FiltroOportunidade, { FilterButton } from "../../Componentes/FiltroOportunidade";
import { tipoDeRoteiroToOption } from "../../Utils/Oportunidades";
import {
	criarFiltro,
	roteirosExecutadosFinalizados,
	roteirosIncompletos,
	roteirosNovos,
	roteirosPendentesSemWhere,
} from "../../Utils/Roteiros";
import {
	oportunidadesExecutadas_Finalizadas,
	oportunidadesIncompletas,
	oportunidadesNovas,
	oportunidadesPendentesPaginado,
} from "../../_actions/RoteiroActions";
import { HeaderPadrao } from "./../../Componentes/Componentes";
import { DEFAULT_PER_PAGE, paginator } from "./../../Utils/RoteiroPaginator";
import Roteiro from "./Roteiro";
import RoteiroConnect from "./RoteiroConnect";
import RoteiroExecutados from "./RoteirosExecutados";
import RoteiroIncompleto from "./RoteirosIncompletos";
import RoteiroPendentes from "./RoteirosPendentes";
import Recorrencia from "./Recorrencia/ListarRecorrencia";
import { tiposDeRoteiros } from "./Roteiro";

const DATA_ATUAL = moment().format("YYYY-MM-DD");

/**
 * @name RoteirosPendentes
 * @description controla o JSX e renderiza a paginação
 */
class RoteirosPendentes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filtroAberto: false,
			carregando: false,
			tabAtiva: this.props.match.params.aba,
			redirect: true,
		};
	}

	/**
	 * @name QUERY
	 * @description query de filtro, data descrecente
	 * @param {Object} filtro controlador da query
	 */
	QUERY = filtro => {
		var query = roteirosIncompletos(this.props.empresa.uid, false);
		if (filtro.dataInicio) query = query.where("data", ">=", filtro.dataInicio);
		if (filtro.dataFinal) query = query.where("data", "<=", filtro.dataFinal);
		if (filtro.tipo && filtro.tipo.id) query = query.where("tipo.id", "==", filtro.tipo.id);
		return query.orderBy("data", "desc");
	};

	/**
	 * @name QUERY2
	 * @description query de filtro, data descrecente
	 * @param {Object} filtro controlador da query
	 */
	QUERY2 = filtro => {
		var query = roteirosNovos(this.props.empresa.uid, false);
		if (filtro.tipo && filtro.tipo.id) query = query.where("tipo.id", "==", filtro.tipo.id);
		return query.where("data", "<", DATA_ATUAL).orderBy("data", "desc");
	};

	/**
	 * @name QUERY3
	 * @description query de filtro, data descrecente
	 * @param {Object} filtro controlador da query
	 */
	QUERY3 = filtro => {
		var query = roteirosExecutadosFinalizados(this.props.empresa.uid, false);
		if (filtro.dataInicio) query = query.where("data", ">=", filtro.dataInicio);
		if (filtro.dataFinal) query = query.where("data", "<=", filtro.dataFinal);
		if (filtro.tipo && filtro.tipo.id) query = query.where("tipo.id", "==", filtro.tipo.id);
		return query.orderBy("data", "desc");
	};

	/**
	 * @name QUERY4
	 * @description query de filtro, data descrecente
	 * @param {Object} filtro controlador da query
	 */
	QUERY4 = filtro => {
		var query = roteirosPendentesSemWhere(this.props.empresa.uid, false);
		if (filtro.dataInicio) query = query.where("data", ">=", filtro.dataInicio);
		if (filtro.dataFinal) query = query.where("data", "<=", filtro.dataFinal);
		if (filtro.tipo && filtro.tipo.id) query = query.where("tipo.id", "==", filtro.tipo.id);
		return query.orderBy("data", "asc");
	};

	/**
	 * @name aplicarFiltro
	 * @description Apenas retorna o criarFiltro, já com o filtro
	 */
	aplicarFiltro = criarFiltro(">=", "<=");

	/**
	 * @name abrirFiltro
	 * @description abre o filtro
	 */
	abrirFiltro = () =>
		this.setState({
			filtroAberto: !this.state.filtroAberto,
		});

	/**
	 * @name limparDadosFiltro
	 * @description limpa todos os listener, changes e dados do paginator e apaga as oportunidades do redux
	 * @param {Function} callback da função
	 */
	limparDadosFiltro = callback => {
		paginator().offListeners();
		paginator().clear();
		callback({ data: [] });
	};

	render() {
		const { filtroAberto } = this.state;
		const { t } = this.props;
		const tiposDeRoteirosOptions = tiposDeRoteiros.map(tipoDeRoteiroToOption(t));

		const querys = {
			0: event =>
				this.aplicarFiltro(
					roteirosNovos(this.props.idEmpresa, false),
					event.filterData
				).onSnapshot(snap =>
					this.props.dispatch(oportunidadesNovas(snap.docs.map(d => d.data())))
				),
			1: event => {
				console.log(event);
				var callback = ({ data }) => {
					this.props.dispatch(oportunidadesPendentesPaginado(data));
					this.setState({ carregando: false });
				};
				this.limparDadosFiltro(callback);
				this.setState({ filtro: event.filterData }, () => {
					paginator().getMultiple(
						[this.QUERY4(this.state.filtro)],
						callback,
						DEFAULT_PER_PAGE,
						"pendentes"
					);
				});
			},
			2: event => {
				var callback = ({ data }) => {
					this.props.dispatch(oportunidadesIncompletas(data));
					this.setState({ carregando: false });
				};
				this.limparDadosFiltro(callback);
				this.setState({ filtro: event.filterData }, () => {
					paginator().getMultiple(
						[this.QUERY(this.state.filtro), this.QUERY2(this.state.filtro)],
						callback,
						DEFAULT_PER_PAGE,
						"incompleto"
					);
				});
			},
			3: event => {
				var callback = ({ data }) => {
					this.props.dispatch(oportunidadesExecutadas_Finalizadas(data));
					this.setState({ carregando: false });
				};
				this.limparDadosFiltro(callback);
				this.setState({ filtro: event.filterData }, () => {
					paginator().get(
						this.QUERY3(this.state.filtro),
						callback,
						DEFAULT_PER_PAGE,
						"executado"
					);
				});
			},
		};

		const subtitulos = {
			0: t("seus.roteiros"),
			1: t("roteiros.pendentes.descricao"),
			2: t("roteiros.execucao.incompletos"),
			3: t("roteiros.executados.descricao"),
			4: t("recorrencias.descricao"),
		};

		const panes = [
			{
				menuItem: { key: "em.aberto", icon: "wait", content: t("oportunidades.em.aberto") },
				render: () => <Roteiro />,
			},
			{
				menuItem: {
					key: "pendentes",
					icon: "tasks",
					content: t("oportunidades.Pendentes"),
				},
				render: () => <RoteiroPendentes />,
			},
			{
				menuItem: {
					key: "incompletos",
					icon: "calendar times",
					content: t("oportunidades.Incompletos"),
				},
				render: () => <RoteiroIncompleto />,
			},
			{
				menuItem: {
					key: "executados",
					icon: "calendar check",
					content: t("oportunidades.Executados"),
				},
				render: () => <RoteiroExecutados />,
			},
			{
				menuItem: {
					key: "recorrencias",
					icon: "calendar alternate",
					content: t("recorrencias"),
				},
				render: () => <Recorrencia />,
			},
		];

		const {
			match: {
				params: { aba },
			},
		} = this.props;
		const { tabAtiva, redirect } = this.state;

		if (redirect) {
			this.setState({ redirect: false });
			return <Redirect to={`/oportunidades/${tabAtiva ?? 0}`} />;
		}
		return (
			<div>
				<div className="container-header-padrao">
					<Button
						style={{ marginTop: "10px" }}
						className="purple-gradient-87"
						as={Link}
						to="/roteiro/novo"
						content={t("novo.roteiro")}
						icon="plus"
						color="violet"
						floated="right"></Button>
					<FilterButton t={t} aberto={filtroAberto} onClick={this.abrirFiltro} />

					<div style={{ width: "70%" }}>
						<HeaderPadrao
							icone="map signs"
							titulo={t("roteiros")}
							subtitulo={subtitulos[this.state.tabAtiva]}
						/>
					</div>
				</div>

				<FiltroOportunidade
					t={t}
					types={tiposDeRoteirosOptions}
					aberto={filtroAberto}
					aplicarFiltro={querys[this.state.tabAtiva]}
				/>

				<Tab
					menu={{
						color: "purple",
						secondary: true,
						pointing: true,
						className: "tab-bar-wrapped",
					}}
					panes={panes}
					activeIndex={aba}
					onTabChange={(event, data) =>
						this.setState({ redirect: true, tabAtiva: data.activeIndex })
					}
				/>
			</div>
		);
	}
}

export default RoteiroConnect(withTranslation()(RoteirosPendentes));
