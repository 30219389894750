import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Card, Grid, Icon, Input, Modal, Header } from "semantic-ui-react";
import CardPromoter from "../../Componentes/CardPromoter";
import { HeaderPadrao } from "../../Componentes/Componentes";
import ImagemCentralizada from "../../Componentes/ImagemCentralizada";
import { getToken, validarPromotorAgencia, vincularPromotorAgencia } from "../../Utils/Api";

const ModalVincularPromotor = props => {
	const {
		t,
		promotor,
		modal,
		formatFone,
		handleChangeTelefone,
		validaPromotorAgencia,
		telefone,
		carregandoValidar,
		telefoneInvalido,
		setState,
		carregandoVincular,
		vinculaPromotorAgencia,
	} = props;
	return (
		<Modal open={modal}>
			<Modal.Header>{t("vincular.promotor")}</Modal.Header>
			<Modal.Content scrolling>
				<p align="center">{t("digite.telefone.verificar")}</p>
				<p align="center">{t("apos.confirmar.vincular")}</p>
				<Modal.Description
					style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
					<Input
						maxLength={13}
						style={{ borderRadius: 10, marginRight: 20 }}
						icon="phone"
						id="telefone"
						name="telefone"
						value={formatFone(telefone)}
						onChange={handleChangeTelefone}
						iconPosition="left"
						placeholder={"DDD + " + t("telefone")}
					/>
					<Button
						disabled={!telefone}
						loading={carregandoValidar}
						style={{ height: 40 }}
						content={t("verificar")}
						onClick={() => validaPromotorAgencia()}
					/>
				</Modal.Description>
				{telefoneInvalido ? (
					<div align="center" style={{ marginTop: 20 }}>
						{t("telefone.invalido")}
					</div>
				) : promotor && (promotor.vinculado || !promotor.agencia) ? (
					<div align="center" style={{ marginTop: 20 }}>
						{promotor.vinculado ? t("promotor.ja.vinculado") : t("promotor.autonomo")}
						{" " + t("impossivel.vincular")}
					</div>
				) : (
					!_.isEmpty(promotor) && (
						<Card centered style={{ marginTop: 25, borderRadius: 15, width: "43%" }}>
							<Card.Content textAlign="center">
								<ImagemCentralizada circular src={promotor.foto} />
								<Card.Header style={{ marginTop: 15 }}>{promotor.nome}</Card.Header>
								<Card.Meta>
									<span className="date">{promotor.sexo}</span>
								</Card.Meta>
								<Card.Description>
									{promotor.rua}, {promotor.numero} - {promotor.cidade} /{" "}
									{promotor.uf} - {promotor.cep}
								</Card.Description>
							</Card.Content>
							<Card.Content extra textAlign="center">
								<Icon name="mail" />
								{promotor.email}
							</Card.Content>
						</Card>
					)
				)}
				<Modal.Description
					style={{ marginTop: 30, display: "flex", justifyContent: "center" }}>
					<Button
						style={{ height: 40, marginRight: 15 }}
						onClick={() =>
							setState({
								modal: false,
								promotor: {},
								telefone: "",
								telefoneInvalido: false,
							})
						}>
						<Icon style={{ marginRight: 8 }} name="cancel" color="red" />
						{t("cancelar")}
					</Button>
					<Button
						disabled={
							_.isEmpty(promotor) ||
							(promotor && (promotor.vinculado || !promotor.agencia))
						}
						loading={carregandoVincular}
						style={{ height: 40 }}
						onClick={() => vinculaPromotorAgencia()}>
						<Icon style={{ marginRight: 8 }} name="check" color="green" />
						{t("vincular")}
					</Button>
				</Modal.Description>
			</Modal.Content>
		</Modal>
	);
};

class Promotores extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			telefoneInvalido: false,
			telefone: "",
			promotor: null,
		};
	}

	formatFone = text => {
		let _text = text.replace(/[\D\s]+55/g, "");
		return _text.replace(/(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
	};

	handleChangeTelefone = event =>
		this.setState({ telefone: "+55" + event.target.value.replace(/[\s\D]/g, "") });

	validaPromotorAgencia = () => {
		this.setState({ carregandoValidar: true }, () => {
			getToken()
				.then(token =>
					validarPromotorAgencia(token, this.state.telefone).then(data =>
						this.setState({
							promotor: data,
							carregandoValidar: false,
							telefoneInvalido: false,
						})
					)
				)
				.catch(() =>
					this.setState({
						telefoneInvalido: true,
						carregandoValidar: false,
					})
				);
		});
	};

	vinculaPromotorAgencia = () => {
		this.setState({ carregandoVincular: true }, () => {
			getToken().then(token =>
				vincularPromotorAgencia(
					token,
					this.state.promotor.uid,
					this.props.empresa.uid
				).then(
					this.setState({
						carregandoVincular: false,
						modal: false,
						promotor: {},
						telefone: "",
					})
				)
			);
		});
	};

	handleState = obj => this.setState(obj);

	render() {
		// const { promotor } = this.state;
		const { t } = this.props;
		return (
			<>
				<ModalVincularPromotor
					{...this.props}
					{...this.state}
					setState={this.handleState}
					formatFone={this.formatFone}
					handleChangeTelefone={this.handleChangeTelefone}
					validaPromotorAgencia={this.validaPromotorAgencia}
					vinculaPromotorAgencia={this.vinculaPromotorAgencia}
				/>
				{!_.isEmpty(this.props.promotores) && (
					<Button
						onClick={() => this.setState({ modal: true })}
						style={{ marginTop: "10px" }}
						icon="plus"
						content={t("vincular.promotor")}
						color="violet"
						floated="right"
						basic
					/>
				)}
				<div style={{ marginBottom: 20 }}>
					<HeaderPadrao
						icone="map signs"
						titulo={t("meus.promotores")}
						subtitulo={t("promotores.cadastrados.agencia")}
					/>
				</div>
				{_.isEmpty(this.props.promotores) && (
					<>
						<Header as="h2" icon textAlign="center">
							<Icon name="users" disabled />
							<Header.Content>
								{t("nenhum.promotor.vinculado")}
								<Header.Subheader>
									{t("nenhum.promotor.vinculado.agencia")}
								</Header.Subheader>
							</Header.Content>
						</Header>
						<div style={{ textAlign: "center" }}>
							<Button
								onClick={() => this.setState({ modal: true })}
								icon="plus"
								content={t("vincular.promotor")}
								color="purple"
								basic
							/>
						</div>
					</>
				)}
				<Grid stackable columns={5}>
					{this.props.promotores.map((promotor, i) => (
						<Grid.Column key={i}>
							<CardPromoter esconderEscolher={true} user={promotor.data()} />
						</Grid.Column>
					))}
				</Grid>
			</>
		);
	}
}

const mapStateToProps = state => ({
	...state.AutenticacaoReducer,
});

export default connect(mapStateToProps)(withTranslation()(Promotores));
