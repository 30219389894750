import { Divider } from "@chakra-ui/react";
import { Opportunity } from "../../../../@types/Opportunity";
import { ProviderOpportunityExecutionNegotiation } from "../../../../contexts/ContextOpportunityExecutionNegotiation";
import { AlertAssociatePdvToNetwork } from "./AlertAssociatePdvToNetwork";
import { ListAgencies } from "./ListAgencies";
import { ListNegotiations } from "./ListNegotiations";

interface OpportunityNegotiationAreaProps {
	opportunity: Opportunity;
}

export function OpportunityNegotiationArea({ opportunity }: OpportunityNegotiationAreaProps) {
	const isAutomaticSelection =
		opportunity.selecaoAutomatica || opportunity.selecaoAutomaticaFavorito;

	//NOTE: Se a oportunidade estiver no modo seleção automática, não permitiremos negociações.
	if (isAutomaticSelection) return null;

	return (
		<ProviderOpportunityExecutionNegotiation opportunity={opportunity}>
			<AlertAssociatePdvToNetwork />
			<ListAgencies />
			<Divider my={5} />
			<ListNegotiations />
			<Divider my={5} />
		</ProviderOpportunityExecutionNegotiation>
	);
}
