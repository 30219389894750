import { KeyboardDatePicker } from "@material-ui/pickers";
import { evaluate } from "mathjs";
import moment from "moment";
import { useMemo } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ITopUpFormData } from "..";
import { BackendApiError } from "../../../../errors/BackendApi.error";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { chamarFunction } from "../../../../Utils/Api";
import { fCurrency } from "../../../../Utils/Number";
import { Container, DatePickerLabel, OptionIcon, PixLogo, SubmitButton, TextTooltip } from "./styles";

interface IPaymentMethodData {
    component: JSX.Element | null;
    labels: {
        avisoTaxa: string;
        avisoCompensacao: string;
        buttonIcon: string;
        buttonText: string;
    };
}

interface IInvoiceIugu {
    secure_url: string;
}

export function PaymentMethodSelected() {
    const { t } = useTranslation();
    const empresa = useAppSelector((state) => state.AutenticacaoReducer.empresa);
    const {
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = useFormContext<ITopUpFormData>();

    const dueDate = watch("dueDate");
    const paymentMethod = watch("paymentMethod");
    const value = watch("value");

    const isPix = paymentMethod === "pix";
    const isAValidDueDate = dueDate || dueDate === "Invalid date";

    /**
     * @description A função calcula quanto deve ser a recarga final removendo a taxa da Iugu
     * @param total Valor para incidir a taxa
     * @returns {number} O valor corrigido pela taxa
     */
    function calcValueWithPixRate(total: number): number {
        const result = evaluate(`${total}-0.99%`) as number;

        return result;
    }

    const onSubmit: SubmitHandler<ITopUpFormData> = async (data) => {
        try {
            const { dueDate, value } = data;
            //NOTE: Se for pix, dua_date deve ser a data de amanhã;
            const dateToUse = isPix ? moment().add(1, "day") : moment(dueDate);

            const items = [
                {
                    description: "Carga de saldo",
                    quantity: 1,
                    price_cents: value * 100, //NOTE: Convertemos o valor numérico para representar centavos
                },
            ];

            //NOTE: Se for boleto, adicionamos seu custo fixo
            if (!isPix) {
                items.push({
                    description: "Custo do boleto",
                    quantity: 1,
                    price_cents: 219,
                });
            }

            //NOTE: Criamos a fatura do usuário.
            const result: IInvoiceIugu = await chamarFunction("Company-topUpBalance", {
                id: empresa.uid,
                due_date: dateToUse.format("YYYY-MM-DD"),
                email: empresa.email,
                fantasia: empresa.fantasia,
                items,
                payable_with: data.paymentMethod,
                razaoSocial: empresa.razaoSocial,
                payer: {
                    address: {
                        city: empresa.endereco.cidade,
                        district: empresa.endereco.bairro,
                        number: empresa.endereco.numero,
                        state: empresa.endereco.estado,
                        zip_code: empresa.endereco.cep,
                        street: empresa.endereco.logradouro,
                    },
                    cpf_cnpj: empresa.cnpj,
                    email: empresa.email,
                    name: empresa.fantasia,
                    phone: String(empresa.telefone).replaceAll(" ", ""),
                },
            });

            //NOTE: Ao terminar, exibimos a fatura para o usuário
            setValue("invoiceUrl", result.secure_url, { shouldDirty: true });
        } catch (error) {
            new BackendApiError(error, {
                options: { type: "error" },
            });
        }
    };

    const currentPaymentMethodOptionData = useMemo<IPaymentMethodData | null>(() => {
        switch (paymentMethod) {
            case "bank_slip":
                return {
                    component: (
                        <>
                            <DatePickerLabel>{t("data.vencimento")}</DatePickerLabel>
                            <KeyboardDatePicker
                                format="DD/MM/YYYY"
                                margin="normal"
                                value={dueDate}
                                minDate={new Date()}
                                onChange={(e) => setValue("dueDate", e?.toDate() || null, { shouldDirty: true })}
                                KeyboardButtonProps={{
                                    "aria-label": t("mudar.data"),
                                }}
                            />
                        </>
                    ),
                    labels: {
                        avisoTaxa: t("custo.boleto"),
                        avisoCompensacao: t("boleto.pode.levar.48.horas"),
                        buttonIcon: "ic:outline-barcode",
                        buttonText: t("gerar-boleto"),
                    },
                };
            case "pix":
                const valueWithTaxeFormatted = fCurrency(calcValueWithPixRate(value));
                const valueFormatted = fCurrency(value);

                return {
                    component: <PixLogo />,
                    labels: {
                        avisoTaxa: t("aviso-taxa-pix", {
                            0: valueFormatted,
                            1: valueWithTaxeFormatted,
                        }),
                        avisoCompensacao: t("aviso-compensacao-pix"),
                        buttonIcon: "ic:baseline-pix",
                        buttonText: t("gerar-pix"),
                    },
                };
            default:
                return null;
        }
    }, [dueDate, paymentMethod, setValue, t, value]);

    if (!currentPaymentMethodOptionData) return null;

    return (
        <Container>
            {currentPaymentMethodOptionData.component}

            <SubmitButton onClick={handleSubmit(onSubmit)} loading={isSubmitting} disabled={!isAValidDueDate}>
                <OptionIcon icon={currentPaymentMethodOptionData.labels.buttonIcon} />
                {currentPaymentMethodOptionData.labels.buttonText}
            </SubmitButton>

            <div>
                <TextTooltip>{currentPaymentMethodOptionData.labels.avisoTaxa}</TextTooltip>
                <TextTooltip>{currentPaymentMethodOptionData.labels.avisoCompensacao}</TextTooltip>
            </div>
        </Container>
    );
}
