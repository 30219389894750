import firebase from "firebase";
import { Company, CompanyVariant } from "../../../@types/Company";
import { IPdv } from "../../../@types/Pdv";
import { getBestMatchOperationNetwork } from "../../../helpers/OperationNetwork";
import { removeDuplicates } from "../../../Utils/Array";
import { OperationAreaService } from "../../OperationArea";

export class CompanyService {
	private static instance: CompanyService;
	public collectionName = "empresas";

	public static getInstance(): CompanyService {
		if (!CompanyService.instance) {
			CompanyService.instance = new CompanyService();
		}
		return CompanyService.instance;
	}

	private constructor() {}

	/**
	 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
	 * @description Função para buscar agências por ids.
	 * @param ids Ids das agências que devem ser retornadas.
	 * @returns Uma Promise com um array de agências.
	 * @obs Se `ids`tiver mais de 10 itens, ele faz uma busca de 10 em 10 usando recursão.
	 */
	async getAgenciesContainingIds(ids: string[]): Promise<Company[]> {
		if (ids.length <= 10) {
			const query = firebase
				.firestore()
				.collection(this.collectionName)
				.where("tipo", "==", CompanyVariant.AGENCIA)
				.where("uid", "in", ids);

			const { docs } = await query.get();

			return docs.map(doc => doc.data() as Company);
		} else {
			//NOTE: Se o array tiver mais de 10 itens, divide o array em chunks de 10 itens. Para fazer mais buscas e evitar o problema de limite de busca com `in` do Firebase.
			const idsChunks = ids.reduce<string[][]>(
				(chunks, _, index) =>
					index % 10 === 0 ? [...chunks, ids.slice(index, index + 10)] : chunks,
				[]
			);

			//NOTE: Faz uso de recursão para buscar de 10 em 10 itens.
			const promises = idsChunks.map(chunkIds => this.getAgenciesContainingIds(chunkIds));

			const agencies = await Promise.all(promises).then(res => res.flat());

			return agencies;
		}
	}

	async getAgenciesByOperationArea(
		companyId: string,
		opportunityPdv: {
			network: IPdv["rede"];
			companyName: string;
			companyfantasy: string;
			lat: number;
			long: number;
		}
	) {
		//NOTE: Busca todas as áreas de atuação que batem com a coordenada da loja da oportunidade.
		const operationAreaIds =
			await OperationAreaService.getInstance().getOperationAreasContainingCoordinate(
				{
					_lat: opportunityPdv.lat,
					_long: opportunityPdv.long,
				},
				companyId
			);

		//NOTE: Se não achar nenhuma área, retorna uma lista vazia.
		if (!operationAreaIds.length)
			return {
				agencies: [],
				matchedNetwork: null,
			};

		//NOTE: Busca todas as agências associadas as áreas de atuação
		const agencies = await this.getAgenciesContainingIds(operationAreaIds);

		//NOTE: Separa uma lista de redes únicas.
		const networks = removeDuplicates(
			agencies.map(agency => agency.redesDeAtuacao || []).flat(),
			(a, b) => a.id === b.id
		);

		//NOTE: Determina qual a rede mais combina ao PDV e seta ela no estado local.
		const matchedNetwork = getBestMatchOperationNetwork(
			{
				fantasia: opportunityPdv.companyfantasy,
				razaoSocial: opportunityPdv.companyName,
				rede: opportunityPdv.network,
			},
			networks
		);

		return {
			agencies,
			matchedNetwork,
		};
	}
}
