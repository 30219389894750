import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { Loader, Message, Segment } from "semantic-ui-react";
import { db } from "../../../config";
import { Header } from "./Header/index";
import { LeftContent } from "./LeftContent";
import { RightContent } from "./RightContent";
import { Container, Content } from "./styles";

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

interface User {
    uid: string;
    imagemURL?: string;
    foto?: string;
    nome: string;
    sexo: string;
    mediaAvaliacao?: number;
    quantidadeAvaliacao?: number;
    tipo: number;
    oportunidadesExecutadas?: number;
    fantasia: string;
    redes: [];
    cursos: [];
    agencia: any;
}

interface Promoter {
    fantasia: string;
    id: string;
    status: string;
}

interface Company {
    bloqueioPromotor: boolean;
    //NOTE:Fiz desse jeito pq está assim no banco, mas acredito que selecaoAutomatica, deveria ser um array de Promotores e não um objeto onde cada chave é um objeto promotor utilizando o id dele
    selecaoAutomatica: { [key: string]: Promoter };
    uid: string;
    fantasia: string;
}

//NOTE: Tipagem do redux deve ser feita futuramente
const mapStateToProps = (state: {
    AutenticacaoReducer: {
        empresa: Company;
    };
}) => ({
    company: state.AutenticacaoReducer.empresa,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const UserProfile = ({ company }: PropsFromRedux) => {
    const [loading, setLoading] = useState(false);
    const [oportunidadesExecutadas, setOportunidadesExecutadas] = useState(0);
    const [user, setUser] = useState<User | null>(null);
    const [assessments, setAssessments] = useState([]);
    const [opportunities, setOpportunities] = useState([]);

    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const ref = uuidRegex.test(id) ? db.collection("empresas").doc(id) : db.collection("perfis").doc(id);
        setLoading(true);

        const snapshotUnsubscribe = ref.onSnapshot((snap) => {
            if (snap.exists) {
                const data = snap.data() as any;
                setUser(data);
                setLoading(false);

                ref.collection("avaliacoes")
                    .limit(10)
                    .orderBy("data", "desc")
                    .onSnapshot((snap) => {
                        const assessments = snap.docs.map((doc) => doc.data()) as never[];
                        setAssessments(assessments);
                    });

                db.collection("roteiros")
                    .where("usuario.uid", "==", id)
                    .where("arquivado", "==", false)
                    .where("statusRoteiro.id", "in", [3, 5])
                    .orderBy("data", "desc")
                    .onSnapshot((snap) => {
                        const opportunities = snap.docs.map((doc) => doc.data()) as never[];
                        setOportunidadesExecutadas(opportunities.length);
                        setOpportunities(opportunities.slice(0, 3));
                    });
            }

            setLoading(false);
        });

        return function cleanup() {
            return snapshotUnsubscribe();
        };
    }, [id, setUser, setAssessments, setOpportunities, setLoading]);

    if (loading) {
        return (
            <Segment basic className="page-loading">
                <Loader style={{ display: "block" }} inline="centered">
                    {t("carregando")}
                </Loader>
            </Segment>
        );
    }

    if (!user) {
        return <Message content={t("usuario.inexistente")} color="brown" />;
    }

    return (
        <Container>
            <Header company={company} user={user} />
            <Content>
                <LeftContent
                    metricData={[user.quantidadeAvaliacao ? user.quantidadeAvaliacao : 0, oportunidadesExecutadas]}
                    assessmentsData={assessments}
                />
                <RightContent
                    promoterName={user.nome}
                    opportunities={opportunities as []}
                    networks={user.redes}
                    courses={user.cursos}
                />
            </Content>
        </Container>
    );
};

export default connector(UserProfile);
