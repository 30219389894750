import React from "react";
import { Courses } from "./Courses";
import { Networks } from "./Networks";
import { Opportunity } from "./Opportunity";
import { Container } from "./styles";

interface RightContentProps {
    promoterName: string;
    //NOTE: Tipar esses arrays quando descobrir certinho oq tem em cada um
    opportunities: [];
    networks: [];
    courses: [];
}

export function RightContent({ promoterName, opportunities, networks, courses }: RightContentProps) {
    return (
        <Container>
            <Opportunity opportunities={opportunities} promoterName={promoterName} />
            <Networks networks={networks} />
            <Courses courses={courses} />
        </Container>
    );
}
