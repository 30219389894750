import { Heading } from "@chakra-ui/react";
import { Divider, Zoom } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { Component } from "react";
import Currency from "react-currency-formatter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Loader, Message, Segment } from "semantic-ui-react";
import { db } from "../../config";
import { actionSalvarFaturas } from "../../_actions/PagamentoActions";
import FaturamentoMensal from "./FaturamentoMensal";

//TODO alterar os elementos da listagens para os dois tipos de faturas do array

const ListarFaturas = props => {
	const { faturas, t } = props;

	const status = {
		pending: props.t("pendente"),
		paid: props.t("pago"),
		expired: props.t("expirado"),
		refunded: props.t("reembolsado"),
		canceled: props.t("cancelado"),
	};

	const cor = {
		pending: "#fbc02d",
		paid: "#35BE35",
		expired: "#CCC",
		refunded: "#F44336",
		canceled: "#F44336",
	};

	return _.orderBy(faturas, ["created_at_iso", "data"], ["desc", "desc"]).map((fatura, index) =>
		fatura.created_at ? (
			<a
				style={{ color: "inherit" }}
				target="_blank"
				href={fatura.secure_url || ""}
				content={t("pdf")}>
				<Zoom
					in={!_.isEmpty(faturas)}
					mountOnEnter
					unmountOnExit
					style={{
						transitionDelay: !_.isEmpty(faturas) ? 50 + index * 40 + "ms" : "0ms",
					}}>
					<div style={styles.segment}>
						<div>
							<span style={styles.texto}>{t("boleto.tipo")}</span>
							<br></br>
							<b style={styles.data}>{t("boleto.recarga")}</b>
						</div>
						<div>
							<span style={styles.texto}>{t("boleto.data")}</span>
							<br></br>
							<b style={styles.data}>
								{moment(fatura.created_at_iso).format("DD/MM/YYYY")}
							</b>
						</div>
						<div>
							<span style={styles.texto}>{t("data.vencimento")}</span>
							<br></br>
							<b style={styles.data}>
								{moment(fatura.due_date).format("DD/MM/YYYY")}
							</b>
						</div>
						<b style={styles.data}>
							<span>{fatura.total}</span>
						</b>

						<div
							style={{
								...styles.pago,
								backgroundColor: cor[fatura.status],
							}}>
							<span style={styles.textoPago}>{status[fatura.status]}</span>
						</div>
					</div>
				</Zoom>
			</a>
		) : (
			<Link
				key={index}
				style={styles.link}
				to={{
					pathname: `/fatura/${fatura.referencia}`,
					fatura: fatura,
				}}>
				<Zoom
					in={!_.isEmpty(faturas)}
					mountOnEnter
					unmountOnExit
					style={{
						transitionDelay: !_.isEmpty(faturas) ? 50 + index * 40 + "ms" : "0ms",
					}}>
					<div style={styles.segment}>
						<div>
							<span style={styles.texto}>{t("boleto.tipo")}</span>
							<br></br>
							<b style={styles.data}>
								{fatura.created_at_iso ? t("boleto.recarga") : t("boleto.mensal")}
							</b>
						</div>
						<div>
							<span style={styles.texto}>
								{fatura.created_at_iso ? t("boleto.data") : t("mes.referencia")}
							</span>
							<br></br>
							<b style={styles.data}>
								{fatura.created_at_iso
									? moment(fatura.created_at_iso).format("DD/MM/YYYY")
									: fatura?.referencia?.substring(0, 2) +
											"/" +
											fatura?.referencia?.substring(2, 6) +
											" " +
											fatura?.referencia?.substring(7, 9) ?? ""}
							</b>
						</div>
						<div>
							<span style={styles.texto}>{t("data.vencimento")}</span>
							<br></br>
							<b style={styles.data}>{moment(fatura.boleto?.due_date).format("L")}</b>
						</div>
						<b style={styles.data}>
							{fatura.secure_url ? (
								<span>{fatura.total}</span>
							) : (
								<Currency
									quantity={parseFloat(fatura?.total) ?? 0}
									locale="pt_BR"
									decimal=","
									group="."
									currency="R$ "
								/>
							)}
						</b>

						{fatura.secure_url ? (
							<div
								style={{
									...styles.pago,
									backgroundColor: cor[fatura.status],
								}}>
								<span style={styles.textoPago}>{status[fatura.status]}</span>
							</div>
						) : (
							<div
								style={{
									...styles.pago,
									backgroundColor: fatura.pago ? "#35BE35" : "#F44336",
								}}>
								<span style={styles.textoPago}>
									{fatura.pago ? t("pago") : t("nao.pago")}
								</span>
							</div>
						)}
					</div>
				</Zoom>
			</Link>
		)
	);
};

class Faturas extends Component {
	state = {
		faturas: [],
		loading: true,
		lista: [],
	};

	componentDidMount() {
		this.listar();
	}

	//Salva as faturas da iugu na lista de faturas
	retornoLista = body => console.log(body.items);

	//Inicia a busca de faturas.
	//Busca de faturas na iugu foi comentada por @Renahn Schultz em 07/08/2024
	listar = async () => {
		this.setState({ loading: true });
		try {
			// await chamarFunction("listarFaturas", {
			//     tipo: "empresas",
			//     uid: this.props.empresa.uid,
			//     empresa: this.props.empresa,
			// }).then(this.retornoLista);
			this.buscarMinhasFaturas();
		} catch (error) {
			console.log(error);
			this.setState({ loading: false });
		}
	};

	//Busca as faturas no firebase
	buscarMinhasFaturas = async () => {
		// this.setState({ loading: true });
		if (_.isEmpty(this.props.faturas)) {
			console.log("buscou");
			await db
				.collection("empresas")
				.doc(this.props.empresa.uid)
				.collection("faturas")
				.get()
				.then(snap => {
					const faturas = [];
					snap.docs.forEach(doc => faturas.push(doc.data()));
					this.props.actionSalvarFaturas(faturas);
					faturas.forEach(item => {
						this.setState(prevState => ({
							faturas: [...prevState.faturas, item],
						}));
					});
				})
				.catch(e => {
					console.log(e);
					this.setState({ loading: false });
				});
		} else {
			this.setState(prevState => ({
				faturas: [...prevState.faturas, ...this.props.faturas],
			}));
		}
		this.setState({ loading: false });
	};

	render() {
		const { t, empresa } = this.props;
		const { faturas, loading } = this.state;

		return (
			<div>
				<FaturamentoMensal {...this.props} />

				{empresa?.faturamentoMensal === true && empresa?.valorMaximoOportunidades && (
					<>
						<Heading as="h3" size="md" my={2}>
							{t("minhas.faturas")}
						</Heading>
						<Divider />
						{loading ? (
							<Segment basic className="page-loading">
								<Loader style={{ display: "block" }} inline="centered">
									{t("carregando")}
								</Loader>
							</Segment>
						) : faturas.length === 0 ? (
							<Message content={t("nenhuma.fatura.encontrada")} />
						) : (
							<ListarFaturas faturas={faturas} t={t} />
						)}
					</>
				)}
			</div>
		);
	}
}

const styles = {
	segment: {
		marginTop: 10,
		borderRadius: 5,
		padding: 10,
		backgroundColor: "#fafafa",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	link: {
		display: "block",
		color: "#333",
	},
	data: {
		fontSize: 16,
	},
	texto: {
		textTransform: "uppercase",
		fontSize: 12,
	},
	pago: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 120,
		height: 30,
		borderRadius: 3,
	},
	textoPago: {
		textTransform: "uppercase",
		fontSize: 16,
		color: "white",
	},
};

const mapStateToProps = state => ({
	faturas: state.PagamentoReducer.faturas,
});

export default connect(mapStateToProps, {
	actionSalvarFaturas,
})(Faturas);
