export class GenericError extends Error {
	constructor(data: string | unknown) {
		console.error("GenericError: ", data);

		if (data instanceof Error) {
			return data;
		}

		if (typeof data === "string") {
			super(data);
			Object.setPrototypeOf(this, GenericError.prototype);
			return;
		}

		return new GenericError("Ocorreu um erro inesperado. Tente novamente mais tarde.");
	}
}
