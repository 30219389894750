import React from 'react';
import { Segment, Header, Message } from 'semantic-ui-react';

export default props => {
    const { roteiro, t } = props
    return (
        <section className="segment-descricao">
            <Header as="h2" align="center">{t("descricao.oportunidade")}</Header>
            {roteiro.descricao === "" ?
                <Message><em>"{t("descricao.nao.existe")}"</em></Message>
                :
                <Segment className="descricao-roteiro">
                    {roteiro.descricao}    
                </Segment>
            }
        </section>
    )
}