import { TRANSACOES } from "./Types";

// const op = { "0": 1, "1": -1 };

export const transacoes = snap => {
    // const txs = snap.docs.reduce(({ gasto, txs }, doc) => {
    //     if (doc.exists) {
    //         const data = doc.data();
    //         if(!data.tipo) return { gasto, txs };
    //         const valorRoteiro = (op[data.tipo.toString()] || 1) * (parseFloat(data.valorTotal) || 0);
    //         return { gasto: gasto + valorRoteiro, txs: txs.concat(data) };
    //     }
    // }, { gasto: 0, txs: [] });

    return ({
        type: TRANSACOES,
        // totalGasto: txs.gasto,
        transacoes: snap
    });
}
