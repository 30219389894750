import { v4 } from "uuid";
import moment from "moment";
import { listaNome } from "./importarProdutos";
import { logging } from "../../services/Api";

/**
 * @name reCadastrarProdutosEmUmaNovaEmpresa
 * @date Criado em 15/12/2020
 * @author Diego jimenes
 * @description faz as modificações necessária para o recadastramento
 * @param { Array } produtos Lista de produtos para importação
 * @param { String } idNovaEmpresa id da empresa que vai receber o produto
 * @param { Function } firebase
 * @param { Object } empresa Empresa que fez a importação
 * @param { String } origem empresa que vai vir os produtos
 * @param { Object } ipLocation ip da maquina que mandou
 * @param { Object } lista Objeto com todos os ID das empresas da triunfante
 * @param { Function } log função de log
 */
const reCadastrarProdutosEmUmaNovaEmpresa = (
	produto,
	idNovaEmpresa,
	firebase,
	empresa,
	origem,
	ipLocation,
	lista = listaNome,
	log = logging
) => {
	let origemEmpresa = lista[origem];
	let empresaLogin = lista[empresa.uid];
	let novaEmpresa = lista[idNovaEmpresa];
	let id = v4();
	let data = moment().format("YYYY-MM-DD HH:mm:ss");
	let dataFormatada = moment().format("DD/MM/YYYY");
	return firebase
		.firestore()
		.collection("produtos")
		.doc(id)
		.set({ ...produto, id: id, idEmpresa: idNovaEmpresa, cadastradoEm: data })
		.then(() => {
			log(
				"CREATE",
				`Produto ${produto._id} 
            importado de ${origemEmpresa} 
            para ${novaEmpresa} no dia 
            ${dataFormatada} pelo login de 
            ${empresaLogin} ${empresa.email}`,
				produto._id
			);
		});
};

/**
 * @name recadastrar_n_empresas
 * @date Criado em 15/12/2020
 * @author Diego jimenes
 * @description percorre uma lista de empresas para iniciar o recadastramento
 * @param { Array } produtos Lista de produtos para importação
 * @param { Array } empresas Lista de empresas que devem receber os produtos
 * @param { Function } firebase
 * @param { Object } empresa Empresa que fez a importação
 * @param { String } origem empresa que vai vir os produtos
 * @param { Object } ipLocation ip da maquina que mandou
 */
const recadastrar_n_empresas = (produto, empresas, firebase, empresa, origem, ipLocation) => {
	return empresas.reduce((promise, idNovaEmpresa) => {
		return promise.then(() => {
			return reCadastrarProdutosEmUmaNovaEmpresa(
				produto,
				idNovaEmpresa,
				firebase,
				empresa,
				origem,
				ipLocation
			);
		});
	}, Promise.resolve());
};

/**
 * @name percorrerProdutos
 * @date Criado em 15/12/2020
 * @author Diego jimenes
 * @description percorre uma lista de produtos para iniciar o recadastramento
 * @param { Array } produtos Lista de produtos para importação
 * @param { Array } empresas Lista de empresas que devem receber os produtos
 * @param { Function } firebase
 * @param { Object } empresa Empresa que fez a importação
 * @param { String } origem empresa que vai vir os produtos
 * @param { Object } ipLocation ip da maquina que mandou
 */
const percorrerProdutos = (produtos, empresas, firebase, empresa, origem, ipLocation) => {
	return produtos.reduce((promise, produto) => {
		return promise.then(() => {
			return recadastrar_n_empresas(produto, empresas, firebase, empresa, origem, ipLocation);
		});
	}, Promise.resolve());
};

/**
 * @name recadastrar_n_empresas
 * @date Criado em 15/12/2020
 * @author Diego jimenes
 * @description faz as importação de produtos em varias empresas
 * @param { Array } produtos Lista de produtos para importação
 * @param { Array } empresas Lista de empresas que devem receber os produtos
 * @param { Function } firebase
 * @param { Object } empresa Empresa que fez a importação
 * @param { String } origem empresa que vai vir os produtos
 * @param { Object } ipLocation ip da maquina que mandou
 */
export default (empresas, produtos, firebase, empresa, origem, ipLocation) =>
	percorrerProdutos(produtos, empresas, firebase, empresa, origem, ipLocation);
