import styled from "styled-components";

interface propsTexto {
	fontSize: string;
	fontWeight: string;
	color?: string;
}
export const Container = styled.div`
	align-itens: center;
	justify-content: center;
	padding: 20px;
	text-align: center;
`;
export const Text = styled.p<propsTexto>`
	font-size: ${props => props.fontSize};
	font-weight: ${props => props.fontWeight};
	color: ${props => (props.color ? props.color : "black")};
`;
