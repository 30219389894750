import { PayloadAction } from "@reduxjs/toolkit";
import update from "immutability-helper";
import { SliceStateProps } from "../../@types/store/Store";

interface GenerateDefaultReducersReturn<T, F = boolean> {
    setFetching: (
        state: SliceStateProps<T, F>,
        action: PayloadAction<SliceStateProps<T, F>["isFetching"]>
    ) => SliceStateProps<T, F>;
    setError: (
        state: SliceStateProps<T, F>,
        action: PayloadAction<SliceStateProps<T, F>["error"]>
    ) => SliceStateProps<T, F>;
    setInitialFetchWasMade: (state: SliceStateProps<T, F>, action: PayloadAction<boolean>) => SliceStateProps<T, F>;
    setInitialStateHasBeenLoaded: (
        state: SliceStateProps<T, F>,
        action: PayloadAction<boolean>
    ) => SliceStateProps<T, F>;
    setData: (
        state: SliceStateProps<T, F>,
        action: PayloadAction<SliceStateProps<T, F>["data"]>
    ) => SliceStateProps<T, F>;
    reFetch: (state: SliceStateProps<T, F>) => SliceStateProps<T, F>;
}

export function generateDefaultReducers<T, F = boolean>(): GenerateDefaultReducersReturn<T, F> {
    return {
        setFetching: (state, action) => update(state, { isFetching: { $set: action.payload } }),
        setError: (state, action) => update(state, { error: { $set: action.payload } }),
        setInitialFetchWasMade: (state, action) => {
            if (action.payload) {
                return update(state, { initialFetchWasMade: { $set: action.payload } });
            } else {
                return update(state, {
                    initialFetchWasMade: { $set: action.payload },
                    initialStateHasBeenLoaded: { $set: action.payload },
                });
            }
        },
        setInitialStateHasBeenLoaded: (state, action) => {
            if (action.payload) {
                return update(state, { initialStateHasBeenLoaded: { $set: action.payload } });
            } else {
                return update(state, {
                    initialFetchWasMade: { $set: action.payload },
                    initialStateHasBeenLoaded: { $set: action.payload },
                });
            }
        },
        setData: (state, action) => update(state, { data: { $set: action.payload } }),
        reFetch: (state) => ({
            ...state,
            initialFetchWasMade: false,
            initialStateHasBeenLoaded: false,
            error: undefined,
        }),
    };
}
