import React, { CSSProperties } from "react";
import { Container } from "./styles";

const companyImageDefault = require("../../imagens/imagem-padrao-empresa.png");

interface AvatarProps {
    circular: boolean;
    styles?: CSSProperties;
    src?: string;
    autoSize?: boolean;
    borderRadius?: number;
}

export const Avatar = ({ styles, circular = true, autoSize = false, borderRadius = 0, src }: AvatarProps) => {
    return (
        <Container
            borderRadius={borderRadius}
            autoSize={autoSize}
            styles={{ ...styles }}
            circular={circular}
            src={src ? src : companyImageDefault.default}
            onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = companyImageDefault.default;
            }}
        />
    );
};
