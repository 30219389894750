import React from "react";
import { useTranslation } from "react-i18next";
import { Opportunity } from "../index";
import { Activities, Container, Hour } from "./styles";

interface OpportunityAreaInformationProps {
    opportunity: Opportunity;
}

export function OpportunityAreaInformation({ opportunity }: OpportunityAreaInformationProps) {
    const { t } = useTranslation();

    return (
        <Container>
            <Hour opportunityType={opportunity.tipo.id}>
                {opportunity.horaInicio} - {opportunity.horaFim}
            </Hour>

            {!(opportunity.tipo.id === 5) ? (
                opportunity.secoes ? (
                    <Activities>
                        {Object.keys(opportunity.secoes).length}{" "}
                        {Object.keys(opportunity.secoes).length > 1 ? t("secoes") : t("secao")}
                    </Activities>
                ) : (
                    <Activities>
                        {opportunity.atividades.length}{" "}
                        {opportunity.atividades.length > 1 ? t("atividades") : t("atividade")}
                    </Activities>
                )
            ) : null}
        </Container>
    );
}
