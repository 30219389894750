import React, { useMemo } from "react";
import Currency from "react-currency-formatter";
import { useTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";
import { roteiroRefunded } from "../../../Utils/Roteiros";
import { Opportunity, OpportunitySegmentVariant, OpportunitySegmentMode } from "../index";
import { Container, ContainerNewOpportunity, ContentOpportunityValue, RefundedValue } from "./styles";

interface ValueAreaProps {
    opportunity: Opportunity;
    type: OpportunitySegmentVariant;
    mode: OpportunitySegmentMode;
}

export function ValueAndMaterialsArea({ opportunity, type, mode }: ValueAreaProps) {
    const { t } = useTranslation();
    const materialsShouldBeSeen = useMemo(
        () => opportunity.tipo.id === 2 || opportunity.tipo.id === 3,
        [opportunity.tipo.id]
    );

    const isRefunded = useMemo(() => roteiroRefunded(opportunity), [opportunity]);

    const componentValue = useMemo(() => {
        const OpportunityValue = <Currency quantity={opportunity.valor} decimal="," group="." currency="BRL" />;

        switch (type) {
            case "new":
                const quantCandidates = opportunity?.candidatos?.length ?? 0;
                return (
                    <ContainerNewOpportunity>
                        {OpportunityValue}
                        {opportunity.statusRoteiro.id !== 2 && (
                            <ContentOpportunityValue>
                                {opportunity.selecaoAutomatica ? (
                                    <Popup
                                        content={t("selecao.automatica.pop")}
                                        position="top center"
                                        trigger={<span>{t("aprovacao.automatica")}</span>}
                                    />
                                ) : (
                                    `${quantCandidates} ${t(quantCandidates > 1 ? "candidatos" : "candidato")}`
                                )}
                            </ContentOpportunityValue>
                        )}
                    </ContainerNewOpportunity>
                );
            case "incomplete":
                return isRefunded ? (
                    <RefundedValue content={t("valor.reembolsado")} trigger={OpportunityValue} />
                ) : (
                    OpportunityValue
                );
            default:
                return OpportunityValue;
        }
    }, [type, isRefunded, opportunity, t]);

    return (
        <Container>
            {mode === "company" ? (
                componentValue
            ) : (
                <Currency quantity={opportunity.pagamento.valorPromotor} decimal="," group="." currency="BRL" />
            )}
            {materialsShouldBeSeen && (
                <ContentOpportunityValue>
                    {opportunity?.material?.fornecido ? <Icon name="check" /> : <Icon name="times" />}
                    {t("materiais")}
                </ContentOpportunityValue>
            )}
        </Container>
    );
}
