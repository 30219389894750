import { useQuery } from "@tanstack/react-query";
import firebase from "firebase";
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MarkOptional } from "ts-essentials";
import { Company, OperationNetworkInCompany } from "../../@types/Company";
import { Negotiation } from "../../@types/Negotiation";
import { Opportunity } from "../../@types/Opportunity";
import { CompanyService } from "../../services/firebase/Company";

/**
 * Defina o tipo que o contexto retorna
 */
interface ContextOpportunityExecutionNegotiationProps {
	//NOTE: Negociações
	negotiations: Negotiation[];
	getNegotiationByAgencyId(id: string): Negotiation | null;

	//NOTE: Agências
	agencyIsLoading: boolean;

	/**
	 * Array de objetos que faz a junção entre negociação e agências
	 */
	joinBetweenNegotiationAndAgency: AgencyWithNegotiation[];

	/**
	 * Oportunidade associada as negociações e as agências
	 */
	opportunity: Opportunity;

	/**
	 * Usado para determinar se a rede de atuação associada ao PDV foi escolhida manualmente ou determinada por uma heurística.
	 */
	hasDefaultNetwork: boolean;

	/**
	 * Rede associada ao PDV
	 *
	 * Pode ser por julgamento heurístico ou escolhida manualmente pelo usuário durante a edição do PDV
	 */
	matchedNetwork: MarkOptional<OperationNetworkInCompany, "palavrasChave"> | null;
}

/**
 * Define o tipo que o Provider precisa receber
 */
interface ProviderOpportunityExecutionNegotiationProps {
	children: ReactNode;
	opportunity: Opportunity;
}

/**
 * Define um tipo de objeto que engloba tanto a agência com uma negociação
 */
export interface AgencyWithNegotiation {
	agency: Company & { mediaAvaliacaoCalculada: number; estaCredenciadaNaRede: boolean };
	negotiation: Negotiation | null;
}

//NOTE: Crie o contexto
const ContextOpportunityExecutionNegotiation =
	createContext<ContextOpportunityExecutionNegotiationProps>(
		{} as ContextOpportunityExecutionNegotiationProps
	);

//NOTE: Crie o provedor do contexto
export function ProviderOpportunityExecutionNegotiation({
	children,
	opportunity,
}: ProviderOpportunityExecutionNegotiationProps) {
	const { t } = useTranslation();

	const [negotiations, setNegotiations] = useState<Negotiation[]>([]);

	const { data: queryData, isLoading: agencyIsLoading } = useQuery({
		queryKey: [
			"agencies",
			opportunity.idEmpresa,
			opportunity.loja.fantasia,
			opportunity.loja.razaoSocial,
			opportunity.loja.endereco.coordenada._lat,
			opportunity.loja.endereco.coordenada._long,
			opportunity.loja.rede,
		],
		queryFn: () =>
			CompanyService.getInstance().getAgenciesByOperationArea(opportunity.idEmpresa, {
				companyfantasy: opportunity.loja.fantasia,
				companyName: opportunity.loja.razaoSocial,
				lat: opportunity.loja.endereco.coordenada._lat,
				long: opportunity.loja.endereco.coordenada._long,
				network: opportunity.loja.rede,
			}),
		staleTime: 1000 * 60 * 15, // 15 minutos até precisar fazer fetch de novo.
		meta: {
			errorMessage: t("ocorreu-um-erro-ao-buscar-as-agencias-tente-novamente-mais-tarde"),
		},
	});

	const getNegotiationByAgencyId = useCallback(
		(id: string) => {
			return negotiations.find(negotiation => negotiation.idExecutor === id) || null;
		},
		[negotiations]
	);

	const fetchNegotiations = useCallback(() => {
		return firebase
			.firestore()
			.collection("roteiros")
			.doc(opportunity.id)
			.collection("negociacoes")
			.onSnapshot(data => {
				const transformedNegotiations = data.docs.map(doc => doc.data() as Negotiation);
				setNegotiations(transformedNegotiations);
			});
	}, [opportunity.id]);

	const joinBetweenNegotiationAndAgency = useMemo<AgencyWithNegotiation[]>(() => {
		//NOTE: Filtra por agências que não são candidatas
		const filteredAgencies =
			queryData?.agencies?.filter(
				agency => !opportunity.candidatos?.includes("E-" + agency.uid)
			) || [];

		const transformedAgencies = filteredAgencies.map(agency => {
			return {
				agency: {
					...agency,
					valorPorHora: agency.valorPorHora || 0,
					mediaAvaliacaoCalculada:
						(agency.mediaAvaliacao || 0) / (agency.quantidadeAvaliacao || 1),
					estaCredenciadaNaRede:
						agency.redesDeAtuacao?.some(
							rede => rede.id === queryData?.matchedNetwork?.id
						) || false,
				},
				negotiation: getNegotiationByAgencyId(agency.uid),
			};
		});

		return transformedAgencies;
	}, [
		getNegotiationByAgencyId,
		opportunity.candidatos,
		queryData?.agencies,
		queryData?.matchedNetwork?.id,
	]);

	useEffect(() => {
		const unsubscribeFn = fetchNegotiations();

		return () => {
			unsubscribeFn();
		};
	}, [fetchNegotiations]);

	const contextValue: ContextOpportunityExecutionNegotiationProps = {
		negotiations,
		getNegotiationByAgencyId,
		agencyIsLoading,
		joinBetweenNegotiationAndAgency,
		opportunity,
		hasDefaultNetwork: !!opportunity.loja.rede,
		matchedNetwork: queryData?.matchedNetwork || null,
	};

	return (
		<ContextOpportunityExecutionNegotiation.Provider value={contextValue}>
			{children}
		</ContextOpportunityExecutionNegotiation.Provider>
	);
}

/**
 * Hook que acessa o contexto ContextOpportunityExecutionNegotiation
 */
export const useContextOpportunityExecutionNegotiation =
	(): ContextOpportunityExecutionNegotiationProps => {
		const context = useContext(ContextOpportunityExecutionNegotiation);

		if (!context) {
			throw new Error(
				"useContextOpportunityExecutionNegotiation deve ser usado dentro de ProviderOpportunityExecutionNegotiation"
			);
		}

		return context;
	};
