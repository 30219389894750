import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

/**
 * @name ModalEditarModelo
 * @date Alterado em 31/03/2021
 * @description Modal editar modelo
 */
export const ModalEditarModelo = props => (
    <Modal open={props.isOpen} className="modal-confirmacao">
        <Modal.Header>{props.titulo}</Modal.Header>
        <Modal.Content>
            <div className="modal-confirmacao-texto">
                {props.message}
                {props.error && <div style={{ color: 'red' }}>{props.t('ocorreu.erro.tente.novamente')}</div>}
            </div>
            <div className="modal-confirmacao-buttons">
                <Button onClick={props.onCancel} basic color="red" content={props.t('cancelar')} />
                <Button onClick={props.onConfirm} loading={props.onLoading} className="green-gradient-light" color="green" content={props.t('confirmar')} />
            </div>
        </Modal.Content>
    </Modal>
);
