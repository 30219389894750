import { Button, Divider, Grid, GridItem, HStack, Skeleton, Tooltip } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { generateArray } from "toolkit-extra";
import { OperationArea } from "..";
import { CardOperationArea } from "../../../Componentes/CardOperationArea";
import { EmptyContentMessage } from "../../../Componentes/EmptyContentMessage";
import { ErrorMessageWithRetry } from "../../../Componentes/ErrorMessageWithRetry";
import { Iconify } from "../../../Componentes/Iconify";
import { PageHeader } from "../../../Componentes/PageHeader";
import { GenericError } from "../../../errors/GenericError";
import { useOperationArea } from "../../../hooks/query/useOperationArea";
import { useConfigs } from "../../../hooks/store/useConfigs";
import { PATHS } from "../../../Utils/Routes";

export default function OperationAreaList() {
	const { t } = useTranslation();

	const { quantMaxAreasDeAtuacao } = useConfigs();

	//NOTE: react-query
	const {
		queryMethods: { isLoading, error, data, isFetching, refetch },
		blockers: { isCreateOperationAreaBlocked },
	} = useOperationArea({ throwToastError: true });

	/**
	 * Conteúdo dinâmico da listagem. Dependendo dos estados, renderiza conteúdos diferentes.
	 */
	const content = useMemo(() => {
		//NOTE: Se estiver buscando os dados iniciais, cria skeletons para mostrar um loading.
		if (isLoading) {
			return generateArray(6).map(uid => <Skeleton key={uid} h="2xs" rounded={"md"} />);
		}

		//NOTE: Se tiver erro, mostra um componente de erro que proporciona tentar novamente.
		if (error) {
			return (
				<GridItem as={HStack} colSpan={2} justifySelf="center" minH={"xl"}>
					<ErrorMessageWithRetry
						error={new GenericError(error)}
						retryFn={refetch}
						retryButtonProps={{ isLoading: isFetching }}
					/>
				</GridItem>
			);
		}

		//NOTE: Se nenhuma área de atuação foi encontrada, mostra uma mensagem de vazio.
		if (!data || data?.length === 0) {
			return (
				<GridItem as={HStack} colSpan={2} justifySelf="center" minH={"xl"}>
					<EmptyContentMessage
						title={t("nao-ha-nada-aqui")}
						message={t("aviso-area-de-atuacao-vazia")}
					/>
				</GridItem>
			);
		}

		//NOTE: Se tiver dados, mostra os cards das áreas de atuação carregadas.
		return data.map(operationArea => (
			<CardOperationArea key={operationArea._id} operationArea={operationArea} />
		));
	}, [data, error, isFetching, isLoading, refetch, t]);

	return (
		<OperationArea.Layout>
			<PageHeader
				title={t("areas-de-atuacao")}
				subtitle={t("subtitulo-lista-area-de-atuacao")}
				icon="mdi:map"
				actions={
					isCreateOperationAreaBlocked ? (
						<Tooltip
							bg="red.400"
							hasArrow
							label={t("aviso-quant-max-areas-de-atuacao", {
								quantMaxAreasDeAtuacao,
							})}>
							<span>
								<Button
									isDisabled
									leftIcon={<Iconify icon="mdi:plus" boxSize={6} />}
									w={{ md: "fit-content", base: "100%" }}>
									{t("nova-area")}
								</Button>
							</span>
						</Tooltip>
					) : (
						<Button
							as={Link}
							_hover={{ color: "white", opacity: 0.9 }}
							to={PATHS.areaDeAtuacao.create}
							leftIcon={<Iconify icon="mdi:plus" boxSize={6} />}
							w={{ md: "fit-content", base: "100%" }}>
							{t("nova-area")}
						</Button>
					)
				}
				breadcrumb={[
					{ name: "Dashboard", to: PATHS.dashboard.root },
					{ name: t("areas-de-atuacao") },
				]}
			/>
			<Divider />
			<Grid
				templateColumns={{
					md: "repeat(2, 1fr)",
					sm: "1fr",
				}}
				gap={5}>
				{content}
			</Grid>
		</OperationArea.Layout>
	);
}
