import firebase from "firebase";
const { v4 } = require("uuid");
const moment = require("moment");
require("moment-recur");

const buscarModelo = id =>
	firebase
		.firestore()
		.collection("modelosRoteiro")
		.doc(id)
		.get()
		.then(doc => doc.data());
const buscarPdvHomologado = id =>
	firebase
		.firestore()
		.collection("lojasHomologadas")
		.doc(id)
		.get()
		.then(doc => doc.data());
const buscarPdv = id =>
	firebase
		.firestore()
		.collection("lojas")
		.doc(id)
		.get()
		.then(doc => {
			if (doc.exists) {
				return doc.data();
			} else {
				return buscarPdvHomologado(id).then(pdv => pdv);
			}
		});

const montarRoteiro = (modelo, id, pdv, percentualTaxa, data, recorrencia, bloqueados) => {
	let roteiro = modelo.roteiro;
	roteiro.id = id;
	roteiro.loja = pdv;
	roteiro.data = data;
	roteiro.dataFim = data;

	const valor = parseFloat(roteiro.valor);
	const taxa = parseFloat((valor * percentualTaxa).toFixed(2));
	const valorPromotor = valor - taxa;

	var _roteiro = {
		...roteiro,
		id,
		valor,
		statusRoteiro: {
			id: 1,
		},
		criadoEm: moment().format("YYYY-MM-DD HH:mm:ss"),
		cadastroPorRecorrencia: recorrencia.id,
		pagamento: {
			valorTotal: valor,
			valorPromotor: parseFloat(valorPromotor.toFixed(2)),
			taxa: taxa,
			percentualTaxa,
			novo: true,
		},
		promotoresBloqueados: bloqueados,
	};
	if (recorrencia.empresa.faturamentoMensal) {
		_roteiro.pagamento.pagarFaturamentoMensal = true;
	}
	return _roteiro;
};

const cadastrandoRoteirosPorDia = (
	listaDeDias,
	modelo,
	pdv,
	percentualTaxa,
	recorrencia,
	bloqueados
) => {
	return listaDeDias.reduce((prom, data) => {
		return prom.then(() => {
			let id = v4();
			let roteiro = montarRoteiro(
				modelo,
				id,
				pdv,
				percentualTaxa,
				data,
				recorrencia,
				bloqueados
			);
			return firebase
				.firestore()
				.collection("roteiros")
				.doc(id)
				.set({ ...roteiro });
		});
	}, Promise.resolve());
};

const verificarSeJaTemOportunidadesCadastradas = (
	hoje,
	proximoDomingo,
	listaDeDias,
	recorrencia
) => {
	return firebase
		.firestore()
		.collection("roteiros")
		.where("data", "<=", proximoDomingo)
		.where("data", ">=", hoje)
		.where("cadastroPorRecorrencia", "==", recorrencia.id)
		.get()
		.then(snap => {
			//NOTE: Busca oportunidades que nao foram arquivadas
			const opportunityNotArchived = snap.docs.filter(doc => doc.data().arquivado !== true);

			//NOTE: Mapeia um array de data para essas oportunidades
			const opportunityDates = opportunityNotArchived.map(r => r.data().data);

			//NOTE: Retorna os dias que ainda nao tem oportunidades criadas para a recorrência
			const restDaysToCreateOpportunity = listaDeDias.filter(data => {
				return !opportunityDates.includes(data);
			});

			return restDaysToCreateOpportunity;
		});
};

const daysRecur = {
	Dom: 0,
	Seg: 1,
	Ter: 2,
	Qua: 3,
	Qui: 4,
	Sex: 5,
	Sab: 6,
};

const criarRoteiros = (recorrencia, percentualTaxa, bloqueados) => {
	let dias = recorrencia.diasDaSemana.map(dia => daysRecur[dia]);
	let hoje = moment().add(1, "days").format("YYYY-MM-DD");
	let proximoDomingo = moment().add(3, "week").day(0).format("YYYY-MM-DD");
	let listaDeDias = moment()
		.recur(hoje, proximoDomingo)
		.every(dias)
		.daysOfWeek()
		.all("YYYY-MM-DD");
	return buscarModelo(recorrencia.modelo.id).then(modelo => {
		return buscarPdv(recorrencia.pdv.id).then(pdv => {
			return verificarSeJaTemOportunidadesCadastradas(
				hoje,
				proximoDomingo,
				listaDeDias,
				recorrencia
			).then(diasQueSobram => {
				return cadastrandoRoteirosPorDia(
					diasQueSobram,
					modelo,
					pdv,
					percentualTaxa,
					recorrencia,
					bloqueados
				);
			});
		});
	});
};

const cadastarRecorrencias = (recorrencia, configs, bloqueados) =>
	new Promise((res, rej) => {
		let { percentualTaxa } = configs;
		return criarRoteiros(recorrencia, percentualTaxa, bloqueados);
	});

export default cadastarRecorrencias;
