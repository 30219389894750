import { Center, CenterProps, Spinner, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface LoadingFullPageProps extends CenterProps {
	text?: string;
}

export function LoadingFullPage({ text, ...restProps }: LoadingFullPageProps) {
	const { t } = useTranslation();

	return (
		<Center {...restProps}>
			<VStack gap={2}>
				<Spinner size="xl" thickness="4px" />
				<Text color="gray.700">{text || t("carregando").concat("...")}</Text>
			</VStack>
		</Center>
	);
}
