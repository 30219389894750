import { useTranslation } from "react-i18next";
import ListarFaturas from "../ListarFaturas";
import { Collapse, Divider } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { SelectInvoiceValue } from "./SelectInvoiceValue";
import { SelectedPaymentMethod } from "./SelectPaymentMethod";
import { ButtonClose, InvoiceView, InvoiceWrapper } from "./styles";

export type IPaymentMethod = "bank_slip" | "pix";

export interface ITopUpFormData {
    value: number;
    dueDate: Date | null;
    paymentMethod: IPaymentMethod | null;
    invoiceUrl: string | null;
}

export function TopUpBalance() {
    const { t } = useTranslation();
    const empresa = useAppSelector((state) => state.AutenticacaoReducer.empresa);

    const formMethods = useForm<ITopUpFormData>({
        defaultValues: {
            dueDate: new Date(),
            value: 0,
            paymentMethod: null,
            invoiceUrl: null,
        },
    });

    const { watch, reset } = formMethods;

    const currentValue = watch("value");
    const invoiceUrl = watch("invoiceUrl");

    if (invoiceUrl)
        return (
            <InvoiceWrapper>
                <ButtonClose
                    onClick={() => reset()}
                    style={{ marginTop: "12px" }}
                    content={t("fechar-fatura")}
                    icon="times"
                    color="red"
                    basic
                ></ButtonClose>
                <InvoiceView title={t("fatura")} src={invoiceUrl} />
            </InvoiceWrapper>
        );

    return (
        <FormProvider {...formMethods}>
            <SelectInvoiceValue />
            <Collapse in={currentValue >= 100}>
                <SelectedPaymentMethod />
            </Collapse>
            <Divider style={{ margin: "14px 0" }} />
            <ListarFaturas empresa={empresa} t={t} />
        </FormProvider>
    );
}
