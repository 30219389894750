import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

/**
 * @name Editor
 * @date Criado em 4/01/2021
 * @author Vitor Andre Savian
 * @description apenas coloca o componente do Sun Editor com a descrição e se for requerido
 * @param {Object} props informações que vão chegar da classe pai ou da função que está solicitando esse componente
*/
export const Editor = (props) => {
    return (
        <div>
            <p style={{ fontWeight: 'bolder'}}>{props.label} {props.required && <span style={{ color: 'red'}}>*</span>}</p>
            <SunEditor 
                lang="pt_br" 
                name={props.name}
                placeholder={props.placeholder}
                setContents={props.value}
                onChange={props.handleInputDescricao} 
                />
            <br />
        </div>
    )
}