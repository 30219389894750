import styled from "styled-components";

interface PropsTexto {
	fontSize: string;
	fontWeight?: string;
	color?: string;
	fontFamily?: string;
	fontStyle?: string;
}

export const Texto = styled.p<PropsTexto>`
	font-size: ${props => props.fontSize};
	font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
	color: ${props => (props.color ? props.color : "black")};
	font-style: ${props => (props.fontStyle ? props.fontStyle : "normal")};
`;
