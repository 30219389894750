import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import firebase from "firebase";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";
import { InputForm } from "../../../Componentes/Form/chakraUI/InputForm";
import { PATHS } from "../../../Utils/Routes";
import UnauthenticatedPageLayout from "../Layout";

const FORGOT_PASSWORD_SCHEMA = z.object({
	email: z.string().email("E-mail inválido"),
});

type ForgotPasswordFormData = z.infer<typeof FORGOT_PASSWORD_SCHEMA>;

export default function ForgotPasswordPage() {
	const { t } = useTranslation();
	const history = useHistory();

	const {
		control,
		handleSubmit,
		formState: { isSubmitting },
	} = useForm<ForgotPasswordFormData>({
		resolver: zodResolver(FORGOT_PASSWORD_SCHEMA),
		defaultValues: {
			email: "",
		},
	});

	const onSubmit: SubmitHandler<ForgotPasswordFormData> = async data => {
		try {
			await firebase.auth().sendPasswordResetEmail(data.email);
			handleGoToLogin();
			toast.success(t("aviso-sucesso-envio-de-email-redefinicao-de-senha"));
		} catch (e) {
			console.error(e);

			const error = e as Omit<firebase.firestore.FirestoreError, "code"> & {
				code: firebase.firestore.FirestoreErrorCode | string;
			};

			if (error?.code) {
				if ("auth/user-not-found") {
					handleGoToLogin();
					toast.success(t("aviso-sucesso-envio-de-email-redefinicao-de-senha"));
				} else {
					toast.error(t(error.code));
				}
			} else {
				toast.error(t("ocorreu-um-erro-inesperado-tente-novamente-mais-tarde"));
			}
		}
	};

	function handleGoToLogin() {
		history.push(PATHS.unauthenticated.login);
	}

	return (
		<UnauthenticatedPageLayout>
			<Flex
				mt={16}
				as="form"
				flexDir={"column"}
				gap={5}
				w="full"
				maxW="3xl"
				onSubmit={handleSubmit(onSubmit)}>
				<Box as="header" textAlign={"center"} maxW="lg" mx="auto">
					<Heading as="h1" fontSize="3xl" mb={2}>
						{t("esqueceu.senha")}
					</Heading>
					<Heading as="h2" fontSize={"md"} fontWeight="normal" color={"gray.500"}>
						{t("descricao-pagina-esqueci-minha-senha")}
					</Heading>
				</Box>

				<InputForm isRequired control={control} name="email" label="E-mail" />
				<Box>
					<Button type="submit" w="full" mb={2} isLoading={isSubmitting} size="lg">
						{t("redefinir-senha")}
					</Button>
					<Link to={PATHS.unauthenticated.login}>
						<Button variant="ghost" w="full" size="lg">
							{t("voltar")}
						</Button>
					</Link>
				</Box>
			</Flex>
		</UnauthenticatedPageLayout>
	);
}
