import "./roteiro.css";
import "react-tagsinput/react-tagsinput.css";
import _ from "lodash";
import React, { Component, useState } from "react";
import { v4 } from "uuid";
import { withTranslation } from "react-i18next";
import { Dropdown, Form, Button, Icon, Header, Loader, Segment } from "semantic-ui-react";
import { ModelRoteiro } from "./../../Model/model";
import { db } from "../../config";
import { validarValorMinimo } from "./../../Utils/Roteiros";
import { downloadMarcasDaEmpresa, downloadLojasDaEmpresa } from "../../Utils/Oportunidades";
import ListarModelos from "./ListarModelos";
import { marcasDaEmpresa, lojasDaEmpresa } from "../../_actions/RecursoActions";
import { validarRoteiro } from "../../Utils/Validacao";
import Tutorial from "../Tutoriais/TutorialGeral";
import PrimeiraFoto from "../../imagens/TutorialCriarOportunidade1.png";
import MensagemCadastroBloqueado from "../../Componentes/MensagemBloqueado";
import MensagemSalvo from "../../Componentes/MensagemSalvo";
import { ModalDeConfirmacao } from "../../Componentes/ConfirmarRegistro";
import RoteiroConnect from "./RoteiroConnect";
import RoteiroForm from "./RoteiroForm";
import ModalSalvarModelo from "../../Componentes/ModalSalvarModelo";
import { OpcoesDeCadastroRoteiro } from "../../Componentes/OpcoesDeCadastroRoteiro";
import firebase from "firebase";
import moment from "moment";
import "moment-recur";
// import './styles.css'
import { logging } from "../../services/Api";
import { ModalEditarModelo } from "../../Componentes/ModalEditarModelo";
import { salvarModelo } from "./VerificarRoteiro/CriarModelo";
import { ReceiptStatus } from "../../@types/Opportunity";

var DATA_ATUAL = moment().format("YYYY-MM-DD HH:mm:ss");

/**
 * Valores default do roteiro para aplicação
 * após a seleção do roteiro.
 */
const defaultRoteiroSchedule = {
	data: "",
	dataFim: "",
	horaInicio: "",
	horaFim: "",
};

/**
 * Cria o contexto que passará pelo pipeline.
 */
const criarContexto = (state, props) => {
	let { salvarModelo, nomeDoModelo, idSelecaoAutomatica, idModelo } = state;
	let { empresa, configuracao, saldos, ipLocation, faturamentoMensal } = props;
	const temFaturamentoMensal = empresa?.faturamentoMensal ?? false;
	const gastos = faturamentoMensal?.gastos;
	let maximo = empresa?.valorMaximoOportunidades ?? 0;
	let limite = maximo - gastos;
	let UltrapassaLimite = state.roteiro.valor >= limite;
	const roteiro = {
		...state.roteiro,
		recorrencia:
			state.recorrencia.length > 0 && state.roteiro.data !== state.roteiro.dataFim
				? state.recorrencia
				: null,
	};
	//adiciona o id da selecao automatica dentro do roteiro se existir
	if (
		idSelecaoAutomatica &&
		(saldos.naoReservado >= roteiro.valor || (temFaturamentoMensal && !UltrapassaLimite))
	)
		roteiro.idSelecaoAutomatica = idSelecaoAutomatica;

	if (roteiro.data === roteiro.dataFim) roteiro.dataFim = "";
	if (roteiro.recorrencia === null) delete roteiro.recorrencia;

	return Promise.resolve({
		empresa,
		roteiro,
		configuracao,
		ipLocation,
		modelo: { salvarModelo, nomeDoModelo, idModelo },
		validacao: null,
	});
};

/**
 * Função do pipeline que grava o roteiro no firebase.
 */
export const salvarRoteiro = async (roteiro, configuracao, empresa) => {
	var percentualTaxa = empresa.taxaPersonalizada ?? configuracao.percentualTaxa;

	if (!configuracao) {
		throw new Error("Configuração não definida.");
	}

	const id = roteiro.id ? roteiro.id : v4();
	const valor = parseFloat(roteiro.valor);
	const taxa = parseFloat((valor * percentualTaxa).toFixed(2));
	const valorPromotor = valor - taxa;
	let bloqueados = [];
	let bloqueadosUid = [];

	await db
		.collection("empresas")
		.doc(empresa.uid)
		.collection("bloqueados")
		.get()
		.then(snap => {
			snap.docs.forEach(doc => bloqueados.push(doc.data()));
		});

	bloqueados.forEach(promotor => {
		bloqueadosUid.push(promotor.uid);
	});

	if (empresa.inativo) {
		await db.collection("empresas").doc(empresa.uid).update({
			inativo: false,
		});
	}

	var _roteiro = {
		...roteiro,
		id,
		valor,
		pagamento: {
			valorTotal: valor,
			valorPromotor: parseFloat(valorPromotor.toFixed(2)),
			taxa: process.env.NODE_ENV === "development" ? 0 : taxa,
			percentualTaxa,
			novo: true,
		},
		promotoresBloqueados: bloqueadosUid,
	};

	if (empresa.faturamentoMensal) {
		_roteiro.pagamento.pagarFaturamentoMensal = true;
	}

	_roteiro[roteiro.id ? "editadoEm" : "criadoEm"] = DATA_ATUAL;
	if (_roteiro.tipo.id !== 3 && _roteiro.tipo.id !== 2) {
		delete _roteiro.material;
	}
	return db
		.collection("roteiros")
		.doc(id)
		.set(_roteiro)
		.then(() => {
			return _roteiro;
		});
};

const gravarNotificacaoSelecaoAuto = roteiro => {
	const id = v4();
	const data = moment().format("YYYY-MM-DD");
	roteiro.idSelecaoAutomatica !== null &&
		roteiro.idSelecaoAutomatica !== "" &&
		db
			.collection("notificacoes")
			.doc(id)
			.set({
				data: data,
				descricao: `A ${roteiro.fantasiaEmpresa} escolheu você para uma oportunidade via seleção automática`,
				id: id,
				idEmpresa: roteiro.idSelecaoAutomatica,
				idRoteiro: roteiro.id,
				link: `/direcionar/${roteiro.id}`,
				titulo: "Você foi selecionado.",
				visto: 0,
			});
};

/**
 * Função do pipeline que adiciona os planogramas dos produtos
 * no objeto do roteiro.
 */
const commitarRoteiro = context => {
	const roteiro = {
		...context.roteiro,
		notaFiscal: { requerida: true, status: 1, id: "" },
		emailEmpresa: context.empresa.email,
		cnpjEmpresa: context.empresa.cnpj,
		imagemURL: context.empresa.imagemURL ?? null,
		enderecoEmpresa: `${context.empresa.endereco.logradouro}, 
        ${context.empresa.endereco.numero} - 
        ${context.empresa.endereco.complemento !== "" ? `${context.empresa.endereco.complemento + " - "}` : ""}
        ${context.empresa.endereco.bairro}, 
        ${context.empresa.endereco.cidade} - 
        ${context.empresa.endereco.estado}, 
        ${context.empresa.endereco.cep}`,
	};
	if (_.isArray(roteiro.atividades)) {
		delete roteiro.atividades;
	}
	return salvarRoteiro(roteiro, context.configuracao, context.empresa).then(_roteiro => ({
		...context,
		roteiro: _roteiro,
	}));
};

/**
 * Transforma as informações do roteiro para gravar
 * no firebase.
 */
const commitarModelo = async context => {
	const {
		roteiro,
		modelo: { nomeDoModelo, idModelo },
	} = context;

	let _roteiro = { ...roteiro };

	const percentualTaxa =
		context?.empresa?.taxaPersonalizada ?? context?.configuracao?.percentualTaxa;
	const valor = parseFloat(roteiro.valor);
	const taxa = parseFloat((valor * percentualTaxa).toFixed(2));
	const valorPromotor = valor - taxa;

	_roteiro.pagamento = {
		valorTotal: valor,
		valorPromotor: parseFloat(valorPromotor.toFixed(2)),
		taxa: process.env.NODE_ENV === "development" ? 0 : taxa,
		percentualTaxa,
		novo: true,
	};

	await salvarModelo(_roteiro, nomeDoModelo, idModelo);
	return context;
};

/**
 * Componente do header do cadastro de oportunidades.
 */
export const HeaderCadastroOportunidade = props => (
	<Header
		as="h1"
		style={{
			margin: "20px 0 40px",
			display: "flex",
			justifyContent: "center",
			textAlign: "center",
		}}>
		<Header.Content style={{ color: "#7501c0", fontSize: "28px", fontWeight: "bold" }}>
			{props.idModelo ? props.t("editar.modelo") : props.t("publicar.oportunidade")}
			<Header.Subheader style={{ fontSize: "22px", color: "#999999" }}>
				{props.idModelo
					? props.t("editar.modelo.subtitulo")
					: props.t("publicar.oportunidade.subtitulo")}
			</Header.Subheader>
		</Header.Content>
	</Header>
);

/**
 * Componente da página de novo roteiro.
 */
export class NovoRoteiro extends Component {
	constructor(props) {
		super(props);
		const roteiro = props.roteiro;
		const idModelo = props.idModelo ?? null;
		if (props.match.params.lojaId) {
			roteiro.loja = _.find(this.props.lojas, {
				id: props.match.params.lojaId,
			});
		}
		let favorito = _.find(this.props.empresa.selecaoAutomatica, {
			favorito: true,
		});
		this.state = {
			roteiro,
			abrirSelecaoDeProdutos: null,
			showTutorial: false,
			atividadeEditando: -1,
			tiposRoteiro: [],
			salvarModelo: false,
			nomeDoModelo: props.nomeDoModelo ?? "",
			modelosRoteiro: [],
			errorValorMinimo: null,
			mensagemSalvando: null,
			aguardandoConfirmacao: false,
			idModelo,
			validacao: null,
			validacaoModelo: null,
			recorrencia: [],
			valorBaixo: false,
			idSelecaoAutomatica: roteiro.idSelecaoAutomatica ?? (favorito ? favorito.id : null),
		};
	}

	/**
	 * Caso nos iniciarmos a navegação nesta página,
	 * vamos tentar fazer o download dos recursos.
	 */
	componentWillMount() {
		this.props.produtos == null &&
			downloadMarcasDaEmpresa(this.props.idEmpresa)
				.limit(20)
				.onSnapshot(snap => this.props.dispatch(marcasDaEmpresa(snap)));
		this.props.lojas == null &&
			firebase
				.firestore()
				.collection("lojasHomologadas")
				.get()
				.then(d => {
					var i = d.docs;
					return i;
				})
				.then(p => {
					downloadLojasDaEmpresa(this.props.idEmpresa).onSnapshot(snap => {
						// d.logSnapshot("Loja", snap);
						var s = {
							docs: _.concat(snap.docs, p),
						};
						// var m = s.docs.map(e => e.data())
						this.props.dispatch(lojasDaEmpresa(s));
					});
				});
	}

	simNaoOptions = [
		{ text: "Sim", value: true },
		{ text: "Não", value: false },
	];

	/**
	 * Atualiza a mensagem do componente.
	 */
	updateMessage = (msg, context) =>
		new Promise(s =>
			this.setState(
				{
					mensagemSalvando: msg,
				},
				() => s(context)
			)
		);

	/**
	 * Verificar os dias da semana marcados para a recorrência
	 */
	diaSemana = (dia, e) => {
		e.preventDefault();
		const { recorrencia } = this.state;
		var set = new Set(recorrencia);
		recorrencia.includes(dia) ? set.delete(dia) : set.add(dia);
		this.setState({ recorrencia: Array.from(set) });
	};

	/**
	 * Autor: Renahn Schultz
	 * Calcula o valor sugestão da oportunidade com base no valor
	 * da hora de um promotor e na quantidade de atividades adicionadas
	 * à oportunidade levando em consideração o tempo médio de execução
	 * de cada uma delas.
	 * Juntamente, aproveitando o tempo médio de execução de cada uma
	 * das atividades, realiza a somatória para calcular o tempo
	 * estimado da execução da oportunidade.
	 */
	calcularValorOportunidadeETempo = atividades => {
		const { roteiro, recorrencia } = this.state;
		const {
			configuracao: { valorMinimo },
		} = this.props;
		const valorBase = 15;
		const valorMinuto = 0.5;
		const temposExecucao = {
			1: 1,
			2: 2,
			3: 2,
			4: 1,
			5: 1,
			6: 7,
		};
		var valorSugestao = 0;
		var tempoEstimado = 0;
		const incrementar = atividade => {
			valorSugestao += valorMinuto * temposExecucao[atividade.tipo];
			tempoEstimado += temposExecucao[atividade.tipo];
		};
		Object.keys(atividades).forEach(key => {
			if (Array.isArray(atividades[key])) {
				atividades[key].forEach(atividade => {
					incrementar(atividade);
				});
			} else {
				incrementar(atividades[key]);
			}
		});
		valorSugestao += valorBase;
		if (valorSugestao < valorMinimo) {
			valorSugestao = valorMinimo;
		}
		var qtdDias = 1;
		if (roteiro.data && roteiro.dataFim && !_.isEmpty(recorrencia)) {
			qtdDias =
				moment()
					.recur(roteiro.data, roteiro.dataFim)
					.every(recorrencia)
					.daysOfWeek()
					.all("YYYY-MM-DD").length || 1;
		} else if (roteiro.data && roteiro.dataFim && _.isEmpty(recorrencia)) {
			qtdDias += moment(roteiro.dataFim).diff(moment(roteiro.data), "days");
		}
		valorSugestao = qtdDias * valorSugestao;
		this.setState({
			valorSugestao,
			roteiro: { ...this.state.roteiro, valor: valorSugestao, tempoEstimado },
		});
	};
	/**
	 * Chamado após a confirmação.
	 */
	respostaConfirmacao = () => {
		const scope = this;
		this.setState({ aguardandoConfirmacao: false, salvando: true, validacao: null }, () =>
			criarContexto(this.state, this.props)
				.then(
					//SALVA A OPORTUNIDADE SE NÃO FOR CADASTRO APENAS DE MODELO
					context => {
						if (scope.state.salvarApenasModelo) {
							return Promise.resolve(context);
						}
						return scope
							.updateMessage("aguarde.registrando.roteiro", context)
							.then(commitarRoteiro)
							.then(context => {
								logging("CREATE", "log.criacao.roteiro", context.roteiro.id);
								return context;
							});
					}
				)
				.then(
					//SALVA O MODELO SE FOR SELECIONADO PRA SALVAR MODELO
					context =>
						!context.modelo.salvarModelo
							? Promise.resolve(context)
							: scope
									.updateMessage("aguarde.registrando.modelo", context)
									.then(commitarModelo)
				)
				.then(
					//FAZ A SELEÇÃO DA AGÊNCIA POR SELEÇÃO AUTOMÁTICA
					context => {
						if (
							!context.roteiro.idSelecaoAutomatica ||
							scope.state.salvarApenasModelo
						) {
							return Promise.resolve(context);
						} else {
							const faturamentoMensal =
								this.props.empresa?.faturamentoMensal ?? false;
							return db
								.collection("empresas")
								.doc(context.roteiro.idSelecaoAutomatica)
								.get()
								.then(snapshot => {
									const ag = snapshot.data();
									return db
										.collection("roteiros")
										.doc(context.roteiro.id)
										.update({
											usuario: {
												uid: ag.uid,
												idUsuario: ag.idUsuario,
												tipo: 2,
												sexo: "Agência",
												nome: ag.fantasia,
												foto: ag.imagemURL,
												mediaAvaliacao: ag.mediaAvaliacao
													? ag.mediaAvaliacao
													: 0,
												quantidadeAvaliacao: ag.quantidadeAvaliacao
													? ag.quantidadeAvaliacao
													: 0,
											},
											"pagamento.novo": true,
											"pagamento.pagarFaturamentoMensal": faturamentoMensal
												? true
												: false,
											statusAgencia: 1,
											statusRoteiro: { id: 2 },
										})
										.then(() => {
											gravarNotificacaoSelecaoAuto(context.roteiro);
											return Promise.resolve(context);
										});
								});
						}
					}
				)
				.then(context => {
					scope.setState({
						roteiro: new ModelRoteiro(context.empresa),
						validacao: null,
						salvarModelo: false,
						nomeDoModelo: "",
						salvando: false,
						mensagemSalvo: true,
						mensagemSalvando: null,
						atividades: {},
						abrirSelecaoDeProdutos: null,
						atividadeEditando: -1,
						errorValorMinimo: null,
					});
				})
				.catch(context => {
					// Context pode ser uma string caso seja uma exceção simples
					if (_.isString(context) || context.message) {
						return console.error(context);
					}
					// Acontece quando tem algum erro no pipeline
					if (context.validacao.status === "error/cancelado") {
						scope.setState({
							validacao: scope.props.t(context.validacao.message),
							salvando: false,
							mensagemSalvo: false,
							mensagemSalvando: null,
						});
					}
				})
				.catch(console.error)
		);
	};

	/**
	 * Requisita validação do roteiro.
	 */
	validarRoteiro = (props, state) => {
		let rec = this.state.recorrencia;
		let recorrencia =
			rec !== undefined &&
			rec.length > 0 &&
			this.state.roteiro.data !== this.state.roteiro.dataFim
				? rec
				: null;

		const roteiro = {
			...state.roteiro,
			recorrencia,
		};

		if (recorrencia === null) delete roteiro.recorrencia;

		return validarRoteiro(roteiro, props);
	};

	/**
	 * Quando esse callback é chamado, ele valida
	 * a informação do roteiro e pedir a confirmação
	 * do envio do roteiro.
	 */
	onSubmitRoteiro = () => {
		const validacao = this.validarRoteiro(this.props, this.state);

		if (validacao) {
			return this.setState({
				validacao,
				salvando: false,
				mensagemSalvo: false,
				mensagemSalvando: null,
			});
		}

		if (parseFloat(this.state.roteiro.valor) < (this.state.valorSugestao * 70) / 100) {
			this.setState({ valorBaixo: true });
		}

		const contaBloqueada = bloquearCadastro =>
			bloquearCadastro
				? Promise.reject({
						validacao: {
							status: "error/bloqueado",
							message: "conta bloqueada",
						},
					})
				: Promise.resolve();

		contaBloqueada(this.props.bloquearCadastro).then(() =>
			this.setState({ aguardandoConfirmacao: true, validacao: null })
		);
	};

	/**
	 * Callback chamado pelo modal seleção de produtos.
	 */
	handleFinishSelectProduts = (event, produtos) => {
		const idAtividade = this.state.abrirSelecaoDeProdutos;
		const atividades = {
			...this.state.atividades,
			[idAtividade]: produtos,
		};
		this.setState({ abrirSelecaoDeProdutos: null, atividades });
	};

	/**
	 * Renahn Schultz
	 *
	 * Altera o estado do componente referente.
	 */
	handleChange = event => this.setState({ [event.target.name]: event.target.value });

	/**
	 * Atualiza a propriedade do objeto roteiro no state.
	 * @param {string} name Nome da propriedade.
	 * @param {any} value O valor a ser definido.
	 */
	handleUpdateRoteiro = (name, value) => {
		if (name === "loja" && value.selecaoAutomatica !== undefined) {
			this.setState({
				idSelecaoAutomatica: value.selecaoAutomatica,
				roteiro: { ...this.state.roteiro, [name]: value },
			});
		} else if (name === "loja" && value.selecaoAutomatica === undefined) {
			this.setState({
				idSelecaoAutomatica: null,
				roteiro: { ...this.state.roteiro, [name]: value },
			});
		} else {
			this.setState({ roteiro: { ...this.state.roteiro, [name]: value } });
		}
	};

	/**
	 * Calback para inputs de texto, data...
	 */
	handleChangeRoteiro = event => this.handleUpdateRoteiro(event.target.name, event.target.value);

	/**
	 * Callback para atualização do valor do roteiro.
	 */
	handleChangeValorRoteiro = (e, { name, value }) => this.handleUpdateRoteiro(name, value);

	/**
	 * Callback para atualização do qual a loja e tipo de atividade.
	 * @param {string} name Nome do campo que será alterado.
	 */
	handleChangeSelectItem =
		name =>
		(e, { value }) =>
			this.handleUpdateRoteiro(name, value);

	/**
	 * Callback para seleção de loja.
	 */
	handleChangeSelectLoja = this.handleChangeSelectItem("loja");

	/**
	 * Callback para seleção do tipo de atividade.
	 */
	handleChangeSelectTipo = this.handleChangeSelectItem("tipo");

	/**
	 * Callback que fecha a mensagem de 'roteiro salvo'.
	 */
	dismissMensagemSalvo = () => this.props.actions.voltarParaOpcoes();

	/**
	 * Validador do input do valor do roteiro.
	 */
	validateValorInput = event => {
		const {
			configuracao: { valorMinimo },
		} = this.props;
		const isValid = validarValorMinimo(event.target.value, valorMinimo);
		!isValid &&
			this.setState({
				errorValorMinimo: this.props
					.t("valor.minimo.menor.que.default")
					.replace(/\{valor\}/, parseFloat(valorMinimo).toFixed(2)),
			});
	};

	/**
	 * Callback para refresh da página após a confirmação
	 * do e-mail.
	 */
	refreshPage = () => location.reload(); // eslint-disable-line no-restricted-globals

	handleDeleteSecao = (index, e, roteiro) => {
		e.preventDefault();
		e.stopPropagation();
		let roteiroFon = roteiro;
		delete roteiroFon.secoes[index];
		this.setState({ roteiro: roteiroFon });
		return roteiro;
	};

	handleDeleteTreinamento = (index, e, roteiro) => {
		e.preventDefault();
		e.stopPropagation();
		let roteiroFon = roteiro;
		delete roteiroFon.treinamentos[index];
		this.setState({ roteiro: roteiroFon });
		return roteiro;
	};

	handleChangeRadio = (e, { value }) => {
		if (value === "automatico") {
			this.setState({
				roteiro: {
					...this.state.roteiro,
					selecaoAutomatica: true,
					selecaoAutomaticaFavorito: false,
				},
			});
		} else {
			this.setState({
				roteiro: {
					...this.state.roteiro,
					selecaoAutomatica: false,
					selecaoAutomaticaFavorito: true,
				},
			});
		}
	};

	handleChangeRadioObrigatorio = (e, { value }) => {
		this.setState({
			roteiro: {
				...this.state.roteiro,
				tempoEstimadoObrigatorio: !value,
			},
		});
	};

	defaultChecked = () => {
		this.setState({
			roteiro: {
				...this.state.roteiro,
				selecaoAutomatica: true,
				selecaoAutomaticaFavorito: false,
			},
		});
	};

	toggleRadios = () => {
		this.setState({
			roteiro: {
				...this.state.roteiro,
				selecaoAutomatica: false,
				selecaoAutomaticaFavorito: false,
			},
		});
	};

	// Callback para gereciar o estado das epis
	handleCheckBoxEpis = epi => {
		var array = this.state.roteiro.epis || [];
		if (array.includes(epi)) {
			var filtered = array.filter(value => {
				return value !== epi;
			});
			this.setState({ roteiro: { ...this.state.roteiro, epis: filtered } });
		} else
			this.setState({
				roteiro: { ...this.state.roteiro, epis: [...array, epi] },
			});
	};

	// Callback para gereciar o estado do Input de material
	handleInputMaterial = value =>
		this.setState({
			roteiro: {
				...this.state.roteiro,
				material: { ...this.state.roteiro.material, descricao: value },
			},
		});

	/**
	 * Callback que altera a descrição geral do roteiro.
	 */
	handleChangeDescricaoGeral = event =>
		this.setState({
			roteiro: {
				...this.state.roteiro,
				descricao: event.target.value,
			},
		});

	handleChangeNomeDoModelo = event => this.setState({ nomeDoModelo: event.target.value });

	confirmarModelo = soModelo => {
		const validacao = this.validarRoteiro(this.props, this.state);

		if (validacao) {
			return this.setState({
				validacao,
				salvando: false,
				mensagemSalvo: false,
				mensagemSalvando: null,
				salvarModelo: false,
			});
		}
		this.setState({ salvarModelo: true, salvarApenasModelo: soModelo });
	};

	onCriarModeloERoteiro = event => {
		if (this.state.nomeDoModelo === "") {
			this.setState({
				validacaoModelo: this.props.t("validacao.modelo"),
			});
			return;
		}

		this.onSubmitRoteiro();
	};

	handleSelecaoAutomatica = e => {
		if (e.currentTarget.id !== this.state.idSelecaoAutomatica) {
			this.setState({ idSelecaoAutomatica: e.currentTarget.id });
		} else {
			if (this.state.roteiro.idSelecaoAutomatica) {
				let roteiro = this.state.roteiro;
				delete roteiro.idSelecaoAutomatica;
				this.setState({ idSelecaoAutomatica: null, roteiro: { ...roteiro } });
			} else {
				this.setState({ idSelecaoAutomatica: null });
			}
		}
	};

	salvarSecao = secao => {
		const _secoes = this.state.roteiro.secoes ? { ...this.state.roteiro.secoes } : {};
		_secoes[secao.id] = secao;
		this.setState({ roteiro: { ...this.state.roteiro, secoes: _secoes } });
	};

	salvarTreinamentos = treinamentos => {
		this.setState({ roteiro: { ...this.state.roteiro, treinamentos } }, () =>
			console.log(this.state.roteiro)
		);
	};

	calcularLimite = (roteiro, empresa, faturamentoMensal) => {
		const gastos = faturamentoMensal?.gastos;
		let maximo = empresa?.valorMaximoOportunidades ?? 0;
		let limite = maximo - gastos;
		return roteiro.valor >= limite;
	};

	render() {
		const { t, emailVerificado, bloquearCadastro, saldos, empresa, faturamentoMensal } =
			this.props;
		const {
			roteiro,
			salvando,
			validacao,
			mensagemSalvo,
			recorrencia,
			valorSugestao,
			salvarApenasModelo,
			idSelecaoAutomatica,
			idModelo,
		} = this.state;
		const lojas = (_.isArray(this.props.lojas) && this.props.lojas) || [];
		const temFaturamentoMensal = empresa?.faturamentoMensal ?? false;
		const UltrapassaLimite = this.calcularLimite(roteiro, empresa, faturamentoMensal);
		if (!emailVerificado) {
			return (
				<div>
					<div style={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
						<HeaderCadastroOportunidade {...this.state} t={t} />
						<Icon className="verificacao-email-icon" name="envelope" color="blue" />
						<h3>{t("verificacao.email.pendente")}</h3>
						<span style={{ fontSize: "19px" }}>
							<br />
							{t("email.nao.confirmado")}
							<br />
							<br />
							{t("assim.que.confirmada")}{" "}
						</span>
						<br />
						<br />
						<span style={{ fontSize: "19px" }}>
							{t("apos.verificacao")}{" "}
							<a onClick={() => this.refreshPage()} href="#top-novo-roteiro">
								{t("clique.aqui")}
							</a>{" "}
							{t("para.atualizar")}
						</span>
					</div>
				</div>
			);
		}

		if (bloquearCadastro) {
			return (
				<div className="novo-roteiro" style={{ margin: "0 auto", width: "80%" }}>
					<HeaderCadastroOportunidade {...this.state} t={t} />
					<MensagemCadastroBloqueado
						t={t}
						oportunidades={this.props.oportunidadesExecutadas}
					/>
				</div>
			);
		}

		if (mensagemSalvo) {
			return (
				<div className="novo-roteiro" style={{ margin: "0 auto", width: "80%" }}>
					<HeaderCadastroOportunidade {...this.state} t={t} />
					<MensagemSalvo novoRegistro={this.dismissMensagemSalvo} t={t} />
				</div>
			);
		}

		if (salvando) {
			return (
				<div className="novo-roteiro" style={{ margin: "0 auto", width: "80%" }}>
					<HeaderCadastroOportunidade {...this.state} t={t} />
					<Segment inverted color="orange">
						<div style={{ height: "60vh" }}>
							<Loader
								className="roteiro-loader"
								size="massive"
								active
								indeterminate
								inverted>
								{t(this.state.mensagemSalvando)}
							</Loader>
						</div>
					</Segment>
				</div>
			);
		}

		const opcoesPublicar = [
			{
				key: 2,
				icon: "copy",
				value: 2,
				text: t("salvar.so.modelo"),
				onClick: () => this.confirmarModelo(true, true),
			},
			{
				key: 3,
				icon: "share",
				value: 3,
				text: t("salvar.e.criar.modelo"),
				onClick: () => this.confirmarModelo(false, true),
			},
		];

		return (
			<div className="novo-roteiro" id="top-novo-roteiro" style={{ margin: "0 auto" }}>
				<HeaderCadastroOportunidade {...this.state} t={t} />
				{/* <ModalNovoPdv isOpenModal={this.state.isOpenModal}/> */}
				<Form onSubmit={this.onSubmitRoteiro.bind(this)}>
					<RoteiroForm
						t={t}
						validacao={validacao}
						roteiro={roteiro}
						saldos={saldos}
						lojas={lojas}
						recorrencia={recorrencia}
						faturamentoMensal={faturamentoMensal}
						diaSemana={this.diaSemana}
						valorSugestao={valorSugestao}
						idSelecaoAutomatica={idSelecaoAutomatica}
						salvarSecao={this.salvarSecao}
						salvarTreinamentos={this.salvarTreinamentos}
						handleDeleteSecao={this.handleDeleteSecao}
						handleDeleteTreinamento={this.handleDeleteTreinamento}
						handleCheckBoxEpis={this.handleCheckBoxEpis}
						handleInputMaterial={this.handleInputMaterial}
						handleChangeRoteiro={this.handleChangeRoteiro.bind(this)}
						handleChangeSelectTipo={this.handleChangeSelectTipo.bind(this)}
						handleChangeSelectLoja={this.handleChangeSelectLoja.bind(this)}
						handleChangeDescricaoGeral={this.handleChangeDescricaoGeral.bind(this)}
						handleChangeValorRoteiro={this.handleChangeValorRoteiro.bind(this)}
						handleChangeSelecaoAutomatica={this.handleSelecaoAutomatica.bind(this)}
						handleChangeRadio={this.handleChangeRadio}
						toggleRadios={this.toggleRadios}
						defaultChecked={this.defaultChecked}
						onSubmit={this.onSubmitRoteiro.bind(this)}
						handleChangeRadioObrigatorio={this.handleChangeRadioObrigatorio}
					/>
					<div
						style={{
							display: "flex",
							marginTop: "25px",
							justifyContent: "flex-end",
						}}>
						<button
							type="button"
							className="ui basic icon right floated button"
							onClick={() => this.setState({ showTutorial: true })}>
							<Icon name="help" />
						</button>
						<Button
							type="button"
							className="voltar-botao"
							content={t("cancelar")}
							icon="left arrow"
							color="red"
							floated="right"
							onClick={() => this.props.actions.voltarParaOpcoes()}></Button>

						<Button.Group style={{ paddingLeft: 5 }} color="violet">
							<Button
								content={idModelo ? t("salvar") : t("publicar")}
								icon="rocket"
								onClick={
									idModelo
										? () => {
												this.confirmarModelo(true);
											}
										: this.onSubmitRoteiro.bind(this)
								}
							/>
							{!idModelo && (
								<Dropdown
									floating
									className="button icon"
									options={opcoesPublicar}
									trigger={<React.Fragment />}
								/>
							)}
						</Button.Group>
					</div>
					{idModelo ? (
						<ModalEditarModelo
							t={t}
							isOpen={this.state.aguardandoConfirmacao}
							titulo={t("editar.modelo")}
							message={<p>{t("editar.descricao")}</p>}
							onConfirm={() =>
								this.setState(
									{ aguardandoConfirmacao: false },
									this.respostaConfirmacao.bind(this)
								)
							}
							onCancel={() => this.setState({ aguardandoConfirmacao: false })}
						/>
					) : (
						<ModalDeConfirmacao
							t={t}
							isOpen={this.state.aguardandoConfirmacao}
							titulo={
								salvarApenasModelo
									? t("cadastro.de.modelo")
									: t("cadastro.de.oportunidade")
							}
							message={
								this.state.valorBaixo ? (
									<>
										<span
											style={{
												textAlign: "center",
												color: "#F44336",
												padding: 0,
											}}>
											<p>{t("aviso")}</p>
										</span>
										<span
											style={{
												padding: 10,
												textAlign: "center",
												fontSize: 14,
												justifyContent: "center",
											}}>
											{t("valor.oportunidade.baixo")}
										</span>
										<p style={{ marginTop: 10, marginBottom: 20 }}>
											{t("confirmar.cadastrar.oportunidade")}
										</p>
									</>
								) : (
									<>
										<p style={{ marginTop: 10 }}>
											{salvarApenasModelo
												? t("confirmar.cadastrar.modelo")
												: t("confirmar.cadastrar.oportunidade")}
										</p>
										<span>
											Valor:{" "}
											{Intl.NumberFormat("pt-br", {
												style: "currency",
												currency: "BRL",
											}).format(roteiro.valor)}
										</span>
										{UltrapassaLimite && idSelecaoAutomatica && (
											<p>
												Limite insuficiente para aprovar automaticamente a
												agência &nbsp;
												<b>
													{
														empresa.selecaoAutomatica[
															idSelecaoAutomatica
														].fantasia
													}
												</b>
											</p>
										)}
										{saldos &&
											saldos.naoReservado < roteiro.valor &&
											!temFaturamentoMensal &&
											idSelecaoAutomatica && (
												<p className="info">
													Saldo insuficiente para aprovar automaticamente
													a agência =&nbsp;
													<b>
														{
															empresa.selecaoAutomatica[
																idSelecaoAutomatica
															].fantasia
														}
													</b>
												</p>
											)}
									</>
								)
							}
							onConfirm={() =>
								this.setState(
									{ aguardandoConfirmacao: false },
									this.respostaConfirmacao.bind(this)
								)
							}
							onCancel={() => this.setState({ aguardandoConfirmacao: false })}
						/>
					)}
					{!idModelo && (
						<ModalSalvarModelo
							t={t}
							open={this.state.salvarModelo}
							nomeDoModelo={this.state.nomeDoModelo}
							validacao={this.state.validacaoModelo}
							handleChangeNomeDoModelo={this.handleChangeNomeDoModelo.bind(this)}
							onSubmit={this.onCriarModeloERoteiro.bind(this)}
							onCancel={() => this.setState({ salvarModelo: false })}
						/>
					)}
				</Form>
				<Tutorial
					nome={"criarOportunidade"}
					tutorial={{
						titulo: `Criação de Oportunidade`,
						primeiroTexto: `As oportunidades são o centro da aplicação, aqui você encontra 3 opções. 
                        Cada uma delas representa uma determinada tarefa com suas peculiariedades.`,
						segundoTexto: `Após escolher a tarefa da oportunidade abrirá o formulário para
                        o preenchimento da oportunidade.`,
						primeiraImagem: PrimeiraFoto,
					}}
				/>
			</div>
		);
	}
}

const useCadastroState = empresa => {
	const [state, update] = useState({
		view: "escolher",
		roteiro: null,
		empresa,
	});

	const comecarComModelo = event =>
		update({
			...state,
			view: "modelo",
		});

	const comecarComNovo = event =>
		update({
			...state,
			view: "novo",
			roteiro: new ModelRoteiro(empresa),
		});

	const selecionarModelo = modelo => {
		return update({
			...state,
			view: "novo",
			roteiro: { ...modelo.roteiro, ...defaultRoteiroSchedule, id: null },
		});
	};

	/**
	 * @name selecionarModeloEditar
	 * @date Criado em 31/03/2021
	 * @description se for editar, manda as informaçoes para serem tratadas
	 */
	const selecionarModeloEditar = modelo => {
		return update({
			...state,
			view: "novo",
			idModelo: modelo.id,
			nomeDoModelo: modelo.nome,
			roteiro: { ...modelo.roteiro, ...defaultRoteiroSchedule, id: null },
		});
	};

	const voltarParaOpcoes = () =>
		update({
			...state,
			view: "escolher",
			roteiro: null,
		});

	return [
		state,
		{
			comecarComModelo,
			comecarComNovo,
			selecionarModelo,
			voltarParaOpcoes,
			selecionarModeloEditar,
		},
	];
};

const CadastroContainer = props => {
	const [state, actions] = useCadastroState(props.empresa);

	if (state.view === "novo") {
		return <NovoRoteiro {...props} {...state} actions={actions} />;
	}

	if (props.history.location?.state?.roteiro) {
		actions.selecionarModeloEditar(props.history.location.state);
	}

	if (state.view === "modelo") {
		return <ListarModelos t={props.t} empresa={props.empresa} actions={actions} />;
	}

	return <OpcoesDeCadastroRoteiro t={props.t} {...state} actions={actions} />;
};

export default RoteiroConnect(withTranslation()(CadastroContainer));
