import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Icon } from 'semantic-ui-react';


const DescricaoDaAtividade = ({
    t, atividade, descricao
}) => (
        <>
            <Icon size='big' name={atividade.icone} className='center-v' style={{ float: 'left', marginRight: 14, width: 40 }}></Icon>
            <span style={{ float: 'left', width: 'calc(100% - 40px - 14px)' }}>
                {atividade.pergunta}< br />
                <span className="roteiro-info-secondary">
                    {descricao || `${t('r:')} ${atividade.resposta}`}
                </span>
            </span>
        </>
    );

export const AtividadePerguntaExpirada = ({
    t, item
}) => (
        <Grid className="roteiro-grid" stackable>
            <Grid.Column width={12}>
                <DescricaoDaAtividade t={t} atividade={item} descricao={t('pergunta')} />
            </Grid.Column>
        </Grid>
    );

export class AtividadePergunta extends Component {
    render() {
        const { t, item, executado } = this.props;
        const foiExecutado = executado == null ? true : executado;
        return (
            <Grid className="roteiro-grid" stackable>
                <Grid.Column width={6}>
                    <DescricaoDaAtividade t={t} atividade={item} />
                </Grid.Column>
                {foiExecutado &&
                    <>
                        <Grid.Column width={2} className='align-center'>
                            <span>
                                {item.hora}<br />
                                <span className="roteiro-info-secondary">Hora</span>
                            </span>
                        </Grid.Column>
                        <Grid.Column width={8} className='align-center'>
                        </Grid.Column>
                    </>}
            </Grid>
        )
    }
}

export default withTranslation()(AtividadePergunta);