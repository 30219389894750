import { NumericFormatProps, PatternFormatProps } from "react-number-format";

export type CreditCardFlag = "visa" | "mastercard" | "elo" | "diners" | "amex";

type NumberMaskProps = Pick<
	NumericFormatProps,
	| "allowLeadingZeros"
	| "allowNegative"
	| "allowedDecimalSeparators"
	| "decimalScale"
	| "decimalSeparator"
	| "prefix"
	| "suffix"
	| "thousandsGroupStyle"
	| "thousandSeparator"
	| "fixedDecimalScale"
>;

type PatternMaskProps = Pick<
	PatternFormatProps,
	"allowEmptyFormatting" | "format" | "mask" | "patternChar"
>;

export const CNPJ_MASK: PatternMaskProps = {
	mask: "_",
	format: "##.###.###/####-##",
	allowEmptyFormatting: true,
};

export const CPF_MASK: PatternMaskProps = {
	mask: "_",
	format: "###.###.###-##",
	allowEmptyFormatting: true,
};

export const PHONE_MASK: PatternMaskProps = {
	format: "(##) #####-####",
};

export const CEP_MASK: PatternMaskProps = {
	mask: "_",
	format: "#####-###",
	allowEmptyFormatting: true,
};

export const CREDIT_CARD_NUMBER_MASK = (variant: CreditCardFlag): PatternMaskProps => {
	let format = "";

	if (variant === "visa" || variant === "mastercard" || variant === "elo") {
		format = "#### #### #### ####";
	}

	if (variant === "diners") {
		format = "#### ###### ####";
	}

	if (variant === "amex") {
		format = "#### ###### #####";
	}

	return { mask: "_", format, allowEmptyFormatting: true };
};

export const CREDIT_CARD_CVV_MASK = (variant: CreditCardFlag): PatternMaskProps => {
	let format = "";

	if (
		variant === "visa" ||
		variant === "mastercard" ||
		variant === "elo" ||
		variant === "diners"
	) {
		format = "###";
	}

	if (variant === "amex") {
		format = "####";
	}

	return { mask: "_", format, allowEmptyFormatting: true };
};

export const CREDIT_CARD_EXPIRATION: PatternMaskProps = {
	mask: "_",
	format: "##/##",
	allowEmptyFormatting: true,
};

export const PRICE_MASK: NumberMaskProps = {
	allowNegative: false,
	allowLeadingZeros: false,
	decimalScale: 2,
	decimalSeparator: ",",
	thousandSeparator: ".",
	fixedDecimalScale: true,
};
