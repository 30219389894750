import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Container, Metric, VerticalSeparator } from "./styles";

interface MetricsProps extends WithTranslation {
    data: number[];
}

const Metrics = ({ data, t }: MetricsProps) => {
    return (
        <Container>
            <Metric>
                <h1>{data[0] && data[0]}</h1>
                <h2>{t("total.avaliacoes")}</h2>
            </Metric>
            <VerticalSeparator />
            <Metric>
                <h1>{data[1] && data[1]}</h1>
                <h2>{t("roteiros.executados")}</h2>
            </Metric>
        </Container>
    );
};

export default withTranslation()(Metrics);
