import firebase from 'firebase';
import { Circle, GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import _ from "lodash";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import { lojaSearchBoxString } from "../Utils/Loja";

// Documentação do placaes e geocoder.
// https://developers.google.com/places/web-service/intro
// https://developers.google.com/maps/documentation/javascript/geocoding#ReverseGeocoding

// Utilizado para centralizar o box d 'place'.
const LATITUDE_PAD = 0.0005;
const DEFAULT_ZOOM = 18;

class _MapsImpl extends Component {
    state = { map: null, placesService: null, customMarker: null };

    componentDidMount() {
        const search = document.getElementById("searchBox");
        if (this.searchBox == null) {
            this.searchBoxInit(search);
        }
        document.addEventListener('maps_reset', this.onResetLocation);
    }

    // shouldComponentUpdate(nextProps) {
    //     return this.props.location && nextProps.location ?
    //         !equalCoordinates(this.props.location, nextProps.location) :
    //         (!!this.props.location | !!nextProps.location);
    // }

    componentWillUnmount() {
        document.removeEventListener('maps_reset', this.onResetLocation);
    }

    __getMapsInternalInfoWindowManager = maps => {
        return maps.__gm.IW_AUTO_CLOSER;
    }

    __getMapsInternalInfoWindowRepresentations = maps => {
        const infoWindowManager = this.__getMapsInternalInfoWindowManager(maps);
        return infoWindowManager ? infoWindowManager.j.j.l : {};
    }

    __closeInternalInfoWindow() {
        const { map } = this.state;
        // There is no way to recover the info window from the maps
        // if not using a custom marker.
        // Get  from maps' internal state,
        // the functions for each opened info window
        // and ask to close them.
        _.forEach(this.__getMapsInternalInfoWindowRepresentations(map), i => i.close());
    }

    fetchPlaces(mapProps, map) {
        const { Geocoder, InfoWindow, places: { PlacesService } } = this.props.google.maps;
        this.setState({
            map: map,
            placesService: new PlacesService(map),
            geocoder: new Geocoder(),
            infowindow: new InfoWindow()
        }, () => {
            map.addListener('click', this.handleClickOnIcon.bind(this));
        });
    }

    handleClickOnIcon = (event) => {
        const { placesService, geocoder } = this.state;
        if (event.placeId) {
            placesService.getDetails(
                { placeId: event.placeId },
                (place, status) => status === 'OK' && this.updatePlace(place, false)
            );
        } else {
            geocoder.geocode({ 'location': event.latLng }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        this.updatePlace(results[0], true, event.latLng);
                    } else {
                        window.alert('No results found');
                    }
                } else {
                    window.alert('Geocoder failed due to: ' + status);
                }
            });
        }
    }

    onChangeSeachBox = event => {
        var places = this.searchBox.getPlaces();
        if (places.length !== 1)
            console.warn("More than one place has returned", places);
        var place = places[0];
        this.updatePlace(place, true);
    }

    updatePlace = (place, centralizar, customMarker = null) => {
        const { map } = this.state;
        if (customMarker)
            this.__closeInternalInfoWindow();
        var location = null;
        if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const center = { lat: lat + (centralizar ? 0 : LATITUDE_PAD), lng };
            location = { lat, lng };
            map.setCenter(center);
            map.setZoom(DEFAULT_ZOOM);
        }
        if (customMarker) {
            place.place_id = '';
        }
        this.props.onClickLocation({ place, location });
    };

    searchBox = null;

    searchBoxInit(searchInput) {
        const { SearchBox } = this.props.google.maps.places;
        this.searchBox = new SearchBox(searchInput);
        this.searchBox.addListener('places_changed', this.onChangeSeachBox);
    }

    onResetLocation = (event) => {
        const { map } = this.state;
        map.setCenter(this.props.mylocation);
        map.setZoom(DEFAULT_ZOOM);
    }

    render() {
        const { location, mylocation } = this.props;
        let mapasPropriedades = {
            zoom: 19,
            places: true,
            clickableIcons: true,
            google: this.props.google
        };

        if (!mylocation) {
            return null;
        }

        mapasPropriedades["initialCenter"] = location || mylocation;

        if (location) {
            mapasPropriedades["center"] = location;
        }

        return (
            <Map {...mapasPropriedades} onReady={this.fetchPlaces.bind(this)}>
                {this.props.customMarker && <Marker position={location} />}
                {mylocation && !this.state.customMarker && !location && <Marker position={mylocation} />}
                {this.props.circle &&
                    <Circle center={mapasPropriedades["initialCenter"]}
                        radius={this.props.circle.radius}
                        strokeColor='transparent'
                        strokeOpacity={0}
                        strokeWeight={5}
                        fillColor='#9999ff'
                        fillOpacity={0.3}
                    />}
            </Map>
        );
    }
}

const MapsImpl = GoogleApiWrapper({
    apiKey: "AIzaSyAfzb00CmRqjAdN2CHINrfzcakwi8yFvso"
})(_MapsImpl);

export class Maps extends Component {
    watchID = null;

    state = {
        location: null,
        mylocation: null,
        permissao: true
    };

    componentDidMount() {
        this.handlePermission()
    }

    handlePermission = () => {
        const { endereco } = this.props
        fetch(`http://nominatim.openstreetmap.org/search?q=${endereco.logradouro} ${endereco.numero} ${endereco.cidade} ${endereco.estado}&format=geojson`)
            .then(res => res.json())
            .then(response => {
                let lat = response.features[0].geometry.coordinates[1]
                let lng = response.features[0].geometry.coordinates[0]

                navigator.permissions
                    .query({ name: 'geolocation' })
                    .then((permissionStatus) => {
                        permissionStatus.state !== 'granted' && this.setState({ mylocation: { lat, lng }, permissao: false })
                    })
            })
    }

    componentWillMount() {
        this.requisitarGeolocalizacao()
    }

    componentDidUpdate() {
        if (this.state.location && this.watchID) {
            this.clearWatchGeolocation(this.watchID);
        }
    }

    componentWillUnmount() {
        this.watchID && this.clearWatchGeolocation(this.watchID);
    }

    requisitarGeolocalizacao = () => {
        const geoLocationOptions = {
            enableHighAccuracy: true,
            maximumAge: 1000,
            timeout: 1000
        };
        navigator.geolocation.getCurrentPosition(
            this.useGeolocation, this.handlePermission, geoLocationOptions
        );
        this.watchID = navigator.geolocation.watchPosition(
            this.useGeolocation, this.handlePermission, geoLocationOptions
        );
    }

    clearWatchGeolocation = id => {
        navigator.geolocation.clearWatch(this.watchID);
    }

    useGeolocation = position => {
        if (!this.state.mylocation || position.coords.accuracy < 500) {
            this.setState({
                mylocation: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                permissao: true
            }, () => {
                this.clearWatchGeolocation(this.watchID);
            });
        }
    };

    onClickLocation = ({ place, location: { lat, lng } }) => {
        const { GeoPoint } = firebase.firestore;
        let searchBox = '';
        const loja = {
            contato: {},
            endereco: {
                endereco: place.formatted_address,
                coordenada: new GeoPoint(lat, lng),
            },
            place_id: place.place_id
        };
        if (place.formatted_phone_number) {
            loja.contato.telefone = place.formatted_phone_number;
        }
        if (place.name) {
            loja.fantasia = place.name;
            searchBox = lojaSearchBoxString(loja);
        }
        place.address_components.forEach(add => {
            var valor = add.long_name;
            switch (add.types[0]) {
                case "postal_code": {
                    loja.endereco.cep = valor;
                    break;
                }
                case "country": {
                    loja.endereco.pais = valor;
                    break;
                }
                case "administrative_area_level_1": {
                    loja.endereco.estado = valor;
                    loja.endereco.sigla = add.short_name;
                    break;
                }
                case "administrative_area_level_2": {
                    loja.endereco.cidade = valor;
                    break;
                }
                case "sublocality_level_1": {
                    loja.endereco.bairro = valor;
                    break;
                }
                case "route": {
                    loja.endereco.rua = valor;
                    break;
                }
                case "street_number": {
                    loja.endereco.numero = valor;
                    break;
                }
                default: {
                    break;
                }
            }
        })
        this.props.onClickLocation({ loja, searchBox });
    }

    render() {
        const { mylocation, permissao } = this.state
        const { t } = this.props
        return (
            <>
                {!permissao && <Message
                    content={t('localizacao.bloqueada')}
                    color='red'
                    onClick={this.requisitarGeolocalizacao}
                    style={{ cursor: 'pointer' }} />}
                <MapsImpl
                    circle={this.props.circle}
                    location={this.props.location}
                    mylocation={mylocation}
                    customMarker={this.props.customMarker}
                    searchBoxInput={this.props.searchBoxInput}
                    onClickLocation={this.onClickLocation.bind(this)}
                    iconClick={this.props.iconClick} >
                </MapsImpl>
            </>
        );
    }
}

export default withTranslation()(Maps);
