import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Currency from "react-currency-formatter";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid, Tab } from "semantic-ui-react";
import { HeaderPadrao } from "../../Componentes/Componentes";
import ImagemCircular from "../../Componentes/ImagemCircular";
import { chamarFunction, getToken, listarSaques } from "../../Utils/Api";
import { imagemOuDefault } from "../../Utils/Login";
import { transacoes } from "../../_actions/TransacoesAction";
import { TopUpBalance } from "./TopUpBalance";
import Faturas from "./Faturas";
import OportunidadesPagas from "./OportunidadesPagas";
import Saques from "./Saques";
import RelatorioGastosMensal from "./RelatorioGastosMensal";

const catchCreatedAt = created_at => moment(created_at, "YYYY-MM-DDTHH:mm:ssZ");

const menusComp = {
	"carregar.saldo": TopUpBalance,
	saques: Saques,
	"comprovantes.de.pagamentos": OportunidadesPagas,
	"faturamento.mensal": Faturas,
	"gastos.do.mes": RelatorioGastosMensal,
};

const panes = (props, faturamentoMensal) =>
	Object.entries(menusComp).map(([key, value]) => {
		if (faturamentoMensal && key === "carregar.saldo") {
			return;
		}
		const Component = value;
		return {
			menuItem: props.t(key),
			render: () => <Component {...props} />,
		};
	});

const menus = {
	carregarSaldo: { name: "carregar.saldo", icon: "money" },
	transacoes: { name: "transacoes", icon: "exchange" },
	saques: { name: "saques", icon: "money bill alternate outline" },
	pagamentos: { name: "pagamentos", icon: "money bill alternate outline" },
	faturas: { name: "faturas", icon: "calculator" },
};

export const contraProposta = (valorSolicitado, percentualTaxa) => {
	percentualTaxa = 1.0 - percentualTaxa;
	let contraproposta = (valorSolicitado * 1) / percentualTaxa;
	contraproposta = contraproposta.toFixed(2);
	return parseFloat(contraproposta);
};

class Financeiro extends Component {
	constructor(props) {
		super(props);
		const dataInicial = new Date();
		dataInicial.setDate(new Date().getDate() - 30);
		const dataFinal = new Date();
		this.state = {
			carregando: false,
			dataInicio: props.match.params.dataInicio
				? moment(props.match.params.dataInicio).toDate()
				: dataInicial,
			dataFinal: props.match.params.dataFinal
				? moment(props.match.params.dataFinal).toDate()
				: dataFinal,
			botaoAtualizar: false,
			menuAtual: "transacoes",
			saques: [],
			tabAtiva: 0,
		};
	}

	componentWillMount() {
		this.props.transacoes == null && this.buscarTransacoes();
		this.buscarSaques();
	}

	buscarTransacoes = () =>
		this.setState({ botaoAtualizar: false, carregando: true }, () =>
			chamarFunction("transferencias", {
				uid: this.props.empresa.uid,
				idAuth: this.props.empresa.idUsuario,
				tipo: "empresas",
			})
				.then(transferencias => {
					var transf = [];
					transf = transf.concat(transferencias.sent);
					transf = transf.concat(transferencias.received);
					transf = _.orderBy(transf, o => catchCreatedAt(o.created_at).toDate(), "desc");
					this.setState(
						{
							carregando: false,
						},
						() => this.props.dispatch(transacoes(transf))
					);
				})
				.catch(() =>
					this.setState({
						carregando: false,
						erro: this.props.t("ocorreu.erro.verifique.conexao"),
					})
				)
		);

	buscarSaques = () => {
		getToken().then(token =>
			listarSaques(token, this.props.empresa.uid).then(data => {
				this.setState({ saques: data.items });
			})
		);
	};

	onChangeData = (fieldName, clampTo, data) =>
		this.setState({
			[fieldName]: moment(data)[clampTo]("day").toDate(),
			botaoAtualizar: true,
		});

	changeMenu = menu => this.setState({ menuAtual: menu });

	render() {
		const {
			t,
			totalGasto,
			empresa,
			faturamentoMensal,
			saldos,
			match: {
				params: { aba },
			},
		} = this.props;

		const { tabAtiva } = this.state;
		const somaSaldo = saldos && saldos.reservado + saldos.reservadoNovo;
		const maximo = empresa?.valorMaximoOportunidades ?? 0;
		const restante = isNaN(maximo - faturamentoMensal?.gastos)
			? 0
			: maximo - faturamentoMensal?.gastos;
		const saldo = saldos && saldos.naoReservado ? saldos.naoReservado : 0;
		const reservado = saldos ? somaSaldo : 0;
		const transacoes = this.props.transacoes || [];

		if (tabAtiva) {
			let tab = tabAtiva === -1 ? 0 : tabAtiva;
			this.setState({ tabAtiva: null });

			return <Redirect to={`/financeiro/${tab}`} />;
		}

		return (
			<div>
				<HeaderPadrao
					icone="chart bar outline"
					titulo={t("controle.financeiro")}
					subtitulo={t("veja.dados.relacionados.a.seu.dinheiro")}
				/>
				<Grid stackable>
					<Grid.Column width={16}>
						<div className="header-financeiro">
							<ImagemCircular size="medio" src={imagemOuDefault(empresa.imagemURL)} />
							<div style={{ flex: 1, alignSelf: "center", paddingLeft: 10 }}>
								<span
									style={{
										fontSize: "22px",
										fontWeight: "bold",
										display: "block",
									}}>
									{empresa.fantasia}
								</span>
								<span style={{ display: "block" }}>{empresa.email}</span>
							</div>
							<div
								onClick={() => this.changeMenu(menus.carregarSaldo.name)}
								className="saldo-financeiro">
								<Currency
									quantity={empresa.faturamentoMensal ? restante : saldo}
									locale="pt_BR"
									decimal=","
									group="."
									currency="R$ "
								/>
								<span
									style={{
										display: "block",
										fontSize: 14,
										textTransform: "capitalize",
									}}>
									{empresa.faturamentoMensal
										? t("limite.disponivel")
										: t("saldo")}
								</span>
							</div>
							<div className="saldo-reservado">
								<Currency
									quantity={empresa.faturamentoMensal ? maximo : reservado}
									locale="pt_BR"
									decimal=","
									group="."
									currency="R$ "
								/>
								<span
									style={{
										display: "block",
										fontSize: 14,
										textTransform: "capitalize",
									}}>
									{empresa.faturamentoMensal ? t("limite.total") : t("reservado")}
								</span>
							</div>
						</div>
						<Tab
							activeIndex={aba ?? (empresa.faturamentoMensal ? 1 : 0)}
							onTabChange={(event, data) =>
								this.setState({
									tabAtiva: data.activeIndex === 0 ? -1 : data.activeIndex,
								})
							}
							menu={{
								color: "purple",
								secondary: true,
								pointing: true,
								className: "tab-bar-wrapped",
							}}
							panes={panes(
								{
									...this.state,
									...this.props,
									onChangeData: this.onChangeData,
									buscarTransacoes: this.buscarTransacoes,
									buscarSaques: this.buscarSaques,
									totalGasto: totalGasto,
									transacoes: transacoes,
								},
								empresa.faturamentoMensal
							)}
						/>
					</Grid.Column>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	notasPendentes: state.RecursosReducer.notasPendentes,
	infoConta: state.AutenticacaoReducer.infoConta,
	empresa: state.AutenticacaoReducer.empresa,
	transacoes: state.TransacoesReducer.transacoes,
	totalGasto: state.TransacoesReducer.totalGasto,
	saldos: state.AutenticacaoReducer.saldos,
	faturamentoMensal: state.AutenticacaoReducer.faturamentoMensal,
});

export default connect(mapStateToProps)(withTranslation()(Financeiro));
