import React from "react";

export const formatarValor = (str, decimalPlaces = 3) => {
    let valor = str.replace(/[^0-9]/g, "");
    if (valor.length < decimalPlaces) {
        valor =
            Array(decimalPlaces - valor.length)
                .fill("0")
                .join("") + valor;
    }
    valor =
        valor.slice(0, valor.length - (decimalPlaces - 1)) +
        "." +
        valor.slice(valor.length - (decimalPlaces - 1), valor.length);
    return parseFloat(valor);
};

const proxyDoEvento = (callback) => (event) =>
    callback(event, {
        name: event.target.name,
        value: formatarValor(event.target.value),
    });

export const formatarValorDoInput = (moeda, delimiter, value, placeholder) => {
    const _moeda = moeda || "";
    const _value = (value ? value : placeholder) || 0;
    return `${_moeda} ${_value.toFixed(2).replace(".", delimiter)}`;
};

export const ValorRoteiro = (props) => (
    <input
        disabled={props.disabled}
        style={props.style}
        type="text"
        name="valor"
        className={"valor-roteiro".concat(` ${props.className}`)}
        onChange={proxyDoEvento(props.onChange)}
        placeholder={formatarValorDoInput(props.moeda, ",", props.placeholder)}
        value={props.value ? formatarValorDoInput(props.moeda, ",", props.value, props.placeholder) : ""}
    />
);
