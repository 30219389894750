import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Form, Header } from "semantic-ui-react";
import { v4 } from "uuid";
import { db } from "../../config";
import { logging } from "../../services/Api";
import TreinamentoForm from "./TreinamentoForm";

export const HeaderCadastroTreinamento = props => (
	<Header
		as="h1"
		style={{
			margin: "20px 0 40px",
			display: "flex",
			justifyContent: "center",
			textAlign: "center",
		}}>
		<Header.Content style={{ color: "#7501c0", fontSize: "28px", fontWeight: "bold" }}>
			{props.t("novo.treinamento")}
			<Header.Subheader style={{ fontSize: "22px", color: "#999999" }}>
				{props.t("novo.treinamento.subtitulo")}
			</Header.Subheader>
		</Header.Content>
	</Header>
);
export const criarTreinamento = ({ setState, empresa, treinamento, db }) => {
	return db
		.collection("empresas")
		.doc(empresa.uid)
		.collection("treinamentos")
		.doc(treinamento.id)
		.set(treinamento)
		.then(s => {
			return new Promise(resolve => {
				setState(
					{
						concluido: true,
						carregando: false,
					},
					() => resolve()
				);
			});
		});
};

export class NovoTreinamento extends Component {
	constructor(props) {
		super(props);
		const empresa = props.empresa;
		const ipLocation = props.ipLocation;
		this.state = {
			empresa,
			ipLocation,
			treinamento: {
				id: v4(),
				ativo: true,
				nome: "",
				notaAprovacao: 0,
				descricao: "",
				atividades: {},
			},
			carregando: false,
		};
	}

	onSubmitTreinamento = () => {
		const { treinamento, empresa, ipLocation } = this.state;
		this.setState({ carregando: true }, () =>
			criarTreinamento({
				setState: (payload, callback) =>
					this.setState(payload, () => {
						callback !== undefined && callback();
					}),
				empresa: this.state.empresa,
				treinamento: this.state.treinamento,
				db: db,
			})
				.then(() => {
					logging("CREATE", "log.create.treinamento", treinamento.id);
				})
				.catch(err => {
					console.log(err);
				})
		);
	};

	// Callback para gerenciar o estado do Input do nome
	handleInput = ({ target: { name, value } }) => {
		if (name === "notaAprovacao") {
			var valor = parseFloat(value);
			var nota = isNaN(valor) ? null : valor;
			nota = nota <= 10 ? nota : 10;
			this.setState({ treinamento: { ...this.state.treinamento, [name]: nota } });
		} else {
			this.setState({ treinamento: { ...this.state.treinamento, [name]: value } });
		}
	};

	/**
	 * Callback que altera a descrição geral do roteiro.
	 */
	handleInputDescricao = value =>
		this.setState({
			treinamento: {
				...this.state.treinamento,
				descricao: value,
			},
		});

	render() {
		const { t } = this.props;
		const { handleOpenTreinamentoModal } = this.props;

		const { empresa, treinamento, concluido, carregando } = this.state;

		if (concluido) {
			return <Redirect to={"/treinamento/" + treinamento.id} />;
		}

		return (
			<>
				<HeaderCadastroTreinamento t={t} />
				<Form onSubmit={this.onSubmitTreinamento.bind(this)}>
					<TreinamentoForm
						t={t}
						empresa={empresa}
						treinamento={treinamento}
						handleInput={this.handleInput}
						handleInputDescricao={this.handleInputDescricao}
					/>
					<br />
					<div style={{ textAlign: "center" }}>
						<Button
							color="red"
							basic
							content={t("cancelar")}
							onClick={handleOpenTreinamentoModal}
						/>
						<Button
							color="green"
							content={t("salvar.treinamento")}
							loading={carregando}
							disabled={carregando}
							onClick={this.onSubmitTreinamento.bind(this)}
						/>
					</div>
				</Form>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		idEmpresa: state.AutenticacaoReducer.empresa.uid,
		empresa: state.AutenticacaoReducer.empresa,
		ipLocation: state.AutenticacaoReducer.ipLocation,
	};
};

export default connect(mapStateToProps)(withTranslation()(NovoTreinamento));
