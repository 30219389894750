import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Icon } from 'semantic-ui-react';
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    ...draggableStyle
});

const getListStyle = () => ({
    padding: grid,
    width: "100%"
});

export default class ListaDeAtividades extends Component {

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        let { props } = this.props
        let listaDeAtividade = this.props.state.items
        return (
            <DragDropContext onDragEnd={(result) => props.onDragEnd(result, reorder)} >
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {listaDeAtividade.map((value, index) => (
                                <Draggable key={value.id} draggableId={value.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            onClick={props.abrirAtividade(value)}
                                            className='atividade-treinamento'
                                        >
                                            <div className='info-atividade-treinamento'>
                                                <div className={`icon-drag-indicator ${props.atividadeAtiva === value.id && "active"}`}>
                                                    <section>
                                                        <div /><div /><div />
                                                    </section>
                                                    <section>
                                                        <div /><div /><div />
                                                    </section>
                                                </div>
                                                <Icon name={this.props.iconeTipoAtividade[value.tipo]} size='large' color={props.atividadeAtiva === value.id ? "purple" : "black"} style={{marginLeft: 10}}/>
                                                <div style={{ "color": props.atividadeAtiva === value.id ? "#4F1E63" : "#000" }}>
                                                    <div className='titulo'>{value.titulo}</div>
                                                    <div className='tipo'>{props.t(this.props.labelTipoAtividade[value.tipo])}</div>
                                                </div>
                                            </div>
                                            <div style={{cursor: 'pointer'}} onClick={(e) => props.handleDeleteAtividade(value.id)}>
                                                <Icon name='trash' color={props.atividadeAtiva === value.id ? "purple" : "black"} />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}