import { TRANSACOES, DESAUTENTICA_USUARIO } from "../_actions/Types";

const INITIAL_STATE = {
    transacoes: null,
    totalGasto: 0
};

const reducers = {
    [TRANSACOES]: (state, { totalGasto, transacoes }) => ({
        ...state, totalGasto, transacoes
    }),
    [DESAUTENTICA_USUARIO]: () => ({
        transacoes: null,
        totalGasto: 0
    })
};

export default (state = INITIAL_STATE, action) => {
    const reducer = reducers[action.type];
    if (reducer) return reducer(state, action)
    return state;
};
