import { useTranslation } from "react-i18next";

export function NotifyAboutOpportunityConditions() {
	const { t } = useTranslation();
	return (
		<>
			<div>
				<span style={{ fontSize: "16px", fontWeight: "bolder" }}>{t("nota.fiscal")}</span>{" "}
				<br></br>
				<span
					className="carrinho-pagamento"
					style={{ fontSize: "14px", fontWeight: "bold" }}>
					{t("voce.deve.emitir.nota.fiscal")}
				</span>
			</div>

			<div>
				<span style={{ fontSize: "16px", fontWeight: "bolder" }}>{t("acesso.loja")}</span>{" "}
				<br></br>
				<span
					className="carrinho-pagamento"
					style={{ fontSize: "14px", fontWeight: "bold" }}>
					{t("acesso.loja.texto")}
				</span>
			</div>
		</>
	);
}
