import firebase from "firebase/app";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "semantic-ui-react";
import { AvatarUpload } from "../../../../Componentes/AvatarUpload";
import { db } from "../../../../config";
import { Container, Content, Image } from "./styles";

interface CompanyImageProps {
	company: {
		uid: string;
		image?: string;
	};
}

export function CompanyImage({ company }: CompanyImageProps) {
	const _isMounted = React.useRef(true);
	const [file, setFile] = useState<File | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [srcFile, setSrcFile] = useState("");

	React.useEffect(() => {
		return () => {
			_isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (file) {
			setModalIsOpen(true);
			setSrcFile(URL.createObjectURL(file));
		} else {
			setModalIsOpen(false);
		}
	}, [file]);

	async function handleUpdateImageProfile() {
		async function updateImageProfile() {
			try {
				if (file) {
					handleCancelOperation();
					setIsLoading(true);

					const imageLocation = `/empresas/${company.uid}`;
					const imageRef = await firebase.storage().ref().child(imageLocation).put(file);
					const imagemURL = await imageRef.ref.getDownloadURL();
					await db
						.collection("empresas")
						.doc(company.uid)
						.update({ imagemURL, imagem: imageLocation });

					_isMounted.current && setFile(null);
				}
			} catch (error) {
				console.log(error);
				throw error;
			} finally {
				_isMounted.current && setIsLoading(false);
			}
		}

		toast.promise(updateImageProfile, {
			error: "Ocorreu um erro ao atualizar a imagem do perfil 😥",
			pending: "Atualizando imagem do usuário...",
			success: "Imagem atualizada com sucesso 🎉",
		});
	}

	function handleCancelOperation() {
		setModalIsOpen(false);
	}

	return (
		<Container>
			<AvatarUpload
				defaultSrc={company.image}
				control={{ file, setFile }}
				autoSize
				borderRadius={10}
				isLoading={isLoading}
			/>
			<Modal dimmer open={modalIsOpen} onClose={() => handleCancelOperation()}>
				<Modal.Header>Atualizar imagem de perfil?</Modal.Header>
				<Content>
					<Image src={srcFile}></Image>
				</Content>
				<Modal.Actions>
					<Button negative onClick={() => handleCancelOperation()}>
						Cancelar
					</Button>
					<Button positive onClick={handleUpdateImageProfile}>
						Atualizar
					</Button>
				</Modal.Actions>
			</Modal>
		</Container>
	);
}
