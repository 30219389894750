import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Segment, Loader, Button, Input, Icon, Grid, Message } from "semantic-ui-react";
import { defaultFilterEvent } from "../../Componentes/FiltroOportunidade";
import Confirmar from "../../Componentes/Confirmar";
import { orderBy, roteirosExecutadosFinalizados } from "../../Utils/Roteiros";
import { oportunidadesExecutadasPorPeriodo } from "../../_actions/RoteiroActions";
import RoteiroConnect from "../Roteiro/RoteiroConnect";
import moment from "moment";
import { gerarNotaDeDeposito, gerarNotaDeDepositoOmie } from "./notasFiscaisDeDeposito";
import { chamarFunction } from "../../Utils/Api";
import { Link } from "react-router-dom";

let hoje = moment().format("YYYY-MM-DD");
let menos7 = moment().subtract(7, "days").format("YYYY-MM-DD");

/**
 * @name buscarNFs
 * @date Criado em 14/06/2021
 * @author Alexandre Poletto
 * @description busca uma nota fiscal existente na focus
 * @param {Object} roteiro roteiro completo
 * @param {Function} t Translation
 * @param {Object} erroNota exibição de mensagem da nota
 */
export const buscarNFs = async (roteiro, t, erroNota) => {
	try {
		const { url, numero, numero_rps } = roteiro.notaFiscal;
		if (url && numero && numero_rps) {
			fetch("https://tradepro-cors.herokuapp.com/" + url).then(async res => {
				const body = await res.text();
				gerarNotaDeDeposito(roteiro, () => {}, t, numero_rps, body);
			});
		}
	} catch (erro) {
		console.log(erro);
		erroNota("Nenhuma nota foi encontrada ou emitida");
	}
};

/**
 * @name emitirNFsOmie
 * @date Criado em 14/06/2021
 * @author Alexandre Poletto
 * @description busca uma nota fiscal existente,
 * caso ela não exista emite a nota
 * @param {Object} roteiro roteiro completo
 * @param {Object} erroNota exibição de mensagem da nota
 */
export const emitirNFsOmie = async (roteiro, erroNota, URLNota) => {
	const referencia = roteiro?.pagamento?.taxaPaga ?? roteiro?.pagamento?.refNF;
	const ref = referencia ? referencia.slice(0, 4) + "-" + referencia.slice(28, 32) : null;
	const mensagem = "Nenhuma nota foi encontrada ou emitida";
	try {
		if (roteiro?.notaFiscal?.codigo_nfs) {
			gerarNotaDeDepositoOmie(roteiro, roteiro.notaFiscal, false, ref);
			const nota = `${URLNota}${roteiro?.notaFiscal?.codigo_nfs}`;
			window.location.assign(nota);
		} else if (ref !== null) {
			const ordemServico = await chamarFunction("listarNFSOmie", {
				codigo_nfs: roteiro?.notaFiscal?.codigo_nfs ?? -1,
				ref,
				roteiro,
			});
			console.log(ordemServico);
			if ("ordemServico" === mensagem) {
				erroNota(mensagem);
			} else {
				erroNota(
					"Essa nota está em processo de emissão, aguarde alguns minutos e tente novamente."
				);
			}
		} else {
			erroNota(mensagem);
		}
	} catch (erro) {
		console.log(erro);
	}
};

class OportunidadesPagas extends Component {
	state = {
		filtroAberto: false,
		carregando: false,
		filtro: { dataInicio: menos7, dataFinal: hoje },
		buscandoNotaError: { error: false, mensagem: "" },
		buscandoNotaModal: false,
	};
	componentWillMount() {
		this.buscarOportunidades(defaultFilterEvent());
	}

	QUERY = filtro => {
		var query = roteirosExecutadosFinalizados(this.props.empresa.uid, false);

		if (filtro.dataInicio) query = query.where("data", ">=", filtro.dataInicio);
		if (filtro.dataFinal) query = query.where("data", "<=", filtro.dataFinal);
		if (filtro.tipo && filtro.tipo.id) query = query.where("tipo.id", "==", filtro.tipo.id);
		// return query.where('acesso.teveAcesso', '==', true).orderBy("data", "desc")
		return query.orderBy("data", "desc");
	};

	buscarOportunidades = event => {
		_.isEmpty(this.props.oportunidadesExecutadasPorPeriodo) &&
			this.QUERY(this.state.filtro).onSnapshot(snap => {
				this.props.dispatch(
					oportunidadesExecutadasPorPeriodo(
						snap.docs.filter(s => {
							return s.data().pagamento?.pagarFaturamentoMensal !== true;
						})
					)
				);
			});
	};

	filtrar = () =>
		this.QUERY(this.state.filtro).onSnapshot(snap => {
			this.props.dispatch(
				oportunidadesExecutadasPorPeriodo(
					snap.docs.filter(s => {
						return s.data().pagamento?.pagarFaturamentoMensal !== true;
					})
				)
			);
		});

	notaFiscal = roteiro => {
		const erroNota = mensagem =>
			this.setState({
				buscandoNota: false,
				buscandoNotaError: {
					error: true,
					mensagem,
				},
			});
		this.setState(
			{
				buscandoNota: true,
				buscandoNotaModal: true,
				buscandoNotaError: { error: false, mensagem: "" },
			},
			async () => {
				if (!roteiro?.notaFiscal?.codigo_nfs && roteiro?.notaFiscal?.numero) {
					await buscarNFs(roteiro, this.props.t, erroNota);
					this.setState({ buscandoNota: false });
				} else {
					await emitirNFsOmie(roteiro, erroNota, this.props?.configuracao?.notaFiscal);
					this.setState({ buscandoNota: false });
				}
			}
		);
	};

	render() {
		let { t } = this.props;
		const carregando = this.props.oportunidadesExecutadasPorPeriodo == null;
		const filtro = this.props?.oportunidadesExecutadasPorPeriodo
			?.map(d => d.data())
			.filter(({ acesso }) => {
				return acesso?.teveAcesso === true || acesso === undefined;
			});
		const oportunidades = orderBy(_.groupBy(filtro, "data"));
		let callbackConfirmar = () =>
			!this.state.buscandoNota ? this.setState({ buscandoNotaModal: false }) : () => {};
		return (
			<div>
				<Confirmar
					callback={callbackConfirmar}
					onClose={callbackConfirmar}
					open={this.state.buscandoNotaModal}
					isLoading={this.state.buscandoNota}
					error={this.state.buscandoNotaError.error}
					errorTitle={t("nota.fiscal")}
					errorMessage={this.state.buscandoNotaError.mensagem}
					loadMessage={t("buscando.nota.message")}
					confirmar={t("fechar")}
					fechar={t("fechar")}
					mensagem={t("buscando.nota.message.sucesso")}
					titulo={t("nota.fiscal")}
				/>
				<Grid
					stackable
					textAlign="center"
					columns={11}
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "left",
					}}>
					<Grid.Column width={4}>
						<Input
							type="date"
							name="inicio"
							fluid
							iconPosition="left"
							icon="calendar"
							value={this.state.filtro.dataInicio}
							onChange={m =>
								this.setState({
									filtro: {
										...this.state.filtro,
										dataInicio: moment(m.target.value).format("YYYY-MM-DD"),
									},
								})
							}
						/>
					</Grid.Column>
					<Grid.Column width={4}>
						<Input
							type="date"
							name="fim"
							fluid
							iconPosition="left"
							icon="calendar"
							value={this.state.filtro.dataFinal}
							onChange={m =>
								this.setState({
									filtro: {
										...this.state.filtro,
										dataFinal: moment(m.target.value).format("YYYY-MM-DD"),
									},
								})
							}
						/>
					</Grid.Column>
					<Grid.Column width={3}>
						<Button
							width="2"
							icon="filter"
							fluid
							content={t("filtrar")}
							style={{ display: "flex" }}
							onClick={this.filtrar}></Button>
					</Grid.Column>
				</Grid>
				{carregando && (
					<Segment basic className="page-loading">
						<Loader style={{ display: "block" }} inline="centered">
							Loading
						</Loader>
					</Segment>
				)}
				{_.isEmpty(oportunidades) ? (
					<Message
						icon="clock"
						fluid="true"
						floating
						header={t("sem.comprovantes")}
						content={t("sem.comprovantes.periodo.solicitado")}
					/>
				) : (
					_.map(oportunidades, (opts, group) => {
						return (
							<div key={group}>
								<h2 style={{ marginTop: "30px" }}>
									{moment(group).format("LLLL")}
								</h2>
								<Segment color="orange">
									{opts.map((roteiro, index) => {
										let {
											id,
											valor,
											loja: {
												fantasia,
												endereco: { endereco },
											},
											tipo: { icone },
										} = roteiro;
										return (
											<div key={index}>
												<Grid
													stackable
													textAlign="center"
													columns={16}
													style={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}>
													<Grid.Column width={1}>
														<Link
															to={`/oportunidade/info/${id}`}
															className="link-oportunidade-lista">
															<Icon
																name={icone}
																size="big"
																style={{ marginRight: 10 }}
															/>
														</Link>
													</Grid.Column>
													<Grid.Column width={8}>
														<div style={{ textAlign: "left" }}>
															<span
																style={{
																	fontSize: 15,
																	fontWeight: "bold",
																}}>
																{fantasia}
																<br />
																<span
																	style={{
																		fontSize: 13,
																		fontWeight: "initial",
																	}}>
																	{endereco}
																</span>
															</span>
														</div>
													</Grid.Column>
													<Grid.Column width={3}>
														<span
															style={{
																fontWeight: "bold",
																fontSize: 15,
															}}>
															{valor.toLocaleString("BRL", {
																style: "currency",
																currency: "BRL",
															})}
														</span>
														<br />
														{roteiro.notaFiscal.numero ? (
															<span
																style={{
																	fontSize: 13,
																	fontWeight: "initial",
																}}>
																{t("numero.nf")}{" "}
																{roteiro.notaFiscal.numero}
															</span>
														) : null}
													</Grid.Column>
													<Grid.Column width={4}>
														<Button
															content={
																roteiro?.notaFiscal?.numero
																	? t("baixar.comprovantes")
																	: t("emitir.nota.fiscal")
															}
															icon="download"
															onClick={() => this.notaFiscal(roteiro)}
														/>
													</Grid.Column>
												</Grid>
												{opts.length - 1 > index && <hr />}
											</div>
										);
									})}
								</Segment>
							</div>
						);
					})
				)}
			</div>
		);
	}
}

export default RoteiroConnect(withTranslation()(OportunidadesPagas));
