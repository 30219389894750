// Índices dos tutorials.
// Para criar um novo tutorial,
// deve ser atualizado o DEFAULT_TUTORIALS.
const LOJA = 0;
const MARCA = 1;
const OPORTUNIDADE = 2;

// Valor default para o localStorage dos tutoriais.
const DEFAULT_TUTORIALS = '111111';

// In-memory storage para testes.
const InMemoryStorage = function() {
    const _backstorage = { 'tutorials': DEFAULT_TUTORIALS };
    return {
        getItem: (name) => _backstorage[name],
        setItem: (name, value) => (_backstorage[name] = value)
    }
};

// Verifica se o localStorage está disponível
// Caso o usuario esteja usando o browser no modo privado,
// deve usar o In-memory storage.
const storage = window !== undefined && window.localStorage ? localStorage : InMemoryStorage();

// Inicializa a variável local para não depender do storage.
// Caso não exista, cria uma nova.
let tutorials = storage.getItem('tutorials');
if (tutorials == null) {
    storage.setItem('tutorials', (tutorials = DEFAULT_TUTORIALS));
}

// Devo dizer também que se quieres adicionar outro tutorial que coloque no JSON
// Aqui os tutoriais vao ser colocados pra se caso a pessoa decidir se não quer ver um especifico
let tutorialFreelance = JSON.parse(localStorage.getItem('tutorialsFreelance'));
if (tutorialFreelance == null) {
    localStorage.setItem('tutorialsFreelance', 
    JSON.stringify({
        criarOportunidade: false, 
        criarPDV: false
    }));
}

/**
 * @param {Number} index  Index onde começa a informação do tutorial.
 * @return {Array} Um par [Cancelou manualmente, não necessário]
 */
const getTutorial = index => {
    const col = index * 2;
    return tutorials.slice(col, col + 2).split('').map(x => Boolean(parseInt(x)));
};

/**
 * @param {number} index  Index onde começa a informação do tutorial.
 * @param {number} manual Usuario cancelou manualmente.
 * @param {number} discartar  Tutorial não necessário.
 */
const updateTutorial = index => manual => discartar => {
    const col = index * 2;
    const head = tutorials.slice(0, col);
    const rest = tutorials.slice(col + 2, tutorials.length);
    const res = `${head}${manual}${discartar}${rest}`;
    storage.setItem('tutorials', (tutorials = res));
};

exports.cancelarNovaLojaTutorial = () => updateTutorial(LOJA)(0)(0);
exports.cancelarNovaMarcaTutorial = () => updateTutorial(MARCA)(0)(0);
exports.cancelNovaOportunidadeTutorial = () => updateTutorial(OPORTUNIDADE)(0)(1);

exports.showNovaOportunidadeTutorial = () => {
    const t = getTutorial(OPORTUNIDADE);
    const l = getTutorial(LOJA);
    const m = getTutorial(MARCA);
    return [t[0], l[1], m[1]];
};