import React, { Component } from 'react'
import { Modal, Grid, Header, Button, Message } from 'semantic-ui-react';
import { salvarTreinamentos } from '../../_actions/RoteiroActions';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import _ from 'lodash'
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { db } from '../../config';

class ModalTreinamento extends Component {
    constructor(props) {
        super(props)
        this.state = {
            escolhidos: [],
            treinamentos: []
        }
    }

    componentDidMount() {
        if (this.props.treinamentos == null) {
            this.setState({
                ref: db.collection('empresas').doc(this.props.empresa.uid).collection('treinamentos'),
                escolhidos: this.props.roteiro.treinamentos ?? []
            }, () => {
                this.buscarTreinamento()
            })
        } else {
            this.setState({ treinamentos: this.props.treinamentos, escolhidos: this.props.roteiro.treinamentos ?? [] })
        }
    }

    buscarTreinamento = () => this.state.ref.onSnapshot(this.buscarInformacoesDoTreinamento);

    buscarInformacoesDoTreinamento = snap => {
        var treinamentos = [] 
        snap.forEach(t => {
            treinamentos.push(t.data())
        })
        this.setState({ treinamentos }, () => {
            this.props.dispatch(salvarTreinamentos(treinamentos))
        })

    }

    closeComponent() {
        var container = ReactDOM.findDOMNode(this).parentNode;
        ReactDOM.unmountComponentAtNode(container);
    }

    salvar = () => {
        const { escolhidos } = this.state
        this.props.salvar(escolhidos)
    }

    adicionarTreinamento = ({ id, nome, notaAprovacao }) => () => {
        var escolhidos = [...this.state.escolhidos]
        escolhidos.push({ id, nome, notaAprovacao })
        this.setState({ escolhidos })
    }

    removerTreinamento = (id) => () => {
        var escolhidos = [...this.state.escolhidos]
        escolhidos = _.pull(escolhidos, id)
        this.setState({ escolhidos })
    }

    render() {
        const { loading, escolhidos, treinamentos } = this.state;
        const { open, t } = this.props;
        return (
            <Modal open={open} style={{ maxWidth: '1000px', maxHeight: '80vh', overflow: 'auto' }} className='modal-secoes'>
                <Modal.Header className='header-secao'>
                    <div className='div-header'>
                        <span>Treinamentos</span>
                        <p style={{ fontSize: '14px', color: '#666', fontWeight: 'normal' }}>Clique para adicionar e remover treinamentos.</p>
                    </div>
                </Modal.Header>
                <Modal.Content>
                    {loading &&
                        <LinearProgress />}
                     <Grid stackable>
                        <Grid.Column tablet={8} computer={8} mobile={16}>
                            <Header content='Treinamentos escolhidos' as='h3' color='grey' ></Header>
                            {_.map(escolhidos, treinamento => {
                                return (
                                    <div className='treinamento-oportunidade' onClick={this.removerTreinamento(treinamento)}>
                                        <p>{treinamento.nome}</p>
                                        <p>{treinamento.notaAprovacao}</p>
                                    </div>
                                )
                            })}
                        </Grid.Column>
                        <Grid.Column tablet={8} computer={8} mobile={16}>
                            <Header content='Seus treinamentos' as='h3' color='grey' ></Header>
                            {_.map(treinamentos, treinamento => {
                                if (!_.find(escolhidos, { id: treinamento.id }) && !_.isEmpty(treinamento.atividades)) {
                                    return (
                                        <div className='treinamento-oportunidade' onClick={this.adicionarTreinamento(treinamento)}>
                                            <p>{treinamento.nome}</p>
                                            <p>{treinamento.notaAprovacao}</p>
                                        </div>
                                    )
                                }
                                return null
                            })}
                            <Message size='mini' content={t('treinamentos.sem.atividade')} />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16} tablet={16} className="botoes-secao" style={{
                            position: 'sticky',
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button content={t('cancelar')} basic onClick={this.props.cancelar} color='red' />
                            <Button content={t('salvar')} icon='check' color='green' onClick={this.salvar} />
                        </Grid.Column>
                    </Grid>
                    {/* <div style={{ float: 'right', paddingBottom: '15px', paddingTop: '15px' }}>
                        <Button content='Cancelar' color='red' type='button' basic onClick={this.props.cancelar} />
                        <Button icon='check' content='Salvar' color='green' type='button' onClick={this.salvar} />
                    </div> */}
                </Modal.Content>
            </Modal >
        )
    }
}

const mapStateToProps = state => ({
    treinamentos: state.RoteiroReducer.treinamentos
})

export default connect(mapStateToProps)(withTranslation()(ModalTreinamento));