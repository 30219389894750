import { Box, Button, ButtonProps, Grid, GridProps, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { GenericError } from "../../errors/GenericError";
import { Card } from "../Card";
import { Iconify, IconifyProps } from "../Iconify";

interface ErrorMessageWithRetryProps extends GridProps {
	error: GenericError;
	title?: string;
	icon?: string;
	iconProps?: Omit<IconifyProps, "icon" | "ref">;
	retryFn?: () => void;
	retryButtonProps?: ButtonProps;
}

/**
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 * @description Renderiza uma mensagem de erro com um botão para tentar novamente.
 */
export function ErrorMessageWithRetry({
	error,
	retryFn,
	retryButtonProps,
	title,
	icon = "bxs:error-alt",
	iconProps,
	...restProps
}: ErrorMessageWithRetryProps) {
	const { t } = useTranslation();

	return (
		<Grid
			justifyItems="center"
			alignItems="center"
			alignContent="center"
			gap={4}
			as={Card}
			w="fit-content"
			p={10}
			px={20}
			{...restProps}>
			<Iconify icon={icon} color="red.400" boxSize="28" {...iconProps} />
			<Box textAlign="center">
				<Heading fontSize="2xl">{title || t("ops-um-erro-ocorreu")}</Heading>
				<Text>{error.message}</Text>
			</Box>
			{!!retryFn && (
				<Button {...retryButtonProps} onClick={retryFn}>
					{t("tentar-novamente")}?
				</Button>
			)}
		</Grid>
	);
}
