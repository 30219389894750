import {
    // DOWNLOADED_RECURSOS,
    NOTIFICADO_AVALIACAO_PENDENTE,
    LOJAS_DA_EMPRESA,
    MARCAS_DA_EMPRESA,
    ATUALIZAR_MARCA,
    CONFIGURACAO,
    SemLoja,
    SemMarca,
    DESAUTENTICA_USUARIO,
    LOCALIZACAO,
    NOTAS_PENDENTES,
} from "../_actions/Types";
import { cancelarNovaLojaTutorial, cancelarNovaMarcaTutorial } from "../Utils/Tutorials";

const INITIAL_STATE = {
    configuracao: null,
    lojas: null,
    marcas: null,
    roteirosNovos: [],
    notasPendentes: [],
    notificarPendenteAprovacao: true,
    bloquearCadastro: false,
    localizacao: {},
    redes: [],
};

const replaceAt = (list, obj, pred) => {
    const index = list.findIndex(pred);
    if (index < 0) throw new Error("Element not found");
    return list
        .slice(0, index)
        .concat([obj])
        .concat(list.slice(index + 1, list.length));
};

const table = {
    [NOTIFICADO_AVALIACAO_PENDENTE]: (state, action) => ({ ...state, notificarPendenteAprovacao: false }),
    [LOJAS_DA_EMPRESA]: (state, action) => {
        (action.lojas || []).length !== 0 && cancelarNovaLojaTutorial();
        return {
            ...state,
            lojas: action.lojas.length === 0 ? SemLoja : action.lojas.filter((loja) => !loja.arquivado),
        };
    },
    [MARCAS_DA_EMPRESA]: (state, action) => {
        (action.marcas || []).length !== 0 && cancelarNovaMarcaTutorial();
        return {
            ...state,
            marcas: action.marcas.length === 0 ? SemMarca : action.marcas.filter((produto) => !produto.arquivado),
        };
    },
    [ATUALIZAR_MARCA]: (state, action) => ({
        ...state,
        marcas: replaceAt(state.marcas, action.marca, (marca) => marca.codigo === action.marca.codigo),
    }),
    [CONFIGURACAO]: (state, action) => ({
        ...state,
        configuracao: action.configuracao,
    }),
    [DESAUTENTICA_USUARIO]: () => ({
        configuracao: null,
        lojas: null,
        marcas: null,
        roteirosNovos: [],
        notasPendentes: [],
        notificarPendenteAprovacao: true,
        bloquearCadastro: false,
        localizacao: {},
    }),
    [LOCALIZACAO]: (state, action) => ({
        ...state,
        localizacao: action.location,
    }),
    [NOTAS_PENDENTES]: (state, action) => ({
        ...state,
        notasPendentes: action.notasPendentes,
    }),
};

export default (state = INITIAL_STATE, action) => {
    const transform = table[action.type];
    if (transform) return transform(state, action);
    return state;
};
