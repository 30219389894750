import React from "react";
import { Grid, Icon, Divider, Header } from "semantic-ui-react";

export const OpcaoButton = props => (
	<div className="botao-agencia-empresa" onClick={props.onClick}>
		<Grid stackable>
			<Grid.Column width={3}>
				<p
					style={{
						top: "50%",
						position: "relative",
						transform: "translateY(-50%)",
					}}>
					<Icon size="big" name={props.icon} />
				</p>
			</Grid.Column>
			<Grid.Column width={13} textAlign="left" style={{ lineHeight: "20px" }}>
				<span style={{ fontWeight: "bold", fontSize: 18, display: "block" }}>
					{props.titulo}
				</span>
				<span style={{ fontSize: 15 }}>{props.subtitulo}</span>
			</Grid.Column>
		</Grid>
	</div>
);

export const OpcoesDeCadastroRoteiro = ({ t, actions }) => (
	<>
		<Header as="h1" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
			<Header.Content style={{ color: "#7501c0", fontSize: "28px", fontWeight: "bold" }}>
				{t("criar.oportunidade")}
				<Header.Subheader style={{ fontSize: "22px", color: "#999999" }}>
					{t("selecionar.opcao")}
				</Header.Subheader>
			</Header.Content>
		</Header>

		<div
			className="novo-roteiro"
			style={{
				margin: "0 auto",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<OpcaoButton
				icon="file alternate outline"
				titulo={t("usar.modelo")}
				subtitulo={t("usar.modelo.subtitulo")}
				onClick={event => actions.comecarComModelo()}
			/>
			<Divider style={{ width: "100%" }} />
			<OpcaoButton
				icon="file outline"
				titulo={t("criar.novo")}
				subtitulo={t("criar.novo.subtitulo")}
				onClick={event => actions.comecarComNovo()}
			/>
		</div>
	</>
);
