import { ReactNode, createContext, useContext, useMemo } from "react";
import { Negotiation } from "../../@types/Negotiation";
import { useNegotiations } from "../../hooks/useNegotiations";

interface NegotiationContextProps {
	foundNegotiation: Negotiation | null;
}

interface NegotiationProviderProps {
	children: ReactNode;
	opportunityId: string;
}

const NegotiationContext = createContext<NegotiationContextProps>({} as NegotiationContextProps);

/**
 * Prove uma negociação baseada no id do roteiro
 */
export function NegotiationProvider({ children, opportunityId }: NegotiationProviderProps) {
	const {
		state: { negotiations },
	} = useNegotiations();

	const foundNegotiation = useMemo(() => {
		return negotiations.find(negotiation => negotiation.roteiro.id === opportunityId) || null;
	}, [negotiations, opportunityId]);

	return (
		<NegotiationContext.Provider value={{ foundNegotiation }}>
			{children}
		</NegotiationContext.Provider>
	);
}

/**
 * @description Hook Utilizado para acessar o contexto de `Negotiation` e trazer uma negociação por id de oportunidade.
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function useNegotiationContext() {
	const contextState = useContext(NegotiationContext);

	if (!contextState) {
		throw new Error(
			"O hook não tem acesso ao contexto. Envolva os componentes necessários em um NegotiationProvider"
		);
	}

	return contextState;
}
