import React from "react";
import { Control, FieldError, FieldPath, RegisterOptions, useController } from "react-hook-form";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { Container, FormErrorMessage, FormLabel } from "./styles";

//TODO: Implementar mascara nos inputs de formulário

interface DropdownFormProps<T> extends DropdownProps {
    label?: string;
    isRequired?: boolean;

    //*React Hook Form
    reactHookError?: FieldError;
    enableTextError?: boolean;
    control?: Control<T>;
    name: FieldPath<T>;
    rules?: RegisterOptions<T>;
}

export function DropdownForm<T>({
    icon,
    iconPosition = "left",
    fluid = true,
    selection = true,
    isRequired = false,
    label,
    reactHookError,
    control,
    name,
    rules,
    enableTextError = true,
    ...rest
}: DropdownFormProps<T>) {
    const {
        field: { onChange, value, ref },
    } = useController({
        name,
        control,
        rules,
    });

    return (
        <Container>
            {!!label && (
                <FormLabel htmlFor={name} error={!!reactHookError}>
                    {label}
                    {isRequired && <span> * </span>}
                </FormLabel>
            )}
            <Dropdown
                error={!!reactHookError}
                name={name}
                onChange={(_, { value }) => onChange(value)}
                value={value as string}
                fluid={fluid}
                selection={selection}
                ref={ref}
                {...rest}
            />
            {!!reactHookError && enableTextError && <FormErrorMessage>{reactHookError.message}</FormErrorMessage>}
        </Container>
    );
}
