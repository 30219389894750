import {
	Center,
	Divider,
	Skeleton,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { generateArray } from "toolkit-extra";
import { EmptyContentMessage } from "../../Componentes/EmptyContentMessage";
import { ErrorMessageWithRetry } from "../../Componentes/ErrorMessageWithRetry";
import { PageHeader } from "../../Componentes/PageHeader";
import { UpsertUserDrawer } from "../../Componentes/UpsertUserDrawer";
import { UsersTableRow } from "../../Componentes/UsersTableRow";
import { useAuth } from "../../hooks/store/useAuth";
import { useCompanyUsers } from "../../hooks/useCompanyUsers";
import { PATHS } from "../../Utils/Routes";
import PageLayout from "../Layout";

export default function UsersPage() {
	const { t } = useTranslation();
	const { companyUsers, isLoading, error } = useCompanyUsers();
	const { isUserAdmin } = useAuth();

	const tableSkeletonContent = useMemo(() => {
		return generateArray(10).map(id => {
			const defaultSkeleton = <Skeleton height="15px" rounded={"md"} />;

			return (
				<Tr key={id}>
					{generateArray(7).map(id => {
						return <Td key={id}>{defaultSkeleton}</Td>;
					})}
				</Tr>
			);
		});
	}, []);

	const tableContent = useMemo(() => {
		if (isLoading) return tableSkeletonContent;

		if (!companyUsers.length || error)
			return (
				<Tr>
					<Td colSpan={7}>
						<Center minH={{ base: "sm", md: "container.sm" }}>
							{!error ? (
								<EmptyContentMessage
									title="Nenhum usuário cadastrado."
									message="Clique no botão acima para criar um novo usuário."
								/>
							) : (
								<ErrorMessageWithRetry error={error} />
							)}
						</Center>
					</Td>
				</Tr>
			);

		return companyUsers?.map(user => <UsersTableRow key={user.uid} user={user} />);
	}, [companyUsers, error, isLoading, tableSkeletonContent]);

	if (!isUserAdmin) return <Redirect to={PATHS.dashboard.root} />;

	return (
		<PageLayout>
			<PageHeader
				title={t("usuarios")}
				subtitle={t("descricao-pagina-listagem-de-usuarios")}
				icon="mdi:account-group"
				actions={<UpsertUserDrawer />}
				breadcrumb={[
					{ name: "Dashboard", to: PATHS.dashboard.root },
					{ name: t("usuarios") },
				]}
			/>
			<Divider />
			<TableContainer border={"1px solid"} rounded="md" borderColor={"gray.300"}>
				<Table variant="striped">
					<Thead>
						<Tr>
							<Th>Nome</Th>
							<Th>Email</Th>
							<Th>Celular</Th>
							<Th textAlign="center">Data de criação</Th>
							<Th textAlign="center">Data de edição</Th>
							<Th textAlign="center">Ativo</Th>
							<Th isNumeric />
						</Tr>
					</Thead>
					<Tbody>{tableContent}</Tbody>
				</Table>
			</TableContainer>
		</PageLayout>
	);
}
