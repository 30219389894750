import { Heading } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";
import { OpportunitySegment } from "../../Componentes/OpportunitySegment";
import RoteiroConnect from "../../Interno/Roteiro/RoteiroConnect";
import { AGENCIA_AGUARDANDO_ENCAMINHAMENTO, oportunidadesPendentes, orderBy } from "../../Utils/Roteiros";
import { oportunidadesAgPendentesAguandoPromotor } from "../../_actions/RoteiroActions";

class DirecionarOportunidades extends Component {
    componentDidMount() {
        this.props.opAgPendentesAguardandoPromotor === null &&
            oportunidadesPendentes(this.props.empresa.idUsuario, AGENCIA_AGUARDANDO_ENCAMINHAMENTO).onSnapshot(
                (snap) => {
                    return this.props.dispatch(oportunidadesAgPendentesAguandoPromotor(snap.docs.map((d) => d.data())));
                }
            );
    }

    componentWillUnmount() {
        this.props.dispatch(oportunidadesAgPendentesAguandoPromotor(null));
    }

    render() {
        const { t, opAgPendentesAguardandoPromotor } = this.props;
        const grupos = orderBy(_.groupBy(opAgPendentesAguardandoPromotor, "data"), "asc");

        return (
            <>
                {_.isEmpty(opAgPendentesAguardandoPromotor) ? (
                    <Message content={t("nenhuma.oportunidade.publicada")}></Message>
                ) : (
                    _.map(grupos, (grupo, data) => (
                        <div key={data} className="cor-padrao">
                            <Heading mb="7px" mt="30px!important" size="md">
                                {moment(data).format("LLLL")}
                            </Heading>
                            {_.map(grupo, (roteiro) => (
                                <OpportunitySegment
                                    key={roteiro.id}
                                    opportunity={roteiro}
                                    color="green"
                                    mode="agency"
                                />
                            ))}
                        </div>
                    ))
                )}
            </>
        );
    }
}

export default RoteiroConnect(withTranslation()(DirecionarOportunidades));
