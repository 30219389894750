import React, { useState, Fragment } from "react";
import { Header, Table } from "semantic-ui-react";
import Pagination from "@material-ui/lab/Pagination";
import _ from "lodash";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const linhasPorPagina = 10;

export const TabelaDeOportunidade = ({ setState, state, t, render }) => {
    let consolidado = state.docs.reduce((acc, roteiro) => {
        let { loja, valor, data, _id } = roteiro;
        let rupturaPorOportunidade = state.dados?.pdvs[loja.id]?.rupturasOportunidades[roteiro._id];
        if (rupturaPorOportunidade) {
            return [...acc, { rupturaPorOportunidade, loja, valor, data, _id }];
        } else {
            return acc;
        }
    }, []);
    let oportunidadesChunks = _.chunk(consolidado, linhasPorPagina);
    let [chunkAtivo, setChunk] = useState(oportunidadesChunks[0]);
    let [paginaAtivo, setPaginaAtiva] = useState(1);

    const LinhaExcel = (props) => {
        return (
          <tr key={props.oportunidade._id}>
            <td>{props.oportunidade.loja.fantasia}</td>
            <td>{props.oportunidade.rupturaPorOportunidade.produtos}</td>
            <td>{props.oportunidade.rupturaPorOportunidade.rupturas}</td>
            <td>{props.oportunidade.rupturaPorOportunidade.percentual}%</td>
          </tr>
        );
      };

    return (
        <>
 <table
          style={{ display: "none" }}
          id="op-to-xls"
          className="ui celled table"
        >
          <thead>
            <tr>
              <th>PDV</th>
              <th>Produtos</th>
              <th>Rupturas</th>
              <th>Percentual</th>
            </tr>
          </thead>
          <tbody>
            {consolidado.map((oportunidade) => (
              <Fragment key={oportunidade._id}>
                <LinhaExcel oportunidade={oportunidade} />
              </Fragment>
            ))}
          </tbody>
        </table>
        {!_.isEmpty(consolidado) ? (
          <div
            style={{
              marginTop: 15,
              paddingRight: 3.5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button "
              table="op-to-xls"
              filename="Rupturas-por-oportunidades"
              sheet="Rupturas"
              buttonText="Exportar XLS"
            />
          </div>
        ) : null}
          <Header as="h3" style={{ marginTop: 0 }}>
                {t("rupturas.por.oportunidades")}
            </Header>
            <Table sortable color={"purple"} celled collapsing style={{ width: "100%" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("pdv")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("produtos")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("rupturas")}</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={state.order["pecentualOp"] ?? null}
                            onClick={() =>
                                setState({
                                    order: {
                                        ...state.order,
                                        pecentualOp:
                                            state.order?.pecentualOp === "ascending" ? "descending" : "ascending",
                                    },
                                })
                            }>
                            {t("percentual")}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>{render(state, chunkAtivo, t)}</Table.Body>
                <Table.Footer>
                    <Table.Row textAlign={"center"}>
                        <Table.HeaderCell colSpan="5">
                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                <Pagination
                                    shape="rounded"
                                    count={oportunidadesChunks.length}
                                    page={paginaAtivo}
                                    onChange={(e, page) => {
                                        setPaginaAtiva(page);
                                        setChunk(oportunidadesChunks[page - 1]);
                                    }}
                                />
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    );
};

export const TabelaDePDV = ({ setState, state, t, render }) => {
    let pdvs = _.toArray(state.dados.pdvs);
    let pdvChunks = _.chunk(pdvs, linhasPorPagina);
    let [chunkAtivo, setChunk] = useState(pdvChunks[0]);
    let [paginaAtivo, setPaginaAtiva] = useState(1);

    const LinhaExcel = (props) => {
        return (
          <tr key={props.loja.pdv.id}>
            <td>{props.loja.pdv.fantasia}</td>
            <td>{props.loja.rupturasPdv.produtos}</td>
            <td>{props.loja.rupturasPdv.rupturas}</td>
            <td>{props.loja.rupturasPdv.percentual}%</td>
          </tr>
        );
      };

    return (
        <>
         <table
          style={{ display: "none" }}
          id="pdv-to-xls"
          className="ui celled table"
        >
          <thead>
            <tr>
              <th>PDV</th>
              <th>Produtos</th>
              <th>Rupturas</th>
              <th>Percentual</th>
            </tr>
          </thead>
          <tbody>
            {pdvs.map((loja) => (
              <Fragment key={loja.pdv.id}>
                <LinhaExcel loja={loja} />
              </Fragment>
            ))}
          </tbody>
        </table>
        {!_.isEmpty(pdvs) ? (
          <div
            style={{
              marginTop: 15,
              paddingRight: 3.5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button "
              table="pdv-to-xls"
              filename="Rupturas-por-pdv"
              sheet="Rupturas"
              buttonText="Exportar XLS"
            />
          </div>
        ) : null}
            <Header as="h3" style={{ marginTop: 0 }}>
                {t("rupturas.por.pdv")}
            </Header>
            <Table sortable color={"blue"} celled collapsing style={{ width: "100%" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("pdv")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("produtos")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("rupturas")}</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={state.order["pecentualPDV"] ?? null}
                            onClick={() =>
                                setState({
                                    order: {
                                        ...state.order,
                                        pecentualPDV:
                                            state.order?.pecentualPDV === "ascending" ? "descending" : "ascending",
                                    },
                                })
                            }>
                            {t("percentual")}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>{render(state, chunkAtivo, t)}</Table.Body>
                <Table.Footer>
                    <Table.Row textAlign={"center"}>
                        <Table.HeaderCell colSpan="5">
                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                <Pagination
                                    shape="rounded"
                                    count={pdvChunks.length}
                                    page={paginaAtivo}
                                    onChange={(e, page) => {
                                        setPaginaAtiva(page);
                                        setChunk(pdvChunks[page - 1]);
                                    }}
                                />
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    );
};

export const TabelaDeProdutos = ({ setState, state, t, render }) => {
    let produtos = _.toArray(state.dados.produtos);
    let produtosChunks = _.chunk(produtos, linhasPorPagina);
    let [chunkAtivo, setChunk] = useState(produtosChunks[0]);
    let [paginaAtivo, setPaginaAtiva] = useState(1);

    const LinhaExcel = (props) => {
        return (
          <tr key={props.produto.key}>
            <td>{props.produto.produto.descricao}</td>
            <td>{props.produto.frequencia}</td>
            <td>{props.produto.rupturas}</td>
            <td>{props.produto.percentual}%</td>
          </tr>
        );
      };

    return (
        <>
         <table
          style={{ display: "none" }}
          id="product-to-xls"
          className="ui celled table"
        >
          <thead>
            <tr>
              <th>Produto</th>
              <th>Frequência</th>
              <th>Rupturas</th>
              <th>Percentual</th>
            </tr>
          </thead>
          <tbody>
            {produtos.map((produto) => (
              <Fragment key={produto.key}>
                <LinhaExcel produto={produto} />
              </Fragment>
            ))}
          </tbody>
        </table>
        {!_.isEmpty(produtos) ? (
          <div
            style={{
              marginTop: 15,
              paddingRight: 3.5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button "
              table="product-to-xls"
              filename="Rupturas-por-produto"
              sheet="Rupturas"
              buttonText="Exportar XLS"
            />
          </div>
        ) : null}
            <Header as="h3" style={{ marginTop: 0 }}>
                {t("rupturas.por.produtos")}
            </Header>
            <Table sortable color={"green"} celled collapsing style={{ width: "100%" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("produto")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("frequencia")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("rupturas")}</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={state.order["pecentualProd"] ?? null}
                            onClick={() =>
                                setState({
                                    order: {
                                        ...state.order,
                                        pecentualProd:
                                            state.order?.pecentualProd === "ascending" ? "descending" : "ascending",
                                    },
                                })
                            }>
                            {t("percentual")}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>{render(state, chunkAtivo, t)}</Table.Body>
                <Table.Footer>
                    <Table.Row textAlign={"center"}>
                        <Table.HeaderCell colSpan="5">
                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                <Pagination
                                    shape="rounded"
                                    count={produtosChunks.length}
                                    page={paginaAtivo}
                                    onChange={(e, page) => {
                                        setPaginaAtiva(page);
                                        setChunk(produtosChunks[page - 1]);
                                    }}
                                />
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    );
};
