import {
	Box,
	Center,
	Collapse,
	Flex,
	HStack,
	Spinner,
	Tooltip,
	useDisclosure,
} from "@chakra-ui/react";
import { Badge } from "@material-ui/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AgencyInformationCard } from "../../../../../Componentes/AgencyInformationCard";
import { EmptyMessage } from "../../../../../Componentes/EmptyMessage";
import { IconifyIconButton } from "../../../../../Componentes/IconifyIconButton";
import { PageHeader } from "../../../../../Componentes/PageHeader";
import { useContextOpportunityExecutionNegotiation } from "../../../../../contexts/ContextOpportunityExecutionNegotiation";

export function ListNegotiations() {
	const { t } = useTranslation();

	const collapseController = useDisclosure({ defaultIsOpen: true });

	const { joinBetweenNegotiationAndAgency, agencyIsLoading: agencyIsFetching } =
		useContextOpportunityExecutionNegotiation();

	const filteredJoinBetweenNegotiationAndAgency = useMemo(() => {
		return joinBetweenNegotiationAndAgency.filter(({ negotiation }) => negotiation);
	}, [joinBetweenNegotiationAndAgency]);

	const contentComponent = useMemo(() => {
		if (agencyIsFetching) {
			return (
				<Center>
					<Spinner my={20} size="xl" thickness={"3px"} />
				</Center>
			);
		}

		if (filteredJoinBetweenNegotiationAndAgency.length) {
			return (
				<Flex overflow={"auto"}>
					<HStack gap={4} p={4}>
						{filteredJoinBetweenNegotiationAndAgency.map(agencyWithNegotiation => (
							<AgencyInformationCard
								data={agencyWithNegotiation}
								key={agencyWithNegotiation.agency.uid}
							/>
						))}
					</HStack>
				</Flex>
			);
		}

		return (
			<Center my={20}>
				<EmptyMessage
					text={t("nenhuma-negociacao-encontrada")}
					icon="fa6-solid:handshake-slash"
				/>
			</Center>
		);
	}, [agencyIsFetching, filteredJoinBetweenNegotiationAndAgency, t]);

	return (
		<Box>
			<Flex my={4} justify="space-between" align="center" gap={4}>
				<PageHeader
					title={t("negociacoes")}
					subtitle={t("negociacoes-em-andamento")}
					icon="mdi:handshake"
				/>
				<Tooltip label={collapseController.isOpen ? "Esconder" : "Mostrar"} hasArrow>
					<Badge
						badgeContent={filteredJoinBetweenNegotiationAndAgency.length}
						color="secondary">
						<IconifyIconButton
							alt="controller-collapse-button"
							size="sm"
							rounded="full"
							icon={collapseController.isOpen ? "mdi:arrow-up" : "mdi:arrow-down"}
							{...collapseController.getButtonProps()}></IconifyIconButton>
					</Badge>
				</Tooltip>
			</Flex>
			<Collapse in={collapseController.isOpen}>{contentComponent}</Collapse>
		</Box>
	);
}
