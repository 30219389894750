import { useEffect } from "react";
import { useFirebaseUnsubscribeFunctions } from "../../contexts/FirebaseUnsubscribeFunctionsContext";
import { actions } from "../../_reducers/Negotiation/actions";

import { useAppDispatch } from "../useAppDispatch";
import { useAppSelector } from "../useAppSelector";

export function useNegotiations() {
    const { setUnsubscribe } = useFirebaseUnsubscribeFunctions();
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.NegotiationReducer);

    useEffect(() => {
        if (!state.initialFetchWasMade) {
            dispatch(actions.fetchData()).then((unsubscribe) => {
                if (unsubscribe) setUnsubscribe("negotiations", unsubscribe);
            });
        }
    }, [dispatch, setUnsubscribe, state.initialFetchWasMade]);

    const { data, ...stateRest } = state;

    return {
        state: { ...stateRest, negotiations: data },
        dispatch,
        actions,
    };
}
