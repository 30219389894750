import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Control, FieldPath, RegisterOptions, useController } from "react-hook-form";
import { PriceInput, PriceInputProps } from "../../PriceInput";

interface PriceInputFormProps<T> extends PriceInputProps {
	label?: string;
	leftAddon?: ReactNode;

	//NOTE: React Hook Form
	enableTextError?: boolean;
	control?: Control<T>;
	name: FieldPath<T>;
	rules?: RegisterOptions<T>;
}

/**
 * Renderiza um `PriceInput` controlador pelo `react-hook-form`.
 */
export function PriceInputForm<T>({
	label,
	control,
	name,
	rules,
	enableTextError = true,
	leftAddon,
	...rest
}: PriceInputFormProps<T>) {
	const {
		field: { onChange, value, ref },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
	});

	return (
		<FormControl isInvalid={!!error}>
			{!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
			<PriceInput
				{...rest}
				ref={ref}
				name={name}
				value={value as string}
				onValueChange={values => {
					onChange(values.formattedValue);
				}}
			/>
			{!!error && enableTextError && <FormErrorMessage>{error.message}</FormErrorMessage>}
		</FormControl>
	);
}
