import { add, bignumber, largerEq } from "mathjs";
import { useCallback, useMemo } from "react";
import { LoggedCompanyBalance, LoggedCompanyMonthlyBalance } from "../../@types/User";
import { useAuth } from "../store/useAuth";
import { useAppSelector } from "../useAppSelector";

interface Balance {
	/**
	 * @description Saldo bruto da conta.
	 */
	grossBalance: number;
	/**
	 * @description Saldo liquido da conta.
	 */
	liquidBalance: number;
	/**
	 * @description Despesas da conta.
	 */
	expenses: number;
}

interface UseAccountBalanceReturns {
	/**
	 * @description Objeto que representa o saldo da empresa logada.
	 */
	balance: Balance;
	/**
	 * @description Indica se o saldo da conta atual é suficiente para realizar a operação.
	 */
	balanceIsSufficient: boolean;

	/**
	 * @description Função que compara o valor com o saldo atual, para determinar se o saldo atual é suficiente para realizar a operação.
	 */
	isBalanceSufficient: (valueToCompare: number) => boolean;
}

/**
 * @description Hook que fornece informações e utilitários para trabalhar com o saldo da conta logada.
 * @param valueToCompare Valor que será usado para determinar se o saldo é suficiente no retorno da variável `balanceIsSufficient`
 * @author Leonardo Petta do Nascimento - <leonardocps9@gmail.com>
 */
export function useAccountBalance(valueToCompare?: number): UseAccountBalanceReturns {
	const { company } = useAuth();

	const prepaidBalance = useAppSelector(state => state.AutenticacaoReducer)
		.saldos as null | LoggedCompanyBalance;

	const postpaidBalance = useAppSelector(state => state.AutenticacaoReducer)
		.faturamentoMensal as null | LoggedCompanyMonthlyBalance;

	const balance = useMemo<Balance>(() => {
		let tempBalance: Balance = { expenses: 0, grossBalance: 0, liquidBalance: 0 };

		if (company?.faturamentoMensal) {
			tempBalance.grossBalance = bignumber(company?.valorMaximoOportunidades || 0).toNumber();
			tempBalance.expenses = bignumber(postpaidBalance?.gastos || 0).toNumber();
			tempBalance.liquidBalance = add(
				bignumber(tempBalance.grossBalance),
				bignumber(-tempBalance.expenses)
			).toNumber();
		} else {
			tempBalance.grossBalance = bignumber(prepaidBalance?.total || 0).toNumber();
			tempBalance.liquidBalance = bignumber(prepaidBalance?.naoReservado || 0).toNumber();
			tempBalance.expenses = bignumber(prepaidBalance?.reservadoNovo || 0).toNumber();
		}

		return tempBalance;
	}, [
		company?.faturamentoMensal,
		company?.valorMaximoOportunidades,
		postpaidBalance?.gastos,
		prepaidBalance?.naoReservado,
		prepaidBalance?.reservadoNovo,
		prepaidBalance?.total,
	]);

	const isBalanceSufficient = useCallback(
		(valueToCompare: number): boolean => {
			return !!largerEq(balance.liquidBalance, Number(valueToCompare));
		},
		[balance.liquidBalance]
	);

	const balanceIsSufficient = useMemo(() => {
		if (!valueToCompare) return true;
		return isBalanceSufficient(valueToCompare);
	}, [isBalanceSufficient, valueToCompare]);

	return {
		balance,
		isBalanceSufficient,
		balanceIsSufficient,
	};
}
