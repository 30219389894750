import React from 'react';
import _ from 'lodash';
import { Icon, Table } from 'semantic-ui-react';

export const ASCENDENTE = 1;
export const DIRECAO = -1;

export const Column = props => { };

export const DefaultDataTableHeader = props => props.label;

export const SortingIcon = props => props.sorting ? (
    <Icon name={`angle ${props.sorting === ASCENDENTE ? 'down' : 'up'}`} />
) : null;

const Label = ({ content }) => <span className='row-table-label'>{content}</span>

const mostrarLabel = {
    codigoBarras: true,
    descricao: true,
    preco: true
}

export const DataTable = props => {
    const columnsSpec = React.Children.toArray(props.children);
    const DataTableHeader = props.header || DefaultDataTableHeader;

    return (
        <>
            <Table fixed color="purple">
                <Table.Header>
                    <Table.Row>
                        {columnsSpec.map((column, index) => {
                            const DTH = column.props.header || DataTableHeader;
                            return (
                                <Table.HeaderCell key={`th-${index}`}>
                                    <DTH sortingIcon={props.sortingIcon}
                                        {...column.props}
                                        onClick={column.props.sort || (() => { })} />
                                </Table.HeaderCell>
                            )
                        })}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.dataSource.map((data, rowIndex) => (
                        <Table.Row key={`td-${rowIndex}`}>
                            {columnsSpec.map(
                                (cs, columnIndex) => {
                                    const Cell = cs.props.cell ? cs.props.cell : (props => props.data || null);
                                    return (
                                        <Table.Cell key={`td-${columnIndex}-${rowIndex}`}>
                                            <span style={{ display: "flex" }}>
                                                <Label content={mostrarLabel[cs.props.property] && data[cs.props.property] !== '' ? `${cs.props.label}:` : null} />
                                                <Cell
                                                    {...cs.props}
                                                    rowIndex={rowIndex} columnIndex={columnIndex}
                                                    rowData={data} data={_.get(data, cs.props.property)} />
                                            </span>
                                        </Table.Cell>
                                    );
                                }
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    )
}
