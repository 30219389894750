import _ from "lodash";
import { useState, useEffect, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Table, Pagination } from "semantic-ui-react";
import { alterarEstadoDeUmRelatorio } from "../../../_actions/RelatoriosActions";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Currency from "react-currency-formatter";

/**
 * Relatório de custo por loja exibido no menu de relatorios após seleção de um período.
 *
 * @param {*} props
 * @returns
 */
const RelatorioDeGastoPorLoja = props => {
	const { t } = props;
	const [ordem, setOrdem] = useState({});
	const [oportunidadesPorLoja, setOportunidadesPorLoja] = useState({});
	const [activePage, setActivePage] = useState(1);
	const paginationOportunidades = _.orderBy(oportunidadesPorLoja, ["valor"], ["desc"])
		.slice((activePage - 1) * 10)
		.slice(0, 10);

	//Gera o relatorio com base nas oportunidades do periodo selecionado.
	//Essa função agrupa cada uma das oportunidades por id de loja, detalhando o custo de cada pdv no periodo.
	const relatorioGastoLoja = (oportunidades = props.oportunidades) => {
		var uidLojas = [];
		var oportunidadesLoja = {};
		var arrayLojas = {};

		oportunidades.docs.forEach(oportunidade => {
			let codigo = oportunidade.loja.codigo;
			let uid = oportunidade.loja.id;
			if (oportunidadesLoja[uid]) {
				oportunidadesLoja = {
					...oportunidadesLoja,
					[uid]: [...oportunidadesLoja[uid], oportunidade],
				};
			} else {
				oportunidadesLoja = { ...oportunidadesLoja, [uid]: [oportunidade] };
			}
			if (
				_.findIndex(uidLojas, loja => {
					return loja.uid === uid;
				}) < 0
			) {
				uidLojas.push({ uid, codigo });
			}
		});

		uidLojas.map(loja => {
			var nome,
				oportunidadesCadastradas = 0,
				oportunidadesFinalizadas = 0,
				oportunidadesPendentes = 0,
				total = 0,
				endereco;

			_.forEach(oportunidadesLoja[loja.uid], (value, key) => {
				nome = value.loja.fantasia;
				endereco = value.loja.endereco.endereco;
				if (value.statusRoteiro.id === 3 || value.statusRoteiro.id === 5) {
					total += value.valor;
					oportunidadesFinalizadas++;
				} else {
					oportunidadesPendentes++;
				}
				oportunidadesCadastradas++;
			});

			return (arrayLojas = {
				...arrayLojas,
				[loja.uid]: {
					uid: loja.uid,
					codigo: loja.codigo,
					nome: nome,
					total: total,
					oportunidadesCadastradas,
					oportunidadesPendentes,
					oportunidadesFinalizadas,
					endereco: endereco,
				},
			});
		});
		setOportunidadesPorLoja(arrayLojas);
	};

	useEffect(() => {
		relatorioGastoLoja(props.oportunidades);
	}, []);

	const changeOrdenar = coluna => () => {
		return setOrdem({
			coluna: coluna,
			ordenar: ordem?.ordenar === "ascending" ? "descending" : "ascending",
		});
	};

	const formatarCnpj = cnpj =>
		cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

	const LinhaExcel = props => {
		return (
			<tr key={props.loja.uid}>
				<td>{props.loja.codigo ? formatarCnpj(props.loja.codigo) : t("nao.informado")}</td>
				<td>{props.loja.nome}</td>
				<td>{props.loja.endereco}</td>
				<td>{props.loja.oportunidadesCadastradas}</td>
				<td>{props.loja.oportunidadesPendentes}</td>
				<td>{props.loja.oportunidadesFinalizadas}</td>
				<td align="right">
					<Currency
						quantity={parseFloat(props.loja.total) ?? 0}
						locale="pt_BR"
						decimal=","
						group="."
						currency="R$ "
					/>
				</td>
			</tr>
		);
	};

	return (
		<div>
			<table style={{ display: "none" }} id="table-to-xls" className="ui celled table">
				<thead>
					<tr>
						<th>CNPJ</th>
						<th>Loja</th>
						<th>Estado - Endereço</th>
						<th>Oportunidades cadastradas</th>
						<th>Oportunidades pendentes</th>
						<th>Oportunidades finalizadas</th>
						<th>Total de gastos (Finalizadas)</th>
					</tr>
				</thead>
				<tbody>
					{Object.values(oportunidadesPorLoja).map(loja => (
						<Fragment key={loja.uid}>
							<LinhaExcel loja={loja} />
						</Fragment>
					))}
				</tbody>
			</table>

			{!_.isEmpty(oportunidadesPorLoja) ? (
				<div
					style={{
						marginTop: 15,
						paddingRight: 3.5,
						display: "flex",
						justifyContent: "flex-end",
					}}>
					<ReactHTMLTableToExcel
						id="test-table-xls-button"
						className="download-table-xls-button "
						table="table-to-xls"
						filename="Custo-Por-Loja"
						sheet="Oportunidades"
						buttonText="Exportar XLS"
					/>
				</div>
			) : null}

			<Table sortable celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell
							sorted={ordem.coluna === "cnpj" ? ordem.ordenar : null}
							onClick={changeOrdenar("cnpj")}>
							{t("cnpj")}
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={ordem.coluna === "nome" ? ordem.ordenar : null}
							onClick={changeOrdenar("nome")}>
							{t("loja.cell")}
						</Table.HeaderCell>
						<Table.HeaderCell>
							{t("estado")} - {t("endereco")}
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={
								ordem.coluna === "oportunidadesCadastradas" ? ordem.ordenar : null
							}
							onClick={changeOrdenar("oportunidadesCadastradas")}>
							{t("oportunidades.cadastradas")}
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={
								ordem.coluna === "oportunidadesPendentes" ? ordem.ordenar : null
							}
							onClick={changeOrdenar("oportunidadesPendentes")}>
							{t("oportunidades.pendentes")}
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={
								ordem.coluna === "oportunidadesFinalizadas" ? ordem.ordenar : null
							}
							onClick={changeOrdenar("oportunidadesFinalizadas")}>
							{t("oportunidades.finalizadas")}
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={ordem.coluna === "total" ? ordem.ordenar : null}
							onClick={changeOrdenar("total")}>
							{t("total.gastos.finalizados")}
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.isEmpty(oportunidadesPorLoja)
						? null
						: _.orderBy(
								paginationOportunidades,
								[ordem.coluna],
								[ordem.ordenar === "descending" ? "desc" : ordem.ordenar]
							).map(loja => {
								return (
									<Table.Row key={loja.uid}>
										<Table.Cell>
											{loja.codigo
												? formatarCnpj(loja.codigo)
												: t("nao.informado")}
										</Table.Cell>
										<Table.Cell>{loja.nome}</Table.Cell>
										<Table.Cell>{loja.endereco}</Table.Cell>
										<Table.Cell>{loja.oportunidadesCadastradas}</Table.Cell>
										<Table.Cell>{loja.oportunidadesPendentes}</Table.Cell>
										<Table.Cell>{loja.oportunidadesFinalizadas}</Table.Cell>
										<Table.Cell>R$ {loja.total}</Table.Cell>
									</Table.Row>
								);
							})}
				</Table.Body>
				<Table.Footer>
					<Table.Row textAlign={"center"}>
						<Table.HeaderCell colSpan="7">
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									width: "100%",
								}}>
								<Pagination
									boundaryRange={10}
									activePage={activePage}
									onPageChange={(e, data) => setActivePage(data.activePage)}
									totalPages={Math.ceil(
										Object.keys(oportunidadesPorLoja).length / 10
									)}
								/>
							</div>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
		</div>
	);
};

const mapStateToProps = state => ({
	oportunidades: state.RelatoriosReducer.oportunidades,
});

export default withTranslation()(
	connect(mapStateToProps, {
		alterarEstadoDeUmRelatorio,
	})(RelatorioDeGastoPorLoja)
);
