import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CompanyUser } from "../../@types/User";
import { db } from "../../config";
import { GenericError } from "../../errors/GenericError";
import { useAuth } from "../store/useAuth";

/**
 * @description Hook que retorna os usuários da empresa logada atualmente.
 *
 * @obs Enquanto o hook estiver sendo usado, ele fica observando atualizações no banco de dados.
 */
export function useCompanyUsers() {
	const [isLoading, setIsLoading] = useState(true);
	const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([]);
	const [error, setError] = useState<GenericError | null>(null);

	const { company } = useAuth();

	/**
	 * @description Função responsável em setar e lidar com os erros locais do hook.
	 * @param error Erro desconhecido que será passado e tratado.
	 */
	const handleLocalError = useCallback((error: unknown) => {
		setError(new GenericError(error));

		toast.error("Erro ao buscar os usuários. Tente novamente mais tarde.");
		setIsLoading(false);
	}, []);

	const fetchUsers = useCallback(() => {
		try {
			setIsLoading(true);

			if (!company) throw new Error("Empresa inexistente.");

			const collectionsReference = db
				.collection("empresas")
				.doc(company.uid)
				.collection("usuarios");

			return collectionsReference.onSnapshot(
				snap => {
					try {
						setCompanyUsers(snap.docs.map(doc => doc.data() as CompanyUser));
						setIsLoading(false);
					} catch (e) {
						handleLocalError(e);
					}
				},
				e => {
					handleLocalError(e);
				}
			);
		} catch (e) {
			handleLocalError(e);
		}
	}, [company, handleLocalError]);

	useEffect(() => {
		const unsubscribeFunction = fetchUsers();

		return () => {
			if (unsubscribeFunction) {
				unsubscribeFunction();
			}
		};
	}, [fetchUsers]);

	return { isLoading, companyUsers, error };
}
