import _ from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Form, Button, Dropdown, Icon, Loader, Segment } from 'semantic-ui-react';
import { auth } from '../config';
import { bancosDisponiveis } from '../Utils/Conta';
import { verificarSubConta } from '../Utils/Api';
import BancoInput from './BancoInput';



const defaultBanco = {
    objetoBanco: null,
    tipoConta: null,
    agencia: null,
    conta: null,
    operacao: null
};

const operacoes = [
    { text: '001 – Conta Corrente de Pessoa Física', key: '001', value: '001' },
    { text: '002 – Conta Simples de Pessoa Física', key: '002', value: '002' },
    { text: '003 – Conta Corrente de Pessoa Jurídica', key: '003', value: '003' },
    { text: '013 – Poupança de Pessoa Física', key: '013', value: '013' },
    { text: '022 – Poupança de Pessoa Jurídica', key: '022', value: '022' },
];

const tipoConta = [
    { text: 'Conta Corrente', key: '001', value: { nome: 'Corrente', id: 0 } },
    { text: 'Conta Poupança', key: '002', value: { nome: 'Poupança', id: 1 } },
];

const ExibirErrorsDaConta = props => props.errors ? (
    <ul style={{ margin: 0, marginTop: 5, marginBottom: 20, padding: '0 20px' }}>
        {props.errors.map(error => (
            <li style={{ color: 'red', textTransform: 'capitalize' }}>{error}</li>
        ))}
    </ul>
) : null;

class EditarInformacoesBancaria extends Component {
    constructor(props) {
        super(props);
        const banco = props.empresa.banco || defaultBanco;
        this.state = {
            errors: null,
            enviando: false,
            bancos: [],
            listaDeBancos: [],
            banco: banco.objetoBanco,
            tipoConta: banco.tipoConta,
            agencia: banco.agencia,
            conta: banco.conta,
            operacao: banco.operacao
        };
    }

    componentDidMount() {
        bancosDisponiveis().get().then(
            snap => this.setState({
                ...snap.docs.reduce((acc, doc) => {
                    const banco = doc.data();
                    acc.bancos.push({ id: banco.id, text: banco.banco, key: banco.id, value: banco.id });
                    acc.listaDeBancos.push(banco);
                    return acc;
                }, { bancos: [], listaDeBancos: [] })
            })
        );
    }

    handleChange = (event, { name, value }) => this.setState({ [name]: value });

    handleChangeInput = name => event =>
        this.handleChange(event, { name, value: event.target.value });

    onSubmit = event => {
        event.preventDefault();
        const { banco, tipoConta, agencia, conta, operacao } = this.state;
        const objetoBanco = this.state.listaDeBancos.find(b => b.id === banco);
        const perfilBanco = {
            idEmpresa: this.props.empresa.uid,
            objetoBanco, tipoConta, agencia, conta
        };
        if (objetoBanco.hasOwnProperty('operacao')) {
            perfilBanco['operacao'] = operacao;
        }

        this.setState(
            { enviando: true },
            () => { auth.currentUser.getIdToken(true).then(
                token => verificarSubConta(
                    token, perfilBanco
                ).then(
                    this.props.onVerificacaoDeConta
                ).catch(
                    error => this.setState({ errors: error.error })
                )
            ).finally(
                () => this.setState({ enviando: false })
            );
        });
    }

    getErrors = (p1,p2, errors) => {
        var er1 = _.get(errors, p1, [])
        var er2 = _.get(errors, p2, [])
        return [...er1, ...er2]    
    }

    render() {
        const { t } = this.props;
        const { errors, enviando } = this.state;

        const carregando = this.state.bancos.length === 0;
        const errorSubConta = _.get(errors, 'account', null);
        const errorBanco = _.get(errors, 'bank', null);
        const errorAgencia = this.getErrors('bank_ag', 'agency', errors);
        const errorConta = _.get(errors, 'bank_cc', null);
        const errorOperacao = _.get(errors, 'account_type', null);
        const banco = this.state.listaDeBancos.find(banco => banco.id === this.state.banco)

        if (carregando || enviando) {
            return (
                <div className="ui loader">
                    <Icon name="spinner" loading={true} style={{ color: 'rgba(0,0,0,.9)' }} />
                </div>
            );
        }

        const mensagem =
            !this.props.empresa.iugu ?
                "Estamos implementando novas formas de pagamento e para isso precisamos que informe os dados bancários da empresa. Estamos a disposição para responder qualquer dúvida :)" :
                "Parece que os dados bancários informados estão inconsistentes, por favor informe novamente para que possamos completar o seu cadastro. Estamos a disposição para responder qualquer dúvida :)";

        return (
            <div style={{ padding: 20 }}>
                <p style={{ marginBottom: 20 }}>{mensagem}</p>
                {!carregando ? (
                    <Form onSubmit={this.onSubmit.bind(this)}>
                        <div className="field">
                            <label>Selecione o seu banco</label>
                        </div>
                        <Dropdown
                            className='input-banco-border'
                            placeholder={t('selecione.seu.banco')}
                            onChange={this.handleChange.bind(this)}
                            value={this.state.banco || ""}
                            fluid
                            selectOnBlur={false}
                            name='banco'
                            selection
                            options={this.state.bancos} />
                        <ExibirErrorsDaConta errors={errorBanco} />
                        {banco && (
                            <>
                                <div className="field" style={{ marginTop: 10 }}>
                                    <label>Selecione o seu tipo de conta</label>
                                </div>
                                <Dropdown
                                    className='input-banco-border'
                                    placeholder={t('tipo.de.conta')}
                                    onChange={this.handleChange.bind(this)}
                                    value={this.state.tipoConta}
                                    fluid
                                    selectOnBlur={false}
                                    name='tipoConta'
                                    selection
                                    required
                                    options={tipoConta}
                                />
                                <ExibirErrorsDaConta errors={errorOperacao} />
                                <div className="field" style={{ marginTop: errorConta ? 0 : 10 }}>
                                    <BancoInput
                                        label={t('agencia')}
                                        mask={banco.formatoAgencia}
                                        name="agencia" value={this.state.agencia}
                                        onChange={this.handleChange.bind(this)} />
                                    <ExibirErrorsDaConta errors={errorAgencia} />
                                </div>
                                <div className="field" style={{ marginTop: errorConta ? 0 : 10 }}>
                                    <BancoInput
                                        label={t('conta')}
                                        mask={banco.formatoConta}
                                        name="conta" value={this.state.conta}
                                        onChange={this.handleChange.bind(this)} />
                                    <ExibirErrorsDaConta errors={errorConta} />
                                    <ExibirErrorsDaConta errors={errorSubConta} />
                                </div>
                                {banco.operacao && (
                                    <>
                                        <div className="field" style={{ marginTop: 10 }}>
                                            <label>Selecione a operação da conta</label>
                                        </div>
                                        <Dropdown
                                            className='input-banco-border'
                                            placeholder={t('selecione.uma.operacao')}
                                            onChange={this.handleChange.bind(this)}
                                            value={this.state.operacao}
                                            fluid
                                            selectOnBlur={false}
                                            name='operacao'
                                            selection
                                            required
                                            options={operacoes} />
                                        {/* <ExibirErrorsDaConta errors={errorOperacao} /> */}
                                    </>
                                )}
                                <Form.Group widths="equal">
                                    <div style={{ marginTop: 10, width: '100%', textAlign: 'center' }}>
                                        <Button type="submit"
                                            className="green-gradient-light" color="green"
                                            content="Pronto" />
                                    </div>
                                </Form.Group>
                            </>
                        )}
                    </Form>
                ) : (
                        <Segment basic>
                            <Loader inverted active inline='centered' />
                        </Segment>
                    )}
            </div>
        );
    }
}

const VerificacaoDeBanco = props => (
    <Modal open={props.open} className="conta-invalida">
        <Modal.Header>Informações bancárias</Modal.Header>
        <Modal.Content>
            {props.open && <EditarInformacoesBancaria {...props} />}
        </Modal.Content>
    </Modal>
);

export default withTranslation()(VerificacaoDeBanco);
