import _ from 'lodash'
import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'

const BtnFile = ({ getVideo }) => {
    return <div class="button-wrapper">
        <div style={{
            width: 200,
            height: 40,
            backgroundColor: "#eee",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 5,
            cursor: 'pointer'
        }}>
            <span style={{ textAlign: 'center' }}>
                <Icon disabled name='video' size="large" />
                Selecione outro video
            </span>
        </div>

        <input
            accept="video/*"
            type="file"
            name="upload"
            id="upload"
            style={{
                display: "inline-block",
                position: "absolute",
                zIndex: 1,
                width: 200,
                height: 40,
                top: 0,
                left: 0,
                opacity: 0,
                cursor: "pointer",
            }}
            class="upload-box"
            placeholder="Upload File"
            onChange={getVideo} />
    </div>
}

const Progress = ({ progress }) => {
    return <div style={{
        width: "100%",
        height: 30,
        backgroundColor: "#CACBCD",
        textAlign: 'center',
        borderRadius: 5,
        overflow: 'hidden',
        marginBottom: 15
    }}>
        <span style={{ position: 'absolute', marginTop: 4, color: "#fff" }}>{progress}%</span>
        <div style={{ width: `${progress}%`, height: "100%", backgroundColor: "#4F1E63" }} />
    </div>
}

export default class Preview extends Component {
    constructor(props) {
        super(props)
        this.state = { thumb: "" }
    }

    componentDidMount() {
        this.props.preview !== "carregando" && document.getElementById('video').addEventListener('loadedmetadata', () => {
            this.currentTime = this.duration / 2;
            document.getElementById('video').removeEventListener('loadedmetadata', () => { }, false);
        }, false);
    }

    componentDidUpdate(prevProps) {
        document.getElementById("descricao").innerHTML = this.props.descricao ? this.props.descricao : "Descricao"
        if (this.props.preview !== prevProps.preview) {
            return this.video.src = this.props.preview
        }
    }

    render() {
        let { selecionarThumb, thumbAtiva, preview } = this.props
        return (
            <div>
                <div style={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 10,
                    marginBottom: 10,
                }}>
                    <section style={{
                        width: "50%",
                        marginRight: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <div>
                            <h4>{this.props.titulo ? this.props.titulo : "Titulo"}</h4>
                            <div id="descricao"></div>
                            {!_.isEmpty(this.props.blobs) && <>
                                <span>Selecione uma thumbnail abaixo</span>
                                <div style={{
                                    display: "flex",
                                    flexDirection: 'row',
                                    width: '100%',
                                    marginTop: 10,
                                    marginBottom: 10
                                }}>
                                    {this.props.blobs.map(({ url, id }) => {
                                        let styleAtivo = thumbAtiva === id ? { border: " 2px solid #4F1E63", } : {}
                                        return <div style={{ ...styleAtivo, cursor: 'pointer', marginRight: 10 }}>
                                            <img key={id} alt='Opção de thumbnail.' src={url} style={{ width: 80, height: 50 }} onClick={() => selecionarThumb(id)} />
                                        </div>
                                    })}
                                </div>
                            </>}
                        </div>
                        {this.props.carregando ?
                            <Progress progress={this.props.progress} />
                            :
                            <BtnFile getVideo={this.props.getVideo} />}
                    </section>
                    <section style={{
                        width: "50%",
                        height: 200,
                        display: "flex",
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}>
                        {preview === "carregando" ?
                            <div style={{
                                width: '100%',
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <div class="carregando_video"></div>
                            </div>
                            :
                            <video
                                ref={(v) => this.video = v}
                                id="video"
                                muted
                                height="100%" style={{ marginBottom: 14 }}>
                                <source src={preview} type="video/mp4" />
                            </video>
                        }

                    </section>
                </div>
            </div>
        )
    }
}
