import _ from 'lodash'

/** 
* @name juntarProdutos
* @date Criado em 14/12/2020
* @author Diego jimenes
* @description faz a junção dos produtos em um unico array para melhor ultilização
* @param { Object } ProdutosSelecionados lista de produtos selecionados
* @param { Object } retornosGeral todos os retornos da api separados por pagina
*/
export const juntarProdutos = (produtosSelecionados, retornosGeral) => {
    let arrayDeProdutos = _.reduce(produtosSelecionados, (acumulador, produtos) => {
        return { ...acumulador, ...produtos }
    }, {})
    return _.reduce(arrayDeProdutos, (array, value, key) => {
        if (value === true) {
            return [...array, retornosGeral[key]]
        } else {
            return array
        }
    }, [])
}

/** 
 * @name buscarProdutos
 * @date Criado em 14/12/2020
 * @author Diego jimenes
 * @description executa a busca dos produtos e faz todas as validações necessárias 
 * @param { String } origem id da empresa de origem, de onde os produtos devem ser buscados
 * @param { String } origemInicial id para comparação
 * @param { String } produto string para realizar a busca do produto
 * @param { Number } paginaAtual pagina atual
 * @param { Object } ProdutosSelecionados lista de produtos selecionados
 * @param { Bollean } SelectAll um bollean que controla se todos os items devem ser selecionados
 * @param { Object } retornosGeral todos os retornos da api separados por pagina
 * @param { Object } axios dependencia
 * @param { Object } firebase dependencia
 * @param { Function } buscaProdutos função que faz a busca na nossa api
 * 
 */
export const buscarProdutos = (origem, origemInicial, produto, paginaAtual, ProdutosSelecionados, SelectAll, retornosGeral, axios, firebase, buscaProdutos) => {
    if (origemInicial !== origem) paginaAtual = 1
    return buscaProdutos(origem, produto, axios, firebase, paginaAtual).then((result) => {
        let produtos = result.data.docs
        if (_.isEmpty(produtos)) return Promise.reject({ error: 'sem-produto' })
        let selectAll = SelectAll[paginaAtual] ? SelectAll[paginaAtual] : false
        let novos = produtos.reduce((acumulador, { _id }) => {
            let atual = acumulador[paginaAtual] ? acumulador[paginaAtual] : {}
            return {
                ...acumulador,
                [paginaAtual]: {
                    ...atual,
                    [_id]: selectAll === false ? false : true
                }
            }
        }, {})

        let agruparResultadosProdutos = produtos.reduce((acumulador, produto) => {
            return { ...acumulador, [produto._id]: produto }
        }, { ...retornosGeral })

        let produtosSelecionados = {}

        if (origemInicial === origem) {
            produtosSelecionados = { ...ProdutosSelecionados, ...novos }
        } else {
            produtosSelecionados = { ...novos }
        }

        return Promise.resolve({
            retornoProdutos: produtos,
            retornosGeral: agruparResultadosProdutos,
            origemInicial: origem,
            produtosSelecionados,
            totalDePaginas: result.data.pages,
            loadingProdutos: false,
            semProdutos: false,
            paginaAtual: paginaAtual,
            selectAll: {
                ...SelectAll,
                [paginaAtual]: (SelectAll[paginaAtual] ?? false)
            }
        })
    })
}

/** 
 * @name listProdutos
 * @date Criado em 15/12/2020
 * @author Vitor Savian
 * @description prepara especificamente para tratar o filtro e arrumar
 * pro componente as informações vindas do paginationSearch 
 * @param { String } descricao descricao do produto para a pesquisa
 * @param { String } preco preco do produto para a pesquisa
 * @param { String } idEmpresa id da empresa que será usado na pesquisa
 * @param { Object } ProdutosSelecionados lista de produtos selecionados
 * @param { Bollean } SelectAll um bollean que controla se todos os items devem ser selecionados
 * @param { Number } paginaAtual pagina atual
 * @param { Object } retornosGeral todos os retornos da api separados por pagina
 * @param { Object } axios dependencia
 * @param { Object } firebase dependencia
 * @param { Boolean } filtro dependencia
 * @param { Function } paginationSearch função que faz a busca no mongo e devolve possivelmente com filtro
 * 
 */
export const listProdutos = async (descricao, preco, idEmpresa, ProdutosSelecionados, SelectAll, paginaAtual, retornosGeral, axios, firebase, filtro, paginationSearch ) => {
    return await paginationSearch(idEmpresa, descricao, preco, axios, firebase, paginaAtual ).then((result) => {
        let produtos = result.data.docs
        if (_.isEmpty(produtos)) return Promise.reject({ error: `Sem produtos`})
        let selectAll = SelectAll[paginaAtual] ? SelectAll[paginaAtual] : false
        let novos = produtos.reduce((acumulador, { _id }) => {
            let atual = acumulador[paginaAtual] ? acumulador[paginaAtual] : {}
            return {
                ...acumulador,
                [paginaAtual]: {
                    ...atual,
                    [_id]: selectAll === false ? false : true
                }
            }
        }, {})

        let agruparResultadosProdutos = {}
        let produtosSelecionados = {}

        if(filtro === false){
            agruparResultadosProdutos = produtos.reduce((acumulador, produto) => {
                return { ...acumulador, [produto._id]: produto }
            }, { ...retornosGeral })
            produtosSelecionados = {...ProdutosSelecionados, ...novos}
        } else {
            agruparResultadosProdutos = produtos.reduce((acumulador, produto) => {
                return { ...acumulador, [produto._id]: produto }
            }, { })
            produtosSelecionados = { ...novos }
        }

        return Promise.resolve({
            products: produtos,
            retornosGeral: agruparResultadosProdutos,
            produtosSelecionados,
            semProdutos: false,
            totalDePaginas: result.data.pages,
            activePage: paginaAtual,
            selectAll: {
                ...SelectAll,
                [paginaAtual]: (SelectAll[paginaAtual] ?? false)
            }
        })
    }) 
}

/** 
 * @name toggleProdutos
 * @date Criado em 14/12/2020
 * @author Diego jimenes
 * @description faz a seleção deu um produtos específico
 * @param { String } _id id do produto
 * @param { Number } paginaAtual pagina atual
 * @param { Object } ProdutosSelecionados lista de produtos selecionados
 */
export const toggleProdutos = (_id, paginaAtual, ProdutosSelecionados) => {
    let produtosSelecionados = ProdutosSelecionados[paginaAtual] ? ProdutosSelecionados[paginaAtual] : {}
    if (produtosSelecionados[_id] === true) {
        produtosSelecionados[_id] = false
    } else {
        produtosSelecionados[_id] = true
    }
    let novos = ProdutosSelecionados
    novos[paginaAtual] = produtosSelecionados
    return novos
}

/** 
 * @name toggleAllProdutos
 * @date Criado em 14/12/2020
 * @author Diego jimenes
 * @description faz a seleção de todos os produtos da pagina atual
 * @param { Bollean } selectAll um bollean que controla se todos os items devem ser selecionados
 * @param { Object } ProdutosSelecionados lista de produtos selecionados
 * @param { Number } paginaAtual pagina atual
 */
export const toggleAllProdutos = (selectAll, ProdutosSelecionados, paginaAtual) => {
    let produtosSelecionados = _.reduce(ProdutosSelecionados[paginaAtual], (acumulador, obj, index) => {
        let atual = acumulador[paginaAtual] ? acumulador[paginaAtual] : {}
        return {
            ...acumulador,
            [paginaAtual]: {
                ...atual,
                [index]: selectAll
            }
        }
    }, {})

    return {
        ...ProdutosSelecionados,
        ...produtosSelecionados
    }
}

/**
 * @name checkar
 * @date Criado em 14/12/2020
 * @author Diego jimenes
 * @description gerencia a seleção dos destinos
 * @param { String } valor id da empresa de destino
 * @param { Array } destinos lista de destinos
 */
export const checkar = (valor, destinos) => {
    let novosDestinos = destinos
    if (novosDestinos.includes(valor)) {
        novosDestinos = _.pull(novosDestinos, valor)
    } else {
        novosDestinos.push(valor)
    }
    return novosDestinos
}