import { withTranslation } from "react-i18next";
import { useState, useReducer } from "react";
import { connect } from "react-redux";
import { Table, Pagination } from "semantic-ui-react";
import moment from "moment";
import { Heading } from "@chakra-ui/react";

const RelatorioGastoMensal = (props) => {
    const { oportunidadesFinalizadas, gastoMensalReal, quantidadeOportunidadesFinalizadas } =
        props.oportunidadesFinalizadasMes;

    const [activePage, setActivePage] = useState(1);
    const [oportunidadesExibir, setOportunidadesExibir] = useState(oportunidadesFinalizadas);

    const [state, dispatch] = useReducer(sortReducer, {
        column: null,
        data: oportunidadesExibir,
        direction: null,
    });
    const { column, direction } = state;
    const paginationOportunidades = _.orderBy(oportunidadesExibir, ["data"], ["desc"])
        .slice((activePage - 1) * 20)
        .slice(0, 20);

    function sortReducer(state, action) {
        switch (action.type) {
            case "CHANGE_SORT":
                if (state.column === action.column) {
                    setOportunidadesExibir(oportunidadesExibir.slice().reverse());
                    return {
                        ...state,
                        data: state.data.slice().reverse(),
                        direction: state.direction === "ascending" ? "descending" : "ascending",
                    };
                }
                setOportunidadesExibir(_.sortBy(oportunidadesExibir, [action.column]));

                return {
                    column: action.column,
                    data: _.orderBy(state.data, action.column, state.direction === "ascending" ? "asc" : "desc"),
                    direction: "ascending",
                };

            default:
                throw new Error();
        }
    }

    return (
        <>
            <Heading as="h3" size="md" my={2}>
                Oportunidade executadas no mês de {moment().format("MMMM")}
            </Heading>

            <Table striped sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={column === "loja.fantasia" ? direction : null}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "loja.fantasia" })}
                        >
                            Loja
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === "usuario.nome" ? direction : null}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "usuario.nome" })}
                        >
                            Promotor
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === "data" ? direction : null}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "data" })}
                        >
                            Data
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === "valor" ? direction : null}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "valor" })}
                        >
                            Valor
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {paginationOportunidades.map((item, index) => {
                        return (
                            <Table.Row>
                                <Table.Cell>{item.loja.fantasia}</Table.Cell>
                                <Table.Cell>{item.usuario.nome}</Table.Cell>
                                <Table.Cell>{moment(item.data).format("DD/MM/YYYY")}</Table.Cell>
                                <Table.Cell>
                                    {" "}
                                    {item.valor.toLocaleString("pt-br", {
                                        style: "currency",
                                        currency: "BRL",
                                    })}
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                    <Table.Row>
                        <Table.Cell colSpan={3} textAlign="right">
                            <b>Valor total gasto:</b>
                        </Table.Cell>
                        <Table.Cell>
                            <b>
                                {gastoMensalReal.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </b>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan={3} textAlign="right">
                            <b>Total de oportunidades executadas:</b>
                        </Table.Cell>
                        <Table.Cell>
                            <b>{quantidadeOportunidadesFinalizadas}</b>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
                <Table.Footer>
                    <Table.Row textAlign={"center"}>
                        <Table.HeaderCell colSpan="5">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                <Pagination
                                    boundaryRange={10}
                                    activePage={activePage}
                                    onPageChange={(e, data) => setActivePage(data.activePage)}
                                    totalPages={Math.ceil(oportunidadesFinalizadas.length / 20)}
                                />
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        oportunidadesFinalizadasMes: state.AutenticacaoReducer,
    };
};

export default connect(mapStateToProps)(withTranslation()(RelatorioGastoMensal));
