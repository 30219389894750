import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Segment } from "semantic-ui-react";
import { defaultFilterEvent } from "../../Componentes/FiltroOportunidade";
import { orderBy, roteirosExecutadosFinalizados } from "../../Utils/Roteiros";
import { oportunidadesExecutadas_Finalizadas } from "../../_actions/RoteiroActions";
import { DEFAULT_PER_PAGE, paginator, RoteiroPaginator } from "./../../Utils/RoteiroPaginator";
import ListarRoteiros from "./ListarRoteiros";
import ListarRoteirosExecutados from "./Listas/ListarRoteirosExecutados";
import RoteiroConnect from "./RoteiroConnect";

const RP = RoteiroPaginator;

class RoteirosExecutados extends Component {
	state = { filtroAberto: false, carregando: false, filtro: {} };

	componentWillMount() {
		if (!RP.findByName("executadas")) this.buildPaginator(defaultFilterEvent());
	}

	QUERY = filtro => {
		var query = roteirosExecutadosFinalizados(this.props.empresa.uid, false);
		if (filtro.dataInicio) query = query.where("data", ">=", filtro.dataInicio);
		if (filtro.dataFinal) query = query.where("data", "<=", filtro.dataFinal);
		if (filtro.tipo && filtro.tipo.id) query = query.where("tipo.id", "==", filtro.tipo.id);
		return query.orderBy("data", "desc");
	};

	buildPaginator = event => {
		var callback = ({ data }) => this.props.dispatch(oportunidadesExecutadas_Finalizadas(data));
		_.isEmpty(this.props.oportunidadesExecutadas_Finalizadas) &&
			paginator().get(this.QUERY(this.state.filtro), callback, DEFAULT_PER_PAGE, "executado");
	};

	render() {
		const { t } = this.props;
		const carregando = this.props.oportunidadesExecutadas_Finalizadas == null;
		const oportunidades = orderBy(
			_.groupBy(this.props.oportunidadesExecutadas_Finalizadas, "data")
		);

		return (
			<div>
				<ListarRoteiros
					t={t}
					carregando={carregando}
					roteiros={oportunidades}
					renderer={ListarRoteirosExecutados}
				/>

				<Segment basic textAlign="center">
					<Button
						content="Carregar mais"
						loading={carregando}
						onClick={() => {
							var callback = ({ data }) => {
								this.props.dispatch(oportunidadesExecutadas_Finalizadas(data));
								this.setState({ carregando: false });
							};
							paginator().get(
								this.QUERY(this.state.filtro),
								callback,
								DEFAULT_PER_PAGE,
								"executado"
							);
						}}
						basic
						color="violet"></Button>
				</Segment>
			</div>
		);
	}
}

export default RoteiroConnect(withTranslation()(RoteirosExecutados));
