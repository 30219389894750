import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import estados from "./estados";
import axios from "axios";
import { Dropdown, Input, Button, Icon } from "semantic-ui-react";
import Label from "../../Componentes/Label";
import { Divider } from "@chakra-ui/react";
var DATA_ATUAL = moment().format("YYYY-MM-DD");
export default class filter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ativo: "cidade",
			cidades: [],
			cidade: null,
			estado: null,
			error: false,
			data: DATA_ATUAL,
			dataFim: "",
		};
	}

	// buscar id do estado selecionado
	buscarID = busca => {
		var estatdo = estados.find(({ nome }) => nome === busca);
		return estatdo.id;
	};

	// buscar lista de cidades na api do ibge
	buscarCidades = value => {
		var id = this.buscarID(value);
		axios
			.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/distritos`)
			.then(res => {
				this.setState({ cidades: res.data, error: false });
			});
	};

	// tratando inputs
	handleInput = e => {
		if (e.target.name === "data") {
			var val = moment(DATA_ATUAL).isBefore(e.target.value) ? e.target.value : DATA_ATUAL;
			this.setState({ [e.target.name]: val });
		} else {
			this.setState({ [e.target.name]: e.target.value });
		}
		if (e.target.name === "dataFim") {
			val = moment(DATA_ATUAL).isBefore(e.target.value) ? e.target.value : DATA_ATUAL;
			this.setState({ [e.target.name]: val });
		}
		if (e.target.name === "estado") {
			this.buscarCidades(e.target.value);
		}
	};

	submit = e => {
		e.preventDefault();
		var filtro =
			this.state.cidade == null
				? {
						data: this.state.data,
						dataFim: this.state.dataFim,
						campo: "estado",
						estado: this.state.estado,
					}
				: {
						data: this.state.data,
						dataFim: this.state.dataFim,
						campo: "cidade",
						cidade: this.state.cidade,
					};
		this.state.estado != null ? this.props.filtrar(filtro) : this.setState({ error: true });
	};

	render() {
		var cidades = _.sortBy(
			this.state.cidades.map(({ nome }, index) => ({
				key: nome + index,
				value: nome,
				text: nome,
			})),
			["value"]
		);
		var ufs = _.sortBy(
			estados.map(({ nome }, index) => ({ key: nome + index, value: nome, text: nome })),
			["value"]
		);
		var { t } = this.props;
		return (
			<div style={{ width: "100%" }}>
				<form className="form_filter" onSubmit={this.submit}>
					<Label name={t("data")}>
						<Input
							disabled={this.props.carregando === true}
							type="date"
							name="data"
							onChange={this.handleInput}
							value={this.state.data}
							iconPosition="left"
							icon="calendar"
							style={{ borderRadius: "500px !important" }}
						/>
					</Label>
					<Label name={t("data.final")}>
						<Input
							disabled={this.props.carregando === true}
							type="date"
							name="dataFim"
							onChange={this.handleInput}
							value={this.state.dataFim}
							iconPosition="left"
							icon="calendar"
							style={{ borderRadius: "500px !important" }}
						/>
					</Label>
					<Label name={t("selecione.estado")}>
						<Dropdown
							disabled={this.props.carregando === true}
							placeholder={t("selecione.estado")}
							search
							selection
							error={this.state.error}
							onChange={(e, d) =>
								this.handleInput({ target: { name: "estado", value: d.value } })
							}
							value={this.state.estado}
							options={ufs}
						/>
					</Label>
					<Label name={t("selecione.cidade")}>
						<Dropdown
							disabled={this.state.estado == null || this.props.carregando === true}
							placeholder={t("selecione.cidade")}
							search
							selection
							onChange={(e, d) =>
								this.handleInput({ target: { name: "cidade", value: d.value } })
							}
							value={this.state.cidade}
							options={cidades}
						/>
					</Label>
					<div className="button_group_filter">
						<Button
							disabled={this.props.carregando === true}
							basic
							color="purple"
							icon
							loading={this.props.carregando}
							style={{ width: 100 }}>
							<Icon name="filter" />
							{t("filtrar")}
						</Button>
						<Button
							disabled={this.props.carregando === true}
							basic
							color="red"
							icon
							loading={this.props.carregando}
							style={{ width: 100 }}
							onClick={e => {
								e.preventDefault();
								this.setState({
									ativo: "cidade",
									cidade: null,
									estado: null,
									error: false,
									data: DATA_ATUAL,
									dataFim: "",
								});
							}}>
							<Icon name="trash alternate outline" />
							{t("limpar.filtro")}
						</Button>
					</div>
				</form>
				<Divider mt={4} borderColor="gray.300" borderWidth={1} />
			</div>
		);
	}
}
