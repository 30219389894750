import React, { Component } from "react";
import { Modal, Button, Image } from "semantic-ui-react";
import _ from "lodash";

export default class Tutorial extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tutoriais: {},
		};
	}

	componentDidMount() {
		this.getTuto();
	}

	getTuto = () => {
		var tutoriais = JSON.parse(localStorage.getItem("tutorialsFreelance"));
		this.setState({ tutoriais });
	};

	handleStopTutorial = nome => {
		var tutoriais = JSON.parse(localStorage.getItem("tutorialsFreelance"));
		tutoriais[nome] = true;
		localStorage.setItem("tutorialsFreelance", JSON.stringify(tutoriais));
		this.getTuto();
	};

	handleStopEveryTutorial = () => {
		var tutoriais = JSON.parse(localStorage.getItem("tutorialsFreelance"));
		let all = _.reduce(
			tutoriais,
			(novo, tuto, key) => {
				return { ...novo, [key]: true };
			},
			{}
		);
		localStorage.setItem("tutorialsFreelance", JSON.stringify(all));
		this.getTuto();
	};

	render() {
		const { tutorial, nome } = this.props;
		const { tutoriais } = this.state;
		var open = tutoriais[nome];

		return (
			<Modal open={!open} scrolling="true" size={"tiny"}>
				<Modal.Header>{tutorial.titulo}</Modal.Header>
				<Modal.Content>
					<div>{tutorial.primeiroTexto && <p>{tutorial.primeiroTexto}</p>}</div>
					{tutorial.primeiraImagem && (
						<Image src={tutorial.primeiraImagem} centered size={"medium"} />
					)}
					<div></div>
					<div>{tutorial.segundoTexto && <p>{tutorial.segundoTexto}</p>}</div>
					<div>
						{tutorial.segundaImagem && (
							<Image src={tutorial.segundaImagem} centered size={"big"} />
						)}
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button
						content="Não desejo tutoriais"
						color={"violet"}
						onClick={this.handleStopEveryTutorial}
					/>
					<Button
						content="Entendi"
						color={"green"}
						onClick={() => this.handleStopTutorial(nome)}
					/>
				</Modal.Actions>
			</Modal>
		);
	}
}
