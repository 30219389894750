import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import { Container, HeaderText } from "./styles";

interface Network {
    id: string;
    nome: string;
}

interface NetworkProps {
    networks: Network[];
}

export function Networks({ networks }: NetworkProps) {
    const { t } = useTranslation();

    return (
        <Container>
            <HeaderText>{t("listagem.redes")}</HeaderText>
            {networks?.length ? (
                networks.map((network) => (
                    <Grid stackable key={network.id} style={{ borderBottom: "1px solid #eee" }}>
                        <Grid.Column
                            width="11"
                            textAlign="left"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}>
                            {network.nome}
                        </Grid.Column>
                    </Grid>
                ))
            ) : (
                <Grid stackable style={{ borderBottom: "1px solid #eee" }}>
                    <Grid.Column
                        width="11"
                        textAlign="left"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}>
                        {t("nenhuma.rede")}
                    </Grid.Column>
                </Grid>
            )}
        </Container>
    );
}
