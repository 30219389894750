import { GASTO_MENSAL } from "../_actions/Types";


const INITIAL_STATE = {
    oportunidadesFinalizadas: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GASTO_MENSAL:
            return { ...state, oportunidadesFinalizadas: action.payload };
        default:
            break;
    }
    return state;
}