import _ from "lodash";
import moment from "moment";
import "moment-recur";

export const noneOf = (list, n) => !list.includes(n);
export const oneOf = (list, n) => list.includes(n);

export const nonEmptyString = str => _.isString(str) && !_.isEmpty(str);
export const positiveNonZero = n => _.isNumber(n) && parseFloat(n) > 0;

export const validarQuantidadeDeFoto = atividade =>
	noneOf([4], atividade.tipo) ? positiveNonZero(parseInt(atividade.quantidadeFoto)) : true;

export const validarProduto = atividade =>
	oneOf([2, 3, 5, 6], atividade.tipo)
		? _.isPlainObject(atividade.produto) && !_.isEmpty(atividade.produto)
		: true;

export const validarPergunta = atividade =>
	oneOf([4], atividade.tipo) ? nonEmptyString(atividade.pergunta) : true;

export const validarAtividades = atividades =>
	atividades.reduce(
		(acc, atividade) => {
			let st =
				validarQuantidadeDeFoto(atividade) &&
				validarProduto(atividade) &&
				validarPergunta(atividade);
			return {
				status: acc.status && st,
				atividades: acc.atividades.concat([{ status: st, atividade }]),
			};
		},
		{ status: true, atividades: [] }
	);

/**
 * Alexandre Poletto
 *
 * Validar as datas da recorrência para verificar se alguma não está no período selecionado.
 */
export const validarRecorrencia = roteiro => {
	return moment()
		.recur(roteiro.data, roteiro.dataFim)
		.every(roteiro.recorrencia)
		.daysOfWeek()
		.all("YYYY-MM-DD");
};

/**
 * Renahn Schultz
 *
 * Altera o roteiro no estado do componente referente.
 */
export const validarData60Dias = value =>
	moment(value, "YYYY/MM/DD").isAfter(moment().add(60, "days")) ? "erro.data.limite" : null;

/**
 * verifica se a data e hora selecionadas estão no passado
 * @param {string} data
 * @param {string} horaInicio
 */
const verificarDataNoPassado = (data, horaInicio) => {
	let dataInicio = moment(`${data} ${horaInicio}`);
	return {
		dataInicio: dataInicio.format("DD/MM/YY HH:mm"),
		passado: moment(dataInicio).isBefore(moment()),
	};
};

// Calcular se possui limite de saldo para criação
// da oportunidade em faturamento mensal
const calcularLimite = (roteiro, empresa, faturamentoMensal) => {
	const gastos = faturamentoMensal?.gastos;
	let maximo = empresa?.valorMaximoOportunidades ?? 0;
	let limite = maximo - gastos;
	return roteiro.valor > limite;
};

export const validarRoteiro = (roteiro, props) => {
	let {
		t,
		configuracao: { valorMinimo },
		empresa,
		faturamentoMensal,
		saldos,
	} = props;

	let error = null;
	error = validarData60Dias(roteiro.data);

	let rec = roteiro.data && roteiro.dataFim && validarRecorrencia(roteiro);
	let temFaturamentoMensal = empresa?.faturamentoMensal ?? false;
	let ultrapassaLimite = calcularLimite(roteiro, empresa, faturamentoMensal);

	if (saldos.naoReservado < roteiro.valor && roteiro.selecaoAutomatica && !temFaturamentoMensal) {
		return t("sem.saldo.suficiente");
	}

	if (temFaturamentoMensal && ultrapassaLimite) {
		return t("sem.saldo.suficiente");
	}

	if (roteiro.recorrencia !== undefined && rec.length === 0) {
		return t("data.recorrencia.nao.encontrada");
	}
	if (error === null && roteiro.dataFim !== "") {
		error = validarData60Dias(roteiro.dataFim);
	}
	if (error) {
		return t(error);
	}
	if (roteiro.tipo === null) {
		return t("selecione.atividade.roteiro");
	}
	if (roteiro.loja === null) {
		return t("selecione.loja.roteiro");
	}
	if ((roteiro.tipo.id === "3" || roteiro.tipo.id === "2") && roteiro.material.descricao === "") {
		return t("info.material.deve.ser.definida");
	}
	if (moment(roteiro.data, "YYYY-MM-DD").isBefore(moment().subtract(1, "day"))) {
		return t("data.invalida.anterior.hoje");
	}
	if (verificarDataNoPassado(roteiro.data, roteiro.horaInicio).passado) {
		return t("Roteiro.nao.pode.ser.no.pasado");
	}
	if (roteiro.horaInicio === "" && roteiro.horaFim === "") {
		return t("horarios.devem.ser.definidos");
	}
	if (roteiro.horaInicio === roteiro.horaFim) {
		return t("horarios.devem.ser.diferente");
	}
	if (moment(roteiro.horaInicio, "HH:mm").isAfter(moment(roteiro.horaFim, "HH:mm"))) {
		return t("horario.inicial.posterior.a.horario.termino");
	}
	if ((parseFloat(roteiro.valor) || 0) < valorMinimo) {
		return t("valor.inferior.minimo").replace(/\{valor\}/, valorMinimo);
	}
	if (roteiro.tipo.id !== 5) {
		if (_.isEmpty(roteiro.secoes)) {
			return t("roteiro.sem.secao");
		}
	}
	if (roteiro.tipo.id === 5 && roteiro.descricao === "") {
		return t("sem.descricao.por.tempo");
	}
	if (roteiro.atividades?.length) {
		return t("roteiro.com.atividade");
	}

	if (roteiro.tipo.id !== 5 && (parseFloat(roteiro.tempoEstimado) || 0) < 1) {
		return t("preencha.tempo.estimado.em.minutos");
	}
	if (roteiro.tipo.id !== 5 && (parseFloat(roteiro.tempoEstimado) || 0) < 30) {
		return t("tempo.estimado.invalido");
	}
	return null;
};
export const validarSalvarModelo = (t, salvar, nome) =>
	salvar && !nonEmptyString(nome) ? t("faltou.nome.modelo.roteiro") : null;
