import { chamarFunction } from "../../../Utils/Api";
import { logging } from "../../Api";

export class CompanyUserService {
	private static instance: CompanyUserService;

	public static getInstance(): CompanyUserService {
		if (!CompanyUserService.instance) {
			CompanyUserService.instance = new CompanyUserService();
		}
		return CompanyUserService.instance;
	}

	private constructor() {}

	createOne(data: CreateOneData) {
		logging("CREATE", `log.criar.usuario`, data.email);
		return chamarFunction("CompanyUser-createOne", {
			nome: data.name,
			email: data.email,
			ativo: data.isActive,
			telefone: data.cellphone,
		});
	}

	patchOne(data: PathOneData) {
		logging("UPDATE", `log.update.usuario`, data.id);
		return chamarFunction("CompanyUser-patchOne", {
			id: data.id,
			nome: data.name,
			email: data.email,
			ativo: data.isActive,
			telefone: data.cellphone,
		});
	}

	deleteOne(id: string) {
		logging("DELETE", `log.delete.usuario`, id);
		return chamarFunction("CompanyUser-deleteOne", {
			id,
		});
	}
}

interface CreateOneData {
	name: string;
	email: string;
	isActive: boolean;
	cellphone: string;
}

interface PathOneData extends Partial<CreateOneData> {
	id: string;
}
