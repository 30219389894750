import { Container, Flex } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { withSnackbar } from "notistack";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { Loader, Segment } from "semantic-ui-react";
import { OpportunityInformationHeader } from "../../Componentes/OpportunityInformationHeader";
import { db } from "../../config";
import { NegotiationProvider } from "../../contexts/Negotiation";
import AprovarCandidato from "./Aprovacao/AprovarCandidato";
import RoteiroExpirado from "./RoteiroExpirado";
import VerificarRoteiro from "./VerificarRoteiro/VerificarRoteiro";

class RoteiroBase extends Component {
	state = {
		idRoteiro: this.props.match.params.id,
		roteiro: {},
		modalRelancarAberto: false,
	};

	perms = {
		1: () => <AprovarCandidato roteiro={this.state.roteiro} {...this.props} />,
		2: () => <AprovarCandidato roteiro={this.state.roteiro} {...this.props} />,
		4: () => <RoteiroExpirado roteiro={this.state.roteiro} {...this.props} />,
		7: () => <RoteiroExpirado roteiro={this.state.roteiro} {...this.props} />,
		6: () => <VerificarRoteiro roteiro={this.state.roteiro} {...this.props} />,
		3: () => <VerificarRoteiro roteiro={this.state.roteiro} {...this.props} />,
		5: () => <VerificarRoteiro roteiro={this.state.roteiro} {...this.props} />,
	};

	HideHeader = { 4: 4 };

	componentDidMount() {
		this.buscarRoteiro();
	}

	buscarRoteiro = () =>
		db
			.collection("roteiros")
			.doc(this.state.idRoteiro)
			.onSnapshot(this.buscarInformacoesDoRoteiro);

	buscarInformacoesDoRoteiro = snap => {
		const roteiro = snap.data();
		if (roteiro.idEmpresa === this.props.empresa.uid) {
			this.setState({ roteiro });
		} else {
			return this.props.history.push("/dashboard");
		}
	};

	switchModalRelancarOportunidade = event =>
		this.setState({
			modalRelancarAberto: !this.state.modalRelancarAberto,
		});

	verificarExpirada = (roteiro, atual) =>
		_.includes([1, 2, 4], roteiro.statusRoteiro && roteiro.statusRoteiro.id) &&
		moment(roteiro.dataFim ? roteiro.dataFim : roteiro.data).isBefore(atual);

	verificarStatus = roteiro => {
		var atual = moment().format("YYYY-MM-DD");
		if (this.verificarExpirada(roteiro, atual)) {
			let Componente = this.perms[4];
			return <Componente />;
		} else {
			let Componente = this.perms[roteiro.statusRoteiro.id];
			return <Componente />;
		}
	};

	render() {
		var atual = moment().format("YYYY-MM-DD");
		var { t } = this.props;
		const { roteiro } = this.state;
		return (
			<NegotiationProvider opportunityId={roteiro.id || ""}>
				<Container maxW="container.lg">
					{!_.isEmpty(roteiro) ? (
						<Flex flexDir="column" gap={7}>
							{!this.HideHeader[roteiro.statusRoteiro.id] &&
								!this.verificarExpirada(roteiro, atual) && (
									<OpportunityInformationHeader
										roteiro={roteiro}
										{...this.props}
										switchModalRelancarOportunidade={
											this.switchModalRelancarOportunidade
										}
										modalRelancarAberto={this.state.modalRelancarAberto}
									/>
								)}
							{this.verificarStatus(roteiro)}
						</Flex>
					) : (
						<>
							<Segment basic className="page-loading">
								<Loader style={{ display: "block" }} inline="centered">
									{t("carregando")}
								</Loader>
							</Segment>
						</>
					)}
				</Container>
			</NegotiationProvider>
		);
	}
}

export default withSnackbar(withTranslation()(RoteiroBase));
