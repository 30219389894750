import { Polygon } from "geojson";
import { useMemo } from "react";
import { DefaultValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Grid, Button } from "@chakra-ui/react";
import { ColorPickerForm } from "../../../Componentes/Form/ColorPickerForm";
import { InputForm } from "../../../Componentes/Form/chakraUI/InputForm";
import { TextAreaForm } from "../../../Componentes/Form/chakraUI/TextAreaForm";
import { OperationAreaMap } from "./OperationAreaMap";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import numeral from "numeral";
import { z } from "zod";
import { getAreaAndGeometryFromLayer } from "../../../helpers/GeographyCalculation";
import {
	OperationArea as OperationAreaType,
	OperationAreaCircle,
} from "../../../@types/OperationArea";
import { generateRandomHexColor } from "../../../Utils/Colors";
import { useConfigs } from "../../store/useConfigs";

export interface OperationAreaFormProps {
	name: string;
	description: string;
	geometricShape: null | OperationAreaType["geometry"];
	color: string;
	zoomLevel: number;
	mapCenter: [number, number];
}

interface UseUpsertOperationAreaFormProps {
	onSubmit: SubmitHandler<OperationAreaFormProps>;
	defaultValues?: DefaultValues<OperationAreaFormProps>;
	editMode?: boolean;
}

export function useUpsertOperationAreaForm({
	onSubmit,
	defaultValues,
	editMode,
}: UseUpsertOperationAreaFormProps) {
	const { t } = useTranslation();
	const { areaDeAtuacaoMaxima } = useConfigs();

	const FormSchema = z.object({
		name: z
			.string()
			.min(1, t("nome-e-obrigatorio"))
			.max(80, t("nome-nao-pode-passar-de-x-caracteres", { max: 80 }))
			.trim(),
		description: z
			.string()
			.max(500, t("descricao-nao-pode-ter-mais-que-x-caracteres", { max: 500 }))
			.trim()
			.optional(),
		color: z.string(),
		zoomLevel: z.number(),
		geometricShape: z
			.null()
			.refine(value => value, t("crie-ao-menos-uma-area"))
			.or(
				z
					.object({})
					.passthrough()
					.refine(
						value => {
							const { area } = getAreaAndGeometryFromLayer(
								value as unknown as Polygon | OperationAreaCircle
							);

							return area <= areaDeAtuacaoMaxima;
						},
						t("a-area-ultrapassa-o-limite-de-x-km-ao-quadrado", {
							max: numeral(areaDeAtuacaoMaxima / 1_000_000).format("0,0"),
						})
					)
			),
	});

	const formMethods = useForm<OperationAreaFormProps>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			name: "",
			description: "",
			color: generateRandomHexColor(),
			geometricShape: null,
			zoomLevel: 13,
			mapCenter: [51.505, -0.09],
			...defaultValues,
		},
	});

	const {
		handleSubmit,
		control,
		formState: { isSubmitting },
	} = formMethods;

	const formContent = useMemo(() => {
		return (
			<FormProvider {...formMethods}>
				<Grid as="form" onSubmit={handleSubmit(onSubmit)} gap={5}>
					<InputForm
						control={control}
						name="name"
						label={t("nome")}
						placeholder={t("placeholder-nome-area-de-atuacao")}
						isRequired
					/>
					<ColorPickerForm control={control} name="color" label={t("cor-da-area")} />
					<TextAreaForm
						minH={168}
						control={control}
						name="description"
						label={t("descricao")}
						placeholder={t("placeholder-descricao-area-de-atuacao")}
					/>
					<OperationAreaMap editMode={editMode} />
					<Button type="submit" isDisabled={isSubmitting}>
						{editMode ? t("atualizar") : t("criar")}
					</Button>
				</Grid>
			</FormProvider>
		);
	}, [control, editMode, formMethods, handleSubmit, isSubmitting, onSubmit, t]);

	return { formContent, formMethods };
}
